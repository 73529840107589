import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import { CINEMA_VENUE_MODEL_NAME } from "./../configs/constants";
import { updateCinemaVenue } from "./../graphql/mutations/benefitMutations";
import { cinemaVenueDetails } from "./../graphql/queries/benefitQueries";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useBaseNavigation from "./../hooks/baseNavigation";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import useModal from "./../hooks/modal";
import useNotification from "./../hooks/notifications";
import { CancelButton, FormControls, SaveButton } from "./../mood-ui/Controls";
import { ReadOnlyField, SelectField } from "./../mood-ui/Fields";
import {
  BlockContainer,
  Col,
  FormContainer,
  FormData,
  PageContainer,
  Row,
} from "./../mood-ui/Layout";
import { ErrorNotification } from "./../mood-ui/Notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { Heading } from "./../mood-ui/Typography";

const CINEMA_VENUE_DETAILS = gql(cinemaVenueDetails);
const UPDATE_CINEMA_VENUE = gql(updateCinemaVenue);

const CinemaVenue = () => {
  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Mutation.ManageCinemaSocietyData);

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const { goBack } = useBaseNavigation(CINEMA_VENUE_MODEL_NAME);

  const { id } = useParams();

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    name: "",
    chain_name: "",
    postcode: "",
    city: "",
    county: "",
    address_1: "",
    address_2: "",
    address_3: "",
    status: "",
  });

  const { setFieldValue: handleFieldOnChangeProxied } = useBaseFieldSetters(
    formData,
    stateMethods,
    clearError
  );

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const { error } = useQuery(CINEMA_VENUE_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.cinemaVenue) return false;

      setFormData({
        ...result.cinemaVenue,
      });
    },
  });

  const [updateVenue, { loading: isUpdating }] = useMutation(
    UPDATE_CINEMA_VENUE,
    {
      variables: { ...formData },
      onCompleted: (result) => {
        goBack();
      },
      onError: (e) => {
        resolveMutationErrors(e);
      },
    }
  );

  const handleSave = () => {
    const data = {
      ...formData,
    };
    if (data.status === "Draft") {
      displayErrorNotification({
        heading: "Status error",
        message:
          "This is a new Cinema venue that was recently pulled from Cinema Society API. You need to change its status to Disabled or Active to save it",
        secondsToDisplay: 5,
      });
      return false;
    }

    updateVenue({ variables: { status: formData.status } });
  };

  const [_, setDraft] = useState(false);

  if (error) return <div>Error...</div>;
  if (!formData || !formData.name) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update Cinema Society Venue status"
              description=""
            />
          </Col>
        </Row>

        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading size="md" text="Visibility" description="" />
                    <p
                      className={
                        "mt-1 text-sm leading-5 text-gray-400 select-none"
                      }
                    >
                      - Draft. This chain was pulled from the Chain Society API
                      and was not configured yet.
                      <br />
                      - Active. Chain is configred and active - available for
                      users.
                      <br /> - Disabled. Chain is disabled and not available for
                      users.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.status}
                      values={
                        formData.status === "Draft"
                          ? [
                              { id: "Draft", name: "Draft" },
                              { id: "Active", name: "Active" },
                              { id: "Disabled", name: "Disabled" },
                            ]
                          : [
                              { id: "Active", name: "Active" },
                              { id: "Disabled", name: "Disabled" },
                            ]
                      }
                      label="Status"
                      name="status"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      valueKey="id"
                      labelKey="name"
                      isRequired={true}
                      error={fieldError("status")}
                      description=""
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Venue details"
                      description="All venue details are read-only, they are pulled from the Cinema Society API. You can change only status"
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles="">
                    <ReadOnlyField
                      name="name"
                      label="Name"
                      value={formData.name}
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <ReadOnlyField
                      name="chain_name"
                      label="Chain"
                      value={formData.chain_name}
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <ReadOnlyField
                      name="city"
                      label="City"
                      value={formData.city}
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <ReadOnlyField
                      name="postcode"
                      label="Postcode"
                      value={formData.postcode}
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <ReadOnlyField
                      name="address_1"
                      label="Address 1"
                      value={formData.address_1}
                    />
                  </Col>
                  {formData.address_2 && (
                    <Col width={4} extraStyles="">
                      <ReadOnlyField
                        name="address_2"
                        label="Address 2"
                        value={formData.address_2}
                      />
                    </Col>
                  )}
                  {formData.address_3 && (
                    <Col width={4} extraStyles="">
                      <ReadOnlyField
                        name="address_3"
                        label="Address 3"
                        value={formData.address_3}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            {hasAccess(Permissions.Mutation.ManageCinemaSocietyData) && (
              <SaveButton
                onClick={handleSave}
                loading={isUpdating}
                disabled={formHasErrors()}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default CinemaVenue;
