import React, { useState } from "react";
// import Search from "./../../mood-ui/Search";
import {
  Row,
  Col,
} from "./../../mood-ui/Layout";
import { ItemsNotFound } from "./../../mood-ui/Typography";
import { Pagination, FetchMoreControl } from "./../../mood-ui/DataTable";
// import { BaseTableBottomCTA } from "./../../mood-ui/tables/BaseTable";
// import {
//   Pagination,
//   FetchMoreControl,
// } from "./../../mood-ui/tables/Pagination";
import Dropzone from "./Dropzone";
import Files from "./Files";

const FileGallery = ({
  showDropzone,
  files,
  nextPage,
  prevPage,
  fetchMore = () => {},
  onSelectFile = false,
  onSelectFileConfirmed = false,
  showCopyLink = true,
  hasMore = false,
  onUploadComplete = false,
}) => {
  const [selectedFile, setSelectedFile] = useState(false);
  const handleSelectFile = (file) => {
    setSelectedFile(file);
    onSelectFile(file);
  };
  const handleFileDoubleClick = (file) => {
    setSelectedFile(file);
    onSelectFileConfirmed(file);
  };
  return (
    <div>
      <Row tweaks="gap-y-2">
        <Col width={12} extraStyles="px-1 mt-6">
          {showDropzone && (
            <Dropzone onUploadComplete={onUploadComplete} />
          )}
        </Col>
        <Col width={12}>
          {files.length > 0 && (
            <Files
              files={files}
              onSelectFile={onSelectFile ? handleSelectFile : () => {}}
              onSelectConfirmed={
                onSelectFileConfirmed ? handleFileDoubleClick : () => {}
              }
              selectedFile={selectedFile}
              showCopyLink={showCopyLink}
            />
          )}
          {!files ||
            (files.length == 0 && <ItemsNotFound text="Files not found" />)}
        </Col>
        <Col width={12} extraStyles="px-1 pb-8">
          {files.length > 0 && prevPage && nextPage && !fetchMore && (
            <Pagination onPrev={prevPage} onNext={nextPage} />
          )}
          {files.length > 0 && fetchMore && (
            <div>{hasMore && <FetchMoreControl onFetchMore={fetchMore} />}</div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FileGallery;
