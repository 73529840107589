import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allMembershipVouchers } from "./../graphql/queries/billingQueries";
import {
  publishMembershipVouchersMutation,
  deleteMembershipVouchersMutation,
} from "../graphql/mutations/billingMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import { MEMBERSHIP_VOUCHER_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import {
  AddButton,
  ListItemCheckbox,
  PageControls,
  TEditButton,
} from "./../mood-ui/Controls";
import { ColorLabel, Heading } from "./../mood-ui/Typography";
import Search from "./../mood-ui/Search";
import { ItemsNotFound } from "./../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  Pagination,
} from "./../mood-ui/DataTable";
import { ConfirmActionModal } from "../mood-ui/Modals";
import {
  ErrorNotification,
  SuccessNotification,
} from "../mood-ui/Notifications";
import useListSelection from "../hooks/rowSelection";
import useModal from "../hooks/modal";
import useNotification from "../hooks/notifications";
import { TableSelectedActions } from "../moose-ui/DataTable";
import MembershipVoucherListActions from "../components/list-secondary-actions/billing/MembershipVoucherListActions";

const FinalPriceLabel = ({ product: p }) => {
  if (p.final_price > p.rrp_price)
    return <ColorLabel text={p.final_price} color="red" />;

  if (p.single_price < p.final_price && p.final_price <= p.rrp_price)
    return <ColorLabel text={p.final_price} color="blue" />;

  return p.final_price;
};

const ALL_VOUCHERS = gql(allMembershipVouchers);
const PUBLISH_VOUCHERS = gql(publishMembershipVouchersMutation);
const DELETE_VOUCHERS = gql(deleteMembershipVouchersMutation);

const MembershipVoucherList = () => {
  const { protectPage } = usePermissions();

  protectPage(Permissions.Mutation.ManageMembershipVouchers);

  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    isAllRowsSelected,
    toggleAllRows,
    clearSelection,
  } = useListSelection();

  const { page, limit, orderBy, orderHow, search } = useParams();

  const { search: queryString } = useLocation();

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { data, loading, refetch, error } = useQuery(ALL_VOUCHERS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { nextPage, prevPage, editForm, applySearch, createForm } =
    useBaseNavigation(MEMBERSHIP_VOUCHER_MODEL_NAME);

  const [publishVouchers, { loading: publishing }] = useMutation(
    PUBLISH_VOUCHERS,
    {
      onCompleted: (_) => {
        refetch();
        displayNotification({
          heading: "Vouchers Published",
          message: "Successfully published selected membership vouchers",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to publish selected membership vouchers",
          secondsToDisplay: 2,
        });
      },
    }
  );

  const [deleteVouchers, { loading: deleting }] = useMutation(DELETE_VOUCHERS, {
    onCompleted: (_) => {
      refetch();
      displayNotification({
        heading: "Vouchers Deleted",
        message: "Successfully deleted selected membership vouchers",
        secondsToDisplay: 1,
      });
      clearSelection();
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to delete selected membership vouchers",
        secondsToDisplay: 2,
      });
    },
  });

  const confirmPublishing = () => {
    showModal({
      heading: "Confirm Publishing",
      message:
        "Are you sure you want to publish selected Membership Vouchers? This action is irreversible.",
      mood: "Success",
      confirmButtonLabel: "Publish",
      onConfirm: () => {
        closeModal();
        handlePublishVouchers();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handlePublishVouchers = () =>
    publishVouchers({ variables: { ids: selectedRows } });

  const confirmDelete = () => {
    showModal({
      heading: "Confirm Deletion",
      message:
        "Are you sure you want to delete selected Membership Vouchers? This action is irreversible.",
      mood: "Danger",
      confirmButtonLabel: "Delete",
      onConfirm: () => {
        closeModal();
        handleDeleteVouchers();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteVouchers = () =>
    deleteVouchers({ variables: { ids: selectedRows } });

  const showUpdateInProgress = deleting || publishing;

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-5 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Membership Vouchers"
              description="Control Membership Vouchers."
            />
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            <AddButton
              label="Add voucher  "
              onClick={createForm}
              loading={loading}
            />
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        <TableContainer tweaks="px-5 pb-5">
          <TableView>
            <THeading>
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() =>
                      toggleAllRows(data?.membershipVouchers?.map((v) => v.id))
                    }
                    isChecked={
                      isAllRowsSelected(
                        data?.membershipVouchers?.map((v) => v.id)
                      )
                        ? true
                        : false
                    }
                  />
                </THCol>
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="code" value="Code" />
                <THCol tweaks="text-left" name="title" value="Title" />
                <THCol tweaks="text-left" name="type" value="Type" />
                <THCol
                  tweaks="text-left"
                  name="max_uses_per_user"
                  value="Max uses per user"
                />
                <THCol tweaks="text-left" name="released" value="Released" />
                <THCol tweaks="text-left" name="used" value="Used" />
                <THCol tweaks="text-left" name="status" value="Status" />
                <THCol
                  tweaks="text-left"
                  name="expires_at"
                  value="Expires At"
                />
                <THCol tweaks="w-16 text-center" />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data &&
              data.membershipVouchers &&
              data.membershipVouchers.length === 0 && (
                <tbody className="w-full">
                  <tr>
                    <td colSpan="100%">
                      <ItemsNotFound text="No Vouchers found" tweaks="" />
                    </td>
                  </tr>
                </tbody>
              )}
            {data &&
              data.membershipVouchers &&
              data.membershipVouchers.length > 0 && (
                <TContent>
                  {data.membershipVouchers.map((v, rowIndex) => (
                    <TRow key={v.id}>
                      <TCol>
                        <ListItemCheckbox
                          onChange={() => toggleRowSelection(v.id)}
                          isChecked={rowSelected(v.id) ? true : false}
                        />
                      </TCol>
                      <TCol value={v.id} />
                      <TCol tweaks="max-w-[180px]">
                        <abbr title={v.code}>{v.code}</abbr>
                      </TCol>
                      <TCol tweaks="max-w-[180px]">
                        <abbr title={v.title}>{v.title}</abbr>
                      </TCol>
                      <TCol tweaks="max-w-[100px]">
                        <abbr title={v.type}>{v.type}</abbr>
                      </TCol>
                      <TCol tweaks="max-w-[100px]">
                        <abbr title={v.max_uses_per_user}>
                          {v.max_uses_per_user}
                        </abbr>
                      </TCol>
                      <TCol tweaks="max-w-[180px]">
                        <abbr title={v.released}>{v.released}</abbr>
                      </TCol>
                      <TCol tweaks="max-w-[180px]">
                        <abbr title={v.used}>{v.used}</abbr>
                      </TCol>
                      <TCol tweaks="max-w-[180px]">
                        {v.status === "Published" && (
                          <ColorLabel text="Active" color="green" />
                        )}
                        {v.status === "Draft" && (
                          <ColorLabel text="Disabled" color="gray" />
                        )}
                      </TCol>
                      <TCol tweaks="max-w-[180px]">
                        <abbr title={v.expires_at}>{v.expires_at}</abbr>
                      </TCol>
                      <TCol tweaks="w-16 text-center">
                        <TEditButton onClick={() => editForm(v.id)} />
                      </TCol>
                    </TRow>
                  ))}
                </TContent>
              )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <MembershipVoucherListActions
                      onClearSelection={clearSelection}
                      onPublish={confirmPublishing}
                      onDelete={confirmDelete}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={7} extraStyles="">
                {showUpdateInProgress && (
                  <ColorLabel text="Appying changes..." color="blue" />
                )}
              </Col>
              <Col width={3}>
                <Pagination
                  onPrev={page > 1 ? prevPage : false}
                  onNext={
                    data?.mebershipVouchers?.length >= limit - 1
                      ? nextPage
                      : false
                  }
                />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default MembershipVoucherList;
