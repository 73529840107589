import React from "react";
import moment from "moment";
import PageSpinner from "../../mood-ui/PageSpinner";
import { truncateNumber } from "../../hooks/utils";

import { Bubble } from "react-chartjs-2";

const BubbleChart = ({ data = null, dateKey = 'date', options = null }) => {

  let labels  = []
  let dataset = []
  if (data) {
    let obj = {}
    data.forEach(item => {
      let day = item[dateKey].split(' ')[0]
      // let hour = Math.ceil(parseInt(item[dateKey].split(' ')[1].split(':')[0]) / 2) * 2
      let hour = Math.ceil(parseInt(item[dateKey].split(' ')[1].split(':')[0]))
      if (obj[day]) { 
        if (obj[day][hour]) { obj[day][hour] ++ }
        else { obj[day][hour] = 1 }
      }
      else { 
        obj[day] = {}
        obj[day][hour] = 1
      }
    })
    let set = []
    Object.keys(obj).forEach(key => {
      Object.keys(obj[key]).forEach(key2 => {
        set.unshift({ x: key, y: key2, z: obj[key][key2], r: Math.max(4, Math.min(20, obj[key][key2] + 4)) })
      })
    })
    dataset = set
  }

  const baseData = {
    labels: labels,
    datasets: [
      {
        label: " Login",
        data: dataset,
        backgroundColor: "rgba(89,35,115,0.2)",
        borderColor: "rgba(89,35,115,0.4)",
        borderWidth: 1,
        hitRadius: 2, 
      },
    ],
  };
  
  const baseOptions = {
    layout: {
      padding: {
        left: 0,
        right: 10,
        top: 20,
        bottom: 0
      }
    },
    keepApectRatio: false, 
    responsive: true, maintainAspectRatio: true, 
    animation: { duration: 300, easing: 'easeOutQuad', }, 
    hover: { animationDuration: 125, },
    legend: { display: false, }, 
    tooltips: {
      mode: "index", intersect: true, 
      backgroundColor: 'rgba(66,66,66,0.8)', 
      titleMarginBottom: 0, titleFontSize: 0, 
      bodySpacing: 4, bodyFontSize: 11, 
      footerMarginTop: 6, footerAlign: 'right', 
      bodyFontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
      cornerRadius: 0, caretSize: 4, borderWidth: 0, 
      xPadding: 7, yPadding: 7, position: 'nearest',
      callbacks: {
        footer: function(tooltipItem, data) {
          return moment(tooltipItem[0].label).format('ddd - DD MMM - YYYY') + ` - ${ tooltipItem[0].yLabel }:00`
        }, 
        beforeBody: function(tooltipItem, data) {
          return data.datasets[0].id
        }, 
        labelColor: function(tooltipItem, chart) {
          return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
        },
        labelTextColor: function(tooltipItem, chart) {
          return `rgba(255,255,255, 1)`
        },
        label: function(tooltipItem, data) {
          return data.datasets[tooltipItem.datasetIndex].label + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].z
        }, 
      }, 
      enabled: true,
    },
    scaleLabel: { fontSize: 10, },
    scales: {
      yAxes: [
        {
          gridLines: { color: 'rgba(200, 200, 200, 0.1)', zeroLineWidth: 0, }, 
          ticks: {
            fontSize: 10, 
            sbeginAtZero: false, fontColor: 'rgba(100, 100, 100, 0.7)', 
            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
            callback: function(value, index, values) { return truncateNumber(value)[0] + truncateNumber(value)[2] }
          },
        },
      ],
      xAxes: [
        {
          gridLines: { color: 'rgba(200, 200, 200, 0.1)', zeroLineWidth: 0, }, 
          ticks: {
            fontSize: 10, fontColor: 'rgba(100, 100, 100, 0.7)', 
            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
          },
          type: 'time',
          time: {
            unit: 'month'
          },
          distribution: 'linear', // series linear
        },
      ],
    },
    ...options
  }

  if (!data) return <PageSpinner clear />;

  return (
    <Bubble height={65} data={baseData} options={baseOptions} />
  );

};

export default BubbleChart;