import React from "react";
import { createRoot } from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import { API_URL } from "./configs/constants";
import "./moose-ui/styles/RichTextEditor.css";
import * as serviceWorker from "./serviceWorker";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

// import { ApolloProvider } from "react-apollo";
// import { ApolloProvider } from "@apollo/react-hooks";
// import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
// import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { BrowserRouter } from "react-router-dom";
import { TOKEN_NAME, getCookie } from "./hooks/authService";
import PermissionProvider from "./contexts/PermissionContext";
import LabelProvider from "./contexts/LabelContext";
import TagTypeProvider from "./contexts/TagTypeContext";

// const API_URL = "http://forms.vaboo.uk/graphql";
// const API_URL = "https://control.vaboo.co.uk/graphql";
// const API_URL = "http://localhost:8000/graphql";
// const API_URL = "http://vaboo-control.local/graphql";

// console.log(API_URL);
console.log(process.env.NODE_ENV);

const httpLink = createHttpLink({
  uri: API_URL,
});

const uploadLink = createUploadLink({
  uri: API_URL,
  headers: {
    "keep-alive": "true",
  },
});

const authLink = setContext((_, { headers }) => {
  // const token = localStorage.getItem(TOKEN_NAME);
  const token = getCookie(TOKEN_NAME);

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cache = new InMemoryCache({
  possibleTypes: {
    GenericFilter: ["Filter", "DatesFilter"],
  },
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache,
});

const container = document.getElementById("root");

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <CookiesProvider>
        <PermissionProvider>
          <LabelProvider>
            <TagTypeProvider>
              <App />
            </TagTypeProvider>
          </LabelProvider>
        </PermissionProvider>
      </CookiesProvider>
    </ApolloProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
