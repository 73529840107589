import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { invoiceDetails } from "../../graphql/queries/billingQueries";
import { updateInvoiceMutation, chargeNowMutation } from "../../graphql/mutations/billingMutations";
import {
  BILLING_MANUAL_INVOICE_STATUS,
  INVOICE_TYPE_MODEL_NAME,
  PAYMENT_SYSTEMS,
} from "./../../configs/constants";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useHyperState from "./../../hooks/hyperState";
import useFormErrors from "./../../hooks/formErrors";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useDataProvider from "./../../hooks/dataProvider";
import useNotification from "../../hooks/notifications";
import PageSpinner from "./../../mood-ui/PageSpinner";
import { ErrorNotification } from "../../mood-ui/Notifications";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../../mood-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
  InlineControls,
  BasicControl
} from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import {
  TextField,
  DateField,
  SelectField,
  NumberField, 
  ReadOnlyField,
} from "./../../mood-ui/Fields";

const INVOICE_DETAILS = gql(invoiceDetails);
const UPDATE_INVOICE = gql(updateInvoiceMutation);
const CHARGE_NOW = gql(chargeNowMutation);

const OPTIONS = {
  USER_MODE: "",
};

const VabooUserInvoiceEdit = ({ updateItemPermission, chargeNowPermission }) => {
  
  useEffect(() => {
    OPTIONS.USER_MODE = window.location.pathname.includes("clients")
      ? "clients"
      : "users";
    return () => {
      OPTIONS.USER_MODE = "";
    };
  }, []);
  
  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();
  
  const { id: user_id, invoice_id } = useParams();
  
  const [users] = useDataProvider("landlords");
  
  const { goBack, goTo } = useBaseNavigation(INVOICE_TYPE_MODEL_NAME, {
    urlContext: `${OPTIONS.USER_MODE}/${user_id}`,
  });

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    cart_id: user_id,
    amount: 0,
    description: "",
    payment_system: "Stripe",
    date_to_charge: undefined,
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleFieldOnChangeProxied(e)
  }

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { loading, error } = useQuery(INVOICE_DETAILS, {
    variables: {
      id: invoice_id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      if (!result || !result.invoice) return false;
      if (result.invoice.status !== BILLING_MANUAL_INVOICE_STATUS) goBack();
      setFormData({ ...result.invoice });
    },
  });

  const [
    update,
    { data: updateResult, loading: isUpdating, error: updateError },
  ] = useMutation(UPDATE_INVOICE, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const [
    chargeNow,
    { data: chargeResult, loading: isCharging, error: chargingError },
  ] = useMutation(CHARGE_NOW, {
    variables: { id: invoice_id },
    onCompleted: (result) => {
      goTo(`${window.location.pathname}/view`);
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to charge client",
        secondsToDisplay: 3,
      });
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    update({ variables: data });
  };

  const [ isDraft, setDraft ] = useState(false)

  if (error) return <div>Error...</div>;

  // if (!formData || loading) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={8}>
            <Heading
              inset={true}
              text="Update or charge invoice"
              description="When you click 'Charge now', you can no longer make changes and the system
                          will attempt to take the payment four times over two days. If the payment 
                          does not succeed, it will become a 'debt record' and must be investigated
                          manually."
              />
          </Col>
          <Col width={4}>
            <InlineControls>
              {chargeNowPermission && (
                <BasicControl
                  onClick={chargeNow}
                  label="Charge now" 
                  loading={isCharging}
                />
              )}
            </InlineControls>
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-[#dfe2e8] py-8 mx-5">
              <Col width={3}> 
                <Row tweaks="">
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Invoice details"
                      description="Customise the invoice settings. Once saved, this invoice cannot be changed."
                    />
                  </Col>
                  <Col width={12}>
                    {OPTIONS.USER_MODE === "users" && (
                      <ReadOnlyField
                        loading={!formData || loading}
                        label="Assign to"
                        value="Invoice will be assigned to the current user"
                      />
                    )}
                    {OPTIONS.USER_MODE === "clients" && (
                      <SelectField
                        loading={!formData || loading}
                        value={formData?.cart_id}
                        values={users || []}
                        label="Assign to"
                        name="cart_id"
                        onChange={handleFieldOnChange}
                        valueKey="id"
                        labelKey="email"
                        isRequired={true}
                        description="Select which client to invoice"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row tweaks="gap-y-2">
                  <Col width={4}>
                    <NumberField
                      loading={!formData || loading}
                      name="amount"
                      label="Amount"
                      value={formData?.amount}
                      onChange={handleFieldOnChange}
                      error={fieldError("amount")}
                      isRequired={true}
                      step={0.01}
                      gbp
                      description="Must include VAT"
                    />
                  </Col>
                  <Col width={4}>
                    <DateField
                      loading={!formData || loading}
                      name="date_to_charge"
                      label="Due date"
                      value={formData?.date_to_charge || ''}
                      description="Leave empty to charge now"
                      onChange={handleFieldOnChange}
                      error={fieldError("date_to_charge")}
                    />
                  </Col>
                  <Col width={4}>
                    <SelectField
                      loading={!formData || loading}
                      value={formData?.payment_system}
                      values={PAYMENT_SYSTEMS || []}
                      label="Payment method"
                      name="payment_system"
                      description="Select a payment method"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      isRequired={true}
                    />
                  </Col>
                  <Col width={12}>
                    <TextField
                      loading={!formData || loading}
                      name="description"
                      label="Description"
                      value={formData?.description}
                      onChange={handleFieldOnChange}
                      error={fieldError("description")}
                      isRequired={true}
                      description="Shown on the invoice seen by the account owner"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            {updateItemPermission && (
              <SaveButton onClick={handleSave} disabled={isCharging || !isDraft || formHasErrors()} loading={isUpdating} />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default VabooUserInvoiceEdit;
