import React from "react";
import FilterTitles from "./FilterTitles";
import { DATE_FILTERS, SYSTEM_FILTERS } from "./../../hooks/baseNavigation";

const FilteringTitles = ({ options, selectedValues, onRemoveFilterValue }) => {
  const allSelectedKeys = Object.keys(selectedValues);
  const selectedKeys = allSelectedKeys.filter(
    (k) =>
      SYSTEM_FILTERS.indexOf(k) == -1 &&
      DATE_FILTERS.indexOf(k) == -1 &&
      ["dl"].indexOf(k) == -1
  );

  if (!selectedKeys) return <div></div>;
  return (
    <div className="pb-4">
      {selectedKeys.map(
        (key, index) =>
          selectedValues[key].length > 0 && (
            <FilterTitles
              key={index}
              values={selectedValues[key]}
              options={options.filter(
                (filterOption) => filterOption.queryKey == key
              )}
              onRemoveFilterValue={onRemoveFilterValue}
            />
          )
      )}
    </div>
  );
};

export default FilteringTitles;
