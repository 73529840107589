// import { fieldNameFromStoreName } from "@apollo/client/cache";
import React from "react";
import { useBaseField } from "../hooks/coreHooks";

const loadingCss =
  "text-transparent bg-gradient-to-tr from-purple-100 via-purple-50 to-purple-100 animate-pulse opacity-50 bg-white border border-purple-200";

const BaseField = ({
  children,
  label,
  error,
  description,
  gbp,
  isRequired,
  name,
  loading = false,
}) => (
  <div className="-mt-0.5">
    {label && (
      <label
        htmlFor={name}
        className={`text-sm leading-5 text-gray-500 pl-1 select-none ${
          loading && loadingCss
        }`}
      >
        {label}
        {isRequired && !loading && (
          <span className="font-light text-gray-400"> *</span>
        )}
      </label>
    )}
    <div className="mt-0.5 relative">
      {gbp && !loading && (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-400 font-medium select-none">£</span>
        </div>
      )}
      {children}
      {error && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-red-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </div>
    {!error && description && (
      <p
        className={`border border-transparent mt-1.5 text-xs text-gray-400 pl-0.5 select-none ${
          loading && loadingCss
        }`}
      >
        {description}
      </p>
    )}
    {error && (
      <p
        className={`border border-transparent mt-1.5 text-xs text-red-400 pl-0.5 select-none ${
          loading && loadingCss
        }`}
      >
        {error}
      </p>
    )}
  </div>
);

export const ReadOnlyField = ({
  value = undefined,
  label,
  name,
  isRequired = false,
  extraStyles = "",
  loading = false,
}) => (
  <div>
    <label
      htmlFor={name}
      className={`text-sm leading-5 text-gray-500 pl-1 select-none ${
        loading && loadingCss
      }`}
    >
      {label}
      {isRequired && <span className="font-light text-gray-400"> *</span>}
    </label>

    {loading ? (
      <div className={loadingCss + " h-[38px] w-full "}></div>
    ) : (
      <div
        className={`opacity-75 bg-gray-100 out-of-range:bg-red-100 out-of-range:border-red-200 rounded-none form-input block w-full border-[#dfe2e8] text-gray-500 sm:text-sm sm:leading-5  ${extraStyles}`}
      >
        {value}
      </div>
    )}
  </div>
);

export const ReadOnlyFieldExt = ({
  value = undefined,
  label,
  name,
  isRequired = false,
  extraStyles = "",
  loading = false,
  error = false,
}) => (
  <div>
    <label
      htmlFor={name}
      className={`text-sm leading-5 text-gray-500 pl-1 select-none ${
        loading && loadingCss
      }`}
    >
      {label}
      {isRequired && <span className="font-light text-gray-400"> *</span>}
    </label>

    {loading ? (
      <div className={loadingCss + " h-[38px] w-full "}></div>
    ) : (
      <div
        className={`opacity-75 bg-gray-100 out-of-range:bg-red-100 out-of-range:border-red-200 rounded-none form-input block w-full border-[#dfe2e8] text-gray-500 sm:text-sm sm:leading-5  ${extraStyles}`}
      >
        {value}
      </div>
    )}
  </div>
);

export const TextField = (props) => {
  const newProps = useBaseField(props);
  return <BaseTextField {...newProps} />;
};

export const BaseTextField = React.memo((props) => {
  const {
    value = undefined,
    name,
    onChange,
    placeholder,
    error,
    loading = false,
    onEnter = () => {
      return false;
    },
    mode = "text",
    readOnly = false,
    disabled = false,
    min = undefined,
    max = undefined,
    minlength = undefined,
    maxlength = undefined,
    step = 1,
    flat = false,
    gbp = false,
    pattern = undefined,
    title = undefined,
    isRequired = false,
    tweaks = "",
    autocomplete = undefined,
  } = props;
  let cssClass = error
    ? "rounded-none form-input block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red sm:text-sm sm:leading-5"
    : "rounded-none form-input block w-full border-[#dfe2e8] text-gray-500 sm:text-sm sm:leading-5 ";
  cssClass += flat
    ? " border-none invalid:ring-1 invalid:ring-red-300 invalid:ring-inset"
    : "";
  cssClass += " " + tweaks;
  cssClass += readOnly ? " pointer-events-none" : "";
  cssClass += gbp ? " pl-7" : "";
  cssClass += gbp && readOnly ? " pr-0" : "";
  cssClass += disabled ? " select-none opacity-75 bg-[#f4f4fb] " : "";
  cssClass += readOnly ? " select-none opacity-75" : "";
  return (
    <BaseField {...props}>
      {loading ? (
        <div className={loadingCss + " h-[38px] w-full "}></div>
      ) : (
        <input
          id={name}
          name={name}
          type={mode}
          value={value}
          onChange={onChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter(e);
            }
          }}
          className={cssClass + " peer"}
          placeholder={placeholder ? placeholder : ""}
          readOnly={readOnly}
          min={min}
          max={max}
          minLength={minlength}
          maxLength={maxlength}
          step={step}
          disabled={disabled}
          required={isRequired}
          pattern={pattern}
          title={title}
          autoComplete={autocomplete}
        />
      )}
      {flat && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none invisible peer-invalid:visible">
          <svg
            className="h-5 w-5 text-red-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </BaseField>
  );
});

export const NumberField = (props) => {
  const newProps = useBaseField(props);
  return <BaseTextField {...newProps} mode="number" />;
};

export const NumberFieldReadOnly = (props) => {
  const newProps = useBaseField(props);
  return <BaseTextField {...newProps} mode="number" readOnly={true} />;
};

export const DateField = (props) => {
  const newProps = useBaseField(props);
  return <BaseTextField {...newProps} mode="date" />;
};

export const DateTimeField = (props) => {
  const newProps = useBaseField(props);
  return <BaseTextField {...newProps} mode="datetime-local" />;
};

export const SelectField = React.memo((props) => {
  const newProps = useBaseField(props);
  const {
    value,
    values,
    name,
    onChange,
    label = false,
    valueKey,
    labelKey,
    prependOption,
    prependOptionValue = 0,
    description,
    error,
    loading = false,
    readOnly = false,
    isDisabled = false,
    isRequired = false,
    transformText = false,
  } = newProps;
  const disabledCssModifier = isDisabled ? "bg-gray-100 select-none " : "";
  let selectCssClass = error
    ? "rounded-none form-select block text-gray-500 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
    : `rounded-none form-select block text-gray-500 w-full transition border-[#dfe2e8] duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledCssModifier}`;

  selectCssClass += readOnly ? " select-none opacity-75" : "";
  const formatString = (str = "") => {
    if (str.length > 1 && transformText) {
      return (
        str.split("_").join(" ").toUpperCase().charAt(0) +
        str.split("_").join(" ").toLowerCase().slice(1)
      );
    }
    return str;
  };
  return (
    <div className="-mt-0.5">
      {label && (
        <label
          htmlFor={name}
          className={`text-sm leading-5 text-gray-500 pl-1 select-none ${
            loading && loadingCss
          }`}
        >
          {formatString(label)}
          {isRequired && !loading && (
            <span className="font-light text-gray-400"> *</span>
          )}
        </label>
      )}
      <div className="mt-0.5 rounded-none">
        {loading ? (
          <div className={loadingCss + " h-[38px] w-full "}></div>
        ) : (
          <select
            className={selectCssClass}
            name={name}
            value={value}
            onChange={onChange}
            disabled={isDisabled ? "disabled" : ""}
            style={{ cursor: isDisabled ? "not-allowed" : "auto" }}
            placeholder={prependOption || "Select an option..."}
            required={isRequired}
          >
            {prependOption && (
              <option
                key={-1}
                value={prependOptionValue}
                className={"text-gray-700"}
              >
                {prependOption}
              </option>
            )}
            {values.map((option, index) => (
              <option
                key={option[valueKey]}
                value={option[valueKey]}
                className={"text-gray-700 option-index-" + index}
              >
                {formatString(option[labelKey])}
              </option>
            ))}
          </select>
        )}
      </div>
      {!error && description && (
        <p
          className={`border border-transparent mt-1.5 text-xs text-gray-400 pl-0.5 select-none ${
            loading && loadingCss
          }`}
        >
          {description}
        </p>
      )}
      {error && (
        <p
          className={`border border-transparent mt-2 text-xs text-red-600 select-none ${
            loading && loadingCss
          }`}
        >
          {error}
        </p>
      )}
    </div>
  );
});

export const CodeEditorField = (props) => {
  const {
    value,
    name,
    onChange,
    label,
    error,
    loading = false,
    description,
    rows,
    isRequired = false,
    disabled = false,
    readOnly = false,
    minlength = undefined,
    maxlength = undefined,
  } = props;
  let textAreaCssClass = error
    ? "rounded-none form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red "
    : "rounded-none form-textarea block w-full transition duration-150 text-gray-500 ease-in-out sm:text-sm sm:leading-5";
  textAreaCssClass += disabled ? " select-none opacity-75" : "";
  textAreaCssClass += readOnly ? " select-none opacity-75" : "";
  return (
    <div>
      <label
        htmlFor={name}
        className={`text-sm -mb-2 leading-0 text-gray-500 py-2 rounded-none select-none ${
          loading && loadingCss
        } `}
      >
        {label}
      </label>
      <div className="mt-1 rounded-none">
        <textarea
          id={name}
          name={name}
          rows={rows}
          className={textAreaCssClass}
          onChange={onChange}
          value={value}
          minLength={minlength}
          maxLength={maxlength}
          required={isRequired}
        ></textarea>
      </div>
      {!error && description && (
        <p
          className={`border border-transparent mt-1.5 text-xs text-gray-400 pl-0.5 select-none ${
            loading && loadingCss
          }`}
        >
          {description}
        </p>
      )}
      {error && (
        <p
          className={`border border-transparent mt-1.5 text-xs text-red-400 pl-0.5 select-none ${
            loading && loadingCss
          }`}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export const RichTextEditorField = (props) => {
  const newProps = useBaseField(props);
  const {
    value,
    name,
    onChange,
    label,
    error,
    loading = false,
    description,
    rows,
    isRequired = false,
    disabled = false,
    readOnly = false,
    minlength = undefined,
    maxlength = undefined,
  } = newProps;
  let textAreaCssClass = error
    ? "rounded-none form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red "
    : "rounded-none form-textarea block w-full transition text-gray-500 border-[#dfe2e8] duration-150 ease-in-out sm:text-sm sm:leading-5";
  textAreaCssClass += disabled ? " select-none opacity-75" : "";
  textAreaCssClass += readOnly ? " select-none opacity-75" : "";
  return (
    <div className="-mt-0.5">
      <label
        htmlFor={name}
        className={`text-sm leading-5 text-gray-500 pl-1 select-none ${
          loading && loadingCss
        } `}
      >
        {label}
        {isRequired && !loading && (
          <span className="font-light text-gray-400"> *</span>
        )}
      </label>
      <div className="mt-0.5 rounded-none">
        {loading ? (
          <div
            className={loadingCss + ` h-[${18 + rows * 20}px] w-full `}
          ></div>
        ) : (
          <textarea
            id={name}
            name={name}
            rows={rows}
            className={textAreaCssClass}
            onChange={onChange}
            value={value}
            minLength={minlength}
            maxLength={maxlength}
            required={isRequired}
          ></textarea>
        )}
      </div>
      {!error && description && (
        <p
          className={`border border-transparent mt-1.5 text-xs text-gray-400 pl-0.5 select-none ${
            loading && loadingCss
          }`}
        >
          {description}
        </p>
      )}
      {error && (
        <p
          className={`border border-transparent mt-1.5 text-xs text-red-400 pl-0.5 select-none ${
            loading && loadingCss
          }`}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export const ToggleCheckboxField = React.memo(
  ({
    name,
    id,
    label,
    action = null,
    wide = false,
    flush = false,
    description = "",
    isChecked = false,
    onChange,
    loading = false,
  }) => {
    const eventObject = { target: { name, value: id } };
    let boxStyle =
      (isChecked
        ? "select-none sm:col-span-2 border-[#dfe2e8] border overflow-hidden bg-purple-50 cursor-pointer group "
        : "select-none sm:col-span-2 border-[#dfe2e8] border overflow-hidden hover:bg-purple-50 bg-white cursor-pointer group ") +
      (wide
        ? " relative pt-3 pb-3 px-5 flex flex-row"
        : action
        ? " p-4 pb-3"
        : " relative pt-3 pb-4 px-5");

    boxStyle += flush ? " -m-px" : " mt-px";

    const mainStyle = isChecked
      ? "relative inline-block flex-no-shrink h-5 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline bg-indigo-600"
      : "relative inline-block flex-no-shrink h-5 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline bg-gray-200";
    const togglingStyle = isChecked
      ? "relative inline-block h-4 w-4 rounded-full bg-white shadow transform transition ease-in-out duration-200 translate-x-3 -translate-y-0.5"
      : "relative inline-block h-4 w-4 rounded-full bg-white shadow transform transition ease-in-out duration-200 translate-x-0 -translate-y-0.5";

    return loading ? (
      <div
        className={loadingCss + ` h-[${wide ? "58px" : "80px"}] w-full `}
      ></div>
    ) : (
      <div
        className={boxStyle}
        onClick={() => {
          onChange(eventObject);
        }}
      >
        <label
          htmlFor={name}
          className={
            "block text-sm leading-5 text-gray-500 mb-2 truncate cursor-pointer" +
            (wide
              ? " border-r-2 border-purple-300 pl-10 pr-4 mt-1.5 mb-1 w-40 basis-1/5"
              : !action
              ? " ml-9 pr-4s mt-1.5 mb-2 w-fulls grow"
              : "")
          }
        >
          {label}
        </label>
        <div
          className={
            "mt-1" + (wide || !action ? " absolute top-3.5 left-4" : "")
          }
        >
          <span
            className={mainStyle}
            role="checkbox"
            aria-checked={isChecked}
            tabIndex="0"
          >
            <span aria-hidden="true" className={togglingStyle}>
              {!isChecked && (
                <span className="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity cursor-pointer">
                  <svg
                    className="h-3 w-3 text-gray-400"
                    fill="none"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              )}
              {isChecked && (
                <span className="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
                  <svg
                    className="h-3 w-3 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              )}
            </span>
            {action && (
              <div className="normal-case text-[#592373] ml-10 -mt-6 text-xs absolute whitespace-nowrap opacity-50">
                {action}
              </div>
            )}
          </span>
        </div>
        {description && !wide && (
          <div
            className={
              "normal-case h-4 text-xs text-gray-400 relative " +
              (!action ? "" : " mt-1")
            }
          >
            <abbr
              title={description}
              className="absolute truncate transform min-w-full w-full left-0"
            >
              {/* <div className="absolute truncate transform min-w-full w-full left-0 group-hover:left-full group-hover:min-w-fit group-hover:-translate-x-full translate-x-0 transition-all ease duration-700 delay-30rr0"> */}
              {description}
            </abbr>
          </div>
        )}
        {description && wide && (
          <div className="normal-case text-xs shrink text-gray-400 basis-4/5 pl-6">
            <div className="h-min max-h-5 mb-1.5 leading-5 mt-1.5 overflow-hidden hover:overflow-visible h-fit hover:max-h-[100vh] transition-all ease-in-out duration-300 hover:duration-500">
              {description}
            </div>
          </div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (
      prevProps.id !== nextProps.id ||
      prevProps.isChecked !== nextProps.isChecked ||
      prevProps.onChange !== nextProps.onChange
    )
      return false;
    return true;
  }
);
