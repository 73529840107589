import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from "./mood-ui/Navbar";
import useAuthService from "./hooks/authService";

import Content from "./pages/Content";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ClientList from "./pages/ClientList";
import OfferList from "./pages/OfferList";

import ImageGallery from "./pages/ImageGallery";

import LayoutWrapper from "./mood-ui/LayoutWrapper";

import Clients from "./pages/Clients";
import Client from "./pages/Client";
import LandlordCreate from "./pages/LandlordCreate";

import ClientService from "./pages/subPages/ClientService";
import LandlordPrimary from "./pages/subPages/LandlordPrimary";
import LandlordPassword from "./pages/subPages/LandlordPassword";
import LandlordOffers from "./pages/subPages/LandlordOffers";
import LandlordSurveys from "./pages/subPages/LandlordSurveys";
import LandlordPlatform from "./pages/subPages/LandlordPlatform";
import LandlordCustomLinkList from "./pages/subPages/LandlordCustomLinkList";
import LandlordCustomLink from "./pages/subPages/LandlordCustomLink";
import LandlordCustomLinkCreate from "./pages/subPages/LandlordCustomLinkCreate";
import LandlordInvoiceList from "./pages/subPages/LandlordInvoiceList";
import LandlordClientApi from "./pages/subPages/LandlordClientApi";
import LandlordClientEmail from "./pages/subPages/LandlordClientEmail";
import LandlordImportList from "./pages/subPages/LandlordImportList";
import LandlordImportCreate from "./pages/subPages/LandlordImportCreate";
import LandlordImportDetails from "./pages/subPages/LandlordImportDetails";
import LandlordCreateDeprecated from "./pages/LandlordCreateDeprecated";
import LandlordInvoiceView from "./pages/subPages/LandlordInvoiceView";
import LandlordInvoiceEdit from "./pages/subPages/LandlordInvoiceEdit";
import LandlordInvoiceCreate from "./pages/subPages/LandlordInvoiceCreate";
import LandlordAccountingSettings from "./pages/subPages/LandlordAccountingSettings";

import Users from "./pages/Users";

import TagManager from "./pages/TagManager";
import QuestionList from "./pages/QuestionList";
import Question from "./pages/Question";
import QuestionCreate from "./pages/QuestionCreate";
import SurveyList from "./pages/SurveyList";
import Survey from "./pages/Survey";
import SurveyCreate from "./pages/SurveyCreate";
import Settings from "./pages/Settings";
import SettingsSurveys from "./pages/subPages/SettingsSurveys";
import TenantQAFlow from "./pages/reports/TenantQAFlow";
import TenantQAStats from "./pages/reports/TenantQAStats";
import ActivityEvents from "./pages/reports/ActivityEvents";
import RunningACUpdates from "./pages/reports/RunningACUpdates";
import ReportList from "./pages/ReportList";
import SettingsApi from "./pages/subPages/SettingsApi";
import SettingsMain from "./pages/subPages/SettingsMain";
import SectionList from "./pages/SectionList";
import SectionCreate from "./pages/SectionCreate";
import Section from "./pages/Section";
import CinemaChainList from "./pages/CinemaChainList";
import RoleList from "./pages/RoleList";
import RoleCreate from "./pages/RoleCreate";
import Role from "./pages/Role";
import SystemUserList from "./pages/SystemUserList";
import SystemUserCreate from "./pages/SystemUserCreate";
import SystemUser from "./pages/SystemUser";
import TenantInvoiceList from "./pages/subPages/TenantInvoiceList";
import VabooUserLoginList from "./pages/subPages/VabooUserLoginList";
import TenantList from "./pages/TenantList";
import TenantCreate from "./pages/TenantCreate";
import Tenant from "./pages/Tenant";
import TenantPrimary from "./pages/subPages/TenantPrimary";
import TenantPassword from "./pages/subPages/TenantPassword";
import VabooUserBenefitLogs from "./pages/subPages/VabooUserBenefitLogs";
import TenantActivityEvents from "./pages/subPages/TenantActivityEvents";
import TenantAdvancedActions from "./pages/subPages/TenantAdvancedActions";
import TenantBulkOperationList from "./pages/subPages/TenantBulkOperationList";
import TenantBulkOperationCreate from "./pages/subPages/TenantBulkOperationCreate";
import ClientStats from "./pages/subPages/ClientStats";
import TenantPaymentSettings from "./pages/subPages/TenantPaymentSettings";
import TenantInvoiceView from "./pages/subPages/TenantInvoiceView";
import TenantInvoiceEdit from "./pages/subPages/TenantInvoiceEdit";
import TenantInvoiceCreate from "./pages/subPages/TenantInvoiceCreate";
import TenantBulkOperationDetails from "./pages/subPages/TenantBulkOperationDetails";
import Invoices from "./pages/reports/Invoices";
import ProfileSettings from "./pages/subPages/ProfileSettings";
import ProfileFieldCompletions from "./pages/reports/ProfileFieldCompletions";
import ProfileFieldAnswers from "./pages/reports/ProfileFieldAnswers";
import MerchantList from "./pages/MerchantList";
import MerchantCreate from "./pages/MerchantCreate";
import Merchant from "./pages/Merchant";
import TilloOfferList from "./pages/TilloOfferList";
import TilloBrandListApprovalPage from "./pages/TilloBrandListApprovalPage";
import TilloOffer from "./pages/TilloOffer";
import UserOrders from "./pages/reports/UserOrders";
import UserOrder from "./pages/reports/UserOrder";
import PassportList from "./pages/reports/PassportList";
import Passport from "./pages/reports/Passport";

import Offer from "./pages/Offer";
import OfferCreate from "./pages/OfferCreate";

import LandlordDirectRegisterLinkList from "./pages/subPages/DirectRegisterLinkList";
import LandlordDirectRegisterLinkCreate from "./pages/subPages/LandlordDirectRegisterLinkCreate";
import LandlordDirectRegisterLink from "./pages/subPages/LandlordDirectRegisterLink";
import LandlordExtensions from "./pages/subPages/LandlordExtensions";
import CinemaChain from "./pages/CinemaChain";
import CinemaSocietyOrders from "./pages/reports/CinemaSocietyOrders";
import CinemaSocietyOrder from "./pages/reports/CinemaSocietyOrder";
import CinemaProductList from "./pages/CinemaProductList";
import CinemaProduct from "./pages/CinemaProduct";
import CinemaVenueList from "./pages/CinemaVenueList";
import CinemaVenue from "./pages/CinemaVenue";
import MembershipPricing from "./pages/MembershipPricing";
import MembershipVoucherList from "./pages/MembershipVoucherList";
import MembershipVoucherCreate from "./pages/MembershipVoucherCreate";
import MembershipVoucher from "./pages/MembershipVoucher";

function App() {
  const {
    getCurrentUser,
    // logout,
  } = useAuthService();
  // getCurrentUser()
  if (!getCurrentUser()) {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    );
  } else {
    return (
      <React.Fragment>
        <Navbar />

        <Switch>
          <LayoutWrapper
            exact
            path="/clients/:page/:limit/:orderBy/:orderHow/:search"
            layout={Clients}
            component={ClientList}
          />
          <LayoutWrapper
            exact
            path="/client/create"
            layout={Clients}
            component={LandlordCreate}
          />
          <LayoutWrapper
            exact
            path="/client/create-deprecated"
            layout={Clients}
            component={LandlordCreateDeprecated}
          />
          <LayoutWrapper
            exact
            path="/clients/:id"
            layout={Client}
            component={ClientStats}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/imports/create"
            layout={Client}
            component={LandlordImportCreate}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/imports/:page/:limit/:orderBy/:orderHow/:search"
            layout={Client}
            component={LandlordImportList}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/imports/:import_id/view"
            layout={Client}
            component={LandlordImportDetails}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/custom-links/:page/:limit/:orderBy/:orderHow/:search"
            layout={Client}
            component={LandlordCustomLinkList}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/direct-register-links/:page/:limit/:orderBy/:orderHow/:search"
            layout={Client}
            component={LandlordDirectRegisterLinkList}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/bulk-operations/:page/:limit/:orderBy/:orderHow/:search"
            layout={Client}
            component={TenantBulkOperationList}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/bulk-operations/create"
            layout={Client}
            component={TenantBulkOperationCreate}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/bulk-operations/:bulk_operation_id/view"
            layout={Client}
            component={TenantBulkOperationDetails}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/invoices/:page/:limit/:orderBy/:orderHow/:search"
            layout={Client}
            component={LandlordInvoiceList}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/invoices/create"
            layout={Client}
            component={LandlordInvoiceCreate}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/invoices/:invoice_id/view"
            layout={Client}
            component={LandlordInvoiceView}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/invoices/:invoice_id"
            layout={Client}
            component={LandlordInvoiceEdit}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/logins/:page/:limit/:orderBy/:orderHow/:search"
            layout={Client}
            component={VabooUserLoginList}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/custom-links/create"
            layout={Client}
            component={LandlordCustomLinkCreate}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/custom-links/:clId"
            layout={Client}
            component={LandlordCustomLink}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/direct-register-links/create"
            layout={Client}
            component={LandlordDirectRegisterLinkCreate}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/direct-register-links/:drlId"
            layout={Client}
            component={LandlordDirectRegisterLink}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/api"
            layout={Client}
            component={LandlordClientApi}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/extensions"
            layout={Client}
            component={LandlordExtensions}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/emails"
            layout={Client}
            component={LandlordClientEmail}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/payment-settings"
            layout={Client}
            component={LandlordAccountingSettings}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/membership-pricing-setup"
            layout={Client}
            component={MembershipPricing}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/password"
            layout={Client}
            component={LandlordPassword}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/offers"
            layout={Client}
            component={LandlordOffers}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/surveys"
            layout={Client}
            component={LandlordSurveys}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/profile-qa-setup"
            layout={Client}
            component={ProfileSettings}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/platform"
            layout={Client}
            component={LandlordPlatform}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/company"
            layout={Client}
            component={LandlordPrimary}
          />
          <LayoutWrapper
            exact
            path="/clients/:id/service"
            layout={Client}
            component={ClientService}
          />

          <LayoutWrapper
            exact
            path="/users/:page/:limit/:orderBy/:orderHow/:search"
            layout={Users}
            component={TenantList}
          />
          <LayoutWrapper
            exact
            path="/user/create"
            layout={Users}
            component={TenantCreate}
          />
          {/* <Route
            exact
            path="/users/:page/:limit/:orderBy/:orderHow/:search"
            component={TenantList}
          />
          <Route exact path="/user/create" component={TenantCreate} /> */}
          <LayoutWrapper
            exact
            path="/users/:id/invoices/:page/:limit/:orderBy/:orderHow/:search"
            layout={Tenant}
            component={TenantInvoiceList}
          />
          <LayoutWrapper
            exact
            path="/users/:id/invoices/create"
            layout={Tenant}
            component={TenantInvoiceCreate}
          />
          <LayoutWrapper
            exact
            path="/users/:id/invoices/:invoice_id/view"
            layout={Tenant}
            component={TenantInvoiceView}
          />
          <LayoutWrapper
            exact
            path="/users/:id/invoices/:invoice_id"
            layout={Tenant}
            component={TenantInvoiceEdit}
          />
          <LayoutWrapper
            exact
            path="/users/:id/logins/:page/:limit/:orderBy/:orderHow/:search"
            layout={Tenant}
            component={VabooUserLoginList}
          />
          <LayoutWrapper
            exact
            path="/users/:id/benefit-logs/:page/:limit/:orderBy/:orderHow/:search"
            layout={Tenant}
            component={VabooUserBenefitLogs}
          />
          <LayoutWrapper
            exact
            path="/users/:id/activity-events/:page/:limit/:orderBy/:orderHow/:search"
            layout={Tenant}
            component={TenantActivityEvents}
          />
          <LayoutWrapper
            exact
            path="/users/:id/password"
            layout={Tenant}
            component={TenantPassword}
          />
          <LayoutWrapper
            exact
            path="/users/:id/payment-settings"
            layout={Tenant}
            component={TenantPaymentSettings}
          />
          <LayoutWrapper
            exact
            path="/users/:id/advanced-actions"
            layout={Tenant}
            component={TenantAdvancedActions}
          />
          <LayoutWrapper
            exact
            path="/users/:id"
            layout={Tenant}
            component={TenantPrimary}
          />

          <LayoutWrapper
            exact
            path="/offers/:page/:limit/:orderBy/:orderHow/:search"
            layout={Content}
            component={OfferList}
          />
          <LayoutWrapper
            exact
            path="/offer/create"
            layout={Content}
            component={OfferCreate}
          />
          <LayoutWrapper
            exact
            path="/offers/:id"
            layout={Content}
            component={Offer}
          />
          <LayoutWrapper
            exact
            path="/cards/:page/:limit/:orderBy/:orderHow/:search"
            layout={Content}
            component={TilloOfferList}
          />
          <LayoutWrapper
            exact
            path="/cards/:id"
            layout={Content}
            component={TilloOffer}
          />
          <LayoutWrapper
            exact
            path="/card/approve"
            layout={Content}
            component={TilloBrandListApprovalPage}
          />
          <LayoutWrapper
            exact
            path="/merchants/:page/:limit/:orderBy/:orderHow/:search"
            layout={Content}
            component={MerchantList}
          />
          <LayoutWrapper
            exact
            path="/merchants/create"
            layout={Content}
            component={MerchantCreate}
          />
          <LayoutWrapper
            exact
            path="/merchants/:id"
            layout={Content}
            component={Merchant}
          />
          <LayoutWrapper
            exact
            path="/sections/:page/:limit/:orderBy/:orderHow/:search"
            layout={Content}
            component={SectionList}
          />
          <LayoutWrapper
            exact
            path="/sections/create"
            layout={Content}
            component={SectionCreate}
          />
          <LayoutWrapper
            exact
            path="/sections/:id"
            layout={Content}
            component={Section}
          />
          <LayoutWrapper
            exact
            path="/cinema-chains/:page/:limit/:orderBy/:orderHow/:search"
            layout={Content}
            component={CinemaChainList}
          />
          <LayoutWrapper
            exact
            path="/cinema-chains/:id"
            layout={Content}
            component={CinemaChain}
          />
          <LayoutWrapper
            exact
            path="/cinema-venues/:page/:limit/:orderBy/:orderHow/:search"
            layout={Content}
            component={CinemaVenueList}
          />
          <LayoutWrapper
            exact
            path="/cinema-venues/:id"
            layout={Content}
            component={CinemaVenue}
          />
          <LayoutWrapper
            exact
            path="/cinema-products/:page/:limit/:orderBy/:orderHow/:search"
            layout={Content}
            component={CinemaProductList}
          />
          <LayoutWrapper
            exact
            path="/cinema-products/:id"
            layout={Content}
            component={CinemaProduct}
          />
          <LayoutWrapper
            exact
            path="/membership-vouchers/:page/:limit/:orderBy/:orderHow/:search"
            layout={Content}
            component={MembershipVoucherList}
          />
          <LayoutWrapper
            exact
            path="/membership-vouchers/create"
            layout={Content}
            component={MembershipVoucherCreate}
          />
          <LayoutWrapper
            exact
            path="/membership-vouchers/:id"
            layout={Content}
            component={MembershipVoucher}
          />
          <LayoutWrapper
            exact
            path="/tags/:search"
            layout={Content}
            component={TagManager}
          />
          <LayoutWrapper
            exact
            path="/images/:page/:limit/:orderBy/:orderHow/:search"
            layout={Content}
            component={ImageGallery}
          />

          <Route
            exact
            path="/questions/:page/:limit/:orderBy/:orderHow/:search"
            component={QuestionList}
          />
          <Route exact path="/questions/create" component={QuestionCreate} />
          <Route exact path="/questions/:id" component={Question} />
          <Route
            exact
            path="/surveys/:page/:limit/:orderBy/:orderHow/:search"
            component={SurveyList}
          />
          <Route exact path="/surveys/create" component={SurveyCreate} />
          <Route exact path="/surveys/:id" component={Survey} />
          <LayoutWrapper
            exact
            path="/settings/surveys"
            layout={Settings}
            component={SettingsSurveys}
          />
          <LayoutWrapper
            exact
            path="/settings/profile-qa-setup"
            layout={Settings}
            component={ProfileSettings}
          />

          <Route
            exact
            path="/reports/:page/:limit/:orderBy/:orderHow/:search"
            component={ReportList}
          />
          <Route
            exact
            path="/reports/user-orders/:page/:limit/:orderBy/:orderHow/:search"
            component={UserOrders}
          />
          <Route exact path="/reports/user-orders/:id" component={UserOrder} />
          <Route
            exact
            path="/reports/passports/:page/:limit/:orderBy/:orderHow/:search"
            component={PassportList}
          />
          <Route
            exact
            path="/reports/cinema-society-orders/:page/:limit/:orderBy/:orderHow/:search"
            component={CinemaSocietyOrders}
          />
          <Route
            exact
            path="/reports/cinema-society-orders/:id"
            component={CinemaSocietyOrder}
          />
          <Route exact path="/reports/passports/:id" component={Passport} />
          <Route
            exact
            path="/reports/tenant-qa-flow/:page/:limit/:orderBy/:orderHow/:search"
            component={TenantQAFlow}
          />
          <Route
            exact
            path="/reports/tenant-qa-stats/:page/:limit/:orderBy/:orderHow/:search"
            component={TenantQAStats}
          />
          <Route
            exact
            path="/reports/activity-events/:page/:limit/:orderBy/:orderHow/:search"
            component={ActivityEvents}
          />
          <Route
            exact
            path="/reports/invoices/:page/:limit/:orderBy/:orderHow/:search"
            component={Invoices}
          />
          <Route
            exact
            path="/reports/running-active-campaign-updates"
            component={RunningACUpdates}
          />
          <Route
            exact
            path="/reports/profile-field-answers/:client_id/:field_name"
            component={ProfileFieldAnswers}
          />
          <Route
            exact
            path="/reports/profile-field-completions/:client_id"
            component={ProfileFieldCompletions}
          />

          <LayoutWrapper
            exact
            path="/settings/membership-pricing-setup"
            layout={Settings}
            component={MembershipPricing}
          />

          <LayoutWrapper
            exact
            path="/settings/api"
            layout={Settings}
            component={SettingsApi}
          />
          <LayoutWrapper
            exact
            path="/settings"
            layout={Settings}
            component={SettingsMain}
          />
          <LayoutWrapper
            exact
            path="/settings/roles/:page/:limit/:orderBy/:orderHow/:search"
            layout={Settings}
            component={RoleList}
          />
          <LayoutWrapper
            exact
            path="/settings/roles/create"
            layout={Settings}
            component={RoleCreate}
          />
          <LayoutWrapper
            exact
            path="/settings/roles/:id"
            layout={Settings}
            component={Role}
          />
          <LayoutWrapper
            exact
            path="/settings/control-application-users/:page/:limit/:orderBy/:orderHow/:search"
            layout={Settings}
            component={SystemUserList}
          />
          <LayoutWrapper
            exact
            path="/settings/control-application-users/create"
            layout={Settings}
            component={SystemUserCreate}
          />
          <LayoutWrapper
            exact
            path="/settings/control-application-users/:id"
            layout={Settings}
            component={SystemUser}
          />

          <Route exact path="/" component={Dashboard} />
          <Redirect to="/" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
