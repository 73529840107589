import { useEffect, useState } from "react";

const store = {};

const useHyperState = (name, initialState = {}) => {
  const [timesRendered, setTimesRendered] = useState(0);
  useEffect(() => {
    store[name] = { ...initialState };
    /*
     * NOTA BENE.
     * Maybe remove syncView from here and just use syncView inside components.
     * But let it be here for now.
     */
    syncView();
    return () => {
      // console.log("cleaning store...");
      store[name] = {};
    };
  }, []);

  const setState = (newState) => {
    store[name] = { ...newState };
    syncView();
  };

  const setStateSilently = (newState) => {
    store[name] = { ...newState };
  };

  const setValue = (field, value) => {
    store[name][field] = value;
  };

  const deleteValue = (field) => {
    delete store[name][field];
  };

  const setArrayObjectProperty = (arrayId, index, field, value) => {
    store[name][arrayId][index][field] = value;
  };

  const syncView = () => {
    setTimesRendered(timesRendered + 1);
    // console.log("rendering page component", timesRendered);
  };

  return {
    state: store[name],
    setState,
    setValue,
    syncView,
    setStateSilently,
    deleteValue,
    setArrayObjectProperty,
    stateMethods: {
      setState,
      setValue,
      syncView,
      deleteValue,
      setArrayObjectProperty,
    },
  };
};

export default useHyperState;
