import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { 
  // useQuery, 
  useMutation, 
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createCustomLinkMutation } from "./../../graphql/mutations/landlordMutations";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useFormErrors from "./../../hooks/formErrors";
import useHyperState from "./../../hooks/hyperState";
import { CUSTOM_LINK_TYPE_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../../mood-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
} from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import {
  TextField,
  NumberField,
  ToggleCheckboxField,
} from "./../../mood-ui/Fields";
import { LabelContext } from "../../contexts/LabelContext";

const CREATE_CUSTOM_LINK = gql(createCustomLinkMutation);

const LandlordCustomLinkCreate = () => {
  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const { id: landlordId } = useParams();
  const labelContext = useContext(LabelContext);

  const { goBack } = useBaseNavigation(CUSTOM_LINK_TYPE_MODEL_NAME, {
    urlContext: `clients/${landlordId}`,
  });

  const {
    state: formData,
    // setState: setFormData,
    stateMethods,
    syncView, 
  } = useHyperState("formData", {
    owner_id: landlordId,
    text: "",
    color: "red",
    icon: "mdi-wrench",
    href: "https://",
    external: true,
    display_order: 1,
    labels: []
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
    toggleArrayFieldValue: toggleListValue,
    arrayFieldHasValue: listInclude,
  } = useBaseFieldSetters(formData, stateMethods, clearError);
  
  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    syncView()
    handleFieldOnChangeProxied(e)
  }

  const [
    createCustomLink,
    { 
      // data: updateResult, 
      loading: isUpdating, 
      // error: updateError 
    },
  ] = useMutation(CREATE_CUSTOM_LINK, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    createCustomLink({ variables: data });
  };

  const [ isDraft, setDraft ] = useState(false)

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Create a new custom link"
              description="Custom links are shown in the side menu of the member platform."
              />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Link settings"
                      description="Control the appearance and behaviour of this link. Use text or an icon or both."
                    />
                    <p className="text-xs text-[#592373] opacity-60 underline underline-offset-4 mt-2 mb-7 select-none">
                      <a href={`https://pictogrammers.com/library/mdi/`} rel="noreferrer" target="_BLANK">Browse icon list</a>
                    </p>
                    <button 
                      style={{ background: formData?.color, color: 'white' }}
                      className={`rounded mt-4 justify-center py-2 px-3 border border-transparent text-sm leading-2 pointer-events-none flex gap-x-2`}
                    >
                      {formData?.icon && formData?.icon !== '' && (
                        <span className={`text-[17px] mdi ${ formData?.icon }`}></span>
                      )}
                      {formData?.text}
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row tweaks="gap-y-3">
                  <Col width={4} extraStyles="">
                    <TextField
                      loading={!formData}
                      name="text"
                      label="Button text"
                      value={formData?.text}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("text")}
                      maxlength="24"
                      description="Keep as short as possible"
                    />
                  </Col>
                  <Col width={3} extraStyles="">
                    <TextField
                      loading={!formData}
                      name="icon"
                      label="Button icon"
                      value={formData?.icon}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("icon")}
                      minlength="5"
                      maxlength="42"
                      pattern="^(mdi-)[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?"
                      description="Use 'mdi-' + 'icon_name'"
                    />
                  </Col>
                  <Col width={3} extraStyles="">
                    <TextField
                      loading={!formData}
                      name="color"
                      label="Button colour"
                      value={formData?.color}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("color")}
                      isRequired={true}
                      minlength="3"
                      maxlength="24"
                      pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$|[a-z]{3,24}?"
                      description="Colour name or hex code"
                    />
                  </Col>
                  <Col width={2} extraStyles="">
                    <NumberField
                      loading={!formData}
                      name="display_order"
                      label="List order"
                      value={formData?.display_order}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("display_order")}
                      isRequired={true}
                      min={1}
                      max={64}
                      description="Position in list"
                    />
                  </Col>
                  <Col width={9} extraStyles="">
                    <TextField
                      loading={!formData}
                      name="href"
                      label="Link url"
                      value={formData?.href}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("href")}
                      isRequired={true}
                      maxlength="1000"                      
                      pattern={`^(tel:|TEL:)(\\+)?([0-9]{9,16})|^(mailto:|MAILTO:)([a-zA-Z0-9-_\.]+)@([a-zA-Z0-9-_\.]+)\\.([a-zA-Z]{2,8})(\.[a-zA-Z]{2,8})?|^(https://|HTTPS://)([a-zA-Z0-9-_]+)\\.([\\[\\])\(\#\!\*\+\^\$\'\"\%\@\?\/\=\&\.a-zA-Z0-9-_]{2,1000})|^[\/]([a-zA-Z0-9-_]+)(\/[a-zA-Z-_0-9]+)*$`}
                      description="/reward-cards, https://external.com, mailto:you@me.co, tel:+447~"
                    />
                  </Col>
                  <Col width={3}>
                    <ToggleCheckboxField
                      loading={!formData}
                      id="external"
                      name="external"
                      label="External"
                      description="Open link in new tab"
                      isChecked={formData?.external}
                      onChange={(e) => {handleToggleCheckbox(e); setDraft(true); }}
                    />
                  </Col>
                  
                </Row>
              </Col>
            </Row> 
            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Link visibility"
                      description="Controls who can see this link in the member platform."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row tweaks="gap-y-3">
                  <Col width={12} extraStyles="">
                    {!formData 
                    ? ( <PageSpinner inline extraStyles="h-[300px]" /> )
                    : ((labelContext?.labels && labelContext?.labels?.length) && (
                        <Row tweaks="gap-0 sm:grid-cols-5 py-1 px-px">
                          {labelContext?.labels?.map((label) => (
                            <Col key={label.id} extraStyles="capitalize sm:col-span-1">
                              <ToggleCheckboxField
                                loading={!formData}
                                id={label.id}
                                name="labels"
                                label={label.name}
                                isChecked={listInclude('labels', label.id)}
                                onChange={() => { toggleListValue('labels', label.id); setDraft(true) }}
                                flush
                                description="Enable for type"
                              />
                            </Col>
                          ))}
                        </Row>
                      )
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>  
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} disabled={!isDraft || formHasErrors()} loading={isUpdating} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default LandlordCustomLinkCreate;
