import React, { useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allReports } from "./../graphql/queries/reportQueries";
import useBaseNavigation from "./../hooks/baseNavigation";
import useTypeFilters from "./../hooks/typeFilters";
import { REPORT_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../moose-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../moose-ui/Layout";
import { TEditButton, AddButton } from "./../moose-ui/Controls";
import { Heading, ItemsNotFound } from "./../moose-ui/Typography";
import Search from "./../moose-ui/Search";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableActions,
  TableSelectedActions,
  Pagination,
} from "./../moose-ui/DataTable";
import TypeFilters from "./../moose-ui/filters/TypeFilters";

const ALL_REPORTS = gql(allReports);

const ReportList = () => {
  
  document.title = 'Reports'

  const { page, limit, orderBy, orderHow, search } = useParams();
  const { search: queryString } = useLocation();
  const { data, loading, error } = useQuery(ALL_REPORTS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { nextPage, prevPage, goTo, applySearch, applyFilters, resetFilters } =
    useBaseNavigation(REPORT_MODEL_NAME);

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    resetFilters: onResetFilters,
    removeFilterValue: onRemoveFilterValue,
    applyFilters: onApplyFilters,
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "report",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: queryString,
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        {loading && <Heading text="Reports. Loading..." />}
        {!loading && <Heading text="Reports" />}
        <Row>
          <Col width={12}>
            <TypeFilters
              data={typeFilters}
              onApply={onApplyFilters}
              onReset={onResetFilters}
              onRemoveValue={onRemoveFilterValue}
              hasActiveFilters={hasActiveFilters}
              onUpdate={onUpdateFilter}
              selectedFilters={selectedFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col width={9}></Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>

        {data && data.reports && data.reports.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol name="id" value="ID" />
                  <THCol name="name" value="Name" />
                  <THCol name="type" value="Type" />
                  <THCol />
                </TRow>
              </THeading>
              <TContent>
                {data.reports.map((report) => (
                  <TRow key={report.id}>
                    <TCol value={report.id} />
                    <TCol value={report.name} />
                    <TCol value={report.type} />
                    <TCol>
                      <TEditButton
                        onClick={() => {
                          goTo(`/${REPORT_MODEL_NAME}/${report.url}`);
                        }}
                        label="View"
                      />
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            </TableView>
            <TableActions>
              <TableSelectedActions></TableSelectedActions>
              <Pagination onPrev={prevPage} onNext={nextPage} />
            </TableActions>
          </TableContainer>
        )}
        {data && data.reports && data.reports.length == 0 && (
          <ItemsNotFound text="Reports not found" />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default ReportList;
