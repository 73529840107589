import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allRoles } from "./../graphql/queries/rolePermissionQueries";
import { createSystemUserMutation } from "./../graphql/mutations/rolePermissionMutation";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import { SYSTEM_USER_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../mood-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "./../mood-ui/Controls";
import { Heading } from "./../mood-ui/Typography";
import { TextField, ToggleCheckboxField } from "./../mood-ui/Fields";

const ALL_ROLES = gql(allRoles);
const CREATE_SYSTEM_USER = gql(createSystemUserMutation);

const SystemUserCreate = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.CreateSystemUser);
  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const { goBack } = useBaseNavigation(SYSTEM_USER_MODEL_NAME, {
    urlContext: "settings",
  });

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    name: "",
    email: "",
    password: "",
    roleIds: [],
  });

  const {
    setFieldValue: handleFieldOnChange,
    toggleArrayFieldValue,
    arrayFieldHasValue,
  } = useBaseFieldSetters(formData, stateMethods, clearError);
  const { data: roles } = useQuery(ALL_ROLES, {
    variables: {
      page: 1,
      limit: 1000,
      orderBy: 0,
      orderHow: 0,
      search: 0,
    },
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log("e", e);
    },
  });

  const [createSystemUser] = useMutation(CREATE_SYSTEM_USER, {
    variables: { ...formData },
    onCompleted: (result) => {
      setDraft(d => false)
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    createSystemUser({ variables: data });
  };
  
  const [ isDraft, setDraft ] = useState(false)

  // if (!formData || !roles) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
      <Row tweaks="border-b border-[#dfe2e8] pb-8 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Create a system user"
              description="Vaboo team members must use their own user account to access Control. 
                          Each account must use appropriate 'roles' to define what it can access. Feel free to 
                          create a new role if an existing role doesn't meet your requirements. Remove roles 
                          from system accounts if they are no longer needed."
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-8 mx-5">
              <Col width={4}>
                <TextField
                  loading={!roles}
                  name="name"
                  label="Name"
                  value={formData?.name}
                  onChange={handleFieldOnChange}
                  error={fieldError("name")}
                  isRequired={true}
                />
              </Col>
              <Col width={4}>
                <TextField
                  loading={!roles}
                  name="email"
                  label="Email"
                  value={formData?.email}
                  onChange={handleFieldOnChange}
                  error={fieldError("email")}
                  isRequired={true}
                />
              </Col>
              <Col width={4}>
                <TextField
                  loading={!roles}
                  name="password"
                  label="Password"
                  value={formData?.password}
                  onChange={handleFieldOnChange}
                  error={fieldError("password")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row tweaks="py-8 px-5 gap-0 pb-4">
              {!roles && (
                <Col width={12} extraStyles="h-[330px]">
                  <PageSpinner fill />
                </Col>
              )}
              {roles?.roles
              .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
              .map((role, rIdx) => (
                <Col width={12} key={rIdx}>
                  <ToggleCheckboxField
                    id={role.id}
                    label={role.name}
                    wide={true}
                    flush={true}
                    description={`Included permissions: ${role.permissions
                      .map((permission) => permission.code)
                      .join(", ")}`}
                    isChecked={arrayFieldHasValue("roleIds", role.id)}
                    onChange={(e) => {
                      toggleArrayFieldValue("roleIds", e.target.value);
                      setDraft(d => true)
                    }}
                  />
                </Col>
              ))}
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} disabled={ !(isDraft == true && (formData.name !== '' && formData.email !== '' && formData.password !== '')) } />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default SystemUserCreate;
