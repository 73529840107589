import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { dynamicListHeatmap } from "./../../graphql/queries/listQueries";
import Moment from "react-moment";
import moment from 'moment';
import { Line } from "react-chartjs-2";
import { truncateNumber } from "./../../hooks/utils";

const LIST_SNAPSHOTS = gql(dynamicListHeatmap);

const weekdays = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', ]

const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', ]

const daysNeeded = 365

var DETAIL_KEY = ''

const colors = [
  { hex: '#FF0341', rgb: '255,3,65',    name: 'Red',        }, 
  { hex: '#28DF31', rgb: '40,223,49',   name: 'Green',      }, 
  { hex: '#3115FF', rgb: '49,21,255',   name: 'Blue',       }, 
  { hex: '#FF9500', rgb: '255,149,0',   name: 'Orange',     }, 
  { hex: '#ECE702', rgb: '236,231,2',   name: 'Yellow',     }, 
  { hex: '#EA66CA', rgb: '234,102,202', name: 'Pink',       }, 
  { hex: '#9F1AFB', rgb: '159,26,251',  name: 'Purple',     }, 
  { hex: '#B31217', rgb: '179,18,23',   name: 'Dark red',   }, 
  { hex: '#0000B7', rgb: '0,0,183',     name: 'Dark blue',  }, 
  { hex: '#16931A', rgb: '22,147,26',   name: 'Dark green', }, 
  { hex: '#FF9500', rgb: '255,149,0',   name: 'Orange',     }, 
  { hex: '#EA66CA', rgb: '234,102,202', name: 'Pink',       }, 
]

const snapshotDetailsTemplate = {
  range: [ null, null ], 
  range_values: null, 
  days: 0, start_index: null, end_index: null, 
  added: 0, removed: 0, activity: 0, 
  initial_count: 0, count: 0, avg_count: 0, list_growth: 0, 
  is_ready: false, 
}

const getRange = (count) => {
  return Array.from({ length: count }, (_, i) => i);
}

const shiftDate = (date, numDays) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

const generateDays = (newDays) => {
  const daysDiff   = daysNeeded - newDays.length
  const today      = new Date(newDays.length > 0 ? newDays[newDays.length - 1].uid : null);
  const neededDays = getRange(daysDiff).map((index) => {
    let date = shiftDate(today, -daysNeeded + index + 1)
    return {
      date: date, 
      day: date.getDate(), 
      weekday: weekdays[date.getDay()],
      month: months[date.getMonth()], 
      added: 0, removed: 0, activity: 0, 
      count: 0, list_growth: 0, 
    };
  })
  return [ ...neededDays, ...newDays ]
}

const relativeOpacityInRange = (value = null, min = null, max = null, avg = null) => {
  let relMax = Math.max(Math.abs(max), Math.abs(min)) - min
  let relValue = Math.abs(value) - min
  return Math.floor((relValue / relMax) * 10000) / 10000
}

const getValuePosInSortedRange = (snapshots = [], key = null, range_values = null) => {
  if (!key || !snapshots.length) return snapshots
  let invalidSnapshots = []
  let sortedSnapshots = []
  snapshots.forEach(snapshot => {
    if (snapshot.uid) sortedSnapshots.push(snapshot)
    else invalidSnapshots.push(snapshot)
  })
  invalidSnapshots.map(snapshot => { snapshot[`${ key }_pos`] = null })
  sortedSnapshots.sort((a, b) => b[key] - a[key]).map((snapshot, index) => { snapshot[`${ key }_pos`] = index })
  return [ ...invalidSnapshots, ...sortedSnapshots ].sort((a, b) => a.pos - b.pos) || snapshots
}

const enrichSnapshots = (snapshots = []) => {
  const valuesToAdd = [ 'initial_count', 'count', 'list_growth', 'added', 'removed', 'activity']
  let enrichedSnapshots = [ ...snapshots ]
  let range_values = { 
    range_size: snapshots.length || 0, 
    initial_count: null, valid_size: 0, 
    start_date: snapshots[0]?.date || 0, 
    end_date:   snapshots[snapshots.length - 1]?.date || 0, 
  }
  valuesToAdd.forEach(key => {
    range_values[`min_${ key }`] =  10000000 
    range_values[`max_${ key }`] = -10000000 
    range_values[`sum_${ key }`] =  0 
    range_values[`avg_${ key }`] =  0 
  })
  enrichedSnapshots.map((snapshot, index) => {
    snapshot.pos               = index
    snapshot.valid_pos         = snapshot.uid ? range_values.valid_size || 0 : null
    range_values.valid_size    = snapshot.uid ? range_values.valid_size + 1  : null
    range_values.initial_count = snapshot.uid && !range_values.initial_count ? snapshot.initial_count  : range_values.initial_count
    valuesToAdd.forEach(key => {
      if (snapshot.uid) {
        range_values[`min_${ key }`] = Math.min(snapshot[key], range_values[`min_${ key }`])
        range_values[`max_${ key }`] = Math.max(snapshot[key], range_values[`max_${ key }`])
        range_values[`sum_${ key }`] = snapshot[key] + range_values[`sum_${ key }`]         
      }
    })
  })
  valuesToAdd.forEach(key => {
    range_values[`avg_${ key }`] = Math.floor((range_values[`sum_${ key }`] / range_values.valid_size) * 100) / 100
    enrichedSnapshots            = getValuePosInSortedRange(enrichedSnapshots, key, range_values)
  })
  enrichedSnapshots.forEach(snapshot => {
    valuesToAdd.forEach(key => {
      snapshot[`${ key }_weight`]  = snapshot.uid ? Math.abs(1 - (Math.floor(snapshot[`${ key }_pos`] / range_values.valid_size * 10000) / 10000)) : null
      snapshot[`${ key }_opacity`] = snapshot.uid ? relativeOpacityInRange(snapshot[key], range_values[`min_${ key }`], range_values[`max_${ key }`], range_values[`avg_${ key }`]) : null
    })
    snapshot.range_values = range_values
  })
  return { snapshots: enrichedSnapshots, range_values: range_values }
}

const getSnapshotColor = (list_growth, value) => {
  return value    === 0 ? '[#b9c6da]' 
    : list_growth == 0  ? 'amber-500' 
    : list_growth < 0   ? '[#FF0000]' 
    : 'emerald-600'
}
const getSnapshotOpacity = (opacity = null) => {
  return opacity === null ? 5 : Math.floor(opacity * 60) + 39
}

const calcSnapshotRange = (snapshots, detailsObj) => {
  let tempDetailsObj = {
    ...detailsObj,
    is_ready: true, 
    locked: false, 
  }
  const tempSnapshots = JSON.parse(JSON.stringify(snapshots))
  const enrichedSnapshots = enrichSnapshots(tempSnapshots)
  tempDetailsObj = { ...tempDetailsObj, ...enrichedSnapshots.range_values, snapshots: [...enrichedSnapshots.snapshots] }
  return tempDetailsObj
}

const DynamicListsDetail = ({
  // dynamicListsAreLoading, 
  activeList: oldActiveList, 
  onHasSnapshots = () => {}, 
}) => {

  // const { 
  //   protectPage, 
  //   // hasAccess, 
  // } = usePermissions();
  // protectPage(Permissions.Query.Clients);  

  const [ heatmapScale, setHeatmapScale ]         = useState('SIZE')
  const [ lineChartScale, setLineChartScale ]     = useState('COUNT')
  const [ activeList, setActiveList ]             = useState(oldActiveList)
  const [ dailySnapshots, setDailySnapshots ]     = useState([]) 
  const [ hoverDate, setHoverDate ]               = useState(undefined)
  const [ snapshotDetails, setSnapshotDetails ]   = useState(snapshotDetailsTemplate)
  const [ comparisonRanges, setComparisonRanges ] = useState(null)
  const [ rangeSelected, setRangeSelected ]       = useState(-1)
  const [ loaded, setLoaded ]                     = useState(false)

  const { 
    // data, 
    // error, 
    loading,
    refetch, 
  } = useQuery(LIST_SNAPSHOTS, {
    variables: {
      id: oldActiveList.id, 
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data?.dynamicListHeatmap?.daily_snapshots?.length > 0) {
        onHasSnapshots(true)
      }
      const newDays = data?.dynamicListHeatmap?.daily_snapshots?.map((d) => { 
        let date = new Date(d.uid)
        const snapshotDay = { 
          ...d, 
          date: date, 
          day: date.getDate(), 
          month: months[date.getMonth()], 
          weekday: weekdays[date.getDay()], 
          activity: d.added + d.removed, 
          list_growth: d.added - d.removed, 
          initial_count: d.count - d.added + d.removed, 
        } 
        return snapshotDay
      }) || [];
      setActiveList(oldActiveList)
      DETAIL_KEY = oldActiveList.id
      const correctAmount = generateDays(newDays)
      const correctValues = enrichSnapshots(correctAmount)
      setDailySnapshots(correctValues.snapshots);
    },
  });

  useEffect(() => {
    if (activeList.id !== oldActiveList.id) {
      setHoverDate(undefined)
      setSnapshotDetails({ ...snapshotDetailsTemplate })
      refetch()
    }
  }, [oldActiveList]);

  const today = new Date(dailySnapshots.length > 0 ? dailySnapshots[dailySnapshots.length - 1].uid : null);

  const day = today.getDay() || 0

  const offset = [...Array(day)].map((_, i) =>  i)
  
  const tail = [...Array(Math.abs(day + 1 - 7))].map((_, i) =>  i)

  const heatmap = useRef()

  const toggleHeatmapScale = (force = false, index = null) => {
    if (index !== null && (index !== rangeSelected || (index === rangeSelected && force === heatmapScale))) toggleRangeSelected(index)
    if (force) setHeatmapScale(force)
    if (index !== null && !force) toggleRangeSelected(index)
    if (force == false && index === null) {
      if      (heatmapScale === 'ACTIVITY') { setHeatmapScale('CHANGE') }
      else if (heatmapScale === 'CHANGE')   { setHeatmapScale('SIZE') }
      else if (heatmapScale === 'SIZE')     { setHeatmapScale('ADDED') }
      else if (heatmapScale === 'ADDED')    { setHeatmapScale('REMOVED') }
      else { setHeatmapScale('ACTIVITY') }
    }
  }

  const toggleLineChartScale = () => {
    if (lineChartScale === 'COUNT')    { setLineChartScale('DRIFT') }
    else { setLineChartScale('COUNT') }
  }

  useEffect(() => {
    if (snapshotDetails?.range[0] === null && snapshotDetails?.range[1] === null) {      
      setSnapshotDetails({ ...snapshotDetailsTemplate })
    }
  }, []);

  const storeComparisonRange = () => {
    const range_size  = Math.max(comparisonRanges?.range_size || 0, snapshotDetails.days)
    const color_map   = comparisonRanges?.color_map || [0,1,2,3,4,5,6,7,8,9,10,11]
    const labels      = Array.from({ length: range_size }, (_, i) => i + 1);
    const id          = comparisonRanges?.list?.length || 1
    let tempComparisonRangesList = [ 
      { 
        ...snapshotDetails, 
        shift: 0, 
        shifted_list: snapshotDetails.snapshots || [], 
        show: true, 
        color_index: color_map[0], 
        color: colors[color_map[0]], 
        source_list: { ...activeList }, 
        id: id, 
      }, 
      ...comparisonRanges?.list || [], 
    ]
    let tempComparisonRanges = {
      range_size: range_size, 
      color_map: color_map.length > 1 ? color_map.slice(1) : [0,1,2,3,4,5,6,7,8,9,10,11], 
      labels: labels, 
      list: tempComparisonRangesList,
    }
    setComparisonRanges(tempComparisonRanges)
    setSnapshotDetails({ ...snapshotDetailsTemplate })
    setRangeSelected(-1)
  }

  const toggleRangeSelected = (index) => {
    if (rangeSelected !== index) { setRangeSelected(index) }
    else { setRangeSelected(-1) }
  }
  
  const toggleComparisonRange = (index) => { 
    let tempComparisonRanges = { ...comparisonRanges }
    tempComparisonRanges.list[index].show = !tempComparisonRanges.list[index].show
    setComparisonRanges(tempComparisonRanges)
  }

  const removeComparisonRange = (index) => {
    let tempComparisonRangesList = [ ...comparisonRanges?.list || [] ]
    let tempColorIndex           = comparisonRanges?.list[index]?.color_index || 0
    if (tempComparisonRangesList.length < 2) { 
      tempComparisonRangesList = [] 
    }
    else { tempComparisonRangesList.splice(index, 1) }
    if (tempComparisonRangesList.length > 0) {
      let range_size = 0
      tempComparisonRangesList.forEach(range => {
        range_size = Math.max(range_size, range.days)
      })
      const labels = Array.from({ length: range_size }, (_, i) => i + 1);
      let tempComparisonRanges = {
        range_size: range_size, 
        labels: labels, 
        color_map: [ tempColorIndex, ...comparisonRanges?.color_map ], 
        list: tempComparisonRangesList,
      }
      setComparisonRanges(tempComparisonRanges)
    }
    else { setComparisonRanges(null) }
    if (index === rangeSelected) { setRangeSelected(-1) }
  }

  const shiftComparisonRange = (index = -1, shift = 1) => {
    let tempComparisonRangesList = [ ...comparisonRanges?.list || [] ]
    let range_size = 0
    tempComparisonRangesList.forEach((range, i) => {
      if (index === i || index === -1) {
        range.shift += shift
        if (shift === 0) {
          range.shift = 0
          range.shifted_list = range.snapshots
        }
        else if (range.shift > 0) {
          range.shifted_list = [
            ...Array.from({ length: range.shift }, (_, i) => NaN),
            ...range.snapshots, 
          ]
        }
        else if (range.shift < 0) { range.shifted_list = range.snapshots.slice(-range.shift) }
        else {
          range.shifted_list = range.snapshots
        }
      }
      range_size = Math.max(range_size, range.days + range.shift)
    })
    const labels = Array.from({ length: range_size }, (_, i) => i + 1);
    let tempComparisonRanges = {
      range_size: range_size, 
      labels: labels, 
      color_map: comparisonRanges?.color_map, 
      list: tempComparisonRangesList,
    }
    setComparisonRanges(tempComparisonRanges)
  }

  const heatmapTip = useRef()

  if (!activeList && !heatmap.current) return <div></div>

  if (heatmap.current && hoverDate === undefined) {
    setTimeout(() => { 
      heatmap.current.scrollLeft = heatmap.current?.scrollWidth || 1000
    }, 700)
    setTimeout(() => { 
      setLoaded(true)
      // transition-all ease-out duration-300
    }, 2000)
  }

  const handleSnapshotClick = (index) => {
    let tempSnapshotDetails = { ...snapshotDetails }
    if (index < 0) index = 0
    if (index === tempSnapshotDetails?.start_index) {
      tempSnapshotDetails = {
        ...tempSnapshotDetails, 
        start_index: null, 
        initial_count: 0, 
        days: 0, 
        range: [null, tempSnapshotDetails?.range[1] || null], 
        is_ready: false, 
      }
    }
    else if (index === tempSnapshotDetails?.end_index) {
      tempSnapshotDetails = {
        ...tempSnapshotDetails, 
        end_index: null, 
        count: 0, 
        days: 0, 
        range: [tempSnapshotDetails?.range[0] || null, null], 
        is_ready: false, 
      }
    }
    else if (hoverDate !== null
      && (tempSnapshotDetails?.range[0] === null || 
        (tempSnapshotDetails?.range[1] !== null 
          && hoverDate?.index < (tempSnapshotDetails?.start_index + (tempSnapshotDetails?.days / 2))
        )
      )
    ) {
      tempSnapshotDetails = {
        ...tempSnapshotDetails, 
        start_index: hoverDate.index, 
        initial_count: hoverDate.initial_count || 0, 
        days: tempSnapshotDetails.end_index ? tempSnapshotDetails.end_index - hoverDate?.index + 1 : 0, 
        range: [ hoverDate, tempSnapshotDetails?.range[1] || null ]
      }
    }
    else if (hoverDate !== null
      && (tempSnapshotDetails?.range[1] === null || 
        (tempSnapshotDetails?.range[0] !== null 
          && hoverDate?.index >= (tempSnapshotDetails?.start_index + (tempSnapshotDetails?.days / 2))
        )
      )
    ) {
      tempSnapshotDetails = {
        ...tempSnapshotDetails, 
        end_index: hoverDate.index, 
        count: hoverDate.count || 0, 
        days: Math.max(0, hoverDate.index - tempSnapshotDetails.start_index + 1), 
        range: [ tempSnapshotDetails?.range[0] || null, hoverDate ]
      }
    }
    if (tempSnapshotDetails.start_index !== null && tempSnapshotDetails.end_index !== null) {
      tempSnapshotDetails.list_growth = tempSnapshotDetails.count - tempSnapshotDetails.initial_count
      tempSnapshotDetails = {
        ...tempSnapshotDetails, 
        ...calcSnapshotRange([...dailySnapshots].slice(tempSnapshotDetails.start_index, tempSnapshotDetails.end_index + 1), tempSnapshotDetails) 
      }
    }
    setSnapshotDetails(tempSnapshotDetails)
  }

  const mouseEnter = (index) => {
    if (index === null || index === false) return setHoverDate(null)
    let getHoverDate = { ...dailySnapshots[index], index: index }
    let tempSnapshotDetails = { ...snapshotDetails }
    if (index < tempSnapshotDetails?.start_index && tempSnapshotDetails?.end_index === null) {
      tempSnapshotDetails = {
        ...tempSnapshotDetails, 
        end_index: tempSnapshotDetails.start_index, 
        start_index: null, 
        count: tempSnapshotDetails?.range[0]?.count || 0, 
        initial_count: null, 
        days: 0, 
        range: [ null, tempSnapshotDetails?.range[0] ], 
        is_ready: false, 
      }
    }
    else if (index > tempSnapshotDetails?.end_index && tempSnapshotDetails?.start_index === null) {
      tempSnapshotDetails = {
        ...tempSnapshotDetails, 
        start_index: tempSnapshotDetails.end_index, 
        end_index: null, 
        initial_count: tempSnapshotDetails?.range[1]?.initial_count || 0, 
        count: null, 
        days: 0, 
        range: [ tempSnapshotDetails?.range[1], null ], 
        is_ready: false, 
      }
    }
    setSnapshotDetails(tempSnapshotDetails)
    setHoverDate(getHoverDate || hoverDate)
  }
  const mouseLeave = () => {
    if (heatmapTip.current) {
      heatmapTip.current.style.left = '-1000px'
      heatmapTip.current.style.top  = '-1000px'
    }
    mouseEnter((snapshotDetails?.start_index !== null && snapshotDetails?.end_index !== null) ? null 
      : snapshotDetails?.start_index !== null ? snapshotDetails?.start_index 
      : snapshotDetails?.end_index !== null ? snapshotDetails?.end_index 
      : null)
  }
  const mouseMove = (e) => {
    if (heatmapTip.current) {
      heatmapTip.current.style.left = Math.max(-30, e.pageX + 10) + 'px'
      heatmapTip.current.style.top = e.pageY + 10 + 'px'
    }
  }

  const getRangeTableBorders = (type = '', index, side = null) => {
    let classes = ' border-t-[1px] border-l-[1px] border-slate-100 ' 
    if (type === '' || index === -1) {
      classes += index < 1 ? ' border-y-slate-200 ' : ' border-t-slate-100 '
      classes += index === 0 && type !== '' ? ' border-t-slate-300 ' : ''
      classes += side === 'LEFT'  && type !== '' ? ' border-l-slate-300 ' : side === 'LEFT'  ? ' border-l-slate-200 ' : ''
      classes += side === 'RIGHT' && type !== '' ? ' border-r-slate-300  border-r-[1px] ' : side === 'RIGHT' ? ' border-r-slate-200 border-r-[1px] ' : ''
      classes += index === (comparisonRanges?.list?.length || 0) - 1 ? 'border-b-[1px] border-b-slate-200' : ''
      classes += index < 0 ? ' bg-white ' : index !== rangeSelected ? ' bg-white ' : ' bg-[#f5f5f5]'
    }
    else if (type !== '') {
      if (type === heatmapScale) {
        if (rangeSelected > -1) {
          if (index === rangeSelected) {
            classes += ' bg-[#ffffff] border-b-[1px] border-y-[#dab4ed] ' 
            classes += ' border-r-[1px] border-x-[#dab4ed] '
          }
          else {
            classes += ' bg-[#eeeff2] '
            classes += index === comparisonRanges?.list?.length - 1 ? ' border-b-[1px] border-b-slate-300 ' : ''    
            classes += index - 1 === rangeSelected ? ' border-b-[1px]  border-t-[#dab4ed] ' : ''
            classes += side === 'LEFT'  ? ' border-l-slate-300 ' : ''
            classes += side === 'RIGHT' ? ' border-r-slate-300  border-r-[1px] ': ''
            classes += index === comparisonRanges?.list?.length - 1 ? ' border-b-[1px]  border-b-[#dab4ed] ' : ''
            classes += index === 0 ? ' border-t-[1px] border-t-slate-300 ' : ''
          }
        }
        else {
          classes += ' bg-white ' 
          classes += ' border-r-[1px] border-x-[#dab4ed] '
          classes += index === 0 && ' border-t-[1px] border-t-[#dab4ed] ' 
          classes += index === comparisonRanges?.list?.length - 1 ? ' border-b-[1px]  border-b-[#dab4ed] ' : ''
        }
      }

      else {
        if (rangeSelected > -1 && index === rangeSelected) { classes += ' bg-[#eeeff2] '  }
        else { classes += ' bg-[#f7f7f7] ' }
        classes += index === comparisonRanges?.list?.length - 1 ? ' border-b-[1px] border-b-slate-300 ' : '' 
        classes += index === 0 ? ' border-t-slate-300 ' : ''
        classes += side === 'LEFT'  ? ' border-l-slate-300 ' : ''
        classes += side === 'RIGHT' ? ' border-r-slate-300  border-r-[1px] ' : ''
      }
    } 
    else if (index > -1) {
      classes += index === rangeSelected && ' border-b-[1px] bg-[#f4f4f4]  ' 
          + (index === (comparisonRanges?.list?.length || 0) - 1 && type !== '' ? ' border-b-slate-300 ' : ' border-b-slate-200 ')
          + (index === 0 && type !== '' ? ' border-t-slate-300 ' : ' border-t-slate-200 ')
          + (side === 'LEFT'  ? ' border-l-slate-200 ' : '' )
          + (side === 'RIGHT'  ? ' border-r-slate-200 ' : '' )
    }
    
    return classes
  }

  return (
    
    <div 
      className={`inline-flex flex-grow ${ loading ? ' opacity-30 animate-pulse' : ' opacity-100' }`}
    >  
      { hoverDate &&
        <div
          ref={heatmapTip}
          className={`
            fixed z-[999] will-change	transform-gpu pointer-events-none 
            flex items-center justify-cesnter
            h-fit px-1.5 pb-0.5 pt-1
            text-[11px] text-center font-mono text-[#efefef] 
            bg-[#666666] opacity-90 
            select-none 
          `}
        >
          <Moment format="ddd&middot;DD&middot;MMM'YY" date={hoverDate?.date} /> 
        </div>
      }
      <div className="inline-flex flex-grow max-w-[670px] w-[33%] min-w-auto pr-1 pl-[0px] mb-4s items-center pt-[3px] self-start select-none">
        <div className="relative select-none w-full max-w-[663px]">
          <div 
            ref={heatmap} 
            onMouseLeave={() => mouseLeave() } 
            className="pt-[16px] relative select-none justify-start flex w-full mt-0.5 whitespace-nowrap overflow-auto scrollbar-hide overscroll-contain select-none "
          >
            <div
              className={`
                sticky left-[0] z-[1] h-min
                flex-col flex flex-wrap
                mt-[-23px] pt-[25px]
                text-gray-500 text-xs
                bg-[#FCFCFE]  
              `}
            >
              {weekdays.map((d, index) => {
                return <div 
                  key={'d-' + index}
                  className={`
                    flex items-center 
                    w-[20px] h-[12px] 
                    p-[1px] px-[0px]
                    text-[10px] text-left font-mono
                  `}  
                >
                  <div 
                    className={`
                      w-[18px] h-[12px] 
                      leading-none border-r-[1px]
                      ${ index == parseInt(day) ? '' : ' opacity-30' }
                    `}
                  >
                    { d.charAt(0) + d.charAt(1) }
                  </div>
                </div>
              })}
            </div>

            <div
              className={`
                group/wrapper relative 
                flex-col flex flex-wrap mr-2 ml-[2px]
                w-full h-[88px]
                text-gray-500 text-xs
              `}
              onMouseMove={e => mouseMove(e)}  
            >

              <React.Fragment> 

                {offset.map((o, index) => { return <div key={'o-'+index} className={`h-[12px] w-[12px]`}></div> })}
                
                {dailySnapshots.map((pixel, index) => {
                  return  (
                    <div 
                      key={'p-' + index}
                      id={'p-' + index}
                      onMouseEnter={(e) => { mouseEnter(index); }}
                      onMouseOver={(e) => { if(e.buttons === 1 ) { requestAnimationFrame(() => handleSnapshotClick(index)) } }}
                      onMouseLeave={(e) => { if(e.buttons === 1) { handleSnapshotClick(index) };  }}
                      onClick={(e) => { handleSnapshotClick(index) } }
                      onDoubleClick={(e) => {
                        e.preventDefault();
                        setSnapshotDetails({ ...snapshotDetailsTemplate })
                      }}
                      style={
                        {
                          transitionProperty: !loaded ? 'none' : 'opacity, outline-color, border-radius, background, background-color', 
                          transitionTimingFunction: 'cubic-bezier(0, 0, 0.2)', 
                          transitionDuration: '300ms'
                        }
                      }
                      className={`
                        group relative will-change transform-gpu
                        h-[11px] w-[11px] 
                        mb-[1px] ml-[1px] 
                        border-[1px] border-[#ffffff] outline-[#f7f7f7] outline outline-[1px] outline-offset-[-1px] 
                        focus-within:outline focus:outline focus-within:pointer-events-none
                        before:absolute before:top-[-1px] before:left-[-1px] before:h-[12px] before:w-[12px]
                        ${
                          (
                            (snapshotDetails?.start_index !== null && snapshotDetails?.end_index === null) 
                            && ( (index >= snapshotDetails?.start_index && index <= hoverDate?.index)
                              || (index <= snapshotDetails?.start_index && index >= hoverDate?.index) )
                          )
                          ||
                          (
                            ( snapshotDetails?.start_index === null && snapshotDetails?.end_index !== null )
                            && ( (index <= snapshotDetails?.end_index && index >= hoverDate.index)
                              || (index >= snapshotDetails?.end_index && index <= hoverDate.index) )
                          )
                          || index === (hoverDate?.index) 
                          || 
                          ( 
                            index >= (snapshotDetails?.start_index !== null ? (snapshotDetails?.start_index) : hoverDate?.index || dailySnapshots.length) && 
                            index <= (snapshotDetails?.end_index || hoverDate?.index || -1)
                          )
                          ? ' outline outline-[#b095c4] outline-[1px] outline-offset-[0px] outline-custom rounded-[0px] bg-[#ffffff]' 
                          : pixel.uid 
                          ? ' hover:outline outline-[#592373] rounded-[32px] outline-[#f3f3f3]' 
                          : ' hover:outline rounded-[32px] outline-[#f7f7f7] outline-offset-[-2.5px] '
                        }
                      `}  
                    > 

                      {
                        pixel.day === 1 && 
                        <div
                          className={`
                            absolute rounded-[32px]
                            left-0 top-[-${ 20 + (((index + offset.length) % 7)) * 12 }px]
                            font-mono slashed-zero tabular-nums text-[10px] text-gray-500 
                            opacity-60 cursor-pointer
                          `}
                          onMouseEnter={(e) => { 
                            e.preventDefault(); 
                            if (snapshotDetails?.start_index !== null && index > snapshotDetails?.start_index) { 
                              mouseEnter(index - 1) 
                            } 
                            else { 
                              mouseEnter(index) 
                            } 
                          }}
                          onClick={(e) => { 
                            e.preventDefault();
                            if (snapshotDetails?.start_index !== null && index > snapshotDetails?.start_index) { 
                              handleSnapshotClick(index - 1) 
                            } 
                            else { 
                              handleSnapshotClick(index) 
                            } 
                          }}
                        >
                          { pixel.month }
                        </div>
                      }

                      <div 
                        className={`
                          ${ pixel.uid && (pixel.count !== 0 || pixel.added !== 0 || pixel.removed !== 0) && 'cursor-pointer ' }
                          h-full w-full 
                          relative rounded-[32px] opacity-0 
                          transition-colors 
                          ${  heatmapScale === 'ACTIVITY' ? 
                              `bg-${ pixel.activity ? 'indigo-500' : '[#b9c6da]' } opacity-[${ getSnapshotOpacity(pixel.activity_opacity) }%] `
                            : heatmapScale === 'CHANGE' ? 
                              `bg-${ getSnapshotColor(pixel.list_growth, pixel.count) } opacity-[${ getSnapshotOpacity(pixel.count_opacity) }%] `
                            : heatmapScale === 'SIZE' ? 
                              `bg-${ pixel.count ? '[#592373]' : '[#b9c6da]' } opacity-[${ getSnapshotOpacity(pixel.count_opacity) }%] `
                            : heatmapScale === 'ADDED' ? 
                              `bg-${ getSnapshotColor(pixel.added, pixel.added) } opacity-[${ getSnapshotOpacity(pixel.added_opacity) }%] `
                            : `bg-${ getSnapshotColor(pixel.removed * -1, pixel.removed) } opacity-[${ getSnapshotOpacity(pixel.removed_opacity) }%] `
                          }
                        `}  
                      ></div>
                    </div>
                  )
                })}
                
                {tail.map((t, index) => { return <div key={'t-'+index} className={`h-[12px] w-[12px]`}></div> })}
                
                <div className={`h-full w-[6px]`}></div>
                
              </React.Fragment>

            </div>

          </div>

          <button
            className={`
              absolute left-[0px] top-[-1px] z-[1]
              slashed-zero tabular-nums text-[11px] text-gray-100 
              opacity-70 h-[14px] w-[14px] rounded-[2px]
              hover:opacity-60 active:opacity-80 focus:outline-none 
              ${  heatmapScale === 'ACTIVITY' ? 'bg-indigo-700'
                : heatmapScale === 'CHANGE' ? 'bg-orange-500'
                : heatmapScale === 'SIZE'     ? 'bg-[#592373]'
                : heatmapScale === 'ADDED'    ? 'bg-emerald-700'
                : 'bg-[#FF0000]'
              }
            `}
            onClick={() => toggleHeatmapScale()}
          >
            { heatmapScale === 'ACTIVITY' ? 
                <span className="mt-[-1px] mdi mdi-swap-vertical block items-center justify-center"></span>
              : heatmapScale === 'CHANGE' ?   
                <span className="mt-[-1px] mdi mdi-chart-line-variant block items-center justify-center"></span>
              : heatmapScale === 'SIZE' ?   
                <span className="mt-[-2px] mdi mdi-equal block items-center justify-center"></span>
              : heatmapScale === 'ADDED' ?   
                <span className="mt-[-1px] mdi mdi-plus block items-center justify-center"></span>
              : <span className="mt-[-1px] mdi mdi-minus block items-center justify-center"></span>
            }
          </button>
          <div 
            className={`
              absolute left-[0px] bottom-[-25px]
              w-full h-fit pr-[3px]
              font-mono slashed-zero tabular-nums text-[12px] text-gray-500 
              focus:outline-none 
              ${ hoverDate === null || !hoverDate?.uid ? ' opacity-70 ' : ' opacity-90 ' }                
            `}
          > 
            <div className="inline-flex item-center justify-start w-full gap-x-0.5">
              <div className="flex basis-1/6 justify-start">
                <span className={`
                  mdi mdi-arrow-collapse-left w-[18px] text-center rounded-l-[4px] border-r-[1px] text-[10px] pb-px pt-[3px] pl-[1px] text-white
                  bg-gray-600 opacity-[${ hoverDate === null || !hoverDate?.uid ? '30' : getSnapshotOpacity(hoverDate?.initial_count_opacity) }%] }
                `}></span>
                <span className="pl-0.5 pr-1 pt-[3px] text-[10px] text-center border-y-[1px] border-r-[1px] rounded-r-[4px] flex-grow">
                  <FormatNumber num={ hoverDate?.initial_count } />
                </span>
              </div>
              <div className="flex basis-1/6 justify-start">
                <span className={`
                  mdi mdi-plus w-[18px] text-center rounded-l-[4px] border-r-[1px] text-[11px] pt-[2px] pl-[0px] text-white
                  bg-${ getSnapshotColor(1, hoverDate?.added) } opacity-[${ hoverDate === null || !hoverDate?.uid ? '30' : getSnapshotOpacity(hoverDate?.added_opacity) }%] }
                `}></span>
                <span className="pl-0.5 pr-1 pt-[3px] text-[10px] text-center border-y-[1px] border-r-[1px] rounded-r-[4px] flex-grow">
                  <FormatNumber num={ hoverDate?.added } />
                </span>
              </div>
              <div className="flex basis-1/6 justify-start">
                <span className={`
                  mdi mdi-minus w-[18px] text-center rounded-l-[4px] border-r-[1px] text-[11px] pt-[3px] pl-[1px] text-white
                  bg-${ getSnapshotColor(-1, hoverDate?.removed) } opacity-[${ hoverDate === null || !hoverDate?.uid ? '30' : getSnapshotOpacity(hoverDate?.removed_opacity) }%] }
                `}></span>
                <span className="pl-0.5 pr-1 pt-[3px] text-[10px] text-center border-y-[1px] border-r-[1px] rounded-r-[4px] flex-grow">
                  <FormatNumber num={ hoverDate?.removed } />
                </span>
              </div>
              <div className="flex basis-1/6 items-center justify-start">
                <span className={`
                  mdi mdi-swap-vertical w-[18px] text-center rounded-l-[4px] border-r-[1px] text-[11px] pt-[2px] pl-[1px] text-white
                  bg-indigo-500 opacity-[${ hoverDate === null || !hoverDate?.uid ? '30' : getSnapshotOpacity(hoverDate?.activity_opacity) }%] }
                `}></span>
                <span className="pl-0.5 pr-1 pt-[3px] text-[10px] text-center border-y-[1px] border-r-[1px] rounded-r-[4px] flex-grow">
                  <FormatNumber num={ hoverDate?.activity } />
                </span>
              </div>
              <div className="flex basis-1/6 items-center justify-start">
                <span className={`
                  mdi mdi-chart-line-variant w-[18px] text-center rounded-l-[4px] border-r-[1px] text-[11px] pt-[2px] pl-[1px] text-white
                  bg-${ getSnapshotColor(hoverDate?.list_growth, hoverDate?.list_growth) } opacity-[${ hoverDate === null || !hoverDate?.uid ? '30' : getSnapshotOpacity(hoverDate?.list_growth_opacity) }%] }
                `}></span>
                <span className="pl-0.5 pr-1 pt-[3px] text-[10px] text-center border-y-[1px] border-r-[1px] rounded-r-[4px] flex-grow">
                  <FormatNumber num={ hoverDate?.list_growth } />
                </span>
              </div>
              <div className="flex basis-1/6 items-center justify-start">
                <span className={`
                  mdi mdi-arrow-collapse-right w-[18px] text-center rounded-l-[4px] border-r-[1px] text-[10px] pb-px pt-[3px] pl-[0px] text-white
                  bg-gray-600 opacity-[${ hoverDate === null || !hoverDate?.uid ? '30' : getSnapshotOpacity(hoverDate?.count_opacity) }%] }
                `}></span>
                <span className="pl-0.5 pr-1 pt-[3px] text-[10px] text-center border-y-[1px] border-r-[1px] rounded-r-[4px] flex-grow">
                  <FormatNumber num={ hoverDate?.count } />
                </span>
              </div>
            </div> 
          </div>
        </div>
      </div>

      <div className="inline-flex w-[430px] min-w-[430px] pl-1 overflow-y-auto scrollbar-hide overscroll-contain h-fit max-h-[137px] select-none">
        <table className="w-full table-auto slashed-zero tabular-nums text-[11px] border-collapse pb-px">
          <thead className="z-[1] sticky top-[0] bg-[#ffffff] border-collapse">
            <tr className="text-gray-500 h-[20px] max-h-[20px] bg-[#FCFCFE] border-collapse">
              <th className="px-1 pb-[3px] pt-0 font-mono slashed-zero tabular-nums text-[10px] opacity-60 font-thin">
                Compare
              </th>
              <th className="px-1 pb-[3px] pt-0 bg-[#FCFCFE]">
                <abbr title="Days">
                  <span className="mdi mdi-calendar-check-outline opacity-40 inline-block z-[1] scale-[1.2]"></span>
                </abbr>
              </th>
              <th className="px-1 pb-[3px] pt-0 bg-[#FCFCFE]">
                <abbr title="Start count">
                  <span className="mdi mdi-arrow-collapse-left opacity-40 inline-block z-[1] scale-[1.05]"></span>
                </abbr>
              </th>
              <th className="px-1 pb-[3px] pt-0 bg-[#FCFCFE]">
                <abbr title="Average count">
                  <span className="mdi mdi-format-horizontal-align-center opacity-40 inline-block z-[1] scale-[1.375]"></span>
                </abbr>
              </th>
              <th className="px-1 pb-[3px] pt-0 bg-[#FCFCFE] cursor-pointer" onClick={() => toggleHeatmapScale('SIZE') }>
                <abbr title="End count">
                  <span className={`${ heatmapScale === 'SIZE' ? 'opacity-80' : 'opacity-40' } mdi mdi-arrow-collapse-right inline-block z-[1] scale-[1.05]`}></span>
                </abbr>
              </th>
              <th className="px-1 pb-[3px] pt-0 bg-[#FCFCFE] cursor-pointer" onClick={() => toggleHeatmapScale('ADDED') }>
                <abbr title="Total added">
                  <span className={`${ heatmapScale === 'ADDED' ? 'opacity-80' : 'opacity-40' } mdi mdi-plus inline-block z-[1] scale-[1.35]`}></span>
                </abbr>
              </th>
              <th className="px-1 pb-[3px] pt-0 bg-[#FCFCFE] cursor-pointer" onClick={() => toggleHeatmapScale('REMOVED') }>
                <abbr title="Total removed">
                  <span className={`${ heatmapScale === 'REMOVED' ? 'opacity-80' : 'opacity-40' } mdi mdi-minus inline-block z-[1] scale-[1.35]`}></span>
                </abbr>
              </th>
              <th className="px-1 pb-[3px] pt-0 bg-[#FCFCFE] cursor-pointer" onClick={() => toggleHeatmapScale('ACTIVITY') }>
                <abbr title="Total activity">
                  <span className={`${ heatmapScale === 'ACTIVITY' ? 'opacity-80' : 'opacity-40' } mdi mdi-swap-vertical inline-block z-[1] scale-[1.35]`}></span>
                </abbr>
              </th>
              <th className="px-1 pb-[3px] pt-0 bg-[#FCFCFE] cursor-pointer" onClick={() => toggleHeatmapScale('CHANGE') }>
                <abbr title="List size change">
                  <span className={`${ heatmapScale === 'CHANGE' ? 'opacity-80' : 'opacity-40' } mdi mdi-chart-line-variant inline-block z-[1] scale-[1.35]`}></span>
                </abbr>
              </th>
              <th className="px-1 pb-[3px] pt-0 bg-[#FCFCFE]">
                <abbr title="Chart offset">
                  <span className={`mdi mdi-arrow-all opacity-40 inline-block z-[1] scale-[1.35]`}></span>
                </abbr>
              </th>
              <th className="bg-[#FCFCFE]"> 
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white font-mono slashed-zero tabular-nums text-[10px] opacity-60 font-thin text-gray-500 text-center h-[22px] max-h-[22px]">
              <td className={`${ getRangeTableBorders('', -1, 'LEFT') } px-0.5 max-w-[111px] min-w-[111px]`}>
                {
                  snapshotDetails?.range[0] !== null 
                  ? <Moment format="DD&middot;MMM" date={snapshotDetails?.range[0]?.date} /> 
                  : hoverDate?.date && snapshotDetails?.end_index !== hoverDate?.index
                  ? <Moment format="DD&middot;MMM" date={hoverDate?.date} />  
                  : <span className="opacity-50">__&middot;___</span>
                }
                <span className="px-0.5 opacity-50">-</span>
                { 
                  snapshotDetails?.range[1] !== null 
                  ? <Moment format="DD&middot;MMM'YY" date={snapshotDetails?.range[1]?.date} />  
                  : snapshotDetails?.range[0] !== null && hoverDate?.date && snapshotDetails?.start_index !== hoverDate?.index
                  ? <Moment format="DD&middot;MMM'YY" date={hoverDate?.date} />  
                  : <span className="opacity-50">__&middot;___&middot;__</span> 
                }
              </td>
              <td className={`${ getRangeTableBorders('', -1) } px-0.5 max-w-[25px] min-w-[25px]`}>
                <FormatNumber num={snapshotDetails?.valid_size || snapshotDetails?.days || null} />
              </td>
              <td className={`${ getRangeTableBorders('', -1) } px-0.5 max-w-[35px] min-w-[35px]`}>
                <FormatNumber num={snapshotDetails?.initial_count || hoverDate?.initial_count || null } />
              </td>
              <td className={`${ getRangeTableBorders('', -1) } px-0.5 max-w-[35px] min-w-[35px]`}>
                <FormatNumber num={snapshotDetails?.avg_count || null} />
              </td>
              <td className={`${ getRangeTableBorders('', -1) } px-0.5 max-w-[35px] min-w-[35px]`}>
                <FormatNumber num={snapshotDetails?.count || hoverDate?.count || null } />
              </td>
              <td className={`${ getRangeTableBorders('', -1) } px-0.5 max-w-[35px] min-w-[35px]`}>
                <FormatNumber num={snapshotDetails?.sum_added} />
              </td>
              <td className={`${ getRangeTableBorders('', -1) } px-0.5 max-w-[35px] min-w-[35px]`}>
                <FormatNumber num={snapshotDetails?.sum_removed} />
              </td>
              <td className={`${ getRangeTableBorders('', -1) } px-0.5 max-w-[35px] min-w-[35px]`}>
                <FormatNumber num={snapshotDetails?.sum_activity} />
              </td>
              <td className={`${ getRangeTableBorders('', -1) } px-0.5 max-w-[35px] min-w-[35px]`}>
                <FormatNumber num={
                  snapshotDetails?.sum_list_growth 
                  || (snapshotDetails?.count || hoverDate?.count || 0) - (snapshotDetails?.initial_count || hoverDate?.initial_count || 0) 
                  || null
                } />
              </td>
              <td className={`${ getRangeTableBorders('', -1, 'RIGHT') } px-0.5 max-w-[25px] min-w-[25px]`}>
                <FormatNumber num={null} />
              </td>
              <td className="font-light max-w-[28px] bg-[#FCFCFE] pl-1 pt-0.5">
                <abbr title="Add to chart">
                  <button 
                    type="button" 
                    className={`
                      w-[14px] h-[14px] rounded-[2px]
                      text-white focus:outline-none 
                      ${ snapshotDetails?.is_ready == false ? ' bg-gray-200 ' : ' bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-700 ' }
                    `}
                    onClick={storeComparisonRange}
                    disabled={snapshotDetails?.is_ready == false}
                  >
                    <span className="mdi mdi-plus"></span>
                  </button>
                </abbr>
              </td>
            </tr>
            {comparisonRanges?.list?.map((rangeObj, index) => {
              return <tr 
                key={index} 
                className={`
                  h-[22px] max-h-[22px] cursor-default opacity-70s 
                  text-gray-500 text-center font-mono slashed-zero tabular-nums text-[10px] font-thin 
                  ${ !rangeObj.show && 'text-opacity-30' }
                `}
              >
                <td 
                  className={`${ getRangeTableBorders('', index, 'LEFT') } px-0.5 max-w-[111px] min-w-[111px]`}
                  onClick={() => toggleHeatmapScale('', index) }
                >
                  <abbr title={rangeObj.source_list?.name || 'Unknown'}>
                    <Moment format="DD&middot;MMM" date={rangeObj.start_date} />  
                    <span className="px-0.5 opacity-50">-</span>
                    <Moment format="DD&middot;MMM'YY" date={rangeObj.end_date} /> 
                  </abbr>
                </td>
                <td className={`${ getRangeTableBorders('', index) } px-0.5 max-w-[25px] min-w-[25px]`}
                    onClick={() => toggleHeatmapScale('', index) }><FormatNumber num={ rangeObj.days } /></td>
                <td className={`${ getRangeTableBorders('', index) } px-0.5 max-w-[35px] min-w-[35px]`}
                    onClick={() => toggleHeatmapScale('', index) }><FormatNumber num={ rangeObj.initial_count } /></td>
                <td className={`${ getRangeTableBorders('', index) } px-0.5 max-w-[35px] min-w-[35px]`}
                    onClick={() => toggleHeatmapScale('', index) }><FormatNumber num={rangeObj.avg_count} /></td>
                <td className={`${ getRangeTableBorders('SIZE', index, 'LEFT') } px-0.5 max-w-[35px] min-w-[35px]`}
                    onClick={() => toggleHeatmapScale('SIZE', index) }><FormatNumber num={rangeObj.count} /></td>
                <td className={`${ getRangeTableBorders('ADDED', index) } px-0.5 max-w-[35px] min-w-[35px]`}
                    onClick={() => toggleHeatmapScale('ADDED', index) }><FormatNumber num={rangeObj.sum_added} /></td>
                <td className={`${ getRangeTableBorders('REMOVED', index) } px-0.5 max-w-[35px] min-w-[35px]`}
                    onClick={() => toggleHeatmapScale('REMOVED', index) }><FormatNumber num={rangeObj.sum_removed} /></td>
                <td className={`${ getRangeTableBorders('ACTIVITY', index) } px-0.5 max-w-[35px] min-w-[35px]`}
                    onClick={() => toggleHeatmapScale('ACTIVITY', index) }><FormatNumber num={rangeObj.sum_activity} /></td>
                <td className={`${ getRangeTableBorders('CHANGE', index, 'RIGHT') } px-0.5 max-w-[35px] min-w-[35px]`}
                    onClick={() => toggleHeatmapScale('CHANGE', index) }><FormatNumber num={rangeObj.list_growth} /></td>
                <td className={`${ getRangeTableBorders('', index, 'RIGHT') } px-0.5 max-w-[25px] min-w-[25px]`}
                    onClick={() => toggleHeatmapScale('', index) }><FormatNumber num={rangeObj.shift} /></td>
                <td className="max-w-[28px] bg-[#FCFCFE] pl-1 pt-0.5">
                  <abbr title="Remove">
                    <button 
                      type="button" 
                      className={`
                        w-[14px] h-[14px] rounded-[2px] text-white 
                        opacity-90 hover:opacity-100 focus:outline-none 
                        ${ ` bg-[${ rangeObj.color.hex }]` }
                      `}
                      onClick={e => { removeComparisonRange(index) }}
                    >
                      <span className="mdi mdi-close"></span>
                    </button>
                  </abbr>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      <div className="relative flex-grow min-w-[230px] w-[36%] pl-1 h-[130px] mb-2 select-none">
        <div className="relative flex-grow h-[114px]" id={`line-chart-${ DETAIL_KEY }`}>
          <Line
            data={{
              labels: comparisonRanges?.labels || [], 
              datasets: comparisonRanges?.list?.length > 0
              ? comparisonRanges?.list?.map((range, index) => {
                  if (range.show) {
                    return {
                      id: index, label: index + 1, 
                      data: range.shifted_list?.map(snapshot => {
                        if (!snapshot?.count) return NaN
                        else return heatmapScale == 'ACTIVITY'
                            ? Math.floor((snapshot.activity    - (lineChartScale === 'DRIFT' ? snapshot.range_values?.avg_activity || 0 : 0)) * 100) / 100 || 0
                            : heatmapScale == 'ADDED'
                            ? Math.floor((snapshot.added       - (lineChartScale === 'DRIFT' ? snapshot.range_values?.avg_added || 0 : 0)) * 100) / 100 || 0
                            : heatmapScale == 'REMOVED'
                            ? Math.floor((snapshot.removed     - (lineChartScale === 'DRIFT' ? snapshot.range_values?.avg_removed || 0 : 0)) * 100) / 100 || 0
                            : heatmapScale == 'SIZE'
                            ? Math.floor((snapshot.count       - (lineChartScale === 'DRIFT' ? snapshot.range_values?.avg_count || 0 : 0)) * 100) / 100 || 0
                            : Math.floor((snapshot.list_growth - (lineChartScale === 'DRIFT' ? snapshot.range_values?.avg_list_growth || 0 : 0)) * 100) / 100 || 0
                      }) || NaN,
                      backgroundColor: 
                        `${ heatmapScale === 'ACTIVITY' ? 'rgba(79, 70, 229,' // '#4f46e5' // blue
                          : heatmapScale === 'CHANGE'   ? 'rgba(234, 88, 12,' // '#ea580c' // orange 
                          : heatmapScale === 'SIZE'     ? 'rgba(89, 35, 115,' // '#592373' // purple 
                          : heatmapScale === 'ADDED'    ? 'rgba(13, 128, 70,' // '#ea580c' // emerald 
                          : 'rgba(227, 16, 41,'                               // '#e31029' // red '#FF0000'
                          }${ rangeSelected === index ? ' 0.3)' : rangeSelected > -1 ? ' 0.05)' : ' 0.1)' }`
                      ,
                      color: `rgba(${ range.color?.rgb || ''},1)`, 
                      borderColor: `rgba(${ range.color?.rgb || ''}${ rangeSelected === index ? ', 1)' : rangeSelected > -1 ? ', 0.2)' : ', 1)' }`, 
                      borderCapStyle: 'rounded', 
                      borderWidth: 1, 
                      snapGaps: false, 
                      pointRadius: ctx => range.shifted_list[ctx.dataIndex]?.day === 1 ? 3 : range.shifted_list[ctx.dataIndex]?.weekday === 'Mon' ? 2 : 0, 
                    }
                  }
                  return { label: index + 1 }
                })
              : [],
            }}
            options={{
              responsive: true, maintainAspectRatio: false, 
              animation: { duration: 300, easing: 'easeOutQuad', }, 
              hover: { animationDuration: 75, },
              tooltips: {
                mode: "index", intersect: false, 
                backgroundColor: 'rgba(66,66,66,0.8)', 
                titleMarginBottom: 0, titleFontSize: 0, 
                bodySpacing: 4, bodyFontSize: 11, 
                footerMarginTop: 6, footerAlign: 'right', 
                bodyFontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
                cornerRadius: 0, caretSize: 4, borderWidth: 0, 
                xPadding: 7, yPadding: 7, position: 'average', 
                callbacks: {
                  footer: function(tooltipItem, data) {
                    return 'Day ' + (Number(tooltipItem[0].index) + 1)
                  }, 
                  labelColor: function(tooltipItem, chart) {
                    return { backgroundColor: `rgb(${ comparisonRanges?.list[tooltipItem.datasetIndex]?.color.rgb})`, }
                  },
                  labelTextColor: function(tooltipItem, chart) {
                    return `rgba(255,255,255${ rangeSelected === tooltipItem.datasetIndex ? ', 1)' : rangeSelected !== -1 ? ', 0.5)' : ', 1)'}`
                  },
                  label: function(tooltipItem, data) {
                    let date = comparisonRanges?.list[tooltipItem.datasetIndex]?.shifted_list[tooltipItem.index].date
                    return ' ' + moment(date).format(`ddd·DD·MMM'YY`) + ': ' + (tooltipItem.value < 0 ? '' : ' ' ) + tooltipItem.value
                  }, 
                }, 
                enabled: false,
                custom: customTooltips, 
              }, 
              legend: { display: false, }, 
              scaleLabel: { fontSize: 10, },
              elements: {
                point: { pointStyle: 'circle', radius: 1, hoverRadius: 1, },
                line: { tension: 0.3, clip: { left: 5, top: 0, right: -2, bottom: 10 }, },
              }, 
              scales: {
                yAxes: [
                  {
                    gridLines: { color: 'rgba(200, 200, 200, 0.1)', zeroLineWidth: 0, }, 
                    ticks: {
                      fontSize: comparisonRanges ? 10 : 0, 
                      beginAtZero: false, fontColor: 'rgba(100, 100, 100, 0.6)', 
                      fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
                      callback: function(value, index, values) { return truncateNumber(value)[0] + truncateNumber(value)[2] }
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: { color: 'rgba(200, 200, 200, 0.1)', zeroLineWidth: 0, }, 
                    ticks: {
                      fontSize: 8, fontColor: 'rgba(100, 100, 100, 0.6)', 
                      fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
                    },
                  },
                ],
              }
            }}
          />
        </div>
        <div className="h-min items-start inline-flex w-full gap-x-1 pl-1.5 justify-end">
          <div className={`${ comparisonRanges ? 'visible' : 'hidden' }`}>
            <button
              className={`
                font-mono slashed-zero tabular-nums text-[11px] text-gray-100 
                h-[19px] w-[20px] rounded-[2px] px-1 pt-[1px] pb-[1px] mr-1
                bg-gray-500 active:opacity-80 focus:outline-none 
                ${ rangeSelected !== -1 ? `visible bg-[${ comparisonRanges?.list[rangeSelected]?.color?.hex }]` : 'hidden bg-gray-500' }
                ${ comparisonRanges?.list[rangeSelected]?.show ? 'opacity-90 hover:opacity-70 ' : 'opacity-60 hover:opacity-50 ' }
              `}
              onClick={e => toggleComparisonRange(rangeSelected)}
            >
              <span className="mdi mdi-eye"></span>
            </button>
            <button
              className={`
                font-mono slashed-zero tabular-nums text-[11px] text-gray-100 
                h-[19px] w-[20px] rounded-l-[2px] px-1 pt-[1px] pb-[1px] 
                bg-gray-500 active:opacity-80 focus:outline-none 
                ${rangeSelected !== -1 ? `bg-[${ comparisonRanges?.list[rangeSelected]?.color?.hex }]` : 'bg-gray-500 ' }
                ${rangeSelected !== -1 && !comparisonRanges?.list[rangeSelected]?.show 
                  ? 'opacity-40 hover:opacity-50 pointer-events-none'
                  : rangeSelected !== -1 ? 'opacity-90 hover:opacity-70 ' : 'opacity-70 hover:opacity-50 ' 
                }
              `}
              onClick={e => shiftComparisonRange(rangeSelected, -1)}
            >
              <span className="mdi mdi-chevron-left"></span>
            </button>
            <button
              className={`
                font-mono slashed-zero tabular-nums text-[11px] text-gray-100 
                h-[19px] w-[20px] px-1 pt-[1px] pb-[1px] 
                border-x-[1px] border-white border-opacity-40 
                bg-gray-500 active:opacity-80 focus:outline-none 
                ${rangeSelected !== -1 ? `bg-[${ comparisonRanges?.list[rangeSelected]?.color?.hex }]` : 'bg-gray-500 ' }
                ${rangeSelected !== -1 && !comparisonRanges?.list[rangeSelected]?.show 
                  ? 'opacity-40 hover:opacity-50 pointer-events-none'
                  : rangeSelected !== -1 ? 'opacity-90 hover:opacity-70 ' : 'opacity-70 hover:opacity-50 ' 
                }
              `}
              onClick={e => shiftComparisonRange(rangeSelected, 0)}
            >
              <span className="mdi mdi-restore"></span>
            </button>
            <button
              className={`
                font-mono slashed-zero tabular-nums text-[11px] text-gray-100 
                h-[19px] w-[20px] rounded-r-[2px] px-1 pt-[1px] pb-[1px] 
                bg-gray-500 active:opacity-80 focus:outline-none 
                ${ rangeSelected !== -1 ? `bg-[${ comparisonRanges?.list[rangeSelected]?.color?.hex }]` : 'bg-gray-500 ' }
                ${ rangeSelected !== -1 && !comparisonRanges?.list[rangeSelected]?.show 
                  ? 'opacity-40 hover:opacity-50 pointer-events-none'
                  : rangeSelected !== -1 ? 'opacity-90 hover:opacity-70 ' : 'opacity-70 hover:opacity-50 ' 
                }
              `}
              onClick={e => shiftComparisonRange(rangeSelected, 1)}
            >
              <span className="mdi mdi-chevron-right"></span>
            </button>
          </div>
          <div className={`${ comparisonRanges ? 'visible' : 'hidden' }`}>
            <button
              className={`
                font-mono slashed-zero tabular-nums text-[10px] text-gray-100 
                h-[19px] w-[74px] rounded-[2px] px-1 pt-[2px] pb-[1px] 
                opacity-70 hover:opacity-60 active:opacity-80 focus:outline-none 
                ${  heatmapScale === 'ACTIVITY' ? 'bg-indigo-700'
                  : heatmapScale === 'CHANGE' ? 'bg-orange-500'
                  : heatmapScale === 'SIZE'     ? 'bg-[#592373]'
                  : heatmapScale === 'ADDED'    ? 'bg-emerald-700'
                  : 'bg-[#FF0000]'
                }
              `}
              onClick={() => toggleHeatmapScale()}
            >
              { heatmapScale === 'ACTIVITY' ? <span className="mdi mdi-swap-vertical"> Activity</span>
                : heatmapScale === 'CHANGE' ? <span className="mdi mdi-chart-line-variant"> Change</span>
                : heatmapScale === 'SIZE'   ? <span className="mdi mdi-equal"> Count</span>
                : heatmapScale === 'ADDED'  ? <span className="mdi mdi-plus"> Added</span>
                : <span className="mdi mdi-minus"> Removed</span>
              }
            </button>
            <button
              className={`
                font-mono slashed-zero tabular-nums text-[10px] text-gray-100 
                h-[19px] rounded-[2px] px-1.5 pt-[2px] pb-[1px] ml-1
                opacity-70 hover:opacity-60 active:opacity-80 focus:outline-none 
                ${  heatmapScale === 'ACTIVITY' ? 'bg-indigo-700'
                  : heatmapScale === 'CHANGE'   ? 'bg-orange-500'
                  : heatmapScale === 'SIZE'     ? 'bg-[#592373]'
                  : heatmapScale === 'ADDED'    ? 'bg-emerald-700'
                  : 'bg-[#FF0000]'
                }
              `}
              onClick={() => toggleLineChartScale()}
            >
              { lineChartScale === 'COUNT' 
                ? <span className="mdi mdi-chart-areaspline-variant"> SUM</span>
                : <span className="mdi mdi-plus-minus"> AVG</span> }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicListsDetail;

const FormatNumber = ({ num = null }) => {
  const [ newNum, newNumLong, numUnit ] = truncateNumber(num)
  return (
    newNum !== false && newNumLong !== false
      ? <abbr title={ newNumLong }>
          <span className={`${ num === 0 ? 'opacity-50' : '' } font-monod tracking-tight`}>{ newNum }</span>
          <span className={`${ num === 0 ? 'opacity-50' : '' } text-[10px] ml-[1px]`}>{ numUnit }</span>
        </abbr>
      : newNum !== false ? <span>{ newNum }</span> : <span className="opacity-40">-</span> 
  )
}

const customTooltips = function(tooltipModel) {                            // https://www.chartjs.org/docs/2.9.4/general/options.html
  var tooltipEl = document.getElementById("chartjs-tooltip");
  const yAlign = tooltipModel.yAlign, xAlign = tooltipModel.xAlign;
  const chart = document.getElementById(`line-chart-${ DETAIL_KEY }`)
  if (!tooltipEl) { 
    tooltipEl = document.createElement("div");                             // Create element on first render
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.style = `opacity: 1; position: absolute; background-color: rgba(20, 20, 20, 0.664); transition: all 0.17s ease-out;pointer-events: none;`
    tooltipEl.innerHTML = `<table class="z-[999]"></table>`;
    chart.appendChild(tooltipEl);
  }
  if (tooltipModel.opacity === 0) { tooltipEl.style.opacity = 0; return; } // Hide if no tooltip
  tooltipEl.classList.remove("top", "bottom", "center", "left", "right");
  tooltipEl.classList.add(tooltipModel.yAlign);
  tooltipEl.classList.add(tooltipModel.xAlign);
  if (tooltipModel.body) {
    var bodyLines = tooltipModel.body.map((bodyItem) => { return bodyItem.lines; });
    var innerHtml = "<thead></thead><tbody>";
    bodyLines.forEach((body, i) => { 
      var style = `background-color: ${ tooltipModel.labelColors[i].backgroundColor }; border-width: 1px; border-color: ${ tooltipModel.labelTextColors[i] };
        display: inline-block; border-radius: 50%; width: 12px; height: 12px; margin-right: 6px; margin-bottom: -3px;`;
      var span = `<span class="chartjs-tooltip-key inline-block" style="${ style }"></span>`;
      innerHtml += `<tr><td class="whitespace-nowrap" style="color: ${ tooltipModel.labelTextColors[i] };"> ${span} ${body[0]} </td></tr>`;
    });
    innerHtml += `<tr><th><div class="text-right" style="color: white;"> ${ tooltipModel.footer[0] }</div></th></tr></tbody>`;
    var tableRoot = tooltipEl.querySelector("table");
    tableRoot.innerHTML = innerHtml;
  }
  const { height, width } = tooltipEl.getBoundingClientRect();             // Tooltip height and width
  const positionY = this._chart.canvas.offsetTop;                          // Chart canvas position
  const positionX = this._chart.canvas.offsetLeft;                         // Chart canvas position
  const caretY = tooltipModel.caretY;                                      // Carets
  const caretX = tooltipModel.caretX;                                      // Carets
  let top  = positionY + window.pageYOffset + caretY - height;             // Final coordinates
  let left = positionX + window.pageXOffset + caretX - width / 2;          // Final coordinates
  let space = 8                                                            // Caret + 1px (can increase)
  if      (yAlign === "top")    top += height + space 
  else if (yAlign === "center") top += height / 2 
  else if (yAlign === "bottom") top -= space 
  if      (xAlign === "left") { left += width / 2 - tooltipModel.xPadding - space / 2; if (yAlign === "center") { left += space * 2 } } 
  else if (xAlign === "right") { left -= width / 2; if (yAlign === "center") { left -= space } else { left += space } }
  tooltipEl.style.opacity    = 1
  tooltipEl.style.top        = `${ top }px`;
  tooltipEl.style.left       = `${ left }px`;
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize   = `${ tooltipModel.bodyFontSize }px`
  tooltipEl.style.fontStyle  = `${ tooltipModel._bodyFontStyle }px`;
  tooltipEl.style.padding    = `${ tooltipModel.yPadding }px ${ tooltipModel.xPadding }px`;
}