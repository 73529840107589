import React from "react";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import VabooUserPassword from "./VabooUserPassword";

const TenantPassword = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.UpdateUserPassword);
  return <VabooUserPassword />;
};

export default TenantPassword;
