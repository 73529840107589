import React, { useState } from "react";

const SimpleDateRangeInput = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  onApplyRange: applyRange,
  min = false, 
  max = false, 
  loading = false, 
  type = 'date', 
}) => {

  const [startDate, setStartDate] = useState(initialStartDate);
  
  const [endDate, setEndDate] = useState(initialEndDate);

  const handleApplyRange = () => {
    applyRange({ startDate: [startDate], endDate: [endDate] });
  };

  return (
    <div className="inline-flex">
      <div className="flex-col flex-col mr-2">
        <input
          type={type}
          className="inline-flex justify-center w-full border border-gray-300 px-2 py-1 bg-white text-sm leading-5 text-gray-500 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          min={min}
          max={max}
          disabled={loading} 
        />
      </div>
      <div className="flex-col flex-col mr-2">
        <input
          type={type}
          className="inline-flex justify-center w-full border border-gray-300 px-2 py-1 bg-white text-sm leading-5 text-gray-500 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          min={min}
          max={max}
          disabled={loading} 
        />
      </div>
      <div className="flex-col flex-col">
        <button 
          onClick={handleApplyRange}
          className="w-20 invalid:hidden disabled:opacity-40 disabled:pointer-events-none inline-flex justify-center py-1.5 mt-[-1px] px-2 border border-transpsarent text-xs leading-5 font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          disabled={loading}
        >
          {
            loading 
              ? <span className="mdi mdi-loading animate-spin"></span>
              : 'Apply' 
          }
        </button>
      </div>
    </div>
  );
};
 
export default SimpleDateRangeInput;
