import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { useCookies } from "react-cookie";
import { loginMutation } from "./../graphql/mutations/authMutations";
import gql from "graphql-tag";

export const TOKEN_NAME       = "vaboo_2023_app_token";
export const USER_NAME        = "vaboo_2023_app_name";
export const USER_EMAIL       = "vaboo_2023_app_email";
export const USER_ROLE        = "vaboo_2023_app_role";
export const TOKEN_EXPIRES_AT = "vaboo_2023_app_token_expires_at";
export const REJECTION_PATH   = "vaboo_2023_rejection_path";

export const getCookie = (name) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

const LOGIN_MUTATION = gql(loginMutation);

export const removeCookie = (name) => {
  document.cookie(name, "", { path: "/" });
  localStorage.setItem(TOKEN_NAME, false);
};

const useAuthService = () => {
  
  const [loginError, setLoginError] = useState(false);
  
  const [cookies, setCookie, removeCookie] = useCookies([
    TOKEN_NAME,
    USER_NAME,
    USER_EMAIL,
    USER_ROLE,
  ]);

  const history = useHistory();

  const [ loginUser ] = useMutation(LOGIN_MUTATION, {
    onCompleted: (result) => {
      if (
        result.loginUser &&
        result.loginUser.success &&
        result.loginUser.success === true
      ) {
        setLoginError(false);
        const { jwtToken, name, email, role, expiresAt } = result.loginUser;
        var rejectionPath = localStorage.getItem(REJECTION_PATH)
        setCurrentUser(jwtToken, name, email, role, expiresAt);
        if (!rejectionPath) { rejectionPath = '/' }
        else { localStorage.removeItem(REJECTION_PATH) }
        window.location.assign(rejectionPath);
      } 
      else { setLoginError(true); }
    },
    onError: (e) => { setLoginError(true); },
  });

  const setCurrentUser = (jwtToken, name, email, role, expiresAt) => {
    setCookie(TOKEN_NAME,       jwtToken,  { path: "/" });
    setCookie(USER_NAME,        name,      { path: "/" });
    setCookie(USER_EMAIL,       email,     { path: "/" });
    setCookie(USER_ROLE,        role,      { path: "/" });
    setCookie(TOKEN_EXPIRES_AT, expiresAt, { path: "/" });
    localStorage.setItem(TOKEN_NAME,       jwtToken);
    localStorage.setItem(USER_NAME,        name);
    localStorage.setItem(USER_EMAIL,       email);
    localStorage.setItem(USER_ROLE,        role);
    localStorage.setItem(TOKEN_EXPIRES_AT, expiresAt);
  };

  const getCurrentUser = () => {
    var isValid = false
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    if (currentTime < localStorage.getItem(TOKEN_EXPIRES_AT)) {
      isValid = localStorage.getItem(TOKEN_NAME) && localStorage.getItem(TOKEN_NAME).length > 10
                ? localStorage.getItem(TOKEN_NAME) 
                : false;
    }
    if (!isValid) {
      logout()
    }
    return isValid;
  };

  const login = (email, password) => {
    loginUser({ variables: { email, password } });
  };

  const logout = () => {
    const rejectionPath = localStorage.getItem(REJECTION_PATH)
    if (!rejectionPath && history.location.pathname !== '/login') {
      localStorage.setItem(REJECTION_PATH, history.location.pathname)
    }
    removeCookie(TOKEN_NAME);
    removeCookie(USER_NAME);
    removeCookie(USER_EMAIL);
    removeCookie(USER_ROLE);
    removeCookie(TOKEN_EXPIRES_AT);
    setCookie(TOKEN_NAME, "", { path: "/" });
    setCookie(USER_NAME, "", { path: "/" });
    setCookie(USER_EMAIL, "", { path: "/" });
    setCookie(USER_ROLE, "", { path: "/" });
    setCookie(TOKEN_EXPIRES_AT, "", { path: "/" });
    localStorage.setItem(TOKEN_NAME, false);
    localStorage.setItem(USER_NAME, false);
    localStorage.setItem(USER_EMAIL, false);
    localStorage.setItem(USER_ROLE, false);
    localStorage.setItem(TOKEN_EXPIRES_AT, false);
    history.replace("/login");
  };

  return { cookies, getCurrentUser, login, logout, error: loginError };
};

export default useAuthService;
