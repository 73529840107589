import { useCallback } from "react";

export const useOfferFieldSetters = (state, stateMethods) => {
  const { setValue, syncView } = stateMethods;
  const handleTagCheck = (e) => {
    const { value } = e.target;
    if (state.tags.indexOf(value) > -1) {
      const newTags = state.tags.filter((tag) => tag != value);
      setValue("tags", newTags);
    } else {
      const newTags = [...state.tags];
      newTags.push(value);
      setValue("tags", newTags);
    }
    syncView();
  };

  const handleExclusionTagCheck = (e) => {
    const { value } = e.target;
    if (state.exclusionTags.indexOf(value) > -1) {
      const newTags = state.exclusionTags.filter((tag) => tag != value);
      setValue("exclusionTags", newTags);
    } else {
      const newTags = [...state.exclusionTags];
      newTags.push(value);
      setValue("exclusionTags", newTags);
    }
    syncView();
  };

  const handleLabelCheck = (e) => {
    const { name, value } = e.target;
    if (state.labels.indexOf(value) > -1) {
      const newLabels = state.labels.filter((l) => l != value);
      setValue("labels", newLabels);
    } else {
      const newLabels = [...state.labels];
      newLabels.push(value);
      setValue("labels", newLabels);
    }
    syncView();
  };

  const handleAddOfferStep = useCallback(() => {
    const newSteps = [...state.steps];
    if (newSteps.length === 0) {
      newSteps.push({ title: "Step " + (newSteps.length + 1), text: "Text " + (newSteps.length + 1) });
    }
    newSteps.push({ title: "Step " + (newSteps.length + 1), text: "Text " + (newSteps.length + 1) });
    setValue("steps", newSteps);
    syncView();
  }, [state, setValue]);

  const handleDeleteOfferStep = useCallback(
    (index) => {
      const newSteps = state.steps.filter((step, i) => i != index);
      setValue("steps", newSteps);
      syncView();
    },
    [state, setValue]
  );

  const handleUpdateOfferStep = useCallback(
    (e) => {
      const { name, value } = e.target;
      const [prop, index] = name.split("_");
      const newSteps = [...state.steps];
      newSteps[index][prop] = value;
      setValue("steps", newSteps);
    },
    [state]
  );

  return {
    handleTagCheck,
    handleExclusionTagCheck,
    handleLabelCheck,
    handleAddOfferStep,
    handleUpdateOfferStep,
    handleDeleteOfferStep,
  };
};

export const useQuestionFieldSetters = (state, stateMethods) => {
  const { setValue, syncView, setArrayObjectProperty } = stateMethods;

  const handleUpdateType = (e) => {
    const { value } = e.target;
    setValue("type", value);
    setValue("answers", []);
    setValue("minimum", "");
    setValue("maximum", "");
    setValue("default", "");
    setValue("step", "");
    syncView();
  };

  const handleAddAnswer = useCallback(() => {
    const newAnswers = [...state.answers];
    newAnswers.push({ title: "" });
    setValue("answers", newAnswers);
    syncView();
  }, [state, setValue]);

  const handleDeleteAnswer = useCallback(
    (index) => {
      const newAnswers = state.answers.filter((answer, i) => i != index);
      setValue("answers", newAnswers);
      syncView();
    },
    [state, setValue]
  );

  const handleUpdateAnswer = useCallback(
    (e) => {
      const { name, value } = e.target;
      const [prop, index] = name.split("_");
      const newAnswers = [...state.answers];
      newAnswers[index][prop] = value;
      setValue("answers", newAnswers);
    },
    [state]
  );

  const handleAddDateBracketsAnswer = useCallback(() => {
    const newAnswers = [...state.answers];
    newAnswers.push({ title: "", minimum: 0, maximum: 0, weight: 0 });
    setValue("answers", newAnswers);
    syncView();
  }, [state, setValue]);

  const handleUpdateDateBracketsAnswerProperty = (idx, field, value) =>
    setArrayObjectProperty("answers", idx, field, value);

  return {
    handleAddAnswer,
    handleUpdateAnswer,
    handleDeleteAnswer,
    handleUpdateType,
    handleAddDateBracketsAnswer,
    handleUpdateDateBracketsAnswerProperty,
  };
};
