export const createDynamicList = `mutation createDynamicList(
  $model: String!, 
  $name: String!, 
  $urlContext: String, 
  $page: Int!, 
  $limit: Int!, 
  $orderBy: String!, 
  $orderHow: String!, 
  $search: String!, 
  $queryString: String, 
  $url: String!, 
  $permissions: [ListUserPermission!]!) {  
  createDynamicList(
      model: $model,
      name: $name,
      urlContext: $urlContext,
      page: $page,
      limit: $limit,
      orderBy: $orderBy,
      orderHow: $orderHow,
      search: $search,
      queryString: $queryString,
      url: $url,
      permissions: $permissions
  ) {
      id
      url
    }
  }
  `;

export const updateDynamicList = `mutation updateDynamicList(
  $id: ID!,
  $name: String!
  $urlContext: String, 
  $page: Int!, 
  $limit: Int!, 
  $orderBy: String!, 
  $orderHow: String!, 
  $search: String!, 
  $queryString: String, 
  $url: String!,
  $permissions: [ListUserPermission!]) {  
    updateDynamicList(
      id: $id,
      name: $name,
      urlContext: $urlContext,
      page: $page,
      limit: $limit,
      orderBy: $orderBy,
      orderHow: $orderHow,
      search: $search,
      queryString: $queryString,
      url: $url,
      permissions: $permissions
  ) {
      id
      url
    }
  }
  `;