import React from "react";
import FilterItemRouter from "./FilterItemRouter";

const TableFilterItem = ({
  columnKey: name,
  columnName: value,
  columnIcon: icon = null,
  toggleColumnVisible,
  isSelected,
  canHide = true, 
  filter, 
  selectedValues, 
  onUpdateFilter, 
  onUpdateDatesFilter, 
  onRemoveDatesFilter, 
  onUpdateRangeFilter, 
  onRemoveRangeFilter, 
  onClearFilter, 
  onSelectManyFilter, 
}) => {

  return (

    <div 
      className={`
        inline-flex w-full justify-between py-2 pl-2 pr-2 
        ${ selectedValues.length > 0 ? ' bg-gray-100 hover:bg-[#eeeff2] ' : '  hover:bg-gray-50 ' }
      `}
    >

      { filter 
        ? <FilterItemRouter
            options={filter || []}
            selectedValues={selectedValues}
            onUpdateFilter={onUpdateFilter} 
            onUpdateDatesFilter={onUpdateDatesFilter} 
            onRemoveDatesFilter={onRemoveDatesFilter} 
            onUpdateRangeFilter={onUpdateRangeFilter} 
            onRemoveRangeFilter={onRemoveRangeFilter} 
            onClearFilter={onClearFilter}
            onSelectManyFilter={onSelectManyFilter} 
          />
        : <div className="w-[27px] h-[27px] mt-[2px] px-2 py-[7px] opacity-50"><span className="mdi mdi-filter-off-outline"></span></div>
      }

      <div className="mr-auto ml-4 flex-col pt-px pr-2 w-fit justify-between items-center pointer-events-none ">
        <div className="inline-flex opacity-70 text-xs whitespace-nowrap w-fit" >
          { icon && <span className={`mdi mdi-${ icon } text-gray-700 mr-0.5`}></span> }
          { value }
        </div>
        <div className="block opacity-50 text-[11px] whitespace-nowrap w-fit" >
          { filter?.title || name  }
        </div>
      </div>

      {
        canHide ?
          <button 
            type="button"
            onClick={(e) => { toggleColumnVisible([ name ]) }} 
            className="flex w-fit ml-4 mr-1 p-1 justify-between items-center cursor-pointer outline-none focus:outline-none"
          >
            <span className={`
              text-[16px]
              mdi text-[#7f4999]
              ${ isSelected ? 'mdi-eye opacity-70' : 'mdi-eye-off opacity-30' }
            `}></span>
          </button>
        :
          <button 
            type="button"
            className="pointer-events-none flex w-fit ml-4 mr-1 p-1 justify-between items-center cursor-default outline-none focus:outline-none"
          >
            <span className={`
              text-[16px] text-[#7f4999]
              mdi mdi-eye-lock opacity-50
            `}></span>
          </button>
      }

    </div>
  );
};

export default TableFilterItem;
