export const allReports = `query reports($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  reports(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    name
    description
    type
    url
  }
}`;

export const invoicesReport = `query invoiceReport($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  invoiceReport(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    amount
    description
    status
    created_at
    updated_at
    last_billing_attempt_date
    payment_system
    pricing_model 
    owner {
      id
      account_type
      email
      firstname
      lastname
      account_name
    }
  }
}`;

export const tenantQAFlow = `query tenantQAFlow($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  tenantQAFlow(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    tenant {
      email
    }
    survey {
      id
      title
    }
    question {
      id
      title
    }
    answers {
      uid
      title
      text
    }
  }
}`;

export const runningACUpdates = `query acPendingUpdates {  
  acPendingUpdates {
    id
    landlord {
      email
    }
    users_total
    users_processed
    progress    
  }
}`;

export const allUserLogins = `query userLogins($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  userLogins(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    useragent
    datetime
    ip
  }
}`;

export const allBenefitLogs = `query benefitLogs($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  benefitLogs(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    useragent
    datetime
    ip
  }
}`;

export const kpiReport = `query kpiReport($account_id: Int!, $date: Date) {  
  kpiReport(account_id: $account_id, date: $date) {
    id
    account_id
    clients
    users
    active_users
    basic_users
    premium_users
  }
}`;

export const userLoginsAndBenefitsReport = `query userLoginsAndBenefitsReport($days: Int) {  
  userLoginsAndBenefitsReport(days: $days) {
    logins {
      name
      value
    }
    benefitLogs {
      name
      value
    }
  }
}`;

export const primaryKPIReport = `query primaryKPIReport($account_id: Int!, $start_date: Date!, $end_date: Date!) {  
  primaryKPIReport(account_id: $account_id, start_date: $start_date, end_date: $end_date) {
    start_date
    end_date
    account_id
    stats {
      total_client_revenue
      total_upgrade_revenue
      clients_added
      users_added
      users_deleted
      users_diff
      activation_rate
      activation_rate_change
      final_basic_count
      final_premium_count
      basic_diff
      premium_diff
      final_basic_active
      final_premium_active
      basic_active_diff
      premium_active_diff
      final_basic_activation_rate
      final_premium_activation_rate
      basic_activation_diff
      premium_activation_diff
      user_logins
      avg_daily_logins
      avg_weekly_logins
      avg_monthly_logins
      user_sessions
      avg_daily_sessions
      avg_weekly_sessions
      avg_monthly_sessions
    }
    clients {
      name
      value
    }
    users {
      name
      value
    }
    active_users {
      name
      value
    }
    basic_users {
      name
      value
    }
    premium_users {
      name
      value
    }
    basic_users_active {
      name
      value
    }
    premium_users_active {
      name
      value
    }
    new_users {
      name
      value
    }
    deleted_users {
      name
      value
    }
    ghost_users {
      name
      value
    }
    total_upgraded_users {
      name
      value
    }
    total_downgraded_users {
      name
      value
    }
    current_upgraded_users {
      name
      value
    }
    client_revenue_today {
      name
      value
    }
    client_revenue_this_month {
      name
      value
    }
    upgrade_revenue_today {
      name
      value
    }
    upgrade_revenue_this_month {
      name
      value
    }
    user_sessions_today {
      name
      value
    }
    created_methods_today {
      date      
      client_api
      client_dashboard
      control
      control_csv
      propco_api      
    }
    label_daily_totals {
      date      
      landlord
      staff
      student
      tenant
      vendor
      buyer
    }
    user_logins_today {
      name
      value
    }
    user_logins_this_month {
      name
      value
    }


    invoice_timeline {
      data {
        date 
        data {
          date 
          bill_num_today_gocard 
          wait_num_today_gocard 
          paid_num_today_gocard 
          debt_num_today_gocard 
          bill_num_today_stripe 
          paid_num_today_stripe 
          fail_num_today_stripe 
          debt_num_today_stripe 
          paid_num_today_manual 
          paid_num_today_voided 
          bill_num_today 
          wait_num_today 
          paid_num_today 
          fail_num_today 
          debt_num_today 
          bill_sum_today_gocard 
          wait_sum_today_gocard 
          paid_sum_today_gocard 
          debt_sum_today_gocard 
          bill_sum_today_stripe 
          paid_sum_today_stripe 
          fail_sum_today_stripe 
          debt_sum_today_stripe 
          paid_sum_today_manual 
          paid_sum_today_voided 
          bill_sum_today 
          wait_sum_today 
          paid_sum_today 
          fail_sum_today 
          debt_sum_today 
          bill_num_month_gocard 
          wait_num_month_gocard 
          paid_num_month_gocard 
          debt_num_month_gocard 
          bill_num_month_stripe 
          paid_num_month_stripe 
          fail_num_month_stripe 
          debt_num_month_stripe 
          paid_num_month_manual 
          paid_num_month_voided 
          bill_num_month 
          wait_num_month 
          paid_num_month 
          fail_num_month 
          debt_num_month 
          bill_sum_month_gocard 
          wait_sum_month_gocard 
          paid_sum_month_gocard 
          debt_sum_month_gocard 
          bill_sum_month_stripe 
          paid_sum_month_stripe 
          fail_sum_month_stripe 
          debt_sum_month_stripe 
          paid_sum_month_manual 
          paid_sum_month_voided 
          bill_sum_month 
          wait_sum_month 
          paid_sum_month 
          fail_sum_month 
          debt_sum_month 
          bill_num_total_gocard 
          wait_num_total_gocard 
          paid_num_total_gocard 
          debt_num_total_gocard 
          bill_num_total_stripe 
          paid_num_total_stripe 
          fail_num_total_stripe 
          debt_num_total_stripe 
          paid_num_total_manual 
          paid_num_total_voided 
          bill_num_total 
          wait_num_total 
          paid_num_total 
          fail_num_total 
          debt_num_total 
          bill_sum_total_gocard 
          wait_sum_total_gocard 
          paid_sum_total_gocard 
          debt_sum_total_gocard 
          bill_sum_total_stripe 
          paid_sum_total_stripe 
          fail_sum_total_stripe 
          debt_sum_total_stripe 
          paid_sum_total_manual 
          paid_sum_total_voided 
          bill_sum_total 
          wait_sum_total 
          paid_sum_total 
          fail_sum_total 
          debt_sum_total 
        }
      }
    }

    
  }
}`;

export const activityEvents = `query activityEvents($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  activityEvents(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    event
    created_at
    session {
      session_id
      tenant {
        email
        landlord {
          email
        }
      }
      continent_name
      continent_code
      browser_name
      browser_type
      device
      os
      ip
      is_threat
      provider_asn
      provider_domain
      provider_type
      city
      country_name
      flag
      is_eu
      region
      region_code
      postal
      latitude
      longitude
    }
    values {
      key
      value
    }
  }
}`;

export const qaStats = `query qaStats($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  qaStats(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    question_id
    question_text
    question_type
    question_response_count
    answer_id
    answer_text
    answer_count
  }
}`;

export const profileCompletionReport = `query profileCompletionReport($client_id: Int!) {  
  profileCompletionReport(client_id: $client_id) {
    client_id
    client_email
    client_subdomain
    users_total
    field_completions {
      field_name
      answers_cnt
      completion_percentage
    }
  }
}`;

export const profileFieldReport = `query profileFieldReport($client_id: Int!, $field_name: String!) {  
  profileFieldReport(client_id: $client_id, field_name: $field_name) {
    client_id
    client_email
    client_subdomain
    field {
      field_name
      answers_cnt
      completion_percentage
    }
    answers {
      answer
      times_answered
      percentage
    }
  }
}`;

export const userOrders = `query orders($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  orders(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    transaction_id
    user {
      id
      email
    }
    client {
      id
      account_name
    }
    initial_offer {
      id
      title
      slug
    }
    brand_slug    
    requested_voucher_amount
    user_payment_amount
    bank_payment_status
    order_status
    created_at
    state    
  }
}`;

export const cinemaSocietyOrders = `query cinemaOrders($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  cinemaOrders(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    transaction_id
    user {
      id
      email
    }
    client {
      id
      account_name
    }
    chain_name
    venue_name
    amount
    final_amount
    saving_amount
    saving_percentage
    bank_payment_status
    order_status
    created_at
    state    
  }
}`;

export const userOrder = `query order($id: ID!) {  
  order(id: $id) {
    id
    state
    user {
      id
      email
      firstname
      lastname
      telephone
      towncity
    }
    client {
      id
      email
      account_name
    }
    initial_offer {
      id
      title
      slug
      merchant_slug
      digital_face_value_limits {
        lower
        upper
        minor_unit
      }
      extendedData {
        digital_face_value_limits {
          lower
          upper
          minor_unit
        }
        denominations
        digital_denominations
        digital_general_restrictions
        online_order_restrictions
        instore_order_restrictions
        gc_pool
        async_only
        delivery_methods 
        transaction_types
        last_updated
        slug
        name
        type
        detail {
          balance_enquiry_url
          barcode
          description
          expiry
          redemption_instructions_url
          redemption_methods
          terms_and_conditions_url
          website_url
          digital_general_restrictions
          online_order_restrictions
          instore_order_restrictions
        }
      }
    }
    discount
    discount_adjustment
    discount_for_user
    user_payment_amount
    vaboo_payment_amount
    balance
    brand_slug 
    reward_card_provider
    payment_provider   
    requested_voucher_amount    
    order_status
    order_final_response_at
    bank_payment_started_at
    bank_payment_completed_at
    bank_payment_id
    bank_payment_url
    bank_payment_status
    created_at
    other_orders {
      id
      brand_slug
      requested_voucher_amount
      order_status
      state
      bank_payment_status
      initial_offer {
        id
        slug
      }
      created_at
    }
    fire_webhooks {
      id
      transaction_type
      status
      created_at
    }
    fire_payment_check_logs {
      id
      transaction_type
      status
      created_at
    }
  }
}`;

export const cinemaOrder = `query cinemaOrder($id: ID!) {  
  cinemaOrder(id: $id) {
    id
    transaction_id
    user {
      id
      email
      firstname
      lastname
      telephone
    }
    client {
      id
      email
      account_name
    }
    provider
    payment_provider
    chain_name
    venue_name
    amount
    final_amount    
    saving_amount
    saving_percentage
    bank_payment_url
    bank_payment_started_at
    bank_payment_completed_at
    bank_payment_status
    order_status
    created_at
    state    
    products {
      id
      sku
      qty
      price
      price_ex_vat
      price_vat
      rrp_price
      saving_amount
      saving_percentage
      final_price
      title
      description
      vouchers {
        id
        code
        expires_at
      }
    }
    other_orders {
      id
      chain_name
      venue_name
      amount
      final_amount
      order_status
      state
      bank_payment_status
      created_at
    }
    fire_webhooks {
      id
      transaction_type
      status
      created_at
    }    
    fire_payment_check_logs {
      id
      transaction_type
      status
      created_at
    }
  }
}`;

export const passports = `query passports($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  passports(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    origin_user_id
    user_id
    email
    firstname
    lastname
    telephone
    user_ids_count
    is_protected
    created_at       
  }
}`;

export const passport = `query passport($id: ID!) {  
  passport(id: $id) {
    id
    origin_user_id
    user_id
    email
    firstname
    lastname
    telephone
    user_ids_count
    is_protected
    created_at
    emails {
      hash
      from_email
      to_email
      created_at
    } 
    clients {
      from_client_id
      to_client_id
      from_client {
        account_name
      }
      to_client {
        account_name
      }
      created_at      
    }
    user_ids {
      user_id
      created_at
    }
    user {
      landlord {
        account_name
        email
        id
      }
    }
  }
}`;
