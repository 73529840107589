import React from "react";
import { ActionList, ActionGroup, Action } from "./../../../mood-ui/DataTable";

const MembershipVoucherListActions = ({
  onClearSelection,
  onPublish,
  onDelete,
}) => (
  <ActionList onClearSelection={onClearSelection}>
    <ActionGroup>
      <Action label="Publish" onClick={onPublish} />
      {/* <Action label="Delete" onClick={onDelete} /> */}
    </ActionGroup>
  </ActionList>
);

export default MembershipVoucherListActions;
