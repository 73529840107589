import React, { useState } from "react";
import Moment from "react-moment";
import { 
  useParams, 
  // useLocation, 
} from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allUserLogins } from "./../../graphql/queries/reportQueries";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useRootPageContext from "./../../hooks/rootPageContext";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import { USER_LOGIN_TYPE_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../mood-ui/Layout";
import { Heading } from "./../../mood-ui/Typography";
import Search from "./../../mood-ui/Search";
import { ItemsNotFound } from "./../../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  // TableActions,
  TableSelectedActions,
  Pagination,
} from "./../../mood-ui/DataTable";
import { PageControls } from "./../../mood-ui/Controls";
import useItemCounter from "../../hooks/itemCounter";

import ClientOverview from "../../components/reports/ClientOverview";

import BubbleChart from "../../components/reports/BubbleChart";

import { uaParse } from '../../hooks/userAgents'

const ALL_USER_LOGINS = gql(allUserLogins);

const VabooUserLoginList = ({ entityDetails = null }) => {
  
  const [ logins, setLogins ] = useState(null)

  const { protectPage } = usePermissions();
  
  protectPage(Permissions.Query.Logins);
  
  const { id: userId, page, limit, orderBy, orderHow, search } = useParams();
  
  // const { search: queryString } = useLocation();

  const { rootContext } = useRootPageContext();
  
  const { data, loading, error } = useQuery(ALL_USER_LOGINS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString: `?user=${userId}`,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data) {
        let tempLogins = []
        data.userLogins.forEach(login => {
          tempLogins.push({ ...login, ua_parsed: uaParse(login.useragent), })
        })
        setLogins(tempLogins)
      }
    }
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "userLogins" });

  const { nextPage, prevPage, applySearch } = useBaseNavigation(
    USER_LOGIN_TYPE_MODEL_NAME,
    {
      urlContext: `${rootContext}/${userId}`,
      showingItemsCount: itemsTotal,
    }
  );

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="sborder-b border-[#dfe2e8] pb-1 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Login history"
              description="Record of all logins for this account."
            />
            {
              (entityDetails && entityDetails?.__typename === 'LandlordView') && 
                <ClientOverview entityDetails={ entityDetails } groupFilter={[ 'activity', 'growth' ]} dense />
            }
            {
              data?.userLogins?.length > 1 && (
                <div className="pt-4">
                  <BubbleChart data={logins || null } dateKey={'datetime'} />
                </div>
              )
            }
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {/* {hasAccess(Permissions.Mutation.CreateRole) && (
              <AddButton label="Add Role" onClick={createForm} loading={loading}  />
            )} */}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row> 
        <TableContainer tweaks="px-5 pb-5">
          <TableView tweaks={(loading || !logins || (logins && logins.length === 0)) ? 'border-[#ebedf2]' : ''}>
            <THeading tweaks={(loading || !logins || (logins && logins.length === 0)) ? 'opacity-30 border-b' : ''}>
              <TRow>
                <THCol tweaks="text-left" name="id" value="Login ID" />
                <THCol tweaks="text-left" name="datetime" value="Date" />
                <THCol tweaks="text-left" name="ip" value="IP" />
                <THCol tweaks="text-left" name="device_title" value="Device" />
                <THCol tweaks="text-left" name="os_title" value="OS" />
                <THCol tweaks="text-left" name="vendor_title" value="Vendor" />
                <THCol tweaks="text-left" name="browser_title" value="Browser" />
                <THCol tweaks="text-left" name="useragent" value="Useragent" />
              </TRow>
            </THeading>
            {(loading) && (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <PageSpinner inline extraStyles="h-[300px]" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && logins && logins.length == 0 && (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <ItemsNotFound text="No login records found." tweaks="mx-5" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && logins && logins.length > 0 && (
              <TContent>
                {logins.map((userLogin) => (
                  <TRow key={userLogin.id}>
                    <TCol tweaks="font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={userLogin.id}>{userLogin.id}</abbr>
                    </TCol>
                    <TCol tweaks="max-w-[100px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={userLogin.datetime}>
                        <Moment format="DD&middot;MMM'YY" date={userLogin.datetime} /> 
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[100px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={userLogin.ip}>{userLogin.ip}</abbr>
                    </TCol>
                    <TCol tweaks="max-w-[100px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={userLogin.ua_parsed.device_title} className="inline-flex items-center">
                        <img 
                          className={`h-[20px] min-w-[20px] mr-1`}
                          src={ userLogin.ua_parsed.device_image } 
                          alt={ userLogin.ua_parsed.device_title } 
                        />
                        { userLogin.ua_parsed.device_title }
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[100px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={userLogin.ua_parsed.os_title} className="inline-flex items-center">
                        <img 
                          className={`h-[20px] min-w-[20px] mr-1`}
                          src={ userLogin.ua_parsed.os_image } 
                          alt={ userLogin.ua_parsed.os_title } 
                        />
                        { userLogin.ua_parsed.os_title }
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[100px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={userLogin.ua_parsed.vendor_title} className="inline-flex items-center">
                        <img 
                          className={`h-[20px] min-w-[20px] mr-1`}
                          src={ userLogin.ua_parsed.vendor_image } 
                          alt={ userLogin.ua_parsed.vendor_title } 
                        />
                        { userLogin.ua_parsed.vendor_title }
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[100px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={userLogin.ua_parsed.browser_title} className="inline-flex items-center">
                        <img 
                          className={`h-[20px] min-w-[20px] mr-1`}
                          src={ userLogin.ua_parsed.browser_image } 
                          alt={ userLogin.ua_parsed.browser_title } 
                        />
                        { userLogin.ua_parsed.browser_title }
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[400px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={userLogin.useragent}>
                        {userLogin.useragent}
                      </abbr>
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={4}>
                <TableSelectedActions></TableSelectedActions>
              </Col>
              <Col width={5} extraStyles="">
                <div className="flex">
                  {/* dynamic list filter functionality here  */}
                </div>
              </Col>
              <Col width={3}>
                <Pagination onPrev={prevPage} onNext={nextPage} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default VabooUserLoginList;
