import React from "react";

export const Heading = ({ text, description = false }) => (
  <div className="">
    <h3 className="text-lg leading-6 font-medium text-gray-900">{text}</h3>
    {description && (
      <p className="mt-1 text-sm leading-5 text-gray-500">{description}</p>
    )}
  </div>
);

export const Text = ({ text }) => <span>{text}</span>;

export const ColorLabel = ({ text, color = "grey" }) => (
  <span
    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${color}-100 text-${color}-800`}
  >
    {text}
  </span>
);

export const ItemsNotFound = ({ text, description = false }) => (
  <center>
    <div className="mt-8 px-7 py-7 rounded bg-color-gray-100 leading-5 border mb-5">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{text}</h3>
      {description && (
        <p className="mt-1 text leading-5 text-gray-500">{description}</p>
      )}
      {!description && (
        <p className="mt-1 text leading-5 text-gray-500">
          Nothing found by current search criteria...
        </p>
      )}
    </div>
  </center>
);

export const BoxedMessage = ({ text, description = false }) => (
  <center>
    <div className="mt-8 px-7 py-7 rounded bg-color-gray-100 leading-5 border">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{text}</h3>
      {description && (
        <p className="mt-1 text leading-5 text-gray-500">{description}</p>
      )}
    </div>
  </center>
);

export const InfoMessage = ({ text }) => (
  <div className="rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg
          className="h-5 w-5 text-blue-400"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-sm leading-5 text-blue-700">{text}</p>
      </div>
    </div>
  </div>
);



export const WarningMessage = ({ text }) => (
  <div className="rounded-md bg-yellow-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0 text-yellow-400">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-sm leading-5 text-yellow-700">{text}</p>
      </div>
    </div>
  </div>
);
