import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allFiles } from "./../graphql/queries/fileQueries";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import { FILE_GALLERY_MODEL_NAME } from "./../configs/constants";
import FileGallery from "./../moose-ui/files/FileGallery";
import { PageContainer, BlockContainer, Row, Col } from "./../moose-ui/Layout";
import { AddButton } from "./../moose-ui/Controls";
import { Heading } from "./../moose-ui/Typography";
import Search from "./../moose-ui/Search";
const ALL_FILES = gql(allFiles);

const ImageGallery = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.Images);
  const [showDropzone, setShowDropzone] = useState(false);
  const { page, limit, orderBy, orderHow, search } = useParams();
  const { search: queryString } = useLocation();
  const queryContext = {
    query: ALL_FILES,
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString: `?storageId=public_images`,
    },
  };
  const { data, loading, error } = useQuery(ALL_FILES, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString: `?storageId=public_images`,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    nextPage,
    prevPage,
    editForm,
    createForm,
    applySearch,
    applyFilters,
    resetFilters,
    applySorting,
  } = useBaseNavigation(FILE_GALLERY_MODEL_NAME);

  const handleUploadComplete = (cache, data) => {
    const prevData = cache.readQuery(queryContext);
    const newData = { ...prevData };
    newData.files = [data.uploadFile, ...newData.files];
    cache.writeQuery({
      ...queryContext,
      data: newData,
    });
  };

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="Images" />

        <Row>
          <Col width={9}>
            {hasAccess(Permissions.Mutation.UploadImage) && (
              <React.Fragment>
                {showDropzone && (
                  <AddButton
                    label="Hide Dropzone"
                    onClick={() => {
                      setShowDropzone(false);
                    }}
                    showPlusIcon={false}
                  />
                )}
                {!showDropzone && (
                  <AddButton
                    label="Show Dropzone"
                    onClick={() => {
                      setShowDropzone(true);
                    }}
                    showPlusIcon={false}
                  />
                )}
              </React.Fragment>
            )}
          </Col>

          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        {!error && data && data.files && (
          <Row>
            <Col width={12}>
              {data.files && (
                <FileGallery
                  showDropzone={showDropzone}
                  files={data.files}
                  queryString={queryString}
                  applyFilters={applyFilters}
                  applySearch={applySearch}
                  resetFilters={resetFilters}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  showCopyLink={true}
                  onUploadComplete={handleUploadComplete}
                />
              )}
            </Col>
          </Row>
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default ImageGallery;
