import React from "react";
import { InlineBlock, HorizontalSpacing } from "../Layout";
import StandardFilter from "./zzzStandardFilters";
import PageSpinner from "../PageSpinner";

const TypeFilters = ({
  typeFiltersReady = false, 
  data,
  // onReset: handleResetFilters,
  onUpdate: updateFilter,
  onUpdateDates: updateDatesFilter,
  onRemoveDates: removeDatesFilter,
  onUpdateRange: updateRangeFilter,
  onRemoveRange: removeRangeFilter,
  selectedFilters,
  onClearFilter: clearFilter,
  onSelectManyFilter: selectManyFilter,
}) => {

  if (!data) return <div></div>;

  // console.log('-------------------------------\nAVAILABLE FILTERS\n\n', data, '\n\nTypeFilters.jsx\n-------------------------------')

  return (
    <React.Fragment>
      <div className="flex flex-wrap relative z-[50] w-full justify-end">
        {!typeFiltersReady
          ? (
          <React.Fragment>
            {[...Array(12)].map((_,i) => {
              return <div key={i} className="h-[24px] inline-flex w-[calc(20%-4px)] border border-purple-100 ml-1 mb-1">
                <PageSpinner fill clean />
              </div>
            })}
          </React.Fragment>
        ) 
        : (
        (data && data.length > 0 && data.map((typeFilter, index) => (
          <InlineBlock key={index} className="w-[20%]">
            {typeFilter.group !== "Base" 
              && index > 0 
              && data[index - 1].group !== typeFilter.group 
              && <HorizontalSpacing pixels={100} /> 
            }
            <StandardFilter
              options={typeFilter}
              selectedValues={
                selectedFilters[typeFilter.queryKey] 
                ? selectedFilters[typeFilter.queryKey] 
                : []
              }
              onUpdateFilter={updateFilter}
              onUpdateDatesFilter={updateDatesFilter}
              onRemoveDatesFilter={removeDatesFilter}
              onUpdateRangeFilter={updateRangeFilter}
              onRemoveRangeFilter={removeRangeFilter}
              onClearFilter={clearFilter}
              onSelectManyFilter={selectManyFilter}
              // onResetFilters={handleResetFilters}
            />
          </InlineBlock>
        ))))}
      </div>
    </React.Fragment>
  );
};

export default TypeFilters;
