import React, { useState } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  clientGraduatedPricing,
  calculateClientGraduatedPricing,
} from "./../../../graphql/queries/billingQueries";
import { updateClientGraduatedPricingMutation } from "./../../../graphql/mutations/billingMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../../configs/permissions";
import usePermissions from "../../../hooks/permissions";
import {
  AddButton,
  DeleteButton,
  FormControls,
  SaveButton,
} from "../../../mood-ui/Controls";
import { NumberField } from "../../../mood-ui/Fields";
import { ItemsNotFound } from "../../../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TContent,
  THCol,
  TRow,
  TCol,
} from "./../../../mood-ui/DataTable";
import PriceCalculator from "./PriceCalculator";
import useBaseFieldSetters from "./../../../hooks/baseFieldSetters";
import useHyperState from "./../../../hooks/hyperState";
import useFormErrors from "../../../hooks/formErrors";

const QUERY_PRICING = gql(clientGraduatedPricing);
const CALCULATE_PRICE = gql(calculateClientGraduatedPricing);
const UPDATE_PRICING = gql(updateClientGraduatedPricingMutation);

const GraduatedPricing = ({
  clientId,
  paymentSettings,
  isDraft, 
  setDraft, 
  parentFormHasErrors, 
  onSuccess = () => { },
  onError = () => { },
}) => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.ClientPaymentSettings, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: clientId },
  });

  const [computedPrice, setComputedPrice] = useState(0);

  const {
    state: formData,
    setState: setFormData,
    stateMethods, 
    syncView, 
  } = useHyperState("graduatedPricing", {
    tiers: [],
  });

  const { resolveMutationErrors, clearError } = useFormErrors();

  const {
    addArrayItem: addTier,
    updateArrayObjectProperty: updateTierProperty,
    deleteArrayItem: deleteTier,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const { loading, error, refetch } = useQuery(QUERY_PRICING, {
    variables: {
      client_id: clientId,
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (!result || !result.clientGraduatedPricing) return false;
      setFormData({ tiers: [...result.clientGraduatedPricing.tiers] });
    },
  });

  const [update, { loading: isUpdating }] = useMutation(UPDATE_PRICING, {
    onCompleted: (result) => { onSuccess(); refetch() },
    onError: (e) => {
      onError();
      resolveMutationErrors(e);
    },
  });

  const [calculatePrice] = useLazyQuery(CALCULATE_PRICE, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      setComputedPrice(result.calculateClientGraduatedPricing.amount);
    },
    onError: (e) => onError(),
  });

  const handleAddTier = () => {
    if (formData.tiers.length > 0) {
      const prevItem = { ...formData.tiers[formData.tiers.length - 1] };
      let prevItemEnd = prevItem.units_end_range
      if (parseInt(prevItemEnd) === 999999) {
        if (formData.tiers.length > 1) {
          const prevPrevItem  = formData.tiers[formData.tiers.length - 2];
          const prevPrevRange = parseInt(prevPrevItem.units_end_range) - parseInt(prevPrevItem.units_start_range)
          prevItemEnd = prevItem.units_start_range + prevPrevRange
        } 
        else {
          prevItemEnd = 100
        }
        updateTierProperty("tiers", formData.tiers.length - 1, 'units_end_range', prevItemEnd);
      }
      let nextStartRange = prevItemEnd + 1;
      let nextEndRange   = 999999
      addTier("tiers", {
        units_start_range: nextStartRange,
        units_end_range: nextEndRange,
        price: prevItem.price,
      });
    } else {
      addTier("tiers", {
        units_start_range: 1,
        units_end_range: 100,
        price: 1,
      });
    }
    setDraft(d => true);
    syncView();
  };

  const handleUpdateTier = (index, name, value) => {
    if (name == 'price') {
      updateTierProperty("tiers", index, name, value);
    }
    else {
      updateTierProperty("tiers", index, name, parseInt(value));
    }
    setDraft(d => true);
    syncView();
  };

  const handleDeleteTier = (index) => {
    if (index === 0 && formData.tiers.length > 1) {
      updateTierProperty("tiers", 1, 'units_start_range', 1);
      syncView();
    }
    deleteTier("tiers", index);
    setDraft(d => true);
  };

  const handleSave = () => {
    var tiers = formData.tiers.map((t) => {
      const { units_start_range, units_end_range, price } = t;
      return { units_start_range, units_end_range, price };
    });
    if (tiers.length > 0) {
      let last_end = 0
      let has_more = tiers.length > 1
      tiers[0].units_start_range = 1
      if (tiers[0].units_end_range < last_end + 2) { 
        tiers[0].units_end_range = last_end + 2 
      }
      if (has_more) {
        if (tiers[0].units_end_range >= tiers[1].units_start_range) { 
          tiers[0].units_end_range = tiers[1].units_start_range -1
        } else {
          tiers[0].units_end_range += Math.floor((tiers[1].units_start_range - tiers[0].units_end_range) / 2)
        }
        for (let i = 1; i < tiers.length; i++) {
          last_end = tiers[i - 1].units_end_range
          tiers[i].units_start_range = last_end + 1
          has_more = i < (tiers.length - 1)
          if (has_more) { 
            if (tiers[i].units_end_range < last_end + 2) { 
              tiers[i].units_end_range = last_end + 2 
            }
            if (tiers[i].units_end_range >= tiers[i + 1].units_start_range) { 
              tiers[i].units_end_range = tiers[i + 1].units_start_range -1
            } else {
              tiers[i].units_end_range += Math.floor((tiers[i + 1].units_start_range - tiers[i].units_end_range) / 2)
            }
          }
          else {
            tiers[i].units_end_range = 999999
          }
        } 
      }
    }
    const data = { ...paymentSettings, tiers: [...tiers] };
    delete data.__typename;
    delete data.account_id;
    update({ variables: data });
  };

  if (loading) return <div></div>;
  if (error) return <div>Error...</div>;

  return (
    <React.Fragment>
      {formData.tiers && formData.tiers.length > 0 && (
        <TableContainer tweaks="relative mt-6">
          <TableView>
            <THeading>
              <TRow>
                <THCol tweaks="text-left" value="Tier Min" />
                <THCol tweaks="text-left" value="Tier Max" />
                <THCol tweaks="text-left" value="£ per user" />
                <THCol width="w-[38.5px]" tweaks="px-0.5 py-0.5">
                  <AddButton label="Add Tier" onClick={handleAddTier} icon />
                </THCol>
              </TRow>
            </THeading>
              <TContent>
                {formData.tiers.map((tier, index) => (
                  <TRow key={index}>
                    <TCol tweaks="px-0.5 py-0.5">
                      <NumberField
                        name={`range_start_${index}`}
                        value={tier.units_start_range}
                        flat 
                        min={ (index > 0) ? (formData.tiers[index -1].units_end_range + 1) : 1 }
                        max={ (index > 0) ? (formData.tiers[index -1].units_end_range + 1) : 1 }
                        step={1}
                        onChange={(e) => {
                          handleUpdateTier(index, 'units_start_range', e.target.value)
                        }}
                        readOnly={index == 0}
                      />
                    </TCol>
                    <TCol tweaks="px-0.5 py-0.5">
                      <NumberField
                        name={`range_end_${index}`}
                        value={formData.tiers.length - 1 > index ? tier.units_end_range : 999999}
                        readOnly={formData.tiers.length - 1 === index}
                        flat 
                        min={tier.units_start_range + 1}
                        max={formData.tiers.length - 1 > index ? formData.tiers[index + 1].units_start_range - 1 : 999999}
                        step={1}
                        onChange={(e) => {
                          handleUpdateTier(index, 'units_end_range', e.target.value)
                        }}
                      />
                    </TCol>
                    <TCol tweaks="px-0.5 py-0.5 w-1/3">
                      <NumberField
                        name={`price_${index}`}
                        value={tier.price}
                        flat 
                        gbp 
                        min={0}
                        max={10}
                        step={0.01}
                        required
                        pattern="(9|([1-4][0-9][0-9][0-9])|([1-9][0-9][0-9])|([1-9][0-9])|[1-9])"
                        onChange={(e) =>
                          handleUpdateTier(index, 'price', e.target.value)
                        }
                      />
                    </TCol>
                    <TCol width="w-[38.5px]" tweaks="px-0.5 py-0.5">
                      {formData.tiers.length > 1 && (
                        <DeleteButton
                        icon 
                        onClick={() => handleDeleteTier(index)}
                        />
                      )}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            </TableView>
            <p className="mt-1.5 text-xs text-gray-400 pl-0.5 select-none">
              Tiers with errors will auto-correct when you click save
            </p>
          </TableContainer>
        )}
        {formData.tiers && formData.tiers.length === 0 && (
        <div className="relative">
          <div className="-mb-14s">
            <ItemsNotFound 
              text="No tiers yet"
              description="Click + to add a pricing tier to a graduated pricing model."
            />
          </div>
          <div className="absolute top-8 left-0">
            <AddButton label="Add Tier" onClick={handleAddTier} icon />
          </div>
        </div>
      )}
      <FormControls spaced>
        <PriceCalculator
          paymentSettings={paymentSettings}
          pricing={formData}
          computedPrice={computedPrice}
          onCalculate={calculatePrice}
        />
        {hasAccess(Permissions.Mutation.UpdateClientPaymentSettings) && (
          <SaveButton onClick={handleSave} loading={isUpdating} disabled={parentFormHasErrors() || !isDraft}/>
        )}
      </FormControls>
    </React.Fragment>
  );
};

export default GraduatedPricing;












