import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createBulkOperationMutation } from "./../../graphql/mutations/landlordMutations";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useFormErrors from "./../../hooks/formErrors";
import useHyperState from "./../../hooks/hyperState";
import Dropzone from "../../mood-ui/files/Dropzone";
import { BULK_OPERATION_TYPE_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../../mood-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
} from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import {
  TextField,
  ReadOnlyField,
  RichTextEditorField,
} from "./../../mood-ui/Fields";
import BulkOperationProgressModal from "./../../vaboo-ui/BulkOperationProgressModal";

const CREATE_BULK_OPERATION = gql(createBulkOperationMutation);

const TenantBulkOperationCreate = () => {

  const { protectPage } = usePermissions();
  
  protectPage(Permissions.Mutation.CreateClientBulkOperation);
  
  const [filename, setFilename] = useState("No upload yet.");
  
  const [importProgressId, setImportProgressId] = useState(0);
  
  const [progressVisible, setProgressVisibility] = useState(false);
  
  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();
  
  const { id: landlordId } = useParams();

  const { goBack } = useBaseNavigation(BULK_OPERATION_TYPE_MODEL_NAME, {
    urlContext: `clients/${landlordId}`,
  });

  const {
    state: formData,
    // setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    owner_id: landlordId,
    file_id: 0,
    name: "",
    description: "",
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    // toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);
  
  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleFieldOnChangeProxied(e)
  }

  const [
    createBulkOperation,
    { 
      // data: createResult, 
      loading: isCreating, 
      // error: creationError 
    },
  ] = useMutation(CREATE_BULK_OPERATION, {
    variables: { ...formData },
    onCompleted: (result) => {
      setImportProgressId(result.createBulkOperation.id);
      setProgressVisibility(true);
      // goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleFileUploadComplete = (cache, data) => {
    if (!data || !data.uploadFile || !data.uploadFile.id) {
      alert("File upload error");
      return false;
    }
    handleFieldOnChange({
      target: { name: "file_id", value: parseInt(data.uploadFile.id) },
    });
    setFilename(
      `${data.uploadFile.original_name} | ${data.uploadFile.size / 1000}kb`
    );
  };

  const handleSave = () => {
    const data = { ...formData };
    createBulkOperation({ variables: data });
  };

  const handleProgressFinished = () => {
    setProgressVisibility(false);
    goBack();
  };

  const handleProgressError = () => {
    setProgressVisibility(false);
    goBack();
  };

  const [isDraft, setDraft] = useState(false)

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Start new operation"
              description="Use a CSV file to batch upgrade, downgrade, or delete members of this platform."
              />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Configure bulk operation"
                      description="Name and a csv file are required. Once you click save, the operation will begin. 
                                  This may take several minutes."
                    />
                    <p className="text-xs text-[#592373] opacity-60 underline underline-offset-4 mt-2 mb-7 select-none">
                      <a href="/downloads/bulk_operation_template.csv" download>
                        Download CSV template
                      </a>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}> 
                <Row>
                  <Col width={4} extraStyles={"px-0"}>
                    <TextField
                      loading={!formData}
                      name="name"
                      label="Name"
                      value={formData?.name}
                      onChange={handleFieldOnChange}
                      error={fieldError("name")}
                      description="A name for the records"
                      isRequired={true}
                      maxlength={64}
                    />
                  </Col>
                  <Col width={8} extraStyles={"px-0"}>
                    <RichTextEditorField
                      loading={!formData}
                      name="description"
                      label="Description"
                      value={formData?.description}
                      onChange={handleFieldOnChange}
                      error={fieldError("description")}
                      rows={1}
                      description="Any extra detail needed for the records"
                    />
                  </Col>
                  <Col width={6} extraStyles={"px-0"}>
                    <ReadOnlyField
                      loading={!formData}
                      label="CSV"
                      value={filename}
                      isRequired={true}
                    />
                  </Col>
                  <Col width={6} extraStyles="px-0 pt-1.5">
                    <Dropzone
                      storageId="csv_imports"
                      text="Upload CSV"
                      type="csv"
                      dense
                      onUploadComplete={handleFileUploadComplete}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} loading={isCreating} disabled={!isDraft || formHasErrors()} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <BulkOperationProgressModal
        isVisible={progressVisible}
        importId={importProgressId}
        onComplete={handleProgressFinished}
        onError={handleProgressError}
      />
    </PageContainer>
  );
};

export default TenantBulkOperationCreate;
