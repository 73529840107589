import React from "react";

const EventValuesNiceView = ({ data }) => {
  if (!data || !data.length) return <span></span>;
  return (
    <div>
      <span>{"{"}</span>
      {data.map((record, idx) => (
        <div key={idx}>
          &nbsp;&nbsp;&nbsp;&nbsp;"{record.key}": "{record.value}"
        </div>
      ))}
      <span>{"}"}</span>
    </div>
  );
};

export default EventValuesNiceView;
