import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { tenantPaymentSettings } from "./../../graphql/queries/tenantQueries";
import { updateTenantPaymentSettingsMutation } from "./../../graphql/mutations/tenantMutations";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../moose-ui/Layout";
import PageSpinner from "./../../moose-ui/PageSpinner";
import Permissions, { tenantNoAccessFallbackList } from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import { FormControls, SaveButton } from "../../moose-ui/Controls";
import {
  TextField,
  NumberField,
  SelectField,
  ToggleCheckboxField,
} from "../../moose-ui/Fields";
import { Heading } from "../../moose-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../moose-ui/Notifications";

import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useHyperState from "./../../hooks/hyperState";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "./../../hooks/notifications";

const PAYMENT_SETTINGS = gql(tenantPaymentSettings);
const UPDATE_PAYMENT_SETTINGS = gql(updateTenantPaymentSettingsMutation);

const TenantPaymentSettings = () => {
  const { id: account_id } = useParams();
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.UserPaymentSettings, {
    noAccessFallbackList: tenantNoAccessFallbackList,
    variables: { id: account_id }
  });
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    account_id: 0,
    vaboo_premium_upgrade_price: 0,
  });

  const [description, setDescription] = useState("");

  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const {
    setFieldValue: handleFieldOnChange,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { loading, error } = useQuery(PAYMENT_SETTINGS, {
    variables: {
      account_id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.tenantPaymentSettings) return false;
      setFormData({
        account_id: result.tenantPaymentSettings.account_id,
        vaboo_premium_upgrade_price:
          result.tenantPaymentSettings.vaboo_premium_upgrade_price,
      });
      setDescription(result.tenantPaymentSettings.description);
    },
  });

  const [
    update,
    { data: updateResult, loading: isUpdating, error: updateError },
  ] = useMutation(UPDATE_PAYMENT_SETTINGS, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated payment settings",
        secondsToDisplay: 1,
      });
      setDescription(result.updateTenantPaymentSettings.description);
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update payment settings",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    update({ variables: data });
  };

  if (loading) return <PageSpinner />;
  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer>
        {isUpdating && (
          <Heading text="Payment Settings. Updating Billing Information. Please Wait..." />
        )}
        {!isUpdating && <Heading text="Payment Settings" />}
        <FormContainer>
          <FormData>
            <Row>
              <Col width={3}>
                <TextField
                  name="vaboo_premium_upgrade_price"
                  label="Vaboo Premium Upgrade Price"
                  value={formData.vaboo_premium_upgrade_price}
                  description={description}
                  onChange={handleFieldOnChange}
                  error={fieldError("vaboo_premium_upgrade_price")}
                  isRequired={false}
                />
              </Col>
            </Row>
          </FormData>
          <FormControls>
            {!isUpdating &&
              hasAccess(Permissions.Mutation.UpdateUserPaymentSettings) && (
                <SaveButton onClick={handleSave} />
              )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default TenantPaymentSettings;
