import React from "react";
import { Link } from "react-router-dom";
import useLinker from "./../hooks/linker";
import useAuthService from "./../hooks/authService";
import usePermissions from "./../hooks/permissions";
import Permissions from "./../configs/permissions";

const Navbar = () => {
  const { hasAccess, hasAtLeastOnePermission } = usePermissions();
  const { createListUrl, goTo } = useLinker();
  const { logout } = useAuthService();
  return (
    <nav className="bg-[#592373] select-none">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-14">
          <div className="flex items-center">
            <Link
              to="/"
              className="flex items-center px-2 py-1 -ml-5 text-sm text-white focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
              style={{ cursor: "pointer" }}
            >
              <img
                className="h-7 w-fit mx-auto"
                src="https://control.vaboo.co.uk/images/vaboo.png"
                alt="Vaboo Control"
              />
            </Link>
            <div className="hidden md:block ml-[24px] border-l border-[#8857a1] pl-4">
              <div className="flex items-baseline">
                {/* <Link
                  to="/"
                  className="px-3 py-2 text-sm text-white focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                >
                  Dashboard
                </Link> */}
                {hasAccess(Permissions.Query.Clients) && (
                  <Link
                    to={createListUrl("clients")}
                    className="fml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    <span className="mdi mdi-account-circle text-[16px] mr-2"></span>
                    Clients
                  </Link>
                )}
                {hasAccess(Permissions.Query.Users) && (
                  <Link
                    to={createListUrl("users")}
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    <span className="mdi mdi-account-group text-[17px] mr-2"></span>
                    Users
                  </Link>
                )}
                {
                  (
                    hasAccess(Permissions.Query.Merchants) || 
                    hasAccess(Permissions.Query.Offers) || 
                    hasAccess(Permissions.Query.TilloOffers) || 
                    hasAccess(Permissions.Query.Sections) || 
                    hasAccess(Permissions.Query.Tags) ||
                    hasAccess(Permissions.Query.Images)
                  )
                  && (
                  <Link
                    to={createListUrl("merchants")}
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    <span className="mdi mdi-view-dashboard text-[16px] mr-2"></span>
                    Content
                  </Link>
                )}
                {/* {hasAccess(Permissions.Query.Merchants) && (
                  <Link
                    to={createListUrl("merchants")}
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    Merchants
                  </Link>
                )}
                {hasAccess(Permissions.Query.Offers) && (
                  <Link
                    to={createListUrl("offers")}
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    Offers
                  </Link>
                )}
                {hasAccess(Permissions.Query.TilloOffers) && (
                  <Link
                    to={createListUrl("cards")}
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    Cards
                  </Link>
                )}
                {hasAccess(Permissions.Query.Sections) && (
                  <Link
                    to={createListUrl("sections")}
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    Sections
                  </Link>
                )}
                {hasAccess(Permissions.Query.Tags) && (
                  <Link
                    to="/tags/all"
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    Tags
                  </Link>
                )}
                {hasAccess(Permissions.Query.Images) && (
                  <Link
                    to={createListUrl("images")}
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    Images
                  </Link>
                )} */}
                {hasAccess(Permissions.Query.Surveys) && (
                  <Link
                    to={createListUrl("surveys")}
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    Surveys
                  </Link>
                )}
                {hasAccess(Permissions.Query.Questions) && (
                  <Link
                    to={'/questions/1/50/id/desc/0'}
                    className="ml-1 px-3 py-1 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
                  >
                    Questions
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {hasAtLeastOnePermission([
              Permissions.Query.TenantQAFlowReport,
              Permissions.Query.TenantQAStatsReport,
              Permissions.Query.UserActivityEventsFlowReport,
              Permissions.Query.RunningActiveCampaignUpdatesReport,
              Permissions.Query.InvoiceReport,
            ]) && (
              <Link
                to={createListUrl("reports")}
                className="ml-4 px-3 py-2 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
              >
                Reports
              </Link>
            )}
            {hasAtLeastOnePermission([
              Permissions.Query.MainAppSettings,
              Permissions.Query.SurveyAndGlobalQuestions,
              Permissions.Query.SystemUsers,
              Permissions.Query.Roles,
            ]) && (
              <Link
                to="/settings"
                className="ml-1 px-3 py-2 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
              >
                <span className="mdi mdi-cog text-[18px]"></span>
              </Link>
            )}
            <a
              href="/login"
              className="ml-1 pl-3 py-2 text-sm text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
              style={{ cursor: "pointer" }}
              onClick={logout}
            >
              <span className="mdi mdi-logout text-[18px]"></span>
            </a>
          </div>

          <div className="-mr-2 flex md:hidden">
            <button className="inline-flex items-center justify-center p-2 text-gray-400 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:bg-[#4a1f5e] focus:text-white">
              <svg
                className="block h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="hidden md:hidden">
        <div className="px-2 pt-2 pb-3 sm:px-3">
          <a
            href="#"
            className="block px-3 py-2 text-base text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
          >
            Dashboard
          </a>
          <a
            href="#"
            className="mt-1 block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
          >
            Team
          </a>
          <a
            href="#"
            className="mt-1 block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
          >
            Projects
          </a>
          <a
            href="#"
            className="mt-1 block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
          >
            Calendar
          </a>
          <a
            href="#"
            className="mt-1 block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-[#4a1f5e] focus:outline-none focus:text-white focus:bg-[#4a1f5e]"
          >
            Reports
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
