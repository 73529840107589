import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createLandlordMutation } from "../graphql/mutations/landlordMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import PageSpinner from "./../mood-ui/PageSpinner";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useHyperState from "./../hooks/hyperState";
import useFormErrors from "../hooks/formErrors";
import useBaseNavigation from "../hooks/baseNavigation";
import useDataProvider from "./../hooks/dataProvider";
import { CLIENT_MODEL_NAME } from "../configs/constants";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../mood-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "../mood-ui/Controls";
import { TextField, SelectField } from "../mood-ui/Fields";
import { Heading } from "../mood-ui/Typography";

const CREATE_LANDLORD = gql(createLandlordMutation);

const LandlordCreate = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.CreateClient);
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    label_id: 1,
    email: "",
    subdomain: "",
    account_name: "", 
    firstname: "",
    lastname: "",
    address: "",
    towncity: "",
    postcode: "",
    telephone: "",
    password: "",
    platform_name: "",
  });

  const [labels] = useDataProvider("labels");

  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();
  
  const { goBack } = useBaseNavigation(CLIENT_MODEL_NAME);

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleFieldOnChangeProxied(e)
  }

  const [
    create,
    { data: createResult, loading: isCreating, error: createError },
  ] = useMutation(CREATE_LANDLORD, {
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    if (isCreating) return false;
    const data = { ...formData };
    create({ variables: data });
  };

  const [ isDraft, setDraft ] = useState(false)

  if (!formData) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Create a new client"
              description="Fill out this form to create a new client account and 
                          the rest of the settings will be created using a template."
              />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Platform details"
                      description="The account name is used in NPS surveys and the account email is used to sign in to the admin account."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="account_name"
                      label="Account name"
                      value={formData.account_name}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      error={fieldError("account_name")}
                      isRequired={true}
                      minlength={1}
                      maxlength={64}
                      description="Seen by members"
                      autocomplete="off"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="platform_name"
                      label="Platform name"
                      value={formData.platform_name}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      error={fieldError("platform_name")}
                      isRequired={true}
                      minlength={1}
                      maxlength="42"
                      description="Used throughout email comms"
                      autocomplete="off"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="email"
                      label="Email"
                      mode="email"
                      value={formData.email}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      error={fieldError("email")}
                      isRequired={true}
                      minlength={4}
                      maxlength={40}
                      description="Can be any email address"
                      autocomplete="off"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="subdomain"
                      label="Subdomain"
                      value={formData.subdomain}
                      onChange={handleFieldOnChange}
                      error={fieldError("subdomain")}
                      isRequired={true}
                      maxlength="48"
                      pattern="[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?"
                      description="Should never be changed"
                      autocomplete="off"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      mode="password"
                      name="password"
                      label="Set a password"
                      value={formData.password}
                      onChange={(e) => { setDraft(true); handleFieldOnChange(e); }}
                      error={fieldError("password")}
                      isRequired={true}
                      minlength={7}
                      maxlength={18}
                      autocomplete="new-password"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Primary contact"
                      description="The main point of contact at the client's company who is responsible for managing this platform."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="firstname"
                      label="First name"
                      value={formData.firstname}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      error={fieldError("firstname")}
                      isRequired={true}
                      minlength={1}
                      maxlength={24}
                      description="--"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="lastname"
                      label="Last name"
                      value={formData.lastname}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      error={fieldError("lastname")}
                      isRequired={true}
                      minlength={1}
                      maxlength={24}
                      description="--"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="telephone"
                      label="Contact number"
                      value={formData.telephone}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      error={fieldError("telephone")}
                      isRequired={true}
                      minlength={1}
                      maxlength={24}
                      description="Best number for emergencies"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Company address"
                      description="The company address might be needed for contract or billing reasons."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="address"
                      label="Address"
                      value={formData.address}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      error={fieldError("address")}
                      isRequired={true}
                      minlength={1}
                      maxlength={64}
                      description="Address line 1 and 2"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="towncity"
                      label="Area"
                      value={formData.towncity}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      error={fieldError("towncity")}
                      isRequired={true}
                      minlength={1}
                      maxlength={36}
                      description="Town or city"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="postcode"
                      label="Postcode"
                      value={formData.postcode}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      error={fieldError("postcode")}
                      isRequired={true}
                      minlength={1}
                      maxlength={10}
                      description="UK postcode"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} disabled={!isDraft || formHasErrors()} loading={isCreating} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default LandlordCreate;
