import React from "react";
import moment from "moment";
import { 
  clientOverviewFields, 
  clientFieldGroups,
} from "../../configs/clientConfig";
import {
  Row,
  Col,
} from "../../mood-ui/Layout";
import PageSpinner from "../../mood-ui/PageSpinner";
import { 
  truncateNumber, 
  commaFloat,
  commaInt, 
} from "../../hooks/utils";

const prettifyValue = (value, type) => {
  return type === 'date'     ? value ? moment(value).format('ddd - DD MMM - YYYY') : '--'
       : type === 'percent'  ? commaFloat(value) + '%'
       : type === 'currency' ? '£' + commaFloat(value)
       : type === 'number'   ? commaInt(value)
       : type === 'score'    ? commaFloat(value)
       : value
}

const formatValue = (value, type) => {
  return type === 'date'     ? value ? moment(value).format('DD/MM/YYYY') : '--'
       : type === 'percent'  ? truncateNumber(value)[0] + truncateNumber(value)[2] + '%'
       : type === 'currency' ? '£' + truncateNumber(value)[0] + truncateNumber(value)[2]
       : type === 'number'   ? truncateNumber(value)[0] + truncateNumber(value)[2] 
       : type === 'score'    ? truncateNumber(value)[0] + truncateNumber(value)[2] 
       : value || '--'
}

const GrowthStatBlocksContainer = ({ blocks = 3, children }) => {
  return <div className={`grid grid-cols-1 sm:grid-cols-${ blocks }`}>{ children }</div>
}

const GrowthStatBlock = ({
  description = '', 
  icon = null, 
  type = null, 
  indicator = false, 
  value = null,
  child = null, 
  stats = null, 
  item = null, 
}) => {
  return (
    <div className={`border-[1px] border-x-[1px] border-gray-200 ml-[-1px] mt-[-1px] ${ !value && ' opacity-50' }`}>
      <div className="px-2 pt-2 pb-1 bg-white cursor-default ">
        <dl>
          <dt className="text-xs text-left leading-2 text-slate-400 truncate">
            { icon && <span className={`mdi mdi-${ icon }`}></span> }
            { description }
          </dt>
          <dd className="pt-1 flex justify-between items-end truncate text-elipsis">
            <div 
              className={`
                flex items-baseline text-slate-500
                ${ type !== 'date' && type !== 'text' ? 'text-[15px]' : 'text-[12px] pb-0.5' }
              `}
            >
              { indicator && 
                  <span 
                    className={`
                      text-md pr-1 mdi 
                      mdi-${ value > 0 ? 'arrow-up-thick text-green-500' : value < 0 ? 'arrow-down-thick text-red-500' : 'approximately-equal text-gray-300' }
                    `}
                  ></span> 
              }
              { 
                type === 'images' 
                ? <div className="w-fit">
                    <abbr title={ value === true ? 'TRUE' : value === 0 ? 'FALSE' : value || 'None' } className="select-none">
                      <img
                        src={item.images[value] !== '' ? (item.images[value] || item.images['default']) : item.images['default']} alt={ item.name + ' image' }
                        className={`mx-auto opacity-85 h-[18px] min-w-[18px] object-contain`} 
                        onError={e => { e.target.classList.add('opacity-0') }}
                      />
                    </abbr>
                  </div>
                : <div className="mb-[-1px]">
                    <abbr title={ prettifyValue(value, type) }>
                      { formatValue(value, type) }
                    </abbr>
                  </div>
              } 
            </div>
            {
              child && 
              <div className={`inline-flex items-baseline text-[11px] text-indigo-700 pb-[2px]`}>
                <abbr title={child.title + ': ' + prettifyValue(stats[child.name] || 0, child.type)}>
                  { formatValue(stats[child.name] || 0, child.type) }
                </abbr>
              </div>
            }
          </dd>
        </dl>
      </div>
    </div>
  );
};

const ClientOverview = ({ entityDetails = null, groupFilter = null, dense = false }) => {

  var groups = clientFieldGroups.map(group => { 
    return {
      name:  group.name, 
      title: group.title, 
      icon:  group.icon, 
      items: JSON.parse(JSON.stringify([ 
        ...clientOverviewFields().filter(item => item.group === group.name) 
      ])), 
    }
  })

  if (!entityDetails) return <PageSpinner clear />;

  if (groupFilter !== null) {
    groups = groups.filter(group => {
      let match = false
      groupFilter.forEach(filter => { if (filter === group.name) { match = true } })
      return match
    })
  }

  return (
    <React.Fragment>
      {
        groups.map(group => {
          return (
            <Row key={group.name} tweaks={`w-full text-left gap-y-[0px] ${ dense ? ' pt-5 ' : ' pb-3 ' }`}>
              {
                !dense && (
                  <Col width={12} extraStyles="mb-[4px] text-slate-500 text-[16px]">
                    <span className={`mdi mdi-${ group.icon } mr-2 scale-[1.1] translate-y-[-1px] inline-block text-[#592373] opacity-70`}></span>
                    { group.title }
                  </Col>
                )
              }
              <Col width={12}>
                <GrowthStatBlocksContainer 
                  blocks={
                    group.items.filter(i =>               !i.parent).length >= 36
                      ? Math.ceil(group.items.filter(i => !i.parent).length / 4)
                      : group.items.filter(i =>           !i.parent).length >= 24 
                      ? Math.ceil(group.items.filter(i => !i.parent).length / 3)
                      : group.items.filter(i =>           !i.parent).length >= 12
                      ? Math.ceil(group.items.filter(i => !i.parent).length / 2)
                      : group.items.filter(i =>           !i.parent).length
                  }
                >
                  {
                    group.items.filter(i => !i.parent)
                    .map(item => {
                      return (
                        <GrowthStatBlock
                          key={item.name}
                          description={item.title || ''}
                          icon={item.icon || null}
                          value={entityDetails[item.name] || ''}
                          type={item.type}
                          indicator={item.indicator || false}
                          child={item.child ? group.items.filter(ch => ch.name === item.child)[0] || null : null}
                          stats={entityDetails}
                          item={item}
                        /> 
                      ) 
                    })
                  }
                </GrowthStatBlocksContainer>
              </Col>
            </Row>
          )
        })
      }
    </React.Fragment>
  );

};

export default ClientOverview;