import React, { useState } from "react";
import Moment from "react-moment";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  allTilloOffers,
  allTilloPendingBrands,
} from "./../graphql/queries/benefitQueries";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useListSelection from "./../hooks/rowSelection";
import { TILLO_OFFER_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import { PageControls, TEditButton, AddButton } from "./../mood-ui/Controls";
import Search from "./../mood-ui/Search";
import { ListItemCheckbox } from "./../mood-ui/Controls";
import { ItemsNotFound, ColorLabel } from "./../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  Pagination,
} from "./../mood-ui/DataTable";
import TilloOfferListActions from "../components/list-secondary-actions/TilloOfferListActions";
import useItemCounter from "../hooks/itemCounter";
import OfferTile from "../mood-ui/content/OfferTile";

const ALL_OFFERS = gql(allTilloOffers);

const ALL_PENDING_BRANDS = gql(allTilloPendingBrands);

const TilloOfferList = ({ entityDetails: { offers, cards, merchants } }) => {

  const { protectPage, hasAccess } = usePermissions();
  
  protectPage(Permissions.Query.TilloOffers);
  
  const [pendingBrands, setPendingBrands] = useState([]);
  
  const history = useHistory();

  const getRelatedOffers = (offerIds = '') => {
    if (!offers || !cards || !merchants) return null
    let ids = offerIds?.replace(/\s/g, '').split(',')
    return ids.map(id => [ ...offers, ...cards ].find(el => el.id == id) )
  }
  
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
    isAllRowsSelected, 
    toggleAllRows, 
  } = useListSelection();
  
  const { page, limit, orderBy, orderHow, search } = useParams();
  
  const { search: queryString } = useLocation();
  
  useQuery(ALL_PENDING_BRANDS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => setPendingBrands(data.tilloPendingBrands),
  });

  const { data, loading, error } = useQuery(ALL_OFFERS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "tilloOffers" });

  const { nextPage, prevPage, editForm, applySearch } = useBaseNavigation(
    TILLO_OFFER_MODEL_NAME,
    { showingItemsCount: itemsTotal }
  );

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Query.TilloBrands) && pendingBrands.length > 0 && (
              <AddButton 
                label={`Review ${pendingBrands.length} pending brands`} 
                onClick={() => history.push("/card/approve")} 
              />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row> 
        <TableContainer tweaks="px-5 pb-5">
          <TableView>
            <THeading>
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() => toggleAllRows(data?.tilloOffers?.map(offer => offer.id))}
                    isChecked={isAllRowsSelected(data?.tilloOffers?.map(offer => offer.id)) ? true : false}
                  />
                </THCol>
                <THCol tweaks="w-8 text-left" name="image" value="" />
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="admin_title" value="Admin title" />
                <THCol tweaks="text-left" name="code" value="Slug" />
                <THCol tweaks="text-left" name="start" value="Starts" />
                <THCol tweaks="text-left" name="end" value="Ends" />
                <THCol tweaks="text-left" name="status" value="Status" />
                <THCol tweaks="text-left" name="related" value="Related" />
                <THCol tweaks="w-16 text-center" />
                <THCol tweaks="w-8 text-center" />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.tilloOffers && data.tilloOffers.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <ItemsNotFound text="No reward cards found" tweaks="" />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.tilloOffers && data.tilloOffers.length > 0 && (
              <TContent>
                {data.tilloOffers.map((offer) => (
                  <TRow key={offer.id} isSelected={rowSelected(offer.id)}>
                    <TCol tweaks="w-4 text-center">
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(offer.id)}
                        isChecked={rowSelected(offer.id) ? true : false}
                      />
                    </TCol>
                    <TCol tweaks="inline-flex items-center pt-0 pr-0 pl-0 pb-0"  truncate={false}>
                      <OfferTile offer={offer} />
                    </TCol>
                    <TCol value={offer.id} />
                    <TCol tweaks="max-w-[120px]">
                      <abbr title={offer.admin_title}>
                        {offer.admin_title}
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[120px]" value={offer.code} />
                    <TCol tweaks={`max-w-[70px] ${ (new Date(offer.start_date).setHours(0,0,0,0)) <= (new Date().setHours(0, 0, 0, 0)) ? '' : ' text-rose-600 bg-rose-100' }`}>
                      <Moment format="DD-MM-YYYY" date={offer.start_date} />
                    </TCol>
                    <TCol tweaks={`max-w-[70px] ${ (new Date(offer.end_date).setHours(0,0,0,0)) > (new Date().setHours(0, 0, 0, 0)) ? '' : ' text-rose-600 bg-rose-100' }`}>
                      <Moment format="DD-MM-YYYY" date={offer.end_date} />
                    </TCol>
                    <TCol tweaks="max-w-[70px]">
                      {offer.status === "Active" ? (
                        <ColorLabel text={offer.status} color="emerald" />
                      ) : (
                        <ColorLabel text={offer.status} color="rose" />
                      )}
                    </TCol>
                    <TCol tweaks="max-w-[600px] px-[2px] pt-0 pb-0 bg-slate-100 border-white overflow-visible inline-flex flex-wrap items-center gap-[2px]" truncate={false}>
                      {
                        getRelatedOffers(offer?.related_offers)?.map((o, index) => {
                          return <OfferTile key={index} offer={o} />
                        })
                      }
                    </TCol>
                    <TCol tweaks="w-16 text-center"> 
                      {hasAccess(Permissions.Query.Offer) && (
                        <TEditButton onClick={() => editForm(offer.id)} />
                      )}
                    </TCol>
                    <TCol tweaks="w-8 text-center"> 
                      <a 
                        target="_BLANK" 
                        href={`https://fakeagent.vaboo.co.uk/view/${ offer.merchant.slug }/${ offer.code }`} 
                        className="text-indigo-600 hover:text-indigo-900 select-none focus:outline-none"
                      >
                        <span className="text-[15px] mdi mdi-launch"></span>
                      </a>
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <TilloOfferListActions
                      onClearSelection={clearSelection}
                      // onDelete={confirmDeletion}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={7} extraStyles="">
              </Col>
              <Col width={3}>
                <Pagination onPrev={page > 1 ? prevPage : false} onNext={data?.tilloOffers?.length >= limit - 1 ? nextPage : false} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default TilloOfferList;
