import { 
  clientSummaryFields, 
} from "../../configs/clientConfig";

import { summaries } from "../../configs/types";

const summaryString = `{ ${ 
  clientSummaryFields().reduce((acc, item) => {
    return acc += (item.name + summaries[item.gqlSummary] + ' ')
  }, '') 
} }`

export const myDynamicListsForModel = `query myDynamicListsForModel($model: String!) {  
    myDynamicListsForModel(model: $model) {
      id
      name
      search
      queryString
      url
      canEdit
      count
      permissions {
        id
        permission
      }
      owner {
        id
        name
        email
      }
    } 
  }
`;

export const dynamicListHeatmap = `query dynamicListHeatmap($id: ID!) {  
    dynamicListHeatmap(id: $id) {
      daily_snapshots {
        uid
        added
        removed
        count
        summary ${ summaryString  }
      }
    }
  }
`;
