export const summaries = {
  NumberSummary:     `{ count_type    min   max   avg   sum   is_blank  is_not_blank }`,
  PercentageSummary: `{ range         min   max   avg         is_blank  is_not_blank }`,
  ScoreSummary:      `{ range         min   max   avg         is_blank  is_not_blank }`,
  DateSummary:       `{ direction     min   max   avg         is_blank  is_not_blank }`,
  BooleanSummary:    `{                                       is_true   is_false     }`,
  CurrencySummary:   `{ range         min   max   avg   sum   is_blank  is_not_blank }`,
  TextCount:         `{                                       is_blank  is_not_blank }`,
  OptionsCount:      `{ options       { value count }         is_blank  is_not_blank }`, 
}

export const pagination =   `{ prev_page     next_page   items_total on_screen_items        }`;