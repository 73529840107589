import React, { useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createMerchantMutation } from "./../graphql/mutations/benefitMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import useModal from "./../hooks/modal";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../moose-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
  FullWHButton,
} from "./../moose-ui/Controls";
import { Heading } from "./../moose-ui/Typography";
import { SelectField, TextField } from "./../moose-ui/Fields";
import { MERCHANT_MODEL_NAME } from "../configs/constants";
import RichTextEditor from "./../moose-ui/RichTextEditor";
import ImageGalleryModal from "./../moose-ui/ImageGalleryModal";

const CREATE_MERCHANT = gql(createMerchantMutation);

const MerchantCreate = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.CreateMerchant);
  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();

  const { goBack } = useBaseNavigation(MERCHANT_MODEL_NAME);

  const { modalState, showModal, closeModal } = useModal();

  const { state: formData, stateMethods } = useHyperState("formData", {
    title: "",
    slug: "",
    status: "Inactive",
    description: "",
    image: "",
    background: "",
    related_merchants: "",
  });

  const [createMerchant] = useMutation(CREATE_MERCHANT, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const { setFieldValue: handleFieldOnChange } = useBaseFieldSetters(
    formData,
    stateMethods,
    clearError
  );

  const handleImageSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        handleFieldOnChange({
          target: { name: "image", value: file.public_url },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
  }, []);

  const handleBackgroundSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        handleFieldOnChange({
          target: { name: "background", value: file.public_url },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
  }, []);

  const handleSave = () => {
    const data = { ...formData };
    createMerchant({ variables: data });
  };

  if (!formData) return <div></div>;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="New Merchant" />
        <FormContainer>
          <FormData>
            <Row>
              <Col width={4}>
                <TextField
                  name="title"
                  label="Title"
                  value={formData.title}
                  onChange={handleFieldOnChange}
                  error={fieldError("title")}
                  isRequired={true}
                />
              </Col>
              <Col width={4}>
                <TextField
                  name="slug"
                  label="Slug"
                  value={formData.slug}
                  onChange={handleFieldOnChange}
                  error={fieldError("slug")}
                  isRequired={true}
                />
              </Col>
              <Col width={2}></Col>
              <Col width={2}>
                <SelectField
                  value={formData.status}
                  values={[
                    { id: "Active", name: "Active" },
                    { id: "Inactive", name: "Inactive" },
                  ]}
                  label="Status"
                  name="status"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="name"
                  error={fieldError("status")}
                />
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                <Heading text="Images & Description" />
              </Col>
            </Row>
            <Row>
              <Col width={2}>
                <FullWHButton
                  onClick={handleImageSelectModal}
                  label="Open Gallery"
                />
              </Col>
              <Col width={8}>
                <TextField
                  name="image"
                  label="Image"
                  value={formData.image}
                  onChange={handleFieldOnChange}
                  error={fieldError("image")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={2}>
                <FullWHButton
                  onClick={handleBackgroundSelectModal}
                  label="Open Gallery"
                />
              </Col>
              <Col width={8}>
                <TextField
                  name="background"
                  label="Background"
                  value={formData.background}
                  onChange={handleFieldOnChange}
                  error={fieldError("background")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={10}>
                <RichTextEditor
                  cols={5}
                  rows={10}
                  name="description"
                  value={formData.description}
                  label="Description"
                  onChange={handleFieldOnChange}
                  error={fieldError("description")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={8}>
                <TextField
                  name="related_merchants"
                  label="Related Merchants (IDs)"
                  description="List of IDs of related merchants. Merchants must be real, but can be not active."
                  value={formData.related_merchants}
                  onChange={handleFieldOnChange}
                  error={fieldError("related_merchants")}
                />
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <ImageGalleryModal {...modalState} />
    </PageContainer>
  );
};

export default MerchantCreate;
