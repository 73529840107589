import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { clientDetails } from "../../graphql/queries/landlordQueries";
import { updateLandlordMutation } from "../../graphql/mutations/landlordMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../mood-ui/Notifications";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useHyperState from "./../../hooks/hyperState";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "../../hooks/notifications";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";
import {
  FormControls,
  SaveButton,
} from "../../mood-ui/Controls";
import { TextField } from "../../mood-ui/Fields";
import { Heading } from "../../mood-ui/Typography";

import ClientOverview from '../../components/reports/ClientOverview'

const LANDLORD_DETAILS = gql(clientDetails);

const UPDATE_LANDLORD = gql(updateLandlordMutation);

const LandlordPrimary = ({ entityDetails = null }) => {
  
  const { id } = useParams();
  
  const { protectPage, hasAccess } = usePermissions();
  
  protectPage(Permissions.Query.Client, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id },
  });

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    id: 0,
    email: "",
    account_name: "",
    firstname: "",
    lastname: "",
    address: "",
    towncity: "",
    postcode: "",
    telephone: "",
    label_id: 0,
    password: "",
  });

  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();

  const {
    setFieldValue: handleFieldOnChangeProxied,
    // toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleFieldOnChangeProxied(e)
  }

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();
 
  const { loading, error } = useQuery(LANDLORD_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.landlord) return false;
      setFormData({ ...result.landlord, label_id: result.landlord.label_type.id });
    },
  });

  const [
    update,
    // { data: updateResult, loading: isUpdating, error: updateError },
    { loading: isUpdating, },
  ] = useMutation(UPDATE_LANDLORD, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated parent primary data",
        secondsToDisplay: 1,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    update({ variables: data });
  };

  const [ isDraft, setDraft ] = useState(false)

  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <FormContainer>
          <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
            <Col width={12}>
              <Heading
                inset={true}
                text="Company information"
                description="This information is used for admin access, 
                            client success, billing and contracts."
                />
                <ClientOverview entityDetails={ entityDetails } groupFilter={[ 'activity', 'company' ]} dense />
            </Col>
          </Row>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Platform details"
                      description="The account name is used in NPS surveys and the account email is used to sign in to the admin account."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles="">
                    <TextField
                      loading={loading}
                      name="account_name"
                      label="Account name"
                      value={formData?.account_name}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("account_name")}
                      isRequired={true}
                      minlength={1}
                      maxlength={64}
                      description="Seen by members"
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <TextField
                      loading={loading}
                      name="email"
                      label="Email"
                      mode="email"
                      value={formData?.email}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("email")}
                      isRequired={true}
                      minlength={4}
                      maxlength={40}
                      description="Can be any email address"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Primary contact"
                      description="The main point of contact at the client's company who is responsible for managing this platform."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <TextField
                      loading={loading}
                      name="firstname"
                      label="First name"
                      value={formData?.firstname}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("firstname")}
                      isRequired={true}
                      minlength={1}
                      maxlength={24}
                      description="--"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      loading={loading}
                      name="lastname"
                      label="Last name"
                      value={formData?.lastname}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("lastname")}
                      isRequired={true}
                      minlength={1}
                      maxlength={24}
                      description="--"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      loading={loading}
                      name="telephone"
                      label="Contact number"
                      value={formData?.telephone}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("telephone")}
                      isRequired={true}
                      minlength={1}
                      maxlength={24}
                      description="Best number for emergencies"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-[#dfe2e8] pt-7 pb-4 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Company address"
                      description="The company address might be needed for contract or billing reasons."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <TextField
                      loading={loading}
                      name="address"
                      label="Address"
                      value={formData?.address}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("address")}
                      isRequired={true}
                      minlength={1}
                      maxlength={64}
                      description="Address line 1 and 2"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      loading={loading}
                      name="towncity"
                      label="Area"
                      value={formData?.towncity}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("towncity")}
                      isRequired={true}
                      minlength={1}
                      maxlength={36}
                      description="Town or city"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      loading={loading}
                      name="postcode"
                      label="Postcode"
                      value={formData?.postcode}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("postcode")}
                      isRequired={true}
                      minlength={1}
                      maxlength={10}
                      description="UK postcode"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls spaced={false}>
            {hasAccess(Permissions.Mutation.UpdateClient) && (
              <SaveButton onClick={handleSave} disabled={!isDraft || formHasErrors()} loading={isUpdating} />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default LandlordPrimary;
