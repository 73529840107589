import React from "react";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import VabooUserInvoiceCreate from "./VabooUserInvoiceCreate";

const LandlordInvoiceCreate = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.CreateClientInvoice);
  return <VabooUserInvoiceCreate />;
};

export default LandlordInvoiceCreate;
