import React, { useState, useRef, useEffect } from "react";

export const TableContainer = ({
  children,
  responsive = true,
  tweaks = "",
  onBlur = () => {},
}) => (
  <div
    className={
      tweaks +
      " relative z-index-[0] flex max-w-full " +
      (responsive ? " flex-col" : " flex-col")
    }
  >
    {children}
  </div>
);

export const TableView = ({ children, tweaks = "", stretch = true }) => (
  <div className=" z-0">
    <table
      className={`${
        stretch ? "min-w-full" : "min-w-fit mx-auto "
      } z-0 relative ${tweaks}`}
    >
      {children}
    </table>
  </div>
);

export const THeading = ({ children, tweaks = "" }) => (
  <thead className={`bg-[#ECDAF5] ${tweaks}`}>{children}</thead>
);

export const TRow = ({
  isSelected = false,
  isFocusRow = -1,
  cellFocus = [-1, -1],
  children,
  tweaks = "",
  onClick = () => {},
  onTilt,
  onPan,
  panBaseline = 0,
}) => {
  const asd = 2;
  return (
    <tr
      tabIndex={1}
      className={`
        hover:bg-[#FCFCFE] cursor-default 
        ${isFocusRow > -1 && " bg-[#fbfbfe] hover:bg-[#fbfbfe] "} 
        ${
          !isSelected
            ? " bg-[#ffffff] "
            : " bg-[#f7f7fc] hover:bg-[#f7f7fc] cursor-default "
        }
        focus:outline-none 
        cursor-default 
        ${tweaks} 
      `}
      onKeyDown={(e) => {
        if (e.key == "ArrowUp") {
          e.preventDefault();
          if (cellFocus[1] > 0) requestAnimationFrame(() => onTilt(-1, e));
        }
        if (e.key == "ArrowDown") {
          e.preventDefault();
          requestAnimationFrame(() => onTilt(1, e));
        }
        if (e.key == "ArrowRight") {
          e.preventDefault();
          requestAnimationFrame(() => onPan(1, e));
        }
        if (e.key == "ArrowLeft") {
          e.preventDefault();
          if (cellFocus[0] > panBaseline)
            requestAnimationFrame(() => onPan(-1, e));
        }
        if (e.key == "Tab" && e.shiftKey) {
          e.preventDefault();
          requestAnimationFrame(() => onTilt(-1, e));
        }
        if (e.key == "Tab" && !e.shiftKey) {
          e.preventDefault();
          if (cellFocus[1] > 0) requestAnimationFrame(() => onTilt(1, e));
        }
        if (e.key == "Enter") onClick(e);
      }}
    >
      {children}
    </tr>
  );
};

export const TCol = ({
  isBold = false,
  value = false,
  width = "min-w-full",
  centerContent = false,
  children,
  tweaks = "",
  truncate = true,
}) => {
  const marginX = centerContent ? 6 : 2;
  const cssClass = ` px-${marginX} py-2 whitespace-no-wrap border border-gray-100 text-xs leading-3 text-gray-500 ${width} ${
    truncate && "truncate"
  }`;
  return (
    <td className={cssClass + " " + tweaks}>
      {value !== false ? value : children}
    </td>
  );
};

export const TColBorderless = React.memo(
  ({ children, isBold = false, width = "min-w-full" }) => {
    const cssClass = isBold
      ? `p-1 whitespace-no-wrap text-xs leading-5 text-gray-500`
      : `p-1 whitespace-no-wrap text-xs leading-5 text-gray-500 ${width}`;
    return <td className={cssClass}>{children}</td>;
  },
  (prevProps, nextProps) => {
    if (prevProps.children !== nextProps.children) return false;
    return true;
  }
);

export const THCol = ({
  value = "",
  children,
  name,
  sortBy,
  sortHow,
  onSort,
  icon = null,
  width = "max-w-full",
  tweaks = "",
  topSticky = "-30px",
}) => {
  if (!onSort || !name)
    return (
      <th
        className={`before:pointer-events-none before:block before:absolute before:top-0 before:bottom-[1.5px] before:border-[#FCFCFE] 
                    first-of-type:before:left-[-20px] first-of-type:before:bg-[#FCFCFE] first-of-type:before:outline 
                    first-of-type:before:outline-[2px] first-of-type:before:outline-[#FCFCFE]
                    first-of-type:before:outline-offset-[-2px]
                    before:-left-[1px] before:-right-[1px] before:border-l-4 before:border-r-4 before:border-t-4
                    select-none pl-1 pr-1 pb-2 pt-2  s-mt-2 font-normal border-transparent outline outline-2 outline-[#ecdaf5] outline-offset-[-2px] bg-[#FCFCFE] 
                    text-[10.5px] leading-3 text-gray-400 trsacking-wider ${width} ${tweaks} sticky top-[${topSticky}] z-10`}
      >
        {value || icon ? (
          <abbr
            title={value}
            className={`
                w-full overflow-hidden translate-x-[15px] 
                ${value && " truncate text-elipsis"} 
              `}
          >
            {icon && (
              <span
                className={`mdi mdi-${icon} inline-block translate-y-[1px] text-[14px] mr-1`}
              ></span>
            )}
            {value && value}
          </abbr>
        ) : (
          children
        )}
      </th>
    );

  return (
    <th
      className={`before:pointer-events-none before:block before:absolute before:top-0 before:bottom-[1.5px] before:border-[#FCFCFE] 
        before:-left-[0px] before:-right-[0px] before:border-l-[3px] before:border-r-[3px] before:border-t-4
        select-none pl-pxs pr-[1px] pb-2 pt-2 s-mt-2 font-normal border-transparent outline outline-2 outline-[#ecdaf5] outline-offset-[-2px] bg-[#FCFCFE] 
        text-[10.5px] leading-3 text-gray-400 trsacking-wider ${width} ${tweaks} sticky top-[${topSticky}] z-10
        cursor-pointer
        ${name === sortBy ? "before:border-b-[#BB93CF] before:border-b-2" : ""}
      `}
      onClick={() => onSort(name)}
    >
      <div className="flex justify-between w-full">
        {value || icon ? (
          <abbr
            title={value}
            className={`
                w-full overflow-hidden translate-x-[19px] pr-[8px] ml-[-20px] 
                ${value && " truncate text-elipsis"} 
              `}
          >
            {icon && (
              <span
                className={`mdi mdi-${icon} inline-block translate-y-[1px] text-[14px] mr-0.5`}
              ></span>
            )}
            {value && value}
          </abbr>
        ) : (
          children
        )}
        {name !== sortBy && (
          <span
            className={`mdi mdi-dots-vertical text-[14px] mr-[-2px] mt-[1px]`}
          ></span>
        )}
        {name === sortBy && sortHow === "asc" && (
          <span
            className={`mdi mdi-chevron-up text-[14px] text-gray-900 mr-[-2px] mt-[1px]`}
          ></span>
        )}
        {name === sortBy && sortHow === "desc" && (
          <span
            className={`mdi mdi-chevron-down text-[14px] text-gray-900 mr-[-2px] mt-[1px]`}
          ></span>
        )}
      </div>
    </th>
  );
};

export const TContent = ({ children, tweaks = "" }) => (
  <tbody className={`bg-white ${tweaks}`}>{children}</tbody>
);

export const TableActions = ({ children }) => (
  <div className="mt-4 grid grid-cols-1 row-gap-12 col-gap-4 sm:grid-cols-12">
    {children}
  </div>
);

export const TableSelectedActions = ({ children }) => (
  <div className={`sm:col-span-8 flex justify-between`}>{children}</div>
);

export const Pagination = ({ children, onPrev, onNext }) => {
  return (
    <div className={`sm:col-span-4 text-right select-none`}>
      {!onPrev && (
        <span className="ml-0 inline-flex">
          <button
            type="button"
            className="py-1 px-4 border border-[#dfe2e8] text-sm leading-5 text-gray-300 focus:outline-none cursor-not-allowed"
          >
            Prev
          </button>
        </span>
      )}
      {onPrev && (
        <span className="ml-0 inline-flex shadsow-sm">
          <button
            type="button"
            className="py-1 px-4 border border-[#dfe2e8] text-sm leading-5 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
            onClick={onPrev}
          >
            Prev
          </button>
        </span>
      )}
      <span className="ml-2 inline-flex">
        {!onNext && (
          <button
            type="button"
            className="py-1 px-4 border border-[#dfe2e8] text-sm leading-5 text-gray-300 focus:outline-none cursor-not-allowed"
          >
            Next
          </button>
        )}
        {onNext && (
          <button
            type="button"
            className="py-1 px-4 border border-[#dfe2e8] text-sm leading-5 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
            onClick={onNext}
          >
            Next
          </button>
        )}
      </span>
    </div>
  );
};

export const PaginationNew = ({
  onPrev = () => {},
  onNext = () => {},
  onSetPageNum = () => {},
  next_page = false,
  prev_page = false,
  items_total = 0,

  on_screen_items = 0,
  page,
  limit,
  loading = false,
}) => {
  return (
    <div
      className={`
      col-span-12 text-right select-none flex items-center justify-end 
      ${loading && " opacity-40"}
    `}
    >
      <span className="mr-4 inline-flex text-sm font-light text-gray-500 select-none">
        <span className="font-medium mr-2">
          {
            parseInt(items_total)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .split(".")[0]
          }
        </span>
        Results
      </span>

      <span className="ml-0 inline-flex select-none">
        <button
          type="button"
          className={`
            mr-1
            select-none py-1 px-2 
            border border-[#dfe2e8] 
            text-sm leading-5 
            focus:outline-none 
            active:bg-gray-50 active:text-gray-800 
            transition duration-150 ease-in-out
            ${
              prev_page && !loading
                ? "text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue"
                : "text-gray-300 cursor-default pointer-events-none"
            }
          `}
          onClick={() => {
            onSetPageNum(1);
          }}
        >
          <span className="mdi mdi-chevron-double-left"></span>
        </button>
        <button
          type="button"
          className={`
            select-none py-1 px-2 
            border border-[#dfe2e8] 
            text-sm leading-5 
            focus:outline-none 
            active:bg-gray-50 active:text-gray-800 
            transition duration-150 ease-in-out
            ${
              prev_page && !loading
                ? "text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue"
                : "text-gray-300 cursor-default pointer-events-none"
            }
          `}
          onClick={onPrev}
        >
          <span className="mdi mdi-chevron-left"></span>
        </button>
      </span>
      <span className="mx-5 inline-flex justify-center select-none text-sm font-light text-gray-700 min-w-[38px] text-center">
        <span className="font-medium">
          {
            parseInt(page)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .split(".")[0]
          }
        </span>
        <span className="opacity-30 px-2">/</span>

        {
          Math.ceil(items_total / limit)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
            .split(".")[0]
        }
      </span>
      <span className="inline-flex select-none">
        <button
          type="button"
          className={`
            select-none py-1 px-2 
            border border-[#dfe2e8] 
            text-sm leading-5 
            focus:outline-none 
            active:bg-gray-50 active:text-gray-800 
            transition duration-150 ease-in-out
            ${
              next_page && !loading
                ? "text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue"
                : "text-gray-300 cursor-default pointer-events-none"
            }
          `}
          onClick={onNext}
        >
          <span className="mdi mdi-chevron-right"></span>
        </button>
        <button
          type="button"
          className={`
            ml-1
            select-none py-1 px-2 
            border border-[#dfe2e8] 
            text-sm leading-5 
            focus:outline-none 
            active:bg-gray-50 active:text-gray-800 
            transition duration-150 ease-in-out
            ${
              next_page && !loading
                ? "text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue"
                : "text-gray-300 cursor-default pointer-events-none"
            }
          `}
          onClick={() => {
            onSetPageNum(Math.ceil(items_total / limit));
          }}
        >
          <span className="mdi mdi-chevron-double-right"></span>
        </button>
      </span>
    </div>
  );
};

export const FetchMoreControl = ({ onFetchMore, label = "Load more" }) => (
  <div className="flex-1 flex justify-end pt-2 select-none">
    <span className="ml-0 inline-flex">
      <button
        type="button"
        className="py-1 px-4 border border-[#dfe2e8] text-sm leading-5 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
        onClick={onFetchMore}
      >
        {label}
      </button>
    </span>
  </div>
);

export const ActionList = ({ onClearSelection, children }) => {
  const [visible, setVisibility] = useState(false);
  const show = () => {
    setVisibility(true);
  };
  const hide = () => {
    setVisibility(false);
  };
  const mainDivCss = visible
    ? "origin-top-right absolute left-0 bottom-0 mt-2 w-56 "
    : "origin-top-right absolute left-0 bottom-0 mt-2 w-56  hidden";

  return (
    <div
      className="flex-11 flex justify-left sm:justify-end select-none"
      onMouseEnter={show}
      onMouseLeave={hide}
    >
      <div className="relative inline-block text-left">
        <div>
          <span className="">
            <button
              type="button"
              className="inline-flex justify-center w-full border border-[#dfe2e8] px-2 py-1 bg-white text-sm leading-5 text-gray-500 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            >
              With selected
              <svg
                className="-mr-1 ml-0.5 h-4 w-4 mt-0.5"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </span>
        </div>
        <div className={mainDivCss}>
          <div className="bg-white ">
            <div className="p-1 border-[#dfe2e8] border">
              {children}
              <div className="border-t border-[#dfe2e8] mb-1"></div>
              <a
                onClick={onClearSelection}
                className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                style={{ cursor: "pointer" }}
              >
                Clear selection
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ActionGroup = ({ children }) => (
  <div className="pb-1 select-none">{children}</div>
);

export const Action = ({ onClick = () => {}, label }) => (
  <a
    style={{ cursor: "pointer" }}
    className="select-none group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
    onClick={onClick}
  >
    {label}
  </a>
);

export const Divider = () => <div className="border-t border-gray-100"></div>;
