import React from "react";
import Dropzone from "./../mood-ui/files/Dropzone";

const FileUploadModal = ({
  onClose = () => {},
  onComplete = () => {},
  onError = () => {}, 
  isVisible = false,
  message = "Drop file here",
}) => {
  if (!isVisible) return <div></div>;

  const visibilityStyle = isVisible ? "" : "hidden";
  const overlayIndex = isVisible ? 100 : -10000;
  return (
    <div
      className={`${visibilityStyle} fixed inset-0 px-4 pb-4 sm:flex sm:items-center sm:justify-center`}
      style={{ zIndex: overlayIndex }}
    >
      <div
        className={`${visibilityStyle} absolute inset-0 transition-opacity bg-gray-500 opacity-75`}
        onClick={onClose}
      >
      </div>

      <div
        className={`${visibilityStyle} bg-white rounded-lgs p-8 shadow-xl transform transition-all`}
      >
        <div className="">
          <div className="mt-3s text-left w-full">
            <h3 className="text-lg leading-6 text-gray-500">
              {message}
            </h3>

            <div
              className="mt-4 w-full"
              style={{ overflowY: "auto", maxHeight: "34.5em" }}
            >
              <Dropzone type="csv" text="Upload CSV" storageId="csv_imports" onUploadComplete={onComplete} />
            </div>
          </div>
        </div>
        <div className="mt-3 flex flex-row-reverse">
          <span className="mt-3 flex w-full shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="inline-flex justify-center w-full border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={onClose}
            >
              Close
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModal;
