import React from "react";

const MultipleFilterValue = ({
  isBoolean = false, 
  filterId: id,
  filterValue: name,
  onClick: toggleFilterValue,
  isSelected,
  count = 0, 
}) => {
  const cssClass = isSelected
    ? "relative w-full max-w-full flex items-center p-1 text-xs text-gray-700 hover:bg-[#eadcfa] hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-700 bg-[#F3E8FF]"
    : "relative w-full max-w-full flex items-center p-1 text-xs text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-700";

  return (
    <button
      type="button"
      className={cssClass}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        toggleFilterValue(id);
        e.target.blur()
      }}
    >
      {isSelected && (
        <svg className="h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
        </svg>
      )}
      {!isSelected && (
        <svg className="w-4 h-4 mr-2 opacity-50"  viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="16" height="16" />
        </svg>
      )}

     <div className="flex w-[calc(100%-24px)] justify-between items-center pointer-events-none">
      <div className="flex-shrink truncate elipsis ... pr-2">
        { 
          isBoolean && name == '0' 
          ? 'FALSE'
          : isBoolean && name == '1' 
          ? 'TRUE'
          : name
        }
      </div>
      <span 
        className={`
          ${count > 0 
            ? 'bg-[#BEA0CD] text-white' 
            : 'bg-gray-50'
          } 
          h-5 w-fit max-w-fit 
          flex items-center justify-center 
          text-[11px] tabular-nums
          px-[5px] 
          rounded-[2px]
          flex-grow
        `}
      >
        {count.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0] || 0}
      </span>
     </div>

    </button>
  );
};

export default MultipleFilterValue;
