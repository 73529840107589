import React from "react";
import { InlineBlock, HorizontalSpacing } from "./../Layout";
import TypeFilter from "./TypeFilter";
import FilteringTitles from "./FilteringTitles";
import Controls from "./Controls";
import DynamicLists from "./DynamicLists";

const TypeFilters = ({
  data,
  onApply: handleApplyFilters,
  onReset: handleResetFilters,
  onRemoveValue: handleRemoveFilterValue,
  hasActiveFilters,
  onUpdate: updateFilter,
  onUpdateDates: updateDatesFilter,
  selectedFilters,
  filterBaseTitle = "Filters",
  activeDynamicListId = false,
  dynamicLists = [],
  onDynamicListChange = (id) => { },
  canSaveList = () => false,
  canSaveNewList = () => false,
  onSaveList = () => { },
  onSaveNewList = () => { },
}) => {
  if (!data) return <div></div>;

  return (
    <React.Fragment>
      <div className="flex flex-col">
        <div className="flex items-center">
          {dynamicLists && dynamicLists.length > 0 && (
            <React.Fragment>
              <div className="pb-1 mr-1">
                <div className="flex">
                  <div className="flex flex-col" style={{ maxWidth: "170px" }}>
                    <strong>Dynamic Lists: </strong>
                  </div>
                </div>
              </div>
              <div className="flex-col">
                <DynamicLists
                  lists={dynamicLists}
                  activeListId={activeDynamicListId}
                  onChange={onDynamicListChange}
                  canSaveList={canSaveList()}
                  onSaveList={onSaveList}
                />
              </div>
            </React.Fragment>
          )}
          <div className="pb-1 mr-1">
            {data && data.length > 0 && (
              <div className="flex">
                <div className="flex flex-col" style={{ maxWidth: "170px" }}>
                  <strong>{filterBaseTitle}: </strong>
                </div>
              </div>
            )}
          </div>
          <div className="flex">
            <div className="flex-col">
              {data &&
                data.length > 0 &&
                data.map((typeFilter, index) => (
                  <InlineBlock key={index}>
                    {typeFilter.group != "Base" &&
                      index > 0 &&
                      data[index - 1].group != typeFilter.group && (
                        <HorizontalSpacing pixels={100} />
                      )}
                    <TypeFilter
                      options={typeFilter}
                      selectedValues={
                        selectedFilters[typeFilter.queryKey]
                          ? selectedFilters[typeFilter.queryKey]
                          : []
                      }
                      onUpdateFilter={updateFilter}
                      onUpdateDatesFilter={updateDatesFilter}
                    />
                  </InlineBlock>
                ))}
            </div>
            <div className="flex-col">
              <Controls
                showApplyButton={hasActiveFilters}
                showResetButton={hasActiveFilters}
                onApplyFilters={handleApplyFilters}
                onResetFilters={handleResetFilters}
                showSaveAsNewListButton={canSaveNewList()}
                onSaveNewList={onSaveNewList}
              />
            </div>
          </div>
        </div>

        {hasActiveFilters > 0 && (
          <div className="flex-col  items-center">
            <FilteringTitles
              selectedValues={selectedFilters}
              options={data}
              onRemoveFilterValue={handleRemoveFilterValue}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TypeFilters;
