export const updateTagMutation = `mutation updateTag($id: ID!, $slug: String!, $title: String!) {  
  updateTag(id: $id, slug: $slug, title: $title) {
    id
    slug
    title
  }
}
`;

export const createTagMutation = `mutation createTag($slug: String!, $title: String!, $type_id: Int!) {  
createTag(slug: $slug, title: $title, type_id: $type_id) {
  id
  slug
  title
 }
}
`;

export const deleteTagMutation = `mutation deleteTag($id: ID!) {  
  deleteTag(id: $id) {
    id    
   }
  }
  `;
