export const allOffers = `query offers($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    offers(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      admin_title 
      title
      provider
      description
      code
      start_date   
      end_date
      status
      image
      background
      method      
      methodIcon
      cta
      public
      verb
      saving
      redeem
      redeem_url
      redeem_external_url
      related_offers
      search_terms
      promocodes_active
      promocodes_static
      reveal_cta
      merchant {
        id
        title
        slug
      }
      tags {
        id
        slug
        title
      }
      exclusionTags {
        id
        slug
        title
      }
    }
  }
`;
export const lightOffers = `query offers($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    offers(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      provider
      admin_title 
      title
      code
      start_date   
      end_date
      status
      related_offers
      image
      background
      saving
      method
      methodIcon
      merchant {
        id
        title
        slug
      }
    }
  }
`;

export const lightTilloOffers = `query tilloOffers($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  tilloOffers(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      provider
      admin_title 
      title
      code
      start_date   
      end_date
      status
      related_offers
      image
      background
      saving
      method
      methodIcon
      merchant {
        id
        title
        slug
      }
    }
  }
`;

export const allTilloOffers = `query tilloOffers($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    tilloOffers(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      admin_title 
      title
      provider
      description
      code
      start_date   
      end_date
      status
      image
      background
      method      
      methodIcon
      cta
      public
      verb
      saving
      redeem
      redeem_url
      redeem_external_url
      related_offers
      search_terms
      promocodes_active
      promocodes_static
      reveal_cta
      tags {
        id
        slug
        title
      }
      exclusionTags {
        id
        slug
        title
      }
      merchant {
        id
        title
        slug
      }
      card_logo
      card_background
    }
  }
  `;

export const allTilloPendingBrands = `query tilloPendingBrands {  
  tilloPendingBrands {
      id
      name
      slug
      serialized_data
      status
      created_at
    }
  }
  `;

export const offerDetails = `query offer($id: ID!) {
  offer(id: $id) {
    id    
    merchant {
      id
    }
    title
    code
    description
    background
    image
    method
    methodIcon
    cta
    premium_gifted
    premium_acquired
    basic
    public
    verb
    saving
    terms
    redeem
    redeem_url
    redeem_external_url
    related_offers
    start_date
    end_date
    admin_title
    status
    search_terms
    promocodes_static
    static_promocode
    reveal_cta
    promocode_low_stock_percentage
    promocode_low_stock_alert_emails
    promocodes_active
    steps {
      title
      text
    }
    tags {
      id
      slug
      title
    }
    exclusionTags {
      id
      slug
      title
    }
    labels {
      id
      name
    }
  }
  }
  `;

export const allSections = `query sections($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    sections(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      title
      slug
      description 
      offer_ids
    }
  }
  `;

export const sectionDetails = `query section($id: ID!) {
  section(id: $id) {
    id
    title
    slug
    description
    offer_ids
    basic
    premium_acquired
    premium_gifted
    labels {
      id
      name
    }
  }
  }
  `;

export const refreshCinemaChains = `query cinemaChainFetchAPI {  
  cinemaChainFetchAPI {
    result
  }
    }
    `;

export const allCinemaChains = `query cinemaChains($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  cinemaChains(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      name
      description 
      discount_adjustment
      status
    }
  }
  `;

export const allCinemaVenues = `query cinemaVenues($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    cinemaVenues(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
        id
        name
        chain_name
        postcode
        city
        status
      }
    }
    `;

export const cinemaChainDetails = `query cinemaChain($id: ID!) {
  cinemaChain(id: $id) {
      id
      name      
      description
      discount_adjustment
      status
      image
    }
  }
  `;

export const cinemaVenueDetails = `query cinemaVenue($id: ID!) {
  cinemaVenue(id: $id) {
        id
        name      
        chain_name
        postcode
        city
        county
        address_1
        address_2
        address_3
        status
      }
    }
    `;

export const allCinemaProducts = `query cinemaProducts($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    cinemaProducts(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
        id
        sku
        title
        single_price 
        discount_adjustment
        final_price
        rrp_price
        chain_name
        venue_name
        status
      }
    }
    `;

export const cinemaProductDetails = `query cinemaProduct($id: ID!) {
    cinemaProduct(id: $id) {
          id
          sku
          title      
          description
          copy
          terms
          expires
          single_price
          discount_adjustment
          final_price
          rrp_price
          venue_name
          chain_name          
          status
          chain {
            discount_adjustment
          }
        }
      }
      `;

// export const allMerchants = `query merchants($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {
//     merchants(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
//       id
//       title
//       slug
//       status
//       description
//       image
//       background
//       related_merchants
//       created_at
//       updated_at
//     }
//   }
// `;
export const allMerchants = `query merchants($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    merchants(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      title 
      slug
      status
      image
      background
    }
  }
`;

export const lightMerchants = `query merchants($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    merchants(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      title 
      slug
      status
      image
      related_merchants
    }
  }
`;

export const merchantDetails = `query merchant($id: ID!) {
    merchant(id: $id) {
      id
      title
      slug
      status
      description
      image
      background
      related_merchants
    }
    }
    `;

export const tilloOfferDetails = `query tilloOffer($id: ID!) {
  tilloOffer(id: $id) {
        id    
        merchant {
          id
        }
        title
        code
        description
        background
        image
        card_logo
        card_background
        method
        methodIcon
        cta
        premium_gifted
        premium_acquired
        basic
        public
        verb
        saving
        saving_diff
        face_value_limit_lower
        face_value_limit_upper
        face_value_minor_unit
        max_cards_per_online
        max_cards_per_instore
        terms
        redeem
        redeem_url
        redeem_external_url
        related_offers
        start_date
        end_date
        admin_title
        status
        search_terms
        reveal_cta
        steps {
          title
          text
        }
        tags {
          id
          slug
          title
        }
        exclusionTags {
          id
          slug
          title
        }
        labels {
          id
          name
        }
        tillo_offer {
          slug
          name
          status_code
          currency
          discount
          last_updated
          gc_pool
          async_only
          vat_exempt
          vat_type
          var_rate
          digital_face_value_limits_lower
          digital_face_value_limits_upper
          digital_face_value_limits_minor_unit
          digital_general_restrictions
          instore_order_restrictions
          online_order_restrictions
          assets_logo_url
          assets_gift_card_url
          balance_enquiry_url
          description
          barcode
          expiry
          terms_and_conditions_url
          website_url
          terms_and_conditions_copy
          redemption_instructions_url
          categories {
            name            
          }
          transaction_types {
            name
          }
          delivery_methods { 
            name
          }
          countries_served {
            name
          }
          redemption_methods {
            name
          }
          denominations {
            name
          }
          digital_denominations {
            name
          }
        }
      }
      }
      `;
