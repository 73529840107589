import React from "react";
import moment from 'moment'
import { truncateNumber, commaInt, commaFloat } from "./../../hooks/utils";

const getMax = (summary, name = '') => {
  if (summary.max !== null && summary.max !== undefined) {
    return summary.__typename === 'DateSummary' 
      ? moment(summary.max).format('DD MMM\'YY')
      : summary.__typename === 'PercentageSummary' 
      ? truncateNumber(summary.max)[0] + truncateNumber(summary.max)[2] + ' %'
      : summary.__typename === 'ScoreSummary' 
      ? truncateNumber(summary.max)[0] + truncateNumber(summary.max)[2]
      : summary.__typename === 'CurrencySummary' 
      ? '£' + truncateNumber(summary.max)[0] + truncateNumber(summary.max)[2]
      : truncateNumber(summary.max)[0] + truncateNumber(summary.max)[2]
  } 
  return '--'
}
const getMin = (summary, name = '') => {
  if (summary.min !== null && summary.min !== undefined) {
    return summary.__typename === 'DateSummary' 
      ? moment(summary.min).format('DD MMM\'YY')
      : summary.__typename === 'PercentageSummary' 
      ? truncateNumber(summary.min)[0] + truncateNumber(summary.min)[2] + ' %'
      : summary.__typename === 'ScoreSummary' 
      ? truncateNumber(summary.min)[0] + truncateNumber(summary.min)[2] 
      : summary.__typename === 'CurrencySummary' 
      ? '£' + truncateNumber(summary.min)[0] + truncateNumber(summary.min)[2]
      : truncateNumber(summary.min)[0] + truncateNumber(summary.min)[2]
  }
  return '--'
}
const getMinAbbr = (summary, name = '') => { 
  if (summary.min !== null && summary.min !== undefined) {
    return summary.__typename === 'DateSummary' 
    ? `Earliest -- ${ moment(summary.min).format('ddd - DD - MMM\'YY') }`
    : summary.__typename === 'CurrencySummary' 
    ? `Min --  £ ${ commaFloat(summary.min) }`
    : summary.__typename === 'PercentageSummary' 
    ? `Min --  ${ commaFloat(summary.min) }` + ' %'
    : summary.__typename === 'ScoreSummary' 
    ? `Min --  ${ commaFloat(summary.min) }`
    : summary.__typename === 'NumberSummary' 
    ? `Min --  ${ commaInt(summary.min) }`
    : `Min --  ${ summary.min }`
  }
  return '--'
}
const getMaxAbbr = (summary, name = '') => { 
  if (summary.max !== null && summary.max !== undefined) {
    return summary.__typename === 'DateSummary' 
    ? `Latest --- ${ moment(summary.max).format('ddd - DD - MMM\'YY') }`
    : summary.__typename === 'CurrencySummary' 
    ? `Max -- £ ${ commaFloat(summary.max) }`
    : summary.__typename === 'PercentageSummary' 
    ? `Max -- ${ commaFloat(summary.max) }` + ' %' 
    : summary.__typename === 'ScoreSummary' 
    ? `Max -- ${ commaFloat(summary.max) }`
    : summary.__typename === 'NumberSummary' 
    ? `Max -- ${ commaInt(summary.max) }`
    : `Max --  ${ summary.max }`
  }
  return '--'
}

const getAvg = (summary, name = '') => {
  if (summary.avg !== null && summary.avg !== undefined) {
    return summary.__typename === 'DateSummary' 
      ? moment(summary.avg).format('DD MMM\'YY')
      : summary.__typename === 'PercentageSummary' 
      ? truncateNumber(summary.avg)[0] + truncateNumber(summary.avg)[2] + ' %' 
      : summary.__typename === 'ScoreSummary' 
      ? truncateNumber(summary.avg)[0] + truncateNumber(summary.avg)[2]
      : summary.__typename === 'CurrencySummary' 
      ? '£' + truncateNumber(summary.avg)[0] + truncateNumber(summary.avg)[2]
      : truncateNumber(summary.avg)[0] + truncateNumber(summary.avg)[2]
  }
  return '--'
}
const getSum = (summary, name = '') => {
  if (summary.sum !== null && summary.sum !== undefined) {
    return summary.__typename === 'CurrencySummary' 
      ? '£' + truncateNumber(summary.sum)[0] + truncateNumber(summary.sum)[2]
      : truncateNumber(summary.sum)[0] + truncateNumber(summary.sum)[2]
  }
  return '--'
}
const getAvgSumAbbr = (summary, name = '') => { 
  if (summary.avg !== null && summary.avg !== undefined) {
    return summary.__typename === 'DateSummary' 
      ? `Avg --  ${ moment(summary.avg).format('ddd - DD - MMM\'YY') }`
      : summary.__typename === 'CurrencySummary' 
      ? `Avg ---  £ ${ commaFloat(summary.avg) }` + (summary.sum !== null && summary.sum !== undefined ? `\nSum --  £ ${ commaFloat(summary.sum) }` : '')
      : summary.__typename === 'PercentageSummary' 
      ? `Avg ---  ${ commaFloat(summary.avg) } %` + (summary.sum !== null && summary.sum !== undefined ? `\nSum --  ${ commaFloat(summary.sum) } %` : '')
      : summary.__typename === 'ScoreSummary' 
      ? `Avg ---  ${ commaFloat(summary.avg) }` + (summary.sum !== null && summary.sum !== undefined ? `\nSum --  ${ commaFloat(summary.sum) }` : '')
      : summary.__typename === 'NumberSummary' 
      ? `Avg ---  ${ commaFloat(summary.avg) }` + (summary.sum !== null && summary.sum !== undefined ? `\nSum --  ${ commaInt(summary.sum) }` : '')
      : `Avg ---  ${ Math.round(summary.avg * 100) / 100 }` + (summary.sum !== null && summary.sum !== undefined ? `\nSum --  ${ commaInt(summary.sum) }` : '')
  }
  return '--'
}

const getNotBlankPercentage = (summary, name = '') => { 
  return truncateNumber(summary.__typename === 'BooleanSummary' 
    ? summary.is_true / (summary.is_true + summary.is_false) * 100
    : summary.is_not_blank / (summary.is_not_blank + summary.is_blank) * 100)[1]
}
const getBlankPercentage = (summary, name = '') => { 
  return truncateNumber(summary.__typename === 'BooleanSummary' 
    ? summary.is_false / (summary.is_true + summary.is_false) * 100
    : summary.is_blank / (summary.is_not_blank + summary.is_blank) * 100)[1]
}
const getBlanksAbbr = (summary, name = '') => {
  return (summary.__typename === 'BooleanSummary' 
    ? `True --- ${ commaInt(summary.is_true) } / ${ commaInt(summary.is_false + summary.is_true) } -- ${ getNotBlankPercentage(summary) }%`
    : `Present -- ${ commaInt(summary.is_not_blank) } / ${ commaInt(summary.is_blank + summary.is_not_blank) } -- ${ getNotBlankPercentage(summary) }%`)
    +
    (summary.__typename === 'BooleanSummary' 
    ? `\nFalse -- ${ commaInt(summary.is_false) } / ${ commaInt(summary.is_false + summary.is_true) } -- ${ getBlankPercentage(summary) }%`
    : `\nMissing -- ${ commaInt(summary.is_blank) } / ${ commaInt(summary.is_blank + summary.is_not_blank) } -- ${ getBlankPercentage(summary) }%`)
}
const getBlanksCounts = (summary, name = '') => {
  return summary.__typename !== 'BooleanSummary' 
    ? `${ truncateNumber(summary.is_not_blank)[0] }${ truncateNumber(summary.is_not_blank)[2] } / ${ truncateNumber(summary.is_blank + summary.is_not_blank)[0] }${ truncateNumber(summary.is_blank + summary.is_not_blank)[2] }`
    : `${ truncateNumber(summary.is_true)[0] }${ truncateNumber(summary.is_true)[2] } / ${ truncateNumber(summary.is_false + summary.is_true)[0] }${ truncateNumber(summary.is_false + summary.is_true)[2] }`
}

const getNotBlanks = (summary, name = '') => {
  return summary.__typename !== 'BooleanSummary' 
    ? `${ truncateNumber(summary.is_not_blank)[0] }${ truncateNumber(summary.is_not_blank)[2] }`
    : `${ truncateNumber(summary.is_true)[0] }${ truncateNumber(summary.is_true)[2] }`
}

const ListHeaderCell = ({
  children,
  value = '',
  name,
  sortBy,
  sortHow,
  onSort, 
  icon = null, 
  tweaks = '', 
  topSticky = '-30px',
  summary = false, 
  showSummaries = true, 
  summaryHeader = false, 
}) => {
  
  return (
    <th
      className={`before:pointer-events-none before:block before:absolute before:top-[0px] before:bottom-[1.5px] before:border-[#FCFCFE] 
        before:-left-[2px] before:right-[0px] before:border-l-[4px] before:border-r-[2px] before:border-t-4
        select-none pl-pxs pr-[1px] pb-2 pt-0.5 s-mt-2 font-normal border-transparent outline outline-2 outline-[#ecdaf5] outline-offset-[-2px] bg-[#FCFCFE] 
        text-[10.5px] leading-3 text-gray-400  ${tweaks} sticky top-[${ topSticky }] z-10 
        ${ name === sortBy ? 'before:border-b-[#BB93CF] before:border-b-2' : '' }
        sbg-indigo-500 align-bottom 
      `}
    >
      <div className="flex justify-between w-full flex-wrap items-end "> 

        { summaryHeader && 
          <div 
            className={`
              flex-wrap justify-center
              sw-[calc(100%)]
              mb-1.5 mr-[-2px] ml-[-1px] divide-y
              font-mono slashed-zero tabular-nums text-[10px] text-left text-slate-500 
              sborder bg-gray-50s border-gray-200
              text-right 
            `}
          > 
            {
              showSummaries &&
              <React.Fragment>
                <div className="mt-[-16px] border-t-transparent opacity-80 inline-flex pl-0.5 pr-1.5 pb-[2px] pt-[4px] items-center justify-end w-full w-full sh-[16px] whitespace-nowrap">
                  Has data
                </div>
                <div className="opacity-80 inline-flex pl-0.5 pr-1.5 pb-[2px] pt-[4px] items-center justify-end w-full w-full sh-[19px] whitespace-nowrap">
                  Min / earliest
                </div>
                <div className="opacity-80 inline-flex pl-0.5 pr-1.5 pb-[2px] pt-[4px] items-center justify-end w-full w-full sh-[19px] whitespace-nowrap">
                  Max / latest
                </div>
                <div className="opacity-80 inline-flex pl-0.5 pr-1.5 pb-[2px] pt-[4px] items-center justify-end w-full w-full sh-[19px] whitespace-nowrap">
                  Avg / sum
                </div>
              </React.Fragment>
            }
          </div>
        }
        { !summaryHeader && summary && showSummaries &&
          <div 
            className={`
              sflex-wrap justify-center
              w-[calc(100%)]
              mb-1.5 mr-[-2px] ml-[-1px] mt-[2px] divide-y
              border-x border-y
              font-mono slashed-zero tabular-nums text-[11px] text-left text-slate-500 
              bg-gray-50 border-gray-200
              z-50
              min-h-[100%] 
              ${ summary.__typename === 'BooleanSummary' && ' pb-[55px] ' }
            `}
          > 
            <abbr 
              title={getBlanksAbbr(summary, name)} 
              className={`
                inline-flex w-full min-w-fit opacity-80 hover:opacity-100 
              `}
            >
              <div 
                className={`
                  inline-flex w-full justify-between h-[18px] whitespace-nowrap
                  ${ summary.__typename === 'BooleanSummary' && ' border-b border-gray-200 h-[19px] ' }
                `}
              >
                <span className="pt-[2px] px-[3px] h-[18px] flex justify-center items-center w-[50%] bg-[#f7f9fc]">
                  { getNotBlanks(summary, name) }
                </span>
                <span className="pt-[2px] px-[3px] h-[18px] flex justify-center items-center w-[50%] bg-[#ecf1f8]">
                  { getNotBlankPercentage(summary, name) }%
                </span>
              </div>
            </abbr>
            {
              summary.__typename !== 'OptionsCount' && summary.__typename !== 'BooleanSummary' && 
              <div className="flex-wrap max-w-full divide-y pb-px">
                <abbr title={getMinAbbr(summary, name)} className="inline-flex w-full justify-between h-[18px] opacity-80 hover:opacity-100">
                  <span className="pt-[2px] pl-[4px] pr-[3px] h-[18px] flex justify-center items-center w-[100%] bg-[#faeff1] border-gray-100">
                    { getMin(summary, name) }
                  </span>
                </abbr>  
                <abbr title={getMaxAbbr(summary, name)} className="inline-flex w-full justify-between h-[18px] opacity-80 hover:opacity-100">
                  <span className="pt-[2px] pl-[4px] pr-[3px] h-[18px] flex justify-center items-center w-[100%] bg-[#e7f8e9] border-gray-100">
                    { getMax(summary, name) }
                  </span>
                </abbr>
                <abbr title={getAvgSumAbbr(summary, name)} className="inline-flex w-full justify-between divide-x h-[18px] opacity-80 hover:opacity-100 ">
                  {
                    summary.avg !== undefined && 
                    <span className="pt-[2px] px-[3px] h-[18px] flex justify-center items-center w-[100%] bg-[#f7f9fc]">
                      { getAvg(summary, name) }
                    </span>
                  }
                  {
                    summary.sum !== undefined && 
                    <span className="pt-[2px] px-[3px] h-[18px] flex justify-center items-center w-[100%] bg-[#ecf1f8]">
                      { getSum(summary, name) }
                    </span>
                  }
                </abbr>
              </div>
            }
            { summary.__typename === 'OptionsCount' && 
              <div className="flex-wrap h-[56px] max-w-full overflow-y-auto scrollbar-hide overscroll-contain ">
                {
                  [ ...(summary.options || []) ]
                  .filter(option => option.value !== '')
                  .sort((a, b) => b.count - a.count )
                  .map((option, index) => {
                    return (
                      <abbr 
                        key={option.value} 
                        title={`${ option.value } -- ${ commaInt(option.count) } -- ${ commaFloat(option.count / summary.is_not_blank * 100) } %`} 
                        className="inline-flex w-full justify-between h-[16px] opacity-80 hover:opacity-100"
                      >
                        <span 
                          className={`
                            pt-[2px] pl-[4px] pr-[3px] h-[15px] flex justify-between divide-x items-center w-[100%] border-gray-100
                            ${ index % 2 ? ' bg-[#f0f4f9] ' : ' bg-[#f7f9fc] ' }
                          `}
                        >
                          <span className="truncate max-w-[calc(100%-30px)]">
                            { option.value }
                          </span>
                          <span className="w-[30px] max-w-[30px] text-right">
                            { option.count }
                          </span>
                        </span>
                      </abbr>
                    )
                  })
                }
              </div>
            }
          </div>
        }
        <div 
          onClick={() => { return onSort ? onSort(name) : false }} 
          className={`flex justify-between w-full flex-wrap items-end ${ onSort ? ' cursor-pointer ' : '' }`}
        > 
          {
            !onSort || !name
            ? ( value || icon
                ? <abbr 
                    title={ value } 
                    className={`w-full overflow-hidden ${ value && 'truncate text-elipsis' }`}
                  >
                    { icon && <span className={`mdi mdi-${ icon } inline-block translate-y-[1px] text-[13px] mr-1`}></span> }
                    { value && value }
                  </abbr>
                : children
              )
            : <React.Fragment>
              { value || icon
                ? <abbr 
                    title={ value } 
                    className={`w-full overflow-hidden translate-x-[19px] pr-[8px] ml-[-20px] ${ value && ' truncate text-elipsis' }`}
                  >
                    { icon && <span className={`mdi mdi-${ icon } inline-block translate-y-[1px] text-[13px] mr-0.5`}></span> }
                    { value && value }
                  </abbr>
                : children
              }
              { name !== sortBy && <span className={`mdi mdi-dots-vertical text-[14px] mr-[-2px] mt-[1px] opacity-30`}></span> }
              { name === sortBy && sortHow === "asc"  && <span className={`mdi mdi-chevron-up text-[14px] text-gray-900 mr-[-2px] mt-[1px]`}></span> }
              { name === sortBy && sortHow === "desc" && <span className={`mdi mdi-chevron-down text-[14px] text-gray-900 mr-[-2px] mt-[1px]`}></span> }
            </React.Fragment>
          }
        </div>
      </div>
    </th>
  );
};



export default ListHeaderCell;