import React from "react";

const getButtonColorStylesByMood = (mood) => {
  if (mood == "Error" || mood == "Danger") {
    return "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5";
  } else if (mood == "Warning") {
    return "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-yellow-400 text-base leading-6 font-medium text-white shadow-sm hover:bg-yellow-300 focus:outline-none focus:border-yellow-500 focus:shadow-outline-yellow transition ease-in-out duration-150 sm:text-sm sm:leading-5";
  } else if (mood == "Info") {
    return "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5";
  }
  return "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5";
};

export const ConfirmActionModal = ({
  onCancel,
  onConfirm,
  mood = "Info",
  isVisible = false,
  confirmButtonLabel,
  cancelButtonLabel = "Cancel",
  heading = "",
  message = "",
}) => {
  const confirmButtonStyles = getButtonColorStylesByMood(mood);

  const visibilityStyle = isVisible ? "" : "hidden";
  const overlayIndex = isVisible ? 100 : -10000;
  return (
    <div
      className={`${visibilityStyle} fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center`}
      style={{ zIndex: overlayIndex }}
    >
      <div className={`${visibilityStyle} fixed inset-0 transition-opacity`}>
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        className={`${visibilityStyle} bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6`}
      >
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {heading}
            </h3>
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500">{message}</p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              className={confirmButtonStyles}
              onClick={onConfirm}
            >
              {confirmButtonLabel}
            </button>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={onCancel}
            >
              {cancelButtonLabel}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};
