import React, { useContext } from "react";
import { EXCLUSION_TAG_TYPE_ID } from "../configs/constants";
import { TagTypeContext } from "../contexts/TagTypeContext";

const TagTypeContextSwitch = ({
  textHeading = "This data depends on Tag Type context.",
}) => {
  const context = useContext(TagTypeContext);
  let color = "blue";
  if (context.activeType == EXCLUSION_TAG_TYPE_ID) {
    color = "red";
  }

  return (
    <div className={`rounded-md bg-${color}-50 p-4 my-3`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className={`h-5 w-5 text-${color}-400`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className={`text-sm leading-5 text-${color}-700`}>
            {textHeading} Current context is{" "}
            <strong>
              {
                context.types.filter(
                  (l) => parseInt(l.id) === parseInt(context.activeType)
                )[0].name
              }
            </strong>
            . You can switch to
            {context.types.map((l) => (
              <span key={l.id}>
                {parseInt(l.id) !== parseInt(context.activeType) && (
                  <React.Fragment>
                    &nbsp;
                    <span
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() => context.onChangeType(l.id)}
                    >
                      {l.name}
                    </span>
                  </React.Fragment>
                )}
              </span>
            ))}
            &nbsp;context.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TagTypeContextSwitch;
