import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { otherSystemUsersBriefly } from "../../graphql/queries/rolePermissionQueries";

import { TextField } from "../../mood-ui/Fields";
import GenericDataModal, {
  GenericSaveControls,
} from "../../mood-ui/modals/GenericDataModal";

const OTHER_SYSTEM_USERS_BRIEFLY = gql(otherSystemUsersBriefly);

const NONE = "None";
const VIEW = "View";
const EDIT = "Edit";

const EVERYBODY_USER = {
  id: 0,
  name: "All users in Control",
  email: "Set the default access for this list",
}; 

const DynamicListSaveModal = ({
  name: oldName = '', 
  permissions: oldPermissions = [], 
  isVisible = false,
  owner = null, 
  canEdit = false, 
  onSave = () => {},
  onCancel = () => {}, 
  myEmail = null, 
  hasSnapshots = false, 
}) => {
 
  const [name, setName] = useState(oldName);
  
  const [permissions, setPermissions] = useState(oldPermissions);
  
  const [sharedWithOthers, setSharedWithOthers] = useState(oldPermissions.length > 0);

  const { data, loading, error, refetch } = useQuery(OTHER_SYSTEM_USERS_BRIEFLY, {
    fetchPolicy: "cache-and-network", 
    onCompleted: (data) => { 
      const allPermission = { 
        id: 0, 
        permission: oldPermissions.find(p => { return p.id == 0})?.permission || NONE, 
      }
      const newPermissions = data.otherSystemUsersBriefly
      .map((item) => {
        return { 
          id: item.id, 
          permission: oldPermissions.find(p => { return p.id == item.id})?.permission || allPermission.permission, 
        };
      })
      setPermissions([allPermission, ...newPermissions]);
    },
  });

  useEffect(() => {
    if (name !== oldName) {
      setName(oldName)
      setSharedWithOthers(oldPermissions.length > 0)
      refetch()
    }
  }, [oldName]);

  const setAllPermissionsTo = (perm) => {
    setPermissions((curPermissions) =>
      curPermissions.map((p) => ({ ...p, permission: perm }))
    );
  };

  const handleUserPermissionChange = (user, permission) => {
    setPermissions((curPermissions) =>
      curPermissions.map((item) =>
        item.id === user.id ? { ...item, permission } : item
      )
    );
  };

  const handleEverybodyUserPermissionChange = (_, permission) =>
    setAllPermissionsTo(permission);

  const handleSharedWithOthersChange = () => {
    if (sharedWithOthers) setAllPermissionsTo(NONE);
    setSharedWithOthers(!sharedWithOthers);
  };

  const shareToggleStyle = {
    btn: sharedWithOthers
      ? "bg-indigo-600 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent focus:outline-none sfocus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      : "bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent focus:outline-none focsus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
    span: sharedWithOthers
      ? "translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0"
      : "translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0",
  };

  const cancel = () => {
    refetch()
    onCancel();
  };

  if (!data) return <div></div>;

  return (
    <GenericDataModal isVisible={isVisible}>
      <form className="z-[9999] relative transform overflow-hidden bg-white px-5 pt-5 pb-6 text-left sm:my-auto sm:w-full max-h-[95vh] sm:max-w-xl sm:p-8">
        <div className="flex flex-col">
          { canEdit && (oldName !== '') ?
            <div className="select-none mb-3">
              <h3
                className="text-xl leading-7 text-gray-700"
                id="modal-title"
              >
                Update this dynamic list
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-400">
                Any changes here will impact everything this list is used for.
              </p>
            </div>
          : oldName !== '' ?
            <div className="select-none mb-3">
              <h3
                className="text-xl leading-7 text-gray-700"
                id="modal-title"
              >
                Unable to make changes
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-400">
                You do not have permission to edit this list.
              </p>
            </div>
            :
            <div className="select-none mb-3">
              <h3
                className="text-xl leading-7 text-gray-700"
                id="modal-title"
              >
                Create a new dynamic list
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-400">
                Save this filtered view for quick access, alerting features and summary emails.
              </p>
            </div>
          }
          <div className={`flex items-center w-full justify-between select-none mt-2 psb-5 ${ !sharedWithOthers ? 'border-hb border-gray-200' : '' }`}>
            <div className="flex-1 pb-2">
              <TextField
                name="name"
                value={name}
                onChange={(e) => { setName(e.target.value) }}
                placeholder="Enter a name for your list..."
                minlength={5}
                maxlength={36}
                isRequired={true}
                description={name.length > 1 && name.length < 6 ? 'More than 6 characters' : 'A short and descriptive name'}
                // disabled={!canEdit || ((oldName !== '') && (myEmail !== owner?.email))}
                disabled={!canEdit || ((oldName !== ''))}
              />
            </div>
            <div className="pl-4 mb-8 pr-1 flex">
              <button
                type="button"
                className={shareToggleStyle.btn + ' disabled:pointer-events-none disabled:opacity-40'}
                role="switch"
                aria-checked="false"
                aria-labelledby="annual-billing-label"
                onClick={handleSharedWithOthersChange}
                // disabled={!canEdit}
              >
                <span aria-hidden="true" className={shareToggleStyle.span}></span>
              </button>
              <span className="ml-3 inline-block -mt-0.5" id="annual-billing-label">
                <span className="text-xs text-gray-400">
                  Share with others
                </span>
              </span>
            </div>
          </div>
          {sharedWithOthers && (
            <UserPermissionListField
              users={data.otherSystemUsersBriefly}
              permissions={permissions}
              onChange={handleUserPermissionChange}
              onChangeAll={handleEverybodyUserPermissionChange}
              canEdit={canEdit}
            />
          )}
        </div>
        {
          hasSnapshots && 
          <div>
            <p className="mt-4 -mb-2 text-sm leading-5 font-light text-gray-400 text-center">
              This list has recorded snapshots so 
              <span className="text-rose-400 font-medium"> changes to filters will not be saved. </span>
              You can still change its name and permissions by clicking save.
            </p>
          </div>
        }
        <GenericSaveControls
          onSave={() => onSave({ name, permissions })}
          onCancel={cancel}
          disabled={name.length < 5 || name.length > 36 || !canEdit}
        />
        {owner &&
          <p className="mt-3 mb-1 text-sm leading-5 text-gray-400 text-center">
            List created by { owner.email }
          </p>
        }
      </form>
    </GenericDataModal>
  );
};

const findPermissionFromList = (user, permissions) => {
  const permission = permissions.find((item) => item.id === user.id);
  return permission ? permission.permission : NONE;
};

const UserPermissionListField = ({
  users,
  permissions = [],
  onChange,
  onChangeAll, 
  canEdit = false, 
}) => {
  return (
    <React.Fragment>
      <div className="h-full overflow-y-auto select-none" style={{ maxHeight: "calc(100vh - 380px)" }}>
        <div className="relative">
          {users && (
            <ul className="relative z-0 divide-y divide-gray-200">
              <UserPermissionField
                key={0}
                user={EVERYBODY_USER}
                permission={findPermissionFromList(EVERYBODY_USER, permissions)}
                onChange={onChangeAll}
                canEdit={canEdit}
              />
              {users.map((user) => (
                <UserPermissionField
                  key={user.id}
                  user={user}
                  permission={findPermissionFromList(user, permissions)}
                  onChange={onChange}
                  canEdit={canEdit}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const UserPermissionField = ({ user, permission, onChange, canEdit = false }) => {
  const fieldNoneId = `user_${user.id}_none`;
  const fieldViewId = `user_${user.id}_view`;
  const fieldEditId = `user_${user.id}_edit`;
  const fieldName = `user_${user.id}`;
  return (
    <li className="bg-white">
      <div className="relative flex items-center space-x-3 p-4 hover:bg-gray-50">
        <div className="flex w-full">
          <div className="min-w-fit">
            <label className="text-sm text-gray-500">
              {user.name}
            </label>
            <p className="text-xs leading-5 text-gray-400">{user.email}</p>
          </div>
          <div className="flex justify-end w-full">
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 ml-4">
              <div className="flex items-center">
                <input
                  id={fieldNoneId}
                  name={fieldName}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                  checked={permission === NONE ? true : false}
                  onChange={(e) => onChange(user, NONE)}
                  value={permission}
                  disabled={!canEdit}
                />
                <label
                  htmlFor={fieldNoneId}
                  className="ml-3 block text-sm text-gray-500 cursor-pointer"
                >
                  {NONE}
                </label>
              </div>
            </div>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 ml-4">
              <div className="flex items-center">
                <input
                  id={fieldViewId}
                  name={fieldName}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                  checked={permission === VIEW ? true : false}
                  onChange={(e) => onChange(user, VIEW)}
                  value={permission}
                  disabled={!canEdit}
                />
                <label
                  htmlFor={fieldViewId}
                  className="ml-3 block text-sm text-gray-500 cursor-pointer"
                >
                  {VIEW}
                </label>
              </div>
            </div>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 ml-4">
              <div className="flex items-center">
                <input
                  id={fieldEditId}
                  name={fieldName}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                  checked={permission === EDIT ? true : false}
                  onChange={(e) => onChange(user, EDIT)}
                  value={permission}
                  disabled={!canEdit}
                />
                <label
                  htmlFor={fieldEditId}
                  className="ml-3 block text-sm text-gray-500 cursor-pointer"
                >
                  {EDIT}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default DynamicListSaveModal;


// email
// : 
// "mel@vaboo.co.uk"
// id
// : 
// "9"
// name
// : 
// "Mellissa Potts"
// __typename
// : 
// "SystemUserBriefly"



// email
// : 
// "harriet.smith@vaboo.co.uk"
// id
// : 
// "5"
// name
// : 
// "Harriet Smith"
// __typename
// : 
// "SystemUser"