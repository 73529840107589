import React, { useState } from "react";
import gql from "graphql-tag";
import FileGallery from "./../moose-ui/files/FileGallery";
import { fetchMoreFiles } from "./../graphql/queries/fileQueries";
import useFetchMoreNavigation from "./../hooks/fetchMoreNavigation";
import { Row, Col } from "./Layout";
import Search from "./../moose-ui/Search";
const FETCH_MORE_FILES = gql(fetchMoreFiles);

const ImageGalleryModal = ({ onCancel, onConfirm, isVisible = false }) => {
  const [selectedFile, setSelectedFile] = useState(false);

  const {
    result: files,
    setResult: setFiles,
    error,
    fetchMore,
    hasMore,
    queryContext,
    applySearch,
  } = useFetchMoreNavigation({
    query: FETCH_MORE_FILES,
    entityType: "files",
    queryString: `?storageId=public_images`,
  });

  const handleUploadComplete = (cache, data) => {
    const prevData = cache.readQuery(queryContext);
    const newData = { ...prevData };
    newData.fetchMoreFiles.files = [
      data.uploadFile,
      ...newData.fetchMoreFiles.files,
    ];
    cache.writeQuery({
      ...queryContext,
      data: newData,
    });

    setFiles([...newData.fetchMoreFiles.files]);
  };

  const handleSelectConfirmation = () => {
    if (!selectedFile) return false;
    onConfirm(selectedFile);
  };

  if (error) return <div>Error</div>;
  if (!isVisible) return <div></div>;

  const visibilityStyle = isVisible ? "" : "hidden";
  const overlayIndex = isVisible ? 100 : -10000;
  return (
    <div
      className={`${visibilityStyle} fixed bottom-0 inset-x-0 px-4 pb-4  sm:flex sm:items-center sm:justify-center`}
      style={{ zIndex: overlayIndex }}
    >
      <div
        className={`${visibilityStyle} fixed inset-0 transition-opacity`}
        onClick={onCancel}
      >
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        className={`${visibilityStyle} bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:w-full sm:p-6`}
      >
        <div className="">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Image Gallery
            </h3>

            <div
              className="mt-2"
              style={{ overflowY: "scroll", maxHeight: "34.5em" }}
            >
              <Row>
                <Col width={9}></Col>
                <Col width={3}>
                  <Search onApplySearch={applySearch} />
                </Col>
              </Row>
              {files && (
                <FileGallery
                  showDropzone={true}
                  files={files}
                  queryString={""}
                  applyFilters={() => { }}
                  applySearch={applySearch}
                  resetFilters={() => { }}
                  nextPage={() => { }}
                  prevPage={() => { }}
                  showCopyLink={false}
                  onSelectFile={(file) => setSelectedFile(file)}
                  onSelectFileConfirmed={(file) => {
                    setSelectedFile(file);
                    handleSelectConfirmation();
                  }}
                  fetchMore={fetchMore}
                  hasMore={hasMore}
                  onUploadComplete={handleUploadComplete}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={handleSelectConfirmation}
            >
              Select
            </button>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={onCancel}
            >
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImageGalleryModal;
