import React, { useState, createContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allLabels } from "./../graphql/queries/otherQueries";
import {
  DEFAULT_LABEL_ID,
  DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
} from "../configs/constants";
import { Divider } from "../mood-ui/DataTable";

const ALL_LABELS = gql(allLabels);

export const LabelContext = createContext();

const LabelProvider = ({ children }) => {
  const [labels, setLabels] = useState([]);
  const [activeLabel, setActiveLabel] = useState(DEFAULT_LABEL_ID);
  const [labelsReady, setLabelsState] = useState(false);
  const onChangeLabel = (id) => setActiveLabel(id);

  useQuery(ALL_LABELS, {
    fetchPolicy: "network-only",
    variables: DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
    onCompleted: (result) => {
      if (!result || !result.labels) return false;
      setLabels([...result.labels]);
      setActiveLabel(DEFAULT_LABEL_ID);
      setLabelsState(true);
    },
  });
  if (!labelsReady)
    return <Divider>Loading App. Initializing Data Context...</Divider>;
  return (
    <React.Fragment>
      <LabelContext.Provider value={{ labels, activeLabel, onChangeLabel }}>
        {children}
      </LabelContext.Provider>
    </React.Fragment>
  );
};

export default LabelProvider; 
