import React from "react";

export const PageContainer = ({ children }) => (
  <main className="min-h-full">
    <div className="max-w-8xl mx-auto min-h-full max-h-full bg-[#FCFCFE] ">
      {children}
    </div>
  </main>
);

export const BlockContainer = ({ children, raised = false, tweaks = "" }) => (
  <div
    className={`max-w-8xl mx-auto sm:pl-3 sm:pr-6 lg:pl-3 lg:pr-1 pt-[30px] ${
      raised ? "h-[calc(100vh-118px)] pb-6" : "h-[calc(100vh-64px)] pb-16"
    } ${tweaks}`}
    style={{ overflowY: "scroll" }}
  >
    {children}
  </div>
);

export const BlockSectionContainer = ({ children }) => (
  <div className="max-w-8xl mx-auto py-1 ">{children}</div>
);

export const FormContainer = ({ children }) => (
  <form>
    <div>{children}</div>
  </form>
);

export const FormData = ({ children }) => <div>{children}</div>;

export const Row = ({ children, card = false, tweaks = "" }) => (
  <div
    className={
      tweaks +
      " grid grid-cols-1 row-gap-5 col-gap-5 sm:grid-cols-12" +
      (card ? " border " : "")
    }
  >
    {children}
  </div>
);

export const Col = ({ children, width = 12, extraStyles = "" }) => (
  <div className={` sm:col-span-${width} ${extraStyles}`}>{children}</div>
);

export const ColExt = ({ children, width = 12, contentAlign = "left" }) => (
  <div className={`sm:col-span-${width}`}>{children}</div>
);

export const Centrify = ({ children }) => <center>{children}</center>;

export const FlexLineup = ({ children }) => (
  <div className="flex content-start flex-wrap mt-6">{children}</div>
);

export const InlineBlock = ({ children, className }) => (
  <div className={`relative inline-block text-left ${className}`}>
    {children}
  </div>
);

export const VerticalSpacing = ({ pixels = 22 }) => (
  <div style={{ height: `${pixels}px` }}></div>
);

export const HorizontalSpacing = () => (
  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
);
