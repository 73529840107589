import React from "react";
// import Moment from "react-moment";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allMerchants } from "./../graphql/queries/benefitQueries";
import { deleteMerchantsMutation } from "./../graphql/mutations/benefitMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useListSelection from "./../hooks/rowSelection";
import useModal from "./../hooks/modal";
import useNotification from "./../hooks/notifications";
import useItemCounter from "./../hooks/itemCounter";
import { MERCHANT_MODEL_NAME } from "./../configs/constants";
import { ConfirmActionModal } from "./../mood-ui/Modals";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../mood-ui/Notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import { TEditButton, AddButton } from "./../mood-ui/Controls";
import { Heading } from "./../mood-ui/Typography";
import Search from "./../mood-ui/Search";
import { PageControls, ListItemCheckbox } from "./../mood-ui/Controls";
import { ItemsNotFound, ColorLabel } from "./../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  // TableActions,
  TableSelectedActions,
  Pagination,
} from "./../mood-ui/DataTable";
import MerchantListActions from "../components/list-secondary-actions/MerchantListActions";

const ALL_MERCHANTS = gql(allMerchants);

const DELETE_MERCHANTS = gql(deleteMerchantsMutation);

const MerchantList = ({ entityDetails: { offers = null, cards = null, merchants = null } }) => {

  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Query.Merchants);

  const getOffers = (merchantId) => {
    return offers?.filter(offer => offer.merchant.id == merchantId) || []
  }
  
  const getCards = (merchantId) => {
    return cards?.filter(card => card.merchant.id == merchantId) || []
  }
  
  // const getRelatedMerchants = (merchantId) => {
  //   return merchants?.filter(merchant => merchant.related_merchants?.replace(/\s/g, '').split(',').includes(merchantId)) || []
  // }

  const getRelatedMerchants = (relatedMerchantIds = '') => {
    return merchants?.filter(merchant => relatedMerchantIds?.replace(/\s/g, '').split(',').includes(merchant.id)) || []
  }

  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
    isAllRowsSelected, 
    toggleAllRows, 
  } = useListSelection();

  const { page, limit, orderBy, orderHow, search } = useParams();

  const { search: queryString } = useLocation();

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { data, loading, error, refetch } = useQuery(ALL_MERCHANTS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "merchants" });

  const {
    nextPage,
    prevPage,
    editForm,
    createForm,
    applySearch,
  } = useBaseNavigation(MERCHANT_MODEL_NAME, { showingItemsCount: itemsTotal });

  const [deleteMerchants] = useMutation(DELETE_MERCHANTS, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Merchants Deleted",
        message: "Successfully deleted selected merchants",
        secondsToDisplay: 1,
      });
      clearSelection();
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to delete selected merchants",
        secondsToDisplay: 2,
      });
    }, 
    update: (cache, { data }) => {
      if (!data.deleteMerchants) return false;
      const deletedIds = data.deleteMerchants.map((cl) => cl.id);
      const existingMerchants = cache.readQuery({
        query: ALL_MERCHANTS,
        variables: {
          page,
          limit,
          orderBy,
          orderHow,
          search,
          queryString,
        },
      });
      const newMerchants = existingMerchants.merchants.filter(
        (cl) => deletedIds.indexOf(cl.id) === -1
      );
      cache.writeQuery({
        query: ALL_MERCHANTS,
        variables: {
          page,
          limit,
          orderBy,
          orderHow,
          search,
          queryString,
        },
        data: { merchants: newMerchants },
      });
      refetch();
    },
  });

  const confirmDeletion = () => {
    if (!hasAccess(Permissions.Mutation.DeleteMerchant)) {
      alert("You do not have a permission to delete offers");
      return false;
    }
    showModal({
      heading: "Confirm Deletion",
      message:
        "Are you sure you want to DELETE selected merchants? All offers that belong to those merchants will be assigned to 'Vaboo Generic Tenant' merchant. Do not proceed if you are not sure.",
      mood: "Danger",
      confirmButtonLabel: "Delete",
      onConfirm: () => {
        closeModal();
        handleDeleteMerchants();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteMerchants = () => {
    deleteMerchants({ variables: { ids: selectedRows } });
  };

  // console.log('ASDASDASD: ', entityDetails)

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        {/* <Row tweaks="border-b border-[#dfe2e8] pb-5 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Merchants"
              description="Control and monitor merchants."
            />
          </Col>
        </Row> */}
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Mutation.CreateMerchant) && (
              <AddButton label="Add merchant" onClick={createForm} loading={loading}  />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row> 
        <TableContainer tweaks="px-5 pb-5">
          <TableView tweaks={(!data || (data && data.merchants && data.merchants.length === 0)) ? 'border-[#ebedf2]' : ''}>
            <THeading tweaks={(!data || (data && data.merchants && data.merchants.length === 0)) ? 'opacity-30 border-b' : ''}>
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() => toggleAllRows(data?.merchants?.map(merchant => merchant.id))}
                    isChecked={isAllRowsSelected(data?.merchants?.map(merchant => merchant.id)) ? true : false}
                  />
                </THCol>
                <THCol tweaks="w-8 text-left" name="image" value="" />
                {/* <THCol tweaks="w-10 text-left" name="background" value="" /> */}
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="title" value="Title" />
                <THCol tweaks="text-left" name="slug" value="Slug" />
                <THCol tweaks="w-44 text-left pt-[2px]">
                  <div className="inline-flex w-full flex-wrap">
                    <div className="min-w-full text-center mb-[-4px] mt-1.5 h-[8spx]">Offers</div>
                    <span className="mb-[-10px] mt-[-3px] text-[9px] inline-flex flex-grow justify-center pl-1">Past</span>
                    <span className="mb-[-10px] mt-[-3px] text-[9px] inline-flex flex-grow justify-center opacity-0">Now</span>
                    <span className="mb-[-10px] mt-[-3px] text-[9px] inline-flex flex-grow justify-center pr-1">Next</span>
                  </div>
                </THCol>
                <THCol tweaks="w-44 text-center pt-[2px]">
                  <div className="inline-flex w-full flex-wrap">
                    <div className="min-w-full text-center mb-[-4px] mt-1.5 h-[8spx]">Cards</div>
                    <span className="mb-[-10px] mt-[-3px] text-[9px] inline-flex flex-grow justify-center pl-1">Past</span>
                    <span className="mb-[-10px] mt-[-3px] text-[9px] inline-flex flex-grow justify-center opacity-0">Now</span>
                    <span className="mb-[-10px] mt-[-3px] text-[9px] inline-flex flex-grow justify-center pr-1">Next</span>
                  </div>
                </THCol>
                <THCol tweaks="w-24 text-center" name="status" value="Status" />
                <THCol tweaks="w-fulls text-center" name="related" value="Related merchants" />
                <THCol tweaks="w-16 text-center" />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.merchants && data.merchants.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <ItemsNotFound text="No merchants found" tweaks="" />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.merchants && data.merchants.length > 0 && (
              <TContent>
                {data.merchants.map((merchant) => (
                  <TRow key={merchant.id} isSelected={rowSelected(merchant.id)}>
                    <TCol tweaks="w-4 text-center">
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(merchant.id)}
                        isChecked={rowSelected(merchant.id) ? true : false}
                      />
                    </TCol>
                    <TCol tweaks="min-w-[37px] max-w-[37px] text-center pr-0 pl-0 pb-0">
                      <img src={merchant.image} alt="" className={`-mt-2 -mr-[5px] h-[37px] w-[37px] ${rowSelected(merchant.id) ? 'opacity-70' : ''}` } />
                    </TCol>
                    {/* <TCol tweaks="min-w-[84px] max-w-[84.5px] text-center pr-0 pl-0 pb-0">
                      <img src={merchant.background} alt="" className={`-mt-2 -mr-[11px] h-[37px] w-[84.5px] ${rowSelected(merchant.id) ? 'opacity-75' : ''}` } />
                    </TCol> */}
                    <TCol value={merchant.id} />
                    <TCol tweaks="max-w-[200px]">
                      <abbr title={merchant.title}>
                        {merchant.title}
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[200px]" value={merchant.slug} />
                    <TCol>
                      <div className="inline-flex w-full">
                        <span 
                          className={`
                            inline-flex flex-grow justify-center 
                            ${ getOffers(merchant.id).filter(offer => offer.end_date < '2023-03-16').length < 1 && 'opacity-30' }
                          `}
                        >
                          { getOffers(merchant.id).filter(offer => offer.status === 'Active' && offer.end_date < '2023-03-16').length }
                          <span className="px-1 opacity-30">/</span>
                          { getOffers(merchant.id).filter(offer => offer.end_date < '2023-03-16').length }
                        </span>
                        <span 
                          className={`
                            inline-flex flex-grow justify-center 
                            ${ getOffers(merchant.id).filter(offer => offer.end_date > '2023-03-16' && offer.start_date < '2023-03-17').length < 1 && 'opacity-30' }
                          `}
                        >
                          { getOffers(merchant.id).filter(offer => offer.status === 'Active' && offer.end_date > '2023-03-16' && offer.start_date < '2023-03-17').length }
                          <span className="px-1 opacity-30">/</span>
                          { getOffers(merchant.id).filter(offer => offer.end_date > '2023-03-16' && offer.start_date < '2023-03-17').length }
                        </span>
                        <span 
                          className={`
                            inline-flex flex-grow justify-center 
                            ${ getOffers(merchant.id).filter(offer => offer.start_date > '2023-03-17').length < 1 && 'opacity-30' }
                          `}
                        >
                          { getOffers(merchant.id).filter(offer => offer.status === 'Active' && offer.start_date > '2023-03-17').length }
                          <span className="px-1 opacity-30">/</span>
                          { getOffers(merchant.id).filter(offer => offer.start_date > '2023-03-17').length }
                        </span>
                      </div>
                    </TCol>
                    <TCol>
                      <div className="inline-flex w-full">
                        <span 
                          className={`
                            inline-flex flex-grow justify-center 
                            ${ getCards(merchant.id).filter(offer => offer.end_date < '2023-03-16').length < 1 && 'opacity-30' }
                          `}
                        >
                          { getCards(merchant.id).filter(offer => offer.status === 'Active' && offer.end_date < '2023-03-16').length }
                          <span className="px-1 opacity-30">/</span>
                          { getCards(merchant.id).filter(offer => offer.end_date < '2023-03-16').length }
                        </span>
                        <span 
                          className={`
                            inline-flex flex-grow justify-center 
                            ${ getCards(merchant.id).filter(offer => offer.end_date > '2023-03-16' && offer.start_date < '2023-03-17').length < 1 && 'opacity-30' }
                          `}
                        >
                          { getCards(merchant.id).filter(offer => offer.status === 'Active' && offer.end_date > '2023-03-16' && offer.start_date < '2023-03-17').length }
                          <span className="px-1 opacity-30">/</span>
                          { getCards(merchant.id).filter(offer => offer.end_date > '2023-03-16' && offer.start_date < '2023-03-17').length }
                        </span>
                        <span 
                          className={`
                            inline-flex flex-grow justify-center 
                            ${ getCards(merchant.id).filter(offer => offer.start_date > '2023-03-17').length < 1 && 'opacity-30' }
                          `}
                        >
                          { getCards(merchant.id).filter(offer => offer.status === 'Active' && offer.start_date > '2023-03-17').length }
                          <span className="px-1 opacity-30">/</span>
                          { getCards(merchant.id).filter(offer => offer.start_date > '2023-03-17').length }
                        </span>
                      </div>
                    </TCol>
                    <TCol>
                      {merchant.status === "Active" ? (
                        <ColorLabel text={merchant.status} color="emerald" />
                      ) : (
                        <ColorLabel text={merchant.status} color="rose" />
                      )}
                    </TCol>
                    <TCol tweaks="max-w-[200px] w-fit h-[37px] text-center pr-0 pl-0 pb-0 bg-slate-100 border-white">
                      <div className="inline-flex gap-x-[2px] justify-around w-full">
                        {
                          getRelatedMerchants(merchant.related_merchants)?.map((relatedMerchant, index) => {
                            return (
                              <abbr key={index} title={relatedMerchant.title}>
                                <img src={relatedMerchant.image} alt="" className={`flex block -mt-2 h-[34px] w-[34px]` } />
                              </abbr>
                            )
                          })
                        }
                      </div>
                    </TCol>
                    <TCol tweaks="w-16 text-center">
                      {hasAccess(Permissions.Query.Merchant) && (
                        <TEditButton onClick={() => editForm(merchant.id)} />
                      )}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <MerchantListActions
                      onClearSelection={clearSelection}
                      onDelete={confirmDeletion}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={7} extraStyles="">
              </Col>
              <Col width={3}>
                <Pagination onPrev={page > 1 ? prevPage : false} onNext={data?.merchants?.length >= limit - 1 ? nextPage : false} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default MerchantList;
