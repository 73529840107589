import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { PresenceControls } from "./PresenceControls";

const decreaseDate = (date, days) => {
  var realDate = new Date(date == "" ? new Date() : date);
  realDate.setDate(realDate.getDate() - days);
  return realDate.toISOString().split("T")[0];
};
const increaseDate = (date, days) => {
  var realDate = new Date(date == "" ? new Date() : date);
  realDate.setDate(realDate.getDate() + days);
  return realDate.toISOString().split("T")[0];
};

const getFirstDate = (date1, date2) => {
  return (date1 && date2) 
    ? (new Date(date1) < new Date(date2) ? date1 : date2)
    : (date1 || date2 || null)
};

const getLastDate = (date1, date2) => {
  return (date1 && date2) 
    ? (new Date(date1) > new Date(date2) ? date1 : date2) 
    : (date1 || date2 || null) 
};

const getStartMax = (end, max) => {
  return decreaseDate(end !== "" ? end : max, 1);
};

const getEndMin = (start, min) => {
  return increaseDate(start !== "" ? start : min, 1);
};

const getDate = (arr, i) => {
  if (arr.length == 1 && arr[0].includes("%3C_%3C%3E_%3E")) {
    const dates = arr[0].split("%3C_%3C%3E_%3E");
    return !isNaN(Date.parse(dates[i] || null)) ? dates[i] : "";
  }
  if (arr.length == 1 && arr[0].includes("<_<>_>")) {
    const dates = arr[0].split("<_<>_>");
    return !isNaN(Date.parse(dates[i] || null)) ? dates[i] : "";
  }
  return "";
};

const getRelValue = (arr, i, target) => {
  let value, unit, dir = null
  if (arr.length == 1 && arr[0].includes("%3C_%3C%3E_%3E")) {
    const relDates = arr[0].split("%3C_%3C%3E_%3E");
    if (relDates[i].charAt(0) === '_') { 
      if (relDates[i] !== '_INF_') {
        dir   = relDates[i].split('_')[1] || ''
        value = relDates[i].split('_')[2] || ''
        unit  = relDates[i].split('_')[3] || ''
      }
    }
  }
  if (arr.length == 1 && arr[0].includes("<_<>_>")) {
    const relDates = arr[0].split("<_<>_>");
    if (relDates[i].charAt(0) === '_') { 
      if (relDates[i] !== '_INF_') {
        dir   = relDates[i].split('_')[1] || ''
        value = relDates[i].split('_')[2] || ''
        unit  = relDates[i].split('_')[3] || ''
      }
    }
  }
  if (target == 'value') return value || ''
  if (target == 'unit')  return unit || ''
  if (target == 'dir')   return dir || ''
  return "";
};

const getFormat = (vals) => {
  if (vals[0] !== '' && vals[1] == '') { return 'dates' }
  if (vals[0] == '' && vals[1] !== '') { return 'rels' }
  return ''
}

const DateFilter = ({
  title = '', 
  filterValues,
  selectedValues,
  onUpdateDatesFilter: updateDatesFilter,
  onRemoveDatesFilter: removeDatesFilter,
}) => {

  const min    = filterValues.find((v) => v.uid === "min")?.name || null;
  const max    = filterValues.find((v) => v.uid === "max")?.name || null;
  const hasMin = filterValues.find((v) => v.uid === "has_min")?.name || null;
  const hasMax = filterValues.find((v) => v.uid === "has_max")?.name || null;

  const [startDate,     setStartDate]     = useState(getDate(selectedValues, 0));
  const [endDate,       setEndDate]       = useState(getDate(selectedValues, 1));
  const [startMax,      setStartMax]      = useState(getStartMax(endDate, hasMax || max));
  const [endMin,        setEndMin]        = useState(getEndMin(startDate, hasMin || min));
  const [isDraft,       setDraft]         = useState(false);

  const [startRelValue, setStartRelValue] = useState(getRelValue(selectedValues, 0, 'value'));
  const [endRelValue,   setEndRelValue]   = useState(getRelValue(selectedValues, 1, 'value'));
  const [startRelUnit,  setStartRelUnit]  = useState(getRelValue(selectedValues, 0, 'unit'))
  const [endRelUnit,    setEndRelUnit]    = useState(getRelValue(selectedValues, 1, 'unit'))
  const [startRelDir,   setStartRelDir]   = useState(getRelValue(selectedValues, 0, 'dir'))
  const [endRelDir,     setEndRelDir]     = useState(getRelValue(selectedValues, 1, 'dir'))

  const [startFormat,   setStartFormat]   = useState(getFormat([getDate(selectedValues, 0), getRelValue(selectedValues, 0, 'value')]))
  const [endFormat,     setEndFormat]     = useState(getFormat([getDate(selectedValues, 1), getRelValue(selectedValues, 1, 'value')]))

  useEffect(() => {
    setStartDate(getDate(selectedValues, 0));
    setEndDate(getDate(selectedValues, 1));
    setStartRelValue(getRelValue(selectedValues, 0, 'value'));
    setEndRelValue(getRelValue(selectedValues, 1, 'value'));
    setStartRelUnit(getRelValue(selectedValues, 0, 'unit'))
    setEndRelUnit(getRelValue(selectedValues, 1, 'unit'))
    setStartRelDir(getRelValue(selectedValues, 0, 'dir'))
    setEndRelDir(getRelValue(selectedValues, 1, 'dir'))
    setStartFormat(getFormat([getDate(selectedValues, 0), getRelValue(selectedValues, 0, 'value')]))
    setEndFormat(getFormat([getDate(selectedValues, 1), getRelValue(selectedValues, 1, 'value')]))
  }, [selectedValues[0]]);

  const handleUpdateDatesfilter = (e) => {
    let start = '_INF_'
    let end = '_INF_'
    if (startFormat == 'dates' && startDate !== '')    { start = startDate }
    if (startFormat == 'rels' && startRelValue !== '') { start = `_${ startRelDir || 'RELP' }_${ startRelValue || '7' }_${ startRelUnit || 'D' }_` }
    if (endFormat == 'dates' && endDate !== '')        { end = endDate }
    if (endFormat == 'rels' && endRelValue !== '')     { end = `_${ endRelDir || 'RELF' }_${ endRelValue || '7' }_${ endRelUnit || 'D' }_` }
    if (start == '_INF_' && end == '_INF_') { removeDatesFilter(e); }
    else                                    { updateDatesFilter(`${ start }<_<>_>${ end }`); }
    setDraft(false);
  };

  const handlePresenceFilter = (presence) => {
    updateDatesFilter(presence)
    setDraft(false);
  }

  const handleRemoveDatesfilter = (e) => {
    setStartDate("");
    setEndDate("");
    setStartMax(getStartMax("", hasMax || max));
    setEndMin(getEndMin("", hasMin || min));
    setStartRelValue('')
    setEndRelValue('')
    setStartFormat('')
    setEndFormat('')
    removeDatesFilter(e);
    setDraft(false);
  };

  const handleFieldOnChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    setStartMax(getStartMax(dates[1], hasMax || max));
    setEndMin(getEndMin(dates[0], hasMin || min));
    setDraft(true);
  };

  return (
    <div className="w-full flex max-w-[450px]" tabIndex={1}>
      <div className="w-max flex-wrap justify-between">

        <div className="inline-flex flex justify-between pb-2 text-[11px] min-w-[100%]">
          <PresenceControls 
            selectedValues={selectedValues}
            setPresenceFilter={handlePresenceFilter}
            unsetPresenceFilter={handleRemoveDatesfilter}
          />
        </div>
        <div 
          className={`
            inline-flex flex-col w-[calc(50%)] pr-1 "
            ${ 
              selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>' 
              ? 'opacity-30 pointer-events-none' : ''
            }
          `}>
          <input
            name="start_range"
            key="start_range"
            type="date"
            className={`
              block w-full h-[24px]
              px-1 py-[0px] rounded-none form-input 
              text-xs text-center border-[#dfe2e8] 
              hover:opacity-100 focus-within:opacity-100 focus:border-purple-300 focus:outline-none focus:shadow-none cursor-pointer 
              transition-opacity ease-in-out duration-150 
              ${startDate ? "text-gray-600" : "text-gray-400"} ${ startFormat !== 'dates' && ' opacity-20'} 
            `}
            value={startDate}
            onChange={(e) => { handleFieldOnChange([e.target.value, endDate]); setStartFormat('dates');  }}
            min={hasMin !== "" ? hasMin : min}
            max={startMax}
          />
          <div className={`text-[11px] flex pt-[7px]`}>
            <button
              onClick={(e) => {
                handleFieldOnChange([ getFirstDate(decreaseDate(endDate, 1), hasMin), endDate ]);
                setStartFormat('dates'); 
                e.target.focus();
              }}
              aria-disabled={(hasMin !== "" ? hasMin : min) === startDate}
              className={`
                basis-1/6 h-[24px] min-w-[20px]
                mr-px mt-px rounded-l-[3px]
                bg-[#A377B8] text-white
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ startFormat !== 'dates' ? ' opacity-50 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-arrow-collapse-left pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([decreaseDate(startDate, 7), endDate]); setStartFormat('dates'); 
              }}
              aria-disabled={
                startDate === "" ||
                getLastDate(
                  startDate,
                  increaseDate(hasMin !== "" ? hasMin : min, 7)
                ) !== startDate
              }
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                mr-px mt-px 
                bg-[#A377B8] text-white 
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ startFormat !== 'dates' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-chevron-double-left pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([decreaseDate(startDate, 1), endDate]); setStartFormat('dates'); 
              }}
              aria-disabled={
                startDate === "" || (hasMin !== "" ? hasMin : min) === startDate
              }
              className={`
                basis-1/6 h-[24px] min-w-[20px]
                mr-px mt-px
                bg-[#A377B8] text-white 
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ startFormat !== 'dates' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-chevron-left pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                handleFieldOnChange([increaseDate(startDate, 1), endDate]); setStartFormat('dates'); 
              }}
              aria-disabled={startDate === "" || startMax <= startDate}
              className={`
                basis-1/6 h-[24px] min-w-[20px]
                bg-[#A377B8] text-white
                mr-px mt-px
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ startFormat !== 'dates' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-chevron-right pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                handleFieldOnChange([increaseDate(startDate, 7), endDate]); setStartFormat('dates'); 
              }}
              aria-disabled={
                startDate === "" ||
                getFirstDate(startDate, decreaseDate(startMax, 7)) !== startDate
              }
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                bg-[#A377B8] text-white 
                mr-px mt-px
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none 
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ startFormat !== 'dates' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-chevron-double-right pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([startMax, endDate]); setStartFormat('dates'); 
              }}
              aria-disabled={startMax <= startDate}
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                sml-px mt-px rounded-r-[3px] 
                bg-[#A377B8] text-white 
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ startFormat !== 'dates' ? ' opacity-50 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-arrow-collapse-right pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange(['', endDate]); setStartRelValue(''); setStartRelUnit(''); setStartRelDir(''); setStartFormat('')
              }}
              aria-disabled={startDate == "" && startRelValue == ''}
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                ml-[3px] mr-px mt-px rounded-l-[3px]
                bg-rose-600 text-white
                opacity-70
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ startDate == '' || startRelValue == '' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-close pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([hasMin, hasMax]); setStartFormat('dates'); setEndFormat('dates');  setStartRelValue(''); setEndRelValue(''); 
              }}
              aria-disabled={
                !hasMin || !hasMax || (hasMin == startDate && hasMax == endDate)
                || selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>'
              }
              className={`
                z-50 basis-1/6 h-[24px] w-[20px] 
                bg-indigo-600 text-white 
                mr-[-18px] mt-px 
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ startDate == '' || startRelValue == '' ? ' opacity-80 aria-disabled:opacity-30' : ' opacity-80'} 
              `}
            >
              <span className="mdi mdi-arrow-left-right poinster-events-none"></span>
            </button>
          </div>
          <div 
            className={`
              min-w-[150px] flex gap-px mt-2 
              focus-within:opacity-100 hover:opacity-100 
              transition-opacity ease-in-out duration-150
              ${ startFormat !== 'rels' && ' opacity-30'}
            `}
          >
            <select
              className={`
                text-xs px-1 py-[0px] rounded-none form-input w-[50%] h-[24px] border-[#dfe2e8] cursor-pointer  
                focus:border-purple-300 focus:outline-none focus:shadow-none
                ${startRelDir ? "text-gray-600" : "text-gray-400"}
              `}
              value={startRelDir}
              onChange={(e) => { setStartRelDir(e.target.value); setDraft(true); setStartFormat('rels'); e.target.focus(); }}
            >
              <option value="" disabled>Rolling start</option>
              <option value="RELP">In the last</option>
              <option value="RELF">After the next</option>
            </select>
            <input
              type="number"
              className={`
                text-xs px-1 py-[0px] rounded-none form-input w-[22%] h-[24px] border-[#dfe2e8] 
                cursor-pointer focus:border-purple-300 focus:outline-none focus:shadow-none
                ${startRelValue ? "text-gray-600" : "text-gray-400"}
              `}
              value={startRelValue}
              onChange={(e) => { setStartRelValue(e.target.value); setDraft(true); setStartFormat('rels'); e.target.focus(); }}
              min={1}
              max={1000}
            />
            <select
              className={`
                text-xs px-1 py-[0px] rounded-none form-input w-[28%] h-[24px] border-[#dfe2e8]  cursor-pointer
                focus:border-purple-300 focus:outline-none focus:shadow-none
                ${startRelUnit ? "text-gray-600" : "text-gray-400"}
              `}
              value={startRelUnit}
              onChange={(e) => { setStartRelUnit(e.target.value); setDraft(true); setStartFormat('rels'); e.target.focus(); }}
            >
              <option value="" disabled>time</option>
              <option value="H">Hours</option>
              <option value="D">Days</option>
              <option value="W">Weeks</option>
              <option value="M">Months</option>
            </select>
          </div>
          <div className="opacity-70 text-[11px] pt-2 h-max items-end">
            {hasMin && (
              <span>
                First: <Moment format="DD&middot;MM&middot;YYYY" date={hasMin} />
              </span>
            )}
          </div>
        </div>

        <div 
          className={`
            inline-flex flex-col w-[calc(50%)] pl-1 
            ${ 
              selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>' 
              ? 'opacity-30 pointer-events-none' : ''
            }
          `}
        >
          <input
            name="end_range"
            key="end_range"
            type="date"
            className={`
              block w-full h-[24px]
              px-1 py-[0px] rounded-none form-input 
              text-xs text-center border-[#dfe2e8] 
              focus:border-purple-300 focus:outline-none focus:shadow-none cursor-pointer 
              hover:opacity-100 focus-within:opacity-100 
              transition-opacity ease-in-out duration-150 
              ${endDate ? "text-gray-600" : "text-gray-400"} ${ endFormat !== 'dates' && ' opacity-20'}
            `}
            value={endDate}
            onChange={(e) => { handleFieldOnChange([startDate, e.target.value]); setEndFormat('dates') }}
            min={endMin}
            max={max}
          />
          <div className={`text-[11px] flex pt-[7px]`}>
            <button
              onClick={(e) => {
                handleFieldOnChange([startDate, '']); setEndRelValue(''); setEndRelUnit(''); setEndRelDir(''); setEndFormat('')
              }}
              aria-disabled={endDate == "" && endRelValue == ''}
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                ml-[11px] mr-[3px] mt-px rounded-r-[3px]
                bg-rose-600 text-white
                opacity-70
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ endDate == '' || endRelValue == '' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-close pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([startDate, endMin]); setEndFormat('dates')
              }}
              aria-disabled={endDate !== "" && endMin >= endDate}
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                mr-px mt-px rounded-l-[3px]
                bg-[#A377B8] text-white
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ endFormat !== 'dates' ? ' opacity-50 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-arrow-collapse-left pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([startDate, decreaseDate(endDate, 7)]); setEndFormat('dates')
              }}
              aria-disabled={
                endDate == "" ||
                getLastDate(endDate, increaseDate(endMin, 7)) !== endDate
              }
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                mr-px mt-px
                bg-[#A377B8] text-white
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ endFormat !== 'dates' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-chevron-double-left pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([startDate, decreaseDate(endDate, 1)]); setEndFormat('dates')
              }}
              aria-disabled={endDate == "" || endMin >= endDate}
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                bg-[#A377B8] text-white
                mr-px mt-px
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ endFormat !== 'dates' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-chevron-left pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([startDate, increaseDate(endDate, 1)]); setEndFormat('dates')
              }}
              aria-disabled={
                endDate === "" || (hasMax !== "" ? hasMax : max) === endDate
              }
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                bg-[#A377B8] text-white
                mr-px mt-px
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ endFormat !== 'dates' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-chevron-right pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([startDate, increaseDate(endDate, 7)]); setEndFormat('dates')
              }}
              aria-disabled={
                endDate === "" ||
                getFirstDate(endDate, decreaseDate(hasMax, 7)) !== endDate
              }
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                bg-[#A377B8] text-white
                mr-pxs mt-px
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ endFormat !== 'dates' ? ' opacity-30 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-chevron-double-right pointer-events-none"></span>
            </button>
            <button
              onClick={(e) => {
                handleFieldOnChange([startDate, hasMax]); setEndFormat('dates')
              }}
              aria-disabled={endDate >= hasMax}
              className={`
                basis-1/6 h-[24px] min-w-[20px] 
                ml-px mt-px rounded-r-[3px]
                bg-[#A377B8] text-white
                hover:opacity-90 focus:opacity-90 active:opacity-100 focus:outline-none
                aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none 
                ${ endFormat !== 'dates' ? ' opacity-50 aria-disabled:opacity-30' : ' opacity-70'} 
              `}
            >
              <span className="mdi mdi-arrow-collapse-right pointer-events-none"></span>
            </button>
          </div>

          <div 
            className={`
              min-w-[150px] flex gap-px mt-2 
              focus-within:opacity-100 hover:opacity-100 
              transition-opacity ease-in-out duration-150
              ${ endFormat !== 'rels' && ' opacity-30'}
            `}
          >
            <select
              className={`
                text-xs px-1 py-[0px] rounded-none form-input w-[50%] h-[24px] border-[#dfe2e8] 
                cursor-pointer focus:border-purple-300 focus:outline-none focus:shadow-none 
                ${endRelDir ? "text-gray-600" : "text-gray-400"}
              `}
              value={endRelDir}
              onChange={(e) => { setEndRelDir(e.target.value); setDraft(true); setEndFormat('rels'); e.target.focus(); }}
            >
              <option value="" disabled>Rolling end</option>
              <option value="RELF">In the next</option>
              <option value="RELP">Before the last</option>
            </select>
            <input
              type="number"
              className={`
                text-xs px-1 py-[0px] rounded-none form-input w-[22%] h-[24px] border-[#dfe2e8] 
                cursor-pointer focus:border-purple-300 focus:outline-none focus:shadow-none 
                ${endRelValue ? "text-gray-600" : "text-gray-400"}
              `}
              value={endRelValue}
              onChange={(e) => { setEndRelValue(e.target.value); setDraft(true); setEndFormat('rels'); e.target.focus(); }}
              min={1}
              max={1000}
            />
            <select
              className={`
                text-xs px-1 py-[0px] rounded-none form-input w-[28%] h-[24px] border-[#dfe2e8] 
                cursor-pointer focus:border-purple-300 focus:outline-none focus:shadow-none 
                ${endRelUnit ? "text-gray-600" : "text-gray-400"}
              `}
              value={endRelUnit}
              onChange={(e) => { setEndRelUnit(e.target.value); setDraft(true); setEndFormat('rels'); e.target.focus(); }}
            >
              <option value="" disabled>time</option>
              <option value="H">Hours</option>
              <option value="D">Days</option>
              <option value="W">Weeks</option>
              <option value="M">Months</option>
            </select>
          </div>

          <div className="flex-wrap w-[calc(100%+8px)] pt-2 h-max items-end">
            <div className="inline-flex ml-[calc(-33%-8px)] w-[66%] opacity-70 text-[13px] text-center justify-center">
              {title && (
                <span>{ title }</span>
              )}
            </div>
            <div className="inline-flex w-[66%] opacity-70 text-[11px] text-right justify-end">
              {hasMax && (
                <span>
                  Last: <Moment format="DD&middot;MM&middot;YYYY" date={hasMax} />
                </span>
              )}
            </div>
          </div>

        </div>
      </div>

      <div className="flex flex-wrap w-fit">
        <div className="flex-col ml-[8px] w-[26px]">
          <button
            type="button"
            onClick={(e) => { handleRemoveDatesfilter(e) }}
            disabled={(!isDraft && !selectedValues.length)}
            className="
              disabled:opacity-40 disabled:pointer-events-none 
              inline-flex justify-center items-center
              w-[26px] h-[26px]
              -mt-px rounded-[3px]
              opacity-80 bg-rose-600 border 
              text-white text-[11px] font-medium 
              hover:bg-rose-500 focus:outline-none focus:border-rose-700 focus:shadow-outline-rose active:bg-rose-700 
              transition duration-150 ease-in-out
            "
          >
            <span className="mdi mdi-close smt-px mx-px"></span>
          </button>
          <button
            type="button"
            onClick={handleUpdateDatesfilter}
            disabled={!isDraft}
            className="
              disabled:opacity-40 disabled:pointer-events-none 
              inline-flex justify-center items-center
              w-[26px] h-[26px] 
              mt-[6.5px] rounded-[3px]
              opacity-80 bg-emerald-600 border 
              text-white text-[11px] font-medium 
              hover:bg-emerald-500 focus:outline-none focus:border-emerald-700 focus:shadow-outline-emerald active:bg-emerald-700 
              transition duration-150 ease-in-out
            "
          >
            <span className="mdi mdi-check smt-px mx-px"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
