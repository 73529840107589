import React from "react";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import VabooUserInvoiceView from "./VabooUserInvoiceView";

const TenantInvoiceView = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.UserInvoice);
  return <VabooUserInvoiceView chargeNowPermission={hasAccess(Permissions.Mutation.ChargeNowUser)} />;
};

export default TenantInvoiceView;
