import React from "react";
import {
  ActionList,
  ActionGroup,
  Action,
} from "./../../mood-ui/DataTable";

const PipelineListActions = ({ onClearSelection, onExclude, onInclude }) => (
  <ActionList onClearSelection={onClearSelection}>
    <ActionGroup>
      <Action label="Exclude" onClick={onExclude} />
      <Action label="Include" onClick={onInclude} />
    </ActionGroup>
  </ActionList>
);

export default PipelineListActions;
