import React from "react";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import VabooUserInvoiceEdit from "./VabooUserInvoiceEdit";

const TenantInvoiceEdit = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.UserInvoice);
  return (
    <VabooUserInvoiceEdit
      updateItemPermission={hasAccess(Permissions.Mutation.UpdateUserInvoice)}
      chargeNowPermission={hasAccess(Permissions.Mutation.ChargeNowUser)}
    />
  );
};

export default TenantInvoiceEdit;
