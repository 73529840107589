import React, { useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allTags } from "./../graphql/queries/tagQueries";
import {
  createTagMutation,
  deleteTagMutation,
} from "./../graphql/mutations/tagMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useFormErrors from "./../hooks/formErrors";
import PageSpinner from "./../moose-ui/PageSpinner";
import { TextField } from "./../moose-ui/Fields";
import { AddButton } from "./../moose-ui/Controls";
import Tag from "./../vaboo-ui/Tag";
import Search from "./../moose-ui/Search";
import {
  Row,
  Col,
  PageContainer,
  BlockContainer,
  FormContainer,
  FormData,
  FlexLineup,
} from "./../moose-ui/Layout";
import { Heading } from "./../moose-ui/Typography";
import TagTypeContextSwitch from "../moose-ui/TagTypeSwitcher";
import { TagTypeContext } from "../contexts/TagTypeContext";

const ALL_TAGS = gql(allTags);
const CREATE_TAG = gql(createTagMutation);
const DELETE_TAG = gql(deleteTagMutation);

const TagManager = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.Tags);
  const tagTypeContext = useContext(TagTypeContext);
  const [newTagTitle, setNewTagTitle] = useState("");
  const [newTagSlug, setNewTagSlug] = useState("");
  const { setFieldError, clearErrors, fieldError, resolveMutationErrors } =
    useFormErrors();
  const { search } = useParams();
  const history = useHistory();

  const { data, loading } = useQuery(ALL_TAGS, {
    variables: {
      search,
      queryString: `?typeId=${tagTypeContext.activeType}`,
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    createTag,
    { data: createResult, loading: isCreating, error: creationError },
  ] = useMutation(CREATE_TAG, {
    variables: {
      slug: newTagSlug,
      title: newTagTitle,
      type_id: tagTypeContext.activeType,
    },
    onCompleted: () => {
      setNewTagTitle("");
      setNewTagSlug("");
      clearErrors();
    },
    update: (cache, result) => {
      if (!result.data || !result.data.createTag) return false;

      const existingTags = cache.readQuery({
        query: ALL_TAGS,
        variables: {
          search,
          queryString: `?typeId=${tagTypeContext.activeType}`,
        },
      });

      const { createTag: newTag } = result.data;

      cache.writeQuery({
        query: ALL_TAGS,
        variables: {
          search,
          queryString: `?typeId=${tagTypeContext.activeType}`,
        },
        data: { tags: [newTag, ...existingTags.tags] },
      });
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const [
    deleteTag,
    { data: deleteResult, loading: isDeleting, error: deleteError },
  ] = useMutation(DELETE_TAG, {
    update: (cache, result) => {
      if (!result.data || !result.data.deleteTag) return false;

      const { id: deletedTagId } = result.data.deleteTag;

      const existingTags = cache.readQuery({
        query: ALL_TAGS,
        variables: {
          search,
          queryString: `?typeId=${tagTypeContext.activeType}`,
        },
      });

      const newTagTitles = existingTags.tags.filter(
        (tag) => tag.id != deletedTagId
      );

      cache.writeQuery({
        query: ALL_TAGS,
        variables: {
          search,
          queryString: `?typeId=${tagTypeContext.activeType}`,
        },
        data: { tags: newTagTitles },
      });
    },
    onError: (e) => {
      //alert error
    },
  });

  const handleDeleteTag = (id) => {
    deleteTag({ variables: { id } });
  };

  const applySearch = (query) => {
    query = query.length > 0 ? query : "all";
    let url = `/tags/${query}`;
    history.push(url);
  };

  if (!data || !data.tags) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        {loading && <Heading text="Tags. Loading..." />}
        {(isDeleting || isCreating) && <Heading text="Tags. Saving..." />}
        {!isDeleting && !isCreating && !loading && <Heading text="Tags" />}
        <FormContainer>
          <FormData>
            <TagTypeContextSwitch />
            {hasAccess(Permissions.Mutation.CreateTag) && (
              <React.Fragment>
                <Row>
                  <Col width={4}>
                    <TextField
                      name="slug"
                      label="Slug"
                      value={newTagSlug}
                      error={fieldError("slug")}
                      onChange={(e) => {
                        setNewTagSlug(e.target.value);
                      }}
                      onEnter={createTag}
                    />
                  </Col>
                  <Col width={4}>
                    <TextField
                      name="title"
                      label="Readable Title"
                      value={newTagTitle}
                      error={fieldError("title")}
                      onChange={(e) => {
                        setNewTagTitle(e.target.value);
                      }}
                      onEnter={createTag}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col width={2}>
                    <AddButton label="Create Tag" onClick={createTag} />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            <Row>
              <Col width={12}></Col>
            </Row>
            <Row>
              <Col width={12}>
                <Heading
                  text="Discover existing tags"
                  description='List of all existing tags. Click "x" to delete, use search to find or click on tag for more details'
                />
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                <Search
                  onApplySearch={applySearch}
                  initalQuery={search != "all" ? search : ""}
                />
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                <FlexLineup>
                  {data &&
                    data.tags &&
                    data.tags.map((tag) => (
                      <Tag
                        key={tag.id}
                        tag={tag}
                        onDelete={(id) =>
                          hasAccess(Permissions.Mutation.DeleteTag)
                            ? handleDeleteTag(id)
                            : () => {}
                        }
                      />
                    ))}
                </FlexLineup>
              </Col>
            </Row>
          </FormData>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default TagManager;
