import React, { useState } from "react";
import FilterValue from "./FilterValue";

const StandardFilter = ({ options, selectedValues, onUpdateFilter }) => {
  const { type, title, queryKey, values } = options;
  const [visible, setVisible] = useState(false);

  const hideFilter = () => {
    setVisible(false);
  };
  const showFilter = () => {
    setVisible(true);
  };

  const listCssClass = visible
    ? "origin-top-right absolute left-0 mt-1 w-56 rounded-md shadow-lg"
    : "origin-top-right absolute left-0 mt-1 w-56 rounded-md shadow-lg hidden";

  const toggleFilterValue = (id) => {
    onUpdateFilter(queryKey, id);
  };

  return (
    <div
      className="relative inline-block text-left pb-1 pr-1 pl-1"
      onMouseEnter={() => {
        showFilter();
      }}
      onMouseLeave={() => {
        hideFilter();
      }}
    >
      <div>
        <span className="rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-1 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          >
            {title}
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>
      <div className={listCssClass}>
        <div
          className="rounded-md bg-white shadow-xs"
          style={{ maxHeight: "350px", overflowY: "scroll" }}
        >
          <div className="py-1">
            {values &&
              values.length > 0 &&
              values.map((value) => (
                <FilterValue
                  key={value.uid}
                  filterId={value.uid}
                  filterValue={value.name}
                  isSelected={
                    selectedValues.indexOf(value.uid) > -1 ? true : false
                  }
                  onClick={toggleFilterValue}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardFilter;
