import React from "react";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { VABOO_REST_API_URL } from "../../configs/constants";
import { generateSystemAccessCodeMutation } from "../../graphql/mutations/systemMutations";
import Permissions, { landlordNoAccessFallbackList } from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "../../mood-ui/Layout";
import { StdButton } from "../../mood-ui/Controls";
import PageSpinner from "../../mood-ui/PageSpinner";
import PrimaryKPIReport from "../../components/reports/PrimaryKPIReport";
import ClientOverview from "../../components/reports/ClientOverview";

const GENERATE_SYSTEM_ACCESS_CODE = gql(generateSystemAccessCodeMutation);

const ClientStats = ({ entityDetails = null }) => {

  const { id: accountId } = useParams();
  
  const { protectPage, hasAccess } = usePermissions();
  
  protectPage(Permissions.Query.Clients, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: accountId },
  });
  
  const [ exportUsers ] = useMutation(GENERATE_SYSTEM_ACCESS_CODE, {
    onCompleted: (result) => {
      const restApiExportUrl = `${VABOO_REST_API_URL}export-users/${accountId}?system_access_code=${result.generateSystemAccessCode.code}`;
      window.open(restApiExportUrl);
    },
    onError: (e) => {
      alert("Failed to generate Vaboo REST System Access Code");
      console.log(e);
    },
  });

  const [ exportDeletedUsers ] = useMutation(GENERATE_SYSTEM_ACCESS_CODE, {
    onCompleted: (result) => {
      const restApiExportUrl = `${VABOO_REST_API_URL}export-deleted-users/${accountId}?system_access_code=${result.generateSystemAccessCode.code}`;
      window.open(restApiExportUrl);
    },
    onError: (e) => {
      alert("Failed to generate Vaboo REST System Access Code");
      console.log(e);
    },
  });

  if (!accountId || !entityDetails) return <PageSpinner clear />;

  return (
    <PageContainer>
      <BlockContainer>
          <div className="w-full max-w-[1340px] mx-auto">
            <Row tweaks="px-4">
              <Col width={12} extraStyles="flex-wrap flex justify-between">
                <ClientOverview entityDetails={ entityDetails } />
              </Col>
              <Col width={12} extraStyles="flex-wrap flex justify-between pt-6">
                <div className="select-none">
                  { hasAccess(Permissions.Query.ClientExportUsers)        && <StdButton tweaks="bg-slate-500 text-[#ffffff]" label="Export Users" onClick={exportUsers} /> }
                  &nbsp;&nbsp;
                  { hasAccess(Permissions.Query.ClientExportDeletedUsers) && <StdButton tweaks="bg-slate-400 text-[#ffffff]" label="Export Deleted Users" onClick={exportDeletedUsers} /> }
                </div>
                { hasAccess(Permissions.Query.PrimaryKPIReport) && (
                  <PrimaryKPIReport landlordId={accountId} />
                )}
              </Col>
            </Row>
          </div>
      </BlockContainer>
    </PageContainer>
  );
};

export default ClientStats;
