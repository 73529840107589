import React, { useState, useEffect } from "react";
import { PresenceControls } from "./PresenceControls";

const animationTime = 750;

function easeInOutQuart (t, b, c, d) { // t = current time // b = start value // c = change needed // d = ease duration
  if (t > d) return b + c
  let x = t / d
  return b + (c * (x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2))
}
const animate = (first, last, cb, exit, val = first, start = performance.now()) => {
  if (val === last) return 
  let now = performance.now() 
  val = exit(val, last) ? last : easeInOutQuart(now - start, first, last - first, animationTime)
  cb(val)
  requestAnimationFrame(() => animate(first, last, cb, exit, val, start))
}

const RangeFilter = ({
  title, 
  filterValues,
  selectedValues, 
  onUpdateRangeFilter: updateRangeFilter,
  onRemoveRangeFilter: removeRangeFilter,
}) => {

  const [ isDraft, setDraft ] = useState(false);
  const [ isZoom,  setZoom  ] = useState(true);
  
  const max                       = parseInt(filterValues.find((v) => v.uid === "max")?.name)     || null;
  const hasMax                    = parseInt(filterValues.find((v) => v.uid === "has_max")?.name) || null;
  const baselineMax               = Math.ceil(max > hasMax * 1.1 ? hasMax * 1.1 : max);
  const [ maxRange, setMaxRange ] = useState(baselineMax);
  
  const min         = parseInt(filterValues.find((v) => v.uid === "min")?.name) || null;
  const hasMin      = parseInt(filterValues.find((v) => v.uid === "has_min")?.name) || null;
  const baselineMin = () => {
    if (hasMin < 0) {
      if (hasMin >= -100)      return Math.floor(-100)  
      if (min > hasMin * 1.15) return Math.floor(hasMin * 1.15) 
      return Math.floor(min)
    }
    else if (min < hasMin * 0.9) return Math.floor(hasMin * 0.9)
    return 0
  };
  const [ minRange, setMinRange ] = useState(baselineMin());

  const getRange = (arr, i) => {
    if (arr.length == 1 && arr[0].includes("%3C_%3C%3E_%3E")) {
      const values = arr[0].split("%3C_%3C%3E_%3E");
      return !isNaN(parseInt(values[i])) ? parseInt(values[i]) : i === 0 ? Math.floor(hasMin) : Math.floor(hasMax);
    }
    if (arr.length == 1 && arr[0].includes("<_<>_>")) {
      const values = arr[0].split("<_<>_>");
      return !isNaN(parseInt(values[i])) ? parseInt(values[i]) : i === 0 ? Math.floor(hasMin) : Math.floor(hasMax);
    }
    return i === 0 ? Math.floor(hasMin) : Math.floor(hasMax);
  };

  const [startRange, setStartRange] = useState(getRange(selectedValues, 0));
  const [endRange, setEndRange]     = useState(getRange(selectedValues, 1));

  const handlePresenceFilter = (presence) => {
    updateRangeFilter(presence)
    setDraft(false);
  }

  useEffect(() => {
    setStartRange(getRange(selectedValues, 0));
    setEndRange(  getRange(selectedValues, 1));
  }, [selectedValues[0]]);

  const easeVal = (last, i = 0, inc = true) => {
    setDraft(true)
    const exit = inc ? (v, l) => { return v >= l } 
                     : (v, l) => { return v <= l } 
    const first = i == 0 ? startRange 
                : i == 1 ? endRange 
                : i == 2 ? minRange 
                        : maxRange
    const cb = i === 0 ? (v) => { setStartRange(Math.floor(v)) }
             : i === 1 ? (v) => {   setEndRange(Math.ceil(v)) }
             : i === 2 ? (v) => {   setMinRange(Math.floor(v)) }
                       : (v) => {   setMaxRange(Math.ceil(v)) }
    animate(first, last, cb, exit)
  };

  const zoom = (range, toggle = undefined) => {
    const startVal = parseInt(range[0])
    const endVal   = parseInt(range[1])
    let min        = Math.floor(startVal - (endVal - startVal) * 0.25)
    let max        = Math.round(endVal   + (endVal - startVal) * 0.25)
    if ((isZoom || toggle === true) && toggle !== false) {
      setZoom(true)
      if (min >= startVal - 2)  { min = startVal - 2  }
      if (max <= endVal + 2)    { max = endVal + 2    }
      if (min <= baselineMin()) { min = baselineMin() }
      if (max >= baselineMax)   { max = baselineMax   }
    } 
    else {
      setZoom(false)
      min = baselineMin()
      max = baselineMax
    }
    if      (min > minRange) { easeVal(min, 2)        } 
    else if (min < minRange) { easeVal(min, 2, false) }
    if      (max > maxRange) { easeVal(max, 3)        } 
    else if (max < maxRange) { easeVal(max, 3, false) }
    setDraft(true)
  }

  const clickBar = (e) => {
    const rect = e.target.getBoundingClientRect()
    const x    = e.clientX - rect.left
    let pos    = Math.round((x / rect.width) * 100) / 100
    if (pos > 1) { pos = 1 }
    if (pos < 0) { pos = 0 }
    const mid   = (startRange + endRange) / 2
    const value = minRange + (maxRange - minRange) * pos
    if (value >= mid) {
      if (isZoom) { setEndRange(Math.round(value)); zoom([startRange, value]) } 
      else        { endRange < value ? easeVal(Math.round(value), 1) : easeVal(Math.round(value), 1, false) }
    } 
    else if (value < mid) {
      if (isZoom) { setStartRange(Math.round(value)); zoom([value, endRange]) } 
      else        { startRange < value ? easeVal(Math.round(value), 0) : easeVal(Math.round(value), 0, false) }
    }
  }

  const debounce = (values) => {
    const timer = setTimeout(() => { handleFieldOnChange(values) }, 1)
    return () => clearTimeout(timer)
  };

  const handleUpdateRangeFilter = (e) => {
    if (startRange !== '' || endRange !== '') {
      updateRangeFilter(`${ startRange !== '' ? startRange : '_INF_' }<_<>_>${ endRange !== '' ? endRange : '_INF_' }`)
    } 
    else { removeRangeFilter(e) }
    setDraft(false)
  };

  const handleRemoveRangeFilter = (e) => {
    setStartRange(Math.floor(hasMin))
    setEndRange(Math.floor(hasMax))
    removeRangeFilter(e)
    setDraft(false)
  }

  const handleFieldOnChange = (values) => {
    let start = startRange;
    let end   = endRange;
    if (values[0] || values[0] === 0) { start = parseInt(values[0]) }
    if (values[1] || values[1] === 0) { end = parseInt(values[1])   }
    setStartRange(start)
    setEndRange(end)
    setDraft(true)
  };

  let timer2;
  let last = true;
  const lazyCorrection = (values) => {
    last = false
    clearTimeout(timer2)
    timer2 = setTimeout(() => {
      last = true;
      let range = values;
      if (range[0]) {
        if (range[0] >= endRange) {
          range[0] = endRange;
          range[1] = endRange;
        } else if (range[0] < baselineMin()) {
          range[0] = baselineMin();
          range[1] = endRange;
        }
      } else if (range[1]) {
        if (range[1] <= startRange) {
          range[1] = startRange;
          range[0] = startRange;
        } else if (range[1] > baselineMax) {
          range[1] = baselineMax;
          range[0] = startRange;
        }
      }
      if (range[0] && range[1] && last) {
        handleFieldOnChange(range, true);
      }
      if (last) {
        range[0] = range[0] ? range[0] : startRange;
        range[1] = range[1] ? range[1] : endRange;
        zoom(range);
      }
    }, 500);
  };



  const scanButtonCss = `
    basis-1/6 
    h-[24px] 
    min-w-[30px] 
    flex-grow
    bg-[#A377B8]
    text-white
    mt-px
    opacity-70
    hover:opacity-90 focus:opacity-90 active:opacity-100
    focus:outline-none
    aria-disabled:opacity-50 aria-disabled:hover:opacity-50 aria-disabled:cursor-default aria-disabled:pointer-events-none
  `

  return (
    <div className="w-full flex transform-gpu">
      <div className="w-full flex-wrap justify-between">

        <div className="inline-flex flex justify-between pb-2 text-[11px] min-w-[100%]">
          <PresenceControls 
            selectedValues={selectedValues}
            setPresenceFilter={handlePresenceFilter}
            unsetPresenceFilter={removeRangeFilter}
          />
        </div>

        <div 
          className={`
            inline-flsex flex-col w-[100%] 
            ${ selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>' ? 'opacity-30 pointer-events-none' : ''}
          `}
        >
          <input
            type="number"
            className={`
              text-xs px-1 py-[3px] mr-1 rounded-none form-input inline-flex w-[calc(50%-4px)] border-[#dfe2e8] text-gray-500 text-center
              focus:border-purple-300 focus:outline-none focus:shadow-none cursor-pointer 
            `}
            value={startRange}
            onChange={(e) => {
              handleFieldOnChange([e.target.value, endRange]);
              lazyCorrection([e.target.value, null]);
            }}
            min={baselineMin()}
            max={endRange}
            step={1}
          />
          <input
            type="number"
            className={`
              text-xs px-1 py-[3px] ml-1 rounded-none form-input inline-flex w-[calc(50%-4px)] border-[#dfe2e8] text-gray-500 text-center
              focus:border-purple-300 focus:outline-none focus:shadow-none cursor-pointer 
            `}
            value={endRange}
            onChange={(e) => {
              handleFieldOnChange([startRange, e.target.value, endRange]);
              lazyCorrection([null, e.target.value]);
            }}
            min={startRange}
            max={baselineMax}
            step={1}
          />
        </div>
        <div
          className={`
            range-slider relative min-w-[100%]
            mb-[12px] pt-[2px]
            ${ selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>' ? 'opacity-30 pointer-events-none' : '' }
          `}
        >
          <div
            className={`
              range-bar
              relative 
              h-[3px] 
              mt-[9px]
              border-[2px] border border-transparent
              bg-gray-100
              rounded-lg
              min-w-full
              cursor-pointer
            `}
            onClick={(e) => clickBar(e)}
            tabIndex="0"
          >
            <div
              className={`
                h-[3px] 
                bg-[#AC84BF]
                rounded-lg 
                absolute 
                pointer-events-none
                left-[${ startRange < endRange ? Math.min(100, Math.max(0, Math.floor(((startRange - minRange) / (maxRange - minRange)) * 100))) : 0 }%]
                right-[${ endRange > startRange ? Math.min(100, Math.max(0, Math.round(((maxRange - endRange) / (maxRange - minRange)) * 100))) : 100 }%]
                top-[-1.5px]
              `}
            ></div>
          </div>
          <div className="range-input">
            <input
              type="range"
              className={`w-[100%] h-[3px] top-[-3px] cursor-pointer`}
              value={startRange}
              onChange={(e) => { e.target.value < endRange ? debounce([e.target.value, null]) : e.preventDefault(); }}
              onMouseUp={(e) => { zoom([e.target.value, endRange]); }}
              min={minRange}
              max={maxRange}
              step={1}
            />
            <input
              type="range"
              className={`w-[100%] h-[3px] top-[-3px] cursor-pointer`}
              value={endRange}
              onChange={(e) => { e.target.value > startRange ? debounce([null, e.target.value]) : e.preventDefault(); }}
              onMouseUp={(e) => { zoom([startRange, e.target.value]); }}
              min={minRange}
              max={maxRange}
              step={1}
            />
          </div>
        </div>

        <div
          className={`
            w-full flex flex-wrap justify-between
            px-1 mt-[-8px]
            font-mono slashed-zero tabular-nums text-center text-[10px]
            ${ selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>' ? 'opacity-30 pointer-events-none' : ''}
          `}
        >
          <div className={`w-[12.5%] text-left`}>
            { minRange.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,").split(".")[0] }
          </div>
          <div className={`basis-[25%] text-center`}>
            { Math.floor((maxRange - minRange) * 0.25 + minRange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,").split(".")[0] }
          </div>
          <div className={`basis-[25%] text-center`}>
            { Math.floor((maxRange - minRange) * 0.5 + minRange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,").split(".")[0] }
          </div>
          <div className={`basis-[25%] text-center`}>
            { Math.floor((maxRange - minRange) * 0.75 + minRange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,").split(".")[0] }
          </div>
          <div className={`w-[12.5%] text-right`}>
            { maxRange.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,").split(".")[0] }
          </div>
        </div>

        <div className={`w-full mt-[5px] ${ selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>' ? 'opacity-40 pointer-events-none' : '' }`}> 
          <div className="text-[11px] flex">
            <button
              onClick={() => { setMinRange(baselineMin()) }}
              onMouseUp={(e) => { easeVal(baselineMin(), 0, false) }}
              aria-disabled={startRange !== "" && startRange <= minRange}
              className={`${ scanButtonCss } rounded-l-[2px] mr-px`}
            >
              <span className="mdi mdi-arrow-collapse-left pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                if (isZoom) {
                  zoom([ Math.floor(Math.max(startRange - (maxRange - minRange) * 0.1, baselineMin())), endRange ])
                  setStartRange(Math.floor(Math.max(startRange - (maxRange - minRange) * 0.1, baselineMin())))
                }
                else { 
                  easeVal(Math.floor(Math.max(startRange - (maxRange - minRange) * 0.1, baselineMin())), 0, false) 
                }
              }}
              aria-disabled={startRange !== "" && startRange <= minRange}
              className={`${ scanButtonCss } mr-px`}
            >
              <span className="mdi mdi-chevron-left pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                if (isZoom) {
                  zoom([ Math.floor(Math.min(startRange + (maxRange - minRange) * 0.25, endRange)),endRange ])
                  setStartRange(Math.floor(Math.min(startRange + (maxRange - minRange) * 0.25, endRange)))
                }
                else {
                  easeVal(Math.floor(Math.min(startRange + (maxRange - minRange) * 0.25, endRange)), 0, true)
                }
              }}
              aria-disabled={startRange !== "" && startRange >= endRange}
              className={`${ scanButtonCss } mr-px`}
            >
              <span className="mdi mdi-chevron-right pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                if (isZoom) {
                  zoom([ Math.floor(endRange - (endRange - startRange) * 0.25), endRange ])
                  setStartRange(Math.floor(endRange - (endRange - startRange) * 0.25))
                }
                else {
                  easeVal(Math.floor(endRange - (endRange - startRange) * 0.25), 0, true);
                }
              }}
              aria-disabled={startRange !== "" && startRange >= endRange}
              className={`${ scanButtonCss } rounded-r-[2px] mr-px`}
            >
              <span className="mdi mdi-arrow-collapse-right pointer-events-none"></span>
            </button>
            <button
              onClick={() => { zoom([startRange, endRange], true) }}
              aria-disabled={isZoom}
              className={`${ scanButtonCss } rounded-l-[2px] ml-0.5`}
            >
              <span className="mdi mdi-plus pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                setMaxRange(baselineMax);
                setMinRange(baselineMin());
                easeVal(baselineMin(), 0, false);
                easeVal(baselineMax, 1);
              }}
              aria-disabled={(!minRange && minRange !== 0) || !maxRange || (minRange == startRange && maxRange == endRange)}
              className={`${ scanButtonCss } mx-px bg-indigo-700`}
            >
              <span className="mdi mdi-arrow-left-right pointer-events-none"></span>
            </button>
            <button
              onClick={() => { zoom([startRange, endRange], false) }}
              aria-disabled={!isZoom}
              className={`${ scanButtonCss } rounded-r-[2px] mr-0.5`}
            >
              <span className="mdi mdi-minus pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                if (isZoom) {
                  zoom([ startRange, Math.floor(endRange - (endRange - startRange) * 0.75) ])
                  setEndRange(Math.floor(endRange - (endRange - startRange) * 0.75))
                }
                else {
                  easeVal(Math.floor(endRange - (endRange - startRange) * 0.75), 1, false)
                }
              }}
              aria-disabled={endRange !== "" && endRange <= startRange}
              className={`${ scanButtonCss } rounded-l-[2px] mx-px`}
            >
              <span className="mdi mdi-arrow-collapse-left pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                if (isZoom) {
                  zoom([ startRange, Math.floor(Math.max(endRange - (maxRange - minRange) * 0.1, startRange)) ])
                  setEndRange(Math.floor(Math.max(endRange - (maxRange - minRange) * 0.1, startRange)))
                }
                else {
                  easeVal(Math.floor(Math.max(endRange - (maxRange - minRange) * 0.1, startRange)), 1, false)
                }
              }}
              aria-disabled={endRange !== "" && endRange <= startRange}
              className={`${ scanButtonCss } mr-px`}
            >
              <span className="mdi mdi-chevron-left pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                if (isZoom) {
                  zoom([ startRange,Math.floor(Math.min(endRange + (maxRange - minRange) * 0.1, baselineMax)) ])
                  setEndRange(Math.floor(Math.min(endRange + (maxRange - minRange) * 0.1, baselineMax)))
                }
                else {
                  easeVal(Math.floor(Math.min(endRange + (maxRange - minRange) * 0.1, baselineMax)), 1, true)
                }
              }}
              aria-disabled={endRange !== "" && endRange >= maxRange - 1}
              className={`${ scanButtonCss } mr-px`}
            >
              <span className="mdi mdi-chevron-right pointer-events-none"></span>
            </button>
            <button
              onClick={() => {
                setMaxRange(baselineMax)
                easeVal(baselineMax, 1, true)
              }}
              aria-disabled={endRange !== "" && endRange >= baselineMax}
              className={`${ scanButtonCss } rounded-r-[2px]`}
            >
              <span className="mdi mdi-arrow-collapse-right pointer-events-none"></span>
            </button>
          </div>
        </div>
        <div 
          className={`
            flex justify-between items-end
            w-[100%] h-max
            pt-2 
            ${ selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>' ? 'opacity-30 pointer-events-none' : 'opacity-70 '}
          `}
        >
          <div className="text-left w-[25%] text-[11px] ">
            {(baselineMin() || baselineMin() === 0) && (
              <span>
                First:{" "}{ (hasMin || min || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,").split(".")[0] }
              </span>
            )}
          </div>
          <div className="text-center w-[50%] text-[13px] ">
            {title && 
              <span>
                { title }
              </span>
            }
          </div>
          <div className="text-right w-[25%] text-[11px] ">
            {baselineMax && (
              <span>
                Last:{" "}{ hasMax.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,").split(".")[0] }
              </span>
            )}
          </div>
        </div>        
      </div>

      <div className="flex flex-wrap w-fit">
        <div className="flex-col ml-[8px] w-[26px]">
          <button
            type="button"
            onClick={(e) => { handleRemoveRangeFilter(e); }}
            disabled={(!isDraft && !selectedValues.length)}
            className="
              disabled:opacity-40 disabled:pointer-events-none 
              inline-flex justify-center items-center
              w-[26px] h-[26px]
              rounded-[3px]
              -mt-px
              border 
              text-[11px] font-medium 
              text-white 
              bg-rose-600 
              opacity-80 
              hover:bg-rose-500 
              focus:outline-none focus:border-rose-700 focus:shadow-outline-rose 
              active:bg-rose-700 
              transition duration-150 ease-in-out
            "
          >
            <span className="mdi mdi-close smt-px mx-px"></span>
          </button>
          <button
            type="button"
            onClick={(e) => { handleUpdateRangeFilter(e); }}
            disabled={!isDraft}
            className="
              disabled:opacity-40 disabled:pointer-events-none 
              inline-flex justify-center items-center
              w-[26px] h-[26px] 
              rounded-[3px]
              mt-[6.5px]
              border 
              text-[11px] font-medium 
              text-white 
              bg-emerald-600 
              opacity-80 
              hover:bg-emerald-500 
              focus:outline-none focus:border-emerald-700 focus:shadow-outline-emerald 
              active:bg-emerald-700 
              transition duration-150 ease-in-out
            "
          >
            <span className="mdi mdi-check smt-px mx-px"></span>
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default RangeFilter;
