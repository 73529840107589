import React from "react";
import Moment from "react-moment";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { activityEvents } from "./../../graphql/queries/reportQueries";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useTypeFilters from "./../../hooks/typeFilters";
import useRootPageContext from "./../../hooks/rootPageContext";
import Permissions, { tenantNoAccessFallbackList } from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import { TENANT_ACTIVITY_EVENT_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import { Heading } from "./../../moose-ui/Typography";
import Search from "./../../moose-ui/Search";
import { ItemsNotFound } from "./../../moose-ui/Typography";

import ActivityEventsReport from "./../../components/reports/ActivityEventsReport";

const ALL_TENANT_ACTIVITY_EVENTS = gql(activityEvents);

const DISPLAY_FIELDS = ["session_id", "datetime"];

const TenantActivityEvents = () => {
  const { id: userId, page, limit, orderBy, orderHow, search } = useParams();
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.UserActivityEvents, {
    noAccessFallbackList: tenantNoAccessFallbackList,
    variables: { id: userId }
  });
  const { search: queryString } = useLocation();
  const { rootContext } = useRootPageContext();
  const {
    nextPage,
    prevPage,
    applySearch,
    applyFilters,
    resetFilters,
    fixShadowQueryParams,
  } = useBaseNavigation(TENANT_ACTIVITY_EVENT_MODEL_NAME, {
    urlContext: `${rootContext}/${userId}`,
  });
  const { data, loading, error } = useQuery(ALL_TENANT_ACTIVITY_EVENTS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString: fixShadowQueryParams(`?user=${userId}`, queryString),
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: filtersUpdateFilter,
    resetFilters: filtersResetFilters,
    removeFilterValue: filtersRemoveFilterValue,
    applyFilters: filtersApplyFilters,
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "activityEvent",
    context: "tenant",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString,
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <ActivityEventsReport
      loading={loading}
      data={data}
      onApplySearch={applySearch}
      onNextPage={nextPage}
      onPrevPage={prevPage}
      typeFilters={typeFilters}
      onApplyFilters={filtersApplyFilters}
      onResetFilters={filtersResetFilters}
      onRemoveFiltervalue={filtersRemoveFilterValue}
      onUpdateFilter={filtersUpdateFilter}
      hasActiveFilters={hasActiveFilters}
      selectedFilters={selectedFilters}
      displayFields={DISPLAY_FIELDS}
    />
  );
};

export default TenantActivityEvents;
