export const createLandlordMutation = `mutation createLandlord(
  $email: String,
  $subdomain: String,
  $account_name: String,
  $firstname: String,
  $lastname: String,
  $address: String,
  $towncity: String,
  $postcode: String,
  $telephone: String,
  $password: String,
  $label_id: Int,
  $platform_name: String
) {  
createLandlord(
    input: {
        email: $email,
        subdomain: $subdomain,
        account_name: $account_name,
        firstname: $firstname,
        lastname: $lastname,
        address: $address,
        towncity: $towncity,
        postcode: $postcode,
        telephone: $telephone,
        password: $password,
        label_id: $label_id,
        platform_name: $platform_name
    }
) {
  id
}
}
`;

export const createLandlordDeprecatedMutation = `mutation createLandlordDeprecated(
  $email: String,
  $subdomain: String,
  $account_name: String,
  $firstname: String,
  $lastname: String,
  $address: String,
  $towncity: String,
  $postcode: String,
  $telephone: String,
  $password: String,
  $label_id: Int
) {  
  createLandlordDeprecated(
    input: {
        email: $email,
        subdomain: $subdomain,
        account_name: $account_name,
        firstname: $firstname,
        lastname: $lastname,
        address: $address,
        towncity: $towncity,
        postcode: $postcode,
        telephone: $telephone,
        password: $password,
        label_id: $label_id
    }
) {
  id
}
}
`;

export const updateClientExtension = `mutation updateClientExtension(
    $client_id: Int!, 
    $verbally_agreed_date: DateTime, 
    $contract_signed_date: DateTime, 
    $contract_end_date: DateTime, 
    $final_bill_date: DateTime, 
    $contract_term_length: Int, 
    $service_level: String, 
    $data_received_date: DateTime, 
    $launch_date: DateTime, 
    $expected_users: Int, 
    $actual_users: Int, 
    $launch_number_user_diff: Int, 
    $campaign_type_id: Int, 
    $client_type_id: Int, 
    $status: String, 
  ) {  
    updateClientExtension(
      client_id: $client_id, 
      verbally_agreed_date: $verbally_agreed_date, 
      contract_signed_date: $contract_signed_date, 
      contract_end_date: $contract_end_date, 
      final_bill_date: $final_bill_date, 
      contract_term_length: $contract_term_length, 
      service_level: $service_level, 
      data_received_date: $data_received_date, 
      launch_date: $launch_date, 
      expected_users: $expected_users, 
      actual_users: $actual_users, 
      launch_number_user_diff: $launch_number_user_diff, 
      campaign_type_id: $campaign_type_id, 
      client_type_id: $client_type_id, 
      status: $status, 
    ) {
      id 
    }
  }
  `;

// client_id
// verbally_agreed_date
// contract_signed_date
// contract_end_date
// final_bill_date
// contract_term_length
// service_level
// data_received_date
// launch_date
// number_of_days_to_launch
// expected_users
// actual_users
// launch_number_user_diff
// campaign_type_id
// client_type_id
// status

export const updateLandlordMutation = `mutation updateLandlord(
      $id: ID!,
      $email: String,
      $account_name: String,
      $firstname: String,
      $lastname: String,
      $address: String,
      $towncity: String,
      $postcode: String,
      $telephone: String,
      $label_id: Int
  ) {  
    updateLandlord(
        input: {
            id: $id,
            email: $email,
            account_name: $account_name,
            firstname: $firstname,
            lastname: $lastname,
            address: $address,
            towncity: $towncity,
            postcode: $postcode,
            telephone: $telephone,
            label_id: $label_id
        }
    ) {
      id
    }
  }
  `;

export const activateOfferTagMutation = `mutation activateOfferTag(
    $account_id: Int!,
    $tag_id: Int!
) {  
  activateOfferTag(      
    account_id: $account_id,
    tag_id: $tag_id  
  ) {
    is_active
    tag_object {
      id
    }
  }
}
`;

export const deactivateOfferTagMutation = `mutation deactivateOfferTag(
  $account_id: Int!,
  $tag_id: Int!
) {  
  deactivateOfferTag(      
    account_id: $account_id,
   tag_id: $tag_id  
  ) {
    is_active
    tag_object {
      id
    }
  }
}
`;

export const activateOfferExclusionTagMutation = `mutation activateOfferExclusionTag(
  $account_id: Int!,
  $tag_id: Int!
) {  
activateOfferExclusionTag(      
  account_id: $account_id,
  tag_id: $tag_id  
) {
  is_active
  tag_object {
    id
  }
}
}
`;

export const deactivateOfferExclusionTagMutation = `mutation deactivateOfferExclusionTag(
  $account_id: Int!,
  $tag_id: Int!
) {  
  deactivateOfferExclusionTag(      
    account_id: $account_id,
  tag_id: $tag_id  
) {
  is_active
  tag_object {
    id
  }
}
}
`;

export const includeOffersMutation = `mutation includeOffers(
  $account_id: Int!,
  $ids: [Int!]!
) {  
  includeOffers(      
    account_id: $account_id,
    ids: $ids
  ) {
    is_excluded
    offer_object {
      id
      title
      tags {
        id
        title
      }
    }
  }
}
`;

export const excludeOffersMutation = `mutation excludeOffers(
  $account_id: Int!,
  $ids: [Int!]!
) {  
  excludeOffers(      
    account_id: $account_id,
    ids: $ids
  ) {
    is_excluded
    offer_object {
      id
      title
      tags {
        id
        title
      }
    }
  }
}
`;

export const createCustomLinkMutation = `mutation createCustomLink(
  $owner_id: Int!,
  $text: String,
  $color: String,
  $icon: String,
  $href: String,
  $external: Boolean,
  $display_order: Int,
  $labels: [Int!]!
) {  
createCustomLink(
  owner_id: $owner_id,
  text: $text,
  color: $color,
  icon: $icon,
  href: $href,
  external: $external,
  display_order: $display_order,
  labels: $labels                
) {
  id
}
}
`;

export const updateCustomLinkMutation = `mutation updateCustomLink(
  $id: ID!,
  $text: String,
  $color: String,
  $icon: String,
  $href: String,
  $external: Boolean,
  $display_order: Int,
  $labels: [Int!]!
) {  
updateCustomLink(
  id: $id,
  text: $text,
  color: $color,
  icon: $icon,
  href: $href,
  external: $external,
  display_order: $display_order,
  labels: $labels        
) {
  id
}
}
`;

export const deleteCustomLinksMutation = `mutation deleteCustomLinks(
  $ids: [Int!]!
) {  
  deleteCustomLinks(      
    ids: $ids
  ) {
    id
  }
}
`;

export const createDirectRegisterLinkMutation = `mutation createDirectRegisterLink(
  $client_id: Int!,
  $is_enabled: Boolean,
  $register_code: String,
  $max_users_per_register_link: Int,
  $register_access_type: String,
  $register_label_id: Int,
  $enable_label_select_by_user: Boolean,
  $labels: [Int!]
) {  
createDirectRegisterLink(
  client_id: $client_id,
  is_enabled: $is_enabled,
  register_code: $register_code,
  max_users_per_register_link: $max_users_per_register_link,
  register_access_type: $register_access_type,
  register_label_id: $register_label_id
  enable_label_select_by_user: $enable_label_select_by_user
  labels: $labels
) {
  id
}
}
`;

export const updateDirectRegisterLinkMutation = `mutation updateDirectRegisterLink(
  $id: ID!,
  $is_enabled: Boolean,
  $register_code: String,
  $max_users_per_register_link: Int,
  $register_access_type: String,
  $register_label_id: Int,
  $enable_label_select_by_user: Boolean,
  $labels: [Int!]
) {  
updateDirectRegisterLink(
  id: $id,
  is_enabled: $is_enabled,
  register_code: $register_code,
  max_users_per_register_link: $max_users_per_register_link,
  register_access_type: $register_access_type,
  register_label_id: $register_label_id,
  enable_label_select_by_user: $enable_label_select_by_user
  labels: $labels
) {
  id
}
}
`;

export const deleteDirectRegisterLinksMutation = `mutation deleteDirectRegisterLinks(
  $ids: [Int!]!
) {  
  deleteDirectRegisterLinks(      
    ids: $ids
  ) {
    id
  }
}
`;

export const updateClientApiMutation = `mutation updateClientApi(
  $account_id: Int!,
  $access_key: String,
  $is_enabled: Boolean,
  $show_activation_status: Boolean,
  $enable_activation_push: Boolean
) {  
  updateClientApi(      
    account_id: $account_id,
    access_key: $access_key,
    is_enabled: $is_enabled,
    show_activation_status: $show_activation_status,
    enable_activation_push: $enable_activation_push
  ) {
    id
    account_id
    access_key
    is_enabled
    show_activation_status
    enable_activation_push
  }
}
`;

export const updateExtensionsMutation = `mutation updateExtensions(
  $account_id: Int!,
  $api_client_users_show_nps: Boolean,
  $api_client_users_show_extended_nps: Boolean,
  $allow_csv_export: Boolean,
  $allow_pdf_report: Boolean,
  $allow_pdf_promotion: Boolean,
  $notify_about_next_invoice: Boolean,
  $notify_email: String
) {  
  updateExtensions(      
    account_id: $account_id,
    api_client_users_show_nps: $api_client_users_show_nps,
    api_client_users_show_extended_nps: $api_client_users_show_extended_nps,
    allow_csv_export: $allow_csv_export,
    allow_pdf_report: $allow_pdf_report,
    allow_pdf_promotion: $allow_pdf_promotion,
    notify_about_next_invoice: $notify_about_next_invoice,
    notify_email: $notify_email
  ) {
    id
    account_id
    api_client_users_show_nps
    api_client_users_show_extended_nps
    allow_csv_export
    allow_pdf_report
    allow_pdf_promotion
    notify_about_next_invoice
    notify_email
  }
}
`;

export const updateClientEmailMutation = `mutation updateClientEmail(
  $account_id: Int!,
  $label_id: Int!,
  $logo_src: String,
  $primary_colour: String,
  $secondary_colour: String,
  $button_colour: String,
  $button_text_colour: String,
  $basic_activation_message_1: String,
  $basic_activation_message_2: String,
  $basic_activation_message_3: String,
  $premium_activation_message_1: String,
  $premium_activation_message_2: String,
  $premium_activation_message_3: String,
  $feedback_campaigns: Boolean,
  $custom_feedback: Boolean      
) {  
  updateClientEmail(      
    account_id: $account_id,
    label_id: $label_id,
    logo_src: $logo_src,
    primary_colour: $primary_colour,
    secondary_colour: $secondary_colour,
    button_colour: $button_colour,
    button_text_colour: $button_text_colour,
    basic_activation_message_1: $basic_activation_message_1,
    basic_activation_message_2: $basic_activation_message_2,
    basic_activation_message_3: $basic_activation_message_3,
    premium_activation_message_1: $premium_activation_message_1,
    premium_activation_message_2: $premium_activation_message_2,
    premium_activation_message_3: $premium_activation_message_3,
    feedback_campaigns: $feedback_campaigns,
    custom_feedback: $custom_feedback,
  ) {
    id
    account_id
    label_id
    logo_src
    primary_colour
    secondary_colour
    button_colour
    button_text_colour
    basic_activation_message_1
    basic_activation_message_2
    basic_activation_message_3
    premium_activation_message_1
    premium_activation_message_2
    premium_activation_message_3
  }
}
`;

export const updatePaymentSettingsMutation = `mutation updatePaymentSettings(
  $account_id: Int!,
  $type: String,
  $maintance_fee_monthly_amount: Float,
  $maintance_fee_monthly_billing_day: Int,
  $gifted_premium_access_fee_monthly: Float,
  $acquired_premium_access_fee_monthly: Float,
  $minimum_payment_amount: Float,
  $number_of_free_premium_users: Int,
  $can_give_basic: Boolean,
  $can_give_premium: Boolean,
  $can_upgrade: Boolean,
  $must_have_payment_method: Boolean
) {  
  updatePaymentSettings(      
    account_id: $account_id,
    type: $type,
    maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
    maintance_fee_monthly_billing_day: $maintance_fee_monthly_billing_day,
    gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
    acquired_premium_access_fee_monthly: $acquired_premium_access_fee_monthly,
    minimum_payment_amount: $minimum_payment_amount,
    number_of_free_premium_users: $number_of_free_premium_users,
    can_give_basic: $can_give_basic,
    can_give_premium: $can_give_premium,
    can_upgrade: $can_upgrade,
    must_have_payment_method: $must_have_payment_method
  ) {
    id
    account_id
    type
    maintance_fee_monthly_amount
    maintance_fee_monthly_billing_day
    gifted_premium_access_fee_monthly
    acquired_premium_access_fee_monthly
    minimum_payment_amount
    number_of_free_premium_users
    can_give_basic
    can_give_premium
    can_upgrade
    must_have_payment_method
  }
}
`;

export const createImportMutation = `mutation createImport(
  $owner_id: Int!,
  $file_id: Int,
  $name: String,
  $description: String
) {  
  createImport(      
    owner_id: $owner_id,
    file_id: $file_id,
    name: $name,
    description: $description
  ) {
    id
    owner_id
    file_id
    name
    description
  }
}
`;

export const startImportMutation = `mutation startImport($id: ID!) {  
  startImport(id: $id) {
    id    
  }
}
`;

export const createBulkOperationMutation = `mutation createBulkOperation(
  $owner_id: Int!,
  $file_id: Int,
  $name: String,
  $description: String
) {  
  createBulkOperation(      
    owner_id: $owner_id,
    file_id: $file_id,
    name: $name,
    description: $description
  ) {
    id
    owner_id
    file_id
    name
    description
  }
}
`;

export const startBulkOperationMutation = `mutation startBulkOperation($id: ID!) {  
  startBulkOperation(id: $id) {
    id    
  }
}
`;

export const loginAsMutation = `mutation loginAsLandlord(
  $account_id: Int!
) {  
  loginAsLandlord(      
    account_id: $account_id
  ) {
    id,
    user_id,
    expires_at,
    url,
    code
  }
}
`;
