import React from "react";
import { CustomPicker } from "react-color";
import {
  EditableInput,
  Hue,
  Saturation
} from "react-color/lib/components/common";

export const MyPicker = ({ hex, hsl, hsv, onChange }) => {
  const styles = {
    hue: {
      height: 10,
      position: "relative",
      width: '100%', 
    },
    saturation: {
      width: 100,
      height: 100,
      position: "relative", 
      marginBottom: 6, 
      width: '100%'
    },
    input: { 
      width: '100%', 
      height: '100%', 
      outline: 0
    },
    swatch: {
      width: 54,
      // height: 38,
      top: 0,
      bottom: 0,
      right: 0,
      position: 'absolute', 
      background: hex, 
      borderLeft: '1px solid #dfe2e8', 
    }
  }; 
  return (
    <div>
      <div 
        style={{ padding: '8px 12px', position: 'relative', outlineStyle: 'solid', outlineColor: 'rgba(164,202,254,.45)' }} 
        className="p-0 outline-0 focus-within:outline focus-within:outline-4 rounded-none form-input block w-full border-[#dfe2e8] text-gray-500 sm:text-sm sm:leading-5 "
      >
        <EditableInput
          style={{ input: styles.input }}
          value={hex}
          onChange={onChange}
        />
        <div className="group">
          <div style={styles.swatch}>
            <div 
              style={{ width: 150, position: 'absolute', top: '0', transform: 'translateY(-100%)', right: 0, }} 
              className="-mr-px hidden group-hover:block bg-white p-3 border border-[#dfe2e8]"
            >
              <div style={{ position: 'absolute', top: '-60px', right: '-60px', bottom: '-60px', left: '-60px', background: 'transparent' }}>
              </div>
              <div style={styles.saturation}>
                <Saturation hsl={hsl} hsv={hsv} onChange={onChange} />
              </div>
              <div style={styles.hue}>
                <Hue hsl={hsl} onChange={onChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPicker(MyPicker);