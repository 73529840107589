import React from "react";
import {
  ActionList,
  ActionGroup,
  Action,
} from "./../../../../mood-ui/DataTable";

const ChainListActions = ({ onClearSelection, onEnable, onDisable }) => (
  <ActionList onClearSelection={onClearSelection}>
    <ActionGroup>
      <Action label="Enable" onClick={onEnable} />
      <Action label="Disable" onClick={onDisable} />
    </ActionGroup>
  </ActionList>
);

export default ChainListActions;
