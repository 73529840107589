import { useParams, useHistory, useLocation } from "react-router-dom";

export const APP_DEFAULT_LIST_PARAMS = "1/25/0/0/0";

export const SYSTEM_FILTERS = ["startDate", "endDate"];

export const DATE_FILTERS = ["dates"];
/*
 * useBaseNavigation hook provides basic navigation when dealing with list/table/grid
 * nextPage - navigate to the next page in the list
 * prevPage - navigate to the prev page in the list
 * editForm - open edit form of current model
 *
 * props:
 * model - name of the current model. Not a part of the routing and must be ingected
 */
const useBaseNavigation = (
  model,
  options = { urlContext: false, queryDefaults: {}, }
) => {

  const history = useHistory();
  
  let { search, page, limit, orderBy, orderHow } = useParams();
  
  const { search: queryString } = useLocation();
  
  const urlContext = options.urlContext ? `/${options.urlContext}` : "";
  
  const query = { queryString: queryString, queryParams: {} };

  if (typeof options.queryDefaults == "undefined") {
    options.queryDefaults = {};
  }

  const createQueryStringFromDataObject = (filterObject) => {
    const queryKeys = Object.keys(filterObject);
    let missing = []
    let present = []
    const queryParts = queryKeys.map((queryKey) => {
      const qs = filterObject[queryKey].reduce((str, item, index) => {
        if (item == '<_MISSING_>') { missing.push(queryKey); return str }
        if (item == '<_PRESENT_>') { present.push(queryKey); return str }
        if (queryKey == 'missing_values' || queryKey == 'present_values') { return str }
        return str + (index > 0 ? '<_OR_>' : '') + item
      }, `${queryKey}=`)
      if (qs !== `${queryKey}=` && qs.length) return qs
      else return null
    });
    missing.length > 0 && queryParts.unshift('missing_values=' + missing.join(','))
    present.length > 0 && queryParts.unshift('present_values=' + present.join(','))
    return '?' + queryParts.filter(p => p !== null).join("&");
  };

  const restoreDataObjectFromQueryString = (only = []) => {
    const qStr = queryString.replace(/\?/, "");
    const qParts = qStr.split("&");
    const qObj = {};
    const missingValues = {};
    const presentValues = {};
    for (let qp of qParts) {
      if (qp.length == 0) continue;
      const [queryKey, value] = qp.split("=");
      if (only.length > 0 && only.indexOf(queryKey) == -1) continue;
      if (DATE_FILTERS.includes(queryKey)) continue;
      if (value.includes('%3C_OR_%3E')) { qObj[queryKey] = value.split('%3C_OR_%3E') }
      else if (value.includes('<_OR_>')) { qObj[queryKey] = value.split('<_OR_>') } 
      else if (value.length > 0){ qObj[queryKey] = [ value ] }
      if (queryKey === 'missing_values') {
        const missing = value.split(',')
        missing.forEach(key => missingValues[key] = ['<_MISSING_>'])
      } 
      else if (queryKey === 'present_values') {
        const present = value.split(',')
        present.forEach(key => presentValues[key] = ['<_PRESENT_>'])
      }
    }
    return {...qObj, ...presentValues, ...missingValues};
  };

  const defaultsKeys = Object.keys(options.queryDefaults);

  const restored = restoreDataObjectFromQueryString(queryString);
  const restoredKeys = Object.keys(restored);
  for (const dk of defaultsKeys) {
    if (restoredKeys.indexOf(dk) == -1) {
      restored[dk] = options.queryDefaults[dk];
    }
  }
  query.queryString = createQueryStringFromDataObject(restored);
  query.queryParams = { ...restored };

  const getCurrentUrl = () => {
    return {
      urlContext,
      model,
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
      url: `${urlContext}/${model}/${page}/${limit}/${orderBy}/${orderHow}/${search}${queryString}`,
    };
  };
  const setPageNum = (pNum) => {
    let url = `${urlContext}/${model}/${pNum}/${limit}/${orderBy}/${orderHow}/${search}${queryString}`;
    history.push(url);
  };

  const nextPage =  () => {
    const pNum = parseInt(page) > 0 ? parseInt(page) + 1 : parseInt(page) + 2;
    setPageNum(pNum);
  };

  const prevPage = () => {
    const pNum = parseInt(page) > 1 ? parseInt(page) - 1 : 1;
    setPageNum(pNum);
  };

  const editForm = (id) => {
    let url = `${urlContext}/${model}/${id}`;
    if (model === 'clients') {
      url = `${urlContext}/${model}/${id}`;
    }
    history.push(url);
  };

  const viewForm = (id) => {
    let url = `${urlContext}/${model}/${id}/view`;
    history.push(url);
  };

  const createForm = (args = false) => {
    let url = `${urlContext}/${model}/create`;
    if (args && args.context) {
      const queryString = createQueryStringFromDataObject(args.context);
      url = url + queryString;
    }
    history.push(url);
  };

  const createFormDeprecated = (args = false) => {
    let url = `${urlContext}/${model}/create-deprecated`;
    if (args && args.context) {
      const queryString = createQueryStringFromDataObject(args.context);
      url = url + queryString;
    }
    history.push(url);
  };

  const createFormOther = (id) => {
    let url = `${urlContext}/${model}/create-other`;
    history.push(url);
  };

  const getRelatedModelEditUrl = (relatedModel, id) => {
    return `/${relatedModel}/${id}`;
  };

  const applySearch = (query) => {
    query = query.length > 0 ? query : "0";
    let url = `${urlContext}/${model}/1/${limit}/${orderBy}/${orderHow}/${query}${queryString}`;
    history.push(url);
  };

  const applyFilters = (filterObject, patch = true) => {
    const keepFilters = patch ? [] : SYSTEM_FILTERS;
    const restoredObject = restoreDataObjectFromQueryString(keepFilters);
    const mergedObject = mergeFilterDataObjects(restoredObject, filterObject);
    const queryString = createQueryStringFromDataObject(mergedObject);
    let url = `${urlContext}/${model}/1/${limit}/${orderBy}/${orderHow}/${search}${queryString}`;
    history.push(url);
  };

  const applyRawFilters = (filterObject, patch = true) => {
    const keepFilters = patch ? [] : SYSTEM_FILTERS;
    const restoredObject = restoreDataObjectFromQueryString(keepFilters);
    const mergedObject = mergeFilterDataObjects(restoredObject, filterObject);
    const queryString = createQueryStringFromDataObject(mergedObject);
    let url = `${urlContext}${queryString}`;
    history.push(url);
  };

  const resetFilters = () => {
    const restoredObject = restoreDataObjectFromQueryString(SYSTEM_FILTERS);
    const queryString = createQueryStringFromDataObject(restoredObject);
    let url = `${urlContext}/${model}/${page}/${limit}/${orderBy}/${orderHow}/${search}${queryString}`;
    history.push(url);
  };

  const resetRawFilters = () => {
    const restoredObject = restoreDataObjectFromQueryString(SYSTEM_FILTERS);
    const queryString = createQueryStringFromDataObject(restoredObject);
    let url = `${urlContext}${queryString}`;
    history.push(url);
  };

  const applySorting = (sortBy) => {
    const sortHow = orderBy == sortBy ? orderHow == "desc" ? "asc" : orderHow !== "asc" ? "desc" : 0 : 'desc'
    const ifSortBy = sortHow ? sortBy : 0
    let url = `${urlContext}/${model}/${page}/${limit}/${ifSortBy}/${sortHow}/${search}${queryString}`;
    history.push(url);
  };

  const mergeFilterDataObjects = (restored, incoming) => {
    const restoredKeys = Object.keys(restored);
    const incomingKeys = Object.keys(incoming);
    const allKeys = restoredKeys.concat(incomingKeys);
    const keys = [];
    for (const k of allKeys) {
      if (keys.indexOf(k) == -1) {
        keys.push(k);
      }
    }
    const mergedObject = {};
    for (const k of keys) {
      if (restoredKeys.indexOf(k) > -1) {
        mergedObject[k] = restored[k];
      }
      if (incomingKeys.indexOf(k) > -1) {
        if (typeof mergedObject[k] != "undefined") {
          if (SYSTEM_FILTERS.indexOf(k) > -1) {
            mergedObject[k] = incoming[k];
          } else {
            for (const v of incoming[k]) {
              if (mergedObject[k].indexOf(v) == -1) {
                mergedObject[k].push(v);
              }
            }
          }
        } 
        else { mergedObject[k] = incoming[k] }
      }
    }
    return mergedObject;
  };

  const goBack = () => {
    history.goBack();
  };

  const goTo = (url) => {
    history.push(url);
  };

  const fixShadowQueryParams = (shadowQuery, realQuery) => {
    if (!realQuery) return shadowQuery;
    if (typeof realQuery.length != "undefined" && realQuery.length > 0) {
      if (realQuery[0] == "?") {
        return `${shadowQuery}&${realQuery.substr(1)}`;
      }
    }
  };

  return {
    fixShadowQueryParams,
    setPageNum,
    nextPage,
    prevPage,
    page,  
    createForm,
    createFormOther,
    createFormDeprecated,
    editForm,
    viewForm,
    goBack,
    getRelatedModelEditUrl,
    applySearch,
    applyFilters,
    resetFilters,
    applyRawFilters,
    resetRawFilters,
    applySorting,
    orderBy,
    orderHow,
    goTo,
    queryString: query.queryString,
    queryParams: query.queryParams,
    composeQueryString: createQueryStringFromDataObject,
    getCurrentUrl,
  };
};

export default useBaseNavigation;
