import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createLandlordDeprecatedMutation } from "../graphql/mutations/landlordMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import PageSpinner from "./../moose-ui/PageSpinner";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useHyperState from "./../hooks/hyperState";
import useFormErrors from "../hooks/formErrors";
import useBaseNavigation from "../hooks/baseNavigation";
import useDataProvider from "./../hooks/dataProvider";
import { CLIENT_MODEL_NAME } from "../configs/constants";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../moose-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "../moose-ui/Controls";
import { TextField, SelectField } from "../moose-ui/Fields";
import { Heading } from "../moose-ui/Typography";

const CREATE_LANDLORD = gql(createLandlordDeprecatedMutation);

const LandlordCreateDeprecated = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.CreateClient);
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    label_id: 1,
    email: "",
    subdomain: "",
    account_name: "",
    firstname: "",
    lastname: "",
    address: "",
    towncity: "",
    postcode: "",
    telephone: "",
    password: "",
  });

  const [labels] = useDataProvider("labels");

  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const { goBack } = useBaseNavigation(CLIENT_MODEL_NAME);

  const {
    setFieldValue: handleFieldOnChange,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const [
    create,
    { data: createResult, loading: isCreating, error: createError },
  ] = useMutation(CREATE_LANDLORD, {
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    if (isCreating) return false;
    const data = { ...formData };
    create({ variables: data });
  };

  if (!formData) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading
          text={isCreating ? "New Client. Saving..." : "New Client"}
          description="Main client information."
        />
        <FormContainer>
          <FormData>
            <Row>
              <Col width={6}>
                <TextField
                  name="email"
                  label="Email"
                  value={formData.email}
                  onChange={handleFieldOnChange}
                  error={fieldError("email")}
                  isRequired={true}
                />
              </Col>
              <Col width={3}>
                <TextField
                  name="subdomain"
                  label="Subdomain"
                  value={formData.subdomain}
                  onChange={handleFieldOnChange}
                  error={fieldError("subdomain")}
                  isRequired={true}
                />
              </Col>
              <Col width={3}>
                <TextField
                  name="account_name"
                  label="Account Name"
                  value={formData.account_name}
                  onChange={handleFieldOnChange}
                  error={fieldError("account_name")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={6}>
                <TextField
                  name="firstname"
                  label="First Name"
                  value={formData.firstname}
                  onChange={handleFieldOnChange}
                  error={fieldError("firstname")}
                  isRequired={true}
                />
              </Col>
              <Col width={6}>
                <TextField
                  name="lastname"
                  label="Last Name"
                  value={formData.lastname}
                  onChange={handleFieldOnChange}
                  error={fieldError("lastname")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={6}>
                <TextField
                  name="address"
                  label="Address"
                  value={formData.address}
                  onChange={handleFieldOnChange}
                  error={fieldError("address")}
                  isRequired={true}
                />
              </Col>
              <Col width={2}>
                <TextField
                  name="towncity"
                  label="Area"
                  value={formData.towncity}
                  onChange={handleFieldOnChange}
                  error={fieldError("towncity")}
                  isRequired={true}
                />
              </Col>
              <Col width={1}>
                <TextField
                  name="postcode"
                  label="Postcode"
                  value={formData.postcode}
                  onChange={handleFieldOnChange}
                  error={fieldError("postcode")}
                  isRequired={true}
                />
              </Col>
              <Col width={3}>
                <TextField
                  name="telephone"
                  label="Primary Contact Number"
                  value={formData.telephone}
                  onChange={handleFieldOnChange}
                  error={fieldError("telephone")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={3}>
                <TextField
                  name="password"
                  label="Password"
                  value={formData.password}
                  onChange={handleFieldOnChange}
                  error={fieldError("password")}
                  isRequired={true}
                />
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default LandlordCreateDeprecated;
