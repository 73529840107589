import React, { useContext } from "react";
import {
  BUYER_LABEL_ID,
  HOME_OWNER_LABEL_ID,
  LANDLORD_LABEL_ID,
  OTHER_LABEL_ID,
  STAFF_LABEL_ID,
  STUDENT_LABEL_ID,
  VENDOR_LABEL_ID,
} from "../configs/constants";
import { LabelContext } from "../contexts/LabelContext";

const LabelContextSwitch = ({
  textHeading = "Applies to selected label",
  icon = true,
  dense = false,
}) => {
  const context = useContext(LabelContext);
  let color = "blue";
  if (context.activeLabel == LANDLORD_LABEL_ID) {
    color = "teal";
  } else if (context.activeLabel == STAFF_LABEL_ID) {
    color = "orange";
  } else if (context.activeLabel == VENDOR_LABEL_ID) {
    color = "yellow";
  } else if (context.activeLabel == BUYER_LABEL_ID) {
    color = "green";
  } else if (context.activeLabel == STUDENT_LABEL_ID) {
    color = "indigo";
  } else if (context.activeLabel == HOME_OWNER_LABEL_ID) {
    color = "green";
  } else if (context.activeLabel == OTHER_LABEL_ID) {
    color = "blue";
  }
  return (
    <div className={`bg-${color}-50 ${!dense && "p-1.5"}`}>
      <div className="flex select-none">
        {icon && (
          <div className="flex-shrink-0">
            <svg
              className={`h-6 w-6 text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
        <div
          className={
            "flex-1 md:flex pt-0.5 items-center " + (icon ? " ml-3" : "")
          }
        >
          <p
            className={`text-${color}-700 ${
              dense ? "leading-5 px-2 w-fit text-xs" : "text-sm leading-none"
            }`}
          >
            {textHeading}{" "}
            {context.labels
              .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
              .map((l) => (
                <span
                  key={l.id}
                  className={
                    `cursor-pointer border border-${color}-400 ${
                      !dense ? " first:ml-2 mx-1 px-1 py-0.5" : "px-1 ml-1"
                    } ` +
                    (parseInt(l.id) === parseInt(context.activeLabel)
                      ? ` text-white bg-${color}-500`
                      : ` text-${color}-500`)
                  }
                  onClick={() => context.onChangeLabel(l.id)}
                >
                  {l.name.charAt(0).toUpperCase() + l.name.slice(1)}
                </span>
              ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LabelContextSwitch;
