import React from "react";
// import { ThreeDots } from "react-loader-spinner";
import { Oval } from "react-loader-spinner";

const loadingCss = 'bg-[#FCFCFE] text-transparent bg-gradient-to-tl from-purple-100 via-purple-50 to-purple-100 animate-pulse opacity-40 bg-white border border-purple-100'

const PageSpinner = ({ opacity = 50, inline = false, fill = false, clean = false, clear = false }) => {
  return (
    <div
      className={`flex content-center flex-wrap justify-center w-full ${ !clear && loadingCss }`}
      style={{ height: inline ? '176px' : fill ? '100%' : 'calc(100vh - 56px)' }}
    >
      <div className="flex-item">
        {!clean && (
          <Oval
            height="80"
            width="80"
            radius="9"
            color="#592373"
            secondaryColor="#F3E8FF"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        )}
      </div>
    </div>
  );
};

export default PageSpinner;
