import React from "react";
import { ActionList, ActionGroup, Action } from "./../../mood-ui/DataTable";

const DirectRegisterLinkListActions = ({ onClearSelection, onDelete }) => (
  <ActionList onClearSelection={onClearSelection}>
    <ActionGroup>
      <Action label="Delete" onClick={onDelete} />
    </ActionGroup>
  </ActionList>
);

export default DirectRegisterLinkListActions;
