export const createTenantMutation = `mutation createTenant(
    $landlord_id: Int!,
    $email: String,
    $firstname: String,
    $lastname: String,
    $telephone: String,
    $address: String,
    $towncity: String,
    $postcode: String,
    $age_range: String,
    $locale: String,
    $gender: String,
    $access_type: String,
    $promo_premium_access: Boolean,
    $label_id: Int

) {  
  createTenant(
      input: {
          landlord_id: $landlord_id,
          email: $email,
          firstname: $firstname,
          lastname: $lastname,
          address: $address,
          towncity: $towncity,
          postcode: $postcode,
          telephone: $telephone,
          age_range: $age_range,
          locale: $locale,
          gender: $gender,
          access_type: $access_type,
          promo_premium_access: $promo_premium_access,
          label_id: $label_id
      }
  ) {
    id
  }
}
`;

export const updateTenantMutation = `mutation updateTenant(
  $id: ID!,
  $email: String,
  $firstname: String,
  $lastname: String,
  $telephone: String,
  $address: String,
  $towncity: String,
  $postcode: String,
  $age_range: String,
  $locale: String,
  $gender: String,
  $access_type: String,
  $promo_premium_access: Boolean,
  $tenancy_start: Date,
  $tenancy_end: Date,
  $notes: String,
  $landlord_id: Int!,
  $label_id: Int
) {  
updateTenant(
    input: {
        id: $id,
        email: $email,
        firstname: $firstname,
        lastname: $lastname,
        address: $address,
        towncity: $towncity,
        postcode: $postcode,
        telephone: $telephone,
        age_range: $age_range,
        locale: $locale,
        gender: $gender,
        access_type: $access_type,
        promo_premium_access: $promo_premium_access,
        tenancy_start: $tenancy_start,
        tenancy_end: $tenancy_end,
        notes: $notes,
        landlord_id: $landlord_id,
        label_id: $label_id
    }
) {
  id
}
}
`;

export const deleteTenantsMutation = `mutation deleteTenants(
  $ids: [Int!]!
) {  
  deleteTenants(      
    ids: $ids
  ) {
    id
  }
}
`;

export const activationPushMutation = `mutation activationPush(
  $ids: [Int!]!
) {  
  activationPush(      
    ids: $ids
  ) {
    id
  }
}
`;

export const syncWithACMutation = `mutation syncWithAC(
  $ids: [Int!]!
) {  
  syncWithAC(      
    ids: $ids
  ) {
    id
  }
}
`;

export const cancelUserDeletingMutation = `mutation cancelDeleting(
  $ids: [Int!]!
) {  
  cancelDeleting(      
    ids: $ids
  ) {
    id
  }
}
`;

export const loginAsMutation = `mutation loginAsTenant(
  $account_id: Int!
) {  
  loginAsTenant(      
    account_id: $account_id
  ) {
    id,
    user_id,
    expires_at,
    url,
    code
  }
}
`;

export const updateTenantPaymentSettingsMutation = `mutation updateTenantPaymentSettings(
  $account_id: Int!,
  $vaboo_premium_upgrade_price: Float,
) {  
  updateTenantPaymentSettings( 
    input: {      
      account_id: $account_id,
      vaboo_premium_upgrade_price: $vaboo_premium_upgrade_price
    }
  ) {
    id
    account_id
    vaboo_premium_upgrade_price
    description
  }
}
`;
