import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import { CINEMA_PRODUCT_MODEL_NAME } from "./../configs/constants";
import { updateCinemaProduct } from "./../graphql/mutations/benefitMutations";
import { cinemaProductDetails } from "./../graphql/queries/benefitQueries";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useBaseNavigation from "./../hooks/baseNavigation";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import useNotification from "./../hooks/notifications";
import { CancelButton, FormControls, SaveButton } from "./../mood-ui/Controls";
import {
  NumberField,
  ReadOnlyField,
  ReadOnlyFieldExt,
  SelectField,
  TextField,
} from "./../mood-ui/Fields";
import {
  BlockContainer,
  Col,
  FormContainer,
  FormData,
  PageContainer,
  Row,
} from "./../mood-ui/Layout";
import { ErrorNotification } from "./../mood-ui/Notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { Heading } from "./../mood-ui/Typography";

import RichTextEditor from "./../mood-ui/RichTextEditor";

const CINEMA_PRODUCT_DETAILS = gql(cinemaProductDetails);
const UPDATE_CINEMA_PRODUCT = gql(updateCinemaProduct);

const CinemaProduct = () => {
  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Mutation.ManageCinemaSocietyData);

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const { goBack } = useBaseNavigation(CINEMA_PRODUCT_MODEL_NAME);

  const { id } = useParams();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    title: "",
    sku: "",
    description: "",
    single_price: 0,
    discount_adjustment: 0,
    final_price: 0,
    rrp_price: 0,
    copy: "",
    terms: "",
    status: "",
    chain_name: "",
    venue_name: "",
    chain: null,
  });

  const { setFieldValue: handleFieldOnChangeProxied } = useBaseFieldSetters(
    formData,
    stateMethods,
    clearError
  );

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const { error, loading } = useQuery(CINEMA_PRODUCT_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.cinemaProduct) return false;

      setFormData({
        ...result.cinemaProduct,
      });
    },
  });

  const [updateProduct, { loading: isUpdating }] = useMutation(
    UPDATE_CINEMA_PRODUCT,
    {
      onCompleted: (result) => {
        goBack();
      },
      onError: (e) => {
        resolveMutationErrors(e);
      },
    }
  );

  const handleSave = () => {
    const data = {
      ...formData,
    };
    if (data.status === "Draft") {
      displayErrorNotification({
        heading: "Status error",
        message:
          "This is a new Cinema product that was recently pulled from Cinema Society API. You need to change its status to Disabled or Active to save it",
        secondsToDisplay: 5,
      });
      return false;
    }
    // if (
    //   parseFloat(data.discount_adjustment) + parseFloat(data.single_price) >
    //   parseFloat(data.rrp_price)
    // ) {
    //   displayErrorNotification({
    //     heading: "Pricing error",
    //     message:
    //       "Discount adjustment + single price cannot be greater than RRP price. Please adjust the discount to a lower value",
    //     secondsToDisplay: 5,
    //   });
    //   return false;
    // }

    updateProduct({
      variables: {
        id: data.id,
        title: data.title,
        description: data.description,
        discount_adjustment: parseFloat(data.discount_adjustment),
        copy: data.copy,
        terms: data.terms,
        status: data.status,
      },
    });
  };

  const [_, setDraft] = useState(false);

  if (error) return <div>Error...</div>;
  if (!formData || loading) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update Cinema Society Chain status"
              description=""
            />
          </Col>
        </Row>

        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading size="md" text="Visibility" description="" />
                    <p
                      className={
                        "mt-1 text-sm leading-5 text-gray-400 select-none"
                      }
                    >
                      - Draft. This product was pulled from the Chain Society
                      API and was not configured yet.
                      <br />
                      - Active. Product is configred and active - available for
                      users.
                      <br /> - Disabled. Product is disabled and not available
                      for users.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.status}
                      values={
                        formData.status === "Draft"
                          ? [
                              { id: "Draft", name: "Draft" },
                              { id: "Active", name: "Active" },
                              { id: "Disabled", name: "Disabled" },
                            ]
                          : [
                              { id: "Active", name: "Active" },
                              { id: "Disabled", name: "Disabled" },
                            ]
                      }
                      label="Status"
                      name="status"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      valueKey="id"
                      labelKey="name"
                      isRequired={true}
                      error={fieldError("status")}
                      description=""
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Pricing"
                      description="Setup discount adjustment"
                    />
                    {formData.rrp_price < formData.single_price && (
                      <p
                        className={
                          "mt-1 text-sm leading-5 text-red-400 select-none"
                        }
                      >
                        <strong>- Important:</strong> rrp_price for this product
                        is lower than single_price.
                      </p>
                    )}
                    {formData.rrp_price < formData.final_price && (
                      <p
                        className={
                          "mt-1 text-sm leading-5 text-red-400 select-none"
                        }
                      >
                        <strong>- Important:</strong> final_price for this
                        product is higher than rrp_price. It is recommended to
                        adjust the discount_adjustment to a lower value.
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <ReadOnlyField
                      name="single_price"
                      label="Price, £"
                      value={formData.single_price}
                      maxlength={96}
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <ReadOnlyField
                      name="rrp_price"
                      label="RRP Price, £"
                      value={formData.rrp_price}
                      maxlength={96}
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <ReadOnlyField
                      name="final_price"
                      label="Price for User, £"
                      value={formData.final_price}
                      maxlength={96}
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <ReadOnlyField
                      name="chain_discount_adjustment"
                      label="Chain adjustment, %"
                      value={formData.chain.discount_adjustment}
                      maxlength={96}
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <NumberField
                      step="0.1"
                      name="discount_adjustment"
                      label="Discount adjustment, £"
                      value={formData.discount_adjustment}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("discount_adjustment")}
                      isRequired={false}
                      maxlength={96}
                      description="How much to add to price"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Primary details"
                      description="Primay data about cinema product like title, description, terms and 'how to use'."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles="">
                    <ReadOnlyField
                      name="chain_name"
                      label="Chain"
                      value={formData.chain_name}
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <ReadOnlyField
                      name="venue_name"
                      label="Venue"
                      value={formData.venue_name}
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <TextField
                      name="title"
                      label="Title"
                      value={formData.title}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("title")}
                      isRequired={false}
                      maxlength={96}
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <ReadOnlyField
                      name="sku"
                      label="SKU"
                      value={formData.sku}
                      isRequired={false}
                      maxlength={96}
                    />
                  </Col>
                  <Col width={12} extraStyles="">
                    <RichTextEditor
                      cols={5}
                      rows={5}
                      name="description"
                      value={formData.description}
                      label="Description"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("description")}
                      isRequired={false}
                      description="Use (ctrl/cmd) + shift + V to paste text without breaking the formatting"
                    />
                  </Col>
                  <Col width={12} extraStyles="">
                    <RichTextEditor
                      cols={5}
                      rows={25}
                      name="copy"
                      value={formData.copy}
                      label="How to use"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("copy")}
                      isRequired={false}
                      description="Use (ctrl/cmd) + shift + V to paste text without breaking the formatting"
                    />
                  </Col>
                  <Col width={12} extraStyles="">
                    <RichTextEditor
                      cols={5}
                      rows={20}
                      name="terms"
                      value={formData.terms}
                      label="Terms"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("terms")}
                      isRequired={false}
                      description="Use (ctrl/cmd) + shift + V to paste text without breaking the formatting"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            {hasAccess(Permissions.Mutation.ManageCinemaSocietyData) && (
              <SaveButton
                onClick={handleSave}
                loading={isUpdating}
                disabled={formHasErrors()}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default CinemaProduct;
