import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allTilloPendingBrands } from "./../graphql/queries/benefitQueries";
import {
  approveTilloBrandMutation,
  rejectTilloBrandMutation,
} from "./../graphql/mutations/benefitMutations";
import { TILLO_OFFER_MODEL_NAME } from "./../configs/constants";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useNotification from "./../hooks/notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { Col, Row, PageContainer, BlockContainer } from "./../mood-ui/Layout";
import { ColorLabel, Heading } from "./../mood-ui/Typography";
import { ItemsNotFound } from "./../mood-ui/Typography";
import {
  ErrorNotification,
  SuccessNotification,
} from "./../mood-ui/Notifications";
import { CancelButton, InlineControls, SaveButton } from "../mood-ui/Controls";

const ALL_PENDING_BRANDS = gql(allTilloPendingBrands);
const APPROVE_BRAND = gql(approveTilloBrandMutation);
const REJECT_BRAND = gql(rejectTilloBrandMutation);

export const renderObject = (obj) => {
  if (typeof obj === "undefined") return <span></span>;
  if (obj === null) return <span></span>;
  let keys = Object.keys(obj);
  keys = keys.filter((key) => key !== "__typename");
  const fieldValues = keys.map((key) => `${key}: ${obj[key]}`);
  return fieldValues.join(", ");
};

export const renderObjectImages = (obj) => {
  if (typeof obj === "undefined") return <span></span>;
  if (obj === null) return <span></span>;
  const keys = Object.keys(obj);
  return keys.map((key) => (
    <React.Fragment key={key}>
      <ImageLink field={key} imgSrc={obj[key]} />
      <br />
    </React.Fragment>
  ));
};

export const renderBoolean = (v) => {
  if (typeof v === "undefined") return <span></span>;
  if (v === null) return <span></span>;
  return v === true ? "true" : "false";
};

export const renderList = (v) => {
  if (typeof v === "undefined") return <span></span>;
  if (v === null) return <span></span>;
  return v.join(", ");
};

export const renderLink = (v) => {
  if (typeof v === "undefined") return <span></span>;
  if (v === null) return <span></span>;
  return (
    <a href={v} target="_blank" rel="noreferrer">
      {v}
    </a>
  );
};

export const renderOrderStatus = (v) => {
  if (typeof v === "undefined") return <span></span>;
  switch (v) {
    case "Resolved":
      return <ColorLabel text={v} color="green" />;
    case "Rejected":
      return <ColorLabel text={v} color="red" />;
    case "Canceled":
      return <ColorLabel text={v} color="red" />;
    case "Registered":
      return <ColorLabel text={v} color="gray" />;
    case "Prepared":
      return <ColorLabel text={v} color="blue" />;
    case "Pending":
      return <ColorLabel text={v} color="blue" />;
    default:
      return <ColorLabel text={v} color="gray" />;
  }
};

export const renderOrderPaymentStatus = (v) => {
  if (typeof v === "undefined") return <span></span>;
  switch (v) {
    case "Paid":
      return <ColorLabel text={v} color="blue" />;
    case "Payment Confirmed":
      return <ColorLabel text={v} color="green" />;
    case "Failed":
      return <ColorLabel text={v} color="red" />;
    default:
      return <ColorLabel text={v} color="gray" />;
  }
};

export const renderSaving = (v) => {
  if (typeof v === "undefined") return <span></span>;
  if (v > 0) return <ColorLabel text={v} color="blue" />;
  if (v < 0) return <ColorLabel text={v} color="red" />;
  return v;
};

export const renderOrderState = (v) => {
  if (typeof v === "undefined") return <span></span>;
  switch (v) {
    case "Resolved":
      return <ColorLabel text={v} color="green" />;
    case "Rejected by Provider":
      return <ColorLabel text={v} color="red" />;
    case "Failed":
      return <ColorLabel text={v} color="red" />;
    case "Issued":
      return <ColorLabel text={v} color="blue" />;
    case "Unpaid":
      return <ColorLabel text={v} color="purple" />;
    case "Paid":
      return <ColorLabel text={v} color="indigo" />;
    case "Expired":
      return <ColorLabel text={v} color="yellow" />;
    default:
      return <ColorLabel text={v} color="gray" />;
  }
};

export const renderVoucher = (v) => {
  if (
    typeof v === "undefined" ||
    typeof v.code === "undefined" ||
    typeof v.expires_at === "undefined"
  )
    return <span></span>;
  return (
    <span>
      Code: {v.code}
      <br />
      Expires At: {v.expires_at}
    </span>
  );
};

const TilloBrandListApprovalPage = () => {
  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Query.TilloBrands);

  const [brands, setBrands] = useState([]);

  const history = useHistory();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { loading, refetch } = useQuery(ALL_PENDING_BRANDS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setBrands(data.tilloPendingBrands);
      if (data.tilloPendingBrands.length === 0)
        history.push(`/${TILLO_OFFER_MODEL_NAME}/1/25/0/0/0`);
    },
  });

  const [approveBrand, { loading: isApproving }] = useMutation(APPROVE_BRAND, {
    onCompleted: () => {
      refetch();
      displayNotification({
        heading: "Brand Approval",
        message: `Successfully approved brand`,
        secondsToDisplay: 3,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Brand Approval",
        message: `Failed to approve brand. Most likely server error`,
        secondsToDisplay: 5,
      });
    },
  });

  const [rejectBrand, { loading: isRejecting }] = useMutation(REJECT_BRAND, {
    onCompleted: () => {
      refetch();
      displayNotification({
        heading: "Brand Rejection",
        message: `Successfully rejected brand`,
        secondsToDisplay: 3,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Brand Rejection",
        message: `Failed to reject brand. Most likely server error`,
        secondsToDisplay: 4,
      });
    },
  });

  const handleApprove = (slug) => approveBrand({ variables: { slug } });

  const handleReject = (slug) => rejectBrand({ variables: { slug } });

  if (!brands) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Pending Tillo brands"
              description="A list of brands added via Tillo that have not been approved
                          to use as reward cards. Once you approve a brand, it will be made
                          available to update and publish in the reward cards list."
            />
          </Col>
        </Row>
        {loading && !brands.length && <PageSpinner inline />}
        {brands.length > 0 &&
          brands.map((brand) => (
            <TilloBrandInfo
              key={brand.slug}
              brand={brand}
              onApprove={
                hasAccess(Permissions.Mutation.ApproveTilloBrand)
                  ? handleApprove
                  : false
              }
              onReject={
                hasAccess(Permissions.Mutation.RejectTilloBrand)
                  ? handleReject
                  : false
              }
              isRejecting={isRejecting}
              isApproving={isApproving}
            />
          ))}
        {!loading && brands && brands.length === 0 && (
          <ItemsNotFound text="There are no pending Tillo brands" />
        )}
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default TilloBrandListApprovalPage;

export const TilloBrandInfo = ({
  brand,
  onApprove = false,
  onReject = false,
  isRejecting = false,
  isApproving = false,
}) => {
  const [
    data,
    // setData
  ] = useState(JSON.parse(brand.serialized_data));

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-7">
      <div className="px-4 py-5 sm:px-6">
        <div className="float-right flex pb-4 pl-4">
          <img
            className={`h-[60px] inline border border-[#dfe2e8] select-none`}
            src={data.detail.assets.gift_card_url}
            alt="Logo"
            crossOrigin="anonymous"
          />
          <img
            className={`h-[60px] inline border border-[#dfe2e8] ml-2 select-none`}
            src={data.detail.assets.logo_url}
            alt="Logo"
            crossOrigin="anonymous"
          />
        </div>
        <h3 className="text-lg leading-6 text-gray-500">{data.name}</h3>
        <p className="mt-1 text-xs text-gray-400">{data.detail.description}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
          <FieldBlock label="Slug" value={data.slug} />
          <FieldBlock label="Slug" value={data.type} />
          <FieldBlock label="Status Code" value={data.status.code} />
          <FieldBlock label="Currency" value={data.currency} />
          <FieldBlock label="Discount" value={data.discount} />
          <FieldBlock
            label="Last Updated"
            value={data.last_updated}
            valueRenderFunc={(v) =>
              moment(data.last_updated).format("YYYY-MM-DD")
            }
          />
          <SpaceBlock width={2} />

          <FieldBlock
            label="Transaction Types"
            value={data.transaction_types}
            valueRenderFunc={renderList}
          />
          <FieldBlock
            label="Delivery Methods"
            value={data.delivery_methods}
            valueRenderFunc={renderList}
          />
          <FieldBlock
            label="Countries Served"
            value={data.countries_served}
            valueRenderFunc={renderList}
          />
          <FieldBlock
            label="Categories"
            value={data.categories}
            valueRenderFunc={renderList}
          />

          <FieldBlock
            label="GC Pool"
            value={data.gc_pool}
            valueRenderFunc={renderBoolean}
          />
          <FieldBlock
            label="Async Only"
            value={data.async_only}
            valueRenderFunc={renderBoolean}
          />
          <SpaceBlock width={2} />

          <FieldBlock
            label="Vat"
            value={data.vat}
            width={4}
            valueRenderFunc={renderObject}
          />

          <FieldBlock
            label="Digital Face Value Limits"
            value={data.digital_face_value_limits}
            width={4}
            valueRenderFunc={renderObject}
          />

          <FieldBlock
            label="Digital General Restrictions"
            value={data.detail.digital_general_restrictions}
          />
          <FieldBlock
            label="Online Order Restrictions"
            value={data.detail.online_order_restrictions}
          />
          <FieldBlock
            label="In-store Order Restrictions"
            value={data.detail.instore_order_restrictions}
          />

          <FieldBlock
            label="Assets"
            value={data.detail.assets}
            width={4}
            valueRenderFunc={renderObjectImages}
          />

          <FieldBlock
            label="Description"
            width={4}
            value={data.detail.description}
          />

          <FieldBlock
            label="Balance Enquiry Url"
            value={data.detail.balance_enquiry_url}
            valueRenderFunc={renderLink}
          />
          <FieldBlock label="Barcode" value={data.detail.barcode} />
          <FieldBlock label="Expiry" value={data.detail.expiry} />
          <FieldBlock
            label="Website Url"
            value={data.detail.website_url}
            valueRenderFunc={renderLink}
          />

          <FieldBlock
            label="Redemption Instructions Url"
            value={data.detail.redemption_instructions_url}
            valueRenderFunc={renderLink}
          />
          <FieldBlock
            label="Redemption Methods"
            value={data.detail.redemption_methods}
            valueRenderFunc={renderList}
          />
          <FieldBlock
            label="Terms And Conditions Copy"
            value={data.detail.terms_and_conditions_copy}
          />
          <FieldBlock
            label="Terms And Conditions Url"
            value={data.detail.terms_and_conditions_url}
            valueRenderFunc={renderLink}
          />

          <FieldBlock
            label="Denominations"
            value={data.denominations}
            valueRenderFunc={renderList}
          />
          <FieldBlock
            label="Digital Denominations"
            value={data.digital_denominations}
            valueRenderFunc={renderList}
          />
        </dl>
        <InlineControls>
          {onReject && (
            <CancelButton
              label="Reject"
              onClick={() => onReject(data.slug)}
              loading={isRejecting}
            />
          )}
          {onApprove && (
            <SaveButton
              label="Approve"
              onClick={() => onApprove(data.slug)}
              loading={isApproving}
            />
          )}
        </InlineControls>
      </div>
    </div>
  );
};

export const FieldBlock = ({
  label,
  value,
  width = 1,
  valueRenderFunc = false,
}) => {
  return (
    <div className={`sm:col-span-${width}`}>
      <dt className="text-sm text-gray-400">{label}</dt>
      {value !== undefined && (
        // {typeof value !== undefined && (
        <dd className="mt-1 text-xs text-gray-500">
          {valueRenderFunc && valueRenderFunc(value)}
          {!valueRenderFunc && value}
        </dd>
      )}
    </div>
  );
};

export const SpaceBlock = ({ width = 1 }) => (
  <div className={`sm:col-span-${width}`}></div>
);

export const ImageLink = ({ field, imgSrc }) => (
  <React.Fragment>
    {field}:{" "}
    <a href={imgSrc} target="_blank" rel="noreferrer">
      {imgSrc}
    </a>
  </React.Fragment>
);
