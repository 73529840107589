import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allCinemaChains } from "./../graphql/queries/benefitQueries";
import {
  enableChainsMutation,
  disableChainsMutation,
} from "../graphql/mutations/benefitMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import { CINEMA_CHAIN_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import {
  ListItemCheckbox,
  PageControls,
  TEditButton,
} from "./../mood-ui/Controls";
import { ColorLabel, Heading } from "./../mood-ui/Typography";
import Search from "./../mood-ui/Search";
import { ItemsNotFound } from "./../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  Pagination,
} from "./../mood-ui/DataTable";
import useListSelection from "../hooks/rowSelection";
import { TableSelectedActions } from "../moose-ui/DataTable";
import ChainListActions from "../components/list-secondary-actions/benefits/cs/ChainListActions";
import useModal from "../hooks/modal";
import useNotification from "../hooks/notifications";
import { ConfirmActionModal } from "../mood-ui/Modals";
import {
  ErrorNotification,
  SuccessNotification,
} from "../mood-ui/Notifications";

const ALL_CINEMA_CHAINS = gql(allCinemaChains);
const ENABLE_CHAINS = gql(enableChainsMutation);
const DISABLE_CHAINS = gql(disableChainsMutation);

const CinemaChainList = ({ entityDetails: { offers, cards, merchants } }) => {
  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Mutation.ManageCinemaSocietyData);

  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    isAllRowsSelected,
    toggleAllRows,
    clearSelection,
  } = useListSelection();

  const { page, limit, orderBy, orderHow, search } = useParams();

  const { search: queryString } = useLocation();

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { data, loading, error, refetch } = useQuery(ALL_CINEMA_CHAINS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { nextPage, prevPage, editForm, applySearch } = useBaseNavigation(
    CINEMA_CHAIN_MODEL_NAME
  );

  const [enableChains, { loading: enableChainsInProgress }] = useMutation(
    ENABLE_CHAINS,
    {
      onCompleted: (_) => {
        refetch();
        displayNotification({
          heading: "Chains Enabled",
          message: "Successfully enabled (activated) selected chains",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to enable (activate) selected chains",
          secondsToDisplay: 2,
        });
      },
    }
  );

  const [disableChains, { loading: disableChainsInProgress }] = useMutation(
    DISABLE_CHAINS,
    {
      onCompleted: (_) => {
        refetch();
        displayNotification({
          heading: "Chains Disabled",
          message: "Successfully disabled (deactivated) selected chains",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to disable (deactivate) selected chains",
          secondsToDisplay: 2,
        });
      },
    }
  );

  const confirmEnable = () => {
    showModal({
      heading: "Confirm Enable (Activation)",
      message: "Are you sure you want to Enable/Activate selected chains?",
      mood: "Success",
      confirmButtonLabel: "Enable",
      onConfirm: () => {
        closeModal();
        handleEnableChains();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleEnableChains = () => {
    enableChains({ variables: { ids: selectedRows } });
  };

  const confirmDisable = () => {
    showModal({
      heading: "Confirm Disable (Deactivation)",
      message: "Are you sure you want to Disable/Deactivate selected chains?",
      mood: "Warning",
      confirmButtonLabel: "Disable",
      onConfirm: () => {
        closeModal();
        handleDisableChains();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDisableChains = () => {
    disableChains({ variables: { ids: selectedRows } });
  };

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-5 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Cinema Chains"
              description="Control Cinema Society Chains."
            />
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {/* {hasAccess(Permissions.Mutation.CreateSection) && (
              <AddButton
                label="Refresh list of chains from provider - Cinema Society API"
                loading={loading}
              />
            )} */}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        <TableContainer tweaks="px-5 pb-5">
          <TableView>
            <THeading>
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() =>
                      toggleAllRows(data?.cinemaChains?.map((c) => c.id))
                    }
                    isChecked={
                      isAllRowsSelected(data?.cinemaChains?.map((c) => c.id))
                        ? true
                        : false
                    }
                  />
                </THCol>
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="title" value="Name" />
                <THCol
                  tweaks="text-left"
                  name="discount_adjustment"
                  value="Discount adjustment, %"
                />
                <THCol tweaks="text-left" name="status" value="Status" />
                <THCol tweaks="w-16 text-center" />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.cinemaChains && data.cinemaChains.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <ItemsNotFound text="No Cinema chains found" tweaks="" />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.cinemaChains && data.cinemaChains.length > 0 && (
              <TContent>
                {data.cinemaChains.map((c, rowIndex) => (
                  <TRow key={c.id}>
                    <TCol>
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(c.id)}
                        isChecked={rowSelected(c.id) ? true : false}
                      />
                    </TCol>
                    <TCol value={c.id} />
                    <TCol tweaks="max-w-[100px]">
                      <abbr title={c.name}>{c.name}</abbr>
                    </TCol>
                    <TCol tweaks="max-w-[180px]">
                      <abbr title={c.discount_adjustment}>
                        {c.discount_adjustment}
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[180px]">
                      {c.status == "Active" && (
                        <ColorLabel text="Active" color="emerald" />
                      )}
                      {c.status == "Disabled" && (
                        <ColorLabel text="Disabled" color="yellow" />
                      )}
                      {c.status == "Draft" && (
                        <ColorLabel text="Draft" color="gray" />
                      )}
                    </TCol>
                    <TCol tweaks="w-16 text-center">
                      {hasAccess(Permissions.Query.Section) && (
                        <TEditButton onClick={() => editForm(c.id)} />
                      )}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <ChainListActions
                      onClearSelection={clearSelection}
                      onEnable={confirmEnable}
                      onDisable={confirmDisable}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={7} extraStyles=""></Col>
              <Col width={3}>
                <Pagination
                  onPrev={page > 1 ? prevPage : false}
                  onNext={
                    data?.cinemaChains?.length >= limit - 1 ? nextPage : false
                  }
                />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default CinemaChainList;
