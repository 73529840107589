import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allClients } from "../graphql/queries/landlordQueries";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "../hooks/baseNavigation";
import useListSelection from "../hooks/rowSelection";
import useModal from "../hooks/modal";
import { CLIENT_MODEL_NAME } from "../configs/constants";
import { 
  clientTableFields, 
  clientTableDefaultCols, 
  clientFieldGroups, 
} from "../configs/clientConfig";
import { ConfirmActionModal } from "../mood-ui/Modals";
import PageSpinner from "../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "../mood-ui/Layout";
import { PageControls } from "../mood-ui/Controls";
import { ItemsNotFound } from "../mood-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "../mood-ui/Notifications";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TContent,
  // TableSelectedActions,
  PaginationNew as Pagination,
} from "../mood-ui/DataTable";
import useNotification from "../hooks/notifications";

import AdvancedTableInterface from "../mood-ui/tables/AdvancedTableInterface";
import ListCell from "../mood-ui/tables/ListCell";
import ListHeaderCell from "../mood-ui/tables/ListHeaderCell";

const ALL_CLIENTS = gql(allClients);

const tableConfig        = clientTableFields()
const visibleColsDefault = clientTableDefaultCols()

const ClientList = () => {
  const { 
    protectPage, 
    // hasAccess, 
  } = usePermissions();

  protectPage(Permissions.Query.Clients);

  const {
    nextPage,
    prevPage,
    editForm,
    applySearch,
    applyFilters,
    resetFilters,
    applySorting,
    getCurrentUrl,
    goTo, 
    setPageNum, 
  } = useBaseNavigation(CLIENT_MODEL_NAME, { showingItemsCount: 50 });

  const [ typeFilters, setTypeFilters ] = useState([])

  const [ activeListId, setActiveListId ] = useState(null)
  
  const [ visibleCols, setVisibleCols ] = useState(null) 
  
  const [ activeListCols, setActiveListCols ] = useState(null)

  const [ showSummaries, setShowSummaries ] = useState(false)

  const resetDefaultVisibleCols = () => { setVisibleCols(visibleColsDefault) }

  useEffect(() => {
    var storedVisibleCols = null
    if (activeListId !== null) {
      storedVisibleCols = window.localStorage.getItem(`CLIENT_VISIBLE_COLS_DL_${ activeListId }`); 
      setActiveListCols(activeListId)
    }
    if (storedVisibleCols !== null && storedVisibleCols !== 'undefined') {
      setVisibleCols(JSON.parse(storedVisibleCols))
    }
    else if (activeListId === null) {
      storedVisibleCols = window.localStorage.getItem('CLIENT_VISIBLE_COLS'); 
      if (storedVisibleCols !== null && storedVisibleCols !== 'undefined') {
        setVisibleCols(JSON.parse(storedVisibleCols))
        setActiveListCols(null)
      }
      else {
        resetDefaultVisibleCols()
        setActiveListCols(null)
      }
    }
  }, [])

  useEffect(() => { 
    if (activeListId !== null) {
      if (activeListCols !== activeListId) {
        var storedVisibleCols = window.localStorage.getItem(`CLIENT_VISIBLE_COLS_DL_${ activeListId }`); 
        setActiveListCols(activeListId)
        if (storedVisibleCols !== null && storedVisibleCols !== 'undefined') { setVisibleCols(JSON.parse(storedVisibleCols)) }
        else { window.localStorage.setItem(`CLIENT_VISIBLE_COLS_DL_${ activeListId }`, JSON.stringify(visibleCols)) }
      }
      else { window.localStorage.setItem(`CLIENT_VISIBLE_COLS_DL_${ activeListId }`, JSON.stringify(visibleCols)) }
    }
    else {
      if (activeListCols !== null || visibleCols === null) {
        var storedVisibleCols = window.localStorage.getItem('CLIENT_VISIBLE_COLS'); 
        if (storedVisibleCols !== null && storedVisibleCols !== 'undefined') { 
          setVisibleCols(JSON.parse(storedVisibleCols)) 
        }
        else { resetDefaultVisibleCols() }
        setActiveListCols(null)
      }
      else { window.localStorage.setItem('CLIENT_VISIBLE_COLS', JSON.stringify(visibleCols)) }
    }
  }, [ visibleCols?.length, activeListId ])

  const [ isFocus, setFocus ] = useState() 

  useEffect(() => {
    const storedFocus = window.localStorage.getItem('CLIENT_FOCUS'); 
    let newFocus = [ -1, -1 ]
    if (storedFocus !== null && storedFocus!== 'undefined') {
      const tempFocus = JSON.parse(storedFocus)
      if (tempFocus?.focus?.length === 2
        && tempFocus.url === getCurrentUrl().url 
          && (getCurrentUrl().queryString !== '' 
            || getCurrentUrl().orderBy !== '0' 
            || getCurrentUrl().search !== '0' 
            || getCurrentUrl().page !== '1')
      ) {
        newFocus = tempFocus.focus
      } 
    } 
    setFocus(newFocus)
  }, [])

  useEffect(() => { 
    if (isFocus?.length === 2 && (isFocus[0] !== -1 && isFocus[1] !== -1) ) {
      window.localStorage.setItem('CLIENT_FOCUS', JSON.stringify({ focus: isFocus, url: getCurrentUrl().url })); 
    }
    else {
      window.localStorage.removeItem('CLIENT_FOCUS')
    }
  }, [isFocus?.length, isFocus ])

  const {
    // selectedRows,
    isRowSelected: rowSelected,
    // toggleRow: toggleRowSelection,
    // clearSelection,
    // isAllRowsSelected,
    // toggleAllRows,
  } = useListSelection();

  const { page, limit, orderBy, orderHow, search } = useParams();

  const { search: queryString } = useLocation();

  const { 
    modalState, 
    // showModal, 
    // closeModal,  
  } = useModal();

  const { 
    data, 
    loading, 
    error 
  } = useQuery(ALL_CLIENTS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    notify: displayNotification, 
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  // const confirmDeletion = () => {
  //   showModal({
  //     heading: "Confirm Deletion",
  //     message:
  //       "Are you sure you want to DELETE selected client? Do not proceed if you are not sure.",
  //     mood: "Danger",
  //     confirmButtonLabel: "Delete",
  //     onConfirm: () => {
  //       closeModal();
  //     },
  //     onCancel: () => {
  //       closeModal();
  //     },
  //   });
  // };

  if (error) return <div>Error</div>;

  const getColScope = (name) => {
    return typeFilters?.length > 0 
      ? (typeFilters.filter(f => f.queryKey === name && f.type !== 'multiple')[0]?.values || null)
      : null
  }

  const emitTypeFilters = (filters) => {
    if (filters.length > 0 && typeFilters.length < 1) {
      setTypeFilters(filters)
    }
  }

  const emitActiveListId = (listId = null) => {
    setActiveListId((listId !== null && listId !== false) ? listId : null)
  }

  const toggleSummaries = () => {
    setShowSummaries(!showSummaries)
  }

  return (
    <PageContainer>
      <BlockContainer raised>

        <Row tweaks="pb-6 mx-5 gap-2 sticky left-[20px] z-50 relative max-w-full max-w-[1800px]s mx-autos">
          <Col width={12} extraStyles="relative">
            <AdvancedTableInterface
              columns={tableConfig}
              selectedColumns={visibleCols}
              setVisibleCols={setVisibleCols}
              resetDefaultVisibleCols={resetDefaultVisibleCols}
              applyFilters={applyFilters}
              resetFilters={resetFilters}
              queryString={queryString}
              getCurrentUrl={getCurrentUrl}
              goTo={goTo}
              displayNotification={displayNotification} 
              displayErrorNotification={displayErrorNotification}
              emitTypeFilters={emitTypeFilters}
              emitActiveListId={emitActiveListId}
              applySearch={applySearch} 
              fieldGroups={clientFieldGroups} 
              hasNoResults={
                !data?.paginatedLandlords || 
                (data?.paginatedLandlords 
                  && data.paginatedLandlords.landlords 
                  && data.paginatedLandlords.landlords.length === 0
                )
              }
              showSummaries={showSummaries || false} 
              toggleSummaries={toggleSummaries} 
            />
          </Col>
        </Row>
            
        <TableContainer tweaks="px-5 pb-3 z-1 border-collapse" > 
          <TableView stretch={false} tweaks={(!data || (data && data.paginatedLandlords.landlords && data.paginatedLandlords.landlords.length === 0)) ? 'border-[#ebedf2]' : ''}>
            <THeading tweaks={(!data || (data && data.paginatedLandlords.landlords && data.paginatedLandlords.landlords.length === 0)) ? 'opacity-30 border-y' : ''}>
              <TRow>
                {/* <THCol topSticky="-40px" tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() => toggleAllRows(data?.landlords?.map(landlord => landlord.id))}
                    isChecked={isAllRowsSelected(data?.landlords?.map(landlord => landlord.id)) ? true : false}
                  />
                </THCol> */}
                {visibleCols?.length > 0 && tableConfig
                  .filter(col => { return visibleCols.includes(col.name) && col.in_table})
                  .map(thc => (
                    <ListHeaderCell 
                      key={thc.name} 
                      {...thc} 
                      summary={thc.table_summary ? (data?.paginatedLandlords?.summary[thc.name] || false) : false}
                      value={thc.title || ''}
                      tweaks={ ` max-w-[${ thc.cellWidth }px] min-w-[${ thc.cellWidth }px] ` + thc.thClass }
                      onSort={ thc.sort && applySorting } 
                      sortBy={ thc.sort && orderBy } 
                      sortHow={ thc.sort && orderHow } 
                      showSummaries={showSummaries} 
                    />
                  )
                )}
              </TRow>
            </THeading>

            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <div className="sticky left-[20px] w-[calc(100vw-247px)]">
                      <PageSpinner inline />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}

            {data && data.paginatedLandlords.landlords && data.paginatedLandlords.landlords.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <div className="sticky left-[20px] w-[calc(100vw-247px)]">
                      <ItemsNotFound text="No clients found" tweaks="" />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}

            {visibleCols?.length > 0 && data && data.paginatedLandlords.landlords && data.paginatedLandlords.landlords.length > 0 && (
              
              <TContent>
                { data.paginatedLandlords.landlords
                  .map((row, rowIndex) => (
                    <TRow 
                      key={row.id} 
                      isSelected={rowSelected(row.id)} 
                      cellFocus={ isFocus }
                      tweaks="last:border-b-[#ecdaf5] last:border-b-2 text-[10.5px]" 
                      isFocusRow={isFocus[1] == rowIndex} 
                      onFocus={e => {setFocus([ isFocus[0], rowIndex ]); }}
                      onTilt={(dir, e) => { setFocus([ isFocus[0], Math.min(isFocus[1] + dir, data?.paginatedLandlords?.landlords?.length - 1) || 0]); }}
                      onPan={(dir, e) => { setFocus([ Math.min(isFocus[0] + dir, visibleCols?.length - 2), isFocus[1] || 0]); }}
                      panBaseline={2}
                      onClick={() => editForm(row.id)}
                    >
                      {/* <TCol centerContent={true}>
                        <ListItemCheckbox
                        onChange={() => toggleRowSelection(row.id)}
                        isChecked={rowSelected(row.id) ? true : false}
                        />
                      </TCol> */}
                      { tableConfig
                        .filter(cell => { return visibleCols.includes(cell.name) && cell.in_table})
                        .map((cell, cellIndex) => (
                          <ListCell 
                            key={row.id + '-' + cell.name} 
                            onClick={e => {
                              if (isFocus[0] == cellIndex && isFocus[1] == rowIndex) { setFocus([-1, -1]) }
                              else if(cell.click == true){ setFocus([ cellIndex, rowIndex ]) }
                            }}
                            canClick={cell.click}
                            cellFocus={ isFocus }
                            isFocusCol={isFocus[0] == cellIndex}
                            isFocusRow={isFocus[1] == rowIndex}
                            isFocusCell={isFocus[0] == cellIndex && isFocus[1] == rowIndex}
                            cell={cell}
                            row={row}
                            rowSelected={rowSelected}
                            onView={() => editForm(row.id)}
                            value={row[cell.name]}
                            colScope={getColScope(cell.name)}
                          />
                        )) 
                      }
                    </TRow>
                  )
                )}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5"> 
              <Col width={2}>
                {/* <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <CustomerListActions
                      onClearSelection={clearSelection}
                      onActivate={confirmActivation}
                      onDeactivate={confirmDeactivation}
                      onDelete={confirmDeletion}
                    />
                  )}
                </TableSelectedActions> */}
              </Col>
              <Col width={5} extraStyles=""></Col>
              <Col width={5}>
                <Pagination 
                  onPrev={prevPage || false} 
                  onNext={nextPage || false} 
                  onSetPageNum={setPageNum || false} 
                  next_page={data?.paginatedLandlords?.pagination?.next_page || false} 
                  prev_page={data?.paginatedLandlords?.pagination?.prev_page || false}
                  items_total={data?.paginatedLandlords?.pagination?.items_total || 0} 
                  page={page}
                  limit={limit}
                  on_screen_items={data?.paginatedLandlords?.pagination?.on_screen_items || 0}
                  loading={loading}
                />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};
 
export default ClientList;
