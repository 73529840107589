export const platformDetails = `query platform($ownerId: Int!, $labelId: Int!) {  
    platform(ownerId: $ownerId, labelId: $labelId) {
      id
      platform_name
      subdomain
      featured_offer
      barrier_survey_enabled
      frequency_survey_enabled
      section_behavior
      section_ids
      primary_colour
      secondary_colour
      button_colour
      button_text_colour      
      bg_color         
      logo_src
      login_bg_src
      promo_page_title
      promo_page_description
      promo_page_contact_email
      footer_message
      show_activation_status
      show_mortgage_answers
      show_rating_answers
      show_access_type
      allow_csv_export
    }
  }
  `;
