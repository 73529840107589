import { APP_DEFAULT_LIST_PARAMS } from "./../configs/constants";
import { useHistory } from "react-router-dom";

export default function useLinker() {
  const history = useHistory();
  const createListUrl = (path) => {
    return `/${path}/${APP_DEFAULT_LIST_PARAMS}`;
  };

  const goTo = (path) => {
    return history.push(path);
  };

  return { createListUrl, goTo };
}
