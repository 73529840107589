import { useState, useEffect } from "react";

const useItemCounter = (obj, options = { searchKey: "_noKey_" }) => {
  const [itemsTotal, setItemsTotal] = useState(0);
  useEffect(() => {
    if (
      typeof obj !== "undefined" &&
      typeof obj[options.searchKey] !== "undefined" &&
      typeof obj[options.searchKey].length !== "undefined"
    )
      setItemsTotal(obj[options.searchKey].length);
    else setItemsTotal(0);
  }, [obj, options.searchKey]);
  return { itemsTotal };
};

export default useItemCounter;
