import React from "react";
import { useLocation } from "react-router-dom";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import PageContainer from "../vaboo-ui/PageContainer";

const Settings = (props) => {
  const { hasAccess } = usePermissions();
  const location = useLocation();
  const rootUrl = `/settings`;

  document.title =
    `${rootUrl}` == location.pathname
      ? "Settings | Platforms"
      : `${rootUrl}/membership-pricing-setup` == location.pathname
      ? "Settings | Membership"
      : `${rootUrl}/surveys` == location.pathname
      ? "Settings | Surveys"
      : location.pathname.indexOf("profile-qa-setup") > -1
      ? "Settings | Profiles"
      : location.pathname.indexOf("control-application-users") > -1
      ? "Settings | Vaboo team"
      : location.pathname.indexOf("roles") > -1
      ? "Settings | Team roles"
      : "Settings";

  const page = {
    title: "Global",
    icon: "globe",
    nav: [],
  };

  if (hasAccess(Permissions.Query.MainAppSettings))
    page.nav.push({
      name: "Platforms",
      url: `${rootUrl}`,
      isActive: `${rootUrl}` == location.pathname ? true : false,
      icon: "home",
    });

  if (hasAccess(Permissions.Query.ClientPaymentSettings))
    page.nav.push({
      name: "Membership",
      url: `${rootUrl}/membership-pricing-setup`,
      isActive: `${rootUrl}` == location.pathname ? true : false,
      icon: "creditCard",
    });

  if (hasAccess(Permissions.Query.SurveyAndGlobalQuestions))
    page.nav.push({
      name: "Surveys",
      url: `${rootUrl}/surveys`,
      isActive: `${rootUrl}/surveys` == location.pathname ? true : false,
      icon: "chatAlt",
    });

  if (hasAccess(Permissions.Query.ProfileQASetup))
    page.nav.push({
      name: "Profiles",
      url: `${rootUrl}/profile-qa-setup`,
      isActive:
        location.pathname.indexOf("profile-qa-setup") > -1 ? true : false,
      icon: "database",
    });

  if (hasAccess(Permissions.Query.SystemUsers))
    page.nav.push({
      name: "Vaboo team",
      url: `${rootUrl}/control-application-users/1/50/0/0/0`,
      isActive:
        location.pathname.indexOf("control-application-users") > -1
          ? true
          : false,
      icon: "userGroup",
    });

  if (hasAccess(Permissions.Query.Roles))
    page.nav.push({
      name: "Team roles",
      url: `${rootUrl}/roles/1/50/0/0/0`,
      isActive: location.pathname.indexOf("roles") > -1 ? true : false,
      icon: "locker",
    });

  return <PageContainer page={page} {...props} />;
};

export default Settings;
