import React from "react";
import Icons from "./configs/icons";

const NavIcon = ({ name, tweaks = '' }) => {
  if (name && Icons[name]) {
    return (
      <svg
        className={"mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" + tweaks}
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d={Icons[name]}
        />
      </svg>
    );
  }
  else if (name.split('-')[0] == 'mdi') {
    return (
      <span className={`
        mdi ${ name } 
        mr-3 h-6 w-6 
        text-gray-400 text-[17px]
        translate-y-[-2px]
        group-hover:text-gray-500 group-focus:text-gray-600 
        transition ease-in-out duration-150
        ${ tweaks }
      `}></span>
    )
  }
  return <div></div>;
};

export default NavIcon;