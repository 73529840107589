export const publishQuestionsMutation = `mutation publishQuestions(
    $ids: [Int!]!
  ) {  
    publishQuestions(      
      ids: $ids
    ) {
      id
      title
      type
      status
    }
  }
  `;

export const createQuestionMutation = `mutation createQuestion(
    $title: String, 
    $admin_title: String, 
    $is_survey_type: Boolean, 
    $is_profile_type: Boolean, 
    $type: String, 
    $answers: String,
    $minimum: Int,
    $maximum: Int,
    $step: Int,
    $default: Int,
  ) {  
    createQuestion(        
      title: $title, 
      admin_title: $admin_title,
      is_survey_type: $is_survey_type,
      is_profile_type: $is_profile_type,
      type: $type,
      answers: $answers    
      minimum: $minimum,
      maximum: $maximum,
      step: $step,
      default: $default,
  ) {
    id
  }
}
`;

export const updateQASettingsMutation = `mutation updateQASettings(
  $owner_id: Int, 
  $label_id: Int, 
  $infinityQuestionList: String, 
  $activation_survey_id: Int,
  $monthly_competition_survey_id: Int,
  $barrier_survey_id: Int,
  $barrier_survey_is_active: Boolean,
  $frequency_survey_id: Int,
  $frequency_days: Int    
) {  
  updateQASettings(        
    owner_id: $owner_id, 
    label_id: $label_id, 
    infinityQuestionList: $infinityQuestionList,
    activation_survey_id: $activation_survey_id,
    monthly_competition_survey_id: $monthly_competition_survey_id,
    barrier_survey_id: $barrier_survey_id,
    barrier_survey_is_active: $barrier_survey_is_active,
    frequency_survey_id: $frequency_survey_id,
    frequency_days: $frequency_days
) 
}
`;

export const updateQuestionMutation = `mutation updateQuestion(
    $id: ID!, 
    $title: String, 
    $is_survey_type: Boolean,
    $is_profile_type: Boolean,
    $admin_title: String, 
    $type: String, 
    $answers: String,
    $minimum: Int,
    $maximum: Int,
    $step: Int,
    $default: Int,
  ) {  
  updateQuestion(        
    id: $id, 
    admin_title: $admin_title,
    is_survey_type: $is_survey_type,
    is_profile_type: $is_profile_type,
    title: $title, 
    type: $type,
    minimum: $minimum,
    maximum: $maximum,
    step: $step,
    default: $default,
    answers: $answers      
  ) {
    id
  }
}
`;

export const publishSurveysMutation = `mutation publishSurveys(
  $ids: [Int!]!
) {  
  publishSurveys(      
    ids: $ids
  ) {
    id
    title
    description
    status
    question_ids
  }
}
`;

export const createSurveyMutation = `mutation createSurvey($title: String, $reusable: Boolean,  $description: String, $question_ids: String, $survey_type_id: Int) { createSurvey(title: $title, reusable: $reusable, description: $description, question_ids: $question_ids, survey_type_id: $survey_type_id) { id } }`;

export const updateSurveyMutation = `mutation updateSurvey(
  $id: ID!, 
  $title: String, 
  $reusable: Boolean,  
  $description: String, 
  $question_ids: String,
  $survey_type_id: Int
) {  
  updateSurvey(        
  id: $id, 
  title: $title, 
  reusable: $reusable,
  description: $description,
  question_ids: $question_ids,
  survey_type_id: $survey_type_id      
) {
  id
}
}
`;
