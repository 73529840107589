import React, { useState } from "react";
import FilterValue from "./FilterValue";

export const extractDates = (value) => {
  if (typeof value[0] == "undefined") return [false, false];
  const dates = value[0].split("-");
  if (!dates || dates.length != 2) return [false, false];
  let year = dates[0].substring(0, 4);
  let month = dates[0].substring(4, 6);
  let day = dates[0].substring(6, 8);
  const from = `${year}-${month}-${day}`;
  year = dates[1].substring(0, 4);
  month = dates[1].substring(4, 6);
  day = dates[1].substring(6, 8);
  const to = `${year}-${month}-${day}`;
  return [from, to];
};

export const composeDate = (value) => {
  const dateParts = value.split("-");
  return `${dateParts[0]}${dateParts[1]}${dateParts[2]}`;
};

const DatesFilter = ({
  options,
  selectedValues,
  onUpdateDatesFilter: onUpdateFilter,
}) => {
  const { queryKey } = options;
  const [from, to] = extractDates(selectedValues);
  const fromDisplayValue = from ? from : options.from;
  const toDisplayValue = to ? to : options.to;
  const handleFromDateChange = (v) => {
    const dates = `${composeDate(
      v.length > 0 ? v : options.from
    )}-${composeDate(toDisplayValue)}`;
    onUpdateFilter(queryKey, dates);
  };
  const handleToDateChange = (v) => {
    const dates = `${composeDate(fromDisplayValue)}-${composeDate(
      v.length > 0 ? v : options.to
    )}`;
    onUpdateFilter(queryKey, dates);
  };

  return (
    <React.Fragment>
      <div className="relative inline-block text-left pb-1 pr-1 pl-1">
        <div className="flex">
          <div className="flex-col flex-col mr-2">
            {/*shadow-sm was here*/}
            <div className="max-w-lg flex rounded-md">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                From
              </span>
              <input
                type="date"
                className="inline-flex justify-center w-full rounded-none rounded-r-md border border-gray-300 px-3 py-1 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
                value={fromDisplayValue}
                onChange={(e) => handleFromDateChange(e.target.value)}
              />
            </div>
          </div>

          <div className="flex-col flex-col mr-2">
            {/*shadow-sm was here*/}
            <div className="max-w-lg flex rounded-md">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                To
              </span>
              <input
                type="date"
                className="inline-flex justify-center w-full rounded-none rounded-r-md border border-gray-300 px-3 py-1 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
                value={toDisplayValue}
                onChange={(e) => handleToDateChange(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatesFilter;
