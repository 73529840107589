import React, { Children, cloneElement, isValidElement } from "react";
import { Link } from "react-router-dom";
import NavIcon from "./NavIcon";

const SecondaryNav = ({ page, entityDetails = null, navHeader = null, navAction = null }) => {
  navHeader = navHeader ? [ navHeader ] : null
  navAction  = navAction ?  [ navAction ]  : null
  return (
    <div className="hidden md:flex md:flex-shrink-0 select-none relative">
      <div className="flex flex-col w-[160px] border-r border-gray-100 bg-white">
          { 
            navHeader
            ? navHeader.map((item, index) => { return { ...item, key: `nav-content-${ index }` } })
            : <div className="text-gray-700 p-3 pb-2 border-b border-gray-100 flex w-full">
                <NavIcon name={page.icon} />
                { page.title }
              </div>
          }
        <div className="h-0 flex-1 flex flex-col pb-4 overflow-y-auto">
          <nav className="flex-1 bg-white flex flex-col w-full items-start">
            {page.nav && page.nav.map((item, key) => (
              <Link
              to={item.url}
              className={
                item.isActive
                ? "w-full group flex border-r-2 border-purple-300 items-center py-1 px-4 text-sm leading-5 text-gray-700 bg-gray-50 hover:text-gray-900 hover:bg-gray-100 focus:outline-none transition ease-in-out duration-150"
                : "w-full group flex border-r-2 border-transparent items-center py-1 px-4 text-sm leading-5 text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus:outline-none transition ease-in-out duration-150"
              }
              key={key}
              >
                <NavIcon name={item.icon} tweaks="h-[17px] w-[17px] mr-[10px]" />
                {item.name}
                {
                  entityDetails?.has_overdue_unpaid_invoice && (item.notice === 'has_overdue_unpaid_invoice')
                  && <span className="mdi mdi-alert text-orange-500 ml-auto mr-[-4px]"></span>
                }
                {
                  entityDetails?.payment_system && (item.notice === 'payment_system')
                  &&  
                  <img 
                    src={ entityDetails?.payment_system === 'Stripe'   ? '/images/stripe.png' 
                      : entityDetails?.payment_system === 'GoCardless' ? '/images/gocardless.png' 
                      : '/images/false-mark.png' } 
                    alt="Method" 
                    className="h-[16px] ml-auto mr-[-5px]"
                  />
                }
              </Link>
            ))}
          </nav>
        </div>
        <div className="flex w-full">
          { 
            navAction && navAction.map((item, index) => { return { ...item, key: `nav-action-${ index }` } })
          }
        </div>
      </div>
    </div>
  );
};

const PageContainer = ({
  page,
  entityDetails = null, 
  navHeader = null, 
  navAction = null, 
  children, 
}) => {
  return (
    <div className="top-14 bottom-0 left-0 right-0 absolute flex bg-white max-h-[100vh]">
      <SecondaryNav page={page} entityDetails={entityDetails} navAction={navAction} navHeader={navHeader} />
      <div className="flex flex-col w-0 flex-1 relative" >
        {
          Children.map(children, (child) => {
            if (!isValidElement(child)) return null
            return cloneElement(child, {
              ...child.props, 
              entityDetails: entityDetails, 
            })
          })
        }
      </div>
    </div>
  );
};

export default PageContainer;
