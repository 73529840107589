import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { surveyDetails } from "./../graphql/queries/vabooSurveyQueries";
import { updateSurveyMutation } from "./../graphql/mutations/vabooSurveyMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import { SURVEY_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../moose-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "./../moose-ui/Controls";
import { Heading, BoxedMessage, InfoMessage } from "./../moose-ui/Typography";
import {
  TextField,
  SelectField,
  ToggleCheckboxField,
  RichTextEditorField,
  ReadOnlyField,
} from "./../moose-ui/Fields";
import useDataProvider from "./../hooks/dataProvider";
const SURVEY_DETAILS = gql(surveyDetails);
const UPDATE_SURVEY = gql(updateSurveyMutation);

const Survey = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.Survey);
  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const { goBack } = useBaseNavigation(SURVEY_MODEL_NAME);
  const { id } = useParams();
  const [surveyTypes] = useDataProvider("surveyTypes");
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    title: "",
    reusable: false,
    survey_type_id: 0,
    description: "",
    question_ids: "",
  });

  const {
    setFieldValue: handleFieldOnChange,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const { data, loading, error } = useQuery(SURVEY_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.survey) return false;
      const questionIds = result.survey.question_ids.join(", ");
      setFormData({ ...result.survey, question_ids: questionIds });
    },
    onError: (e) => {
      console.log("e", e);
    },
  });

  const [
    updateSurvey,
    { data: updateResult, loading: isUpdating, error: updateError },
  ] = useMutation(UPDATE_SURVEY, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    updateSurvey({ variables: data });
  };

  const isPublished = formData && formData.status == "Published" ? true : false;

  if (error) return <div>Error...</div>;
  if (!formData || loading) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="Survey" />
        <FormContainer>
          <FormData>
            {isPublished && (
              <Row>
                <Col width={12}>
                  <InfoMessage text="This survey is published. Changes are not allowed." />
                </Col>
              </Row>
            )}
            <Row>
              <Col width={6}>
                {isPublished && (
                  <ReadOnlyField
                    value={formData.title}
                    label="Survey"
                    isRequired={true}
                  />
                )}
                {!isPublished && (
                  <TextField
                    name="title"
                    label="Survey"
                    value={formData.title}
                    onChange={handleFieldOnChange}
                    error={fieldError("title")}
                    isRequired={true}
                  />
                )}
              </Col>
              <Col width={6}>
                {isPublished && (
                  <ReadOnlyField
                    value={formData.question_ids}
                    label="Question IDs"
                    isRequired={true}
                  />
                )}
                {!isPublished && (
                  <TextField
                    name="question_ids"
                    label="Question IDs"
                    description="Comma-separated list of question identifiers; wildcard to use next not answered question from the infinite list. Example: 1, 2, *, 50"
                    value={formData.question_ids}
                    onChange={handleFieldOnChange}
                    error={fieldError("question_ids")}
                    isRequired={true}
                  />
                )}
              </Col>
            </Row>
            <Row>
              {!isPublished && (
                <Col width={3}>
                  <SelectField
                    value={formData.survey_type_id}
                    values={surveyTypes}
                    label="Survey Type"
                    name="survey_type_id"
                    onChange={handleFieldOnChange}
                    valueKey="id"
                    labelKey="name"
                    error={fieldError("survey_type_id")}
                    prependOption="Select Type"
                    isRequired={true}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col width={12}>
                {isPublished && (
                  <ReadOnlyField
                    value={formData.description}
                    label="Description"
                    isRequired={true}
                  />
                )}
                {!isPublished && (
                  <RichTextEditorField
                    name="description"
                    label="Description"
                    value={formData.description}
                    onChange={handleFieldOnChange}
                    error={fieldError("description")}
                    rows={5}
                    isRequired={true}
                  />
                )}
              </Col>
            </Row>
          </FormData>
          {!isPublished && (
            <FormControls>
              <CancelButton onClick={goBack} />
              {hasAccess(Permissions.Mutation.UpdateSurvey) && (
                <SaveButton onClick={handleSave} />
              )}
            </FormControls>
          )}
          {isPublished && (
            <FormControls>
              <CancelButton onClick={goBack} label="Go back" />
            </FormControls>
          )}
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default Survey;
