import React from "react";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import VabooUserPassword from "./VabooUserPassword";

const LandlordPassword = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.UpdateClientPassword);
  return <VabooUserPassword />;
};

export default LandlordPassword;
