export const truncateNumber = (num) => {
  let newNum = '--'
  let numUnit = ''
  let newNumLong = '--'
  if (
   num !== null 
   && num !== false 
   && num !== undefined 
   && num !== ''
   && (typeof parseInt(num) == 'number')
  ) { 
    // num = 365
    // num = 55555555
    if (num < 1) {
      newNum = parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      if (newNum.charAt(newNum.length - 1) == '0') {
        newNum = parseFloat(num).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        if (newNum.charAt(newNum.length - 1) == '0') {
          newNum = newNum.split('.')[0]
        }
      }
      newNumLong = newNum
    }
    else if (num < 10) {
      newNum = parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      if (newNum.charAt(newNum.length - 1) == '0') {
        newNum = parseFloat(num).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        if (newNum.charAt(newNum.length - 1) == '0') {
          newNum = newNum.split('.')[0]
        }
      }
      newNumLong = newNum
    }
    else if (num < 100) {
      newNum = parseFloat(num).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      newNum = (newNum.charAt(newNum.length - 1) == '0' ? newNum.split('.')[0] : newNum)
      newNumLong = newNum
    }
    else if (num < 1000) {
      newNum = parseFloat(num).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      newNumLong = parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
    }
    else if (num < 99999) {
      newNum = parseFloat(num / 1000).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,') //.split('.')[0]
      newNum = (newNum.charAt(newNum.length - 1) == '0' ? newNum.split('.')[0] : newNum)
      newNumLong = parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      numUnit = 'k'
    }
    else if (num < 999999) {
      newNum = parseFloat(num / 1000).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      newNumLong = parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      numUnit = 'k'
    }
    else if (num < 100000000) {
      newNum = parseFloat(num / 1000000).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      newNum = (newNum.charAt(newNum.length - 1) == '0' ? newNum.split('.')[0] : newNum)
      newNumLong = parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      numUnit = 'm'
    }
    else if (num < 1000000000) {
      newNum = parseFloat(num / 1000000).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      newNumLong = parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      numUnit = 'm'
    }
    else if (num < 10000000000) {
      newNum = parseFloat(num / 100000000).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      newNumLong = parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      numUnit = 'b'
    }
    else if (num < 100000000000) {
      newNum = parseFloat(num / 1000000000).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      newNumLong = parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
      numUnit = 'b'
    }
  }
  return [ newNum, newNumLong, numUnit ]
}

export const commaInt = (num = null) => {
  if (num === null || num == '') return '--'
  return parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
}

export const commaFloat = (num = null) => {
  if (num === null || num == '') return '--'
  return parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const activationColor = (percent) => {
  return percent <= 20 ? 'rose'
  : percent <= 40 ? 'orange'
  : percent <= 60 ? 'yellow'
  : percent <= 80 ? 'cyan' 
  : percent <= 100 ? 'emerald'
  : 'emerald'
}

export const npsColor = (nps) => {
  return nps <= -30 ? 'rose' : nps <= 30 ? 'yellow' : 'emerald'
}

export const clone = (el) => {
  return JSON.parse(JSON.stringify(el));
}

export const getSumCount = (arr) => {
  return arr.reduce((acc, item) => {
    return acc += (parseFloat(item) > 0 || parseFloat(item) < 0 ? parseFloat(item) : 0)
  }, 0)
}

export const getMean = (arr)=> arr.reduce((acc, val) => acc + val, 0) / arr.length;

export const standardDeviation = (arr, usePopulation = false) => {
  const filteredArr = arr.filter(v => v)
  const mean = getMean(filteredArr)
  return Math.sqrt(
    arr.reduce((acc, val) => acc.concat((val - mean) ** 2), []).reduce((acc, val) => acc + val, 0) /
      (arr.length - (usePopulation ? 0 : 1))
  );
};

export const getDistPercent = (arr) => {
  return ((standardDeviation(arr) / getSumCount(arr)) * -100) + 100
  // return (((standardDeviation(arr) / getSumCount(arr)) * -100) || -100) + 100
}