export const authUserPermisions = `query authUserPermissions {  
    authUserPermissions {
      id
      code
      description
      created_at
    } 
  }
  `;

export const allPermissionGroups = `query permissionGroups {  
    permissionGroups {
      id
      name
      permissions {
        id        
        code 
        description
      }
    }
  }
  `;

export const allRoles = `query roles($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    roles(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      name
      permissions {
        id
        code
        description
      }
    }
  }
  `;

export const roleDetails = `query role($id: ID!) {
    role(id: $id) {
      id
      name
      permissions {
        id
        code
        description
      }
    }
  }
  `;
 
export const allSystemUsers = `query systemUsers($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    systemUsers(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      name
      email
      roles {
        id
        name
      }
    }
  }
  `;



export const systemUserDetails = `query systemUser($id: ID!) {
    systemUser(id: $id) {
      id
      name
      email
      roles {
        id
        name
      }
    }
  }
  `;

export const otherSystemUsersBriefly = `query otherSystemUsersBriefly {
  otherSystemUsersBriefly {
      id
      name
      email
    }
  }
  `;