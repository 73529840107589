import React, { useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { bulkOperationDetails } from "./../graphql/queries/landlordQueries";
import { startBulkOperationMutation } from "./../graphql/mutations/landlordMutations";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../moose-ui/Layout";

const BULK_OPERATION_DETAILS = gql(bulkOperationDetails);
const START_BULK_OPERATION = gql(startBulkOperationMutation);

const BulkOperationProgressModal = ({
  importId,
  isVisible,
  onComplete = () => { },
  onError = () => { },
}) => {
  const [startWatching, { called, loading, data }] = useLazyQuery(
    BULK_OPERATION_DETAILS,
    {
      variables: {
        id: importId,
      },
      pollInterval: 500,
      fetchPolicy: "network",
      onCompleted: (result) => { },
      onError: (e) => { },
    }
  );

  const [
    startBulkOperation,
    { data: importResult, loading: isImporting, error: importingError },
  ] = useMutation(START_BULK_OPERATION, {
    variables: { id: importId },
    onCompleted: (result) => {
      onComplete();
    },
    onError: (e) => {
      alert("Error while importing. Contact system admin.");
    },
  });

  useEffect(() => {
    if (importId > 0) {
      startWatching();
      startBulkOperation();
    }
  }, [importId]);

  const visibilityStyle = isVisible ? "" : "hidden";
  const overlayIndex = isVisible ? 100 : -10000;

  return (
    <div
      className={`${visibilityStyle} fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center`}
      style={{ zIndex: overlayIndex }}
    >
      <div className={`${visibilityStyle} fixed inset-0 transition-opacity`}>
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        className={`${visibilityStyle} bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6`}
      >
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              User Import Progress {importId}
            </h3>
            {data && data.bulkOperation && (
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Total: {data.bulkOperation.total}
                    </td>
                    <td>Processed: {data.bulkOperation.processed}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Applied: {data.bulkOperation.applied}
                    </td>
                    <td>Unresolved: {data.bulkOperation.unresolved}</td>
                  </tr>
                  <tr>
                    <td>Progress: {data.bulkOperation.progress}%</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkOperationProgressModal;
