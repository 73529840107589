export const updateProfileSettingsMutation = `mutation updateProfileSettings(
    $account_id: Int!,
    $label_id: Int!,
    $is_enabled: Boolean!,
    $profile_field_settings: [ProfileFieldSettings],
  ) {  
    updateProfileSettings(      
      account_id: $account_id,
      label_id: $label_id,
      is_enabled: $is_enabled,
      profile_field_settings: $profile_field_settings
    ) {      
        is_enabled      
    }
  }
  `;
