import React, { useState } from "react";
import Controls from "./Controls";

const DateRangeFilter = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  onApplyRange: applyRange,
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [isVisible, setVisibility] = useState(true);

  const handleApplyRange = () => {
    applyRange({ startDate: [startDate], endDate: [endDate] });
  };

  return (
    <div className="flex">
      <div className="flex-col mr-2">
        <strong>Start Date:</strong>
      </div>
      <div className="flex-col flex-col mr-2">
        <input
          type="date"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-1 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </div>
      <div className="flex-col flex-col mr-2">
        <strong>End Date:</strong>
      </div>
      <div className="flex-col flex-col mr-2">
        <input
          type="date"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-1 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <div className="flex-col flex-col mr-2">
        <Controls showApplyButton={true} onApplyFilters={handleApplyRange} />
      </div>
    </div>
  );
};

export default DateRangeFilter;
