import React, { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useParams, useLocation } from "react-router-dom";
import { client } from "../graphql/queries/landlordQueries";
import gql from "graphql-tag";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import { CLIENT_MODEL_NAME } from "../configs/constants";
import PageContainer from "../vaboo-ui/PageContainer";

import { loginAsMutation } from "../graphql/mutations/landlordMutations";

import useNotification from "../hooks/notifications";

import { BasicControl } from "../mood-ui/Controls";

import { truncateNumber, npsColor } from "../hooks/utils";

const ENTITY_DETAIL = gql(client);

const LOGIN_AS = gql(loginAsMutation);

const Client = (props) => {
  const { protectPage, hasAccess } = usePermissions();

  const { id } = useParams();

  const location = useLocation();

  const rootUrl = `/${CLIENT_MODEL_NAME}/${id}`;

  const [entityDetails, setEntityDetails] = useState(null);

  const title =
    `${rootUrl}` === location.pathname
      ? "Overview"
      : `${rootUrl}/company` === location.pathname
      ? "Company"
      : `${rootUrl}/password` === location.pathname
      ? "Password"
      : `${rootUrl}/payment-settings` === location.pathname
      ? "Billing"
      : location.pathname.indexOf("invoices") > -1
      ? "Invoices"
      : `${rootUrl}/platform` === location.pathname
      ? "Platform"
      : `${rootUrl}/emails` === location.pathname
      ? "Emails"
      : location.pathname.indexOf("custom-links") > -1
      ? "Custom Links"
      : location.pathname.indexOf("custom-links") > -1
      ? "Direct Register"
      : `${rootUrl}/direct-register-links` === location.pathname
      ? "Content"
      : `${rootUrl}/surveys` === location.pathname
      ? "Surveys"
      : `${rootUrl}/profile-qa-setup` === location.pathname
      ? "Profiles"
      : location.pathname.indexOf("imports") > -1
      ? "Imports"
      : location.pathname.indexOf("bulk-operations") > -1
      ? "Operations"
      : location.pathname.indexOf("logins") > -1
      ? "Logins"
      : `${rootUrl}/api` === location.pathname
      ? "API"
      : "Client";

  document.title = title;

  const page = {
    type: "client",
    title: "Client",
    icon: "client",
    nav: [],
    id: id,
  };

  const [getDetails, { loading }] = useLazyQuery(ENTITY_DETAIL, {
    skip: entityDetails !== null,
    variables: { id: id },
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      setEntityDetails(d.landlordView);
    },
  });

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const [loginAs, { loading: loginAsPending }] = useMutation(LOGIN_AS, {
    onCompleted: (result) => {
      window.open(result.loginAsLandlord.url, "_blank");
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to create System Client Login Data",
        secondsToDisplay: 3,
      });
    },
    update: (cache, { data }) => {
      return false;
    },
  });

  const handleLoginAs = () => {
    loginAs({ variables: { account_id: id } });
  };

  if (!loading && ENTITY_DETAIL && id && entityDetails == null) {
    getDetails();
  } else if (entityDetails?.subdomain) {
    document.title = title + " | " + (entityDetails?.subdomain || "");
  }

  page.nav = [
    {
      name: "Company",
      url: `${rootUrl}/company`,
      isActive: `${rootUrl}/company` == location.pathname ? true : false,
      icon: "home",
    },
  ];

  if (hasAccess(Permissions.Query.PrimaryKPIReport)) {
    page.nav.unshift({
      name: "Overview",
      url: `${rootUrl}`,
      isActive: `${rootUrl}` === location.pathname ? true : false,
      icon: "chart",
    });

    // if (hasAccess(Permissions.Query.PrimaryKPIReport))
    page.nav.push({
      name: "Service",
      url: `${rootUrl}/service`,
      isActive: `${rootUrl}/service` === location.pathname ? true : false,
      icon: "mdi-draw-pen",
      // icon: "sign",
    });
  }

  if (hasAccess(Permissions.Query.ClientPaymentSettings))
    page.nav.push({
      name: "Billing",
      url: `${rootUrl}/payment-settings`,
      isActive:
        `${rootUrl}/payment-settings` === location.pathname ? true : false,
      icon: "creditCard",
      notice: "payment_system",
    });

  if (hasAccess(Permissions.Query.ClientInvoices))
    page.nav.push({
      name: "Invoices",
      url: `${rootUrl}/invoices/1/50/0/0/0`,
      isActive: location.pathname.indexOf("invoices") > -1 ? true : false,
      icon: "wallet",
      notice: "has_overdue_unpaid_invoice",
    });

  if (hasAccess(Permissions.Query.ClientPlatform))
    page.nav.push({
      name: "Platform",
      url: `${rootUrl}/platform`,
      isActive: `${rootUrl}/platform` === location.pathname ? true : false,
      icon: "paint",
    });

  if (hasAccess(Permissions.Query.ClientEmailSettings))
    page.nav.push({
      name: "Emails",
      url: `${rootUrl}/emails`,
      isActive: `${rootUrl}/emails` === location.pathname ? true : false,
      icon: "mail",
    });

  if (hasAccess(Permissions.Query.ClientCustomLinks))
    page.nav.push({
      name: "Custom links",
      url: `${rootUrl}/custom-links/1/50/0/0/0`,
      isActive: location.pathname.indexOf("custom-links") > -1 ? true : false,
      icon: "link",
    });

  if (hasAccess(Permissions.Mutation.UpdateClientOffers))
    page.nav.push({
      name: "Content",
      url: `${rootUrl}/offers`,
      isActive: `${rootUrl}/offers` === location.pathname ? true : false,
      icon: "content",
    });

  if (hasAccess(Permissions.Query.ClientSurveys))
    page.nav.push({
      name: "Surveys",
      url: `${rootUrl}/surveys`,
      isActive: `${rootUrl}/surveys` === location.pathname ? true : false,
      icon: "smile",
    });

  if (hasAccess(Permissions.Query.ClientProfileQASetup))
    page.nav.push({
      name: "Profiles",
      url: `${rootUrl}/profile-qa-setup`,
      isActive:
        `${rootUrl}/profile-qa-setup` === location.pathname ? true : false,
      icon: "mdi-target-account",
    });

  if (hasAccess(Permissions.Query.ClientCSVImports))
    page.nav.push({
      name: "Imports",
      url: `${rootUrl}/imports/1/50/0/0/0`,
      isActive: location.pathname.indexOf("imports") > -1 ? true : false,
      icon: "cloudUpload",
    });

  if (hasAccess(Permissions.Query.ClientBulkOperations))
    page.nav.push({
      name: "Operations",
      url: `${rootUrl}/bulk-operations/1/50/0/0/0`,
      isActive:
        location.pathname.indexOf("bulk-operations") > -1 ? true : false,
      icon: "cycle",
    });

  if (hasAccess(Permissions.Query.Logins))
    page.nav.push({
      name: "Logins",
      url: `${rootUrl}/logins/1/200/0/0/0`,
      isActive: location.pathname.indexOf("logins") > -1 ? true : false,
      icon: "cursor",
    });

  if (hasAccess(Permissions.Mutation.UpdateClientPassword))
    page.nav.push({
      name: "Password",
      url: `${rootUrl}/password`,
      isActive: `${rootUrl}/password` === location.pathname ? true : false,
      icon: "locker",
    });

  if (hasAccess(Permissions.Query.ClientAPI))
    page.nav.push({
      name: "API",
      url: `${rootUrl}/api`,
      isActive: `${rootUrl}/api` === location.pathname ? true : false,
      icon: "code",
    });

  if (hasAccess(Permissions.Mutation.ManageDirectRegisterLinks))
    page.nav.push({
      name: "Direct Register",
      url: `${rootUrl}/direct-register-links/1/50/0/0/0`,
      isActive:
        location.pathname.indexOf("direct-register-links") > -1 ? true : false,
      icon: "link",
    });

  if (hasAccess(Permissions.Mutation.UpdateExtensions))
    page.nav.push({
      name: "Extensions",
      url: `${rootUrl}/extensions`,
      isActive:
        location.pathname.indexOf("direct-register-links") > -1 ? true : false,
      icon: "link",
    });

  if (hasAccess(Permissions.Query.ClientPaymentSettings))
    page.nav.push({
      name: "Membership",
      url: `${rootUrl}/membership-pricing-setup`,
      isActive:
        location.pathname.indexOf("membership-pricing-setup") > -1
          ? true
          : false,
      icon: "creditCard",
    });

  const navHeader =
    !loading && ENTITY_DETAIL && id && entityDetails !== null ? (
      <div className="text-gray-700 border-b border-gray-200 flex w-full z-[999]">
        <div
          className={`pointer-events-none text-gray-700 py-3 px-4  border-b border-gray-200 justify-center mt-[-56px] absolute z-[99999] h-[57px] bg-[${
            entityDetails?.primary_colour || ""
          }] items-center flex w-full`}
        >
          <img
            src={entityDetails?.platform_logo_url || ""}
            alt=""
            className="max-h-full max-w-full"
          />
        </div>
        <div
          className={`text-gray-700 pl-1 pr-2 pt-2 pb-1 justify-center h-[80px] flex-wrap flex w-full text-sm`}
        >
          <abbr
            title={entityDetails?.account_name}
            className="whitespace-nowrap truncate min-w-full text-center pb-0.5 px-1"
          >
            {entityDetails?.account_name}
          </abbr>
          <div className="px-0.5 text-gray-500 text-xs">
            <abbr
              title={`Users: ${
                truncateNumber(entityDetails?.users_total)[1] || 0
              }`}
            >
              <span className="pr-px mdi mdi-account"></span>
              {truncateNumber(entityDetails?.users_total)[0] +
                truncateNumber(entityDetails?.users_total)[2] || 0}
            </abbr>
          </div>
          <div className="px-1 text-gray-500 text-xs">
            <abbr
              title={`Active: ${
                truncateNumber(entityDetails?.activation_rate)[1] || 0
              } %`}
            >
              <span className="pr-px mdi mdi-account-check"></span>
              {truncateNumber(entityDetails?.activation_rate)[0] +
                truncateNumber(entityDetails?.activation_rate)[2] || 0}
              %
            </abbr>
          </div>
          {entityDetails?.net_promoted_score !== null && (
            <div className="px-0.5 text-gray-500 text-xs">
              <abbr
                title={`NPS: ${
                  truncateNumber(entityDetails?.net_promoted_score)[1] || 0
                }`}
              >
                <span
                  className={`pr-px mdi mdi-star text-${npsColor(
                    entityDetails?.net_promoted_score || 0
                  )}-500`}
                ></span>
                {truncateNumber(entityDetails?.net_promoted_score)[0] +
                  truncateNumber(entityDetails?.net_promoted_score)[2] || 0}
              </abbr>
            </div>
          )}
          <abbr
            title={entityDetails?.subdomain}
            className="whitespace-nowrap truncate min-w-full text-center px-1 text-xs text-slate-400 "
          >
            <a
              href={`https://${entityDetails?.subdomain}.vaboo.co.uk`}
              target="_BLANK"
              className="underline underline-offset-1"
            >
              {`${entityDetails?.subdomain}.vaboo.co.uk`}
            </a>
          </abbr>
        </div>
      </div>
    ) : null;

  const navAction = hasAccess(Permissions.Mutation.LoginAsClient) ? (
    <BasicControl
      block
      onClick={handleLoginAs}
      label="Login as client"
      loading={loginAsPending}
      tweaks={`
            bg-[#592373] 
            text-white
            border-none 
            opacity-90
            hover:opacity-80
            hover:text-white
            active:opacity-100
            active:text-white
            active:bg-[#592373] 
            select-none
          `}
    />
  ) : null;

  return (
    <PageContainer
      page={page}
      entityDetails={entityDetails}
      {...props}
      navHeader={navHeader}
      navAction={navAction}
    />
  );
};

export default Client;
