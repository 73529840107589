import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import useNotification from "../../hooks/notifications";
import { DIRECT_REGISTER_LINK_TYPE_MODEL_NAME } from "./../../configs/constants";
import { updateDirectRegisterLinkMutation } from "./../../graphql/mutations/landlordMutations";
import { directRegisterLinkDetails } from "./../../graphql/queries/landlordQueries";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useFormErrors from "./../../hooks/formErrors";
import useHyperState from "./../../hooks/hyperState";
import {
  CancelButton,
  FormControls,
  SaveButton,
} from "./../../mood-ui/Controls";
import {
  NumberField,
  SelectField,
  TextField,
  ToggleCheckboxField,
} from "./../../mood-ui/Fields";
import {
  BlockContainer,
  Col,
  FormContainer,
  FormData,
  PageContainer,
  Row,
} from "./../../mood-ui/Layout";
import { Heading } from "./../../mood-ui/Typography";
import { LabelContext } from "../../contexts/LabelContext";

const DIRECT_REGISTER_LINK = gql(directRegisterLinkDetails);
const UPDATE_DIRECT_REGISTER_LINK = gql(updateDirectRegisterLinkMutation);

const LandlordDirectRegisterLink = () => {
  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();
  const { id: landlordId, drlId: id } = useParams();

  const { goBack } = useBaseNavigation(DIRECT_REGISTER_LINK_TYPE_MODEL_NAME, {
    urlContext: `clients/${landlordId}`,
  });

  const labelContext = useContext(LabelContext);

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
    syncView,
  } = useHyperState("formData", {
    client_id: landlordId,
    is_enabled: false,
    register_code: "",
    max_users_per_register_link: 0,
    register_access_type: "Basic Access",
    register_label_id: 3,
    enable_label_select_by_user: false,
    labels: [],
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    syncView();
    handleFieldOnChangeProxied(e);
  };

  const { notify: displayErrorNotification } = useNotification();

  useQuery(DIRECT_REGISTER_LINK, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.directRegisterLink) return false;
      const labels = result.directRegisterLink.labels.map((l) => l.id);
      setFormData({
        ...result.directRegisterLink,
        labels,
      });
    },
    onError: (e) => {
      console.log("e", e);
    },
  });

  const [updateDirectRegisterLink, { loading: isUpdating }] = useMutation(
    UPDATE_DIRECT_REGISTER_LINK,
    {
      variables: { ...formData },
      onCompleted: (result) => {
        goBack();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to update client api",
          secondsToDisplay: 2,
        });
        resolveMutationErrors(e);
      },
    }
  );

  const handleLabelCheck = (e) => {
    const { name, value } = e.target;
    let newLabels = [];
    if (formData.labels.indexOf(value) > -1)
      newLabels = formData.labels.filter((l) => l != value);
    else {
      newLabels = [...formData.labels];
      newLabels.push(value);
    }

    handleFieldOnChange({
      target: {
        name: "labels",
        value: newLabels,
      },
    });
    syncView();
  };

  const handleSave = () => {
    const data = { ...formData };
    updateDirectRegisterLink({ variables: data });
  };

  const [isDraft, setDraft] = useState(false);

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update  direct register link"
              description=""
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Direct Register Link"
                      description="Setup how Direct Register Links will work: max user registrations per link, default acces type and label."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-y-3">
                  <Col width={4} extraStyles="">
                    <ToggleCheckboxField
                      id="is_enabled"
                      name="is_enabled"
                      label="Enable Link"
                      description="Allow register using this Direct Link"
                      isChecked={formData?.is_enabled}
                      error={fieldError("is_enabled")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleToggleCheckbox(e);
                      }}
                    />
                  </Col>
                  <Col width={8} extraStyles="">
                    <TextField
                      loading={false}
                      name="register_code"
                      label="Register Code"
                      value={formData?.register_code}
                      onChange={handleFieldOnChange}
                      error={fieldError("register_code")}
                      description="A unique and complex register code that unlocks the direct link registration for the future users of the client. Should be in the format XXXX-XXXX-XXXX-XXXX, where is X is a letter or a number."
                      isRequired={true}
                      pattern="^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <NumberField
                      min={0}
                      loading={false}
                      name="max_users_per_register_link"
                      label="Max Registrations per Link"
                      value={formData?.max_users_per_register_link}
                      onChange={handleFieldOnChange}
                      error={fieldError("max_users_per_register_link")}
                      description="How many users can register using the same link"
                      isRequired={true}
                    />
                  </Col>
                  <Col width={4} extraStyles={"px-0"}>
                    <SelectField
                      value={formData?.register_access_type}
                      values={[
                        { id: "Basic Access", name: "Basic Access" },
                        { id: "Premium Access", name: "Premium Access" },
                      ]}
                      label="Use Access Type"
                      name="register_access_type"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      error={fieldError("register_access_type")}
                      isRequired={true}
                      description="What access type to use for the users that register using the direct link"
                    />
                  </Col>
                  <Col width={4} extraStyles={"px-0"}>
                    <SelectField
                      value={formData?.register_label_id}
                      values={[
                        { id: 1, name: "landlord" },
                        { id: 2, name: "staff" },
                        { id: 3, name: "tenant" },
                        { id: 4, name: "vendor" },
                        { id: 5, name: "buyer" },
                        { id: 6, name: "student" },
                        { id: 7, name: "home_owner" },
                        { id: 8, name: "other" },
                      ]}
                      label="Use Label"
                      name="register_label_id"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      error={fieldError("register_label_id")}
                      isRequired={true}
                      description="What label should be assigned to user when they register using the direct link"
                    />
                  </Col>

                  <Col width={4} extraStyles="">
                    <ToggleCheckboxField
                      id="enable_label_select_by_user"
                      name="enable_label_select_by_user"
                      label="User can select label"
                      description="Allow user to select label when registering using the direct link"
                      isChecked={formData?.enable_label_select_by_user}
                      error={fieldError("enable_label_select_by_user")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleToggleCheckbox(e);
                      }}
                    />
                  </Col>
                  <Col width={8} extraStyles=""></Col>
                  {formData?.enable_label_select_by_user == 1 && (
                    <Col width={12}>
                      <Row>
                        {labelContext.labels.map((label) => (
                          <Col
                            key={label.id}
                            extraStyles="capitalize"
                            width={3}
                          >
                            <ToggleCheckboxField
                              id={label.id}
                              name="labels"
                              label={label.name}
                              isChecked={
                                formData?.labels.indexOf(label.id) > -1
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                setDraft(true);
                                handleLabelCheck(e);
                              }}
                              flush
                            />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} loading={isUpdating} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default LandlordDirectRegisterLink;
