import React, { useState } from "react";
import DateFilter from "./inputs/DateFilter";
import RangeFilter from "./inputs/RangeFilter";
import MultipleFilter from "./inputs/MultipleFilter";

const FilterItemRouter = ({ 
  options = [], 
  selectedValues = [], 
  onUpdateFilter = () => {}, 
  onUpdateDatesFilter = () => {}, 
  onRemoveDatesFilter = () => {}, 
  onUpdateRangeFilter = () => {}, 
  onRemoveRangeFilter = () => {}, 
  onClearFilter = () => {},
  onSelectManyFilter = () => {}, 
}) => { 

  const { 
    type, 
    title, 
    queryKey, 
    values, 
  } = options;
  
  const toggleFilterValue = (id) => {
    onUpdateFilter(queryKey, id);
  };

  const updateDatesFilter = (value) => {
    onUpdateDatesFilter(queryKey, value);
  };

  const removeDatesFilter = (value) => {
    onRemoveDatesFilter(queryKey, value);
  };
  
  const updateRangeFilter = (value) => {
    onUpdateRangeFilter(queryKey, value);
  };
  
  const removeRangeFilter = (value) => {
    onRemoveRangeFilter(queryKey, value);
  };
  
  const clearFilter = () => {
    onClearFilter(queryKey)
  };
  
  const selectAllFilter = () => {
    const allOptions = options.values.map(options => options.uid)
    onSelectManyFilter(queryKey, allOptions)
  };

  return (

    <div className="h-[27px] w-[27px] text-left pr-0 group z-[9999]" >

      <button
        type="button" 
        className={`
          relative inline-flex justify-between select-none 
          w-full h-[27px] mt-[2px] px-2 py-[3px]s 
          text-xs border border-gray-200 focus:outline-none
          group/button
          bg-[#FCFCFE] border-gray-150 text-gray-500 text-opacity-[70%]
          shover:bg-[#ffffff] hover:border-gray-200 hover:text-opacity-[80%]
          group-hover:bg-[#ffffff] group-hover:border-[#DAC8E3] group-hover:text-opacity-[80%]
          group-focus-within:bg-[#ffffff] group-focus-within:border-[#DAC8E3] group-focus-within:text-opacity-[80%]
          transition-colors duration-75 ease-in-out
          ${ selectedValues.length > 0 ? 'border-[#d8bee6]' : '' }
        `}
      >
        <div 
          className={`
            ${selectedValues.length > 0
              ? ` 
                border-[#895f9e]
                bg-[#a377b8] text-white
                group-hover:bg-[#9268a6]
                group-hover:border-[#6c4680]
                group-focus-within:bg-[#9268a6] 
                group-focus-within:border-[#6c4680] 
              `
              : `
                border-[#cbb6d6]
                bg-purple-50 
                group-hover:bg-[#d4adeb]
                group-hover:border-[#b399bf]
                group-hover:text-[#4e2961]
                group-focus-within:bg-[#d4adeb] 
                group-focus-within:text-[#3e1d4f]
                group-focus-within:border-[#b399bf]
                opacity-50 
                text-gray-600
              `
            }
            border
            min-w-[27px] h-[27px] max-w-[27px]
            flex items-center justify-center 
            text-[10.5px] tabular-nums
            absolute
            -right-px -top-pxs
            pr-[1px]
          `}
        >
          <span 
            className={`
              h-4 w-4 
              -mr-pxf mt-pxf
              absolute
            `}
          >{type == 'date_range'
            ? <span className="text-[16px] mt-pxs block ml-pxs mdi mdi-calendar-blank"></span>
            : type == 'range'
            ? <span className="text-[16px] mt-pxs block ml-pxs mdi mdi-tune-variant"></span>
            : <span>
                <span
                  className={`
                    absolute
                    inset-0
                    ${selectedValues.length == 0 
                      ? 'opacity-0 duration-none'
                      : 'opacity-80 transistion-opacity duration-75 ease-in'
                    }
                  `}
                >{selectedValues.length}</span>
                <span
                  className={`
                    absolute
                    inset-0
                    mdi mdi-checkbox-multiple-outline
                    text-[17px] 
                    ${selectedValues.length == 0 
                      ? 'opacity-90'
                      : 'opacity-0 duration-none'
                    }
                  `}
                ></span>
              </span>
            }
          </span>
        </div>
      </button>

      <div 
        className={`
          z-[999] origin-top-left absolute 
          top-[-1px] right-[calc(100%+6px)]
          py-1.5 pl-1.5 pr-1 
          overscroll-contain select-none 
          bg-white border border-gray-300 bg-white text-gray-500 
          z-[999]
          hidden group-focus-within:block hover:block
          pointer-events-click 
          w-fit smin-w-[calc(100%-4px)]
          max-h-[calc(100%+2px)] h-fit
          after:absolute after:top-[-10px] after:left-[-160px] after:h-[55vh] after:right-[-10px] after:bg-[#ff000] after:z-[-15]
        `}
      >

        {type == 'date_range' && (
          <div 
            className="
              relative 
              pointer-events-click 
              flex justify-end 
              w-full 
              bg-white 
              py-1 pl-1 pr-[3px]
              text-xs 
              z-[999] 
            "
          >
            <DateFilter 
              title={title}
              filterValues={values}
              selectedValues={selectedValues}
              onUpdateDatesFilter={updateDatesFilter}
              onRemoveDatesFilter={(e) => { removeDatesFilter(); }} 
            />
          </div>
        )}
        
        {type == 'range' && (
          <div 
            className="
              relative 
              pointer-events-click 
              flex justify-end 
              w-full 
              bg-white 
              py-1 pl-1 pr-[3px]
              text-xs 
              z-[999] 
            "
          >
            <RangeFilter 
              title={title}
              filterValues={values}
              selectedValues={selectedValues}
              onUpdateRangeFilter={updateRangeFilter}
              onRemoveRangeFilter={removeRangeFilter}
            />
          </div>
        )}
        
        {type == 'multiple' && (
          <div 
            className="
              relative 
              pointer-events-click 
              flex justify-end 
              w-full 
              bg-white 
              py-1 pl-1 pr-[3px]
              text-xs 
              z-[999] 
            "
          >
            <MultipleFilter 
              filterValues={values}
              selectedValues={selectedValues}
              onUpdateFilter={toggleFilterValue}
              clearFilter={clearFilter}
              selectAllFilter={selectAllFilter}
              updatePresence={updateDatesFilter}
            />
          </div>
        )}

      </div>
    </div>
  );
};

export default FilterItemRouter;
