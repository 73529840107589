import React from "react";
import { 
  useParams, 
  // useLocation, 
} from "react-router-dom";
import { 
  useQuery, 
  // useMutation, 
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allSystemUsers } from "./../graphql/queries/rolePermissionQueries";
// import { deleteSectionsMutation } from "./../graphql/mutations/benefitMutations";
import Permissions, {
  settingsNoAccessFallbackList,
} from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useListSelection from "./../hooks/rowSelection";
import useNotification from "./../hooks/notifications";
import useModal from "./../hooks/modal";
import { SYSTEM_USER_MODEL_NAME } from "./../configs/constants";
import { ConfirmActionModal } from "./../mood-ui/Modals";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../mood-ui/Notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import { PageControls, TEditButton, AddButton } from "./../mood-ui/Controls";
import { Heading } from "./../mood-ui/Typography";
import Search from "./../mood-ui/Search";
import { ListItemCheckbox } from "./../mood-ui/Controls";
import { ItemsNotFound } from "./../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  // TableActions,
  TableSelectedActions,
  Pagination,
} from "./../mood-ui/DataTable";

import SystemUserListActions from "./../components/list-secondary-actions/SystemUserListActions";
import useItemCounter from "../hooks/itemCounter";

const ALL_SYSTEM_USERS = gql(allSystemUsers);
// const DELETE_SECTIONS = gql(deleteSectionsMutation);

const SystemUserList = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.SystemUsers, {
    noAccessFallbackList: settingsNoAccessFallbackList,
  });
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection, 
    isAllRowsSelected, 
    toggleAllRows,
  } = useListSelection();
  const { page, limit, orderBy, orderHow, search } = useParams();
  // const { search: queryString } = useLocation();
  const { 
    modalState, 
    // showModal, 
    // closeModal, 
  } = useModal();
  const {
    // notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();
  const {
    // notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();
  const { data, loading, error } = useQuery(ALL_SYSTEM_USERS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "systemUsers" });

  const { nextPage, prevPage, editForm, createForm, applySearch } =
    useBaseNavigation(SYSTEM_USER_MODEL_NAME, {
      urlContext: "settings",
      showingItemsCount: itemsTotal,
    });

  //   const [deleteSections, { loading: deletingSections }] = useMutation(
  //     DELETE_SECTIONS,
  //     {
  //       onCompleted: (result) => {
  //         displayNotification({
  //           heading: "Sections Deleted",
  //           message: "Successfully deleted selected sections",
  //           secondsToDisplay: 1,
  //         });
  //         clearSelection();
  //       },
  //       onError: (e) => {
  //         displayErrorNotification({
  //           heading: "Error",
  //           message: "Failed to delete selected sections",
  //           secondsToDisplay: 2,
  //         });
  //       },
  //       update: (cache, { data }) => {
  //         if (!data.deleteSections) return false;

  //         const deletedIds = data.deleteSections.map((cl) => cl.id);
  //         const existingSections = cache.readQuery({
  //           query: ALL_SECTIONS,
  //           variables: {
  //             page,
  //             limit,
  //             orderBy,
  //             orderHow,
  //             search,
  //           },
  //         });
  //         const newSections = existingSections.sections.filter(
  //           (cl) => deletedIds.indexOf(cl.id) == -1
  //         );
  //         cache.writeQuery({
  //           query: ALL_SECTIONS,
  //           variables: {
  //             page,
  //             limit,
  //             orderBy,
  //             orderHow,
  //             search,
  //           },
  //           data: { sections: newSections },
  //         });
  //       },
  //     }
  //   );

  //   const confirmDeletion = () => {
  //     showModal({
  //       heading: "Confirm Deletion",
  //       message:
  //         "Are you sure you want to DELETE selected sections? Do not proceed if you are not sure.",
  //       mood: "Danger",
  //       confirmButtonLabel: "Delete",
  //       onConfirm: () => {
  //         closeModal();
  //         handleDeleteSections();
  //       },
  //       onCancel: () => {
  //         closeModal();
  //       },
  //     });
  //   };

  //   const handleDeleteSections = () => {
  //     deleteSections({ variables: { ids: selectedRows } });
  //   };

  if (error) return <div>Error</div>;
  // if (!data) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="System users"
              description="Vaboo team members must use their own user account to access Control. 
                          Each account must use appropriate 'roles' to define what it can access. Feel free to 
                          create a new role if an existing role doesn't meet your requirements. Remove roles 
                          from system accounts if they are no longer needed."
            />
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Mutation.CreateSystemUser) && (
              <AddButton label="Add User" onClick={createForm} loading={loading} />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        <TableContainer tweaks="px-5 pb-4">
          <TableView tweaks={(!data || (data && data.systemUsers && data.systemUsers.length === 0)) ? 'border-[#ebedf2]' : ''}>
            <THeading tweaks={(!data || (data && data.systemUsers && data.systemUsers.length === 0)) ? 'opacity-30 border-b' : ''}>
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() => toggleAllRows(data?.systemUsers?.map(r => r.id))}
                    isChecked={isAllRowsSelected(data?.systemUsers?.map(r => r.id)) ? true : false}
                  />
                </THCol>
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="name" value="Name" />
                <THCol tweaks="text-left" name="email" value="Email" />
                <THCol tweaks="text-left" name="roles" value="Roles" />
                <THCol tweaks="w-16 text-center" />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.systemUsers && data.systemUsers.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <ItemsNotFound text="No control users found" tweaks="" />
                  </td>
                </tr>
              </tbody>
            )}         
            {data && data.systemUsers && data.systemUsers.length > 0 && (
              <TContent>
                {data.systemUsers
                .sort((a, b) => a.email > b.email ? 1 : a.email < b.email ? -1 : 0)
                .map((u) => (
                  <TRow key={u.id} isSelected={rowSelected(u.id)}>
                    <TCol tweaks="w-4 text-center">
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(u.id)}
                        isChecked={rowSelected(u.id) ? true : false}
                      />
                    </TCol>
                    <TCol value={u.id} />
                    <TCol value={u.name} />
                    <TCol value={u.email} />
                    <TCol value={u.roles.length} />
                    {/* <TCol value={u.roles.map((role) => role.name).join(", ")} /> */}
                    <TCol tweaks="w-16 text-center">
                      {hasAccess(Permissions.Query.SystemUser) && (
                        <TEditButton onClick={() => editForm(u.id)} />
                      )}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={4}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <SystemUserListActions
                    onClearSelection={clearSelection}
                    onDelete={() => {}}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={5} extraStyles="">
                <div className="flex">
                  {/* dynamic list filter functionality here  */}
                </div>
              </Col>
              <Col width={3}>
                <Pagination onPrev={prevPage} onNext={nextPage} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default SystemUserList;
