import React, { useState, useEffect } from "react";
import { Editor, EditorState, ContentState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

const RichTextEditor = ({
  value = undefined,
  name,
  onChange,
  label,
  error,
  description,
  cols,
  rows,
  flat = false, 
  isRequired = false,
  tweaks = '', 
  readOnly = false, 
  disabled = false, 
}) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(value ? value : "")
    )
  );
  const [editorError, setEditorError] = useState(error);

  //   const [fieldState, setFieldState] = useState({ value, error });
  // useEffect(() => {
  //   setEditorState(EditorState.createWithContent(value));
  // }, [value, error, name]);
  // const handleOnChange = useCallback(e => {
  //   setFieldState({ value: e.target.value, error: false });
  //   onChange(e);
  // }, []);

  // console.log("es", value);

  useEffect(() => {
    const contentState = stateFromHTML(value);
    const initialEditorState = EditorState.createWithContent(contentState);
    setEditorState(initialEditorState);
    setEditorError(error);
    // if (value && value.length > 0) {
    //   const contentState = stateFromHTML(value);
    //   const initialEditorState = EditorState.createWithContent(contentState);
    //   setEditorState(initialEditorState);
    // } else {
    //   const contentState = stateFromHTML(value);
    //   const initialEditorState = EditorState.createWithContent(contentState);
    //   setEditorState(initialEditorState);
    //   console.log("gerwehrkjhew");
    // }
  }, [value, error, name]);

  const handleStateChange = (state) => {
    setEditorState(state);
    setEditorError(false);
    updateStateDeep();
  };

  const updateStateDeep = () => {
    onChange({
      target: { name, value: stateToHTML(editorState.getCurrentContent()) },
    });
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
    }
    updateStateDeep();
  };

  const handleBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
    updateStateDeep();
  };

  const handleItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
    updateStateDeep();
  };

  const handleUnderlineClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
    updateStateDeep();
  };

  // const textAreaCssClass = editorError
  //   ? "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red "
  //   : "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";




  let textAreaCssClass = editorError
  ? "focus-within:outline-none focus-within:shadow-outline rounded-none form-input block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red sm:text-sm sm:leading-5"
  : "focus-within:outline-none focus-within:shadow-outline rounded-none form-input block w-full border-[#dfe2e8] text-gray-500 sm:text-sm sm:leading-5 " 
  textAreaCssClass += flat ? ' border-none invalid:ring-1 invalid:ring-red-300 invalid:ring-inset' : ''
  textAreaCssClass += (' ' + tweaks)
  textAreaCssClass += (readOnly? ' pointer-events-none' : '') 
  textAreaCssClass += (disabled ? ' select-none opacity-75' : '');
  textAreaCssClass += (readOnly ? ' select-none opacity-75' : '');




  return (
    <div className={`relative sm:col-span-${cols} select-none`}>
       {label && (
        <label
          htmlFor={name}
          className="text-sm leading-5 text-gray-500 pl-1 select-none"
        >
          {label}
          {isRequired && <span className="font-light text-gray-400"> *</span>}
        </label>
      )}
      <div className="mt-1"></div>
      <div
        id={name}
        name={name}
        rows={rows}
        className={textAreaCssClass}
        style={{ minHeight: "80px" }}
        value={value}
      >
      <span className="float-right z-0 inline-flex mb-2 mr-[-13px] mt-[-9px] pl-[20px]">
        <button
          type="button"
          className="relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm leading-5 font-medium text-[#b07fc7] hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          style={{ fontWeight: "bold" }}
          onClick={handleBoldClick}
        >
          B
        </button>

        <button
          type="button"
          className="-ml-px relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm leading-5 font-medium text-[#b07fc7] hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          style={{ fontStyle: "italic" }}
          onClick={handleItalicClick}
        >
          I
        </button>
        <button
          type="button"
          className="-ml-px relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm leading-5 font-medium text-[#b07fc7] hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          style={{ textDecoration: "underline" }}
          onClick={handleUnderlineClick}
        >
          U
        </button>
      </span>
        <Editor
          editorState={editorState}
          onChange={handleStateChange}
          handleKeyCommand={handleKeyCommand}
          stripPastedStyles={true}
        ></Editor>
      </div>

      {!editorError && description && (
        <p className="mt-1.5 text-xs text-gray-400 pl-0.5 select-none">{description}</p>
      )}
      {editorError && (
        <p className="mt-1.5 text-xs text-red-400 pl-0.5 select-none">{editorError}</p>
      )}
    </div>
  );
};

export default RichTextEditor;
