import React from "react";

const useDataFieldSetters = (data, setData) => {
  const setFieldValue = (e) => {
    const { name, value } = e.target;
    const newData = { ...data };
    newData[name] = value;
    setData(newData);
  };

  const setCheckboxGroupFieldValue = (e) => {
    const { name, value } = e.target;
    const newData = { ...data };
    if (newData[name].indexOf(value) > -1) {
      const selectedValues = newData[name].filter((sVal) => sVal != value);
      newData[name] = selectedValues;
    } else {
      newData[name].push(value);
    }
    setData(newData);
  };
  // const setFieldValue = e => {
  //   const { name, value } = e.target;
  //   const newData = { ...data };
  //   newData[name] = value;
  //   setData(newData);
  // };

  // const setCheckboxGroupFieldValue = e => {
  //   const { name, value } = e.target;
  //   const newData = { ...data };
  //   if (newData[name].indexOf(value) > -1) {
  //     const selectedValues = newData[name].filter(sVal => sVal != value);
  //     newData[name] = selectedValues;
  //   } else {
  //     newData[name].push(value);
  //   }
  //   setData(newData);
  // };

  return { setFieldValue, setCheckboxGroupFieldValue };
};

export default useDataFieldSetters;
