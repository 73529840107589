import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  ACCOUNTING_STANDARD_PRICING,
  ACCOUNTING_PACKAGE_PRICING,
  ACCOUNTING_GRADUATED_PRICING,
  ACCOUNTING_VOLUME_PRICING,
  ACCOUNTING_VOLUME_PACKAGE_PRICING,
  DEFAULT_PAYMENT_METHOD,
} from "../../configs/constants";
import {
  clientPricingModel,
  clientPaymentSettings,
} from "./../../graphql/queries/billingQueries";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";
import {
  TextField,
  NumberField,
  SelectField,
  ToggleCheckboxField,
} from "../../mood-ui/Fields";
import { Heading, InfoMessage } from "../../mood-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../mood-ui/Notifications";

import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useHyperState from "./../../hooks/hyperState";
import useDataProvider from "./../../hooks/dataProvider";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "./../../hooks/notifications";
import StandardPricing from "./accounting/StandardPricing";
import PackagePricing from "./accounting/PackagePricing";
import GraduatedPricing from "./accounting/GraduatedPricing";
import VolumePricing from "./accounting/VolumePricing";

import ClientOverview from "./../../components/reports/ClientOverview";
import VolumePackagePricing from "./accounting/VolumePackagePricing";

const PRICING_MODELS = [
  { id: ACCOUNTING_STANDARD_PRICING, name: ACCOUNTING_STANDARD_PRICING },
  { id: ACCOUNTING_PACKAGE_PRICING, name: ACCOUNTING_PACKAGE_PRICING },
  { id: ACCOUNTING_GRADUATED_PRICING, name: ACCOUNTING_GRADUATED_PRICING },
  { id: ACCOUNTING_VOLUME_PRICING, name: ACCOUNTING_VOLUME_PRICING },
  {
    id: ACCOUNTING_VOLUME_PACKAGE_PRICING,
    name: ACCOUNTING_VOLUME_PACKAGE_PRICING,
  },
];

const CLIENT_PRICING_MODEL = gql(clientPricingModel);
const CLIENT_PAYMENT_SETTINGS = gql(clientPaymentSettings);

const LandlordAccountingSettings = ({ entityDetails = null }) => {
  const { id: client_id } = useParams();
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.ClientPaymentSettings, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: client_id },
  });
  const [paymentMethods, setPaymentMethods] = useState([]);

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
    // syncView,
  } = useHyperState("formData", {
    account_id: 0,
    client_id: 0,
    type: "Monthly",
    maintance_fee_monthly_amount: 0,
    maintance_fee_monthly_billing_day: 0,
    gifted_premium_access_fee_monthly: 0,
    acquired_premium_access_fee_monthly: 0,
    minimum_payment_amount: 0,
    number_of_free_premium_users: 0,
    must_have_payment_method: 0,
    payment_takeover_by_user_id: 0,
    invoice_to_text: undefined,
    only_charge_for_active_premium: 0,
    should_invoice: 1,
    payment_method_code: DEFAULT_PAYMENT_METHOD,
  });

  const { setFieldError, formHasErrors, fieldError, clearError } =
    useFormErrors();

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const [pricingModel, setPricingModel] = useState(false);

  const [landlords] = useDataProvider("landlords");

  const { loadingPricing, pricingError } = useQuery(CLIENT_PRICING_MODEL, {
    variables: {
      client_id,
    },
    fetchPolicy: "network-only",
    // fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.clientPricingModel) return false;
      setPricingModel(result.clientPricingModel.name);
    },
  });

  const { loading, error } = useQuery(CLIENT_PAYMENT_SETTINGS, {
    variables: { client_id },
    fetchPolicy: "network-only",
    // fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.clientPaymentSettings) return false;
      setFormData({
        ...result.clientPaymentSettings,
        client_id: result.clientPaymentSettings.account_id,
      });
      setPaymentMethods(result.clientPaymentSettings.available_payment_methods);
    },
  });

  const [isDraft, setDraft] = useState(false);

  if (error || pricingError) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <FormContainer>
          <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
            <Col width={12}>
              <Heading
                inset={true}
                text="Billing settings"
                description="Control when, how, and how much a client pays. Change these settings 
                              with caution as they affect real-world payments and our
                              bookkeepers rely on invoices being correct."
              />
              <ClientOverview
                entityDetails={entityDetails}
                groupFilter={["billing", "growth"]}
                dense
              />
            </Col>
          </Row>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Invoicing"
                      description="Turn off invoices if a client should not pay yet or has cancelled."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles={""}>
                    <ToggleCheckboxField
                      loading={loading || loadingPricing}
                      id="should_invoice"
                      name="should_invoice"
                      label="Should invoice"
                      isChecked={formData?.should_invoice}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      error={fieldError("should_invoice")}
                      description="Enable/disable payments"
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <NumberField
                      loading={loading || loadingPricing}
                      name="maintance_fee_monthly_billing_day"
                      label="Billing day"
                      value={formData?.maintance_fee_monthly_billing_day}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("maintance_fee_monthly_billing_day")}
                      isRequired={true}
                      min={1}
                      max={28}
                      step={1}
                      description="Day of month from 1 to 28"
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <TextField
                      loading={loading || loadingPricing}
                      name="invoice_to_text"
                      label="Name on invoice"
                      value={formData?.invoice_to_text || ""}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("invoice_to_text")}
                      isRequired={false}
                      minlength="4"
                      maxlength="48"
                      description="Company or person"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Payment method"
                      description="Swap between active payment methods or select another client to pay these bills."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles={""}>
                    <ToggleCheckboxField
                      loading={loading || loadingPricing}
                      id="must_have_payment_method"
                      name="must_have_payment_method"
                      label="Is required"
                      isChecked={formData?.must_have_payment_method}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      error={fieldError("must_have_payment_method")}
                      description="Must connect to add users"
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <SelectField
                      loading={loading || loadingPricing}
                      value={formData?.payment_method_code}
                      values={paymentMethods || []}
                      label="Active method"
                      name="payment_method_code"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      valueKey="code"
                      labelKey="name"
                      placeholder="asd"
                      isRequired={true}
                      error={fieldError("payment_method_code")}
                      description="Select a connected method"
                      prependOption={
                        paymentMethods.length ? undefined : "None connected..."
                      }
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <SelectField
                      loading={loading || loadingPricing}
                      value={formData?.payment_takeover_by_user_id}
                      values={landlords || []}
                      label="Transfer payments"
                      name="payment_takeover_by_user_id"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      valueKey="id"
                      labelKey="email"
                      isRequired={false}
                      error={fieldError("payment_takeover_by_user_id")}
                      description="Pass payments to another client"
                      prependOption="Pay own bills"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Basic billing"
                      description="These settings relate to our core service fees."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles={""}>
                    <ToggleCheckboxField
                      loading={loading || loadingPricing}
                      id="can_give_basic"
                      name="can_give_basic"
                      label="Can give basic"
                      isChecked={formData?.can_give_basic}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      error={fieldError("can_give_basic")}
                      description="Can add basic users"
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <NumberField
                      loading={loading || loadingPricing}
                      name="maintance_fee_monthly_amount"
                      label="Maintenance fee"
                      value={formData?.maintance_fee_monthly_amount}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("maintance_fee_monthly_amount")}
                      isRequired={true}
                      min={0}
                      max={99999}
                      step={0.01}
                      description="A fixed monthly management fee"
                      gbp
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <NumberField
                      loading={loading || loadingPricing}
                      name="minimum_payment_amount"
                      label="Minimum invoice amount"
                      value={formData?.minimum_payment_amount}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("minimum_payment_amount")}
                      isRequired={true}
                      min={0}
                      max={99999}
                      step={0.01}
                      description="Will round up an invoice if lower"
                      gbp
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Premium billing"
                      description="These settings define how we charge for premium users. Use the 
                                  calculator at the borrom of this page to test settings before saving them."
                    />
                  </Col>
                  <Col width={12}>
                    <InfoMessage
                      loading={loading || loadingPricing}
                      icon={false}
                      text={
                        pricingModel === ACCOUNTING_STANDARD_PRICING
                          ? `Standard pricing charges the same amount per premium user every month, regardless of quantity.`
                          : pricingModel === ACCOUNTING_PACKAGE_PRICING
                          ? `Package pricing is used to bulk charge for groups of user accounts. E.g. You might charge £100.00 
                          for every 100 users. Bills are rounded up by default, so a client with 120 users would pay £200.00
                          on their billing day.`
                          : pricingModel === ACCOUNTING_GRADUATED_PRICING
                          ? `Graduated pricing enables multiple price points across different user groups based on quantity tiers. 
                          E.g. You could charge £1.00 per user for 100 users forever, and then £0.50 per user for an additional 50, 
                          and £0.25 per user for the next 1,000.`
                          : pricingModel === ACCOUNTING_VOLUME_PRICING
                          ? `Volume pricing charges the same amount per premium user based on the total number of premium users. 
                          E.g. A client might pay £1.00 per user when below 100 users, then pay £0.50 per user instead once they reach 101.`
                          : pricingModel === ACCOUNTING_VOLUME_PACKAGE_PRICING
                          ? `Volume Package pricing charges tier  amount when number of premium users is within tier setup. 
                          E.g. A client might pay £100.00 when he has 1..500 users and £300.00 when he has 501..1000 users.`
                          : ""
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles={""}>
                    <ToggleCheckboxField
                      loading={loading || loadingPricing}
                      id="can_give_premium"
                      name="can_give_premium"
                      label="Can give premium"
                      isChecked={formData?.can_give_premium}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      error={fieldError("can_give_premium")}
                      description="Can add premium users"
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <ToggleCheckboxField
                      loading={loading || loadingPricing}
                      id="only_charge_for_active_premium"
                      name="only_charge_for_active_premium"
                      label="Free until active"
                      isChecked={formData?.only_charge_for_active_premium}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      error={fieldError("only_charge_for_active_premium")}
                      description="Only charge for active users"
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <NumberField
                      loading={loading || loadingPricing}
                      name="number_of_free_premium_users"
                      label="Free premium users"
                      min={0}
                      max={99999}
                      step={1}
                      value={formData?.number_of_free_premium_users}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("number_of_free_premium_users")}
                      isRequired={true}
                      description="Invoices will ignore X premium users"
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <SelectField
                      loading={loading || loadingPricing}
                      value={pricingModel}
                      values={PRICING_MODELS || []}
                      label="Pricing model"
                      onChange={(e) => {
                        setDraft(true);
                        setPricingModel(e.target.value);
                      }}
                      valueKey="id"
                      labelKey="name"
                      transformText
                      description="Used to calculate premium fee"
                    />
                  </Col>
                  {pricingModel === ACCOUNTING_STANDARD_PRICING &&
                    !loadingPricing && (
                      <Col width={4} extraStyles={""}>
                        <NumberField
                          loading={loading}
                          name="gifted_premium_access_fee_monthly"
                          label="Monthly user fee"
                          value={formData?.gifted_premium_access_fee_monthly}
                          min={0}
                          max={100}
                          step={0.01}
                          onChange={(e) => {
                            handleFieldOnChange(e);
                          }}
                          error={fieldError(
                            "gifted_premium_access_fee_monthly"
                          )}
                          isRequired={true}
                          gbp
                          description="All premium users cost the same"
                        />
                        <StandardPricing
                          loading={loading}
                          clientId={client_id}
                          paymentSettings={formData}
                          isDraft={isDraft}
                          parentFormHasErrors={formHasErrors}
                          onSuccess={() => {
                            displayNotification({
                              heading: "Update OK",
                              message:
                                "Successfully updated payment settings. Using Standard Pricing Model",
                              secondsToDisplay: 2,
                            });
                            setDraft(false);
                          }}
                          onError={() =>
                            displayErrorNotification({
                              heading: "Error",
                              message: "Failed to update payment settings",
                              secondsToDisplay: 2,
                            })
                          }
                        />
                      </Col>
                    )}
                  {pricingModel === ACCOUNTING_PACKAGE_PRICING &&
                    !loadingPricing && (
                      <Col width={8} extraStyles={""}>
                        <PackagePricing
                          loading={loading}
                          clientId={client_id}
                          paymentSettings={formData}
                          isDraft={isDraft}
                          setDraft={setDraft}
                          parentFormHasErrors={formHasErrors}
                          onSuccess={() => {
                            displayNotification({
                              heading: "Update OK",
                              message:
                                "Successfully updated payment settings. Using Package Pricing Model",
                              secondsToDisplay: 2,
                            });
                            setDraft(false);
                          }}
                          onError={() =>
                            displayErrorNotification({
                              heading: "Error",
                              message: "Failed to update payment settings",
                              secondsToDisplay: 2,
                            })
                          }
                        />
                      </Col>
                    )}
                  {pricingModel === ACCOUNTING_GRADUATED_PRICING &&
                    !loadingPricing && (
                      <Col width={8} extraStyles={""}>
                        <GraduatedPricing
                          loading={loading}
                          clientId={client_id}
                          paymentSettings={formData}
                          isDraft={isDraft}
                          setDraft={setDraft}
                          parentFormHasErrors={formHasErrors}
                          onSuccess={() => {
                            displayNotification({
                              heading: "Update OK",
                              message:
                                "Successfully updated payment settings. Using Graduated Pricing Model",
                              secondsToDisplay: 2,
                            });
                            setDraft(false);
                          }}
                          onError={() =>
                            displayErrorNotification({
                              heading: "Error",
                              message: "Failed to update payment settings",
                              secondsToDisplay: 2,
                            })
                          }
                        />
                      </Col>
                    )}
                  {pricingModel === ACCOUNTING_VOLUME_PRICING &&
                    !loadingPricing && (
                      <Col width={8} extraStyles={""}>
                        <VolumePricing
                          loading={loading}
                          clientId={client_id}
                          paymentSettings={formData}
                          isDraft={isDraft}
                          setDraft={setDraft}
                          parentFormHasErrors={formHasErrors}
                          onSuccess={() => {
                            displayNotification({
                              heading: "Update OK",
                              message:
                                "Successfully updated payment settings. Using Volume Pricing Model",
                              secondsToDisplay: 2,
                            });
                            setDraft(false);
                          }}
                          onError={() =>
                            displayErrorNotification({
                              heading: "Error",
                              message: "Failed to update payment settings",
                              secondsToDisplay: 2,
                            })
                          }
                        />
                      </Col>
                    )}
                  {pricingModel === ACCOUNTING_VOLUME_PACKAGE_PRICING &&
                    !loadingPricing && (
                      <Col width={8} extraStyles={""}>
                        <VolumePackagePricing
                          loading={loading}
                          clientId={client_id}
                          paymentSettings={formData}
                          isDraft={isDraft}
                          setDraft={setDraft}
                          parentFormHasErrors={formHasErrors}
                          onSuccess={() => {
                            displayNotification({
                              heading: "Update OK",
                              message:
                                "Successfully updated payment settings. Using Volume Package Pricing Model",
                              secondsToDisplay: 2,
                            });
                            setDraft(false);
                          }}
                          onError={() =>
                            displayErrorNotification({
                              heading: "Error",
                              message: "Failed to update payment settings",
                              secondsToDisplay: 2,
                            })
                          }
                        />
                      </Col>
                    )}
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-[#dfe2e8] pt-7 pb-5 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Member upgrades"
                      description="Optionally allow users to upgrade to premium for a monthly fee."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles={""}>
                    <ToggleCheckboxField
                      loading={loading || loadingPricing}
                      id="can_upgrade"
                      name="can_upgrade"
                      label="Allow upgrades"
                      isChecked={formData?.can_upgrade}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      error={fieldError("can_upgrade")}
                      description="Enable/disable upgrades"
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <NumberField
                      loading={loading || loadingPricing}
                      name="acquired_premium_access_fee_monthly"
                      label="Monthly upgrade fee"
                      gbp
                      min={0}
                      max={100}
                      step={0.01}
                      value={formData?.acquired_premium_access_fee_monthly}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("acquired_premium_access_fee_monthly")}
                      isRequired={true}
                      disabled={!formData?.can_upgrade}
                      description="Monthly recurring card payments"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default LandlordAccountingSettings;
