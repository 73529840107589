import React from "react";

const DynamicLists = ({
  lists,
  onChange = () => {},
  activeList, 
  listsTitle = "Dynamic lists",
}) => {

  return (

    <div className="relative z-50 group smt-[-3px]">
      
      <button
        type="button"
        className={`
          select-none 
          inline-flex justify-between 
          w-full h-[30px]
          px-2 items-center 
          border border-gray-200 focus:outline-none
          text-xs 
          focus:outline-0
          group/button
          bg-[#FCFCFE] border-gray-150 text-gray-500 text-opacity-[70%]
          hover:bg-[#ffffff] hover:border-gray-200 hover:text-opacity-[80%]
          group-hover:bg-[#ffffff] group-hover:border-gray-300 group-hover:text-opacity-[80%]
          group-focus-within:bg-[#ffffff] group-focus-within:border-gray-300 group-focus-within:text-opacity-[80%]
          relative
          transition-colors duration-75 ease-in
          ${activeList 
            ? 'border-[#d8bee6]' 
            : ''
          }
        `}
      >

        <div className="pr-[23px] truncate text-left">
          {activeList ? activeList.name : listsTitle}
        </div>
        <div 
          className={`
            ${activeList 
              ? ` 
                  border-[#895f9e]
                  bg-[#a377b8] text-white
                  group-hover:bg-[#9268a6]
                  group-hover:border-[#6c4680]
                  group-focus-within:bg-[#9268a6] 
                  group-focus-within:border-[#6c4680] 
                `
              : `
                  bg-purple-50 
                  group-hover:bg-[#f3eafb] group-hover/button:border-gray-300
                  group-hover:bg-purple-50 group-hover:bg-[rgb(243,240,255)] group-hover:border-gray-300
                  group-focus-within:bg-purple-50 group-focus-within:bg-[rgb(243,240,255)] group-focus-within:border-gray-300
                `
            }
            transition-colors duration-75 ease-in
            border
            min-w-[30px] h-[30px] max-w-[24px]
            flex items-center justify-center 
            text-[12px] tabular-nums
            absolute
            -right-px -top-px
          `}
        >
          <span 
            className={`
              absolute
              ${activeList
                ? 'transistion-opacity duration-75 ease-in'
                : 'opacity-0 duration-none'
              }
            `}
          >
            <span className="mdi mdi-flash"></span>
          </span>
          <svg 
            className={`
              h-4 w-4 
              absolute
              ${activeList
                ? 'opacity-0 duration-none'
                : 'opacity-50 transition-opacity duration-75 ease-in'
              }
            `}
            fill="currentColor" 
            viewBox="0 0 20 20"
          >
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </div>
      </button>

      <div 
        className={`
          origin-top-left absolute 
          left-0 
          w-full 
          p-1 mt-1 
          overscroll-contain select-none 
          bg-white border border-gray-300 bg-white text-gray-500 
          hidden group-focus-within:block hover:blocks
          pointer-events-click
        `}
        style={{ maxHeight: "55vh", overflowY: "auto" }}
      >

        {lists && lists.length > 0 && lists.map((l) => (

          <button
            key={l.id}
            onClick={(e) => { onChange(l.id); e.target.blur() }}
            className={
              (activeList?.id == l.id) 
              ? "               w-full flex justify-between items-center pr-[10px]d p-1 text-xs leading-5 text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700 bg-[#F3E8FF]"
              : "cursor-pointer w-full flex justify-between items-center pr-[10px]d p-1 text-xs leading-5 text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-700"
            }
          >
            
            <div className="flex w-full justify-between items-center pointer-events-none">
              <div className="flex-shrink truncate elipsis ... pointer-events-none pr-2">
                {l.name}
              </div>
              <span 
                className={`
                  ${l.count > 0 
                    ? 'bg-[#BEA0CD] text-white' 
                    : 'bg-gray-50'
                  } 
                  h-5 w-fit max-w-fit 
                  flex items-center justify-center 
                  text-[11px] tabular-nums
                  px-[5px] 
                  pointer-events-none 
                  rounded-[2px]
                  flex-grow
                `}
              >
                {l.count.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0] || 0}
              </span>
            </div>
         
          </button>

        ))}

      </div>

    </div>
  
  );

};

export default DynamicLists;
