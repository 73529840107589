import React, { useRef, useState } from "react";
import NewDatesFilter from "./inputs/DateFilter";
import RangeFilter from "./inputs/RangeFilter";
import MultipleFilter from "./inputs/MultipleFilter";

const StandardFilter = ({ 
  options, 
  selectedValues, 
  onUpdateFilter, 
  onUpdateDatesFilter, 
  onRemoveDatesFilter, 
  onUpdateRangeFilter, 
  onRemoveRangeFilter, 
  onClearFilter,
  onSelectManyFilter, 
}) => { 

  const { 
    type, 
    title, 
    queryKey, 
    values, 
  } = options;
  
  const toggleFilterValue = (id) => {
    onUpdateFilter(queryKey, id);
  };

  const updateDatesFilter = (value) => {
    onUpdateDatesFilter(queryKey, value);
  };

  const removeDatesFilter = (value) => {
    onRemoveDatesFilter(queryKey, value);
  };
  
  const updateRangeFilter = (value) => {
    onUpdateRangeFilter(queryKey, value);
  };
  
  const removeRangeFilter = (value) => {
    onRemoveRangeFilter(queryKey, value);
  };
  
  const clearFilter = () => {
    onClearFilter(queryKey)
  };
  
  const selectAllFilter = () => {
    const allOptions = options.values.map(options => options.uid)
    onSelectManyFilter(queryKey, allOptions)
  };
  
  const boxRef = useRef();

  const onNext = (e) => { e.target.blur(); boxRef?.current?.offsetParent?.offsetParent?.focus() }
  
  const [right, setRight] = useState(0)
  if (boxRef.current && ((type == 'date_range' || type == 'range') && right == 0)) {
    const rects = boxRef.current.getBoundingClientRect()
    if ((rects.x + rects.width - 470) < 191) {
      setRight(rects.x + rects.width - 470 - 191)
    }
  }

  return (

    <div className="relatives h-[24px] text-left mb-1 pl-1 pr-0 w-full group" >

      <button
        type="button" 
        ref={boxRef}
        className={`
          select-none 
          inline-flex justify-between 
          w-full h-[24px]
          px-2 py-[3px] 
          border border-gray-200 focus:outline-none
          text-xs 
          group/button
          bg-[#FCFCFE] border-gray-150 text-gray-500 text-opacity-[70%]
          hover:bg-[#ffffff] hover:border-gray-200 hover:text-opacity-[80%]
          group-hover:bg-[#ffffff] group-hover:border-[#DAC8E3] group-hover:text-opacity-[80%]
          group-focus-within:bg-[#ffffff] group-focus-within:border-[#DAC8E3] group-focus-within:text-opacity-[80%]
  srelative
          transition-colors duration-75 ease-in-out
          ${selectedValues.length 
            ? 'border-[#d8bee6]' 
            : ''
          }
        `}
      >
        <div className="pr-[23px] truncate text-left">
          {title}
        </div>
        <div 
          className={`
            ${selectedValues.length 
              ? ` 
                border-[#895f9e]
                bg-[#a377b8] text-white
                group-hover:bg-[#9268a6]
                group-hover:border-[#6c4680]
                group-focus-within:bg-[#9268a6] 
                group-focus-within:border-[#6c4680] 
              `
              : `
                border-[#cbb6d6]
                bg-purple-50 
                group-hover:bg-[#e4cbf2]
                group-hover:border-[#b399bf]
                group-hover:text-[#4e2961]
                group-focus-within:bg-[#e4cbf2] 
                group-focus-within:text-[#3e1d4f]
                group-focus-within:border-[#b399bf]
                opacity-50 
                text-gray-600
              `
            }
            border
            min-w-[24px] h-[24px] max-w-[24px]
            flex items-center justify-center 
            text-[10.5px] tabular-nums
            absolute
            -right-px -top-px
            pr-[1px]
          `}
        >
          <span 
            className={`
              h-4 w-4 
              -mr-pxf
              mt-pxf
              absolute
            `}
          >{type == 'date_range'
            ? <span className="text-[14px] mt-px block ml-px mdi mdi-calendar-blank"></span>
            : type == 'range'
            ? <span className="text-[14px] mt-px block ml-px mdi mdi-tune-variant"></span>
            : <span>
                <span
                  className={`
                    absolute
                    inset-0
                    ${selectedValues.length == 0 
                      ? 'opacity-0 duration-none'
                      : 'opacity-80 transistion-opacity duration-75 ease-in'
                    }
                  `}
                >{selectedValues.length}</span>
                <span
                  className={`
                    absolute
                    inset-0
                    mdi mdi-list-status
                    text-[15px] 
                    ${selectedValues.length == 0 
                      ? 'opacity-80 transistion-opacity duration-75 ease-in'
                      : 'opacity-0 duration-none'
                    }
                  `}
                ></span>
              </span>
            }
          </span>
        </div>
      </button>

      <div 
        className={`
          z-[999] origin-top-right absolute 
          top-[30px] right-[${ right }px]
          py-1.5 pl-1.5 pr-1 
          overscroll-contain select-none 
          bg-white border border-gray-300 bg-white text-gray-500 
          z-[999]
          hidden group-focus-within:block hover:block
          pointer-events-click 
          ${type == 'date_range' || type == 'range' 
            ? 'w-fit min-w-[calc(200%-4px)] smax-w-[calc(300%-4px)]'
            : 'w-max min-w-[calc(100%-4px)] smax-w-[calc(200%-4px)]'
          }
        `}
      >

        {type == 'date_range' && (
          <div 
            className="
              relative 
              pointer-events-click 
              flex justify-end 
              w-full 
              bg-white 
              py-1 pl-1 pr-[3px]
              text-xs 
              z-[999] 
            "
          >
            <NewDatesFilter 
              onNext={onNext}
              title={title}
              filterValues={values}
              selectedValues={selectedValues}
              onUpdateDatesFilter={updateDatesFilter}
              onRemoveDatesFilter={(e) => { removeDatesFilter(); e.target.blur()}} 
            />
          </div>
        )}
        
        {type == 'range' && (
          <div 
            className="
              relative 
              pointer-events-click 
              flex justify-end 
              w-full 
              bg-white 
              py-1 pl-1 pr-[3px]
              text-xs 
              z-[999] 
            "
          >
            <RangeFilter 
              onNext={onNext}
              title={title}
              filterValues={values}
              selectedValues={selectedValues}
              onUpdateRangeFilter={updateRangeFilter}
              onRemoveRangeFilter={removeRangeFilter}
            />
          </div>
        )}
        
        {type == 'multiple' && (
          <div 
            className="
              relative 
              pointer-events-click 
              flex justify-end 
              w-full 
              bg-white 
              py-1 pl-1 pr-[3px]
              text-xs 
              z-[999] 
            "
          >
            <MultipleFilter 
              onNext={onNext}
              filterValues={values}
              selectedValues={selectedValues}
              onUpdateFilter={toggleFilterValue}
              clearFilter={clearFilter}
              selectAllFilter={selectAllFilter}
              updatePresence={updateDatesFilter}
            />
          </div>
        )}

      </div>
    </div>
  );
};

export default StandardFilter;
