import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import { qaStats } from "./../../graphql/queries/reportQueries";
import { generateSystemAccessCodeMutation } from "./../../graphql/mutations/systemMutations";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useTypeFilters from "./../../hooks/typeFilters";
import {
  REPORTS_QA_STATS_MODEL_NAME,
  VABOO_REST_API_URL,
} from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import QAStatsReport from "./../../components/reports/QAStatsReport";

const QA_STATS = gql(qaStats);
const GENERATE_SYSTEM_ACCESS_CODE = gql(generateSystemAccessCodeMutation);

const START_DATE = moment().add(-30, "days").format("YYYY-MM-DD");
const END_DATE = moment().format("YYYY-MM-DD");

const TenantQAStats = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.TenantQAStatsReport);
  const { page, limit, orderBy, orderHow, search } = useParams();

  const {
    applyFilters,
    resetFilters,
    queryString: adjustedQueryString,
    queryParams,
  } = useBaseNavigation(REPORTS_QA_STATS_MODEL_NAME, {
    queryDefaults: { startDate: [START_DATE], endDate: [END_DATE] },
  });

  const { data, loading, error } = useQuery(QA_STATS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString: adjustedQueryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: filtersUpdateFilter,
    resetFilters: filtersResetFilters,
    removeFilterValue: filtersRemoveFilterValue,
    applyFilters: filtersApplyFilters,
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "qaStats",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: adjustedQueryString,
  });

  const [generateSystemAccessCode] = useMutation(GENERATE_SYSTEM_ACCESS_CODE, {
    onCompleted: (result) => {
      const restApiExportUrl = `${VABOO_REST_API_URL}reporting/survey-stats-report${adjustedQueryString}&system_access_code=${result.generateSystemAccessCode.code}`;
      window.open(restApiExportUrl);
    },
    onError: (e) => {
      alert("Failed to generate Vaboo REST System Access Code");
      console.log(e);
    },
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <QAStatsReport
      loading={loading}
      data={data}
      typeFilters={typeFilters}
      onApplyFilters={filtersApplyFilters}
      onResetFilters={filtersResetFilters}
      onRemoveFiltervalue={filtersRemoveFilterValue}
      onUpdateFilter={filtersUpdateFilter}
      onExportData={generateSystemAccessCode}
      hasActiveFilters={hasActiveFilters}
      selectedFilters={selectedFilters}
      startDate={queryParams.startDate[0]}
      endDate={queryParams.endDate[0]}
      exportIsAvailable={true}
    />
  );
};

export default TenantQAStats;
