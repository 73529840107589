import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { updatePasswordMutation } from "./../../graphql/mutations/accountSharedMutations";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../moose-ui/Layout";
import { FormControls, SaveButton } from "../../moose-ui/Controls";
import { TextField } from "../../moose-ui/Fields";
import { Heading } from "../../moose-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../moose-ui/Notifications";

import useFormErrors from "./../../hooks/formErrors";
import useNotification from "./../../hooks/notifications";

const UPDATE_PASSWORD = gql(updatePasswordMutation);

const TenantAdvancedActions = () => {
  const { id } = useParams();
  const [password, setPassword] = useState("");

  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const [
    update,
    { data: updateResult, loading: isUpdating, error: updateError },
  ] = useMutation(UPDATE_PASSWORD, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated password",
        secondsToDisplay: 1,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update password",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handlePasswordChange = (e) => {
    clearError("password");
    setPassword(e.target.value);
  };

  const handleSave = () => {
    update({ variables: { account_id: id, password } });
  };

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="Password Update" />
        <FormContainer>
          <FormData>
            <Row>
              <Col width={12}>
                <TextField
                  name="password"
                  label="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  error={fieldError("password")}
                />
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <SaveButton onClick={handleSave} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default TenantAdvancedActions;
