import { useCallback } from "react";

const useBaseFieldSetters = (state, stateMethods, clearError) => {
  const setFieldValue = (e) => {
    const { name, value } = e.target;
    stateMethods.setValue(name, value);
    clearError(name);
  };

  const toggleCheckbox = (e) => {
    const { name, value } = e.target;
    const newValue = !state[name];
    stateMethods.setValue(name, newValue);
    stateMethods.syncView();
  };

  const toggleArrayFieldValue = (arrayId, value) => {
    let newArray = [];
    if (state[arrayId].indexOf(value) > -1) {
      newArray = state[arrayId].filter((v) => v !== value);
    } else {
      newArray = [...state[arrayId], value];
    }
    stateMethods.setValue(arrayId, newArray);
    stateMethods.syncView();
  };

  const arrayFieldHasValue = (arrayId, value) =>
    state[arrayId] && state[arrayId].indexOf(value) > -1 ? true : false;

  const addArrayItem = (arrayId, item) => {
    const newArray = [...state[arrayId]];
    newArray.push(item);
    stateMethods.setValue(arrayId, newArray);
    stateMethods.syncView();
  };

  const deleteArrayItem = (arrayId, index) => {
    const newArray = state[arrayId].filter((_, i) => i !== index);
    stateMethods.setValue(arrayId, newArray);
    stateMethods.syncView();
  };

  const updateArrayItem = (arrayId, index, value) => {
    stateMethods.setValue(state[arrayId][index], value);
  };

  const updateArrayObjectProperty = (arrayId, index, field, value) => {
    stateMethods.setArrayObjectProperty(arrayId, index, field, value);
  };

  return {
    setFieldValue,
    toggleCheckbox,
    toggleArrayFieldValue,
    arrayFieldHasValue,
    addArrayItem,
    updateArrayItem,
    updateArrayObjectProperty,
    deleteArrayItem,
  };
};

export default useBaseFieldSetters;
