import React, { useState, useEffect } from "react";
import { Editor, EditorState, ContentState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

const RichTextEditor = ({
  value,
  name,
  onChange,
  label,
  error,
  description,
  cols,
  rows,
  isRequired = false,
}) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(value ? value : "")
    )
  );
  const [editorError, setEditorError] = useState(error);

  //   const [fieldState, setFieldState] = useState({ value, error });
  // useEffect(() => {
  //   setEditorState(EditorState.createWithContent(value));
  // }, [value, error, name]);
  // const handleOnChange = useCallback(e => {
  //   setFieldState({ value: e.target.value, error: false });
  //   onChange(e);
  // }, []);

  // console.log("es", value);

  useEffect(() => {
    const contentState = stateFromHTML(value);
    const initialEditorState = EditorState.createWithContent(contentState);
    setEditorState(initialEditorState);
    setEditorError(error);
    // if (value && value.length > 0) {
    //   const contentState = stateFromHTML(value);
    //   const initialEditorState = EditorState.createWithContent(contentState);
    //   setEditorState(initialEditorState);
    // } else {
    //   const contentState = stateFromHTML(value);
    //   const initialEditorState = EditorState.createWithContent(contentState);
    //   setEditorState(initialEditorState);
    //   console.log("gerwehrkjhew");
    // }
  }, [value, error, name]);

  const handleStateChange = (state) => {
    setEditorState(state);
    setEditorError(false);
    updateStateDeep();
  };

  const updateStateDeep = () => {
    onChange({
      target: { name, value: stateToHTML(editorState.getCurrentContent()) },
    });
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
    }
    updateStateDeep();
  };

  const handleBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
    updateStateDeep();
  };

  const handleItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
    updateStateDeep();
  };

  const handleUnderlineClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
    updateStateDeep();
  };

  const textAreaCssClass = editorError
    ? "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red "
    : "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";

  return (
    <div className={`sm:col-span-${cols}`}>
      <label
        htmlFor="about"
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {label}
        {isRequired && " *"}
      </label>
      <div className="mt-1 rounded-md shadow-sm"></div>
      <span className="relative z-0 inline-flex shadow-sm mb-2 mt-1">
        <button
          type="button"
          className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          style={{ fontWeight: "bold" }}
          onClick={handleBoldClick}
        >
          B
        </button>

        <button
          type="button"
          className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          style={{ fontStyle: "italic" }}
          onClick={handleItalicClick}
        >
          I
        </button>
        <button
          type="button"
          className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          style={{ textDecoration: "underline" }}
          onClick={handleUnderlineClick}
        >
          U
        </button>
      </span>
      <div
        id={name}
        name={name}
        rows={rows}
        className={textAreaCssClass}
        style={{ minHeight: "200px" }}
        value={value}
      >
        <Editor
          editorState={editorState}
          onChange={handleStateChange}
          handleKeyCommand={handleKeyCommand}
          stripPastedStyles={true}
        ></Editor>
      </div>

      {!editorError && description && (
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      )}
      {editorError && (
        <p className="mt-1 text-sm text-red-600">{editorError}</p>
      )}
    </div>
  );
};

export default RichTextEditor;
