import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allCinemaProducts } from "./../graphql/queries/benefitQueries";
import {
  enableProductsMutation,
  disableProductsMutation,
} from "../graphql/mutations/benefitMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import { CINEMA_PRODUCT_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import {
  ListItemCheckbox,
  PageControls,
  TEditButton,
} from "./../mood-ui/Controls";
import { ColorLabel, Heading } from "./../mood-ui/Typography";
import Search from "./../mood-ui/Search";
import { ItemsNotFound } from "./../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  Pagination,
} from "./../mood-ui/DataTable";
import { ConfirmActionModal } from "../mood-ui/Modals";
import {
  ErrorNotification,
  SuccessNotification,
} from "../mood-ui/Notifications";
import useListSelection from "../hooks/rowSelection";
import useModal from "../hooks/modal";
import useNotification from "../hooks/notifications";
import { TableSelectedActions } from "../moose-ui/DataTable";
import ProductListActions from "../components/list-secondary-actions/benefits/cs/ProductListActions";

const FinalPriceLabel = ({ product: p }) => {
  if (p.final_price > p.rrp_price)
    return <ColorLabel text={p.final_price} color="red" />;

  if (p.single_price < p.final_price && p.final_price <= p.rrp_price)
    return <ColorLabel text={p.final_price} color="blue" />;

  return p.final_price;
};

const ALL_CINEMA_PRODUCTS = gql(allCinemaProducts);
const ENABLE_PRODUCTS = gql(enableProductsMutation);
const DISABLE_PRODUCTS = gql(disableProductsMutation);

const CinemaProductList = () => {
  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Mutation.ManageCinemaSocietyData);

  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    isAllRowsSelected,
    toggleAllRows,
    clearSelection,
  } = useListSelection();

  const { page, limit, orderBy, orderHow, search } = useParams();

  const { search: queryString } = useLocation();

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { data, loading, refetch, error } = useQuery(ALL_CINEMA_PRODUCTS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { nextPage, prevPage, editForm, applySearch } = useBaseNavigation(
    CINEMA_PRODUCT_MODEL_NAME
  );

  const [enableProducts, { loading: enableProductsInProgress }] = useMutation(
    ENABLE_PRODUCTS,
    {
      onCompleted: (_) => {
        refetch();
        displayNotification({
          heading: "Products Enabled",
          message: "Successfully enabled (activated) selected products",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to enable (activate) selected products",
          secondsToDisplay: 2,
        });
      },
    }
  );

  const [disableProducts, { loading: disableProductsInProgress }] = useMutation(
    DISABLE_PRODUCTS,
    {
      onCompleted: (_) => {
        refetch();
        displayNotification({
          heading: "Products Disabled",
          message: "Successfully disabled (deactivated) selected products",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to disable (deactivate) selected products",
          secondsToDisplay: 2,
        });
      },
    }
  );

  const confirmEnable = () => {
    showModal({
      heading: "Confirm Enable (Activation)",
      message: "Are you sure you want to Enable/Activate selected products?",
      mood: "Success",
      confirmButtonLabel: "Enable",
      onConfirm: () => {
        closeModal();
        handleEnableProducts();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleEnableProducts = () => {
    enableProducts({ variables: { ids: selectedRows } });
  };

  const confirmDisable = () => {
    showModal({
      heading: "Confirm Disable (Deactivation)",
      message: "Are you sure you want to Disable/Deactivate selected products?",
      mood: "Warning",
      confirmButtonLabel: "Disable",
      onConfirm: () => {
        closeModal();
        handleDisableProducts();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDisableProducts = () => {
    disableProducts({ variables: { ids: selectedRows } });
  };

  const showUpdateInProgress =
    disableProductsInProgress || enableProductsInProgress;

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-5 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Cinema Products"
              description="Control Cinema Society Products."
            />
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}></Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        <TableContainer tweaks="px-5 pb-5">
          <TableView>
            <THeading>
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() =>
                      toggleAllRows(data?.cinemaProducts?.map((p) => p.id))
                    }
                    isChecked={
                      isAllRowsSelected(data?.cinemaProducts?.map((p) => p.id))
                        ? true
                        : false
                    }
                  />
                </THCol>
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="chain_name" value="Chain" />
                <THCol tweaks="text-left" name="chain_name" value="Venue" />
                <THCol tweaks="text-left" name="sku" value="SKU" />
                <THCol tweaks="text-left" name="title" value="Title" />
                <THCol
                  tweaks="text-left"
                  name="single_price"
                  value="Price, £"
                />
                <THCol
                  tweaks="text-left"
                  name="discount_adjustment"
                  value="Adjustment, £"
                />
                <THCol
                  tweaks="text-left"
                  name="final_price"
                  value="Price for Users, £"
                />
                <THCol
                  tweaks="text-left"
                  name="rrp_price"
                  value="RRP Price, £"
                />
                <THCol tweaks="text-left" name="status" value="Status" />
                <THCol tweaks="w-16 text-center" />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data &&
              data.cinemaProducts &&
              data.cinemaProducts.length === 0 && (
                <tbody className="w-full">
                  <tr>
                    <td colSpan="100%">
                      <ItemsNotFound
                        text="No Cinema products found"
                        tweaks=""
                      />
                    </td>
                  </tr>
                </tbody>
              )}
            {data && data.cinemaProducts && data.cinemaProducts.length > 0 && (
              <TContent>
                {data.cinemaProducts.map((p, rowIndex) => (
                  <TRow key={p.id}>
                    <TCol>
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(p.id)}
                        isChecked={rowSelected(p.id) ? true : false}
                      />
                    </TCol>
                    <TCol value={p.id} />
                    <TCol tweaks="max-w-[180px]">
                      <abbr title={p.chain_name}>{p.chain_name}</abbr>
                    </TCol>
                    <TCol tweaks="max-w-[180px]">
                      <abbr title={p.venue_name}>{p.venue_name}</abbr>
                    </TCol>
                    <TCol tweaks="max-w-[100px]">
                      <abbr title={p.sku}>{p.sku}</abbr>
                    </TCol>
                    <TCol tweaks="max-w-[100px]">
                      <abbr title={p.title}>{p.title}</abbr>
                    </TCol>
                    <TCol tweaks="max-w-[180px]">
                      <abbr title={p.single_price}>{p.single_price}</abbr>
                    </TCol>
                    <TCol tweaks="max-w-[50px]">
                      {p.discount_adjustment > 0 &&
                      p.single_price == p.final_price ? (
                        <ColorLabel text={p.discount_adjustment} color="red" />
                      ) : (
                        p.discount_adjustment
                      )}
                    </TCol>
                    <TCol tweaks="max-w-[180px]">
                      <FinalPriceLabel product={p} />
                    </TCol>
                    <TCol tweaks="max-w-[180px]">
                      {p.rrp_price < p.single_price ? (
                        <ColorLabel text={p.rrp_price} color="red" />
                      ) : (
                        p.rrp_price
                      )}
                    </TCol>
                    <TCol tweaks="max-w-[180px]">
                      {p.status == "Active" && (
                        <ColorLabel text="Active" color="emerald" />
                      )}
                      {p.status == "Disabled" && (
                        <ColorLabel text="Disabled" color="yellow" />
                      )}
                      {p.status == "Draft" && (
                        <ColorLabel text="Draft" color="gray" />
                      )}
                    </TCol>
                    <TCol tweaks="w-16 text-center">
                      <TEditButton onClick={() => editForm(p.id)} />
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <ProductListActions
                      onClearSelection={clearSelection}
                      onEnable={confirmEnable}
                      onDisable={confirmDisable}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={7} extraStyles="">
                {showUpdateInProgress && (
                  <ColorLabel text="Appying changes..." color="blue" />
                )}
              </Col>
              <Col width={3}>
                <Pagination
                  onPrev={page > 1 ? prevPage : false}
                  onNext={
                    data?.cinemaProducts?.length >= limit - 1 ? nextPage : false
                  }
                />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default CinemaProductList;
