export const updateOfferMutation = `mutation updateOffer(
    $id: ID!,
    $merchant_id: Int, 
    $code: String, 
    $title: String, 
    $description: String, 
    $background: String, 
    $image: String, 
    $method: String, 
    $methodIcon: String, 
    $cta: String, 
    $premium_gifted: Boolean, 
    $premium_acquired: Boolean, 
    $basic: Boolean, 
    $public: Boolean, 
    $verb: String, 
    $saving: String, 
    $terms: String, 
    $redeem: String, 
    $redeem_url: String, 
    $redeem_external_url: Boolean, 
    $promocodes_active: Boolean, 
    $related_offers: String, 
    $start_date: Date, 
    $end_date: Date, 
    $status: String, 
    $admin_title: String, 
    $steps: String, 
    $tags: [Int!],
    $exclusionTags: [Int!],
    $labels: [Int!]!,
    $search_terms: String,
    $promocodes_static: Boolean,
    $static_promocode: String,
    $reveal_cta: String,
    $promocode_low_stock_percentage: Int,
    $promocode_low_stock_alert_emails: String
  ) {  
  updateOffer(
        input: {
            id: $id, 
            merchant_id: $merchant_id,
            code: $code, 
            title: $title, 
            description: $description,
            background: $background,
            image: $image,
            method: $method,
            methodIcon: $methodIcon,
            cta: $cta,
            premium_gifted: $premium_gifted,
            premium_acquired: $premium_acquired,
            promocodes_active: $promocodes_active,
            basic: $basic,
            public: $public,
            verb: $verb,
            saving: $saving,
            terms: $terms,
            redeem: $redeem,
            redeem_url: $redeem_url,
            related_offers: $related_offers,
            redeem_external_url: $redeem_external_url,
            start_date: $start_date,
            end_date: $end_date,
            status: $status,
            admin_title: $admin_title,
            steps: $steps,
            tags: $tags,
            exclusionTags: $exclusionTags,
            labels: $labels,
            search_terms: $search_terms,
            static_promocode: $static_promocode,
            promocodes_static: $promocodes_static,
            reveal_cta: $reveal_cta,
            promocode_low_stock_percentage: $promocode_low_stock_percentage,
            promocode_low_stock_alert_emails: $promocode_low_stock_alert_emails
        }
    ) {
      id
    }
  }
  `;

export const createOfferMutation = `mutation createOffer(
    $merchant_id: Int,
    $code: String, 
    $title: String, 
    $description: String, 
    $background: String, 
    $image: String, 
    $method: String, 
    $methodIcon: String, 
    $cta: String, 
    $premium_gifted: Boolean, 
    $premium_acquired: Boolean, 
    $promocodes_active: Boolean, 
    $basic: Boolean, 
    $public: Boolean, 
    $verb: String, 
    $saving: String, 
    $terms: String, 
    $redeem: String, 
    $redeem_url: String, 
    $related_offers: String, 
    $redeem_external_url: Boolean, 
    $start_date: Date, 
    $end_date: Date, 
    $status: String, 
    $admin_title: String, 
    $steps: String, 
    $tags: [Int!],
    $exclusionTags: [Int!],
    $labels: [Int!]!,
    $search_terms: String,
    $promocodes_static: Boolean,
    $static_promocode: String,
    $reveal_cta: String,
    $promocode_file_ids: [Int],
  ) {  
  createOffer(
        input: {
            merchant_id: $merchant_id,
            code: $code, 
            title: $title, 
            description: $description,
            background: $background,
            image: $image,
            method: $method,
            methodIcon: $methodIcon,
            promocodes_active: $promocodes_active,
            cta: $cta,
            premium_gifted: $premium_gifted,
            premium_acquired: $premium_acquired,
            basic: $basic,
            public: $public,
            verb: $verb,
            saving: $saving,
            terms: $terms,
            redeem: $redeem,
            redeem_url: $redeem_url,
            related_offers: $related_offers,
            redeem_external_url: $redeem_external_url,
            start_date: $start_date,
            end_date: $end_date,
            status: $status,
            admin_title: $admin_title,
            steps: $steps,
            tags: $tags,
            exclusionTags: $exclusionTags,
            labels: $labels,
            search_terms: $search_terms,
            promocodes_static: $promocodes_static,
            static_promocode: $static_promocode,
            reveal_cta: $reveal_cta,
            promocode_file_ids: $promocode_file_ids
        }
    ) {
      id
    }
  }
  `;

export const updateSectionMutation = `mutation updateSection(
    $id: ID!, 
    $title: String, 
    $slug: String, 
    $description: String, 
    $offer_ids: String,
    $basic: Boolean,
    $premium_acquired: Boolean,
    $premium_gifted: Boolean,
    $labels: [Int!]!
  ) {  
  updateSection(
     id: $id,
     title: $title,
     slug: $slug,
     description: $description, 
     offer_ids: $offer_ids,
     basic: $basic,      
     premium_gifted: $premium_gifted,
     premium_acquired: $premium_acquired,
     labels: $labels
    ) {
      id
    }
  }
  `;

export const createSectionMutation = `mutation createSection(
    $title: String, 
    $slug: String, 
    $description: String, 
    $offer_ids: String,
    $basic: Boolean,
    $premium_acquired: Boolean,
    $premium_gifted: Boolean,
    $labels: [Int!]!
  ) {  
    createSection(
     title: $title,
     slug: $slug,
     description: $description, 
     offer_ids: $offer_ids,
     basic: $basic,      
     premium_gifted: $premium_gifted,
     premium_acquired: $premium_acquired,
     labels: $labels
    ) {
      id
    }
  }
  `;

export const deleteSectionsMutation = `mutation deleteSections(
    $ids: [Int!]!
  ) {  
    deleteSections(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const updateCinemaChain = `mutation updateCinemaChain(
    $id: ID!, 
    $name: String, 
    $description: String, 
    $discount_adjustment: Float,
    $status: String,
    $image: String
  ) {  
    updateCinemaChain(
     id: $id,
     name: $name,
     description: $description,
     discount_adjustment: $discount_adjustment,
     status: $status,
     image: $image
    ) {
      id
    }
  }
  `;

export const enableChainsMutation = `mutation enableCinemaChains(
    $ids: [Int!]!
  ) {  
    enableCinemaChains(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const disableChainsMutation = `mutation disableCinemaChains(
    $ids: [Int!]!
  ) {  
    disableCinemaChains(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const updateCinemaVenue = `mutation updateCinemaVenue(
    $id: ID!, 
    $status: String    
  ) {  
    updateCinemaVenue(
     id: $id,
     status: $status
    ) {
      id
    }
  }
  `;

export const enableVenuesMutation = `mutation enableCinemaVenues(
    $ids: [Int!]!
  ) {  
    enableCinemaVenues(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const disableVenuesMutation = `mutation disableCinemaVenues(
    $ids: [Int!]!
  ) {  
    disableCinemaVenues(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const updateCinemaProduct = `mutation updateCinemaProduct(
    $id: ID!, 
    $title: String, 
    $description: String, 
    $discount_adjustment: Float,    
    $copy: String,
    $terms: String
    $status: String    
  ) {  
    updateCinemaProduct(
     id: $id,
     title: $title,
     description: $description,
     discount_adjustment: $discount_adjustment,
     copy: $copy,
     terms: $terms,
     status: $status
    ) {
      id
    }
  }
  `;

export const enableProductsMutation = `mutation enableCinemaProducts(
    $ids: [Int!]!
  ) {  
    enableCinemaProducts(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const disableProductsMutation = `mutation disableCinemaProducts(
    $ids: [Int!]!
  ) {  
    disableCinemaProducts(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const deleteOffersMutation = `mutation deleteOffers(
    $ids: [Int!]!
  ) {  
    deleteOffers(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const deleteMerchantsMutation = `mutation deleteMerchants(
    $ids: [Int!]!
  ) {  
    deleteMerchants(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const importOfferPromocodesMutation = `mutation importOfferPromocodes(
    $offerId: Int!, 
    $fileId: Int!
  ) {  
    importOfferPromocodes(
     offerId: $offerId,
     fileId: $fileId
    ) {
      id
      processed
      resolved
      rejected
      action
    }
  }
  `;

export const retireOfferPromocodesMutation = `mutation retireOfferPromocodes(
    $offerId: Int!, 
    $fileId: Int!
  ) {  
    retireOfferPromocodes(
     offerId: $offerId,
     fileId: $fileId
    ) {
      id
      processed
      resolved
      rejected
      action
    }
  }
  `;

export const createMerchantMutation = `mutation createMerchant(
    $title: String, 
    $slug: String, 
    $status: String,
    $description: String,
    $image: String,
    $background: String,
    $related_merchants: String
  ) {  
    createMerchant(
     title: $title,
     slug: $slug,
     status: $status,
     description: $description
     image: $image
     background: $background,
     related_merchants: $related_merchants
    ) {
      id
    }
  }
  `;

export const updateMerchantMutation = `mutation updateMerchant(
    $id: ID!,
    $title: String, 
    $slug: String, 
    $status: String,
    $description: String,
    $image: String,
    $background: String,
    $related_merchants: String
  ) {  
    updateMerchant(
     id: $id,
     title: $title,
     slug: $slug,
     status: $status,
     description: $description,
     image: $image,
     background: $background,
     related_merchants: $related_merchants
    ) {
      id
    }
  }
  `;

export const approveTilloBrandMutation = `mutation approveTilloBrand($slug: String!) {  
    approveTilloBrand(slug: $slug) {
      id
    }
  }
  `;

export const rejectTilloBrandMutation = `mutation rejectTilloBrand($slug: String!) {  
    rejectTilloBrand(slug: $slug) {
      id
    }
  }
  `;

export const updateTilloOfferMutation = `mutation updateTilloOffer(
    $id: ID!,
    $merchant_id: Int, 
    $code: String, 
    $title: String, 
    $description: String, 
    $background: String, 
    $image: String, 
    $method: String, 
    $methodIcon: String, 
    $cta: String, 
    $premium_gifted: Boolean, 
    $premium_acquired: Boolean, 
    $basic: Boolean, 
    $public: Boolean, 
    $verb: String, 
    $saving_diff: Float, 
    $face_value_limit_lower: Float, 
    $face_value_limit_upper: Float, 
    $face_value_minor_unit: Float, 
    $max_cards_per_online: Int, 
    $max_cards_per_instore: Int, 
    $terms: String, 
    $redeem_external_url: Boolean, 
    $related_offers: String, 
    $start_date: Date, 
    $end_date: Date, 
    $status: String, 
    $admin_title: String, 
    $steps: String, 
    $tags: [Int!],
    $exclusionTags: [Int!],
    $labels: [Int!]!,
    $search_terms: String,
  ) {  
    updateTilloOffer(
        input: {
            id: $id, 
            merchant_id: $merchant_id,
            code: $code, 
            title: $title, 
            description: $description,
            background: $background,
            image: $image,
            method: $method,
            methodIcon: $methodIcon,
            cta: $cta,
            premium_gifted: $premium_gifted,
            premium_acquired: $premium_acquired,
            basic: $basic,
            public: $public,
            verb: $verb,
            saving_diff: $saving_diff,
            face_value_limit_lower: $face_value_limit_lower,
            face_value_limit_upper: $face_value_limit_upper,
            face_value_minor_unit: $face_value_minor_unit,
            max_cards_per_online: $max_cards_per_online,
            max_cards_per_instore: $max_cards_per_instore,
            terms: $terms,
            related_offers: $related_offers,
            redeem_external_url: $redeem_external_url,
            start_date: $start_date,
            end_date: $end_date,
            status: $status,
            admin_title: $admin_title,
            steps: $steps,
            tags: $tags,
            exclusionTags: $exclusionTags,
            labels: $labels,
            search_terms: $search_terms,
        }
    ) {
      id
    }
  }
  `;
