import React, { useState, createContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allTagTypes } from "./../graphql/queries/otherQueries";
import {
  DEFAULT_TAG_TYPE_ID,
  DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
} from "../configs/constants";
import { Divider } from "../moose-ui/DataTable";

const ALL_TAG_TYPES = gql(allTagTypes);

export const TagTypeContext = createContext();

const TagTypeProvider = ({ children }) => {
  const [types, setTypes] = useState([]);
  const [activeType, setActiveType] = useState(DEFAULT_TAG_TYPE_ID);
  const [typesReady, setTypesState] = useState(false);
  const onChangeType = (id) => setActiveType(id);

  useQuery(ALL_TAG_TYPES, {
    fetchPolicy: "network-only",
    variables: DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
    onCompleted: (result) => {
      if (!result || !result.tagTypes) return false;
      setTypes([...result.tagTypes]);
      setActiveType(DEFAULT_TAG_TYPE_ID);
      setTypesState(true);
    },
  });
  if (!typesReady)
    return <Divider>Loading App. Initializing Data Context...</Divider>;
  return (
    <React.Fragment>
      <TagTypeContext.Provider value={{ types, activeType, onChangeType }}>
        {children}
      </TagTypeContext.Provider>
    </React.Fragment>
  );
};

export default TagTypeProvider;
