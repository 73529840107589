export const loginMutation = `mutation loginUser($email: String!, $password: String!) {  
    loginUser(input: {email: $email, password: $password}) {
      jwtToken
      name
      email
      role
      success
      expiresAt
    }
  }
  `;

export const logoutMutation = `mutation logoutUser {  
    logoutUser {
      success
    }
  }
  `;
