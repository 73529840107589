import React from "react";

const Tag = ({ tag, onDelete }) => {
  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full font-medium leading-6 bg-indigo-100 text-indigo-800 mr-4 mt-4">
      {tag.title} ({tag.slug})
      <button
        type="button"
        className="flex-shrink-0 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700"
        onClick={() => onDelete(tag.id)}
      >
        <svg
          className="h-2 w-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
};

export default Tag;
