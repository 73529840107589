import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allTenants } from "./../graphql/queries/tenantQueries";
import {
  deleteTenantsMutation,
  activationPushMutation,
} from "../graphql/mutations/tenantMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useListSelection from "./../hooks/rowSelection";
import useNotification from "./../hooks/notifications";
import useTypeFilters from "./../hooks/typeFilters";
import useModal from "./../hooks/modal";
import { TENANT_MODEL_NAME } from "./../configs/constants";
import { ConfirmActionModal } from "./../mood-ui/Modals";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout"; 
import { TEditButton } from "./../mood-ui/Controls";
import { Heading, ItemsNotFound, ColorLabel } from "./../mood-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../mood-ui/Notifications";
import Search from "./../mood-ui/Search";
import { PageControls, ListItemCheckbox } from "./../mood-ui/Controls";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  Pagination, 
} from "./../mood-ui/DataTable";
import TypeFilters from "../mood-ui/filters/zzzTypeFilters";
// import { typeFilters } from "../graphql/queries/typeFilters";
import TenantListActions from "./../components/list-secondary-actions/TenantListActions";
import useItemCounter from "../hooks/itemCounter";
import useDynamicList from "../hooks/dynamicList";

// import Controls from "../mood-ui/filters/zzzControls";
import DynamicLists from "./../mood-ui/filters/DynamicLists";

import DynamicListSaveModal from "../components/dynamic-lists/DynamicListSaveModal";
import useSimpleModal from "../hooks/simpleModal";

const ALL_TENANTS = gql(allTenants);
const DELETE_TENANTS = gql(deleteTenantsMutation);
const ACTIVATION_PUSH = gql(activationPushMutation);

const TenantList = () => {
  const { protectPage, hasAccess, hasAtLeastOnePermission } = usePermissions();
  protectPage(Permissions.Query.Users);

  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    isAllRowsSelected, 
    toggleAllRows, 
    clearSelection,
  } = useListSelection();

  const { page, limit, orderBy, orderHow, search } = useParams();

  const { search: queryString } = useLocation();

  const { modalState, showModal, closeModal } = useModal();

  const {
    isVisible: isVisibleSaveNewModal,
    showModal: showSaveNewModal,
    closeModal: closeSaveNewModal,
  } = useSimpleModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { 
    data, 
    loading, 
    error 
  } = useQuery(ALL_TENANTS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "tenants" });

  const {
    nextPage,
    prevPage,
    editForm,
    createForm,
    applySearch,
    applyFilters,
    resetFilters,
    applySorting,
    getCurrentUrl,
    goTo,
  } = useBaseNavigation(TENANT_MODEL_NAME, { showingItemsCount: itemsTotal });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    resetFilters: onResetFilters,
    removeFilterValue: onRemoveFilterValue,
    applyFilters: onApplyFilters,
    clearFilter: onClearFilter, 
    selectManyFilter: onSelectManyFilter, 
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "tenant",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: queryString,
  });

  const {
    navigateToList,
    activeListId,
    lists,
    dynamicListsAreLoading,
    errorFetchingDynamicLists,
    canSaveList,
    canSaveNewList,
    saveNew,
    save,
  } = useDynamicList("users", {
    onNavigate: goTo,
    onReset: onResetFilters,
    currentUrl: getCurrentUrl,
    onSaveNewSucces: (data) => {
      closeSaveNewModal();
      displayNotification({
        heading: "Dynamic List Created",
        message: "Successfully created new dynamic list",
        secondsToDisplay: 2,
      });
    },
    onSaveNewError: (e) => {
      closeSaveNewModal();
      displayErrorNotification({
        heading: "Dynamic List Creation Error",
        message: "Failed to create new dynamic list",
        secondsToDisplay: 2,
      });
    },
    onSaveSucces: (data) => {
      displayNotification({
        heading: "Dynamic List Saved",
        message: "Successfully saved updates to dynamic list",
        secondsToDisplay: 2,
      });
    },
    onSaveError: (e) => {
      displayErrorNotification({
        heading: "Dynamic List Update Error",
        message: "Failed to update dynamic list",
        secondsToDisplay: 2,
      });
    },
  });

  const [deleteTenants, { loading: deletingTenants }] = useMutation(
    DELETE_TENANTS,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Users Deleted",
          message: "Successfully deleted selected users",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to delete selected users",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.deleteTenants) return false;

        const deletedIds = data.deleteTenants.map((t) => t.id);
        const existingTenants = cache.readQuery({
          query: ALL_TENANTS,
          variables: {
            page,
            limit,
            orderBy,
            orderHow,
            search,
            queryString,
          },
        });
        const newTenants = existingTenants.tenants.filter(
          (t) => deletedIds.indexOf(t.id) == -1
        );
        cache.writeQuery({
          query: ALL_TENANTS,
          variables: {
            page,
            limit,
            orderBy,
            orderHow,
            search,
            queryString,
          },
          data: { tenants: newTenants },
        });
      },
    }
  );

  const [activationPush, { loading: activationPushPending }] = useMutation(
    ACTIVATION_PUSH,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Activation Push Complete",
          message:
            "Successfully applied 'Activation Push' action for selected users",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to apply 'Activation Push' action",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        return false;
      },
    }
  );

  const confirmDeletion = () => {
    showModal({
      heading: "Confirm Deletion",
      message:
        "Are you sure you want to DELETE selected users? Do not proceed if you are not sure.",
      mood: "Danger",
      confirmButtonLabel: "Delete",
      onConfirm: () => {
        closeModal();
        handleDeleteTenants();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const confirmActivationPush = () => {
    showModal({
      heading: "Confirm Activation Push",
      message:
        "Are you sure you want to apply 'Activation Push' (AC action) for the selected users? Note: this action may take some time to complete.",
      mood: "Info",
      confirmButtonLabel: "Apply",
      onConfirm: () => {
        closeModal();
        handleActivationPush();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteTenants = () => {
    deleteTenants({ variables: { ids: selectedRows } });
  };

  const handleActivationPush = () => {
    activationPush({ variables: { ids: selectedRows } });
  };

  if (error || errorFetchingDynamicLists) return <div>Error</div>;

  const activeList = lists.find((l) => l.id === activeListId);

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="sborder-b border-[#dfe2e8] pb-5 mx-5 z-50s">
          {/* <Col width={4}>
            <Heading
              inset={true}
              text="All users"
              description="Manage and monitor Vaboo users using filters. Save
                          a filtered view as a dynamic list for easy access and email
                          alerts."
            />
          </Col> */}
          <Col width={12}>
            {!typeFiltersReady ? (
              <div className="flex flex-wrap justify-end items-start">
                <div className="h-8 inline-flex min-w-[160px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[120px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[100px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[140px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[150px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[140px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[120px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[110px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[125px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[135px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[155px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[155px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
                <div className="h-8 inline-flex min-w-[145px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
              </div>
            ) : (
              <TypeFilters
                data={typeFilters}
                onApply={onApplyFilters}
                onReset={onResetFilters}
                onRemoveValue={onRemoveFilterValue}
                hasActiveFilters={hasActiveFilters}
                onUpdate={onUpdateFilter}
                onClearFilter={onClearFilter}
                onSelectManyFilter={onSelectManyFilter}
                selectedFilters={selectedFilters}
                activeDynamicListId={activeListId}
                dynamicLists={lists}
                onDynamicListChange={navigateToList}
                canSaveList={canSaveList}
                canSaveNewList={canSaveNewList}
                onSaveList={save}
                onSaveNewList={showSaveNewModal}
              />
            )}
          </Col>
        </Row>
        <Row tweaks="border-b border-[#ebedf2] mb-6 py-2 mt-0 mx-5 bg-[#FCFCFE] bg-[rgba(252,252,254,0.9)] z-30s backdrop-blur-sm ">
          <Col width={3} extraStyles="inline py-2 pr-3">
            <Search onApplySearch={applySearch} disabled={!data || (hasActiveFilters && data && data.landlords && data.landlords.length === 0)} />
          </Col>
          <Col width={9} extraStyles="inline">
            {dynamicListsAreLoading ? (
              <div className="flex flex-wrap justify-end items-start pt-2">
                <div className="h-[30px] inline-flex w-[123px] border border-purple-100 ml-1 mb-1">
                  <PageSpinner fill clean />
                </div>
              </div>
              ): (canSaveNewList() || hasActiveFilters || canSaveList() || (lists && lists.length > 0)) ? (
                <div className="flex pl-2 justify-end pt-2 items-center z-50s">
                  {/* <Controls
                    showResetButton={hasActiveFilters}
                    onResetFilters={onResetFilters}
                    canSaveNewList={canSaveNewList()}
                    onSaveNewList={showSaveNewModal}
                    canSaveList={canSaveList}
                    activeList={activeList} 
                    onSaveList={save} 
                  /> */}
                  {lists && lists.length > 0 && (
                    <React.Fragment>
                      <DynamicLists
                        lists={lists}
                        activeListId={activeListId}
                        onChange={navigateToList}
                        activeList={activeList}
                      />
                    </React.Fragment>
                  )}
                </div>
              ) 
              : null
            }
          </Col>
        </Row>
        <TableContainer tweaks="px-5 pb-5 z-40r">
          <TableView tweaks={(!data || (data && data.tenants && data.tenants.length === 0)) ? 'border-[#ebedf2]' : ''}>
            <THeading tweaks={(!data || (data && data.tenants && data.tenants.length === 0)) ? 'opacity-30 border-b' : ''}>
              <TRow>
                <THCol topSticky="-40px" tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() => toggleAllRows(data?.tenants?.map(tenant => tenant.id))}
                    isChecked={isAllRowsSelected(data?.tenants?.map(tenant => tenant.id)) ? true : false}
                  />
                </THCol>
                <THCol topSticky="-40px" tweaks="text-left" name="id" value="ID" />
                <THCol topSticky="-40px" tweaks="text-left" name="name" value={"Name"} />
                <THCol topSticky="-40px" tweaks="text-left" name="email" value={"Email"} />
                <THCol topSticky="-40px" tweaks="text-left" name="access_type" value="Access" />
                <THCol topSticky="-40px" tweaks="text-left" name="status" value="Status" />
                <THCol topSticky="-40px" tweaks="text-left" name="account_state" value="Lifecycle" />
                <THCol topSticky="-40px" tweaks="text-left" name="client" value="Client" />
                <THCol topSticky="-40px" tweaks="w-16 text-center" />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.tenants && data.tenants.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <ItemsNotFound text="No users found" tweaks="" />
                  </td>
                </tr>
              </tbody>
            )}         
            {data && data.tenants && data.tenants.length > 0 && (
              <TContent>
                {data.tenants.map((tenant) => (
                  <TRow key={tenant.id} isSelected={rowSelected(tenant.id)}>
                    <TCol tweaks="w-4 text-center">
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(tenant.id)}
                        isChecked={rowSelected(tenant.id) ? true : false}
                      />
                    </TCol>
                    <TCol value={tenant.id} />
                    <TCol tweaks="max-w-[180px]">
                      <abbr title={tenant.firstname + ' ' + tenant.lastname}>
                      {tenant.firstname} {tenant.lastname}
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[180px]">
                      <abbr title={tenant.email}>
                        {tenant.email}
                      </abbr>
                    </TCol>
                    <TCol>
                      {tenant.access_type == 'Premium Access' ? (
                        <ColorLabel text="Premium" color="purple" />
                      ) : (
                        <ColorLabel text=" " color="gray" />
                      )}
                    </TCol>
                    <TCol>
                      {tenant.activated ? (
                        <ColorLabel text="Active" color="emerald" />
                      ) : (
                        <ColorLabel text="" color="gray" />
                      )}
                    </TCol>
                    <TCol>
                      {tenant.account_state == 'ALIVE' ? (
                        <ColorLabel text="ALIVE" color="emerald" />
                      ) : (
                        <ColorLabel text={tenant.account_state} color="rose" />
                      )}
                    </TCol>
                    <TCol tweaks="max-w-[160px]">
                      <abbr title={tenant.landlord ? tenant.landlord.email : "N/A"}>
                        {tenant.landlord ? tenant.landlord.email : "N/A"}
                      </abbr>
                    </TCol>
                    <TCol tweaks="w-16 text-center">
                      {hasAtLeastOnePermission([
                        Permissions.Query.User,
                        Permissions.Query.Users,
                      ]) && <TEditButton onClick={() => editForm(tenant.id)} />}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <TenantListActions
                      onClearSelection={clearSelection}
                      onDelete={confirmDeletion}
                      onActivationPush={confirmActivationPush}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={7} extraStyles=""></Col>
              <Col width={3}>
                <Pagination onPrev={prevPage} onNext={nextPage} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <DynamicListSaveModal
        isVisible={isVisibleSaveNewModal}
        onSave={saveNew}
        onCancel={closeSaveNewModal}
      />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default TenantList;
