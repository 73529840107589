import React from "react";

const Controls = ({
  onApplyFilters,
  onResetFilters,
  showApplyButton,
  showResetButton,
  show,
  onSaveNewList,
  showSaveAsNewListButton,
}) => {
  return (
    <div className="pl-2">
      {showApplyButton && (
        <button
          type="button"
          className="inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
          onClick={() => onApplyFilters()}
        >
          Apply
        </button>
      )}
      &nbsp;
      {showResetButton && (
        <button
          type="button"
          className="inline-flex items-center px-3 py-1 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
          onClick={() => onResetFilters()}
        >
          Reset
        </button>
      )}
      {showSaveAsNewListButton && (
        <button
          type="button"
          className="ml-3 inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
          onClick={() => onSaveNewList()}
        >
          Save New List
        </button>
      )}
    </div>
  );
};

export default Controls;
