import React from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createQuestionMutation } from "./../graphql/mutations/vabooSurveyMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import { useQuestionFieldSetters } from "./../hooks/vabooHooks";
import { QUESTION_MODEL_NAME, QUESTION_TYPES } from "./../configs/constants";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../moose-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
  AddButton,
  DeleteButton,
} from "./../moose-ui/Controls";
import { Heading, BoxedMessage } from "./../moose-ui/Typography";
import {
  ToggleCheckboxField,
  TextField,
  NumberField,
  SelectField,
} from "./../moose-ui/Fields";
import {
  TableContainer,
  TableView,
  THeading,
  TContent,
  THCol,
  TRow,
  TColBorderless,
} from "./../moose-ui/DataTable";

const CREATE_QUESTION = gql(createQuestionMutation);

const QuestionCreate = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.CreateQuestion);
  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const { goBack } = useBaseNavigation(QUESTION_MODEL_NAME);
  const { id } = useParams();
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    title: "",
    admin_title: "",
    type: "MULTIPLE",
    is_survey_type: true,
    is_profile_type: false,
    answers: [],
  });

  const {
    setFieldValue: handleFieldOnChange,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const {
    handleAddAnswer,
    handleUpdateAnswer,
    handleDeleteAnswer,
    handleUpdateType,
    handleAddDateBracketsAnswer,
    handleUpdateDateBracketsAnswerProperty,
  } = useQuestionFieldSetters(formData, stateMethods);

  const [
    createQuestion,
    { data: createResult, loading: isCreating, error: creationError },
  ] = useMutation(CREATE_QUESTION, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData, answers: JSON.stringify(formData.answers) };
    createQuestion({ variables: data });
  };

  if (!formData) return <div></div>;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="New Question" />
        <FormContainer>
          <FormData>
            <Row>
              <Col width={8}>
                <TextField
                  name="title"
                  label="Question"
                  value={formData.title}
                  onChange={handleFieldOnChange}
                  error={fieldError("title")}
                  isRequired={true}
                />
              </Col>
              <Col width={2}></Col>
              <Col width={2}>
                <SelectField
                  value={formData.type}
                  values={QUESTION_TYPES}
                  label="Type"
                  name="type"
                  onChange={handleUpdateType}
                  valueKey="id"
                  labelKey="name"
                  error={fieldError("type")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                <TextField
                  name="admin_title"
                  label="Admin Title"
                  value={formData.admin_title}
                  onChange={handleFieldOnChange}
                  error={fieldError("admin_title")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={2}>
                <ToggleCheckboxField
                  id="is_survey_type"
                  name="is_survey_type"
                  label="Survey Usage"
                  description="If checked - available for Surveys"
                  isChecked={formData.is_survey_type}
                  onChange={handleToggleCheckbox}
                />
              </Col>
              <Col width={2}>
                <ToggleCheckboxField
                  id="is_profile_type"
                  name="is_profile_type"
                  label="Profile Usage"
                  description="If checked - available for Profiles"
                  isChecked={formData.is_profile_type}
                  onChange={handleToggleCheckbox}
                />
              </Col>
            </Row>
            {["DATE_BRACKETS"].indexOf(formData.type) > -1 && (
              <React.Fragment>
                <Row>
                  <Col width={12}></Col>
                </Row>
                <Row>
                  <Col width={3}>
                    <AddButton
                      label="Add Answer"
                      onClick={handleAddDateBracketsAnswer}
                    />
                  </Col>
                </Row>
                {formData.answers.length > 0 && (
                  <TableContainer>
                    <TableView>
                      <THeading>
                        <TRow>
                          <THCol name="minimum" value="Range (From)" />
                          <THCol name="maximum" value="Range (To)" />
                          <THCol name="title" value="Range (Text)" />
                          <THCol
                            name="weight"
                            value="Computed Range (Weight)"
                          />
                          <THCol />
                        </TRow>
                      </THeading>
                      <TContent>
                        {formData.answers.map((answer, index) => (
                          <TRow key={index}>
                            <TColBorderless>
                              <NumberField
                                value={answer.minimum}
                                onChange={(e) =>
                                  handleUpdateDateBracketsAnswerProperty(
                                    index,
                                    "minimum",
                                    e.target.value
                                  )
                                }
                              />
                            </TColBorderless>
                            <TColBorderless>
                              <NumberField
                                value={answer.maximum}
                                onChange={(e) =>
                                  handleUpdateDateBracketsAnswerProperty(
                                    index,
                                    "maximum",
                                    e.target.value
                                  )
                                }
                              />
                            </TColBorderless>
                            <TColBorderless>
                              <TextField
                                value={answer.title}
                                onChange={(e) =>
                                  handleUpdateDateBracketsAnswerProperty(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                              />
                            </TColBorderless>
                            <TColBorderless>
                              <NumberField
                                value={answer.weight}
                                onChange={(e) =>
                                  handleUpdateDateBracketsAnswerProperty(
                                    index,
                                    "weight",
                                    e.target.value
                                  )
                                }
                              />
                            </TColBorderless>
                            <TColBorderless width="w-20">
                              <DeleteButton
                                onClick={() => handleDeleteAnswer(index)}
                              />
                            </TColBorderless>
                          </TRow>
                        ))}
                      </TContent>
                    </TableView>
                  </TableContainer>
                )}
              </React.Fragment>
            )}
            {[
              "MULTIPLE",
              "MULTIPLE_AND_CUSTOM",
              "SINGLE",
              "SINGLE_AND_CUSTOM",
            ].indexOf(formData.type) > -1 && (
              <React.Fragment>
                <Row>
                  <Col width={12}></Col>
                </Row>
                <Row>
                  <Col width={3}>
                    <AddButton label="Add Answer" onClick={handleAddAnswer} />
                  </Col>
                </Row>
                {formData.answers.length > 0 && (
                  <TableContainer>
                    <TableView>
                      <THeading>
                        <TRow>
                          <THCol name="title" value="Answers" />
                          <THCol />
                        </TRow>
                      </THeading>
                      <TContent>
                        {formData.answers.map((answer, index) => (
                          <TRow key={index}>
                            <TColBorderless>
                              <TextField
                                name={`title_${index}`}
                                value={answer.title}
                                onChange={handleUpdateAnswer}
                              />
                            </TColBorderless>
                            <TColBorderless width="w-20">
                              <DeleteButton
                                onClick={() => handleDeleteAnswer(index)}
                              />
                            </TColBorderless>
                          </TRow>
                        ))}
                      </TContent>
                    </TableView>
                  </TableContainer>
                )}
              </React.Fragment>
            )}
            {["TEXT"].indexOf(formData.type) > -1 && (
              <BoxedMessage
                text="Answers are not available for this type"
                description="When using Text as question type, answers become unavailable."
              />
            )}
            {["RATING"].indexOf(formData.type) > -1 && (
              <React.Fragment>
                <Row>
                  <Col width={3}>
                    <NumberField
                      name="minimum"
                      label={`Minimum Rating Value ${formData.minimum}`}
                      value={formData.minimum}
                      onChange={handleFieldOnChange}
                      error={fieldError("minimum")}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="maximum"
                      label="Maximum Rating Value"
                      value={formData.maximum}
                      onChange={handleFieldOnChange}
                      error={fieldError("maximum")}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="step"
                      label="Rating Step"
                      value={formData.step}
                      onChange={handleFieldOnChange}
                      error={fieldError("step")}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="default"
                      label="Default Value"
                      value={formData.default}
                      onChange={handleFieldOnChange}
                      error={fieldError("default")}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {["YEAR"].indexOf(formData.type) > -1 && (
              <React.Fragment>
                <Row>
                  <Col width={3}>
                    <NumberField
                      name="minimum"
                      label={`Year (From)`}
                      value={formData.minimum}
                      onChange={handleFieldOnChange}
                      error={fieldError("minimum")}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="maximum"
                      label="Year (To)"
                      value={formData.maximum}
                      onChange={handleFieldOnChange}
                      error={fieldError("maximum")}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="step"
                      label="Year Step"
                      value={formData.step}
                      onChange={handleFieldOnChange}
                      error={fieldError("step")}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="default"
                      label="Default Value"
                      value={formData.default}
                      onChange={handleFieldOnChange}
                      error={fieldError("default")}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default QuestionCreate;
