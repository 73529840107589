import React, { useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allQuestions } from "./../graphql/queries/vabooSurveyQueries";
import { publishQuestionsMutation } from "./../graphql/mutations/vabooSurveyMutations"; 
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useListSelection from "./../hooks/rowSelection";
import useNotification from "./../hooks/notifications";
import useModal from "./../hooks/modal";
import { QUESTION_MODEL_NAME } from "./../configs/constants";
import { ConfirmActionModal } from "./../mood-ui/Modals";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import { TEditButton, AddButton, PageControls } from "./../mood-ui/Controls";
import { Heading, ItemsNotFound, ColorLabel } from "./../mood-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../mood-ui/Notifications";
import Search from "./../mood-ui/Search";
import { ListItemCheckbox } from "./../mood-ui/Controls";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  Pagination,
} from "./../mood-ui/DataTable";
import QuestionListActions from "./../components/list-secondary-actions/QuestionListActions";
import useItemCounter from "../hooks/itemCounter";

const ALL_QUESTIONS = gql(allQuestions);
const PUBLISH_QUESTIONS = gql(publishQuestionsMutation);



const Answers = ({ answers }) => {
  return (
    <div 
      className={`
        relative flex items-center justify-center
        w-[20px] h-[18px]
        bg-indigo-100
        pt-0.5
        group font-mono slashed-zero tabular-nums
      `}
    >
      <span className="text-gray-600">{ answers?.length || 0 }</span>
      <div
        className={`
          absolute top-[28px] right-1 z-[999]
          bg-gray-800
          text-[#ffffff] 
          pt-3 pb-2 px-3
          hidden
          group-hover:block
          h-fit 
          font-mono slashed-zero tabular-nums text-[12px] 
        `}
      >
        {
          answers?.map((answer, index) => {
            return (
              <div key={ index } className="flex mb-1">
                { index + 1 }: { answer.title }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}




const QuestionList = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.Questions);
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
  } = useListSelection();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  document.title = 'Questions'

  const { page, limit, orderBy, orderHow, search } = useParams();
  const { search: queryString } = useLocation();
  const { modalState, showModal, closeModal } = useModal();
  const { data, loading, error } = useQuery(ALL_QUESTIONS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });
  const [publishQuestions, { loading: publishingQuestions }] = useMutation(
    PUBLISH_QUESTIONS,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Questions Published",
          message: "Successfully published selected questions",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to publish selected questions",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.excludeOffers) return false;
        updateQuestionStatuses(cache, data, "excludeOffers", true);
      },
    }
  );
  const updateQuestionStatuses = useCallback(
    (cache, data, mutationKey, status) => {
      const qids = data[mutationKey].map((q) => q.id);

      const prevData = cache.readQuery({
        query: ALL_QUESTIONS,
        variables: {
          page,
          limit,
          orderBy,
          orderHow,
          search,
          queryString,
        },
      });

      const updatedQuestions = prevData.questions.map((q) => {
        const updatedQuestion = { ...q };
        if (qids.indexOf(q.id) > -1) {
          updateQuestionStatuses.status = status;
        }
        return updatedQuestion;
      });

      cache.writeQuery({
        query: ALL_QUESTIONS,
        variables: {
          page,
          limit,
          orderBy,
          orderHow,
          search,
          queryString,
        },
        data: { ...prevData, questions: updatedQuestions },
      });
    },
    []
  );

  const { itemsTotal } = useItemCounter(data, { searchKey: "questions" });

  const {
    nextPage,
    prevPage,
    editForm,
    createForm,
    applySearch,
    applySorting,
  } = useBaseNavigation(QUESTION_MODEL_NAME, { showingItemsCount: itemsTotal });

  const confirmPublishing = () => {
    showModal({
      heading: "Confirm Publishing",
      message:
        "Are you sure you want to PUBLISH selected questions? They will become available for usage in surveys, but you will not be able to edit them.",
      mood: "Info",
      confirmButtonLabel: "Publish",
      onConfirm: () => {
        closeModal();
        handlePublishQuestions();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handlePublishQuestions = () => {
    publishQuestions({ variables: { ids: selectedRows } });
  };

  if (error) return <div>Error</div>;
  if (!data) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-5 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Questions"
              description="Control questions used on the platform."
            />
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Mutation.CreateQuestion) && (
              <AddButton label="Add Question" onClick={createForm} loading={loading}  />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row> 
        
        
        
      
        <TableContainer tweaks="px-5 pb-5">
          <TableView>
            <THeading>
              <TRow>
                <THCol tweaks="w-4 text-center" />
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="admin_title" value="Admin Title" />
                <THCol tweaks="text-left" name="title" value="Question" />
                <THCol tweaks="text-left" name="answers" value="Answers" />
                {/* <THCol tweaks="text-left" name="usage_type" value="Usage Type" /> */}
                <THCol tweaks="text-left" name="type" value="Types" />
                <THCol tweaks="text-left" name="status" value="Status" />
                <THCol tweaks="w-16 text-center" />
              </TRow>
            </THeading>

            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.questions && data.questions.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <ItemsNotFound text="No questions found" tweaks="" />
                  </td>
                </tr>
              </tbody>
            )}

          {data && data.questions && data.questions.length > 0 && (
            <TContent>
              {data.questions.map((question) => (
                <TRow key={question.id} isSelected={rowSelected(question.id)}>
                  <TCol>
                    <ListItemCheckbox
                      onChange={() => toggleRowSelection(question.id)}
                      isChecked={rowSelected(question.id) ? true : false}
                    />
                  </TCol>
                  <TCol tweaks="max-w-[30px]" value={question.id} />
                  <TCol tweaks="max-w-[120px]">
                    <abbr title={question.admin_title}>
                      {question.admin_title}
                    </abbr>
                  </TCol>
                  <TCol tweaks="max-w-[240px]">
                    <abbr title={question.title}>
                      {question.title}
                    </abbr>
                  </TCol>
                  
                  <TCol tweaks="max-w-[100px] relative overflow-visible inline-flex items-center" truncate={false} >
                    {
                      question.type == 'TEXT'
                      ? <React.Fragment>
                          <span className="mdi mdi-message-processing pr-[14px] text-pink-400 origin-left scale-[1.22]"></span>
                          <abbr className="truncate pr-1 mr-auto" title="Free text">Free text</abbr> 
                        </React.Fragment>
                      : question.type == 'RATING'
                      ? <React.Fragment>
                          <span className="mdi mdi-star pr-[14px] text-yellow-400 origin-left scale-[1.3]"></span>
                          <abbr className="truncate pr-1 mr-auto" title="Out of 10">Out of 10</abbr> 
                        </React.Fragment>
                      : question.type == 'YEAR'
                      ? <React.Fragment>
                          <span className="mdi mdi-calendar-blank pr-[14px] text-green-500 origin-left scale-[1.4]"></span>
                          <abbr className="truncate pr-1 mr-auto" title="Year picker">Year picker</abbr> 
                        </React.Fragment>
                      : question.type == 'SINGLE'
                      ? <React.Fragment>
                          <span className="mdi mdi-radiobox-marked pr-[14px] text-teal-500 origin-left scale-[1.22]"></span>
                          <abbr className="truncate pr-1 mr-auto" title="Single-choice">Single-choice</abbr> 
                          <Answers answers={question.answers || []} />
                        </React.Fragment>
                      : question.type == 'MULTIPLE'
                      ? <React.Fragment>
                          <span className="mdi mdi-checkbox-multiple-marked-outline pr-[14px] text-indigo-500 origin-left scale-[1.4]"></span>
                          <abbr className="truncate pr-1 mr-auto" title="Multiple-choice">Multiple-choice</abbr> 
                          <Answers answers={question.answers || []} />
                        </React.Fragment>
                      : question.type == 'DATE_BRACKETS'
                      ? <React.Fragment>
                          <span className="mdi mdi-chart-timeline-variant-shimmer pr-[14px] text-fuchsia-500 origin-left scale-[1.4]"></span>
                          <abbr className="truncate pr-1 mr-auto" title="Text-to-datestamp">Text-to-datestamp</abbr> 
                          <Answers answers={question.answers || []} />
                        </React.Fragment>
                      : question.type
                    }
                  </TCol>
                  {/* <TCol tweaks="max-w-[60px]">
                    <abbr title={ question.type }>
                      { question.type }
                    </abbr>
                  </TCol> */}
                  <TCol tweaks="max-w-[70px] text-center">
                    <div className="w-full inline-flex gap-x-[4px]">
                      { question.is_survey_type && 
                          <div className="flex-grow px-1">
                            Survey
                          </div> 
                      }
                      { question.is_profile_type && 
                          <div className="flex-grow px-1">
                            Profile
                          </div> 
                      }
                    </div>
                  </TCol>
                  <TCol tweaks="max-w-[50px]" >
                    {question.status == "Published" ? (
                      <ColorLabel text={question.status} color="green" />
                    ) : (
                      <ColorLabel text={question.status} color="gray" /> 
                    )}
                  </TCol>
                  <TCol tweaks="w-16 text-center">
                    {hasAccess(Permissions.Query.Question) && (
                      <TEditButton onClick={() => editForm(question.id)} />
                    )}
                  </TCol>
                </TRow>
              ))}
            
            </TContent>
          )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <QuestionListActions
                      onClearSelection={clearSelection}
                      onPublish={confirmPublishing}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={7} extraStyles="">
              </Col>
              <Col width={3}>
                <Pagination onPrev={page > 1 ? prevPage : false} onNext={data?.questions?.length >= limit - 1 ? nextPage : false} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default QuestionList;
