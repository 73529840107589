import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { runningACUpdates } from "./../../graphql/queries/reportQueries";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import PageSpinner from "./../../moose-ui/PageSpinner";
import { PageContainer, BlockContainer } from "./../../moose-ui/Layout";
import { Heading, ItemsNotFound } from "./../../moose-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
} from "./../../moose-ui/DataTable";

const RUNNING_AC_UPDATES = gql(runningACUpdates);

const RunningACUpdates = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.RunningActiveCampaignUpdatesReport);
  const { page, limit, orderBy, orderHow, search } = useParams();
  const { search: queryString } = useLocation();
  const { data, error } = useQuery(RUNNING_AC_UPDATES, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    pollInterval: "1000",
    fetchPolicy: "cache-and-network",
  });

  if (error) return <div>Error</div>;
  if (!data) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="Report. Running Active Campaign Updates" />
        {data && data.acPendingUpdates && data.acPendingUpdates.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol name="sync_id" value="Sync ID" />
                  <THCol name="client" value="Client" />
                  <THCol name="user_progress" value="Users Processed" />
                  <THCol name="progress" value="Progress" />
                </TRow>
              </THeading>
              <TContent>
                {data.acPendingUpdates.map((acu) => (
                  <TRow key={acu.id}>
                    <TCol value={acu.id} />
                    <TCol value={acu.landlord.email} />
                    <TCol>
                      {acu.users_processed}/{acu.users_total}
                    </TCol>
                    <TCol>{acu.progress}%</TCol>
                  </TRow>
                ))}
              </TContent>
            </TableView>
          </TableContainer>
        )}
        {data && data.acPendingUpdates && data.acPendingUpdates.length == 0 && (
          <ItemsNotFound
            text="No running Active Campaign Updates."
            description="Systems synced."
          />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default RunningACUpdates;
