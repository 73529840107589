import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { extensions } from "./../../graphql/queries/landlordQueries";
import { updateExtensionsMutation } from "./../../graphql/mutations/landlordMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";
import { FormControls, SaveButton } from "../../mood-ui/Controls";
import { TextField, ToggleCheckboxField } from "../../mood-ui/Fields";
import { Heading } from "../../mood-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../mood-ui/Notifications";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useHyperState from "./../../hooks/hyperState";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "./../../hooks/notifications";

const EXTENSIONS = gql(extensions);
const UPDATE_EXTENSIONS = gql(updateExtensionsMutation);

const LandlordExtensions = () => {
  const { id: account_id } = useParams();
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.Extensions, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: account_id },
  });
  const {
    state: formData,
    setState: setFormData,
    syncView,
    stateMethods,
  } = useHyperState("formData", {
    account_id: 0,
    api_client_users_show_nps: false,
    api_client_users_show_extended_nps: false,
    allow_csv_export: false,
    allow_pdf_report: false,
    allow_pdf_promotion: false,
    notify_about_next_email: false,
    notify_email: "",
  });

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const handleNPSPrimaryChange = (e) => {
    if (
      formData.api_client_users_show_extended_nps &&
      formData.api_client_users_show_nps
    )
      setFormData({ ...formData, api_client_users_show_extended_nps: false });
    handleToggleCheckbox(e);
  };

  const handleNPSExtendedChange = (e) => {
    if (
      !formData.api_client_users_show_extended_nps &&
      !formData.api_client_users_show_nps
    )
      setFormData({ ...formData, api_client_users_show_nps: true });
    handleToggleCheckbox(e);
  };

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { loading, error } = useQuery(EXTENSIONS, {
    variables: {
      account_id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.extensions) return false;
      setFormData({ ...result.extensions });
    },
  });

  const [update, { loading: isUpdating }] = useMutation(UPDATE_EXTENSIONS, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated extensions setup",
        secondsToDisplay: 1,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update extensions setup",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    if (!data.notify_email || data.notify_email.trim().length === 0)
      delete data.notify_email;
    update({ variables: data });
  };

  const [isDraft, setDraft] = useState(false);

  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading inset={true} text="Extensions Setup" />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Configure Client Platform API Extensions"
                      description="Extra features that are available for Client Platform API."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="api_client_users_show_nps"
                      name="api_client_users_show_nps"
                      label="NPS Primary Data Report"
                      description="Return NPS report when returning list of users in Client Platform. Primary data"
                      isChecked={formData?.api_client_users_show_nps}
                      error={fieldError("api_client_users_show_nps")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleNPSPrimaryChange(e);
                      }}
                    />
                  </Col>
                  <Col width={6} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="api_client_users_show_extended_nps"
                      name="api_client_users_show_extended_nps"
                      label="NPS Extended Data Report"
                      description="Return extended (data with label info) NPS report when returning list of users in Client Platform"
                      isChecked={formData?.api_client_users_show_extended_nps}
                      error={fieldError("api_client_users_show_extended_nps")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleNPSExtendedChange(e);
                      }}
                    />
                  </Col>
                  <Col width={6} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="allow_csv_export"
                      name="allow_csv_export"
                      label="Allow CSV Export"
                      description="CSV Export of users"
                      isChecked={formData?.allow_csv_export}
                      error={fieldError("allow_csv_export")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleToggleCheckbox(e);
                      }}
                    />
                  </Col>
                  <Col width={6} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="allow_pdf_report"
                      name="allow_pdf_report"
                      label="Allow PDF Report"
                      description="Ability to generate PDF data report"
                      isChecked={formData?.allow_pdf_report}
                      error={fieldError("allow_pdf_report")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleToggleCheckbox(e);
                      }}
                    />
                  </Col>
                  <Col width={6} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="allow_pdf_promotion"
                      name="allow_pdf_promotion"
                      label="Allow PDF Promotion"
                      description="Configure and generate PDF promotions"
                      isChecked={formData?.allow_pdf_promotion}
                      error={fieldError("allow_pdf_promotion")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleToggleCheckbox(e);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Configure Client Notifications"
                      description="Setup notifications that will be sent to the Clients when an events happen."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="notify_about_next_invoice"
                      name="notify_about_next_invoice"
                      label="Notify about next invoice"
                      description="Send email to the client about next invoice date and amount"
                      isChecked={formData?.notify_about_next_invoice}
                      error={fieldError("notify_about_next_invoice")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        clearError("notify_email");
                        handleToggleCheckbox(e);
                      }}
                    />
                  </Col>
                  <Col width={8} extraStyles={"pt-1.5"}>
                    <TextField
                      loading={loading}
                      name="notify_email"
                      label="Notify email"
                      description="Email address to send notification about next invoice date and amount to"
                      value={formData?.notify_email}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("notify_email")}
                      isRequired={formData?.notify_about_next_invoice}
                      maxlength="42"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            {hasAccess(Permissions.Mutation.UpdateExtensions) && (
              <SaveButton
                onClick={handleSave}
                loading={isUpdating}
                disabled={!isDraft || formHasErrors()}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default LandlordExtensions;
