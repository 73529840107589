import React, { useState, useEffect } from "react";
import useAuthService from "../../hooks/authService";

import useDynamicList from "../../hooks/dynamicList";
import DynamicLists from "../filters/DynamicLists";
import DynamicListsControls from "../filters/DynamicListsControls";
import DynamicListsDetail from '../filters/DynamicListsDetail'
import DynamicListSaveModal from "../../components/dynamic-lists/DynamicListSaveModal";

import useTypeFilters from "../../hooks/typeFilters";
import FiltersList from "../filters/FiltersList";

import Search from "../Search";

import useSimpleModal from "../../hooks/simpleModal";
import { Row, Col } from "../Layout";

const AdvancedTableInterface = ({ 
  columns, 
  selectedColumns, 
  setVisibleCols, 
  resetDefaultVisibleCols, 
  applyFilters, 
  resetFilters, 
  queryString, 
  getCurrentUrl, 
  goTo, 
  displayNotification, 
  displayErrorNotification,
  emitTypeFilters, 
  emitActiveListId, 
  applySearch, 
  hasNoResults, 
  fieldGroups, 
  showSummaries, 
  toggleSummaries,  
}) => { 

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    updateDatesFilter: onUpdateDatesFilter,
    removeDatesFilter: onRemoveDatesFilter, 
    updateRangeFilter: onUpdateRangeFilter,
    removeRangeFilter: onRemoveRangeFilter, 
    resetFilters: onResetFilters,
    // removeFilterValue: onRemoveFilterValue,
    // applyFilters: onApplyFilters,
    clearFilter: onClearFilter,
    clearManyFilter: onClearManyFilter,
    selectManyFilter: onSelectManyFilter,
    isReady: typeFiltersReady, 
  } = useTypeFilters({
    filtersFor: "landlord",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: queryString,
    excludeFilters: [ 'platform_logo_url', 'platform_name', ]
  });

  const { cookies } = useAuthService();

  const [ hasSnapshots, setHasSnapshots ] = useState(false)

  const my_email = localStorage.getItem('vaboo_2023_app_email')

  useEffect(() => {
    if (typeFiltersReady === true) {
      emitTypeFilters(typeFilters)
    }
  }, [typeFiltersReady])

  const {
    navigateToList,
    activeListId,
    lists,
    dynamicListsAreLoading,
    errorFetchingDynamicLists,
    canSaveNewList,
    saveNew,
    canSaveList,
    save,
  } = useDynamicList("clients", {
    onNavigate: goTo,
    onReset: onResetFilters,
    currentUrl: getCurrentUrl,
    onSaveNewSucces: (data) => {
      closeSaveNewModal();
      displayNotification({
        heading: "Dynamic List Created",
        message: "Successfully created new dynamic list",
        secondsToDisplay: 2,
      });
    },
    onSaveNewError: (e) => {
      closeSaveNewModal();
      displayErrorNotification({
        heading: "Dynamic List Creation Error",
        message: "Failed to create new dynamic list",
        secondsToDisplay: 2,
      });
    },
    onSaveSucces: (data) => {
      closeSaveModal();
      displayNotification({
        heading: "Dynamic List Saved",
        message: "Successfully saved updates to dynamic list",
        secondsToDisplay: 2,
      });
    },
    onSaveError: (e) => {
      closeSaveModal();
      displayErrorNotification({
        heading: "Dynamic List Update Error",
        message: "Failed to update dynamic list",
        secondsToDisplay: 2,
      });
    },
  });

  const [ watchActivelistId, setWatchActivelistId ] = useState(activeListId || null)

  useEffect(() => {
    if (activeListId !== watchActivelistId) {
      setHasSnapshots(false)
      setWatchActivelistId(activeListId)
    }
  }, [activeListId])

  const toggleVisibleColumns = (keys = []) => {
    var newVisibleColumns = []
      columns.forEach(col => {
        if (selectedColumns.includes(col.name) ) {
          if (!keys.includes(col.name)) {
            newVisibleColumns.push(col.name) 
          }
        }
        else if (keys.includes(col.name)) { 
          newVisibleColumns.push(col.name) 
        }
      })      
    setVisibleCols(newVisibleColumns);
  };

  const getColumnFilter = (name) => {
    if (typeFilters?.length > 0) {
      return typeFilters.filter(f => f.queryKey === name)[0] || null
    }
    return null
  }

  const {
    isVisible: isVisibleSaveNewModal,
    showModal: showSaveNewModal,
    closeModal: closeSaveNewModal,
  } = useSimpleModal();

  const {
    isVisible: isVisibleSaveModal,
    showModal: showSaveModal,
    closeModal: closeSaveModal,
  } = useSimpleModal();

  useEffect(() => {
    emitActiveListId(activeListId)
  })

  if (errorFetchingDynamicLists) return <div>Error</div>;

  const activeList = lists.find((l) => l.id === activeListId) || null;

  return (
    <React.Fragment>

      <Row tweaks="border-ts border-[#ebedf2] bg-[#FCFCFE]">

        <Col width={12} extraStyles="relative inline-flex">
        
          <div className="inline-flex flex-grow max-w-[660px] w-full">
            {lists && lists.length > 0 && (
              <div className="flex-grow min-w-[200px]  max-w-[330px] mr-[6px]" tabIndex={-1}>
                <DynamicLists
                  lists={lists}
                  activeListId={activeListId}
                  onChange={navigateToList}
                  activeList={activeList}
                  listsTitle="Dynamic lists"
                /> 
              </div>
            )}
            <div className="flex-shrink h-full" tabIndex={-1}>
              {(canSaveNewList() || hasActiveFilters || canSaveList() || (lists && lists.length > 0)) && (
                <DynamicListsControls
                  dynamicListsAreLoading={dynamicListsAreLoading}
                  showResetButton={hasActiveFilters}
                  activeList={activeList} 
                  onResetFilters={onResetFilters}
                  canSaveNewList={canSaveNewList()}
                  onSaveNewList={showSaveNewModal} 
                  canSaveList={canSaveList}
                  onSaveList={showSaveModal} 
                  hasSnapshots={hasSnapshots}
                  hasLists={lists && lists.length > 0}
                />
              )}
            </div>
          </div>

          <div className="relative h-full pl-4 ml-auto w-[240px] max-w-[240px] group/cols " >
            <Search 
              onApplySearch={applySearch} 
              disabled={hasNoResults} 
            />
          </div>
          
          <div className="relative h-full ml-[8px] group/cols w-[30px] max-w-[30px] " >
            <button
              onClick={toggleSummaries}
              className={`
                select-none 
                flex justify-center items-center 
                w-full h-full
                border border-gray-200 focus:outline-none
                text-xs 
                transition-colors duration-75 ease-in-out 
                ${
                  showSummaries
                  ? 'border-[#895f9e] bg-[#A377B8] text-white' 
                  : 'hover:border-gray-200 hover:text-opacity-[80%] hover:bg-[#ffffff] bg-[#FCFCFE] border-gray-150 text-opacity-[70%] text-gray-500 '
                }
              `}
            >
              <span className={`text-[19px] mdi mdi-${ showSummaries ? 'table' : 'table-off' }`}></span>
            </button>
          </div>

          <div className="relative h-full ml-[8px] w-[30px] min-w-[30px] group/cols " >
            <FiltersList 
              columns={columns}
              selectedColumns={selectedColumns}
              selectedFilters={selectedFilters}
              hasActiveFilters={hasActiveFilters}
              activeList={activeList}
              typeFiltersReady={typeFiltersReady}
              toggleVisibleColumns={toggleVisibleColumns}
              resetDefaultVisibleCols={resetDefaultVisibleCols}
              getColumnFilter={getColumnFilter}
              onUpdateFilter={onUpdateFilter}
              onUpdateDatesFilter={onUpdateDatesFilter}
              onRemoveDatesFilter={onRemoveDatesFilter}
              onUpdateRangeFilter={onUpdateRangeFilter}
              onRemoveRangeFilter={onRemoveRangeFilter}
              onClearFilter={onClearFilter}
              onClearManyFilter={onClearManyFilter}
              onSelectManyFilter={onSelectManyFilter}
              fieldGroups={fieldGroups}
            />
          </div>

        </Col>
          
        { activeList && activeList.id &&
          <Col width={12} extraStyles="relative inline-flex justify-end">    
            <DynamicListsDetail 
              dynamicListsAreLoading={dynamicListsAreLoading}
              activeList={activeList}
              onHasSnapshots={setHasSnapshots}
            />
          </Col>
        }

      </Row>

      <DynamicListSaveModal
        isVisible={isVisibleSaveNewModal}
        canEdit={true}
        onSave={saveNew}
        onCancel={closeSaveNewModal}
      />

      { activeList && activeList?.owner && activeList?.owner?.id && activeList?.name && my_email && 
        <DynamicListSaveModal
          name={activeList.name}
          owner={activeList.owner}
          canEdit={activeList.canEdit}
          permissions={activeList.permissions || []}
          isVisible={isVisibleSaveModal}
          onSave={save}
          onCancel={closeSaveModal}
          myEmail={my_email}
          hasSnapshots={hasSnapshots}
        />
      }
      
    </React.Fragment>

  );
};

export default AdvancedTableInterface;