import React from "react";

export const FormControls = ({ children, spaced = false }) => (
  <div className="z-40 py-3 h-[64px] pr-10 pl-9 border-t fixed right-0 left-0 md:left-[160px] bottom-0 bg-[rgba(255,255,255,1)] select-none	">
    <div
      className={
        "flex h-full align-center " +
        (spaced ? "justify-between align-center" : "justify-end")
      }
    >
      {children}
    </div>
  </div>
);

export const PageControls = ({ children, spaced = false }) => (
  <div className="z-40 py-3 h-[64px] pr-10 pl-9 border-t fixed right-0 left-0 md:left-[160px] bottom-0 bg-[rgba(255,255,255,1)] select-none	">
    <div
      className={
        "flex h-full align-center " +
        (spaced ? "justify-between align-center" : "justify-end")
      }
    >
      {children}
    </div>
  </div>
);

export const InlineControls = ({ children, align = "end", tweaks = "" }) => (
  <div className={`select-none flex justify-${align} ${tweaks}`}>
    {children}
  </div>
);

export const StdButton = ({
  children,
  label,
  onClick,
  loading = false,
  tweaks = "",
}) => (
  <span className="inline-flex">
    <button
      type="button"
      className={`
        ${loading ? "pointer-events-none " : ""} 
        inline-flex items-center 
        px-2.5 py-1.5 
        border border-gray-300 
        text-xs leading-4 text-gray-500
        bg-white 
        opacity-90
        hover:opacity-100
        active:opacity-100
        focus:outline-none active:outline-none
        transition ease-in-out duration-150
        ${tweaks}
      `}
      onClick={onClick}
    >
      {label}
    </button>
  </span>
);

export const BasicControl = ({
  onClick,
  label = "Button",
  loading = false,
  block = false,
  tweaks = "",
}) => (
  <span className={"inline-flex select-none " + (block ? " w-full" : "")}>
    <button
      type="button"
      className={`
        ${loading ? "pointer-events-none " : ""} 
        ${block ? " w-full " : ""}
        ${tweaks}
        py-2 px-4 
        border border-[#dfe2e8] 
        text-sm leading-5 text-gray-700 hover:text-gray-500 
        focus:outline-none focus:border-blue-300 focus:shadow-outline-blue 
        transition duration-150 ease-in-out
      `}
      onClick={onClick}
    >
      {loading && (
        <svg className="animate-spin h-5 w-5 mx-1" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
          />
        </svg>
      )}
      {!loading && <span>{label}</span>}
    </button>
  </span>
);

export const SaveButton = ({
  onClick,
  label = "Save",
  loading = false,
  disabled = false,
}) => (
  <span className="ml-3 select-none">
    <button
      // type="submit"
      type="button"
      className="invalid:hidden disabled:opacity-40 disabled:pointer-events-none inline-flex justify-center py-2 px-4 border border-transpsarent text-sm leading-5 font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
      onClick={onClick}
      disabled={disabled}
    >
      {loading && (
        <svg className="animate-spin h-5 w-5 mx-1" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
          />
        </svg>
      )}
      {!loading && <span>{label}</span>}
    </button>
  </span>
);

export const AddButton = ({ onClick, label = "Create Item", icon = false }) => (
  <span className="ml-0 inline-flex select-none">
    {!icon && (
      <button
        type="button"
        className="inline-flex justify-center py-1 px-4 border border-transparent text-sm leading-5 text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
        onClick={onClick}
      >
        {label}
      </button>
    )}
    {icon && (
      <button
        onClick={onClick}
        type="button"
        className="inline-flex bg-transparent justify-center p-1 border border-transparent text-sm text-indigo-600 hover:bg-indigo-50 focus:outline-none transition duration-150 ease-in-out"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </button>
    )}
  </span>
);

export const DeleteButton = ({ onClick, title = "Delete", icon = false }) => (
  <span className="inline-flex content-right select-none">
    {!icon && (
      <button
        onClick={onClick}
        type="button"
        className={`relative inline-flex items-center px-4 py-1 border border-transparent text-sm leading-5 text-white bg-red-600 shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150`}
      >
        {title}
      </button>
    )}
    {icon && (
      <button
        onClick={onClick}
        type="button"
        className="mx-1 relative inline-flex bg-transparent items-center p-1 border border-transparent text-sm text-red-600 hover:bg-red-50 focus:outline-none transition duration-150 ease-in-out"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    )}
  </span>
);

export const CancelButton = ({
  onClick,
  label = "Cancel",
  loading = false,
  disabled = false,
}) => (
  <span className="inline-flex select-none">
    <button
      type="button"
      className="py-2 px-4 border border-gray-300 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
      onClick={onClick}
      disabled={disabled}
    >
      {loading && (
        <svg className="animate-spin h-5 w-5 mx-1" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
          />
        </svg>
      )}
      {!loading && <span>{label}</span>}
    </button>
  </span>
);

export const TEditButton = ({ onClick, label = "Edit" }) => (
  <button
    className="text-indigo-600 hover:text-indigo-900 select-none focus:outline-none"
    onClick={onClick}
  >
    {label}
  </button>
);

export const ListItemCheckbox = ({ onChange, isChecked }) => (
  <input
    type="checkbox"
    className="select-none form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out rounded-none"
    style={{ cursor: "pointer" }}
    onChange={onChange}
    checked={isChecked}
  />
);

export const ItemOrderControl = ({ currentOrder, onOrderUp, onOrderDown }) => (
  <div className="flex align-start items-center text-gray-500 select-none">
    <div className="mr-3 text-sm leading-5 font-medium text-gray-500">
      {currentOrder}
    </div>
    <svg
      style={{ cursor: "pointer" }}
      onClick={onOrderDown}
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31658 11.9024 6.70711 12.2929L9 14.5858L9 3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44772 11 3L11 14.5858L13.2929 12.2929C13.6834 11.9024 14.3166 11.9024 14.7071 12.2929Z"
        fill="currentColor"
      />
    </svg>
    <svg
      className="ml-1"
      style={{ cursor: "pointer" }}
      onClick={onOrderUp}
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 7.70711C4.90237 7.31658 4.90237 6.68342 5.29289 6.29289L9.29289 2.29289C9.68342 1.90237 10.3166 1.90237 10.7071 2.29289L14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711C14.3166 8.09763 13.6834 8.09763 13.2929 7.70711L11 5.41421L11 17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17L9 5.41421L6.70711 7.70711C6.31658 8.09763 5.68342 8.09763 5.29289 7.70711Z"
        fill="currentColor"
      />
    </svg>
  </div>
);

export const FullWHButton = ({ onClick, label }) => (
  <button
    type="button"
    className="select-none text-inherit w-full h-full py-2 px-10 border border-transparent text-sm leading-5 text-gray-500 hover:bg hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
    onClick={onClick}
  >
    {label}
  </button>
);
