import React from "react";
// import Moment from "react-moment";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allBulkOperations } from "./../../graphql/queries/landlordQueries";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useModal from "./../../hooks/modal";
import { BULK_OPERATION_TYPE_MODEL_NAME } from "./../../configs/constants";
// import { ConfirmActionModal } from "./../../mood-ui/Modals";
// import {
//   SuccessNotification,
//   ErrorNotification,
// } from "./../../mood-ui/Notifications";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../mood-ui/Layout";
import { PageControls, TEditButton, AddButton } from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import Search from "./../../mood-ui/Search";
import { ItemsNotFound, ColorLabel } from "./../../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  Pagination,
} from "./../../mood-ui/DataTable";
import useItemCounter from "../../hooks/itemCounter";

const ALL_BULK_OPERATIONS = gql(allBulkOperations);

const TenantBulkOperationList = () => {
  
  const { id: ownerId, page, limit, orderBy, orderHow, search } = useParams();
  
  const { protectPage, hasAccess } = usePermissions();
  
  protectPage(Permissions.Query.ClientBulkOperations, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: ownerId },
  });
  
  // const { search: queryString } = useLocation();
  
  // const { modalState, showModal, closeModal } = useModal();

  const { data, loading, error } = useQuery(ALL_BULK_OPERATIONS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString: `?owner=${ownerId}`,
    },
    fetchPolicy: "cache-and-network",
  });
 
  const { itemsTotal } = useItemCounter(data, { searchKey: "bulkOperations" });

  const { nextPage, prevPage, viewForm, createForm, applySearch } =
    useBaseNavigation(BULK_OPERATION_TYPE_MODEL_NAME, {
      urlContext: `clients/${ownerId}`,
      showingItemsCount: itemsTotal,
    });

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Bulk operations"
              description="Use a CSV file to batch upgrade, downgrade, or delete members of this platform."
            />
          </Col>
        </Row>  
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Mutation.CreateClientBulkOperation) && (
              <AddButton label="Start new operation" onClick={createForm} loading={loading}  />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row> 
        <TableContainer tweaks="px-5 pb-4">
          <TableView tweaks={(loading || !data || (data && data.bulkOperations && data.bulkOperations.length === 0)) ? 'border-[#ebedf2]' : ''}>
            <THeading tweaks={(loading || !data || (data && data.bulkOperations && data.bulkOperations.length === 0)) ? 'opacity-30 border-b' : ''}>
              <TRow>
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="name" value="Name" />
                <THCol tweaks="text-left" name="filename" value="Filename" />
                <THCol tweaks="text-center" name="total" value="Total" />
                <THCol tweaks="text-center" name="applied" value="Pass" />
                <THCol tweaks="text-center" name="unresolved" value="Skip" />
                <THCol tweaks="text-left" name="message" value="Message" />
                <THCol tweaks="text-left" name="status" value="Status" />
                <THCol tweaks="text-left" name="actions" value="" />
              </TRow>
            </THeading>
            {(loading) && (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <PageSpinner inline extraStyles="h-[300px]" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && data && data.bulkOperations && data.bulkOperations.length == 0 && (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <ItemsNotFound text="No previous operations found." tweaks="mx-5" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && data && data.bulkOperations && data.bulkOperations.length > 0 && (
              <TContent>
                {data.bulkOperations.map((i) => (
                  <TRow key={i.id}>
                    <TCol value={i.id} tweaks="font-mono slashed-zero tabular-nums text-xs" />
                    <TCol tweaks="max-w-[170px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={i.name}>
                        {i.name}
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[150px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={i.file.original_name}>
                        {i.file.original_name}
                      </abbr>
                    </TCol>
                    <TCol value={i.total} tweaks="text-center font-mono slashed-zero tabular-nums text-xs" />
                    <TCol value={i.applied} tweaks="text-center font-mono slashed-zero tabular-nums text-xs" />
                    <TCol value={i.unresolved} tweaks="text-center font-mono slashed-zero tabular-nums text-xs" />
                    <TCol tweaks="max-w-[180px] font-mono slashed-zero tabular-nums text-xs">
                      <abbr title={i.message}>
                        {i.message}
                      </abbr>
                    </TCol>
                    <TCol tweaks={`w-[50px] font-mono slashed-zero tabular-nums text-center uppercase text-xs
                      ${
                        ( i.status == 'Complete' )
                        ? ' text-emerald-600 bg-emerald-200'
                        : ' text-rose-500 bg-rose-100'
                      }`
                    }>
                      { i.status } 
                    </TCol>
                    <TCol tweaks="w-16 text-center font-mono slashed-zero tabular-nums text-xs">
                      {hasAccess(Permissions.Query.ClientBulkOperation) && (
                        <TEditButton
                          label="View"
                          onClick={() => viewForm(i.id)}
                        />
                      )}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions></TableSelectedActions>
              </Col>
              <Col width={7} extraStyles="">
              </Col>
              <Col width={3}>
                <Pagination onPrev={prevPage} onNext={nextPage} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default TenantBulkOperationList;
