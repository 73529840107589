import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { cinemaChainDetails } from "./../graphql/queries/benefitQueries";
import { updateCinemaChain } from "./../graphql/mutations/benefitMutations";
import { resizeImageMutation } from "./../graphql/mutations/systemMutations";
import useModal from "./../hooks/modal";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import useNotification from "./../hooks/notifications";
import { CINEMA_CHAIN_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../mood-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "./../mood-ui/Controls";
import {
  ErrorNotification,
  InfoNotification,
  SuccessNotification,
} from "./../mood-ui/Notifications";
import { Heading, InfoMessage } from "./../mood-ui/Typography";
import { SelectField, NumberField, ReadOnlyField } from "./../mood-ui/Fields";

import RichTextEditor from "./../mood-ui/RichTextEditor";
import ImageGalleryModal from "./../mood-ui/ImageGalleryModal";

const CINEMA_CHAIN_DETAILS = gql(cinemaChainDetails);
const UPDATE_CINEMA_CHAIN = gql(updateCinemaChain);
const RESIZE_IMAGE = gql(resizeImageMutation);

const CinemaChain = () => {
  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Mutation.ManageCinemaSocietyData);

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const { goBack } = useBaseNavigation(CINEMA_CHAIN_MODEL_NAME);

  const { id } = useParams();

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    name: "",
    description: "",
    discount_adjustment: 0,
    status: "",
    image: "",
  });

  const { setFieldValue: handleFieldOnChangeProxied } = useBaseFieldSetters(
    formData,
    stateMethods,
    clearError
  );

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const { error } = useQuery(CINEMA_CHAIN_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.cinemaChain) return false;

      setFormData({
        ...result.cinemaChain,
      });
    },
  });

  const [updateChain, { loading: isUpdating }] = useMutation(
    UPDATE_CINEMA_CHAIN,
    {
      variables: { ...formData },
      onCompleted: (result) => {
        goBack();
      },
      onError: (e) => {
        resolveMutationErrors(e);
      },
    }
  );

  const [resizeLogo] = useMutation(RESIZE_IMAGE, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Processing Image",
        message: "Image was successfully resized",
        secondsToDisplay: 1,
      });
      handleFieldOnChange({
        target: {
          name: "image",
          value: result.resizeImage.public_url,
        },
      });
    },
    onError: (e) => {
      console.log("e");
      displayErrorNotification({
        heading: "Processing Image",
        message: "Failed to resize image",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handleImageSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        displayNotification({
          heading: "Processing Image",
          message: "Resizing selected image",
          secondsToDisplay: 1,
        });
        resizeLogo({
          variables: {
            file_id: file.id,
            width: 400,
            height: 400,
          },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
  }, []);

  // const handleImageSelectModal = useCallback(() => {
  //   showModal({
  //     onConfirm: (file) => {
  //       handleFieldOnChange({
  //         target: { name: "image", value: file.public_url },
  //       });
  //       closeModal();
  //     },
  //     onCancel: () => {
  //       closeModal();
  //     },
  //   });
  // }, []);

  const handleSave = () => {
    const data = {
      ...formData,
    };
    if (data.status === "Draft") {
      displayErrorNotification({
        heading: "Status error",
        message:
          "This is a new Cinema chain that was recently pulled from Cinema Society API. You need to change its status to Disabled or Active to save it",
        secondsToDisplay: 5,
      });
      return false;
    }

    updateChain({ variables: data });
  };

  const [_, setDraft] = useState(false);

  if (error) return <div>Error...</div>;
  if (!formData) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update Cinema Society Chain status"
              description=""
            />
          </Col>
        </Row>

        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading size="md" text="Visibility" description="" />
                    <p
                      className={
                        "mt-1 text-sm leading-5 text-gray-400 select-none"
                      }
                    >
                      - Draft. This chain was pulled from the Chain Society API
                      and was not configured yet.
                      <br />
                      - Active. Chain is configred and active - available for
                      users.
                      <br /> - Disabled. Chain is disabled and not available for
                      users.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.status}
                      values={
                        formData.status === "Draft"
                          ? [
                              { id: "Draft", name: "Draft" },
                              { id: "Active", name: "Active" },
                              { id: "Disabled", name: "Disabled" },
                            ]
                          : [
                              { id: "Active", name: "Active" },
                              { id: "Disabled", name: "Disabled" },
                            ]
                      }
                      label="Status"
                      name="status"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      valueKey="id"
                      labelKey="name"
                      isRequired={true}
                      error={fieldError("status")}
                      description=""
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Primary details"
                      description="Primay data about cinema chain like name, discount adjustment that should be applied when selling tickets and description."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles="">
                    <ReadOnlyField
                      name="name"
                      label="Name"
                      value={formData.name}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("name")}
                      isRequired={false}
                      maxlength={96}
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <NumberField
                      name="discount_adjustment"
                      label="Discount adjustment, %"
                      value={formData.discount_adjustment}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("discount_adjustment")}
                      isRequired={false}
                      maxlength={96}
                      description=""
                    />
                  </Col>
                  <Col width={12} extraStyles="">
                    <RichTextEditor
                      cols={5}
                      rows={5}
                      name="description"
                      value={formData.description}
                      label="Description"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("description")}
                      isRequired={false}
                      description="Use (ctrl/cmd) + shift + V to paste text without breaking the formatting"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Artwork"
                      description="Section where you can select an image that should be displayed to the user in the Platform when showing this cinema chain or its venues."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col
                width={8}
                extraStyles="flex items-around align-center flex-wrap select-none relastive"
              >
                <div
                  className={`flex items-censter justify-center relative w-full aspect-[16/7] cursor-pointer relative `}
                >
                  <div
                    className={`h-[55%] aspect-[1/1] border-2 z-40 border-white cursor-pointer absolute top-7 left-7`}
                    onClick={handleImageSelectModal}
                  >
                    <img
                      src={
                        formData.image && formData.image.length > 0
                          ? formData.image
                          : "https://content.vaboo.co.uk/images/20230119_005519_logo-image.png"
                      }
                      alt="Logo"
                      className={`object-fill w-full hover:outline-none hover:shadow-outline peer`}
                      crossOrigin="anonymous"
                      onError={(e) => {
                        e.target.className += " opacity-0";
                      }}
                      onLoad={(e) => {
                        e.target.parentNode.className +=
                          " bg-red-600 outline outline-white outline-1 outline-offset-[-3px]";
                        e.target.className = e.target.className.replace(
                          /opacity-0/g,
                          ""
                        );
                      }}
                    />
                    <span className="absolute text-xs leading-2 top-1/2 -translate-y-1/2 left-0 right-0 p-3 text-center text-red-300 -z-1 invisible peer-[.opacity-0]:visible">
                      There was an error. Please try another image.
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} disabled={isUpdating} />
            {hasAccess(Permissions.Mutation.UpdateTilloOffer) && (
              <SaveButton
                onClick={handleSave}
                loading={isUpdating}
                disabled={formHasErrors()}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <ImageGalleryModal {...modalState} />
      <InfoNotification
        heading={"Update in progress"}
        message={
          "Chain update in progress. It may take a few seconds to update child products. Please, wait..."
        }
        isVisible={isUpdating}
      />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default CinemaChain;
