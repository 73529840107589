import React from "react";
import { ThreeDots } from "react-loader-spinner";

const PageSpinner = ({ opacity = 0 }) => {
  return (
    <div
      className={`flex content-center flex-wrap bg-gray-${opacity} justify-center`}
      style={{ height: "100vh" }}
    >
      <div className="flex-item mb-20">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#00BFFF"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    </div>
  );
};

export default PageSpinner;
