import React from "react";

export const PageContainer = ({ children }) => (
  <main>
    <div className="max-w-8xl mx-auto py-6 sm:px-6 lg:px-0">{children}</div>
  </main>
);

export const BlockContainer = ({ children }) => (
  <div className="max-w-8xl mx-auto py-1 sm:px-6 lg:px-8">{children}</div>
);

export const BlockSectionContainer = ({ children }) => (
  <div className="max-w-8xl mx-auto py-1">{children}</div>
);

export const FormContainer = ({ children }) => (
  <form>
    <div>{children}</div>
  </form>
);

export const FormData = ({ children }) => <div>{children}</div>;

export const Row = ({ children }) => (
  <div className="mt-6 grid grid-cols-1 row-gap-12 col-gap-4 sm:grid-cols-12">
    {children}
  </div>
);

export const Col = ({ children, width = 12, extraStyles = "" }) => (
  <div className={`sm:col-span-${width} ${extraStyles}`}>{children}</div>
);

export const ColExt = ({ children, width = 12, contentAlign = "left" }) => (
  <div className={`sm:col-span-${width}`}>{children}</div>
);

export const Centrify = ({ children }) => <center>{children}</center>;

export const FlexLineup = ({ children }) => (
  <div className="flex content-start flex-wrap mt-6">{children}</div>
);

export const InlineBlock = ({ children }) => (
  <div className="relative inline-block text-left">{children}</div>
);

export const VerticalSpacing = ({ pixels = 22 }) => (
  <div style={{ height: `${pixels}px` }}></div>
);

export const HorizontalSpacing = () => (
  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
);
