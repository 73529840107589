import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { profileSettings } from "./../../graphql/queries/profileQueries";
import { updateProfileSettingsMutation } from "./../../graphql/mutations/profileMutations";
import Permissions, {
  settingsNoAccessFallbackList,
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useHyperState from "./../../hooks/hyperState";
import useDataProvider from "./../../hooks/dataProvider";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useFormErrors from "./../../hooks/formErrors";
import useNotification from "../../hooks/notifications";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../mood-ui/Notifications";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../../mood-ui/Layout";
import { FormControls, SaveButton } from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import {
  SelectField,
  NumberField,
  ToggleCheckboxField,
} from "./../../mood-ui/Fields";
import LabelContextSwitch from "../../mood-ui/LabelContextSwitcher";
import { LabelContext } from "../../contexts/LabelContext";

import ClientOverview from "./../../components/reports/ClientOverview";

const PROFILE_SETTINGS = gql(profileSettings);
const UPDATE_PROFILE_SETTINGS = gql(updateProfileSettingsMutation);

const ProfileSettings = ({entityDetails = null}) => {
  const { id } = useParams();
  const accountId = parseInt(id) > 0 ? parseInt(id) : 0;
  const { protectPage, hasAccess } = usePermissions();
  const requiredPermission =
    accountId > 0
      ? Permissions.Query.ClientProfileQASetup
      : Permissions.Query.ProfileQASetup;
  const requiredMutationPermission =
    accountId > 0
      ? Permissions.Mutation.UpdateClientProfileQASetup
      : Permissions.Mutation.UpdateProfileQASetup;
  const fallbackList =
    accountId > 0 ? landlordNoAccessFallbackList : settingsNoAccessFallbackList;
  protectPage(requiredPermission, {
    noAccessFallbackList: fallbackList,
  });

  const labelContext = useContext(LabelContext);
  const { resolveMutationErrors, clearError } = useFormErrors();
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
    syncView,
  } = useHyperState("formData", {
    account_id: accountId,
    label_id: labelContext.activeLabel,
    is_enabled: false,
    profile_field_settings: [],
  });
  const {
    toggleCheckbox: handleToggleCheckbox,
    updateArrayObjectProperty: handleUpdateArrayObjectProperty,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const [questions] = useDataProvider("questions", {
    filters: { status: ["Published"], usageType: ["Profile"] },
  });

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { loading, error } = useQuery(PROFILE_SETTINGS, {
    variables: {
      account_id: accountId,
      label_id: labelContext.activeLabel,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.profileSettings) return false;
      const profileFieldSettings = result.profileSettings.profile_fields.map(
        (fld) => {
          return {
            field: fld.field,
            is_required: fld.is_required,
            page_num: fld.page_num,
            order_num: fld.order_num,
            question_id: fld.question && fld.question.id ? fld.question.id : 0,
          };
        }
      );
      setFormData({
        ...formData,
        label_id: labelContext.activeLabel,
        is_enabled: result.profileSettings.is_enabled,
        profile_field_settings: profileFieldSettings,
      });
    },
  });

  const [
    updateProfileSettings,
    { data: updateResult, loading: isUpdating, error: updateError },
  ] = useMutation(UPDATE_PROFILE_SETTINGS, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated Profile QA settings",
        secondsToDisplay: 1,
      });
      // clearError("question_ids");
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handleUpdateProfileFieldSettings = (idx, field, value) => {
    handleUpdateArrayObjectProperty(
      "profile_field_settings",
      idx,
      field,
      value
    );
  };

  const handleSave = () => {
    updateProfileSettings({ variables: formData });
  };

  const [ isDraft, setDraft ] = useState(false)

  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b pb-8 mt-0 mb-4 mx-5">
              <Col width={12}>
                <Heading
                  inset={true}
                  text="Profile settings"
                  description="Control the behaviour of profile forms for each member type. 
                              If a question is 'required' and a member has not given an answer, 
                              the profile form will block platform access until they do.
                              Use form sections to define how questions are vertically grouped
                              on the profile form. Use question order to order questions within
                              their form section."
                />
                {
                  (accountId > 0 && entityDetails) && <ClientOverview entityDetails={ entityDetails } groupFilter={[ 'users', 'buy_sell_date', 'demographics' ]} dense />
                }
              </Col>
            </Row>
            <Row tweaks="mx-5">
              <Col width={12} extraStyles="min-h-min pt-4">
                <ToggleCheckboxField
                  loading={loading}
                  id="is_enabled"
                  name="is_enabled"
                  label="Master Switch"
                  wide
                  description="If unchecked, then 'inheritance logic Client-Global, Label-Fallback Label' will take affect."
                  isChecked={formData?.is_enabled}
                  onChange={(e) => { setDraft(d => true); handleToggleCheckbox(e) }}
                />
              </Col>
            </Row>
            <Row tweaks="mt-4 gap-0 px-5 mb-4">
              {formData?.is_enabled &&
              formData?.profile_field_settings.length > 0 &&
              formData?.profile_field_settings.map((fld, idx) => (
                <Col width={12} key={idx} extraStyles="mb-4">
                  <Row>
                    <Col width={5} extraStyles="pt-2">
                      <SelectField
                        loading={loading || !questions}
                        value={fld?.question_id}
                        values={questions}
                        label={fld.field}
                        valueKey="id"
                        labelKey="dropdown_title"
                        onChange={(e) => {
                          handleUpdateProfileFieldSettings(
                            idx,
                            "question_id",
                            e.target.value
                          );
                          setDraft(d => true)
                        }}
                        prependOption="Select Question"
                      />
                    </Col>
                    <Col width={2} extraStyles="pt-2">
                      <SelectField
                        loading={loading || !questions}
                        value={fld?.page_num}
                        values={[ {  valueKey: 1, labelKey: 1 },{ valueKey: 2, labelKey: 2 },{ valueKey: 3, labelKey: 3 }, { valueKey: 4, labelKey: 4 } ] }
                        label="Form Section"
                        valueKey="valueKey"
                        labelKey="labelKey"
                        onChange={(e) => {
                          handleUpdateProfileFieldSettings(
                            idx,
                            "page_num",
                            e.target.value
                          );
                          setDraft(d => true)
                        }}
                        prependOption="Select A Section"
                      />
                    </Col>
                    <Col width={2} extraStyles="pt-2">
                      <NumberField
                        loading={loading || !questions}
                        label="Question Order"
                        value={fld?.order_num}
                        onChange={(e) => {
                          handleUpdateProfileFieldSettings(idx, "order_num", e.target.value);
                          setDraft(d => true)
                        }}
                      />
                    </Col>
                    <Col width={3}>
                      <ToggleCheckboxField
                        loading={loading || !questions}
                        label="Required"
                        description="Decide if users must answer"
                        isChecked={fld?.is_required}
                        onChange={() => {
                          handleUpdateProfileFieldSettings(idx, "is_required", !fld.is_required);
                          syncView();
                          setDraft(d => true)
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </FormData>
          <FormControls spaced={false}>
            <LabelContextSwitch />
            {hasAccess(requiredMutationPermission) && (
              <SaveButton onClick={handleSave} loading={isUpdating} disabled={!isDraft} />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default ProfileSettings;
