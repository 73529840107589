import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createTenantMutation } from "../graphql/mutations/tenantMutations";
import PageSpinner from "./../mood-ui/PageSpinner";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useHyperState from "./../hooks/hyperState";
import useFormErrors from "../hooks/formErrors";
import useBaseNavigation from "../hooks/baseNavigation";
import useDataProvider from "./../hooks/dataProvider"; 
import {
  TENANT_MODEL_NAME,
  TENANT_ACCESS_TYPES,
  GENDERS,
  AGE_BRACKETS,
} from "../configs/constants";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../mood-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "../mood-ui/Controls";
import {
  TextField,
  SelectField,
  ToggleCheckboxField,
} from "../mood-ui/Fields";
import { Heading } from "../mood-ui/Typography";
import { ErrorNotification } from "../mood-ui/Notifications";

const CREATE_TENANT = gql(createTenantMutation);

const TenantCreate = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.CreateUser);
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    landlord_id: 0,
    label_id: 3,
    email: "",
    account_name: "",
    firstname: "",
    lastname: "",
    address: "",
    towncity: "",
    postcode: "",
    telephone: "",
    gender: "",
    locale: "",
    age_range: "",
    access_type: "Basic Access",
    promo_premium_access: false,
  });

  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();
  
  const { goBack } = useBaseNavigation(TENANT_MODEL_NAME);
  
  const [landlords] = useDataProvider("landlords");
  
  const [labels] = useDataProvider("labels");
  
  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleFieldOnChangeProxied(e)
  }

  const [
    create,
    { data: createResult, loading: isCreating, error: createError },
  ] = useMutation(CREATE_TENANT, {
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      console.log("e", e);
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    if (isCreating) return false;
    const data = { ...formData };
    create({ variables: data });
  };

  const [ isDraft, setDraft ] = useState(false)

  if (!formData) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Create a new user"
              description="Fill out this form to create a new user account and 
                          they will receive an activation email immediately."
              />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="User settings"
                      description="Communication and membership information."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="firstname"
                      label="First name"
                      value={formData.firstname}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("firstname")}
                      isRequired={true}
                      minlength={1}
                      maxlength={64}
                      description="--"
                      autocomplete="off"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="lastname"
                      label="Last name"
                      value={formData.lastname}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("lastname")}
                      isRequired={true}
                      minlength={1}
                      maxlength={64}
                      description="--"
                      autocomplete="off"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="email"
                      label="Email"
                      value={formData.email}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("email")}
                      isRequired={true}
                      minlength={1}
                      maxlength={64}
                      description="Must be unique"
                      autocomplete="off"
                    />
                  </Col>
                  <Col width={4}>
                    <SelectField
                      value={formData.label_id}
                      values={labels}
                      label="Label"
                      name="label_id"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      error={fieldError("label_id")}
                      isRequired={true}
                      description=""
                    />
                  </Col>
                  <Col width={4}>
                    <SelectField
                      value={formData.access_type}
                      values={TENANT_ACCESS_TYPES}
                      label="Access Type"
                      name="access_type"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      error={fieldError("access_type")}
                      isRequired={true}
                      description="Fees are client-specific"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>


            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Client settings"
                      description="--"
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.landlord_id}
                      values={landlords}
                      label="Client"
                      name="landlord_id"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="email"
                      prependOption="Select associated client"
                      error={fieldError("landlord_id")}
                      isRequired={true}
                      description="The client this user belongs to"
                    />
                  </Col>
                  <Col width={4}>
                    <ToggleCheckboxField
                      id="promo_premium_access"
                      name="promo_premium_access"
                      label="Promo premium"
                      isChecked={formData.promo_premium_access}
                      onChange={handleToggleCheckbox}
                      description="Don't charge for this user"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

        

            <Row>
              <Col width={2}>
                <SelectField
                  value={formData.gender}
                  values={GENDERS}
                  label="Gender"
                  name="gender"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="name"
                  error={fieldError("gender")}
                />
              </Col>
              <Col width={2}>
                <SelectField
                  value={formData.age_range}
                  values={AGE_BRACKETS}
                  label="Age Range"
                  name="age_range"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="name"
                  error={fieldError("age_range")}
                />
              </Col>
            </Row>
            <Row>
              <Col width={6}>
                <TextField
                  name="address"
                  label="Address"
                  value={formData.address}
                  onChange={handleFieldOnChange}
                  error={fieldError("address")}
                />
              </Col>
              <Col width={2}>
                <TextField
                  name="towncity"
                  label="Towncity"
                  value={formData.towncity}
                  onChange={handleFieldOnChange}
                  error={fieldError("towncity")}
                />
              </Col>
              <Col width={1}>
                <TextField
                  name="postcode"
                  label="Postcode"
                  value={formData.postcode}
                  onChange={handleFieldOnChange}
                  error={fieldError("postcode")}
                />
              </Col>
              <Col width={3}>
                <TextField
                  name="telephone"
                  label="Telephone"
                  value={formData.telephone}
                  onChange={handleFieldOnChange}
                  error={fieldError("telephone")}
                />
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} disabled={!isDraft || formHasErrors()} loading={isCreating} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};


export default TenantCreate;
