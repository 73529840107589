import React, { useState } from "react";
import {
  // useParams,
  // useHistory,
  useLocation,
} from "react-router-dom";

import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/client";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import PageContainer from "../vaboo-ui/PageContainer";

import {
  lightMerchants,
  lightOffers,
  lightTilloOffers,
  // refreshCinemaChains,
} from "../graphql/queries/benefitQueries";

// const isActiveUrl = (location, urlPart) =>
//   location.indexOf(urlPart) > 0 ? true : false;

const ALL_MERCHANTS = gql(lightMerchants);
const ALL_OFFERS = gql(lightOffers);
const ALL_CARDS = gql(lightTilloOffers);
// const REFRESH_CINEMA_CHAINS = gql(refreshCinemaChains);

const Content = (props) => {
  const { hasAccess } = usePermissions();

  // const { id } = useParams();

  const location = useLocation();

  const rootUrl = ``;

  document.title = "Content";

  const page = {
    title: "Content",
    icon: "content",
    nav: [],
  };

  const [merchants, setMerchants] = useState(null);

  const [offers, setOffers] = useState(null);

  const [cards, setCards] = useState(null);

  // const [_] = useQuery(REFRESH_CINEMA_CHAINS);

  // document.title = location.pathname.split('/')[2] === ''        ? 'Content overview'
  //                : location.pathname.split('/')[1] === 'clients' ? 'Explore clients'
  //                : location.pathname.split('/')[1] === 'client'  ? 'Add client'
  //                : 'Content'

  const [getMerchants, { loading: loadingMerchants }] = useLazyQuery(
    ALL_MERCHANTS,
    {
      skip: merchants !== null,
      variables: {
        page: 1,
        limit: 1000,
        search: 0,
        orderBy: 0,
        orderHow: 0,
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (d) => {
        setMerchants(d.merchants);
      },
    }
  );

  const [getOffers, { loading: loadingOffers }] = useLazyQuery(ALL_OFFERS, {
    skip: offers !== null,
    variables: {
      page: 1,
      limit: 1000,
      search: 0,
      orderBy: 0,
      orderHow: 0,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => {
      setOffers(d.offers);
    },
  });
  const [getCards, { loading: loadingCards }] = useLazyQuery(ALL_CARDS, {
    skip: cards !== null,
    variables: {
      page: 1,
      limit: 1000,
      search: 0,
      orderBy: 0,
      orderHow: 0,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => {
      setCards(d.tilloOffers);
    },
  });

  if (!merchants && !loadingMerchants) {
    getMerchants();
  }
  if (!offers && !loadingOffers) {
    getOffers();
  }
  if (!cards && !loadingCards) {
    getCards();
  }

  // if (hasAccess(Permissions.Query.Merchants))
  //   page.nav.push({
  //     name: "Overview",
  //     url: `${rootUrl}/`,
  //     isActive: location.pathname.split('/')[2] === '' ? true : false,
  //     icon: "chart",
  //   });

  if (hasAccess(Permissions.Query.Merchants))
    page.nav.push({
      name: "Merchants",
      url: `${rootUrl}/merchants/1/50/0/0/0`,
      isActive: location.pathname.split("/")[1] === "merchants" ? true : false,
      icon: "merchant",
    });

  if (hasAccess(Permissions.Query.Offers))
    page.nav.push({
      name: "Online offers",
      url: `${rootUrl}/offers/1/50/0/0/0`,
      isActive: ["offer", "offers"].includes(location.pathname.split("/")[1])
        ? true
        : false,
      icon: "ticket",
    });

  if (hasAccess(Permissions.Query.TilloOffers))
    page.nav.push({
      name: "Reward cards",
      url: `${rootUrl}/cards/1/50/0/0/0`,
      isActive: ["card", "cards"].includes(location.pathname.split("/")[1])
        ? true
        : false,
      icon: "creditCard",
    });

  if (hasAccess(Permissions.Mutation.ManageCinemaSocietyData))
    page.nav.push({
      name: "Cinema Chains",
      url: `${rootUrl}/cinema-chains/1/50/0/0/0`,
      isActive: ["cinema-chains"].includes(location.pathname.split("/")[1])
        ? true
        : false,
      icon: "ticket",
    });

  if (hasAccess(Permissions.Mutation.ManageCinemaSocietyData))
    page.nav.push({
      name: "Cinema Venues",
      url: `${rootUrl}/cinema-venues/1/50/0/0/0`,
      isActive: ["cinema-venues"].includes(location.pathname.split("/")[1])
        ? true
        : false,
      icon: "ticket",
    });

  if (hasAccess(Permissions.Mutation.ManageCinemaSocietyData))
    page.nav.push({
      name: "Cinema Products",
      url: `${rootUrl}/cinema-products/1/50/0/0/0`,
      isActive: ["cinema-products"].includes(location.pathname.split("/")[1])
        ? true
        : false,
      icon: "ticket",
    });

  if (hasAccess(Permissions.Query.Sections))
    page.nav.push({
      name: "Sections",
      url: `${rootUrl}/sections/1/50/0/0/0`,
      isActive: location.pathname.split("/")[1] === "sections" ? true : false,
      icon: "collection",
    });

  if (hasAccess(Permissions.Query.Tags))
    page.nav.push({
      name: "Tags",
      url: `${rootUrl}/tags/all`,
      isActive: location.pathname.split("/")[1] === "tags" ? true : false,
      icon: "tag",
    });

  if (hasAccess(Permissions.Mutation.ManageMembershipVouchers))
    page.nav.push({
      name: "Membership Vouchers",
      url: `${rootUrl}/membership-vouchers/1/50/0/0/0`,
      isActive:
        location.pathname.split("/")[1] === "membership-vouchers"
          ? true
          : false,
      icon: "collection",
    });

  // if (hasAccess(Permissions.Query.Images))
  //   page.nav.push({
  //     name: "Images",
  //     url: `${rootUrl}/images/1/50/0/0/0`,
  //     isActive: location.pathname.split('/')[1] === 'images' ? true : false,
  //     icon: "image",
  //   });

  // const navAction =
  //   hasAccess(Permissions.Mutation.LoginAsClient)
  //     ? <BasicControl
  //         block
  //         onClick={handleLoginAs}
  //         label="Login as client"
  //         loading={loginAsPending}
  //         tweaks={`
  //           bg-[#592373]
  //           text-white
  //           border-none
  //           opacity-90
  //           hover:opacity-80
  //           hover:text-white
  //           active:opacity-100
  //           active:text-white
  //           active:bg-[#592373]
  //           select-none
  //         `}
  //       />
  //     : null

  const navHeader = null;

  const navAction = null;

  return (
    <PageContainer
      page={page}
      entityDetails={{ merchants: merchants, offers: offers, cards: cards }}
      {...props}
      navHeader={navHeader}
      navAction={navAction}
    />
  );
};

export default Content;
