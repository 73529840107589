import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  qaSettings,
  publishedSurveys,
} from "./../../graphql/queries/vabooSurveyQueries";
import { updateQASettingsMutation } from "./../../graphql/mutations/vabooSurveyMutations";
import Permissions, {
  settingsNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useHyperState from "./../../hooks/hyperState";
import useDataProvider from "./../../hooks/dataProvider";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useFormErrors from "./../../hooks/formErrors";
import useNotification from "../../hooks/notifications";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../mood-ui/Notifications";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../../mood-ui/Layout";
import { FormControls, SaveButton } from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import {
  TextField,
  SelectField,
  NumberField,
  ToggleCheckboxField,
} from "./../../mood-ui/Fields";
// import { CUSTOM_SURVEY_TYPES } from "./../../configs/constants";
import LabelContextSwitch from "../../mood-ui/LabelContextSwitcher";
import { LabelContext } from "../../contexts/LabelContext";

const QA_SETTINGS = gql(qaSettings);
const UPDATE_QA_SETTINGS = gql(updateQASettingsMutation);

const SettingsSurveys = () => {
  
  const { protectPage, hasAccess } = usePermissions();
  
  protectPage(Permissions.Query.SurveyAndGlobalQuestions, {
    noAccessFallbackList: settingsNoAccessFallbackList,
  });
  
  const labelContext = useContext(LabelContext);
  
  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    owner_id: 0,
    label_id: labelContext.activeLabel,
    activation_survey_id: 0,
    monthly_competition_survey_id: 0,
    barrier_survey_id: 0,
    barrier_survey_is_active: false,
    frequency_survey_id: 0,
    frequency_days: 0,
    infinityQuestionList: "",
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleFieldOnChangeProxied(e)
  }

  const [surveys] = useDataProvider("surveys", {
    filters: { status: ["Published"], reusable: [0] },
  });

  const [reusableSurveys] = useDataProvider("surveys", {
    filters: { status: ["Published"], reusable: [1] },
  });

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { 
    // data, 
    loading, 
    error 
  } = useQuery(QA_SETTINGS, {
    variables: {
      owner_id: 0,
      label_id: labelContext.activeLabel,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.qaSettings) return false;
      setFormData({
        ...result.qaSettings,
        owner_id: 0,
        label_id: labelContext.activeLabel,
      });
    },
  });

  const [
    updateQASettings,
    { 
      // data: updateResult, 
      loading: isUpdating, 
      // error: updateError 
    },
  ] = useMutation(UPDATE_QA_SETTINGS, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated survey settings",
        secondsToDisplay: 1,
      });
      setDraft(d => false)
      clearError("question_ids");
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update",
        secondsToDisplay: 2,
      });
      setDraft(d => false)
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    updateQASettings({ variables: formData });
  };

  const [ isDraft, setDraft ] = useState(false)

  if (error) return <div>Error...</div>;
  // if (loading || !surveys || !publishedSurveys) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <FormContainer>
          <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
            <Col width={12}>
              <Heading
                inset={true}
                text="Global survey settings"
                description="Control the behaviour of surveys for each member type. 
                            These settings can be overwritten for each Client in their settings
                            unless 'impacts all clients' is stated."
                />
            </Col>
          </Row>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Activation survey"
                      description="No longer in use. Activation surveys were replaced by
                                  a profile form and frequency survey. Will remove soon."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles={""}>
                    <SelectField
                      loading={(loading || !surveys || !publishedSurveys)}
                      value={formData?.activation_survey_id}
                      values={surveys?.filter(
                        (s) => s.survey_type.name === "ACTIVATION_SURVEY"
                      )}
                      label="Selected survey"
                      name="activation_survey_id"
                      description="Select a survey from the list"
                      valueKey="id"
                      labelKey="title"
                      onChange={(e) => { handleFieldOnChange(e) }}
                      error={fieldError("activation_survey_id")}
                      prependOption="Select Survey"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Frequency survey"
                      description="Required at activation and whenever X days have passed since last answered.
                                  If a new survey is selected, all members will be forced to complete it."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles={""}>
                    <NumberField
                      loading={loading}
                      name="frequency_days"
                      label="Days to wait"
                      description="Use 0 to disable repeats"
                      value={formData?.frequency_days}
                      min={0}
                      onChange={(e) => { handleFieldOnChange(e) }}
                      error={fieldError("frequency_days")}
                      isRequired={true}
                    />
                  </Col>
                  <Col width={8} extraStyles={""}>
                    <SelectField
                      loading={(loading || !surveys || !publishedSurveys)}
                      value={formData?.frequency_survey_id}
                      values={reusableSurveys?.filter(
                        (s) => s.survey_type.name === "FREQUENCY_SURVEY"
                      )}
                      label="Selected survey"
                      name="frequency_survey_id"
                      description="Select a survey from the list"
                      valueKey="id"
                      labelKey="title"
                      onChange={(e) => { handleFieldOnChange(e) }}
                      error={fieldError("frequency_survey_id")}
                      prependOption="Select Survey"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Barrier survey"
                      description="Blocks member access until completed.
                                  Can be disabled in each client's custom settings."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}> 
                <Row>
                  <Col width={4} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="barrier_survey_is_active"
                      name="barrier_survey_is_active"
                      label="Master Switch"
                      description="Impacts all clients" 
                      isChecked={formData?.barrier_survey_is_active}
                      error={fieldError("barrier_survey_is_active")}
                      onChange={(e) => { setDraft(d => true); handleToggleCheckbox(e) }}
                    />
                  </Col>
                  <Col width={8} extraStyles={"px-0"}>
                    <SelectField
                      loading={(loading || !surveys || !publishedSurveys)}
                      value={formData?.barrier_survey_id}
                      values={surveys?.filter(
                        (s) => s.survey_type.name === "BARRIER_SURVEY"
                      )}
                      label="Selected survey"
                      name="barrier_survey_id"
                      description="Select a survey from the list"
                      valueKey="id"
                      labelKey="title" 
                      onChange={(e) => { handleFieldOnChange(e) }}
                      error={fieldError("barrier_survey_id")}
                      prependOption="Select Survey"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Prize draw survey"
                      description="Asterisks (*) used as question IDs in surveys are replaced
                                  by the next unanswered question in this prioritised list."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4}>
                    <SelectField
                      loading={(loading || !surveys || !publishedSurveys)}
                      value={formData?.monthly_competition_survey_id}
                      values={surveys?.filter(
                        (s) => s.survey_type.name === "MONTHLY_COMPETITION"
                      )}
                      label="Selected survey"
                      description="New surveys are at the bottom"
                      name="monthly_competition_survey_id"
                      valueKey="id"
                      labelKey="title"
                      onChange={(e) => { handleFieldOnChange(e) }}
                      error={fieldError("monthly_competition_survey_id")}
                      prependOption="Select Survey"
                    />
                  </Col>
                  <Col width={8}>
                    <TextField 
                      loading={loading}
                      name="infinityQuestionList"
                      label="Prioritised questions"
                      value={formData?.infinityQuestionList}
                      onChange={(e) => { handleFieldOnChange(e) }}
                      error={fieldError("infinityQuestionList")}
                      description="Get immediate answers by adding to the start"
                      pattern="^([0-9]+)(,\s?[0-9]+)*\s?$"
                      maxlength={1080}
                      tweaks="pr-8"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls spaced={false}>
            <LabelContextSwitch />
            {hasAccess(Permissions.Mutation.UpdateSurveyAndGlobalQuestions) && (
              <SaveButton loading={isUpdating} onClick={handleSave} disabled={!isDraft} />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default SettingsSurveys;
