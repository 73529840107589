const Permissions = {
  Query: {
    Clients: "query.clients",
    Client: "query.client",
    ClientPaymentSettings: "query.clientPaymentSettings",
    ClientPlatform: "query.clientPlatform",
    ClientEmailSettings: "query.clientEmailSettings",
    ClientCustomLinks: "query.clientCustomLinks",
    ClientCustomLink: "query.clientCustomLink",
    ClientExportUsers: "query.clientExportUsers",
    ClientExportDeletedUsers: "query.clientExportDeletedUsers",
    ClientCSVImports: "query.clientCSVImports",
    ClientBulkOperations: "query.clientBulkOperations",
    ClientBulkOperation: "query.clientBulkOperation",
    ClientAPI: "query.clientAPI",
    Extensions: "query.extensions",
    ClientOffers: "query.clientOffers",
    ClientSurveys: "query.clientSurveys",
    ClientUsers: "query.clientUsers",
    ClientProfileQASetup: "query.clientProfileQASetup",
    Users: "query.users",
    User: "query.user",
    UserPaymentSettings: "query.userPaymentSettings",
    UserBenefitsLogs: "query.userBenefitsLogs",
    UserActivityEvents: "query.userActivityEvents",
    ClientInvoices: "query.clientInvoices",
    ClientInvoice: "query.clientInvoice",
    UserInvoices: "query.userInvoices",
    UserInvoice: "query.userInvoice",
    Logins: "query.logins",
    Offers: "query.offers",
    Offer: "query.offer",
    Merchants: "query.merchants",
    Merchant: "query.merchant",
    Sections: "query.sections",
    Section: "query.section",
    Tags: "query.tags",
    Images: "query.images",
    Surveys: "query.surveys",
    Survey: "query.survey",
    Questions: "query.questions",
    Question: "query.question",
    ProfileFieldCompletionsReport: "query.profileFieldCompletionsReport",
    ProfileFieldAnswerShareReport: "query.profileFieldAnswerShareReport",
    InvoiceReport: "query.invoiceReport",
    TenantQAFlowReport: "query.tenantQAFlowReport",
    ActivityEventsReport: "query.userActivityEventsFlowReport",
    UserOrdersReport: "query.orders",
    PassportsReport: "query.passports",
    CinemaOrdersReport: "query.cinemaOrders",
    RunningActiveCampaignUpdatesReport:
      "query.runningActiveCampaignUpdatesReport",
    UserActivityEventsFlowReport: "query.userActivityEventsFlowReport",
    TenantQAStatsReport: "query.tenantQAStatsReport",
    PrimaryKPIReport: "query.primaryKPIReport",
    MainAppSettings: "query.mainAppSettings",
    SurveyAndGlobalQuestions: "query.surveyAndGlobalQuestions",
    ProfileQASetup: "query.profileQASetup",
    SystemUsers: "query.systemUsers",
    SystemUser: "query.systemUser",
    Roles: "query.roles",
    Role: "query.role",
    TilloOffers: "query.tilloOffers",
    TilloOffer: "query.tilloOffer",
    TilloBrands: "query.tilloPendingBrands",
    TilloBrand: "query.tilloPendingBrands",
    CheckOrderState: "query.checkOrderState",
  },
  Mutation: {
    CreateClient: "mutation.createClient",
    UpdateClient: "mutation.updateClient",
    LoginAsClient: "mutation.loginAsClient",
    UpdateClientPaymentSettings: "mutation.updateClientPaymentSettings",
    UpdateClientPlatform: "mutation.updateClientPlatform",
    UpdateClientEmailSettings: "mutation.updateClientEmailSettings",
    ManageCustomLinks: "mutation.manageCustomLinks",
    CreateClientCSVImport: "mutation.createClientCSVImport",
    CreateClientBulkOperation: "mutation.createClientBulkOperation",
    UpdateClientAPI: "mutation.updateClientAPI",
    UpdateExtensions: "mutation.updateExtensions",
    UpdateClientOffers: "mutation.updateClientOffers",
    UpdateClientSurveys: "mutation.updateClientSurveys",
    UpdateClientProfileQASetup: "mutation.updateClientProfileQASetup",
    CreateUser: "mutation.createUser",
    UpdateUser: "mutation.updateUser",
    DeleteUser: "mutation.deleteUser",
    LoginAsUser: "mutation.loginAsUser",
    SyncUserWithAC: "mutation.syncUserWithAC",
    ActivationPush: "mutation.activationPush",
    UpdateUserPaymentSettings: "mutation.updateUserPaymentSettings",
    UpdateClientPassword: "mutation.updateClientPassword",
    UpdateUserPassword: "mutation.updateUserPassword",
    CreateClientInvoice: "mutation.createClientInvoice",
    UpdateClientInvoice: "mutation.updateClientInvoice",
    DeleteClientInvoice: "mutation.deleteClientInvoice",
    CreateUserInvoice: "mutation.createUserInvoice",
    UpdateUserInvoice: "mutation.updateUserInvoice",
    DeleteUserInvoice: "mutation.deleteUserInvoice",
    CreateOffer: "mutation.createOffer",
    UpdateOffer: "mutation.updateOffer",
    DeleteOffer: "mutation.deleteOffer",
    CreateMerchant: "mutation.createMerchant",
    UpdateMerchant: "mutation.updateMerchant",
    DeleteMerchant: "mutation.deleteMerchant",
    CreateSection: "mutation.createSection",
    UpdateSection: "mutation.updateSection",
    DeleteSection: "mutation.deleteSection",
    CreateTag: "mutation.createTag",
    DeleteTag: "mutation.deleteTag",
    UploadImage: "mutation.uploadImage",
    CreateSurvey: "mutation.createSurvey",
    UpdateSurvey: "mutation.updateSurvey",
    PublishSurvey: "mutation.publishSurvey",
    CreateQuestion: "mutation.createQuestion",
    UpdateQuestion: "mutation.updateQuestion",
    PublishQuestion: "mutation.publishQuestion",
    UpdateMainAppSettings: "mutation.updateMainAppSettings",
    UpdateProfileQASetup: "mutation.updateProfileQASetup",
    UpdateSurveyAndGlobalQuestions: "mutation.updateSurveyAndGlobalQuestions",
    CreateSystemUser: "mutation.createSystemUser",
    UpdateSystemUser: "mutation.updateSystemUser",
    CreateRole: "mutation.createRole",
    UpdateRole: "mutation.updateRole",
    DeleteRole: "mutation.deleteRole",
    GenerateAccessCode: "mutation.generateAccessCode",
    ChargeNowClient: "mutation.chargeNowClient",
    ChargeNowUser: "mutation.chargeNowUser",
    UpdateTilloOffer: "mutation.updateTilloOffer",
    DeleteTilloOffer: "mutation.deleteTilloOffer",
    ApproveTilloBrand: "mutation.approveTilloBrand",
    RejectTilloBrand: "mutation.rejectTilloBrand",
    UpdateTilloOfferDiscountAdjustment:
      "mutation.updateTilloOfferDiscountAdjustment",
    DeleteOrder: "mutation.deleteOrder",
    PlaceOrderRaw: "mutation.placeOrderRaw",
    ManageDirectRegisterLinks: "mutation.manageDirectRegisterLinks",
    ManageCinemaSocietyData: "mutation.manageCinemaTickets",
    ManageMembershipVouchers: "mutation.manageMembershipVouchers",
  },
};

export const settingsNoAccessFallbackList = [
  {
    permission: Permissions.Query.MainAppSettings,
    url: "/settings",
  },
  {
    permission: Permissions.Query.SurveyAndGlobalQuestions,
    url: "/settings/surveys",
  },
  {
    permission: Permissions.Query.ProfileQASetup,
    url: "/settings/profile-qa-setup",
  },
  {
    permission: Permissions.Query.SystemUsers,
    url: "/settings/control-application-users/1/50/0/0/0",
  },
  {
    permission: Permissions.Query.Roles,
    url: "/settings/roles/1/50/0/0/0",
  },
];

export const tenantNoAccessFallbackList = [
  {
    permission: Permissions.Query.User,
    url: "/users/:id",
  },
  {
    permission: Permissions.Mutation.UpdateUserPassword,
    url: "/users/:id/password",
  },
  {
    permission: Permissions.Query.UserPaymentSettings,
    url: "/users/:id/payment-settings",
  },
  {
    permission: Permissions.Query.UserInvoices,
    url: "/users/:id/invoices/1/50/0/0/0",
  },
  {
    permission: Permissions.Query.Logins,
    url: "/users/:id/logins/1/50/0/0/0",
  },
  {
    permission: Permissions.Query.UserBenefitsLogs,
    url: "/users/:id/benefit-logs/1/50/0/0/0",
  },
  {
    permission: Permissions.Query.UserActivityEvents,
    url: "/users/:id/activity-events/1/50/0/0/0",
  },
];

export const landlordNoAccessFallbackList = [
  {
    permission: Permissions.Query.Client,
    url: "/clients/:id",
  },
  {
    permission: Permissions.Mutation.UpdateClientPassword,
    url: "/clients/:id/password",
  },
  {
    permission: Permissions.Query.ClientPaymentSettings,
    url: "/clients/:id/payment-settings",
  },
  {
    permission: Permissions.Query.ClientPlatform,
    url: "/clients/:id/platform",
  },
  {
    permission: Permissions.Query.ClientEmailSettings,
    url: "/clients/:id/emails",
  },
  {
    permission: Permissions.Query.ClientCustomLinks,
    url: "/clients/:id/custom-links/1/50/0/0/0",
  },
  {
    permission: Permissions.Query.ClientUsers,
    url: "/clients/:id/users",
  },
  {
    permission: Permissions.Query.ClientInvoices,
    url: "/clients/:id/invoices/1/50/0/0/0",
  },
  {
    permission: Permissions.Query.Logins,
    url: "/clients/:id/logins/1/50/0/0/0",
  },
  {
    permission: Permissions.Query.ClientCSVImports,
    url: "/clients/:id/imports/1/50/0/0/0",
  },
  {
    permission: Permissions.Query.ClientBulkOperations,
    url: "/clients/:id/bulk-operations/1/50/0/0/0",
  },
  {
    permission: Permissions.Query.ClientAPI,
    url: "/clients/:id/api",
  },
  {
    permission: Permissions.Query.ClientOffers,
    url: "/clients/:id/offers",
  },
  {
    permission: Permissions.Query.ClientSurveys,
    url: "/clients/:id/surveys",
  },
  {
    permission: Permissions.Query.ClientProfileQASetup,
    url: "/clients/:id/profile-qa-setup",
  },
];

export default Permissions;
