import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { updatePasswordMutation } from "./../../graphql/mutations/accountSharedMutations";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";
import { FormControls, SaveButton } from "../../mood-ui/Controls";
import { TextField } from "../../mood-ui/Fields";
import { Heading } from "../../mood-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../mood-ui/Notifications";

import useFormErrors from "./../../hooks/formErrors";
import useNotification from "./../../hooks/notifications";

const UPDATE_PASSWORD = gql(updatePasswordMutation);

const VabooUserPassword = () => {
  const { id } = useParams();
  const [password, setPassword] = useState("");

  const { formHasErrors, setFieldError, fieldError, resolveMutationErrors, clearError } = useFormErrors();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const [
    update,
    { data: updateResult, loading: isUpdating, error: updateError },
  ] = useMutation(UPDATE_PASSWORD, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated password",
        secondsToDisplay: 1,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update password",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handlePasswordChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    setPassword(e.target.value);
  }

  const handleSave = () => {
    update({ variables: { account_id: id, password } });
  };

  const [ isDraft, setDraft ] = useState(false)

  return (
    <PageContainer>
      <BlockContainer raised>
        <FormContainer>
          <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
            <Col width={12}>
              <Heading
                inset={true}
                text="Account password"
                description="Reset the password used to access this account.
                            This cannot be undone and should be used with caution."
                />
            </Col>
          </Row>
          <FormData>
            <Row tweaks="border-[#dfe2e8] pt-7 pb-5 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Password reset"
                      description="This should only be used as a temporary solution and you must advise 
                                    the account owner to change it immediately."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles="">
                    <TextField
                      mode="password"
                      name="password"
                      label="Set a new password"
                      value={password}
                      onChange={(e) => { setDraft(true); handlePasswordChange(e); }}
                      error={fieldError("password")}
                      isRequired={true}
                      minlength={7}
                      maxlength={18}
                      description="Use with caution"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <SaveButton onClick={handleSave} disabled={!isDraft || formHasErrors()} loading={isUpdating} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default VabooUserPassword;
