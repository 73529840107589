import { useState } from "react";
import useHyperState from "./hyperState";

const useFormErrors = () => {
  const {
    state: formErrors,
    setState: setFormErrors,
    deleteValue
  } = useHyperState("formErrors", {});

  // const [formErrors, setFormErrors] = useState({});
  const fieldError = field =>
    formErrors && formErrors[field] ? formErrors[field] : false;

  const setFieldError = (field, error) => {
    const newErrors = { ...formErrors };
    newErrors[field] = error;
    setFormErrors(newErrors);
  };

  const clearErrors = () => {
    setFormErrors({});
  };

  const clearError = field => {
    if (!fieldError(field)) return false;
    deleteValue(field);
    // const newErrors = { ...formErrors };
    // deleteValue(field);
    // delete newErrors[field];
    // setFormErrors(newErrors);
    // console.log("updated errors", formErrors);
  };

  const formHasErrors = () =>
    Object.keys(formErrors).length > 0 ? true : false;

  const extractArrayOfErrors = e => {
    return e.graphQLErrors &&
      e.graphQLErrors[0] &&
      e.graphQLErrors[0].extensions &&
      e.graphQLErrors[0].extensions.validation
      ? e.graphQLErrors[0].extensions.validation
      : false;
  };

  const resolveMutationErrors = e => {
    const errors = extractArrayOfErrors(e);
    if (errors) {
      const newErrors = {};
      const keys = Object.keys(errors);
      keys.map(key => {
        const validKey = key.replace(/input./, "");
        const validErrorMessage = errors[key][0].replace(/input./, "");
        newErrors[validKey] = validErrorMessage;
      });
      setFormErrors(newErrors);
    } else {
      clearErrors();
    }
  };

  return {
    formErrors,
    setFormErrors,
    setFieldError,
    fieldError,
    formHasErrors,
    clearErrors,
    clearError,
    resolveMutationErrors
  };
};

// const useFormErrors = () => {
//   const [formErrors, setFormErrors] = useState({});
//   const fieldError = (field) => (formErrors[field] ? formErrors[field] : false);

//   const setFieldError = (field, error) => {
//     const newErrors = { ...formErrors };
//     newErrors[field] = error;
//     setFormErrors(newErrors);
//   };

//   const clearErrors = () => {
//     setFormErrors({});
//   };

//   const clearError = (field) => {
//     if (!fieldError(field)) return false;
//     const newErrors = { ...formErrors };
//     delete newErrors[field];
//     setFormErrors(newErrors);
//   };

//   const formHasErrors = () =>
//     Object.keys(formErrors).length > 0 ? true : false;

//   const extractArrayOfErrors = (e) => {
//     return e.graphQLErrors &&
//       e.graphQLErrors[0] &&
//       e.graphQLErrors[0].extensions &&
//       e.graphQLErrors[0].extensions.validation
//       ? e.graphQLErrors[0].extensions.validation
//       : false;
//   };

//   const resolveMutationErrors = (e) => {
//     const errors = extractArrayOfErrors(e);
//     if (errors) {
//       const newErrors = {};
//       const keys = Object.keys(errors);
//       keys.map((key) => {
//         const validKey = key.replace(/input./, "");
//         const validErrorMessage = errors[key][0].replace(/input./, "");
//         newErrors[validKey] = validErrorMessage;
//       });
//       setFormErrors(newErrors);
//     } else {
//       clearErrors();
//     }
//   };

//   return {
//     formErrors,
//     setFormErrors,
//     setFieldError,
//     fieldError,
//     formHasErrors,
//     clearErrors,
//     clearError,
//     resolveMutationErrors,
//   };
// };

export default useFormErrors;
