import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { passport } from "./../../graphql/queries/reportQueries";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import { REPORTS_PASSPORTS_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import { PageContainer, BlockContainer } from "./../../moose-ui/Layout";
import {
  FormControls,
  CancelButton,
  TEditButton,
} from "./../../moose-ui/Controls";
import { Heading, ColorLabel } from "./../../moose-ui/Typography";

import {
  TableContainer,
  TableView,
  THeading,
  TContent,
  THCol,
  TRow,
  TCol,
} from "./../../moose-ui/DataTable";
import { useState } from "react";
import {
  FieldBlock,
  renderObject,
  renderLink,
  renderList,
  renderBoolean,
  renderOrderStatus,
  SpaceBlock,
  renderOrderPaymentStatus,
  renderOrderState,
} from "../TilloBrandListApprovalPage";
import { render } from "@testing-library/react";

const PASSPORT_DETAILS = gql(passport);

const InfoSection = ({ children, title = "", description = "" }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-7">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1  text-sm text-gray-500">{description}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
          {children}
        </dl>
      </div>
    </div>
  );
};

const TableSection = ({ children, title = "", description = "" }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-7">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1  text-sm text-gray-500">{description}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
          {children}
        </dl>
      </div>
    </div>
  );
};

const Passport = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.UserOrdersReport);
  const { goBack, goTo } = useBaseNavigation(REPORTS_PASSPORTS_MODEL_NAME);
  const { id } = useParams();
  const [passport, setPassport] = useState(false);

  const { loading, error } = useQuery(PASSPORT_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      setPassport({ ...res.passport });
    },
  });

  if (error) return <div>Error...</div>;
  if (!passport) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="Passport" />
        <InfoSection
          title="User & Client"
          description="Brief informantion about user"
        >
          <FieldBlock label="User Id" value={(passport.user_id == passport.origin_user_id) ? passport.user_id : `${passport.user_id} (origin_id: ${passport.origin_user_id})`} />
          <FieldBlock label="User Email" value={passport.email} />
          <FieldBlock
            label="Full Name"
            value={`${passport.firstname} ${passport.lastname}`}
          />
          <FieldBlock label="Telephone" value={`${passport.telephone}`} />
          <FieldBlock label="Client Id" value={passport.user.landlord.id} />
          <FieldBlock label="Client Email" value={passport.user.landlord.email} />
          <FieldBlock label="Account Name" value={passport.user.landlord.account_name} />
        </InfoSection>

        <TableSection
          title="Email Updates by this User"
          description="List of other emails that are related to this user"
        >
          {(passport.emails.length < 2) && (
            <span>This user has no other emails in the history of udpates</span>
          )}
          {passport.emails.length > 1 && (
            <TableContainer>
              <TableView>
                <THeading>
                  <TRow>
                    <THCol name="from_email" value="From Email" />
                    <THCol name="to_email" value="To Email" />
                    <THCol name="created_at" value="Date" />
                  </TRow>
                </THeading>
                <TContent>
                  {passport.emails.map((email, idx) => (
                    <TRow key={idx}>
                      <TCol value={email.from_email ? email.from_email : '_initital_'} />
                      <TCol value={email.to_email} />
                      <TCol value={email.created_at} />
                    </TRow>
                  ))}
                </TContent>
              </TableView>
            </TableContainer>
          )}
        </TableSection>

        <TableSection
          title="User Client Relations"
          description="List of other clients with appearance of this user"
        >
          {(passport.clients.length < 2) && (
            <span>This user has no other clients relations</span>
          )}
          {passport.clients.length > 1 && (
            <TableContainer>
              <TableView>
                <THeading>
                  <TRow>
                    <THCol name="from_client" value="From Client" />
                    <THCol name="to_client" value="To Client" />
                    <THCol name="created_at" value="Date" />
                  </TRow>
                </THeading>
                <TContent>
                  {passport.clients.map((client, idx) => (
                    <TRow key={idx}>
                      <TCol value={client.from_client_id ? client.from_client_id : '_initital_'} />
                      <TCol>{client.to_client_id} ({client.to_client.account_name})</TCol>
                      <TCol value={client.created_at} />
                    </TRow>
                  ))}
                </TContent>
              </TableView>
            </TableContainer>
          )}
        </TableSection>

        <TableSection
          title="Other User IDs"
          description="List of other user ids that are related to this user"
        >
          {(passport.user_ids.length < 2) && (
            <span>This user has no other ids</span>
          )}
          {passport.user_ids.length > 1 && (
            <TableContainer>
              <TableView>
                <THeading>
                  <TRow>
                    <THCol name="user_id" value="User ID" />
                    <THCol name="created_at" value="Date" />
                  </TRow>
                </THeading>
                <TContent>
                  {passport.user_ids.map((user_id, idx) => (
                    <TRow key={idx}>
                      <TCol>{user_id.user_id}</TCol>
                      <TCol value={user_id.created_at} />
                    </TRow>
                  ))}
                </TContent>
              </TableView>
            </TableContainer>
          )}
        </TableSection>

        <FormControls>
          <CancelButton onClick={goBack} label="Go back" />
        </FormControls>
      </BlockContainer>
    </PageContainer>
  );
};

export default Passport;
