import React from "react";

export const PresenceControls = ({
  setPresenceFilter,
  unsetPresenceFilter, 
  selectedValues, 
}) => (
  <React.Fragment>
    <button
      onClick={(e) => { unsetPresenceFilter(e); }}
      aria-disabled={selectedValues[0] !== '<_MISSING_>' && selectedValues[0] !== '<_PRESENT_>'}
      className={`
        h-[24px] 
        min-w-[80px] 
        flex-1
        rounded-l-[2px]
        bg-[#A377B8]
        text-white
        mb-px
        opacity-70
        hover:opacity-80 focus:opacity-80 active:opacity-90
        focus:outline-none
        aria-disabled:opacity-40 aria-disabled:hover:opacity-40 aria-disabled:cursor-default aria-disabled:pointer-events-none
      `}
    >
      Any
    </button>
    <button
      onClick={() => { setPresenceFilter('<_MISSING_>'); }}
      aria-disabled={selectedValues[0] === '<_MISSING_>'}
      className={`
        h-[24px] 
        min-w-[80px] 
        flex-1
        bg-[#A377B8]
        text-white
        mx-px mb-px
        opacity-70
        hover:opacity-80 focus:opacity-80 active:opacity-90
        focus:outline-none
        aria-disabled:opacity-40 aria-disabled:hover:opacity-40 aria-disabled:cursor-default aria-disabled:pointer-events-none
      `}
    >
      Is blank
    </button>
    <button
      onClick={() => { setPresenceFilter('<_PRESENT_>'); }}
      aria-disabled={selectedValues[0] === '<_PRESENT_>'}
      className={`
        h-[24px] 
        min-w-[80px] 
        flex-1
        rounded-r-[2px]
        bg-[#A377B8]
        text-white
        mb-px
        opacity-70
        hover:opacity-80 focus:opacity-80 active:opacity-90
        focus:outline-none
        aria-disabled:opacity-40 aria-disabled:hover:opacity-40 aria-disabled:cursor-default aria-disabled:pointer-events-none
      `}
    >
      Is not blank
    </button>
  </React.Fragment>
)