import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createOfferMutation } from "./../graphql/mutations/benefitMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useDataProvider from "./../hooks/dataProvider";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import useModal from "./../hooks/modal";
import useNotification from "./../hooks/notifications";
import { useOfferFieldSetters } from "./../hooks/vabooHooks";
import {
  EXCLUSION_TAG_TYPE_ID,
  OFFER_MODEL_NAME,
  OFFER_STATUSES,
  REGULAR_TAG_TYPE_ID,
} from "./../configs/constants";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../mood-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
  AddButton,
  DeleteButton,
  BasicControl,
  InlineControls,
} from "./../mood-ui/Controls";
import {
  ErrorNotification,
  SuccessNotification,
} from "./../mood-ui/Notifications";
import { Heading } from "./../mood-ui/Typography";
import {
  DateField,
  SelectField,
  TextField,
  NumberField,
  ToggleCheckboxField,
} from "./../mood-ui/Fields";
import {
  TableContainer,
  TableView,
  THeading,
  TContent,
  THCol,
  TRow,
  TCol,
} from "./../mood-ui/DataTable";
import RichTextEditor from "./../mood-ui/RichTextEditor";
import ImageGalleryModal from "./../mood-ui/ImageGalleryModal";
import { LabelContext } from "../contexts/LabelContext";
import FileUploadModal from "../mood-ui/FileUploadModal";

import PageSpinner from "../mood-ui/PageSpinner";

const CREATE_OFFER = gql(createOfferMutation);

const OfferCreate = () => {

  const { protectPage } = usePermissions();
  
  protectPage(Permissions.Mutation.CreateOffer);
  
  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();

  const labelContext = useContext(LabelContext);
  
  const labelIds = labelContext.labels.map(label => label.id)

  const [merchants] = useDataProvider("merchants");

  const [tags] = useDataProvider("tags", {
    filters: { typeId: [REGULAR_TAG_TYPE_ID] },
  });
  const sortedTags = [...tags].sort((a, b) =>  a.slug > b.slug ? 1 : a.slug < b.slug ? -1 : 0 )
  
  const [exclusionTags] = useDataProvider("tags", {
    filters: { typeId: [EXCLUSION_TAG_TYPE_ID] },
  });
  const sortedExclusionTags = [...exclusionTags].sort((a, b) =>  a.slug > b.slug ? 1 : a.slug < b.slug ? -1 : 0 )

  const { goBack } = useBaseNavigation(OFFER_MODEL_NAME);

  const { modalState, showModal, closeModal } = useModal();
  
  const {
    modalState: promocodeImportModalState,
    openModal: showPromocodeImportModal,
    closeModal: closePromocodeImportModal,
  } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    state: formData,
    stateMethods,
    syncView, 
    // setValue,
  } = useHyperState("formData", {
    merchant_id: 1,
    code: "",
    title: "",
    description: "",
    background: "",
    image: "",
    method: "Online",
    methodIcon: "mdi-flash",
    cta: "View offer",
    verb: "Save",
    saving: "",
    terms: "<p>See merchant's website for full terms and conditions.</p>",
    redeem: "Visit Website",
    redeem_url: "",
    related_offers: "",
    redeem_external_url: true,
    premium: false,
    start_date: new Date().toISOString().split('T')[0],
    end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0],
    status: "Inactive",
    admin_title: "",
    premium_gifted: true,
    premium_acquired: true,
    basic: true,
    public: true,
    tags: [],
    exclusionTags: [],
    steps: [
      {
        title: "Get your code", 
        text: "Click 'Reveal Code' to access your voucher code.", 
      }, 
      {
        title: "Click the link", 
        text: "Click 'Visit Website' below to visit the retailer website.", 
      }, 
    ],
    labels: [...labelIds],
    search_terms: "",
    promocodes_static: true,
    promocodes_active: false,
    static_promocode: "",
    reveal_cta: "Reveal Code",
    promocode_low_stock_percentage: 75,
    promocode_low_stock_alert_emails: "darren@vaboo.co.uk,partners@vaboo.co.uk",
    promocode_file_ids: [],
  });

  const [createOffer, {loading}] = useMutation(CREATE_OFFER, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleFieldOnChangeProxied(e)
  }

  const {
    handleTagCheck,
    handleExclusionTagCheck,
    handleLabelCheck,
    handleAddOfferStep,
    handleUpdateOfferStep: handleUpdateOfferStepProxied,
    handleDeleteOfferStep,
  } = useOfferFieldSetters(formData, stateMethods);


  const handleUpdateOfferStep = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleUpdateOfferStepProxied(e)
  }

  const handleImageSelectModal = () => {
    showModal({
      onConfirm: (file) => {
        handleFieldOnChange({
          target: { name: "image", value: file.public_url },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleBackgroundSelectModal = () => {
    showModal({
      onConfirm: (file) => {
        handleFieldOnChange({
          target: { name: "background", value: file.public_url },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handlePromocodeImportModal = () => showPromocodeImportModal();

  const handlePromocodeImportUploadComplete = (cache, data) => {
    closePromocodeImportModal();
    if (data.uploadFile && data.uploadFile.id) {
      displayNotification({
        heading: "Import Promocodes Ok",
        message: `Successfully uploaded file ${data.uploadFile.original_name}. Promocodes will be processed and assigned to this offer once the offer is created (saved).`,
        secondsToDisplay: 5,
      });
      handleFieldOnChange({
        target: {
          name: "promocode_file_ids",
          value: [...formData.promocode_file_ids, parseInt(data.uploadFile.id)],
        },
      });
    } else {
      displayErrorNotification({
        heading: "Import Promocodes Error",
        message:
          "Error occured when trying to import promocodes from the uploaded CSV file",
        secondsToDisplay: 5,
      });
    }
  };

  const handleSave = () => {
    const data = { ...formData, steps: JSON.stringify(formData.steps) };
    createOffer({ variables: data });
  };

  const [isDraft, setDraft] = useState(false)

  if (
    !formData ||
    !formData.tags ||
    !tags ||
    !exclusionTags ||
    !formData.exclusionTags
  )
    return <PageSpinner />;


  return (
    <PageContainer>
      <BlockContainer raised>
        
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Create a new offer"
              description="Every offer must belong to a merchant. A merchant can 
                          container many offers. If you are creating an offer before 
                          the merchant is added, you can temporarily assign it to 
                          'Vaboo Generic Merchant'."
              />
          </Col>
        </Row>

        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Admin settings"
                      description="Internal settings used for managing and monitoring offers."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.merchant_id}
                      values={merchants}
                      label="Merchant"
                      name="merchant_id"
                      onChange={(e) => { handleFieldOnChange(e); }}
                      valueKey="id"
                      labelKey="title"
                      isRequired={true}
                      error={fieldError("merchant")}
                      description="A merchant can own many offers"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="admin_title"
                      label="Admin title"
                      value={formData.admin_title}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("admin_title")}
                      isRequired={true}
                      maxlength={72}
                      description="For internal use only"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="code"
                      label="URL slug"
                      value={formData.code}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("code")}
                      isRequired={true}
                      maxlength={64}
                      description="Unique path for the URL"
                      pattern="^[a-z0-9]+(?:-[a-z0-9]+)*"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.status}
                      values={OFFER_STATUSES}
                      label="Status"
                      name="status"
                      onChange={(e) => { handleFieldOnChange(e); }}
                      valueKey="id"
                      labelKey="name"
                      isRequired={true}
                      error={fieldError("status")}
                      description="Show or hide this offer"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <DateField
                      name="start_date"
                      label="Start date"
                      value={formData.start_date}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("start_date")}
                      isRequired={true}
                      description="Hidden until this date"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <DateField
                      name="end_date"
                      label="End date"
                      value={formData.end_date}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("end_date")}
                      isRequired={true}
                      description="Hidden after this date"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Artwork"
                      description="With the exception of logos, images should not contain text, should not
                                  include promotions, and should not be seasonal. Use descriptive file names 
                                  so images can be found via search in the future. 
                                  E.g. tesco-logo.png, hotels-com-background.jpeg.
                                  "
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8} extraStyles="flex items-around align-center flex-wrap select-none relastive">
                <div className={`flex items-censter justify-center relative w-full aspect-[16/7] cursor-pointer relative `}>
                  <div 
                    className={`h-[55%] aspect-[1/1] border-2 z-40 border-white cursor-pointer absolute top-7 left-7`}
                    onClick={handleImageSelectModal}
                  >
                    <img
                      src={
                        formData.image && formData.image.length > 0 
                        ? formData.image
                        : 'https://content.vaboo.co.uk/images/20230119_005519_logo-image.png'
                      }
                      alt="Logo"
                      className={`object-fill w-full hover:outline-none hover:shadow-outline peer`}
                      crossOrigin="anonymous"
                      onError={e => { e.target.className += ' opacity-0'; }}
                      onLoad={e => { e.target.parentNode.className += ' bg-red-600 outline outline-white outline-1 outline-offset-[-3px]'; e.target.className = e.target.className.replace(/opacity-0/g,"");  }}
                    />
                    <span className="absolute text-xs leading-2 top-1/2 -translate-y-1/2 left-0 right-0 p-3 text-center text-red-300 -z-1 invisible peer-[.opacity-0]:visible">
                      There was an error. Please try another image.
                    </span>
                  </div>
                  <div 
                    className={`aspect-[16/7] cursor-pointer hover:outline-none hover:shadow-outline `}
                    onClick={handleBackgroundSelectModal}
                  >
                    <img
                      src={
                        formData.background && formData.background.length > 0 
                        ? formData.background
                        : 'https://content.vaboo.co.uk/images/20230119_005706_background-image.png'
                      }
                      alt="Background"
                      className={`object-contain max-h-full w-full peer`}
                      crossOrigin="anonymous"
                      onError={e => { e.target.className += ' opacity-0'; }}
                      onLoad={e => { e.target.parentNode.className += ' bg-red-600'; e.target.className = e.target.className.replace(/opacity-0/g,"");  }}
                    />
                    <span className="absolute top-8 right-8 text-red-300 -z-1 invisible peer-[.opacity-0]:visible">
                      There was an error. Please try another image.
                    </span>
                  </div>
                  {(!formData.background || !formData.background.length) && (
                    <div className={`bg-black opacity-5 h-full w-full absolute top-0 left-0 pointer-events-none `}></div>
                  )}
                </div>
                <p className="mt-1.5 text-xs text-gray-400 pl-0.5 select-none">
                  A red border will appear around an image with sizing issues
                </p>
              </Col>
            </Row>
            {/* <TextField
              name="image"v
              label="Image"
              value={formData.image}
              onChange={handleFieldOnChange}
              error={fieldError("image")}
              isRequired={true}
            />
            <TextField
              name="background"
              label="Background"
              value={formData.background}
              onChange={handleFieldOnChange}
              error={fieldError("background")}
              isRequired={true}
            /> */}

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Primary details"
                      description="This essential text content should include enough information
                                  to be used on its own. These are the only elements of an offer that are scanned 
                                  by the search function in the platform."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles="">
                    <TextField
                      name="title"
                      label="Headline"
                      value={formData.title}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("title")}
                      isRequired={true}
                      maxlength={96}
                      description="Always shown with offer"
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <TextField
                      name="search_terms"
                      label="Extra search terms"
                      value={formData.search_terms}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("search_terms")}
                      isRequired={false}
                      maxlength={96}
                      description="Associated words, mispellings, categories etc."
                    />
                  </Col>
                  <Col width={12} extraStyles="">
                    <RichTextEditor
                      cols={5}
                      rows={5}
                      name="description"
                      value={formData.description}
                      label="Description"
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("description")}
                      isRequired={true}
                      description="Use (ctrl/cmd) + shift + V to paste text without breaking the formatting"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Supporting content"
                      description="Control the elements that belong to this offer. Character
                                  limits protect an offer's layout. Always preview 
                                  new offers by logging into the live platform."
                    />
                    <p className="text-xs text-[#592373] opacity-60 underline underline-offset-4 mt-4 mb-7 select-none">
                      <a href={`https://pictogrammers.com/library/mdi/`} rel="noreferrer" target="_BLANK">Browse icon list</a>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="cta"
                      label="CTA 1"
                      value={formData.cta}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("cta")}
                      isRequired={true}
                      maxlength={96}
                      description="Pre-redemption button text"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="verb"
                      label="Context"
                      value={formData.verb}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("verb")}
                      isRequired={true}
                      maxlength={14}
                      description="E.g 'First box', 'Save over', 'Save'"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="saving"
                      label="Saving"
                      value={formData.saving}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("saving")}
                      isRequired={true}
                      maxlength={6}
                      description="E.g. 'FREE', '£30.00', '15%'"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="related_offers"
                      label="Related offers"
                      value={formData.related_offers}
                      onChange={(e) => { handleFieldOnChange(e); syncView(); }}
                      error={fieldError("related_offers")}
                      maxlength={128}
                      description="Comma-separated list of IDs"
                      tweaks="pr-8"
                      pattern="^([0-9]+)(,\s?[0-9]+)*\s?$"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="method"
                      label="Method"
                      value={formData.method}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("method")}
                      isRequired={true}
                      maxlength={12}
                      description="E.g. 'Online', 'Compare', 'Switch' "
                    />
                  </Col>
                  <Col width={4} extraStyles="relative ">
                    <TextField
                      name="methodIcon"
                      label="Method icon"
                      value={formData.methodIcon}
                      onChange={(e) => { handleFieldOnChange(e); syncView(); }}
                      error={fieldError("methodIcon")}
                      isRequired={true}
                      maxlength={36}
                      description="Use 'mdi-' + 'icon_name'"
                      tweaks="pr-8"
                      pattern="[a-z0-9]+(?:-[a-z0-9]+)*"
                    />
                    <span className={`absolute top-7 pt-[3px] right-3 text-[#b07fc7] -mr-0.5 text-[17px] mdi ${ formData.methodIcon }`}></span>
                  </Col>
                  <Col width={12}>
                    <p className="text-sm mb-0.5 pb-[2px] leading-5 text-gray-500 pl-1 select-none">
                      Steps
                    </p>
                    {formData && formData.steps && formData.steps.length === 0 && (
                      <div className="p-3 border border-[#dfe2e8] bg-white flex">
                        <AddButton label="Add step" onClick={handleAddOfferStep} icon />
                        <div className="ml-2 mt-0.5 pt-px mr-auto w-[75%] text-sm text-gray-500">
                          Add steps to explain how this offer works
                        </div>
                      </div>
                    )}
                    {formData && formData.steps && formData.steps.length > 0 && (
                      <TableContainer tweaks="relative">
                        <TableView>
                          <THeading>
                            <TRow>
                              <THCol tweaks="w-4" name="title" value="#" />
                              <THCol tweaks="text-left" name="title" value="Title" />
                              <THCol tweaks="text-left" name="text" value="Text" />
                              <THCol width="w-[38.5px]" tweaks="px-0.5 py-0.5">
                                <AddButton label="Add step" onClick={handleAddOfferStep} icon />
                              </THCol>
                            </TRow>
                          </THeading>
                          <TContent>
                            {formData.steps.map((step, index) => (
                              <TRow key={index}>
                                <TCol tweaks="px-3 py-0.5 text-center">
                                  {index + 1}
                                </TCol>
                                <TCol tweaks="px-0.5 py-0.5 w-[25%]">
                                  <TextField
                                    name={`title_${index}`}
                                    value={step.title}
                                    flat
                                    isRequired={true}
                                    onChange={handleUpdateOfferStep}
                                    maxlength={36}
                                  />
                                </TCol>
                                <TCol tweaks="px-0.5 py-0.5">
                                  <TextField
                                    name={`text_${index}`}
                                    value={step.text}
                                    flat
                                    isRequired={true}
                                    onChange={handleUpdateOfferStep}
                                    maxlength={196}
                                  />
                                </TCol>
                                <TCol width="w-[38.5px]" tweaks="px-0.5 py-0.5">
                                  {formData.steps.length > 2 && (
                                    <DeleteButton
                                      icon 
                                      onClick={() => { handleDeleteOfferStep(index); setDraft(true); }}
                                    />
                                  )}
                                </TCol>
                              </TRow>
                            ))}
                          </TContent>
                        </TableView>
                        <p className="mt-1.5 text-xs text-gray-400 pl-0.5 select-none">
                          Use at least 2 steps to explain how to redeem this offer
                        </p>
                        <div className="self-end pr-2 pt-0.5 top-0 -mr-px -mb-7 absolute">
                          
                        </div>
                      </TableContainer>
                    )}
                  </Col>
                  <Col width={12}>
                    <RichTextEditor
                      cols={5}
                      rows={5}
                      name="terms"
                      value={formData.terms}
                      label="Terms"
                      onChange={handleFieldOnChange}
                      error={fieldError("terms")}
                      isRequired={true}
                      description="Use (ctrl/cmd) + shift + V to paste text without breaking the formatting"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Promo codes"
                      description="Enable the reveal code functionality for this offer. 
                                  Use one 'static' code for all users or upload a
                                  one-column csv of single-use codes."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row tweaks="">
                  <Col width={4} extraStyles="mb-2">
                    <ToggleCheckboxField
                      id="promocodes_active"
                      name="promocodes_active"
                      label="Use promo codes"
                      description="Enable 'reveal code' feature"
                      isChecked={formData.promocodes_active}
                      error={fieldError("promocodes_active")}
                      onChange={(e) => { setDraft(true); handleToggleCheckbox(e); syncView() }}
                    />
                  </Col>
                  <Col width={4} extraStyles={(formData.promocodes_active) ? '' : 'hidden absolute left-[-200em]'}>
                    <ToggleCheckboxField
                      id="promocodes_static"
                      name="promocodes_static"
                      label="Use static code"
                      description="Use same code for all users"
                      isChecked={formData.promocodes_static}
                      error={fieldError("promocodes_static")}
                      onChange={(e) => { setDraft(true); handleToggleCheckbox(e); syncView() }}
                    />
                  </Col>
                  <Col width={4} extraStyles={(formData.promocodes_active) ? '' : 'hidden absolute left-[-200em]'}>
                    <TextField
                      name="reveal_cta"
                      label="Reveal CTA"
                      value={formData.reveal_cta}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("reveal_cta")}
                      isRequired={formData.promocodes_active}
                      maxlength={24}
                      description="Text on reveal code button"
                    />
                  </Col>
                  <Col width={4} extraStyles={(formData.promocodes_active && formData.promocodes_static) ? '' : 'hidden absolute left-[-200em]'}>
                    <TextField
                      name="static_promocode"
                      label="Static promo code"
                      value={formData.static_promocode}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("static_promocode")}
                      isRequired={formData.promocodes_static}
                      maxlength={48}
                      description="Give this code to all users"
                    />
                  </Col>
                  <Col width={5} extraStyles={(formData.promocodes_active && !formData.promocodes_static) ? '' : 'hidden absolute left-[-200em]'}>
                    <TextField
                      name="promocode_low_stock_alert_emails"
                      label="Low stock email alert"
                      value={formData.promocode_low_stock_alert_emails}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("promocode_low_stock_alert_emails")}
                      maxlength={512}
                      description="Comma-separated list of emails to receive alert"
                    />
                  </Col>
                  <Col width={3} extraStyles={(formData.promocodes_active && !formData.promocodes_static) ? '' : 'hidden absolute left-[-200em]'}>
                    <NumberField
                      name="promocode_low_stock_percentage"
                      label="Alert at X% used"
                      value={formData.promocode_low_stock_percentage}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("promocode_low_stock_percentage")}
                      min={0}
                      max={100}
                      description="Use 0 to disable"
                    />
                  </Col>
                  <Col width={3} extraStyles={(formData.promocodes_active && !formData.promocodes_static) ? '' : 'hidden absolute left-[-200em]'}>
                    <InlineControls align="start" block tweaks="mt-6 w-full">
                      <BasicControl
                        block
                        onClick={handlePromocodeImportModal}
                        label="Upload CSV"
                      />
                    </InlineControls>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Redemption"
                      description="Control the button behaviour for the final action in 
                                  the redemption process."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row tweaks="">
                  <Col width={4} extraStyles="">
                    <TextField
                      name="redeem"
                      label="CTA 2"
                      value={formData.redeem}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("redeem")}
                      isRequired={true}
                      maxlength={96}
                      description="Final action button text"
                    />
                  </Col>
                  <Col width={5} extraStyles="">
                    <TextField
                      name="redeem_url"
                      label="Redeem URL"
                      value={formData.redeem_url}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("redeem_url")}
                      isRequired={true}
                      maxlength={512}
                      description="Where to redeem offer"
                    />
                  </Col>
                  <Col width={3} extraStyles="">
                    <ToggleCheckboxField
                      id="redeem_external_url"
                      name="redeem_external_url"
                      label="External"
                      description="Open link in new tab"
                      isChecked={formData.redeem_external_url}
                      error={fieldError("redeem_external_url")}
                      onChange={(e) => { setDraft(true); handleToggleCheckbox(e); }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Privileges"
                      description="Allow the use of this offer in marketing and control access across membership tiers."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row tweaks="gap-0 py-1 px-px">
                  <Col width={3}>
                    <ToggleCheckboxField
                      id="public"
                      name="public"
                      label="Public"
                      isChecked={formData.public}
                      onChange={(e) => { setDraft(true); handleToggleCheckbox(e); }}
                      flush
                      description="Show in marketing"
                      />
                  </Col>
                  <Col width={3}>
                    <ToggleCheckboxField
                      id="basic"
                      name="basic"
                      label="Basic"
                      isChecked={formData.basic} 
                      onChange={(e) => { setDraft(true); handleToggleCheckbox(e); }}
                      flush
                      description="Is not premium"
                      />
                  </Col>
                  <Col width={3}>
                    <ToggleCheckboxField
                      id="premium_gifted"
                      name="premium_gifted"
                      label="Gifted"
                      isChecked={formData.premium_gifted}
                      onChange={(e) => { setDraft(true); handleToggleCheckbox(e); }}
                      flush
                      description="Was given premium"
                      />
                  </Col>
                  <Col width={3}>
                    <ToggleCheckboxField
                      id="premium_acquired"
                      name="premium_acquired"
                      label="Upgraded"
                      isChecked={formData.premium_acquired}
                      onChange={(e) => { setDraft(true); handleToggleCheckbox(e); }}
                      flush
                      description="Pays for premium access"
                      />
                  </Col>
                </Row>
              </Col>
            </Row>
            
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="User types"
                      description="Control which users can access this offer based on their label type."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row tweaks="gap-0 sm:grid-cols-5 py-1 px-px">
                  {labelContext.labels.map((label) => (
                    <Col key={label.id} extraStyles="capitalize" width={1}>
                      <ToggleCheckboxField
                        id={label.id}
                        name="labels"
                        label={label.name}
                        isChecked={
                          formData.labels.indexOf(label.id) > -1 ? true : false
                        }
                        onChange={(e) => { setDraft(true); handleLabelCheck(e); }}
                        flush
                        description="Enable for type"
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            {sortedExclusionTags && sortedExclusionTags.length && (
              <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
                <Col width={12}> 
                  <Heading 
                    size="md"
                    text="Exclusion tags"
                    description="Used to group offers types that must be excluded from
                                certain platforms. Internal use only."
                  />
                </Col>
                <Col width={12}>
                  <Row tweaks="gap-0 sm:grid-cols-5s py-1 px-px">
                    {sortedExclusionTags.map((tag) => (
                      <Col width={2} key={tag.id} extraStyles="capitalizes sm:col-span-1s">
                        <ToggleCheckboxField
                          id={tag.id}
                          name="exclutionTags"
                          tag={tag.name}
                          isChecked={
                            formData.exclusionTags.indexOf(tag.id) > -1 ? true : false
                          }
                          onChange={(e) => { setDraft(true); handleExclusionTagCheck(e); }}
                          flush
                          label={tag.title}
                          description={tag.slug}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}

            {sortedTags && sortedTags.length && (
              <Row tweaks="border-[#dfe2e8] py-7 mx-5">
                <Col width={12}> 
                  <Heading 
                    size="md"
                    text="Category tags"
                    description="An offer must have at least one tag to be organically
                                discoverable in the platform. Tags define the
                                structure of categories for navigation."
                  />
                </Col>
                <Col width={12}>
                  <Row tweaks="gap-0 sm:grid-cols-5s py-1 px-px">
                    {sortedTags.map((tag) => (
                      <Col width={2} key={tag.id} extraStyles="">
                        <ToggleCheckboxField
                          id={tag.id}
                          name="tags"
                          tag={tag.name}
                          isChecked={
                            formData.tags.indexOf(tag.id) > -1 ? true : false
                          }
                          onChange={(e) => { setDraft(true); handleTagCheck(e); }}
                          flush
                          label={tag.title}
                          description={tag.slug}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}

          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} loading={loading} disabled={!isDraft || formHasErrors()} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <ImageGalleryModal {...modalState} />
      <FileUploadModal
        {...promocodeImportModalState}
        onClose={closePromocodeImportModal}
        onComplete={handlePromocodeImportUploadComplete}
        message="Drop CSV file with Promocodes to import"
      />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default OfferCreate;
