import React from "react";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
} from "./../../moose-ui/DataTable";
import { ItemsNotFound } from "./../../moose-ui/Typography";
import { Heading } from "./../../moose-ui/Typography";
import TypeFilters from "./../../moose-ui/filters/TypeFilters";
import DateRangeFilter from "./../../moose-ui/filters/DateRangeFilter";
import { StdButton } from "./../../moose-ui/Controls";

const QAStatsReport = ({
  loading,
  data,
  typeFilters,
  onApplyFilters: applyFilters,
  onResetFilters: resetFilters,
  onRemoveFiltervalue: removeFilterValue,
  onUpdateFilter: updateFilter,
  onExportData: exportData,
  selectedFilters,
  hasActiveFilters,
  startDate,
  endDate,
  exportIsAvailable = false,
}) => {
  return (
    <PageContainer>
      <BlockContainer>
        {loading && <Heading text="Report. Tenant QA Stats. Loading..." />}
        {!loading && <Heading text="Report. Tenant QA Stats" />}
        <div className="flex items-center pt-5"></div>
        <Row>
          <Col width={12}>
            <DateRangeFilter
              startDate={startDate}
              endDate={endDate}
              onApplyRange={applyFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col width={12}>
            <TypeFilters
              data={typeFilters}
              onApply={applyFilters}
              onReset={resetFilters}
              onRemoveValue={removeFilterValue}
              hasActiveFilters={hasActiveFilters}
              onUpdate={updateFilter}
              selectedFilters={selectedFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col width={9}>
            {exportIsAvailable && (
              <StdButton label="Export to CSV" onClick={exportData} />
            )}
          </Col>
        </Row>

        {data && data.qaStats && data.qaStats.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol name="question_id" value="Question ID" />
                  <THCol name="question_text" value="Question Text" />
                  <THCol name="question_type" value="Question Type" />
                  <THCol
                    name="question_response_count"
                    value="Question Responses Count"
                  />
                  <THCol name="answer_id" value="Answer ID" />
                  <THCol name="answer_text" value="Answer Text" />
                  <THCol name="answer_count" value="Answer Count" />
                </TRow>
              </THeading>
              <TContent>
                {data.qaStats.map((qas, idx) => (
                  <TRow key={idx}>
                    <TCol value={qas.question_id} />
                    <TCol value={qas.question_text} />
                    <TCol value={qas.question_type} />
                    <TCol value={qas.question_response_count} />
                    <TCol value={qas.answer_id} />
                    <TCol value={qas.answer_text} />
                    <TCol value={qas.answer_count} />
                  </TRow>
                ))}
              </TContent>
            </TableView>
          </TableContainer>
        )}
        {data && data.qaStats && data.qaStats.length == 0 && (
          <ItemsNotFound text="Tenant QA Stats not found" />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default QAStatsReport;
