import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/react-hooks";
import { uploadFileMutation } from "./../../graphql/mutations/fileMutations";
import gql from "graphql-tag";

const UPLOAD_FILE = gql(uploadFileMutation);

const Dropzone = ({ onUploadComplete, storageId = "public_images", text = 'Upload images', type = 'image', dense = false }) => {
  
  const [uploadCounts, setUploadCounts] = useState({
    error: 0,
    success: 0,
    total: 0,
  });
  
  const onDrop = useCallback(
    (files) => {
      setUploadCounts({
        ...uploadCounts,
        total: uploadCounts.total + files.length,
      });
      for (let file of files) {
        uploadFile({ variables: { file, storageId } });
      }
    },
    [uploadCounts]
  );

  const accept = type == 'image' ? { 'image/jpeg': [], 'image/png': [], }
               : type == 'csv' ? { 'text/csv': [] }
               : undefined
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });
  
  const [
    uploadFile,
    { data: uploadResult, loading: isUploading, error: uploadError },
  ] = useMutation(UPLOAD_FILE, {
    onCompleted: (result) => {
      const resolvedUploads = uploadCounts.success + 1;
      setUploadCounts({ ...uploadCounts, success: resolvedUploads });
    },
    onError: (e) => {
      const failedUploads = uploadCounts.error + 1;
      setUploadCounts({ ...uploadCounts, error: failedUploads });
    },
    update: (cache, { data }) => {
      if (!data.uploadFile) return false;
      if (!onUploadComplete) return false;
      onUploadComplete(cache, data);
    },
  });

  return (
    <div>
      <div className="" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={`group cursor-pointer flex px-5 py-2 border-2 border-gray-300 border-dashed rounded-none ${isDragActive? 'bg-emerald-50' : ''} ${isUploading? 'bg-emerald-50 animate-pulse' : 'hover:bg-gray-50'}`}>
          
          {!dense && (
            <div className="flex-1"></div>
          )}
          
          <div className={!dense ? "flex-1 justify-center" : "flex justify-start"}>
            <div className="text-center">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24" 
                strokeWidth="1.5" 
                stroke="currentColor" 
                className="inline mr-2 h-4 w-4 text-gray-400"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" 
                />
              </svg>
              <p className="inline leading-8 text-sm text-gray-500">
                <button
                  type="button"
                  className="text-gray-500 group-hover:text-indidgo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
                >
                  { text }
                </button>
              </p>
              <p className="mt-1 text-xs text-gray-500"></p>
            </div>
          </div>
          <div className="flex-1 text-right align-text-bottom leading-8">
            {uploadCounts.total > 0 && (
              <div>
                {uploadCounts.error > 0 && (
                  <span>
                    <span className="text-sm font-light text-red-700">
                      {uploadCounts.error} error
                    </span>
                    <span className="text-sm font-light text-gray-400 mx-2">/</span>
                  </span>
                )}
                <span className="text-sm font-light text-green-700">
                  {uploadCounts.success} success
                </span>
                <span className="text-sm font-light text-gray-400 mx-2">/</span>
                <span className="text-sm font-light text-blue-700">
                  {uploadCounts.total} total
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropzone;
