import React, { useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allSurveys } from "./../graphql/queries/vabooSurveyQueries";
import { publishSurveysMutation } from "./../graphql/mutations/vabooSurveyMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useListSelection from "./../hooks/rowSelection";
import useNotification from "./../hooks/notifications";
import useModal from "./../hooks/modal";
import useTypeFilters from "./../hooks/typeFilters";
import { SURVEY_MODEL_NAME } from "./../configs/constants";
import { ConfirmActionModal } from "./../moose-ui/Modals";
import PageSpinner from "./../moose-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../moose-ui/Layout";
import { TEditButton, AddButton } from "./../moose-ui/Controls";
import { Heading, ItemsNotFound, ColorLabel } from "./../moose-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../moose-ui/Notifications";
import Search from "./../moose-ui/Search";
import { ListItemCheckbox } from "./../moose-ui/Controls";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableActions,
  TableSelectedActions,
  Pagination,
} from "./../moose-ui/DataTable";
import TypeFilters from "./../moose-ui/filters/TypeFilters";
import SurveyListActions from "./../components/list-secondary-actions/SurveyListActions";
import useItemCounter from "../hooks/itemCounter";

const ALL_SURVEYS = gql(allSurveys);
const PUBLISH_SURVEYS = gql(publishSurveysMutation);

const SurveyList = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.Surveys);
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
  } = useListSelection();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();
  
  document.title = 'Surveys'

  const { page, limit, orderBy, orderHow, search } = useParams();
  const { search: queryString } = useLocation();
  const { modalState, showModal, closeModal } = useModal();
  const { data, loading, error } = useQuery(ALL_SURVEYS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });
  const [publishSurveys, { loading: publishingSurveys }] = useMutation(
    PUBLISH_SURVEYS,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Surveys Published",
          message: "Successfully published selected surveys",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to publish selected surveys",
          secondsToDisplay: 2,
        });
      },
    }
  );

  const { itemsTotal } = useItemCounter(data, { searchKey: "surveys" });

  const {
    nextPage,
    prevPage,
    editForm,
    createForm,
    applySearch,
    applyFilters,
    resetFilters,
    applySorting,
  } = useBaseNavigation(SURVEY_MODEL_NAME, { showingItemsCount: itemsTotal });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    resetFilters: onResetFilters,
    removeFilterValue: onRemoveFilterValue,
    applyFilters: onApplyFilters,
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "survey",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: queryString,
  });

  const confirmPublishing = () => {
    showModal({
      heading: "Confirm Publishing",
      message:
        "Are you sure you want to PUBLISH selected surveys? They will become available for usage in App/Landlord survey settings, but you will not be able to edit them.",
      mood: "Info",
      confirmButtonLabel: "Publish",
      onConfirm: () => {
        closeModal();
        handlePublishSurveys();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handlePublishSurveys = () => {
    publishSurveys({ variables: { ids: selectedRows } });
  };

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        {loading && <Heading text="Surveys. Loading..." />}
        {!loading && <Heading text="Surveys" />}
        <Row>
          <Col width={12}>
            <TypeFilters
              data={typeFilters}
              onApply={onApplyFilters}
              onReset={onResetFilters}
              onRemoveValue={onRemoveFilterValue}
              hasActiveFilters={hasActiveFilters}
              onUpdate={onUpdateFilter}
              selectedFilters={selectedFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col width={9}>
            {hasAccess(Permissions.Mutation.CreateSurvey) && (
              <AddButton label="Add Survey" onClick={createForm} />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>

        {data && data.surveys && data.surveys.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol />
                  <THCol name="id" value="ID" />
                  <THCol name="title" value="Survey" />
                  <THCol name="type" value="Type" />
                  <THCol name="status" value="Status" />
                  {/* <THCol name="type" value="Question IDs" /> */}
                  <THCol />
                </TRow>
              </THeading>
              <TContent>
                {data.surveys.map((survey) => (
                  <TRow key={survey.id} isSelected={rowSelected(survey.id)}>
                    <TCol>
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(survey.id)}
                        isChecked={rowSelected(survey.id) ? true : false}
                      />
                    </TCol>
                    <TCol value={survey.id} />
                    <TCol value={survey.title} />
                    <TCol value={survey.survey_type.name} />
                    <TCol>
                      {survey.status == "Published" ? (
                        <ColorLabel text={survey.status} color="green" />
                      ) : (
                        <ColorLabel text={survey.status} color="gray" />
                      )}
                    </TCol>
                    {/* <TCol value={survey.question_ids.join(", ")} /> */}
                    <TCol>
                      {hasAccess(Permissions.Query.Survey) && (
                        <TEditButton onClick={() => editForm(survey.id)} />
                      )}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            </TableView>
            <TableActions>
              <TableSelectedActions>
                {selectedRows && selectedRows.length > 0 && (
                  <SurveyListActions
                    onClearSelection={clearSelection}
                    onPublish={confirmPublishing}
                  />
                )}
              </TableSelectedActions>
              <Pagination onPrev={prevPage} onNext={nextPage} />
            </TableActions>
          </TableContainer>
        )}
        {data && data.surveys && data.surveys.length == 0 && (
          <ItemsNotFound text="Surveys not found" />
        )}
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default SurveyList;
