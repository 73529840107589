import React from "react";

const Controls = ({
  dynamicListsAreLoading, 
  showResetButton,
  onResetFilters,
  canSaveNewList,
  onSaveNewList,
  canSaveList, 
  activeList, 
  onSaveList, 
  hasSnapshots, 
  hasLists = false, 
}) => {
  return (
    <div className="select-none flex-wrap flex-grow">
      <div className="select-none justify-stretchs flex w-full">
       {/* { hasLists && activeList && (
          <button
            type="button"
            className={` 
              mr-1 basis-1/3 justify-center max-w-[90px] min-w-fit whitespace-nowrap
              py-0.5 pl-3 pr-2 
              h-[30px] min-w-[75px]
              border border-transparent 
              text-xs leading-4 
              focus:outline-none focus:border-orange-700 focus:shadow-outline-blue
              transition-colors duration-75 ease-in
              ${canSaveList && activeList
                ? 'border-orange-300 text-orange-400 hover:bg-orange-100 '
                : 'border-orange-50 text-orange-200 bg-gray-50 '
              }
            `}
            onClick={() => onSaveNewList()}
            disabled={!canSaveList || !activeList || dynamicListsAreLoading}
          >
            Alerts
            <span className="mdi mdi-bell-alert ml-1"></span>
          </button>
          )
        } */}
        {
          hasLists && (
            <button
              type="button"
              className={`
                mr-1 basis-1/3 justify-center max-w-[90px] min-w-fit whitespace-nowrap
                py-0.5 pl-3 pr-2 
                h-[30px] min-w-[80px]
                border border-transparent 
                text-xs leading-4 
                focus:outline-none focus:border-emerald-700 focus:shadow-outline-blue
                transition-colors duration-75 ease-in
                ${  (canSaveList && canSaveNewList && activeList && !hasSnapshots)
                  ? ' border-emerald-400 text-emerald-500 hover:bg-emerald-100 '
                  : (canSaveList && activeList)
                  ? ' border-[#A377B8] text-[#A377B8] hover:bg-purple-100 '
                  : ' border-purple-200 text-purple-200 bg-gray-50 '
                }
              `}
              onClick={() => onSaveList()}
              disabled={!canSaveList || !activeList || dynamicListsAreLoading}
            >
              { 
                activeList && canSaveList && canSaveNewList && !hasSnapshots
                ? 'Apply' 
                : 'Update' 
              }
              { 
                canSaveNewList 
                ? <span className="mdi mdi-pencil ml-1"></span>
                : <span className="mdi mdi-pencil ml-1"></span>
              }
            </button>
          )
        }
        <button
          type="button"
          className={`
            mr-1 basis-1/3 justify-center max-w-[90px] min-w-fit whitespace-nowrap
            py-0.5 pl-3 pr-2 
            h-[30px] min-w-[75px]
            border border-transparent 
            text-xs leading-4 
            focus:outline-none focus:border-indigo-700 focus:shadow-outline-blue
            transition-colors duration-75 ease-in
            ${showResetButton && canSaveNewList
              ? ' border-indigo-300 text-indigo-400 hover:bg-indigo-100 '
              : ' border-indigo-100 text-indigo-200 bg-gray-50 '
            }
          `}
          onClick={() => onSaveNewList()}
          disabled={!showResetButton || !canSaveNewList || dynamicListsAreLoading}
        >
          New
          <span className="mdi mdi-plus ml-1"></span>
        </button>
        { hasLists && activeList && (
            <button
              type="button"
              className={`
                basis-1/3 items-center max-w-[90px] min-w-fits whitespace-nowrap
                pl-3 pr-2 py-0.5 
                h-[30px] min-w-[75px]
                border border-transparent 
                text-xs leading-4 
                focus:outline-none focus:border-rose-300 focus:shadow-outline-blue
                transition-colors duration-75 ease-in
                ${showResetButton && canSaveList 
                  ? ' border-rose-300 text-rose-400 hover:bg-rose-100'
                  : ' border-rose-100 text-rose-200 bg-gray-50 '
                }
              `}
              onClick={() => onResetFilters()}
              disabled={!showResetButton || dynamicListsAreLoading || !canSaveList}
            >
              { 
                showResetButton && canSaveNewList && canSaveList && activeList
                ? 'Cancel' 
                : 'Close' 
              }
              { 
                showResetButton && canSaveNewList && canSaveList && activeList
                ? <span className="mdi mdi-undo ml-1"></span>
                : <span className="mdi mdi-close ml-1"></span>
              }
            </button>
          )
        }
      </div>
    
    </div>
  );
};

export default Controls;
