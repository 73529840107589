import UAParser from 'ua-parser-js'

// browser.name
const browsers = [                    
  { name: 'Other',    image: '/images/ua/other_browser.png', method: 'CONTAINS', criteria: 'not_null'                                        }, 
  { name: 'Chrome',   image: '/images/ua/chrome.png',        method: 'CONTAINS', criteria: 'Chrome'                                          }, 
  { name: 'Chromium', image: '/images/ua/chromium.png',      method: 'CONTAINS', criteria: 'Chromium'                                        }, 
  { name: 'Safari',   image: '/images/ua/safari.png',        method: 'CONTAINS', criteria: 'Safari'                                          }, 
  { name: 'Edge',     image: '/images/ua/edge.png',          method: 'CONTAINS', criteria: 'Edge'                                            }, 
  { name: 'Firefox',  image: '/images/ua/firefox.png',       method: 'CONTAINS', params: [ 'Firefox', 'Mozilla' ]                            }, 
  { name: 'IE',       image: '/images/ua/ie.png',            method: 'IS',       params: [ 'IE', 'IEMobile' ]                                }, 
  { name: 'Social',   image: '/images/ua/social.png',        method: 'CONTAINS', params: [ 'Facebook', 'Instagram', 'LinkedIn' ]             }, 
  { name: 'Native',   image: '/images/ua/native.png',        method: 'CONTAINS', params: [ 'WeChat', 'Opera', 'Nokia', 'Huawei', 'Android' ] }, 
  { name: 'Postman',  image: '/images/ua/postman.png',       method: 'IS',       criteria: 'Postman'                                         }, 
]

// device.vendor
const vendors = [
  { name: 'Other',    image: '/images/ua/unknown.png', method: 'CONTAINS', criteria: 'not_null' }, 
  { name: 'Acer',     image: '/images/ua/acer.png',    method: 'CONTAINS', criteria: 'Acer'     }, 
  { name: 'Apple',    image: '/images/ua/apple.png',   method: 'CONTAINS', criteria: 'Apple'    }, 
  { name: 'ASUS',     image: '/images/ua/asus.png',    method: 'CONTAINS', criteria: 'ASUS'     }, 
  { name: 'Google',   image: '/images/ua/google.png',  method: 'CONTAINS', criteria: 'Google'   }, 
  { name: 'HTC',      image: '/images/ua/htc.png',     method: 'CONTAINS', criteria: 'HTC'      }, 
  { name: 'Huawei',   image: '/images/ua/huawei.png',  method: 'CONTAINS', criteria: 'Huawei'   }, 
  { name: 'LG',       image: '/images/ua/lg.png',      method: 'CONTAINS', criteria: 'LG'       }, 
  { name: 'Nokia',    image: '/images/ua/nokia.png',   method: 'CONTAINS', criteria: 'Nokia'    }, 
  { name: 'OnePlus',  image: '/images/ua/oneplus.png', method: 'CONTAINS', criteria: 'OnePlus'  }, 
  { name: 'Samsung',  image: '/images/ua/samsung.png', method: 'CONTAINS', criteria: 'Samsung'  }, 
  { name: 'Xiaomi',   image: '/images/ua/xiaomi.png',  method: 'CONTAINS', criteria: 'Xiaomi'   }, 
]

// os.name
const o_systems = [
  { name: 'Other',       image: '/images/ua/unknown.png',    method: 'CONTAINS', criteria: 'not_null'    }, 
  { name: 'Android',     image: '/images/ua/android.png',    method: 'CONTAINS', criteria: 'Android'     }, 
  { name: 'Linux',       image: '/images/ua/linux.png',      method: 'CONTAINS', params: [ 'Linux', 'Arch', 'Bada', 'BeOS', 'CentOS', 'Fedora', 'Debian', 'FreeBSD', 'Linpus', 'Linspire', 'Manjaro', 'Mint', 'OpenBSD', 'RedHat', 'Ubuntu', 'VectorLinux' ] }, 
  { name: 'Chromium OS', image: '/images/ua/chromium.png', method: 'IS',       criteria: 'Chromium OS' }, 
  { name: 'Firefox OS',  image: '/images/ua/firefox.png',  method: 'IS',       criteria: 'Firefox OS'  }, 
  { name: 'iOS',         image: '/images/ua/ios.png',        method: 'IS',       criteria: 'iOS'         }, 
  { name: 'Mac OS',      image: '/images/ua/macos.png',      method: 'IS',       criteria: 'Mac OS'      }, 
  { name: 'Windows',     image: '/images/ua/windows.png',    method: 'CONTAINS', criteria: 'Windows'     }, 
]

// device.type
const devices = [
  { name: 'Console',  image: '/images/ua/console.png',  method: 'IS', criteria: 'console'  }, 
  { name: 'Mobile',   image: '/images/ua/mobile.png',   method: 'IS', criteria: 'mobile'   }, 
  { name: 'Tablet',   image: '/images/ua/tablet.png',   method: 'IS', criteria: 'tablet'   }, 
  { name: 'Smart TV', image: '/images/ua/smarttv.png',  method: 'IS', criteria: 'smarttv'  }, 
  { name: 'Wearable', image: '/images/ua/wearable.png', method: 'IS', criteria: 'wearable' }, 
  { name: 'Embedded', image: '/images/ua/embedded.png', method: 'IS', criteria: 'embedded' }, 
]

const enrichUa = (parsedUa) => {
  
  // let sample = {
  //   browser: { name: 'Chrome',  version: '103.0.0.0', major: '103'    }, 
  //   cpu:     { architecture: undefined                                }, 
  //   device:  { vendor: 'Apple', model: 'Macintosh',   type: 'desktop' }, 
  //   engine:  { name: 'Blink',   version: '103.0.0.0'                  }, 
  //   os:      { name: 'Mac OS',  version: '10.15.7'                    }, 
  //   ua: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36 ", 
  // }

  let tempUa = {
    ...parsedUa, 
    browser_title: 'Unknown', 
    browser_image: '/images/ua/unknown.png',
    vendor_title:  'Unknown', 
    vendor_image:  '/images/ua/unknown.png',
    os_title:      'Unknown', 
    os_image:      '/images/ua/unknown.png',
    device_title:  'Unknown', 
    device_image:  '/images/ua/unknown.png',
  }

  if (tempUa.ua.search('Postman') > -1) { 
    tempUa.browser.name = 'Postman'
    tempUa.device.type  = 'console'
  }

  const methodContains = (find, criteria = null, params = null) => {
    let found = false
    if          (criteria) found = find.search(criteria) > -1
    else if     (params) { params.forEach(param => { if (find.search(param) > -1) found = true }) }
    return      found
  }  
  const methodIs = (find, criteria = null, params = null) => {
    let found = false
    if          (criteria) found = find === criteria
    else if     (params) { params.forEach(param => { if (find === param) found = true }) }
    return      found
  }

  // BROWSER
  if (parsedUa.browser.name) {
    let complete = false
    browsers.forEach(browser => {
      if (complete === false && browser.method === 'CONTAINS') {
        let match = methodContains(parsedUa.browser.name, browser.criteria || null, browser.params || null)
        if (match) {
          tempUa.browser_title = browser.name
          tempUa.browser_image = browser.image
          complete = true
        }
      }
      if (complete === false && browser.method === 'IS') {
        let match = methodIs(parsedUa.browser.name, browser.criteria || null, browser.params || null)
        if (match) {
          tempUa.browser_title = browser.name
          tempUa.browser_image = browser.image
          complete = true
        }
      }
    })
    if (!complete) {
      tempUa.browser_title = browsers[0].name
      tempUa.browser_image = browsers[0].image
    }
  }

  // VENDOR
  if (parsedUa.device.vendor) {
    let complete = false
    vendors.forEach(vendor => {
      if (complete === false && vendor.method === 'CONTAINS') {
        let match = methodContains(parsedUa.device.vendor, vendor.criteria || null, vendor.params || null)
        if (match) {
          tempUa.vendor_title = vendor.name
          tempUa.vendor_image = vendor.image
          complete = true
        }
      }
      if (complete === false && vendor.method === 'IS') {
        let match = methodIs(parsedUa.device.vendor, vendor.criteria || null, vendor.params || null)
        if (match) {
          tempUa.vendor_title = vendor.name
          tempUa.vendor_image = vendor.image
          complete = true
        }
      }
    })
    if (!complete) {
      tempUa.vendor_title = vendors[0].name
      tempUa.vendor_image = vendors[0].image
    }
  }

  // OS
  if (parsedUa.os.name) {
    let complete = false
    o_systems.forEach(os => {
      if (complete === false && os.method === 'CONTAINS') {
        let match = methodContains(parsedUa.os.name, os.criteria || null, os.params || null)
        if (match) {
          tempUa.os_title = os.name
          tempUa.os_image = os.image
          complete = true
        }
      }
      if (complete === false && os.method === 'IS') {
        let match = methodIs(parsedUa.os.name, os.criteria || null, os.params || null)
        if (match) {
          tempUa.os_title = os.name
          tempUa.os_image = os.image
          complete = true
        }
      }
    })
    if (!complete) {
      tempUa.os_title = o_systems[0].name
      tempUa.os_image = o_systems[0].image
    }
  }

  // DEVICE
  if (parsedUa.device.type) {
    let complete = false
    devices.forEach(device => {
      if (complete === false && device.method === 'CONTAINS') {
        let match = methodContains(parsedUa.device.type, device.criteria || null, device.params || null)
        if (match) {
          tempUa.device_title = device.name
          tempUa.device_image = device.image
          complete = true
        }
      }
      if (complete === false && device.method === 'IS') {
        let match = methodIs(parsedUa.device.type, device.criteria || null, device.params || null)
        if (match) {
          tempUa.device_title = device.name
          tempUa.device_image = device.image
          complete = true
        }
      }
    })
  }

  // LOGIC FOR DESKTOP
  if (tempUa.device_title === 'Unknown') {
    if ([ 'Linux', 'Mac OS', 'Windows' ].includes(tempUa.os_title)) {
      tempUa.device_title = 'Desktop' 
      tempUa.device_image = '/images/ua/desktop.png' 
    }
    else if (tempUa.browser.name === 'IEMobile') {
      tempUa.device_title = 'Mobile' 
      tempUa.device_image = '/images/ua/mobile.png' 
    }
    else if (tempUa.browser.name === 'IEMobile') {
      tempUa.device_title = 'Mobile' 
      tempUa.device_image = '/images/ua/mobile.png' 
    }
  }
  return tempUa
}

export const uaParse = (useragent) => {
  let parser = new UAParser(useragent)    // https://github.com/faisalman/ua-parser-js
  let parserResults = parser.getResult()
  parserResults = enrichUa(parserResults)
  return parserResults
}