import React from "react";
import Moment from "react-moment";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { passports } from "../../graphql/queries/reportQueries";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "../../hooks/baseNavigation";
import useTypeFilters from "../../hooks/typeFilters";
import { REPORTS_PASSPORTS_MODEL_NAME } from "../../configs/constants";
import PageSpinner from "../../moose-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "../../moose-ui/Layout";
import { TEditButton } from "../../moose-ui/Controls";
import { Heading, ItemsNotFound, ColorLabel } from "../../moose-ui/Typography";
import Search from "../../moose-ui/Search";
import TypeFilters from "../../moose-ui/filters/TypeFilters";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  TableActions,
  Pagination,
} from "../../moose-ui/DataTable";
import useItemCounter from "../../hooks/itemCounter";

const PASSPORTS = gql(passports);

const PassportList = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.PassportsReport);
  const { page, limit, orderBy, orderHow, search } = useParams();
  const { search: queryString } = useLocation();
  const { data, loading, error } = useQuery(PASSPORTS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "passports" });

  const { nextPage, prevPage, applySearch, applyFilters, resetFilters, goTo } =
    useBaseNavigation(REPORTS_PASSPORTS_MODEL_NAME, {
      showingItemsCount: itemsTotal,
    });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    updateDatesFilter: onUpdateDatesFilter,
    resetFilters: onResetFilters,
    removeFilterValue: onRemoveFilterValue,
    applyFilters: onApplyFilters,
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "passports",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: queryString,
    fetchPolicy: "no-cache",
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        {loading && <Heading text="Report. Passports. Loading..." />}
        {!loading && <Heading text="Report. Passports" />}
        <Row>
          <Col width={12}>
            <TypeFilters
              data={typeFilters}
              onApply={onApplyFilters}
              onReset={onResetFilters}
              onRemoveValue={onRemoveFilterValue}
              hasActiveFilters={hasActiveFilters}
              onUpdate={onUpdateFilter}
              onUpdateDates={onUpdateDatesFilter}
              selectedFilters={selectedFilters}
              filterBaseTitle="Dates & Filters"
            />
          </Col>
        </Row>
        <Row>
          <Col width={9}></Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>

        {data && data.passports && data.passports.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol name="id" value="Passport ID" />
                  <THCol name="email" value="Email" />
                  <THCol name="firstname" value="First Name" />
                  <THCol name="lastname" value="Last Name" />
                  <THCol name="telephone" value="Telephone" />
                  <THCol />
                </TRow>
              </THeading>
              <TContent>
                {data.passports.map((passport, idx) => (
                  <TRow key={idx}>
                    <TCol value={passport.id} />
                    <TCol value={passport.email} />
                    <TCol value={passport.firstname} />
                    <TCol value={passport.lastname} />
                    <TCol value={passport.telephone} />
                    <TCol>
                      <TEditButton
                        onClick={() => {
                          goTo(
                            `/${REPORTS_PASSPORTS_MODEL_NAME}/${passport.id}`
                          );
                        }}
                        label="View"
                      />
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            </TableView>
            <TableActions>
              <TableSelectedActions></TableSelectedActions>
              <Pagination onPrev={prevPage} onNext={nextPage} />
            </TableActions>
          </TableContainer>
        )}
        {data && data.passports && data.passports.length === 0 && (
          <ItemsNotFound text="No Passports found by current criteria" />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default PassportList;
