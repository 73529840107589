import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { tenantDetails } from "../../graphql/queries/tenantQueries";
import {
  updateTenantMutation,
  activationPushMutation,
  loginAsMutation,
  syncWithACMutation,
  cancelUserDeletingMutation,
} from "../../graphql/mutations/tenantMutations";
import PageSpinner from "./../../moose-ui/PageSpinner";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../moose-ui/Notifications";
import Permissions, {
  tenantNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useFormReducer, { baseReducer } from "../../hooks/formDataReducer";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useHyperState from "./../../hooks/hyperState";
import useDataProvider from "./../../hooks/dataProvider";
import useFormEventDispatchers from "../../hooks/formEventDispatchers";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "../../hooks/notifications";
import useBaseNavigation from "../../hooks/baseNavigation";
import {
  TENANT_MODEL_NAME,
  TENANT_ACCESS_TYPES,
  GENDERS,
  AGE_BRACKETS,
} from "../../configs/constants";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../moose-ui/Layout";
import {
  FormControls,
  InlineControls,
  StdButton,
  SaveButton,
  BasicControl,
} from "../../moose-ui/Controls";
import {
  TextField,
  RichTextEditorField,
  SelectField,
  ToggleCheckboxField,
  DateField,
  ReadOnlyField,
} from "../../moose-ui/Fields";
import { Heading } from "../../moose-ui/Typography";

const TENANT_DETAILS = gql(tenantDetails);
const UPDATE_TENANT = gql(updateTenantMutation);
const ACTIVATION_PUSH = gql(activationPushMutation);
const SYNC_WITH_AC = gql(syncWithACMutation);
const CANCEL_USER_DELETING = gql(cancelUserDeletingMutation);
const LOGIN_AS = gql(loginAsMutation);
const DEFAULT_META_STATE = {
  activation_push_enabled: false,
};

// const fieldDataReducer = (state, action) => {
//   const newState = { ...state };
//   return baseReducer(state, action);
// };

const TenantPrimary = () => {
  const { id } = useParams();
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.User, {
    noAccessFallbackList: tenantNoAccessFallbackList,
    variables: { id },
  });
  const [metaState, setMetaState] = useState(DEFAULT_META_STATE);
  const [createdMethod, setCreatedMethod] = useState("");
  const [parent, setParent] = useState({ id: 0, email: "" });
  const [acPushState, setACPushState] = useState({
    enabled: true,
    label: "Activation Push",
  });
  const [landlords] = useDataProvider("landlords");
  const [labels] = useDataProvider("labels");
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    id: 0,
    email: "",
    account_name: "",
    firstname: "",
    lastname: "",
    address: "",
    towncity: "",
    postcode: "",
    telephone: "",
    promo_premium_access: false,
    tenancy_start: "",
    tenancy_end: "",
    notes: "",
    ref: "",
    landlord: { id: "", email: "" },
    landlord_id: "",
    label_id: 0,
  });

  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const {
    setFieldValue: handleFieldOnChange,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    loading,
    error,
    refetch: refetchTenant,
  } = useQuery(TENANT_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.tenant) return false;
      setFormData({
        ...result.tenant,
        landlord_id: result.tenant.landlord.id,
        label_id: result.tenant.label_type.id,
      });
      setParent(result.tenant.landlord);
      setCreatedMethod(result.tenant.created_method);
      setMetaState({ ...result.tenant.meta_state });
    },
  });

  const [
    update,
    { data: updateResult, loading: isUpdating, error: updateError },
  ] = useMutation(UPDATE_TENANT, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated user primary data",
        secondsToDisplay: 1,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const [activationPush, { loading: activationPushPending }] = useMutation(
    ACTIVATION_PUSH,
    {
      onCompleted: (result) => {
        setACPushState({ enabled: false, label: "Activation Push" });
        displayNotification({
          heading: "Activation Push Complete",
          message:
            "Successfully applied 'Activation Push' action for current user",
          secondsToDisplay: 1,
        });
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to apply 'Activation Push' action",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        return false;
      },
    }
  );

  const [syncWithAC, { loading: syncWithACPending }] = useMutation(
    SYNC_WITH_AC,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Sync with AC",
          message: "Synchronization with Active Campaign complete",
          secondsToDisplay: 1,
        });
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to sync tenan data with Active Campaign",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        return false;
      },
    }
  );

  const [cancelUserDeleting, { loading: cancelUserDeletingPending }] =
    useMutation(CANCEL_USER_DELETING, {
      onCompleted: (result) => {
        refetchTenant();
        displayNotification({
          heading: "Cancel User Deleting",
          message: "Deleting process is canceled for this user",
          secondsToDisplay: 2,
        });
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to cancel user deleting process",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        return false;
      },
    });

  const [loginAs, { loading: loginAsPending }] = useMutation(LOGIN_AS, {
    onCompleted: (result) => {
      window.open(result.loginAsTenant.url, "_blank");
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to create System User Login Data",
        secondsToDisplay: 2,
      });
    },
    update: (cache, { data }) => {
      return false;
    },
  });

  const handleActivationPush = () => {
    setACPushState({ enabled: true, label: "In Progress..." });
    activationPush({ variables: { ids: [id] } });
  };

  const handleSyncWithAC = () => {
    syncWithAC({ variables: { ids: [id] } });
  };

  const handleCancelUserDeleting = () => {
    cancelUserDeleting({ variables: { ids: [id] } });
  };

  const handleLoginAs = () => {
    loginAs({ variables: { account_id: id } });
  };

  const handleSave = () => {
    if (isUpdating) return false;
    const data = { ...formData };
    update({ variables: data });
  };

  if (loading) return <PageSpinner />;
  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer>
        <Row>
          <Col width={4}>
            <Heading
              text={isUpdating ? "User. Updating..." : "User"}
              description="Main user information."
            />
          </Col>
          <Col width={2}></Col>
          <Col width={6}>
            <InlineControls>
              {hasAccess(Permissions.Mutation.LoginAsUser) && (
                <BasicControl
                  onClick={handleLoginAs}
                  label="Login as this User"
                />
              )}
              &nbsp;&nbsp;
              {hasAccess(Permissions.Mutation.SyncUserWithAC) && (
                <BasicControl onClick={handleSyncWithAC} label="Sync With AC" />
              )}
              {acPushState.enabled &&
                metaState.activation_push_enabled &&
                hasAccess(Permissions.Mutation.ActivationPush) && (
                  <React.Fragment>
                    &nbsp;&nbsp;
                    <BasicControl
                      onClick={handleActivationPush}
                      label={acPushState.label}
                    />
                  </React.Fragment>
                )}
              {formData.account_state === "DELETING" && (
                <React.Fragment>
                  &nbsp;&nbsp;
                  <BasicControl
                    onClick={handleCancelUserDeleting}
                    label="Cancel Deleting"
                  />
                </React.Fragment>
              )}
            </InlineControls>
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row>
              <Col width={4}>
                <SelectField
                  value={formData.landlord_id}
                  values={landlords}
                  label="Parent Account"
                  name="landlord_id"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="email"
                  isRequired={true}
                />
              </Col>
              <Col width={2}>
                <SelectField
                  value={formData.label_id}
                  values={labels}
                  label="Label"
                  name="label_id"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="name"
                  isRequired={true}
                />
              </Col>
              <Col width={4}></Col>
              <Col width={2} extraStyles="flex justify-end">
                <ReadOnlyField
                  value={createdMethod}
                  label="Created Method"
                  extraStyles="flex justify-end"
                />
              </Col>
            </Row>
            <Row>
              <Col width={6}>
                <TextField
                  name="email"
                  label="Email"
                  value={formData.email}
                  onChange={handleFieldOnChange}
                  error={fieldError("email")}
                  isRequired={true}
                />
              </Col>
              <Col width={3}>
                <TextField
                  name="firstname"
                  label="First Name"
                  value={formData.firstname}
                  onChange={handleFieldOnChange}
                  error={fieldError("firstname")}
                  isRequired={true}
                />
              </Col>
              <Col width={3}>
                <TextField
                  name="lastname"
                  label="Last Name"
                  value={formData.lastname}
                  onChange={handleFieldOnChange}
                  error={fieldError("lastname")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={2}>
                <SelectField
                  value={formData.access_type}
                  values={TENANT_ACCESS_TYPES}
                  label="Access Type"
                  name="access_type"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="name"
                  error={fieldError("access_type")}
                  isRequired={true}
                />
              </Col>
              <Col width={2}>
                <ToggleCheckboxField
                  id="promo_premium_access"
                  name="promo_premium_access"
                  label="Promo Premium Access"
                  isChecked={formData.promo_premium_access}
                  onChange={handleToggleCheckbox}
                />
              </Col>
              <Col width={4} />
              <Col width={2}>
                <DateField
                  name="tenancy_start"
                  label="Tenancy Start"
                  value={formData.tenancy_start}
                  onChange={handleFieldOnChange}
                  error={fieldError("tenancy_start")}
                />
              </Col>
              <Col width={2}>
                <DateField
                  name="tenancy_end"
                  label="Tenancy End"
                  value={formData.tenancy_end}
                  onChange={handleFieldOnChange}
                  error={fieldError("tenancy_end")}
                />
              </Col>
            </Row>
            <Row>
              <Col width={2}>
                <SelectField
                  value={formData.gender}
                  values={GENDERS}
                  label="Gender"
                  name="gender"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="name"
                  error={fieldError("gender")}
                />
              </Col>
              <Col width={2}>
                <SelectField
                  value={formData.age_range}
                  values={AGE_BRACKETS}
                  label="Age Range"
                  name="age_range"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="name"
                  error={fieldError("age_range")}
                />
              </Col>
            </Row>
            <Row>
              <Col width={6}>
                <TextField
                  name="address"
                  label="Address"
                  value={formData.address}
                  onChange={handleFieldOnChange}
                  error={fieldError("address")}
                />
              </Col>
              <Col width={2}>
                <TextField
                  name="towncity"
                  label="Towncity"
                  value={formData.towncity}
                  onChange={handleFieldOnChange}
                  error={fieldError("towncity")}
                />
              </Col>
              <Col width={1}>
                <TextField
                  name="postcode"
                  label="Postcode"
                  value={formData.postcode}
                  onChange={handleFieldOnChange}
                  error={fieldError("postcode")}
                />
              </Col>
              <Col width={3}>
                <TextField
                  name="telephone"
                  label="Telephone"
                  value={formData.telephone}
                  onChange={handleFieldOnChange}
                  error={fieldError("telephone")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                <RichTextEditorField
                  name="notes"
                  label="Notes"
                  value={formData.notes ? formData.notes : ""}
                  onChange={handleFieldOnChange}
                  error={fieldError("notes")}
                  rows={5}
                />
              </Col>
            </Row>
          </FormData>
          <FormControls>
            {hasAccess(Permissions.Mutation.UpdateUser) && (
              <SaveButton onClick={handleSave} />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default TenantPrimary;
