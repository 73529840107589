import React from "react";
import PrimaryKPIReport from "./../components/reports/PrimaryKPIReport";
import { 
  PageContainer, 
  BlockContainer, 
  Row,
  Col,
} from "./../mood-ui/Layout";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";

const Dashboard = () => {
  const { hasAccess } = usePermissions();
  document.title = 'Control'
  return (
    <PageContainer>
      <BlockContainer>
        {hasAccess(Permissions.Query.PrimaryKPIReport) && (
          <div className="w-full max-w-[1440px] mx-auto px-[16px]">
            <Row tweaks="px-4">
              <Col width={12} extraStyles="flex-wrap flex justify-center">
                <div></div>
                <PrimaryKPIReport landlordId={0} />
              </Col>
            </Row>
          </div>
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default Dashboard;
 