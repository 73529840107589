import React, { useState } from "react";

const NO_LIST_TITLE = "--- no list ---";

const DynamicLists = ({
  lists,
  activeListId,
  onChange = () => {},
  onSaveList = () => {},
  canSaveList = false,
}) => {
  const [visible, setVisible] = useState(false);
  const hideFilter = () => {
    setVisible(false);
  };
  const showFilter = () => {
    setVisible(true);
  };

  const activeList = lists.find((l) => l.id === activeListId);

  const listCssClass = visible
    ? "origin-top-right absolute left-0 mt-1 w-56 rounded-md shadow-lg"
    : "origin-top-right absolute left-0 mt-1 w-56 rounded-md shadow-lg hidden";

  return (
    <div
      className="relative inline-block text-left pb-1 pr-1 pl-1 mr-2"
      onMouseEnter={() => {
        showFilter();
      }}
      onMouseLeave={() => {
        hideFilter();
      }}
    >
      <div className="flex">
        <span className="rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-1 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          >
            {activeList && activeList.name}
            {!activeList && NO_LIST_TITLE}
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
        {canSaveList && activeList && activeList.canEdit && (
          <button
            type="button"
            className=" ml-3 inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
            onClick={() => onSaveList()}
          >
            Save Changes
          </button>
        )}
      </div>

      <div className={listCssClass}>
        <div
          className="rounded-md bg-white shadow-xs"
          style={{ maxHeight: "350px", overflowY: "scroll" }}
        >
          <div className="py-1">
            <a
              key={0}
              className="block px-3 py-1 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              style={{ cursor: "pointer" }}
              onClick={() => onChange(0)}
            >
              {NO_LIST_TITLE}
            </a>
            {lists &&
              lists.length > 0 &&
              lists.map((l) => (
                <a
                  key={l.id}
                  className="block px-3 py-1 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  style={{ cursor: "pointer" }}
                  onClick={() => onChange(l.id)}
                >
                  {l.name}
                </a>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicLists;
