import React, { useState } from "react";

const initialState = {
  isVisible: false,
  heading: "",
  message: "",
};

const useNotification = () => {
  const [state, setState] = useState(initialState);

  const notify = ({ heading, message, secondsToDisplay }) => {
    setState({
      isVisible: true,
      heading,
      message,
    });
    const milliseconds = parseInt(secondsToDisplay * 1000);
    setTimeout(() => {
      setState({ isVisible: false, heading: "", message: "" });
    }, milliseconds);
  };

  const handleClose = () => {
    setState({ heading: "", message: "", isVisible: false });
  };

  return { notify, notificationState: state, handleClose };
};

export default useNotification;
