import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { bulkOperationFullDetails } from "./../../graphql/queries/landlordQueries";
import useBaseNavigation from "./../../hooks/baseNavigation";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useTypeFilters from "./../../hooks/typeFilters";
import { BULK_OPERATION_TYPE_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../mood-ui/Layout";
import { Heading } from "./../../mood-ui/Typography";
import TypeFilters from "../../mood-ui/filters/zzzTypeFilters"; 
import { ItemsNotFound, ColorLabel } from "./../../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
} from "./../../mood-ui/DataTable";

const BULK_OPERATION_FULL_DETAILS = gql(bulkOperationFullDetails);

const TenantBulkOperationDetails = () => {

  const { protectPage } = usePermissions();
  
  protectPage(Permissions.Query.ClientBulkOperation);
  
  const { id: clientId, bulk_operation_id: id } = useParams();
  
  const { search: queryString } = useLocation();
  
  const { data, loading, error } = useQuery(BULK_OPERATION_FULL_DETAILS, {
    variables: {
      id,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });
  
  const { applyRawFilters: applyFilters, resetRawFilters: resetFilters } =
    useBaseNavigation(BULK_OPERATION_TYPE_MODEL_NAME, {
      urlContext: `clients/${clientId}/bulk-operations/${id}/view`,
    });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    resetFilters: onResetFilters,
    removeFilterValue: onRemoveFilterValue,
    applyFilters: onApplyFilters,
    clearFilter: onClearFilter, 
    selectManyFilter: onSelectManyFilter, 
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "bulkOperationDetails",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: queryString,
  });

  if (error) return <div>Error</div>;

  // if (!typeFiltersReady) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Row tweaks="border-b border-[#dfe2e8] pb-5 mx-5">
          <Col width={4}>
            <Heading
              inset={true}
              text={`Operation #${data?.bulkOperationFullDetails?.id || ''}`}
              description="A log of records for this operation."
            />
          </Col>
          <Col width={8}>
            {typeFiltersReady && (
              <TypeFilters
                data={typeFilters}
                onApply={onApplyFilters}
                onReset={onResetFilters}
                onRemoveValue={onRemoveFilterValue}
                hasActiveFilters={hasActiveFilters}
                onUpdate={onUpdateFilter}
                selectedFilters={selectedFilters}
                onClearFilter={onClearFilter}
                onSelectManyFilter={onSelectManyFilter}              
              />
            )}
          </Col>
        </Row>
        <TableContainer tweaks="px-5 mt-8">
          <TableView tweaks={(loading || !data || (data && data.bulkOperationFullDetails && data.bulkOperationFullDetails.details?.length === 0)) ? 'border-[#ebedf2]' : ''}>
            <THeading tweaks={(loading || !data || (data && data.bulkOperationFullDetails && data.bulkOperationFullDetails.details?.length === 0)) ? 'opacity-30 border-b' : ''}> 
              <TRow>
                <THCol tweaks="text-left" name="action" value="Action" />
                <THCol tweaks="text-left" name="email" value="Email" />
                <THCol tweaks="text-left" name="message" value="Details" />
                <THCol tweaks="text-center" name="status" value="Status" />
              </TRow>
            </THeading>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <PageSpinner inline extraStyles="h-[300px]" />
                  </td>
                </tr>
                </tbody>
              ) 
              : ((data && data.bulkOperationFullDetails 
                  && data.bulkOperationFullDetails.details 
                  && data.bulkOperationFullDetails.details.length == 0
                ) ? (
                  <tbody>
                    <tr>
                      <td colSpan={"100%"}>
                        <ItemsNotFound text="No previous operations found." tweaks="mx-5" />
                      </td>
                    </tr>
                  </tbody>
                )
                :
                ((data && data.bulkOperationFullDetails 
                  && data.bulkOperationFullDetails.details 
                  && data.bulkOperationFullDetails.details.length > 0) 
                  && (
                    <TContent>
                      {data.bulkOperationFullDetails.details.map((i) => (
                        <TRow key={i.id}>
                          <TCol 
                            tweaks="font-mono slashed-zero tabular-nums text-left uppercase text-xs"
                            value={i.operation} 
                          />
                          <TCol 
                            tweaks="max-w-[120px] font-mono slashed-zero tabular-nums text-left text-xs" 
                            value={i.email} 
                          />
                          <TCol 
                            tweaks="max-w-[220px] font-mono slashed-zero tabular-nums text-left uppercase text-xs" 
                            value={i.message} 
                          />
                          <TCol tweaks={`w-[50px] font-mono slashed-zero tabular-nums text-center uppercase text-xs
                            ${
                              ( i.status == 'Applied' )
                              ? ' text-emerald-600 bg-emerald-200'
                              : ( i.status == 'Skipped' )
                              ? ' text-yellow-600 bg-yellow-200'
                              : ' text-rose-500 bg-rose-100'
                            }`
                          }>
                            { i.status } 
                          </TCol>
                        </TRow>
                      ))}
                    </TContent>
                  )
                )
              )
            }
          </TableView>
        </TableContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default TenantBulkOperationDetails;
