export const deleteOrder = `mutation deleteOrder($id: Int!) {  
    deleteOrder(id: $id) {
      result
    }
  }
  `;

export const placeOrder = `mutation placeOrderRaw($id: Int!) {  
  placeOrderRaw(id: $id) {
      result
    }
  }
  `;
