export const uploadFileMutation = `mutation uploadFile($file: File!, $storageId: String) {
    uploadFile(file: $file, storageId: $storageId) {
      id
      original_name
      filename
      filepath
      public_url
      title
      description
      mimetype
      size
      width
      height
    }
  }
`;
