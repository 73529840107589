import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allSections } from "./../graphql/queries/benefitQueries";
import { deleteSectionsMutation } from "./../graphql/mutations/benefitMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useListSelection from "./../hooks/rowSelection";
import useNotification from "./../hooks/notifications";
import useModal from "./../hooks/modal";
import { SECTION_MODEL_NAME } from "./../configs/constants";
import { ConfirmActionModal } from "./../mood-ui/Modals";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../mood-ui/Notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import { PageControls, TEditButton, AddButton } from "./../mood-ui/Controls";
import { Heading } from "./../mood-ui/Typography";
import Search from "./../mood-ui/Search";
import { ListItemCheckbox } from "./../mood-ui/Controls";
import { ItemsNotFound } from "./../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol, 
  TableSelectedActions,
  Pagination,
} from "./../mood-ui/DataTable";
import SectionListActions from "./../components/list-secondary-actions/SectionListActions";
import useItemCounter from "../hooks/itemCounter";
import OfferTile from "../mood-ui/content/OfferTile";

const ALL_SECTIONS = gql(allSections);

const DELETE_SECTIONS = gql(deleteSectionsMutation);

const SectionList = ({ entityDetails: { offers, cards, merchants } }) => {
  
  const { protectPage, hasAccess } = usePermissions();
  
  protectPage(Permissions.Query.Sections);
  
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
    isAllRowsSelected, 
    toggleAllRows, 
  } = useListSelection();
  
  const { 
    // id: ownerId, 
    page, 
    limit, 
    orderBy, 
    orderHow, 
    search 
  } = useParams();
  
  const { search: queryString } = useLocation();
  
  const { modalState, showModal, closeModal } = useModal();

  const getRelatedOffers = (offerIds = '') => {
    if (!offers || !cards || !merchants) return null
    let ids = offerIds?.replace(/\s/g, '').split(',')
    return ids.map(id => [ ...offers, ...cards ].find(el => el.id == id) )
  }

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();
  
  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();
  
  const { data, loading, error } = useQuery(ALL_SECTIONS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "sections" });

  const {
    nextPage,
    prevPage,
    editForm,
    createForm,
    applySearch,
  } = useBaseNavigation(SECTION_MODEL_NAME, { showingItemsCount: itemsTotal });

  const [
    deleteSections, 
    // { loading: deletingSections }
  ] = useMutation(
    DELETE_SECTIONS,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Sections Deleted",
          message: "Successfully deleted selected sections",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to delete selected sections",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.deleteSections) return false;

        const deletedIds = data.deleteSections.map((cl) => cl.id);
        const existingSections = cache.readQuery({
          query: ALL_SECTIONS,
          variables: {
            page,
            limit,
            orderBy,
            orderHow,
            search,
          },
        });
        const newSections = existingSections.sections.filter(
          (cl) => deletedIds.indexOf(cl.id) === -1
        );
        cache.writeQuery({
          query: ALL_SECTIONS,
          variables: {
            page,
            limit,
            orderBy,
            orderHow,
            search,
          },
          data: { sections: newSections },
        });
      },
    }
  );

  const confirmDeletion = () => {
    showModal({
      heading: "Confirm Deletion",
      message: "Are you sure you want to DELETE selected sections? Do not proceed if you are not sure.",
      mood: "Danger",
      confirmButtonLabel: "Delete",
      onConfirm: () => {
        closeModal();
        handleDeleteSections();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteSections = () => {
    deleteSections({ variables: { ids: selectedRows } });
  };

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-5 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Sections"
              description="Control sections used on the platform."
            />
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Mutation.CreateSection) && (
              <AddButton label="Add section" onClick={createForm} loading={loading}  />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row> 
        <TableContainer tweaks="px-5 pb-5">
          <TableView>
            <THeading>
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() => toggleAllRows(data?.sections?.map(section => section.id))}
                    isChecked={isAllRowsSelected(data?.sections?.map(section => section.id)) ? true : false}
                  />
                </THCol>
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="title" value="Title" />
                <THCol tweaks="text-left" name="description" value="Description" />
                <THCol tweaks="text-left" name="offer_ids" value="Offer IDs" />
                <THCol tweaks="w-16 text-center" />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.sections && data.sections.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <ItemsNotFound text="No sections found" tweaks="" />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.sections && data.sections.length > 0 && (
              <TContent>
                {data.sections.map((s, rowIndex) => (
                  <TRow key={s.id} isSelected={rowSelected(s.id)}>
                    <TCol>
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(s.id)}
                        isChecked={rowSelected(s.id) ? true : false}
                      />
                    </TCol>
                    <TCol value={s.id} />
                    <TCol tweaks="max-w-[100px]">
                      <abbr title={s.title}>
                        {s.title}
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[180px]">
                      <abbr title={s.description}>
                        {s.description}
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[600px] px-[2px] pt-0 pb-0 bg-slate-100 border-white overflow-visible inline-flex flex-wrap items-center gap-[2px]" truncate={false}>
                      {
                        getRelatedOffers(s.offer_ids)?.map((offer, index) => {
                          return (
                            <OfferTile
                              key={index}
                              offer={offer}
                              rows={data.sections.length || 0}
                              index={rowIndex}
                            />
                          )
                        })
                      }
                    </TCol>
                    <TCol tweaks="w-16 text-center">
                      {hasAccess(Permissions.Query.Section) && (
                        <TEditButton onClick={() => editForm(s.id)} />
                      )}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <SectionListActions
                      onClearSelection={clearSelection}
                      onDelete={confirmDeletion}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={7} extraStyles="">
              </Col>
              <Col width={3}>
                <Pagination onPrev={page > 1 ? prevPage : false} onNext={data?.sections?.length >= limit - 1 ? nextPage : false} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default SectionList;
