import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { merchantDetails } from "./../graphql/queries/benefitQueries";
import { updateMerchantMutation } from "./../graphql/mutations/benefitMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useNotification from "./../hooks/notifications";
import useModal from "./../hooks/modal";
import useHyperState from "./../hooks/hyperState";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../mood-ui/Layout";
import { ErrorNotification } from "./../mood-ui/Notifications";
import {
  FormControls,
  SaveButton,
  CancelButton,
} from "./../mood-ui/Controls";
import { Heading } from "./../mood-ui/Typography";
import { SelectField, TextField } from "./../mood-ui/Fields";
import PageSpinner from "./../mood-ui/PageSpinner";
import { MERCHANT_MODEL_NAME } from "../configs/constants";
import RichTextEditor from "./../mood-ui/RichTextEditor";
import ImageGalleryModal from "./../mood-ui/ImageGalleryModal";

const MERCHANT_DETAILS = gql(merchantDetails);
const UPDATE_MERCHANT = gql(updateMerchantMutation);

const Merchant = () => {
  
  const { protectPage } = usePermissions();
  
  protectPage(Permissions.Mutation.CreateMerchant);
  
  const { id } = useParams(); 

  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();

  const { goBack } = useBaseNavigation(MERCHANT_MODEL_NAME);

  const { modalState, showModal, closeModal } = useModal();

  const {
    state: formData,
    stateMethods,
    setState: setFormData,
    syncView, 
  } = useHyperState("formData", {
    title: "",
    slug: "",
    status: "Inactive",
    description: "",
    image: "",
    background: "",
    related_merchants: "",
  });

  const { error, loading } = useQuery(MERCHANT_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.merchant) return false;
      setFormData({ ...result.merchant });
    },
  });

  const [updateMerchant, {loading: isSaving}] = useMutation(UPDATE_MERCHANT, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
      displayErrorNotification({
        heading: "Error",
        message:
          "Error occured when trying to update Merchant. Most likely the slug is already taken",
        secondsToDisplay: 5,
      });
    },
  });

  const { setFieldValue: handleFieldOnChangeProxied } = useBaseFieldSetters(
    formData,
    stateMethods,
    clearError
  );

  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleFieldOnChangeProxied(e)
  }

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const handleImageSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        handleFieldOnChange({
          target: { name: "image", value: file.public_url },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackgroundSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        handleFieldOnChange({
          target: { name: "background", value: file.public_url },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    const data = { ...formData };
    updateMerchant({ variables: data });
  };

  const [isDraft, setDraft] = useState(false)

  if (error) return <div>Error...</div>;
  if (!formData || loading) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update merchant"
              description="A merchant acts as a parent entity for offers and reward cards. Anything assigned to a merchant
                          will live on the same merchant page in the platform."
              />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Merchant details"
                      description="Control the information associated with this merchant."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="title"
                      label="Title"
                      value={formData.title}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("title")}
                      isRequired={true}
                      maxlength={72}
                      description="Visible to members"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="slug"
                      label="URL slug"
                      value={formData.slug}
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("slug")}
                      isRequired={true}
                      maxlength={64}
                      description="Unique path for the URL"
                      pattern="^[a-z0-9]+(?:-[a-z0-9]+)*"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.status}
                      values={[
                        { id: "Active", name: "Active" },
                        { id: "Inactive", name: "Inactive" },
                      ]}
                      label="Status"
                      name="status"
                      onChange={(e) => { handleFieldOnChange(e); }}
                      valueKey="id"
                      labelKey="name"
                      isRequired={true}
                      error={fieldError("status")}
                      description="Show or hide this merchant"
                    />
                  </Col>
                  <Col width={8}>
                    <RichTextEditor
                      cols={5}
                      rows={5}
                      name="description"
                      value={formData.description}
                      label="Description"
                      onChange={(e) => { handleFieldOnChange(e); }}
                      error={fieldError("description")}
                      isRequired={true}
                      description="Use (ctrl/cmd) + shift + V to paste text without breaking the formatting"
                    />
                  </Col>
                  <Col width={4}>
                    <TextField
                      name="related_merchants"
                      label="Related Merchants"
                      value={formData.related_merchants}
                      onChange={(e) => { handleFieldOnChange(e); syncView(); }}
                      error={fieldError("related_merchants")}
                      description="Comma-separated list of IDs"
                      maxlength={128}
                      tweaks="pr-8"
                      pattern="^([0-9]+)(,\s?[0-9]+)*\s?$"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
                                {/* <Row>
                                  <Col width={4}>
                                    <TextField
                                      name="title"
                                      label="Title"
                                      value={formData.title}
                                      onChange={handleFieldOnChange}
                                      error={fieldError("title")}
                                      isRequired={true}
                                    />
                                  </Col>
                                  <Col width={4}>
                                    <TextField
                                      name="slug"
                                      label="Slug"
                                      value={formData.slug}
                                      onChange={handleFieldOnChange}
                                      error={fieldError("slug")}
                                      isRequired={true}
                                    />
                                  </Col>
                                  <Col width={2}></Col>
                                  <Col width={2}>
                                    <SelectField
                                      value={formData.status}
                                      values={[
                                        { id: "Active", name: "Active" },
                                        { id: "Inactive", name: "Inactive" },
                                      ]}
                                      label="Status"
                                      name="status"
                                      onChange={handleFieldOnChange}
                                      valueKey="id"
                                      labelKey="name"
                                      error={fieldError("status")}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col width={12}>
                                    <Heading text="Images & Description" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col width={2}>
                                    <FullWHButton
                                      onClick={handleImageSelectModal}
                                      label="Open Gallery"
                                    />
                                  </Col>
                                  <Col width={8}>
                                    <TextField
                                      name="image"
                                      label="Image"
                                      value={formData.image}
                                      onChange={handleFieldOnChange}
                                      error={fieldError("image")}
                                      isRequired={true}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col width={2}>
                                    <FullWHButton
                                      onClick={handleBackgroundSelectModal}
                                      label="Open Gallery"
                                    />
                                  </Col>
                                  <Col width={8}>
                                    <TextField
                                      name="background"
                                      label="Background"
                                      value={formData.background}
                                      onChange={handleFieldOnChange}
                                      error={fieldError("background")}
                                      isRequired={true}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col width={10}>
                                    <RichTextEditor
                                      cols={5}
                                      rows={10}
                                      name="description"
                                      value={formData.description}
                                      label="Description"
                                      onChange={handleFieldOnChange}
                                      error={fieldError("description")}
                                      isRequired={true}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col width={8}>
                                    <TextField
                                      name="related_merchants"
                                      label="Related Merchants (IDs)"
                                      description="List of IDs of related merchants. Merchants must be real, but can be not active."
                                      value={formData.related_merchants}
                                      onChange={handleFieldOnChange}
                                      error={fieldError("related_merchants")}
                                    />
                                  </Col>
                                </Row>*/}
            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Artwork"
                      description="With the exception of logos, images should not contain text, should not
                                  include promotions, and should not be seasonal. Use descriptive file names 
                                  so images can be found via search in the future. 
                                  E.g. tesco-logo.png, hotels-com-background.jpeg.
                                  "
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8} extraStyles="flex items-around align-center flex-wrap select-none relastive">
                <div className={`flex items-censter justify-center relative w-full aspect-[16/7] cursor-pointer relative `}>
                  <div 
                    className={`h-[55%] aspect-[1/1] border-2 z-40 border-white cursor-pointer absolute top-7 left-7`}
                    onClick={handleImageSelectModal}
                  >
                    <img
                      src={
                        formData.image && formData.image.length > 0 
                        ? formData.image
                        : 'https://content.vaboo.co.uk/images/20230119_005519_logo-image.png'
                      }
                      alt="Logo"
                      className={`object-fill w-full hover:outline-none hover:shadow-outline peer`}
                      crossOrigin="anonymous"
                      onError={e => { e.target.className += ' opacity-0'; }}
                      onLoad={e => { e.target.parentNode.className += ' bg-red-600 outline outline-white outline-1 outline-offset-[-3px]'; e.target.className = e.target.className.replace(/opacity-0/g,"");  }}
                    />
                    <span className="absolute text-xs leading-2 top-1/2 -translate-y-1/2 left-0 right-0 p-3 text-center text-red-300 -z-1 invisible peer-[.opacity-0]:visible">
                      There was an error. Please try another image.
                    </span>
                  </div>
                  <div 
                    className={`aspect-[16/7] cursor-pointer hover:outline-none hover:shadow-outline `}
                    onClick={handleBackgroundSelectModal}
                  >
                    <img
                      src={
                        formData.background && formData.background.length > 0 
                        ? formData.background
                        : 'https://content.vaboo.co.uk/images/20230119_005706_background-image.png'
                      }
                      alt="Background"
                      className={`object-contain max-h-full w-full peer`}
                      crossOrigin="anonymous"
                      onError={e => { e.target.className += ' opacity-0'; }}
                      onLoad={e => { e.target.parentNode.className += ' bg-red-600'; e.target.className = e.target.className.replace(/opacity-0/g,"");  }}
                    />
                    <span className="absolute top-8 right-8 text-red-300 -z-1 invisible peer-[.opacity-0]:visible">
                      There was an error. Please try another image.
                    </span>
                  </div>
                  {(!formData.background || !formData.background.length) && (
                    <div className={`bg-black opacity-5 h-full w-full absolute top-0 left-0 pointer-events-none `}></div>
                  )}
                </div>
                <p className="mt-1.5 text-xs text-gray-400 pl-0.5 select-none">
                  A red border will appear around an image with sizing issues
                </p>
              </Col>
            </Row>
            {/* <TextField
              name="image"
              label="Image"
              value={formData.image}
              onChange={handleFieldOnChange}
              error={fieldError("image")}
              isRequired={true}
            />
            <TextField
              name="background"
              label="Background"
              value={formData.background}
              onChange={handleFieldOnChange}
              error={fieldError("background")}
              isRequired={true}
            /> */}

          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} loading={isSaving} disabled={!isDraft || formHasErrors()} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
      <ImageGalleryModal {...modalState} />
    </PageContainer>
  );
};

export default Merchant;
