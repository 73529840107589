import React, { useRef, useEffect } from "react"
import Moment from "react-moment"
import { TEditButton } from "../Controls"

import { activationColor, npsColor } from "../../hooks/utils"

const mergeString = (row, string) => {
  let parts = string.split('::')
  let partsNew = []
  parts.forEach(p => {
    partsNew.push(p.charAt(0) == '#' ? row[p.slice(1)] : p)
  })
  return partsNew.join('')
}

const getPercentScale = (percent, nps = false) => {
  let left = 0, right = 0
  const target = parseFloat(percent)
  const isNeg = target < 0
  const dist  = Math.abs(target)
  if (isNeg) {
    left = 50 - (Math.floor(dist) / 2)
    left = left == 0 ? 0 : left
    right = 50
  }
  else {
    right = 50 - (Math.floor(dist) / 2)
    right = right == 0 ? 0 : right
    left = 50
  }
  return ` left-[${ left }%] right-[${ right }%] rounded-${ left == 50 ? 'l-none' : 'r-none' } bg-${ nps ? (npsColor(percent)) : activationColor(dist * (isNeg ? -1 : 1) + 50) }-500 } `
}

const getDateScale = (date, colScope, range) => {
  let left = 0, right = 0
  let first  = new Date(colScope?.length > 1 ? colScope[0].name : '').valueOf()
  let last   = new Date(colScope?.length > 1 ? colScope[1].name : '').valueOf()
  let now    = new Date().valueOf()
  let target = new Date(date).valueOf()
  let isPast = now > first
  let dist  = Math.abs(now - target)
  if (!range && !isPast) {
    right = Math.floor(dist / Math.abs(now - last) * 100)
  }
  else if (!range && isPast) {
    left = 100 - Math.floor((dist / Math.abs(now - first)) * 100)
  }
  else {
    let max = Math.max(
      Math.abs(now - first),
      Math.abs(now - last),
    )
    if (!isPast) {
      left = Math.floor(dist / (max) * 100) / 2
      right = 50
    }
    else {
      right = Math.floor(dist / (max) * 100) / 2
      left = 50
    }
  }
  return ` left-[${ left }%] right-[${ right }%] } `
}

const ListCell = ({
  isFocusCol = false,  
  isFocusRow = false,  
  isFocusCell = false, 
  cellFocus = [-1,-1], 
  canClick = false, 
  tweaks = '', 
  onClick = () => {}, 
  row = null, 
  cell = null, 
  rowSelected = null, 
  onView, 
  value = null,
  colScope = null, 
  children, 

}) => {

  const cellRef = useRef()

  useEffect(() => {
    if (isFocusCell === true) {
      requestAnimationFrame(()=>cellRef?.current?.focus())
    }
  }, [isFocusCell])

  return (

    <td 
      tabIndex={-1}
      ref={cellRef}
      className={`
        relative border-collapsse bg-white
        px-2 py-[3px] whitespace-no-wrap truncate border border-gray-100 leading-3 
        h-[30px]
        ${ tweaks }
        ${  ((value !== null && value !== '') || cell.type == 'view') && (cell?.likeNull?.findIndex(v => v == value) < 0) && isFocusCell && !cell.isMainImage
              ? ' bg-[#ffffff] z-[9] p-[1px] transform scale-[1.2] outline outline-[2px] outline-offset-[-1.5px] outline-[#ECDAF5] text-gray-600 opacity-[100%] ' 
          : ((value !== null && value !== '') || cell.type == 'view') && (cell?.likeNull?.findIndex(v => v == value) < 0) && (isFocusRow || isFocusCol) && !cell.isMainImage
              ? ' bg-[#f9f9fd] z[-2] text-gray-500 focus:outline-none ' 
          : ((value !== null && value !== '') || cell.type == 'view') && (cell?.likeNull?.findIndex(v => v == value) < 0) 
              ? ' z[-2] text-gray-500 ' 
          : (value == '' || value == null || (cell?.likeNull?.findIndex(v => v == value) > -1)) && isFocusCell && !cell.isMainImage
              ? ' bg-[#F4F4FB] outline outline-[2px] outline-offset-[-0px] outline-[#ECDAF5] z-[5]' 
          : (value == '' || value == null || (cell?.likeNull?.findIndex(v => v == value) > -1)) && (isFocusRow || isFocusCol) 
              ? ' darkest bg-[#f0f0fa] focus:outline-none z[-2] ' 
          : (value == '' || value == null || (cell?.likeNull?.findIndex(v => v == value) > -1))
              ? ' bg-[#f4f4fb] z[-2] '
          : ' z[-2] focus:outline-none text-gray-500'
        }
        ${ isFocusCell && cellFocus[1] == 0 && children !== '' ? ' translate-y-[2px]' : ' origin-center' } 
        ${ isFocusCell && cellFocus[0] == 0 && children !== '' ? ' translate-x-[-4px]s' : ' origin-center' } 
        ${ isFocusRow && !canClick && !cell.isMainImage ? ' bg-[#fbfafd]' : '' } 
        ${ canClick && children !== '' ? ' cursor-pointer' : '' } 
        font-mono slashed-zero tabular-nums text-[10.5px] max-w-[${ cell.cellWidth - 16 }px] ${ mergeString(row, cell.tdClass) }
      `}
      onClick={onClick}
      onDoubleClick={e => { e.preventDefault(); onView() }}
    >

      { 
        cell?.type == 'image' && value ? 
          <div className="leading-none">
            { cell.imageAlt && 
              <div className="opacity-0 absolute">
                {mergeString(row, cell.imageAlt)}
              </div>
            }
            <img
              src={value} alt={`${ mergeString(row, cell.imageAlt) }`} 
              className={`select-none ${ cell.imageClass && cell.imageClass } ${ rowSelected(row.id) && 'opacity-75' }`}
            />
          </div>

        : cell?.type == 'text' && value ? 
            <abbr title={ value } className="truncate text-elipsis max-w-[90px]">
              { value }
            </abbr> 

        : cell?.type === 'date' && value ? 
          <div 
            className={`
              leading-none tracking-widest mx-auto text-center 
              ${ isFocusCell && isFocusRow ? 'pb-[1px] -mt-[1px]' : '' }
            `}
          >
            <abbr title={ (cell.hover ? row[cell.hover] : value) + (cell.hoverText ? cell.hoverText : '')} className="inline-flex">
              <Moment format="DD&middot;MMM'YY" date={value} /> 
            </abbr>
            <div
                className={`
                  bg-[#eeeff2]
                  absolute 
                  ${ isFocusCell && isFocusRow
                    ? ' mb-[-1px] h-[4px] rounded-[0px] bottom-[3px] left-[1px] right-[2px] ' 
                    : ' mb-[-1.5px] h-[5px] bottom-0 right-0 left-0 ' }
                `}
              />
            <div
              className={`
                mb-[-1.5px] h-[5px] absolute bottom-0 
                bg-rose-500 
                ${ getDateScale(value, colScope, cell.range || false) }
                ${ isFocusCell && isFocusRow
                  ? ' mb-[-1px] h-[4px] rounded-[0px] bottom-[3px] mr-[2px] min-w-[2px] max-w-[calc(50%-2px)]' 
                  : ' mb-[-1.5px] h-[5px] bottom-0 min-w-[2px]' }
              `}
            />
          </div> 

        : cell?.type === 'number' && value !== null && (cell?.likeNull?.findIndex(v => v == value) < 0 ) ? 
            <div 
              className={`
                text-center mx-auto 
                ${ value == 0 ? 'text-gray-300 ' : value !== 0 ? ' max-w-[100px] ' : ' text-transparent' } 
              `}
            > 
              <abbr title={ (cell.hover ? row[cell.hover] : value) + (cell.hoverText ? cell.hoverText : '')} className="inline-flex">
                { parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0] }
              </abbr>
            </div>

        : cell?.type === 'currency' && value !== null && (cell?.likeNull?.findIndex(v => v == value) < 0 ) ? 
            <div className={` 
              leading-none tracking-widest 
              flex w-[66px] mx-auto justify-center text-right
              ${ value == 0 ? 'text-gray-300 ' : value > 0 ? ' max-w-[100px] ' : ' text-transparent' } 
              ${ isFocusCell && isFocusRow ? 'pb-[1px] -mt-[1px]' : '' }
            `}>
              <abbr title={ (cell.hover ? row[cell.hover] : value) + (cell.hoverText ? cell.hoverText : '')} className="inline-flex justify-between w-[56px]">
                <div 
                  className={`
                  mr-auto w-[18px] text-left opacity-60 ${ isFocusCell && isFocusRow ? 'pb-[1px] -mt-[1px]' : '' }
                  `}>£ </div>
                <div>{ 
                  value >= 1000 
                  ? parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
                  : parseFloat(value).toFixed(value >= 100 ? 1 : value >= 10 ? 1 : 2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }</div>
              </abbr>
            </div>

        : (cell?.type === 'percent' || cell?.type === 'nps') && value !== null && (cell?.likeNull?.findIndex(v => v == value) < 0 ) ? 
            <div 
              className={`
                leading-none tracking-widest flex w-[60px] mx-auto justify-end 
                ${ value == 0 && ' text-gray-300' }
              `}
            >
              <abbr 
                title={ (cell.hover ? row[cell.hover] : value) + (cell.hoverText ? cell.hoverText : ' %')} 
                className={`
                  inline-flex justify-between w-[56px]
                  ${ cell?.type === 'percent' ? ' w-fit ' : ' min-w-full text-center ' }
                `}
              >
                <div 
                  className={` 
                    w-full text-right pr-[7px]
                    ${ isFocusCell && isFocusRow ? 'pb-[1px] -mt-[1px]' : '' }
                  `}
                >
                  { 
                    value >= 1000 
                    ? parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
                    : parseFloat(value).toFixed(value === 0 ? 1 : value >= 100 ? 1 : value >= 10 ? 1 : (value < 0.1 && value > -0.1) ? 2 : 1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                  }
                </div>
                { cell?.type === 'percent' && <div className={`w-[14px] opacity-60 ${ isFocusCell && isFocusRow ? 'pb-[1px] -mt-[1px]' : '' }`}>%</div> }
              </abbr>
              <div
                className={`
                  bg-[#eeeff2]
                  absolute 
                  ${ isFocusCell && isFocusRow
                    ? ' mb-[-1px] h-[4px] rounded-[0px] bottom-[3px] left-[1px] right-[2px] ' 
                    : ' mb-[-1.5px] h-[5px] bottom-0 right-0 left-0 ' }
                `}
              />
              { cell.range 
                ? <div>
                    <div
                      className={`
                        absolute 
                        ${ getPercentScale(value, (cell?.type === 'nps' ? true : false)) } 
                        ${ isFocusCell && isFocusRow
                          ? ' mb-[-1px] h-[4px] rounded-[0px] bottom-[3px] mr-[2px] min-w-[2px] max-w-[calc(50%-2px)]' 
                          : ' mb-[-1.5px] h-[5px] bottom-0 min-w-[2px]' }
                      `}
                    />
                  </div>
                : <div
                    className={`
                      absolute
                      right-[${ 100 - parseFloat(value) }%]
                      bg-${ activationColor(parseFloat(value)) }-500
                      ${ isFocusCell && isFocusRow
                        ? ' mb-[-1px] h-[4px] rounded-[0px] bottom-[3px] left-[1px] mr-[2px] min-w-[2px]' 
                        : ' mb-[-1.5px] h-[5px] bottom-0 left-0 min-w-[2px] ' }
                    `}
                  />
              }
            </div> 

        : cell?.type === 'images' && value 
          ? <div className="leading-none min-w-fit">
              { cell.imageAlt && 
                <div className="opacity-0 absolute">
                  {mergeString(row, cell.imageAlt)}
                </div>
              }
              <abbr title={ (cell.hover ? row[cell.hover] : value) + (cell.hoverText ? cell.hoverText : '')} className="select-none">
                <img
                  src={cell.images[value] !== '' ? (cell.images[value] || cell.images['default']) : cell.images['default']} alt="aa"
                  className={`mx-auto opacity-85 h-[12px] min-w-[12px] ${ rowSelected(row.id) ? "opacity-75" : "" }`}
                />
              </abbr>
            </div>

        : cell.type === 'view' ? 
          <div>
            <TEditButton onClick={onView} label={cell.text } />
          </div>

        : ''

      }

    </td>
  )

};


export default ListCell;