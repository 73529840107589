import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import { updateMembershipPricingMutation } from "./../graphql/mutations/billingMutations";
import { membershipPricing } from "./../graphql/queries/billingQueries";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import useNotification from "./../hooks/notifications";
import { FormControls, SaveButton } from "./../mood-ui/Controls";
import { NumberField, ToggleCheckboxField } from "./../mood-ui/Fields";
import {
  BlockContainer,
  Col,
  FormContainer,
  FormData,
  PageContainer,
  Row,
} from "./../mood-ui/Layout";
import {
  ErrorNotification,
  SuccessNotification,
} from "./../mood-ui/Notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { Heading } from "./../mood-ui/Typography";

const MEMBERSHIP_PRICING = gql(membershipPricing);
const UPDATE_MEMBERSHIP_PRICING = gql(updateMembershipPricingMutation);

const MembershipPricing = () => {
  const { id: account_id } = useParams();
  const client_id = account_id ? parseInt(account_id) : 0;
  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Mutation.UpdateClientPaymentSettings);

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    monthly_price: 0,
    annual_price: 0,
    lifetime_price: 0,
    use_global_settings: false,
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const { error, loading } = useQuery(MEMBERSHIP_PRICING, {
    variables: {
      client_id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.membershipPricing) return false;

      setFormData({
        ...result.membershipPricing,
      });
    },
  });

  const [updateMembership, { loading: isUpdating }] = useMutation(
    UPDATE_MEMBERSHIP_PRICING,
    {
      variables: { ...formData },
      onCompleted: (result) => {
        displayNotification({
          heading: "Update successful",
          message: "Membership pricing was successfully updated",
          secondsToDisplay: 2,
        });
        setDraft(false);
      },
      onError: (e) => {
        resolveMutationErrors(e);
        displayErrorNotification({
          heading: "Update failed",
          message: "Failed to update membership pricing",
          secondsToDisplay: 2,
        });
      },
    }
  );

  const handleSave = () => {
    const data = {
      ...formData,
    };
    updateMembership({ variables: data });
  };

  const [_, setDraft] = useState(false);

  if (error) return <div>Error...</div>;
  if (!formData) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update Membership Pricing"
              description=""
            />
          </Col>
        </Row>

        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading size="md" text="Pricing" description="" />
                    <p
                      className={
                        "mt-1 text-sm leading-5 text-gray-400 select-none"
                      }
                    >
                      Setup Monthly, Annual and Lifetime pricing for members of
                      {formData.client_id > 0
                        ? " this client"
                        : " Vaboo App Globally"}
                      .
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  {client_id > 0 && (
                    <React.Fragment>
                      <Col width={4} extraStyles="">
                        <ToggleCheckboxField
                          loading={loading}
                          id="use_global_settings"
                          name="use_global_settings"
                          label="Use Global Settings"
                          isChecked={formData?.use_global_settings}
                          onChange={(e) => {
                            setDraft(true);
                            handleToggleCheckbox(e);
                          }}
                          error={fieldError("use_global_settings")}
                          description="If checked, the global settings will be used for this client."
                        />
                      </Col>
                      <Col width={8}></Col>
                    </React.Fragment>
                  )}
                  {!formData.use_global_settings && (
                    <React.Fragment>
                      <Col width={4} extraStyles="">
                        <NumberField
                          name="monthly_price"
                          label="Monthly Price, £"
                          value={formData.monthly_price}
                          onChange={(e) => {
                            handleFieldOnChange(e);
                          }}
                          error={fieldError("monthly_price")}
                          isRequired={false}
                          loading={loading}
                          maxlength={96}
                          description=""
                          step=".01"
                        />
                      </Col>
                      <Col width={4} extraStyles="">
                        <NumberField
                          name="annual_price"
                          label="Annual Price, £"
                          value={formData.annual_price}
                          onChange={(e) => {
                            handleFieldOnChange(e);
                          }}
                          error={fieldError("annual_price")}
                          isRequired={false}
                          maxlength={96}
                          loading={loading}
                          description=""
                          step=".01"
                        />
                      </Col>
                      <Col width={4} extraStyles="">
                        <NumberField
                          name="lifetime_price"
                          label="Lifetime Price, £"
                          value={formData.lifetime_price}
                          onChange={(e) => {
                            handleFieldOnChange(e);
                          }}
                          error={fieldError("lifetime_price")}
                          isRequired={false}
                          maxlength={96}
                          loading={loading}
                          description=""
                          step=".01"
                        />
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            {hasAccess(Permissions.Mutation.UpdateClientPaymentSettings) && (
              <SaveButton
                onClick={handleSave}
                loading={isUpdating}
                disabled={formHasErrors()}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>

      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default MembershipPricing;
