import React from "react";

const File = ({
  file,
  showCopyLink,
  onSelect,
  onSelectConfirmed,
  isSelected = false,
}) => {
  const mainDivStyles = isSelected
    ? "bg-gray-200 border border-gray-300 rounded border-solid sm:col-span-1 p-5 text-sm leading-5 text-gray-500"
    : "border border-gray-300 rounded border-solid sm:col-span-1 p-5 text-sm leading-5 text-gray-500";
  return (
    <div
      key={file.id}
      className={mainDivStyles}
      onClick={() => {
        onSelect(file);
      }}
      onDoubleClick={() => {
        onSelectConfirmed(file);
      }}
    >
      <div
        style={{
          height: "200px",
          backgroundColor: "#E6E6E6",
          display: "inline-block",
        }}
      >
        <center>
          <img
            src={file.public_url}
            className=""
            style={{ maxHeight: "180px" }}
          />
        </center>
      </div>
      <center>
        {file.filename}
        <br />
        {file.mimetype}
        <br />
        {parseInt(file.size / 1014)} kb
        <br />
        {showCopyLink && (
          <button
            type="button"
            className="mt-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            onClick={(e) => {
              navigator.clipboard.writeText(file.public_url);
              e.stopPropagation();
            }}
          >
            Copy link
          </button>
        )}
      </center>
    </div>
  );
};

export default File;
