import React, { useState } from "react";
import { NumberField, TextField } from "../../../mood-ui/Fields";

const PriceCalculator = ({
  paymentSettings,
  pricing = {},
  computedPrice,
  onCalculate,
}) => {
  const [numberOfPremiumUsers, setNumberOfPremiumUsers] = useState(undefined);
  const handleCalculation = () => {
    const data = {
      ...paymentSettings,
      ...pricing,
      number_of_premium_users: numberOfPremiumUsers,
    };
    delete data.typename;
    delete data.account_id;
    delete data.can_give_basic;
    delete data.can_give_premium;
    delete data.can_upgrade;
    delete data.maintance_fee_monthly_billing_day;
    delete data.must_have_payment_method;
    delete data.acquired_premium_access_fee_monthly;
    delete data.type;
    if (typeof data.tiers !== "undefined") {
      const newTiers = data.tiers.map((t) => {
        const { units_start_range, units_end_range, price } = t;
        // delete t['__typename'];
        return { units_start_range, units_end_range, price };
      });
      data.tiers = newTiers;
    }
    onCalculate({ variables: data });
  }
  return (
    <React.Fragment>
      <div className="flex items-center pl-3">
        <span className="mt-1s flex w-72">
          <div className="w-full">
          <NumberField
            name="number_of_premium_users"
            value={numberOfPremiumUsers}
            min={0}
            max={999999}
            onChange={(e) => {
              if (e.target.value) {
                setNumberOfPremiumUsers(e.target.value); 
              }
            }}
            onEnter={(e) => { handleCalculation() }}
            error={false}
            placeholder="# of users.."
            />
            </div>
          <button onClick={handleCalculation} type="button" className="relative z-10 -mx-px inline-flex items-center border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 text-gray-400">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
            </svg>
          </button>
          <div className="w-full">
            <TextField
              name="number_of_premium_users"
              value={computedPrice ? computedPrice.toLocaleString('en', {useGrouping:true}) : '0.00'}
              placeholder=""
              readOnly
              gbp
            />
          </div>
        </span>
        <span className="ml-3 text-sm text-gray-500">
          per month inc. VAT
        </span>
      </div>
    </React.Fragment>
  );
};

export default PriceCalculator;