import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { sectionDetails } from "./../graphql/queries/benefitQueries";
import { updateSectionMutation } from "./../graphql/mutations/benefitMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import { SECTION_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../mood-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "./../mood-ui/Controls";
import { Heading } from "./../mood-ui/Typography";

import {
  TextField,
  RichTextEditorField,
  ToggleCheckboxField,
} from "./../mood-ui/Fields";
import { LabelContext } from "../contexts/LabelContext";

const SECTION_DETAILS = gql(sectionDetails);
const UPDATE_SECTION = gql(updateSectionMutation);

const Section = () => {
  const {
    protectPage,
    // hasAccess
  } = usePermissions();

  protectPage(Permissions.Query.Section);

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const labelContext = useContext(LabelContext);

  const { id } = useParams();

  const { goBack } = useBaseNavigation(SECTION_MODEL_NAME);

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    id,
    title: "",
    slug: "",
    description: "",
    offer_ids: "",
    basic: false,
    premium_gifted: false,
    premium_acquired: false,
    labels: [],
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
    toggleArrayFieldValue: toggleListValue,
    arrayFieldHasValue: listInclude,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const {
    // data,
    loading,
    error,
  } = useQuery(SECTION_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.section) return false;
      setFormData({
        ...result.section,
        labels: result.section.labels.map((l) => l.id),
      });
    },
    onError: (e) => {
      console.log("e", e);
    },
  });

  const [
    updateSection,
    {
      // data: updateResult,
      loading: isUpdating,
      // error: updateError
    },
  ] = useMutation(UPDATE_SECTION, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    updateSection({ variables: data });
  };

  const [isDraft, setDraft] = useState(false);

  if (error) return <div>Error...</div>;
  if (!formData || loading) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update section"
              description="Sections provide content to the scrolling carousels on the platform home page."
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Section details"
                      description="Control the behaviour of this section in the platform."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4}>
                    <TextField
                      name="title"
                      label="Title"
                      value={formData.title}
                      onChange={handleFieldOnChange}
                      error={fieldError("title")}
                      isRequired={true}
                      minlength={3}
                      maxlength={48}
                      description="Section headline"
                    />
                  </Col>
                  <Col width={3}>
                    <TextField
                      name="slug"
                      label="URL slug"
                      value={formData.slug}
                      onChange={handleFieldOnChange}
                      error={fieldError("slug")}
                      isRequired={true}
                      maxlength={64}
                      description="Unique path for the URL"
                      pattern="^[a-z0-9]+(?:-[a-z0-9]+)*"
                    />
                  </Col>
                  <Col width={5}>
                    <TextField
                      name="offer_ids"
                      label="Offer IDs"
                      value={formData.offer_ids}
                      onChange={handleFieldOnChange}
                      error={fieldError("offer_ids")}
                      description="Comma-separated list of offer ids"
                      tweaks="pr-8"
                      // isRequired={true}
                      pattern="^([0-9]+)(,\s?[0-9]+)*\s?$"
                    />
                  </Col>
                  <Col width={12}>
                    <RichTextEditorField
                      name="description"
                      label="Description"
                      value={formData.description}
                      onChange={handleFieldOnChange}
                      error={fieldError("description")}
                      rows={3}
                      description="Supporting text shown below title"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Privileges"
                      description="Control visibility across membership tiers."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-0 sm:grid-cols-4 py-1 px-px">
                  <Col width={3} extraStyles="sm:col-span-1">
                    <ToggleCheckboxField
                      id="basic"
                      name="basic"
                      label="Basic"
                      isChecked={formData.basic}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      flush
                      description="Is not premium"
                    />
                  </Col>
                  <Col width={3} extraStyles="sm:col-span-1">
                    <ToggleCheckboxField
                      id="premium_gifted"
                      name="premium_gifted"
                      label="Gifted"
                      isChecked={formData.premium_gifted}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      flush
                      description="Was given premium"
                    />
                  </Col>
                  <Col width={3} extraStyles="sm:col-span-1">
                    <ToggleCheckboxField
                      id="premium_acquired"
                      name="premium_acquired"
                      label="Upgraded"
                      isChecked={formData.premium_acquired}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      flush
                      description="Pays for premium access"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="User types"
                      description="Control which users can see this section based on their label type."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                {labelContext.labels && labelContext.labels.length && (
                  <Row tweaks="gap-0 sm:grid-cols-5 py-1 px-px">
                    {labelContext.labels.map((label) => (
                      <Col key={label.id} extraStyles="capitalize" width={1}>
                        <ToggleCheckboxField
                          id={label.id}
                          name="labels"
                          label={label.name}
                          isChecked={listInclude("labels", label.id)}
                          onChange={(e) => {
                            setDraft(true);
                            toggleListValue("labels", label.id);
                          }}
                          flush
                          description="Enable for type"
                        />
                      </Col>
                    ))}
                  </Row>
                )}
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton
              onClick={handleSave}
              loading={isUpdating}
              disabled={!isDraft || formHasErrors()}
            />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default Section;
