import React, { useState } from "react";

const ColorText = ({ value = 0, text = '', lum = 700, inverted = false }) => {
  const normalVal = parseFloat(value) * (inverted ? -1 : 1)
  return <span className={`text-${ normalVal > 0 ? 'emerald' : normalVal < 0 ? 'rose' : 'slate-400 '  }-${ lum } whitespace-nowrap`}>
          { text }
          <span 
            className={`
              pl-0.5 mdi mr-[-1px]
              mdi-${ parseFloat(value) > 0 ? 'arrow-up-thick ' : parseFloat(value) < 0 ? 'arrow-down-thick ' :  'approximately-equal '}
            `}
          ></span> 
        </span>
}

const InvoicesTable = ({ 
    monthlyInvoiceChartData, 
    monthlyCountsChartData, 
    invoiceKpiData, 
    vatSwitch = () => {}, 
  }) => {

    return (
      <React.Fragment>
        <div className="mt-6 pb-2">
          <table className="select-alls border-collapse">
            <thead className="text-[11px] text-slate-500 text-left font-medium whitespace-nowrap"> 
              <tr><th className="bg-slate-600 text-white py-1 px-2 border-[1px] border-gray-400 border-b-[1px] border-b-gray-400">
                EOM Invoices
              </th></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400">
                Invoiced in month
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400 border-b-gray-400">
                Invoiced avg
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400">
                Paid in month
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400 border-b-gray-400">
                Paid avg
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400">
                Pending at EOM
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400 border-b-gray-400">
                Pending avg
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400">
                Failed at EOM
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400 border-b-gray-400">
                Failed avg
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400">
                Debt records at EOM
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400 border-b-gray-400">
                Debt records avg
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400">
                All debt records	
              </td></tr>
              <tr><td className="py-1 px-2 border-[1px] border-r-gray-400 border-l-[1px] border-l-gray-400 border-b-gray-400">
                All debt records avg
              </td></tr>
            </thead>
          </table>
        </div>
        <div className="mt-6 w-full overflow-x-scroll scrollbar-hides pb-2 px-2">
          <table className="select-alls border-collapse min-w-full whitespace-nowrap">
            <thead className="bg-slate-500 text-white"> 
              <tr>
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <th colSpan={2} key={index + '-month'} className="py-1 px-2 border-[1px] border-gray-400 text-[11px] text-center font-medium">
                      { label }
                    </th>
                  )}
                </React.Fragment>
              </tr>
            </thead>
            <tbody>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-bills'} >
                      <td 
                        className={`
                          min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.bill_month_top.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.bill_month_bottom.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        { vatSwitch(monthlyInvoiceChartData?.datasets[0]?.data[index] || 0) }
                      </td>
                      <td 
                        className={`
                          min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.bill_month_best.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.bill_month_worst.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.bill_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.bill_diffs[index] || 0)} inverted={false}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-bills-avg'}>
                      <td className="py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 border-b-gray-400 bg-white font-regular text-[11px] text-slate-900 text-right opacity-70">
                        { vatSwitch((monthlyInvoiceChartData?.datasets[0]?.data[index] || 0) / (monthlyCountsChartData?.datasets[0]?.data[index] || 0) || 0)}
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 border-b-gray-400 bg-white font-light text-[11px] text-slate-900 text-right opacity-70
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.bill_avg_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.bill_avg_diffs[index] || 0)} inverted={false}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-payments'} >
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.paid_month_top.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.paid_month_bottom.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        { vatSwitch(monthlyInvoiceChartData?.datasets[1]?.data[index] || 0) }
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.paid_month_best.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.paid_month_worst.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.paid_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.paid_diffs[index] || 0)} inverted={false}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-payments-avg'} >
                      <td className="py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 border-b-gray-400 bg-white font-regular text-[11px] text-slate-900 text-right opacity-70">
                        { vatSwitch((monthlyInvoiceChartData?.datasets[1]?.data[index] || 0) / (monthlyCountsChartData?.datasets[1]?.data[index] || 0) || 0)}
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 border-b-gray-400 bg-white font-light text-[11px] text-slate-900 text-right opacity-70
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.paid_avg_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.paid_avg_diffs[index] || 0)} inverted={false}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-pending'}>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.wait_month_bottom.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.wait_month_top.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        { vatSwitch(monthlyInvoiceChartData?.datasets[3]?.data[index] || 0) }
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.wait_month_worst.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.wait_month_best.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                      <ColorText value={ invoiceKpiData?.wait_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.wait_diffs[index] || 0)} inverted={true}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-pending-avg'}>
                      <td className="py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 border-b-gray-400 bg-white font-regular text-[11px] text-slate-900 text-right opacity-70">
                        { vatSwitch((monthlyInvoiceChartData?.datasets[3]?.data[index] || 0) / (monthlyCountsChartData?.datasets[3]?.data[index] || 0) || 0)}
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 border-b-gray-400 bg-white font-light text-[11px] text-slate-900 text-right opacity-70
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.wait_avg_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.wait_avg_diffs[index] || 0)} inverted={true}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-fail'} >
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.fail_month_bottom.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.fail_month_top.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        { vatSwitch(monthlyInvoiceChartData?.datasets[4]?.data[index] || 0) }
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.fail_month_worst.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.fail_month_best.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.fail_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.fail_diffs[index] || 0)} inverted={true}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-fail-avg'}>
                      <td className="py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 border-b-gray-400 bg-white font-regular text-[11px] text-slate-900 text-right opacity-70">
                        { vatSwitch((monthlyInvoiceChartData?.datasets[4]?.data[index] || 0) / (monthlyCountsChartData?.datasets[4]?.data[index] || 0) || 0)}
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 border-b-gray-400 bg-white font-light text-[11px] text-slate-900 text-right opacity-70
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.fail_avg_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.fail_avg_diffs[index] || 0)} inverted={true}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-debt'} >
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.debt_month_bottom.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.debt_month_top.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        { vatSwitch(monthlyInvoiceChartData?.datasets[2]?.data[index] || 0) }
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.debt_month_worst.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.debt_month_best.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.debt_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.debt_diffs[index] || 0)} inverted={true}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment> 
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-debt-avg'}>
                      <td className="py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 border-b-gray-400 bg-white font-regular text-[11px] text-slate-900 text-right opacity-70">
                        { vatSwitch((monthlyInvoiceChartData?.datasets[2]?.data[index] || 0) / (monthlyCountsChartData?.datasets[2]?.data[index] || 0) || 0)}
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 border-b-gray-400 bg-white font-light text-[11px] text-slate-900 text-right opacity-70
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.debt_avg_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.debt_avg_diffs[index] || 0)} inverted={true}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-all-debt'}>
                      <td  
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.all_debt_month_bottom.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.all_debt_month_top.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        { vatSwitch(monthlyInvoiceChartData?.datasets[5]?.data[index] || 0) }
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                          ${ invoiceKpiData?.all_debt_month_worst.index === index ? ' bg-emerald-200' 
                            : invoiceKpiData?.all_debt_month_best.index === index ? ' bg-rose-200' 
                            : ' bg-white' 
                          } 
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.all_debt_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.all_debt_diffs[index] || 0)} inverted={true}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <React.Fragment>
                  { monthlyInvoiceChartData?.labels?.length > 0 && monthlyInvoiceChartData?.labels.map((label, index) => 
                    <React.Fragment key={index + '-all-debt-avg'}>
                      <td className="py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 border-b-gray-400 bg-white font-regular text-[11px] text-slate-900 text-right opacity-70">
                        { vatSwitch((monthlyInvoiceChartData?.datasets[5]?.data[index] || 0) / (monthlyCountsChartData?.datasets[5]?.data[index] || 0) || 0)}
                      </td>
                      <td 
                        className={`
                          py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 border-b-gray-400 bg-white font-light text-[11px] text-slate-900 text-right opacity-70
                        `}
                      >
                        <ColorText value={ invoiceKpiData?.all_debt_avg_diffs[index] || 0 } text={vatSwitch(invoiceKpiData?.all_debt_avg_diffs[index] || 0)} inverted={true}  />
                    </td>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-6 pb-2">
          <table className="select-alls border-collapse">
            <thead className="bg-slate-600 text-white"> 
              <tr>
                <th className="min-w-[80px] py-1 px-2 border-[1px] border-gray-400 border-l-indigo-400 border-t-indigo-400 text-[11px] text-right font-medium">
                  Average
                </th>
                <th className="min-w-[80px] py-1 px-2 border-[1px] border-gray-400 border-t-indigo-400 text-[11px] text-right font-medium">
                  Growth
                </th>
                <th className="min-w-[80px] py-1 px-2 border-[1px] border-gray-400 border-t-indigo-400 text-[11px] text-right font-medium">
                  Step
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch(invoiceKpiData?.bill_month_avg || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.bill_month_dif || 0 } text={vatSwitch(invoiceKpiData?.bill_month_dif || 0)} inverted={false}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.bill_month_var || 0 } text={vatSwitch(invoiceKpiData?.bill_month_var || 0)} inverted={false}  />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch((Math.floor((invoiceKpiData?.bill_month_avg || 0) / (invoiceKpiData?.bill_month_avg_num || 0) * 100) / 100) || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.bill_month_avg_diff || 0 } text={vatSwitch(invoiceKpiData?.bill_month_avg_diff || 0)} inverted={false}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={((invoiceKpiData?.bill_month_var || 0) * ((invoiceKpiData?.bill_month_avg_diff || 0) / (invoiceKpiData?.bill_month_dif || 0)) || 0 ) } 
                            text={vatSwitch((invoiceKpiData?.bill_month_var || 0) * ((invoiceKpiData?.bill_month_avg_diff || 0) / (invoiceKpiData?.bill_month_dif || 0)) || 0) } 
                            inverted={false} />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch(invoiceKpiData?.paid_month_avg || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.paid_month_dif || 0 } text={vatSwitch(invoiceKpiData?.paid_month_dif || 0)} inverted={false}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.paid_month_var || 0 } text={vatSwitch(invoiceKpiData?.paid_month_var || 0)} inverted={false}  />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch((Math.floor((invoiceKpiData?.paid_month_avg || 0) / (invoiceKpiData?.paid_month_avg_num || 0) * 100) / 100) || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.paid_month_avg_diff || 0 } text={vatSwitch(invoiceKpiData?.paid_month_avg_diff || 0)} inverted={false}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText 
                    value={(invoiceKpiData?.paid_month_var || 0) * ((invoiceKpiData?.paid_month_avg_diff || 0) / (invoiceKpiData?.paid_month_dif || 0)) || 0 } 
                    text={vatSwitch((invoiceKpiData?.paid_month_var || 0) * ((invoiceKpiData?.paid_month_avg_diff || 0) / (invoiceKpiData?.paid_month_dif || 0)) || 0)} 
                    inverted={false} />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch(invoiceKpiData?.wait_month_avg || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.wait_month_dif || 0 } text={vatSwitch(invoiceKpiData?.wait_month_dif || 0)} inverted={true}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.wait_month_var || 0 } text={vatSwitch(invoiceKpiData?.wait_month_var || 0)} inverted={true}  />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch((Math.floor((invoiceKpiData?.wait_month_avg || 0) / (invoiceKpiData?.wait_month_avg_num || 0) * 100) / 100) || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.wait_month_avg_diff || 0 } text={vatSwitch(invoiceKpiData?.wait_month_avg_diff || 0)} inverted={true}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText 
                    value={ (invoiceKpiData?.wait_month_var || 0) * ((invoiceKpiData?.wait_month_avg_diff || 0) / (invoiceKpiData?.wait_month_dif || 0)) || 0 } 
                    text={ vatSwitch((invoiceKpiData?.wait_month_var || 0) * ((invoiceKpiData?.wait_month_avg_diff || 0) / (invoiceKpiData?.wait_month_dif || 0)) || 0) } 
                    inverted={true} />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch(invoiceKpiData?.fail_month_avg || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.fail_month_dif || 0 } text={vatSwitch(invoiceKpiData?.fail_month_dif || 0)} inverted={true}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.fail_month_var || 0 } text={vatSwitch(invoiceKpiData?.fail_month_var || 0)} inverted={true}  />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch((Math.floor((invoiceKpiData?.fail_month_avg || 0) / (invoiceKpiData?.fail_month_avg_num || 0) * 100) / 100) || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.fail_month_avg_diff || 0 } text={vatSwitch(invoiceKpiData?.fail_month_avg_diff || 0)} inverted={true}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText 
                    value={ (invoiceKpiData?.fail_month_var || 0) * ((invoiceKpiData?.fail_month_avg_diff || 0) / (invoiceKpiData?.fail_month_dif || 0)) || 0 } 
                    text={ vatSwitch((invoiceKpiData?.fail_month_var || 0) * ((invoiceKpiData?.fail_month_avg_diff || 0) / (invoiceKpiData?.fail_month_dif || 0)) || 0) } 
                    inverted={true} />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch(invoiceKpiData?.debt_month_avg || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.debt_month_dif || 0 } text={vatSwitch(invoiceKpiData?.debt_month_dif || 0)} inverted={true}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.debt_month_var || 0 } text={vatSwitch(invoiceKpiData?.debt_month_var || 0)} inverted={true}  />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch((Math.floor((invoiceKpiData?.debt_month_avg || 0) / (invoiceKpiData?.debt_month_avg_num || 0) * 100) / 100) || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.debt_month_avg_diff || 0 } text={vatSwitch(invoiceKpiData?.debt_month_avg_diff || 0)} inverted={true}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText 
                    value={ (invoiceKpiData?.debt_month_var || 0) * ((invoiceKpiData?.debt_month_avg_diff || 0) / (invoiceKpiData?.debt_month_dif || 0)) || 0 } 
                    text={ vatSwitch((invoiceKpiData?.debt_month_var || 0) * ((invoiceKpiData?.debt_month_avg_diff || 0) / (invoiceKpiData?.debt_month_dif || 0)) || 0) } 
                    inverted={true} />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch(invoiceKpiData?.all_debt_month_avg || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.all_debt_month_dif || 0 } text={vatSwitch(invoiceKpiData?.all_debt_month_dif || 0)} inverted={true}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.all_debt_month_var || 0 } text={vatSwitch(invoiceKpiData?.all_debt_month_var || 0)} inverted={true}  />
                </td>
              </tr>
              <tr className="odd:bg-white even:bg-slate-100">
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-r-gray-200 border-b-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  { vatSwitch((Math.floor((invoiceKpiData?.all_debt_month_avg || 0) / (invoiceKpiData?.all_debt_month_avg_num || 0) * 100) / 100) || 0) }
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-gray-200 border-b-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText value={ invoiceKpiData?.all_debt_month_avg_diff || 0 } text={vatSwitch(invoiceKpiData?.all_debt_month_avg_diff || 0)} inverted={true}  />
                </td>
                <td className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 border-b-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}>
                  <ColorText 
                    value={ (invoiceKpiData?.all_debt_month_var || 0) * ((invoiceKpiData?.all_debt_month_avg_diff || 0) / (invoiceKpiData?.all_debt_month_dif || 0)) || 0 } 
                    text={ vatSwitch((invoiceKpiData?.all_debt_month_var || 0) * ((invoiceKpiData?.all_debt_month_avg_diff || 0) / (invoiceKpiData?.all_debt_month_dif || 0)) || 0) } 
                    inverted={true} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </React.Fragment>
  )
}

export default InvoicesTable;