import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { activityEvents } from "./../../graphql/queries/reportQueries";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useTypeFilters from "./../../hooks/typeFilters";
import { REPORTS_ACTIVITY_EVENTS_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import ActivityEventsReport from "./../../components/reports/ActivityEventsReport";

const ACTIVITY_EVENTS = gql(activityEvents);

const START_DATE = moment().add(-30, "days").format("YYYY-MM-DD");
const END_DATE = moment().format("YYYY-MM-DD");

const ActivityEvents = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.ActivityEventsReport);

  const { page, limit, orderBy, orderHow, search } = useParams();

  const {
    nextPage,
    prevPage,
    applySearch,
    applyFilters,
    resetFilters,
    queryString: adjustedQueryString,
    queryParams,
  } = useBaseNavigation(REPORTS_ACTIVITY_EVENTS_MODEL_NAME, {
    queryDefaults: { startDate: [START_DATE], endDate: [END_DATE] },
  });

  const { data, loading, error } = useQuery(ACTIVITY_EVENTS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString: adjustedQueryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: filtersUpdateFilter,
    resetFilters: filtersResetFilters,
    removeFilterValue: filtersRemoveFilterValue,
    applyFilters: filtersApplyFilters,
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "activityEvent",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: adjustedQueryString,
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <ActivityEventsReport
      loading={loading}
      data={data}
      onApplySearch={applySearch}
      onNextPage={nextPage}
      onPrevPage={prevPage}
      typeFilters={typeFilters}
      onApplyFilters={filtersApplyFilters}
      onResetFilters={filtersResetFilters}
      onRemoveFiltervalue={filtersRemoveFilterValue}
      onUpdateFilter={filtersUpdateFilter}
      hasActiveFilters={hasActiveFilters}
      selectedFilters={selectedFilters}
      startDate={queryParams.startDate[0]}
      endDate={queryParams.endDate[0]}
    />
  );
};

export default ActivityEvents;
