import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { questionDetails } from "./../graphql/queries/vabooSurveyQueries";
import { updateQuestionMutation } from "./../graphql/mutations/vabooSurveyMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import { useQuestionFieldSetters } from "./../hooks/vabooHooks";
import { QUESTION_MODEL_NAME, QUESTION_TYPES } from "./../configs/constants";
import PageSpinner from "./../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../moose-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
  AddButton,
  DeleteButton,
} from "./../moose-ui/Controls";
import { Heading, BoxedMessage, InfoMessage } from "./../moose-ui/Typography";
import {
  TextField,
  NumberField,
  SelectField,
  ReadOnlyField,
  ToggleCheckboxField,
} from "./../moose-ui/Fields";
import {
  TableContainer,
  TableView,
  THeading,
  TContent,
  THCol,
  TRow,
  TCol,
  TColBorderless,
} from "./../moose-ui/DataTable";
const QUESTION_DETAILS = gql(questionDetails);
const UPDATE_QUESTION = gql(updateQuestionMutation);

const Question = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.Question);
  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const { goBack } = useBaseNavigation(QUESTION_MODEL_NAME);
  const { id } = useParams();
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    title: "",
    is_profile_type: false,
    is_survey_type: false,
    admin_title: "",
    type: "",
    status: "Draft",
    answers: [],
  });

  const {
    setFieldValue: handleFieldOnChange,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const {
    handleAddAnswer,
    handleUpdateAnswer,
    handleDeleteAnswer,
    handleUpdateType,
    handleAddDateBracketsAnswer,
    handleUpdateDateBracketsAnswerProperty,
  } = useQuestionFieldSetters(formData, stateMethods);

  const { data, loading, error } = useQuery(QUESTION_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.question) return false;
      const answers = result.question.answers.map((answer) => {
        if (result.question.type === "DATE_BRACKETS") {
          return {
            id: answer.id,
            title: answer.title,
            minimum: answer.metadata.minimum,
            maximum: answer.metadata.maximum,
            weight: answer.metadata.weight,
          };
        } else {
          return {
            id: answer.id,
            title: answer.title,
          };
        }
      });
      const data = {
        id: result.question.id,
        status: result.question.status,
        title: result.question.title,
        admin_title: result.question.admin_title,
        type: result.question.type,
        answers: answers,
        is_profile_type: result.question.is_profile_type,
        is_survey_type: result.question.is_survey_type,
      };
      if (data.type == "RATING") {
        const params = JSON.parse(result.question.params);
        if (
          typeof params.minimum == "undefined" ||
          typeof params.maximum == "undefined" ||
          typeof params.step == "undefined" ||
          typeof params.default == "undefined"
        ) {
          return false;
        }
        data.minimum = params.minimum;
        data.maximum = params.maximum;
        data.step = params.step;
        data.default = params.default;
      }
      if (data.type == "YEAR") {
        const params = JSON.parse(result.question.params);
        if (
          typeof params.minimum == "undefined" ||
          typeof params.maximum == "undefined" ||
          typeof params.step == "undefined" ||
          typeof params.default == "undefined"
        ) {
          return false;
        }
        data.minimum = params.minimum;
        data.maximum = params.maximum;
        data.step = params.step;
        data.default = params.default;
      }
      setFormData({ ...data });
    },
  });

  const [
    updateQuestion,
    { data: updateResult, loading: isUpdating, error: updateError },
  ] = useMutation(UPDATE_QUESTION, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData, answers: JSON.stringify(formData.answers) };
    updateQuestion({ variables: data });
  };

  const isPublished = formData && formData.status == "Published" ? true : false;

  if (error) return <div>Error...</div>;
  if (!formData || loading) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="Question" />
        <FormContainer>
          <FormData>
            {isPublished && (
              <Row>
                <Col width={12}>
                  <InfoMessage text="This question is published. Changes are not allowed." />
                </Col>
              </Row>
            )}
            <Row>
              <Col width={8}>
                {isPublished && (
                  <ReadOnlyField
                    value={formData.title}
                    label="Question"
                    isRequired={true}
                  />
                )}
                {!isPublished && (
                  <TextField
                    name="title"
                    label="Question"
                    value={formData.title}
                    onChange={handleFieldOnChange}
                    error={fieldError("title")}
                    isRequired={true}
                  />
                )}
              </Col>
              <Col width={2}></Col>
              <Col width={2}>
                <SelectField
                  value={formData.type}
                  values={QUESTION_TYPES}
                  label="Type"
                  name="type"
                  onChange={handleUpdateType}
                  valueKey="id"
                  labelKey="name"
                  error={fieldError("type")}
                  isDisabled={isPublished}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                {isPublished && (
                  <ReadOnlyField
                    value={formData.admin_title}
                    label="Admin Title"
                    isRequired={true}
                  />
                )}
                {!isPublished && (
                  <TextField
                    name="admin_title"
                    label="Admin Title"
                    value={formData.admin_title}
                    onChange={handleFieldOnChange}
                    error={fieldError("admin_title")}
                    isRequired={true}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col width={2}>
                {isPublished && (
                  <ReadOnlyField
                    value={formData.is_survey_type === true ? "Yes" : "No"}
                    label="Survey Usage"
                  />
                )}
                {!isPublished && (
                  <ToggleCheckboxField
                    id="is_survey_type"
                    name="is_survey_type"
                    label="Survey Usage"
                    description="If checked - available for Surveys"
                    isChecked={formData.is_survey_type}
                    onChange={handleToggleCheckbox}
                  />
                )}
              </Col>
              <Col width={2}>
                {isPublished && (
                  <ReadOnlyField
                    value={formData.is_profile_type === true ? "Yes" : "No"}
                    label="Profile Usage"
                  />
                )}
                {!isPublished && (
                  <ToggleCheckboxField
                    id="is_profile_type"
                    name="is_profile_type"
                    label="Profile Usage"
                    description="If checked - available for Profiles"
                    isChecked={formData.is_profile_type}
                    onChange={handleToggleCheckbox}
                  />
                )}
              </Col>
            </Row>
            {["DATE_BRACKETS"].indexOf(formData.type) > -1 && (
              <React.Fragment>
                <Row>
                  <Col width={12}></Col>
                </Row>
                {!isPublished && (
                  <Row>
                    <Col width={3}>
                      <AddButton
                        label="Add Answer"
                        onClick={handleAddDateBracketsAnswer}
                      />
                    </Col>
                  </Row>
                )}
                {!isPublished && formData.answers.length > 0 && (
                  <TableContainer>
                    <TableView>
                      <THeading>
                        <TRow>
                          <THCol name="minimum" value="Range (From)" />
                          <THCol name="maximum" value="Range (To)" />
                          <THCol name="title" value="Range (Text)" />
                          <THCol
                            name="weight"
                            value="Computed Range (Weight)"
                          />
                          <THCol />
                        </TRow>
                      </THeading>
                      <TContent>
                        {formData.answers.map((answer, index) => (
                          <TRow key={index}>
                            <TColBorderless>
                              <NumberField
                                value={answer.minimum}
                                onChange={(e) =>
                                  handleUpdateDateBracketsAnswerProperty(
                                    index,
                                    "minimum",
                                    e.target.value
                                  )
                                }
                              />
                            </TColBorderless>
                            <TColBorderless>
                              <NumberField
                                value={answer.maximum}
                                onChange={(e) =>
                                  handleUpdateDateBracketsAnswerProperty(
                                    index,
                                    "maximum",
                                    e.target.value
                                  )
                                }
                              />
                            </TColBorderless>
                            <TColBorderless>
                              <TextField
                                value={answer.title}
                                onChange={(e) =>
                                  handleUpdateDateBracketsAnswerProperty(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                              />
                            </TColBorderless>
                            <TColBorderless>
                              <NumberField
                                value={answer.weight}
                                onChange={(e) =>
                                  handleUpdateDateBracketsAnswerProperty(
                                    index,
                                    "weight",
                                    e.target.value
                                  )
                                }
                              />
                            </TColBorderless>
                            <TColBorderless width="w-20">
                              <DeleteButton
                                onClick={() => handleDeleteAnswer(index)}
                              />
                            </TColBorderless>
                          </TRow>
                        ))}
                      </TContent>
                    </TableView>
                  </TableContainer>
                )}
                {isPublished && formData.answers.length > 0 && (
                  <TableContainer>
                    <TableView>
                      <THeading>
                        <TRow>
                          <THCol name="id" value="ID" width={"w-1"} />
                          <THCol name="minimum" value="Range (From)" />
                          <THCol name="maximum" value="Range (To)" />
                          <THCol name="title" value="Range (Text)" />
                          <THCol
                            name="weight"
                            value="Computed Range (Weight)"
                          />
                        </TRow>
                      </THeading>
                      <TContent>
                        {formData.answers.map((answer, index) => (
                          <TRow key={index}>
                            <TColBorderless>
                              <ReadOnlyField value={answer.id} />
                            </TColBorderless>
                            <TColBorderless>
                              <ReadOnlyField value={answer.minimum} />
                            </TColBorderless>
                            <TColBorderless>
                              <ReadOnlyField value={answer.maximum} />
                            </TColBorderless>
                            <TColBorderless>
                              <ReadOnlyField value={answer.title} />
                            </TColBorderless>
                            <TColBorderless>
                              <ReadOnlyField value={answer.weight} />
                            </TColBorderless>
                          </TRow>
                        ))}
                      </TContent>
                    </TableView>
                  </TableContainer>
                )}
              </React.Fragment>
            )}
            {[
              "MULTIPLE",
              "MULTIPLE_AND_CUSTOM",
              "SINGLE",
              "SINGLE_AND_CUSTOM",
            ].indexOf(formData.type) > -1 && (
              <React.Fragment>
                <Row>
                  <Col width={12}></Col>
                </Row>
                {/* <Row>
                  <Col width={12}>
                    <Heading text="Answers" />
                  </Col>
                </Row> */}
                {!isPublished && (
                  <Row>
                    <Col width={3}>
                      <AddButton label="Add Answer" onClick={handleAddAnswer} />
                    </Col>
                  </Row>
                )}
                {!isPublished &&
                  formData &&
                  formData.answers &&
                  formData.answers.length > 0 && (
                    <TableContainer>
                      <TableView>
                        <THeading>
                          <TRow>
                            <THCol name="title" value="Answers" />
                            <THCol />
                          </TRow>
                        </THeading>
                        <TContent>
                          {formData.answers.map((answer, index) => (
                            <TRow key={index}>
                              <TColBorderless>
                                <TextField
                                  name={`title_${index}`}
                                  value={answer.title}
                                  onChange={handleUpdateAnswer}
                                />
                              </TColBorderless>
                              <TColBorderless width="w-20">
                                <DeleteButton
                                  onClick={() => handleDeleteAnswer(index)}
                                />
                              </TColBorderless>
                            </TRow>
                          ))}
                        </TContent>
                      </TableView>
                    </TableContainer>
                  )}
                {isPublished &&
                  formData &&
                  formData.answers &&
                  formData.answers.length > 0 && (
                    <TableContainer>
                      <TableView>
                        <THeading>
                          <TRow>
                            <THCol name="id" value="ID" width={"w-1"} />
                            <THCol name="title" value="Answers" />
                          </TRow>
                        </THeading>
                        <TContent>
                          {formData.answers.map((answer, index) => (
                            <TRow key={index}>
                              <TColBorderless>
                                <ReadOnlyField value={answer.id} />
                              </TColBorderless>
                              <TColBorderless>
                                <ReadOnlyField value={answer.title} />
                              </TColBorderless>
                            </TRow>
                          ))}
                        </TContent>
                      </TableView>
                    </TableContainer>
                  )}
              </React.Fragment>
            )}
            {["TEXT"].indexOf(formData.type) > -1 && (
              <BoxedMessage
                text="Answers are not available for this type"
                description="When using Text as question type, answers become unavailable."
              />
            )}
            {["RATING"].indexOf(formData.type) > -1 && (
              <React.Fragment>
                <Row>
                  <Col width={3}>
                    {isPublished && (
                      <ReadOnlyField
                        value={formData.minimum}
                        label="Minimum Rating Value"
                      />
                    )}
                    {!isPublished && (
                      <NumberField
                        name="minimum"
                        label={`Minimum Rating Value ${formData.minimum}`}
                        value={formData.minimum}
                        onChange={handleFieldOnChange}
                        error={fieldError("minimum")}
                      />
                    )}
                  </Col>
                  <Col width={3}>
                    {isPublished && (
                      <ReadOnlyField
                        value={formData.maximum}
                        label="Maximum Rating Value"
                      />
                    )}
                    {!isPublished && (
                      <NumberField
                        name="maximum"
                        label="Maximum Rating Value"
                        value={formData.maximum}
                        onChange={handleFieldOnChange}
                        error={fieldError("maximum")}
                      />
                    )}
                  </Col>
                  <Col width={3}>
                    {isPublished && (
                      <ReadOnlyField
                        value={formData.step}
                        label="Rating Step"
                      />
                    )}
                    {!isPublished && (
                      <NumberField
                        name="step"
                        label="Rating Step"
                        value={formData.step}
                        onChange={handleFieldOnChange}
                        error={fieldError("step")}
                      />
                    )}
                  </Col>
                  <Col width={3}>
                    {isPublished && (
                      <ReadOnlyField
                        value={formData.default}
                        label="Default Value"
                      />
                    )}
                    {!isPublished && (
                      <NumberField
                        name="default"
                        label="Default Value"
                        value={formData.default}
                        onChange={handleFieldOnChange}
                        error={fieldError("default")}
                      />
                    )}
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {!isPublished && ["YEAR"].indexOf(formData.type) > -1 && (
              <React.Fragment>
                <Row>
                  <Col width={3}>
                    <NumberField
                      name="minimum"
                      label={`Year (From)`}
                      value={formData.minimum}
                      onChange={handleFieldOnChange}
                      error={fieldError("minimum")}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="maximum"
                      label="Year (To)"
                      value={formData.maximum}
                      onChange={handleFieldOnChange}
                      error={fieldError("maximum")}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="step"
                      label="Year Step"
                      value={formData.step}
                      onChange={handleFieldOnChange}
                      error={fieldError("step")}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="default"
                      label="Default Year"
                      value={formData.default}
                      onChange={handleFieldOnChange}
                      error={fieldError("default")}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {isPublished && ["YEAR"].indexOf(formData.type) > -1 && (
              <React.Fragment>
                <Row>
                  <Col width={3}>
                    <ReadOnlyField
                      value={formData.minimum}
                      label="Year (From)"
                    />
                  </Col>
                  <Col width={3}>
                    <ReadOnlyField value={formData.maximum} label="Year (To)" />
                  </Col>
                  <Col width={3}>
                    <ReadOnlyField value={formData.step} label="Year Step" />
                  </Col>
                  <Col width={3}>
                    <ReadOnlyField
                      value={formData.default}
                      label="Default Year"
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </FormData>
          {!isPublished && (
            <FormControls>
              <CancelButton onClick={goBack} />
              {hasAccess(Permissions.Mutation.UpdateQuestion) && (
                <SaveButton onClick={handleSave} />
              )}
            </FormControls>
          )}
          {isPublished && (
            <FormControls>
              <CancelButton onClick={goBack} label="Go back" />
            </FormControls>
          )}
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default Question;
