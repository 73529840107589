import React, { useState } from "react";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Line, Bar } from "react-chartjs-2";
import { primaryKPIReport } from "./../../graphql/queries/reportQueries";
import { Row, Col, Centrify } from "./../../mood-ui/Layout";
import PageSpinner from "./../../mood-ui/PageSpinner";
import SimpleDateRangeInput from "../../mood-ui/filters/inputs/SimpleDateRangeInput";
import {
  truncateNumber,
  commaInt,
  getDistPercent,
  getSumCount,
  commaFloat,
} from "./../../hooks/utils";

import UserGrowthTable from "./KpiTables/UserGrowthTable";
import InvoicesTable from "./KpiTables/InvoicesTable";

import { BarOptions } from "./BarChartOptions";

const daysInThisMonth = (date = null) => {
  if (date)
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

const killTooltips = () => {
  const test = document.getElementsByClassName("chartjs-tooltip-2") || [];
  for (let i = 0; i < test.length; i++) {
    test[i].style.opacity = 0;
  }
};

const customTooltips = (tooltipModel) => {
  // https://www.chartjs.org/docs/2.9.4/general/options.html
  const slug = tooltipModel?.beforeBody ? tooltipModel?.beforeBody[0] : null;
  if (!slug) {
    killTooltips();
  } else {
    var tooltipEl = document.getElementById("chartjs-tooltip-" + slug);
    var yAlign = tooltipModel.yAlign;
    var xAlign = tooltipModel.xAlign;
    const chart = document.getElementById(slug);
    if (!tooltipEl) {
      tooltipEl = document.createElement("div"); // Create element on first render
      tooltipEl.id = "chartjs-tooltip-" + slug;
      tooltipEl.classList.add("chartjs-tooltip-2");
      tooltipEl.innerHTML = `<div style="position: relative; background-color: rgba(20, 20, 20, 0.8); padding: ${tooltipModel.yPadding}px ${tooltipModel.xPadding}px"><table class="z-[99999] relative" style=""></table>`;
      chart.parentNode.appendChild(tooltipEl);
    }
    var tableRoot = tooltipEl.querySelector("table");
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    } // Hide if no tooltip
    if (tooltipModel.body) {
      var bodyLines = tooltipModel.body.map((bodyItem) => {
        return bodyItem.lines;
      });
      var innerHtml = "<thead></thead><tbody>";
      let rows = [];
      let lastCountType = -1;
      bodyLines.forEach((body, i) => {
        var style = `background-color: ${tooltipModel.labelColors[i].backgroundColor}; border-width: 1px; border-color: ${tooltipModel.labelTextColors[i]};
          display: inline-block; width: 12px; height: 12px; margin-right: 6px; margin-bottom: -3px;`;
        if (tooltipModel.labelColors[i].borderColor !== "rgba(0,0,0,0)") {
          style += "border-radius: 0; ";
          lastCountType++;
        } else {
          style += "border-radius: 50%; ";
        }

        var labelColor = `<td class="whitespace-nowrap w-min"><span class="chartjs-tooltip-key inline-block" style="${style}"></span></td>`;
        var labelName = `<td class="whitespace-nowrap" style="color: ${
          tooltipModel.labelTextColors[i]
        };"> ${body[0].split(": ")[0]} </td>`;
        var labelValue = `<td class="whitespace-nowrap pl-3 text-right" style="color: ${
          tooltipModel.labelTextColors[i]
        };"> ${body[0].split(": ")[1]} </td>`;
        rows.push(`<tr> ${labelColor} ${labelName} ${labelValue} </tr>`);
      });
      if (lastCountType > -1 && rows.length > lastCountType + 1) {
        rows.splice(
          lastCountType + 1,
          0,
          `<tr style="padding: 0; margin: 0; width: 12px; height: 6px;"><td></td></tr>`
        );
      }

      innerHtml += rows.join("");
      innerHtml += `<tr><th colspan="3"><div class="text-right" style="color: white; margin-top: 6px;"> ${tooltipModel.footer[0]}</div></th></tr></tbody>`;
      tableRoot.innerHTML = innerHtml;
    }
    const scale = chart.height / 245;
    const { height, width } = tableRoot.getBoundingClientRect(); // Tooltip height and width
    var caretY = tooltipModel.caretY; // Carets
    var caretX = tooltipModel.caretX; // Carets
    let space = 8; // Caret + 1px (can increase)
    let top = window.pageYOffset + caretY - height; // Final coordinates
    let left = window.pageXOffset + caretX - width / 2; // Final coordinates
    if (height > chart.height - (40 + 68 * scale)) {
      if (tooltipModel.y < 80 && yAlign === "top") {
      } else {
        yAlign = "center";
        if (xAlign === "center") {
          if (caretX > chart.width / 2) {
            xAlign = "right";
          } else {
            xAlign = "left";
          }
        }
      }
    }
    if (yAlign === "bottom") {
      top = Math.max(44, top - 13);
      left -= 7;
    }
    if (yAlign === "center") {
      top = Math.max(32 - height / 2, top);
      caretY = Math.min(0 - chart.height, caretY);
    }
    if (yAlign === "top") {
      top += height + space;
      left -= space - 1;
    } else if (yAlign === "center") {
      top += height / 2;
    } else if (yAlign === "bottom") {
      top -= space;
    }
    if (xAlign === "left") {
      left -= 3;
      left += width / 2 - tooltipModel.xPadding + space;
      if (yAlign === "center") {
        left += space;
        top -= 6;
      }
    } else if (xAlign === "right") {
      left -= 11;
      left -= width / 2 - tooltipModel.xPadding - space;
      if (yAlign === "center") {
        left -= 9;
        left -= space * 2;
        top -= 6;
      }
    }
    let offsetHeight = height - chart.height;
    if (yAlign === "center") {
      if (height > chart.height - (40 + 68 * scale)) {
        top = 40;
        if (tooltipModel.yAlign === "bottom") {
          offsetHeight = tooltipModel.y * 2 + height / 2 + 14 * scale;
        } else if (tooltipModel.yAlign === "top") {
          offsetHeight = (tooltipModel.y * 2 + 12 * scale) / 2;
        } else if (tooltipModel.yAlign === "center") {
          offsetHeight = tooltipModel.y * 2 + 18 * scale;
        }
      }
    }
    tooltipEl.classList.remove("top", "bottom", "center", "left", "right");
    tooltipEl.classList.add(yAlign);
    tooltipEl.classList.add(xAlign);
    tooltipEl.style = `opacity: 1; position: absolute; transition: all 0.17s ease-out;pointer-events: none; height: ${offsetHeight}px;`;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.top = `${top}px`;
    tooltipEl.style.left = `${left}px`;
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
    tooltipEl.style.fontStyle = `${tooltipModel._bodyFontStyle}px`;
  }
};

const numberWithCommas = (value) => {
  return value.toLocaleString("en-US", { maximumFractionDigits: 2 });
};

const currencyNumber = (value) => {
  return `£${numberWithCommas(value)}`;
};

const GrowthStatBlocksContainer = ({ blocks = 3, children, gap = false }) => {
  return (
    <div
      className={`grid grid-cols-1 sm:grid-cols-${blocks} ${gap && "gap-x-3"}`}
    >
      {children}
    </div>
  );
};

const GrowthStatBlock = ({
  description,
  value,
  valueAppend = "",
  growth = null,
  growthAbbr = null,
  growthAppend = "",
  growthPrepend = "",
  percent = null,
  percentAbbr = null,
  indicator = true,
  color = true,
  tweaks = "",
  valueFormatter = numberWithCommas,
  growthFormatter = numberWithCommas,
}) => {
  return (
    <div
      className={`border-[1px] border-x-[1px] border-gray-200 ml-[-1px] mt-[-1px] ${
        (!value || value == "0%") && " opacity-50"
      } ${tweaks}`}
    >
      <div className="px-2 pt-2 pb-1.5 bg-white cursor-default relative">
        <dl>
          <dt className="text-[11px] text-left leading-2 text-slate-400 truncate">
            {description}
          </dt>
          <dd className="pt-1.5 flex justify-between items-end truncate">
            <div
              className={`
                flex items-baseline text-[11px] smb-[-2px] text-slate-500 
              `}
            >
              {valueFormatter(value) + valueAppend}
            </div>
            {percent !== null && (
              <div
                className={`
                  absolute 
                  top-[7px] right-[7px] 
                  text-[10px] text-purple-500 
                `}
              >
                <abbr title={percentAbbr}>{percent}</abbr>
              </div>
            )}
            {growth !== null && (
              <div
                className={`
                  inline-flex items-baseline px-1 spy-[1px] rounded-xs text-[10px] 
                  ${
                    parseFloat(growth) > 0 && color
                      ? " bg-emerald-100 text-emerald-600 "
                      : parseFloat(growth) < 0 && color
                      ? " bg-rose-100 text-rose-500 "
                      : " bg-slate-100 text-slate-400 "
                  }
                `}
              >
                <abbr title={growthAbbr}>
                  {/* { 
                    indicator && <span 
                      className={`
                        pr-0.5 mdi ml-[-1px]
                        mdi-${ parseFloat(growth) > 0 ? 'arrow-up-thick ' : parseFloat(growth) < 0 ? 'arrow-down-thick ' :  'approximately-equal '}
                      `}
                    ></span> 
                  } */}
                  {growthPrepend + growthFormatter(growth) + growthAppend}
                </abbr>
              </div>
            )}
          </dd>
        </dl>
      </div>
    </div>
  );
};

const PRIMARY_KPI_REPORT = gql(primaryKPIReport);

const START_DATE = moment().add(-122, "days").format("YYYY-MM");
const END_DATE = moment().subtract(1, "days").format("YYYY-MM");

const MAIN_STATS_REPORT_DRAFT = {
  labels: [],
  datasets: [
    {
      label: "Clients",
      data: [],
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 1,
      id: "key-stats",
    },
    {
      label: "Users",
      data: [],
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
      id: "key-stats",
    },
    {
      label: "Active",
      data: [],
      backgroundColor: ["rgba(255, 206, 86, 0.2)"],
      borderColor: ["rgba(255, 206, 86, 1)"],
      borderWidth: 1,
      id: "key-stats",
    },
    {
      label: "Users added",
      data: [],
      backgroundColor: ["rgba(75, 192, 192, 0.2)"],
      borderColor: ["rgba(75, 192, 192, 1)"],
      borderWidth: 1,
      id: "key-stats",
    },
    {
      label: "Users deleted",
      data: [],
      backgroundColor: ["rgba(153, 102, 255, 0.2)"],
      borderColor: ["rgba(153, 102, 255, 1)"],
      borderWidth: 1,
      id: "key-stats",
    },
  ],
};

const ACCOUNT_TYPE_STATS_REPORT_DRAFT = {
  labels: [],
  datasets: [
    {
      label: "Basic",
      data: [],
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 1,
      id: "access-types",
    },
    {
      label: "Basic active",
      data: [],
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
      id: "access-types",
    },
    {
      label: "Premium",
      data: [],
      backgroundColor: ["rgba(255, 206, 86, 0.2)"],
      borderColor: ["rgba(255, 206, 86, 1)"],
      borderWidth: 1,
      id: "access-types",
    },
    {
      label: "Premium active",
      data: [],
      backgroundColor: ["rgba(75, 192, 192, 0.2)"],
      borderColor: ["rgba(75, 192, 192, 1)"],
      borderWidth: 1,
      id: "access-types",
    },
  ],
};

const USER_ACTIVITY_REPORT_DRAFT = {
  labels: [],
  datasets: [
    {
      label: "Logins",
      data: [],
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 1,
      id: "engagement",
    },
    {
      label: "Logins / mth",
      data: [],
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
      id: "engagements",
      hidden: true,
    },
    {
      label: "Sessions",
      data: [],
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 1,
      id: "engagement",
    },
  ],
};

const CREATED_METHOD_REPORT_DRAFT = {
  labels: [],
  datasets: [
    {
      label: "API",
      data: [],
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 1,
      id: "created-method",
    },
    {
      label: "Client",
      data: [],
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
      id: "created-method",
    },
    {
      label: "Control",
      data: [],
      backgroundColor: ["rgba(255, 206, 86, 0.2)"],
      borderColor: ["rgba(255, 206, 86, 1)"],
      borderWidth: 1,
      id: "created-method",
    },
    {
      label: "CSV",
      data: [],
      backgroundColor: ["rgba(75, 192, 192, 0.2)"],
      borderColor: ["rgba(75, 192, 192, 1)"],
      borderWidth: 1,
      id: "created-method",
    },
    {
      label: "PropCo",
      data: [],
      backgroundColor: ["rgba(150, 106, 86, 0.2)"],
      borderColor: ["rgba(150, 106, 86, 1)"],
      borderWidth: 1,
      id: "created-method",
    },
  ],
};

const LABELS_REPORT_DRAFT = {
  labels: [],
  datasets: [
    {
      label: "Tenant",
      data: [],
      backgroundColor: ["rgba(255, 206, 86, 0.2)"],
      borderColor: ["rgba(255, 206, 86, 1)"],
      borderWidth: 1,
      id: "label-type",
    },
    {
      label: "Landlord",
      data: [],
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 1,
      id: "label-type",
    },
    {
      label: "Buyer",
      data: [],
      backgroundColor: ["rgba(150, 106, 86, 0.2)"],
      borderColor: ["rgba(150, 106, 86, 1)"],
      borderWidth: 1,
      id: "label-type",
    },
    {
      label: "Vendor",
      data: [],
      backgroundColor: ["rgba(75, 192, 192, 0.2)"],
      borderColor: ["rgba(75, 192, 192, 1)"],
      borderWidth: 1,
      id: "label-type",
    },
    {
      label: "Staff",
      data: [],
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
      id: "label-type",
    },
    {
      label: "Student",
      data: [],
      backgroundColor: ["rgba(255, 99, 100, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
      id: "label-type",
    },
  ],
};

const UPGRADE_STATS_REPORT_DRAFT = {
  labels: [],
  datasets: [
    {
      label: "Upgrades",
      data: [],
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 1,
      id: "upgrades",
    },
    {
      label: "Downgrades",
      data: [],
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
      id: "upgrades",
    },
    {
      label: "Current upgraded",
      data: [],
      backgroundColor: ["rgba(255, 206, 86, 0.2)"],
      borderColor: ["rgba(255, 206, 86, 1)"],
      borderWidth: 1,
      id: "upgrades",
    },
  ],
};

const ZERO_STATS = {
  total_client_revenue: 0,
  total_upgrade_revenue: 0,
  clients_added: 0,
  users_added: 0,
  users_deleted: 0,
  users_diff: 0,
  activation_rate: 0,
  activation_rate_change: 0,
  final_basic_count: 0,
  final_premium_count: 0,
  basic_diff: 0,
  premium_diff: 0,
  final_basic_active: 0,
  final_premium_active: 0,
  basic_active_diff: 0,
  premium_active_diff: 0,
  final_basic_activation_rate: 0,
  final_premium_activation_rate: 0,
  basic_activation_diff: 0,
  premium_activation_diff: 0,
  user_logins: 0,
  avg_daily_logins: 0,
  avg_weekly_logins: 0,
  avg_monthly_logins: 0,
  user_sessions: 0,
  avg_daily_sessions: 0,
  avg_weekly_sessions: 0,
  avg_monthly_sessions: 0,
  loading: true,
};

const INVOICE_SUM_DAY_MONTH = {
  labels: [],
  datasets: [
    {
      label: "Invoices",
      data: [],
      backgroundColor: "rgba(54, 162, 235, 0.7)",
      borderColor: "rgba(54, 162, 235, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_day_month",
      stack: "1",
    },
    {
      label: "Payments",
      data: [],
      backgroundColor: "rgba(75, 200, 180, 0.7)",
      borderColor: "rgba(75, 200, 180, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_day_month",
      stack: "0",
    },
    {
      label: "Debt",
      data: [],
      backgroundColor: "rgba(255, 99, 100, 0.7)",
      borderColor: "rgba(255, 99, 100, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_day_month",
      stack: "0",
    },
    {
      label: "Pending",
      data: [],
      backgroundColor: "rgba(255, 215, 86, 0.7)",
      borderColor: "rgba(255, 215, 86, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_day_month",
      stack: "0",
    },
    {
      label: "Failed",
      data: [],
      backgroundColor: "rgba(255, 140, 86, 0.7)",
      borderColor: "rgba(255, 140, 86, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_day_month",
      stack: "0",
    },
    {
      label: "All debt",
      data: [],
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(255, 99, 100, 0.7)",
      borderWidth: 1,
      id: "invoice_sum_day_month",
      stack: "2",
      type: "line",
      yAxisID: "second-y-axis",
    },
  ],
};

const INVOICE_SUM_MONTH = {
  labels: [],
  datasets: [
    {
      label: "Invoices",
      data: [],
      backgroundColor: "rgba(54, 162, 235, 0.7)",
      borderColor: "rgba(54, 162, 235, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "1",
    },
    {
      label: "Payments",
      data: [],
      backgroundColor: "rgba(75, 200, 180, 0.7)",
      borderColor: "rgba(75, 200, 180, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "0",
    },
    {
      label: "Debt",
      data: [],
      backgroundColor: "rgba(255, 99, 100, 0.7)",
      borderColor: "rgba(255, 99, 100, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "0",
    },
    {
      label: "Pending",
      data: [],
      backgroundColor: "rgba(255, 215, 86, 0.7)",
      borderColor: "rgba(255, 215, 86, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "0",
    },
    {
      label: "Failed",
      data: [],
      backgroundColor: "rgba(255, 140, 86, 0.7)",
      borderColor: "rgba(255, 140, 86, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "0",
    },
    {
      label: "All debt",
      data: [],
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(255, 99, 100, 0.7)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "2",
      type: "line",
      yAxisID: "second-y-axis",
    },
  ],
};

const INVOICE_NUM_MONTH = {
  labels: [],
  datasets: [
    {
      label: "Invoices",
      data: [],
      backgroundColor: "rgba(54, 162, 235, 0.7)",
      borderColor: "rgba(54, 162, 235, 0.8)",
      borderWidth: 1,
      stack: "1",
      id: "invoice_sum_month",
    },
    {
      label: "Payments",
      data: [],
      backgroundColor: "rgba(75, 200, 180, 0.7)",
      borderColor: "rgba(75, 200, 180, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "0",
    },
    {
      label: "Debt",
      data: [],
      backgroundColor: "rgba(255, 99, 100, 0.7)",
      borderColor: "rgba(255, 99, 100, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "0",
    },
    {
      label: "Pending",
      data: [],
      backgroundColor: "rgba(255, 215, 86, 0.7)",
      borderColor: "rgba(255, 215, 86, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "0",
    },
    {
      label: "Failed",
      data: [],
      backgroundColor: "rgba(255, 140, 86, 0.7)",
      borderColor: "rgba(255, 140, 86, 0.8)",
      borderWidth: 1,
      id: "invoice_sum_month",
      stack: "0",
    },
    {
      label: "All debt",
      data: [],
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(255, 99, 100, 0.7)",
      borderWidth: 1,
      stack: "2",
      type: "line",
      yAxisID: "second-y-axis",
      id: "invoice_sum_month",
    },
  ],
};

const MEMBER_GROWTH_MONTH = {
  labels: [],
  datasets: [
    {
      label: "Propco",
      data: [],
      backgroundColor: "rgba(230, 200, 80, 0.7)",
      borderColor: "rgba(230, 200, 80, 0.8)",
      borderWidth: 1,
      stack: "2",
      id: "member-growth-month",
    },
    {
      label: "Control CSV",
      data: [],
      backgroundColor: "rgba(100,35,120, 0.7)",
      borderColor: "rgba(100,35,120, 0.8)",
      borderWidth: 1,
      stack: "2",
      id: "member-growth-month",
    },
    {
      label: "Control",
      data: [],
      backgroundColor: "rgba(220,120,220, 0.7)",
      borderColor: "rgba(220,120,220, 0.8)",
      borderWidth: 1,
      stack: "2",
      id: "member-growth-month",
    },
    {
      label: "Client API",
      data: [],
      backgroundColor: "rgba(56, 165, 230, 0.7)",
      borderColor: "rgba(56, 165, 230, 0.8)",
      borderWidth: 1,
      stack: "2",
      id: "member-growth-month",
    },
    {
      label: "Client login",
      data: [],
      backgroundColor: "rgba(54, 180, 130, 0.7)",
      borderColor: "rgba(54, 200, 160, 0.8)",
      borderWidth: 1,
      stack: "2",
      id: "member-growth-month",
    },
    {
      label: "Added",
      data: [],
      backgroundColor: "rgba(54, 200, 65, 0.7)",
      borderColor: "rgba(54, 200, 65, 0.8)",
      borderWidth: 1,
      stack: "0",
      id: "member-growth-month",
    },
    {
      label: "Removed",
      data: [],
      backgroundColor: "rgba(255, 85, 95, 0.8)",
      borderColor: "rgba(255, 85, 95, 0.7)",
      borderWidth: 1,
      stack: "0",
      id: "member-growth-month",
    },
    {
      label: "+/- in range",
      data: [],
      backgroundColor: "rgba(95, 110, 245, 0.2)",
      borderColor: "rgba(95, 110, 245, 0.4)",
      borderWidth: 1,
      stack: "9",
      type: "line",
      id: "member-growth-month",
    },
  ],
};

const MEMBER_BREAKDOWN_MONTH = {
  labels: [],
  datasets: [
    {
      label: "Members",
      data: [],
      backgroundColor: "rgba(50, 155, 230, 0.7)",
      borderColor: "rgba(50, 155, 230, 0.8)",
      borderWidth: 1,
      stack: "1",
      id: "member-breakdown-month",
    },
    {
      label: "Premium",
      data: [],
      backgroundColor: "rgba(100,35,120, 0.7)",
      borderColor: "rgba(100,35,120, 0.8)",
      borderWidth: 1,
      stack: "2",
      id: "member-breakdown-month",
    },
    {
      label: "Basic",
      data: [],
      backgroundColor: "rgba(220,120,220, 0.7)",
      borderColor: "rgba(220,120,220, 0.8)",
      borderWidth: 1,
      stack: "2",
      id: "member-breakdown-month",
    },
    {
      label: "Premium active",
      data: [],
      backgroundColor: "rgba(100,35,120, 0.7)",
      borderColor: "rgba(100,35,120, 0.8)",
      borderWidth: 1,
      stack: "5",
      id: "member-breakdown-month",
    },
    {
      label: "Basic active",
      data: [],
      backgroundColor: "rgba(220,120,220, 0.7)",
      borderColor: "rgba(220,120,220, 0.8)",
      borderWidth: 1,
      stack: "5",
      id: "member-breakdown-month",
    },
    {
      label: "Active",
      data: [],
      backgroundColor: "rgba(54, 200, 65, 0.7)",
      borderColor: "rgba(54, 200, 65, 0.8)",
      borderWidth: 1,
      stack: "4",
      id: "member-breakdown-month",
    },
    {
      label: "Active %",
      data: [],
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(54, 200, 65, 0.9)",
      borderWidth: 2,
      stack: "9",
      type: "line",
      id: "member-breakdown-month",
      yAxisID: "second-y-axis",
    },
    {
      label: "Premium active %",
      data: [],
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(100,35,120, 0.9)",
      borderWidth: 2,
      stack: "9",
      type: "line",
      id: "member-breakdown-month",
      yAxisID: "second-y-axis",
    },
    {
      label: "Basic active %",
      data: [],
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(220,120,220, 0.9)",
      borderWidth: 2,
      stack: "9",
      type: "line",
      id: "member-breakdown-month",
      yAxisID: "second-y-axis",
    },
  ],
};

const MEMBER_LABELS_MONTH = {
  labels: [],
  datasets: [
    {
      label: "Tenants",
      data: [],
      backgroundColor: "rgba(240, 210, 80, 0.7)",
      borderColor: "rgba(240, 210, 80, 0.8)",
      borderWidth: 1,
      stack: "3",
      id: "member-labels-month",
    },
    {
      label: "Landlords",
      data: [],
      backgroundColor: "rgba(170, 220, 90, 0.7)",
      borderColor: "rgba(170, 220, 90, 0.8)",
      borderWidth: 1,
      stack: "3",
      id: "member-labels-month",
    },
    {
      label: "Buyers",
      data: [],
      backgroundColor: "rgba(85, 200, 65, 0.7)",
      borderColor: "rgba(85, 200, 65, 0.8)",
      borderWidth: 1,
      stack: "3",
      id: "member-labels-month",
    },
    {
      label: "Vendors",
      data: [],
      backgroundColor: "rgba(55, 180, 88, 0.7)",
      borderColor: "rgba(55, 180, 88, 0.8)",
      borderWidth: 1,
      stack: "3",
      id: "member-labels-month",
    },
    {
      label: "Staff",
      data: [],
      backgroundColor: "rgba(80, 200, 190, 0.7)",
      borderColor: "rgba(80, 200, 190, 0.8)",
      borderWidth: 1,
      stack: "3",
      id: "member-labels-month",
    },
    {
      label: "Student",
      data: [],
      backgroundColor: "rgba(80, 200, 150, 0.7)",
      borderColor: "rgba(80, 200, 190, 0.8)",
      borderWidth: 1,
      stack: "3",
      id: "member-labels-month",
    },
    {
      label: "Distribution %",
      data: [],
      backgroundColor: "rgba(95, 110, 245, 0.1)",
      borderColor: "rgba(95, 110, 245, 0.3)",
      borderWidth: 2,
      stack: "9",
      type: "line",
      id: "member-labels-month",
      yAxisID: "second-y-axis",
    },
  ],
};

const MEMBER_ENGAGEMENT_MONTH = {
  labels: [],
  datasets: [
    {
      label: "Sessions",
      data: [],
      backgroundColor: "rgba(50, 155, 230, 0.7)",
      borderColor: "rgba(50, 155, 230, 0.8)",
      borderWidth: 1,
      stack: "1",
      id: "member-engagement-month",
    },
    {
      label: "Logins",
      data: [],
      backgroundColor: "rgba(55, 180, 88, 0.7)",
      borderColor: "rgba(55, 180, 88, 0.8)",
      borderWidth: 1,
      stack: "2",
      id: "member-engagement-month",
    },
    {
      label: "Returns",
      data: [],
      backgroundColor: "rgba(85, 210, 65, 0.7)",
      borderColor: "rgba(85, 210, 65, 0.8)",
      borderWidth: 1,
      stack: "2",
      id: "member-engagement-month",
    },
    {
      label: "Returns %",
      data: [],
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(85, 210, 65, 0.8)",
      borderWidth: 2,
      stack: "9",
      type: "line",
      id: "member-engagement-month",
      yAxisID: "second-y-axis",
    },
  ],
};

const PrimaryKPIReport = ({ landlordId = 0 }) => {
  let mainStats = { ...MAIN_STATS_REPORT_DRAFT };

  if (landlordId !== 0) {
    mainStats.datasets[0].hidden = true;
  }

  const [startDate, setStartDate] = useState(START_DATE);
  const [endDate, setEndDate] = useState(END_DATE);

  const [vatIncluded, setVatIncluded] = useState(true);

  const [dailyMonthlyInvoiceChartData, setDailyMonthlyInvoiceChartData] =
    useState(INVOICE_SUM_DAY_MONTH);
  const [monthlyInvoiceChartData, setMonthlyInvoiceChartData] =
    useState(INVOICE_SUM_MONTH);
  const [monthlyCountsChartData, setMonthlyCountsChartData] =
    useState(INVOICE_NUM_MONTH);
  const [invoiceKpiData, setInvoiceKpiData] = useState();

  const [accountTypeStatsChartData, setAccountTypeStatsChartData] = useState(
    ACCOUNT_TYPE_STATS_REPORT_DRAFT
  );
  const [upgradeStatsChartData, setUpgradeStatsChartData] = useState(
    UPGRADE_STATS_REPORT_DRAFT
  );
  const [userActivityChartData, setUserActivityStatsChartData] = useState(
    USER_ACTIVITY_REPORT_DRAFT
  );
  const [createdMethodCharData, setCreatedMethodChartData] = useState(
    CREATED_METHOD_REPORT_DRAFT
  );
  const [labelsCharData, setLabelsChartData] = useState(LABELS_REPORT_DRAFT);

  const [mainStatsChartData, setMainStatsChartData] = useState(mainStats);
  const [stats, setStats] = useState(ZERO_STATS);

  const [memberGrowthMonthChartData, setMemberGrowthMonthChartData] =
    useState(MEMBER_GROWTH_MONTH);
  const [memberBreakdownMonthChartData, setMemberBreakdownMonthChartData] =
    useState(MEMBER_BREAKDOWN_MONTH);
  const [memberLabelsMonthChartData, setMemberLabelsMonthChartData] =
    useState(MEMBER_LABELS_MONTH);
  const [memberEngagementMonthChartData, setMemberEngagementMonthChartData] =
    useState(MEMBER_ENGAGEMENT_MONTH);
  const [memberKpiData, setMemberKpiData] = useState();

  const handleDateRangeChange = (dates) => {
    // setStartDate(dates.startDate[0] + '-' + ('0000' + daysInThisMonth(new Date(dates.startDate[0])).toString()).slice(-2));
    // setEndDate(dates.endDate[0] + '-' + ('0000' + daysInThisMonth(new Date(dates.endDate[0])).toString()).slice(-2));
    setStartDate(dates.startDate[0]);
    setEndDate(dates.endDate[0]);
    refetch();
  };

  const vatSwitch = (value = 0) => {
    if (!value) return 0;
    if (vatIncluded) return commaFloat(value);
    return commaFloat((value / 6) * 5);
  };

  const chartOptions = {
    tooltips: { mode: "index", intersect: false },
    keepApectRatio: false,
    responsive: true,
    maintainAspectRatio: true,
    animation: { duration: 300, easing: "easeOutQuad" },
    hover: { animationDuration: 125 },
    legend: {
      onHover: () => killTooltips(),
      labels: {
        boxWidth: 12,
        fontSize: 10,
        fontFamily:
          'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      backgroundColor: "rgba(66,66,66,0.8)",
      titleMarginBottom: 0,
      titleFontSize: 0,
      bodySpacing: 4,
      bodyFontSize: 11,
      footerMarginTop: 6,
      footerAlign: "right",
      bodyFontFamily:
        'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
      cornerRadius: 0,
      caretSize: 4,
      borderWidth: 0,
      xPadding: 7,
      yPadding: 7,
      position: "nearest",
      callbacks: {
        footer: function (tooltipItem, data) {
          return tooltipItem[0].label;
        },
        beforeBody: function (tooltipItem, data) {
          return data.datasets[0].id;
        },
        labelColor: function (tooltipItem, chart) {
          return {
            backgroundColor:
              chart.data.datasets[tooltipItem.datasetIndex].borderColor[0],
          };
        },
        labelTextColor: function (tooltipItem, chart) {
          return `rgba(255,255,255, 1)`;
        },
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ": " +
            commaInt(tooltipItem.value)
          );
        },
      },
      enabled: false,
      custom: customTooltips,
    },
    scaleLabel: { fontSize: 10 },
    elements: {
      point: { pointStyle: "circle", radius: 1, hoverRadius: 1 },
      line: { tension: 0.3, clip: { left: 5, top: 0, right: -2, bottom: 10 } },
    },
    scales: {
      yAxes: [
        {
          gridLines: { color: "rgba(200, 200, 200, 0.1)", zeroLineWidth: 0 },
          ticks: {
            fontSize: 10,
            beginAtZero: false,
            fontColor: "rgba(100, 100, 100, 0.7)",
            fontFamily:
              'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
            callback: function (value, index, values) {
              return truncateNumber(value)[0] + truncateNumber(value)[2];
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: { color: "rgba(200, 200, 200, 0.1)", zeroLineWidth: 0 },
          ticks: {
            fontSize: 10,
            fontColor: "rgba(100, 100, 100, 0.7)",
            fontFamily:
              'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
          },
          type: "time",
          time: {
            displayFormats: {
              day: "DD/MM/YY",
              week: "DD MMM 'YY",
              month: "MMM 'YY",
              quarter: "MMM 'YY",
            },
          },
          distribution: "series",
        },
      ],
    },
  };

  const chartOptionsSwitch = (
    yTicks1 = null,
    yTicks2 = null,
    tooltipLabelFunc = undefined
  ) => {
    let chartOptions = { ...BarOptions(tooltipLabelFunc) };
    if (yTicks1)
      chartOptions.scales.yAxes[0].ticks = {
        ...chartOptions.scales.yAxes[0].ticks,
        ...yTicks1,
      };
    if (yTicks2)
      chartOptions.scales.yAxes[1].ticks = {
        ...chartOptions.scales.yAxes[1].ticks,
        ...yTicks2,
      };
    return chartOptions;
  };

  const getDaysBetween = (date_1, date_2) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  const today = new Date();
  today.setHours(0, 0, 0);
  const todaySafe = new Date();
  todaySafe.setHours(12, 0, 0);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0);
  const beforeYesterday = new Date();
  beforeYesterday.setDate(beforeYesterday.getDate() - 2);
  beforeYesterday.setHours(0, 0, 0);

  const getEndDate = (date) => {
    const endSafe = new Date(
      date + "-" + ("00" + daysInThisMonth(new Date(date))).toString().slice(-2)
    );
    if (endSafe >= todaySafe) {
      return yesterday;
    }
    endSafe.setHours(0, 0, 0);
    return endSafe;
  };

  const getStartDate = (date) => {
    const startSafe = new Date(date + "-01");
    startSafe.setDate(startSafe.getDate() - 1);
    startSafe.setHours(0, 0, 0);
    return startSafe;
  };

  const getMonthString = (str) => {
    return str == "Jan"
      ? "01"
      : str == "Feb"
      ? "02"
      : str == "Mar"
      ? "03"
      : str == "Apr"
      ? "04"
      : str == "May"
      ? "05"
      : str == "Jun"
      ? "06"
      : str == "Jul"
      ? "07"
      : str == "Aug"
      ? "08"
      : str == "Sep"
      ? "09"
      : str == "Oct"
      ? "10"
      : str == "Nov"
      ? "11"
      : str == "Dec"
      ? "12"
      : moment(yesterday).format("MM");
  };

  const nameToDate = (name = false, year = false) => {
    if (!name || name?.length < 5) return NaN;
    const yearStr = year || moment(yesterday).format("YYYY");
    const newDate = [
      yearStr,
      ...[...name.split("-").reverse()].map((str, i) => {
        return i == 0 ? getMonthString(str) : str;
      }),
    ].join("-");
    return new Date(newDate);
  };

  const getRange = (count) => {
    return Array.from({ length: count }, (_, i) => i);
  };

  const shiftDate = (date, numDays) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return newDate;
  };

  const generateDays = (
    sourceRange = [],
    templateRange = [],
    endDateRange = false,
    nudge = 0,
    entity = false
  ) => {
    const targetDays = templateRange.length;
    if (targetDays < 1) return [];

    const templateEnd =
      templateRange[(templateRange?.length || 1) - 1]?.date ||
      endDateRange ||
      new Date();

    const sourceEnd = sourceRange[sourceRange?.length - 1]?.date
      ? new Date(sourceRange[(sourceRange?.length || 0) - 1]?.date)
      : sourceRange[(sourceRange?.length || 0) - 1]?.name
      ? nameToDate(sourceRange[(sourceRange?.length || 0) - 1]?.name)
      : templateEnd;

    const end = new Date(templateEnd);
    end.setHours(0, 0, 0, 0);
    templateEnd.setHours(0, 0, 0, 0);
    sourceEnd.setHours(0, 0, 0, 0);
    const templateEndShort = getDaysBetween(end, templateEnd);
    const templateStartShort =
      targetDays - templateRange.length + templateEndShort;
    const sourceEndShort = getDaysBetween(end, sourceEnd);

    let tempSource = [...sourceRange];

    if (tempSource.length > 0) {
      if (sourceEndShort < 0) {
        tempSource = tempSource.slice(sourceEndShort);
      } else if (sourceEndShort > 0) {
        let tail = getRange(sourceEndShort).map((i) => {
          return {
            value: 0, ////
            date: shiftDate(end, i - sourceEndShort),
          };
        });
        tempSource = [...tempSource, ...tail];
      }
      if (tempSource.length > targetDays) {
        tempSource = tempSource.slice(tempSource.length - targetDays);
      } else if (tempSource.length < targetDays) {
        tempSource = [
          ...getRange(targetDays - tempSource.length).map((v) => NaN),
          ...tempSource,
        ];
      }
    }

    var offset = getRange(templateStartShort);
    var targetRange = getRange(
      targetDays - templateStartShort - templateEndShort
    );
    var tail = getRange(templateEndShort);

    const completed = [...offset, ...targetRange, ...tail].map((i) => {
      let ticker = i + 1;
      let shift = i - targetDays + 1;
      let value = parseFloat(tempSource[i]?.value) || 0;

      let date = shiftDate(end, shift);
      if (ticker <= offset.length) {
        return {
          date: date,
          value: value,
        };
      } else if (ticker <= offset.length + targetRange.length) {
        return {
          // date1: templateRange[i - offset.length]?.date || null,
          // index: i - offset.length,
          date: date,
          value: value,
        };
      } else if (ticker <= offset.length + targetRange.length + tail.length) {
        return {
          date: date,
          value: value,
        };
      }
    });
    return completed;
  };

  const { loading, refetch } = useQuery(PRIMARY_KPI_REPORT, {
    variables: {
      account_id: landlordId,
      start_date: moment(getStartDate(startDate)).format("YYYY-MM-DD"),
      end_date: moment(getEndDate(endDate)).format("YYYY-MM-DD"),
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => {
      const endDateRange = getEndDate(endDate);

      const invoiceTimeline =
        d?.primaryKPIReport?.invoice_timeline?.data
          ?.slice(1)
          .map((day) => day.data) || [];
      // const invoiceTimeline = d?.primaryKPIReport?.invoice_timeline?.data?.map(day => day.data) || []

      const globalLabelz = [
        ...d.primaryKPIReport.label_daily_totals.map((day) => {
          return { date: new Date(day.date) };
        }),
      ];
      const globalLabels = generateDays(
        [],
        [...globalLabelz].slice(1),
        endDateRange
      );
      const dateLabels = globalLabels.map((day) => new Date(day.date));

      let monthIndexes = [];

      const monthLabels = dateLabels.reduce((acc, day, index) => {
        if (
          day.getDate() === daysInThisMonth(day) ||
          index === dateLabels.length - 1
        ) {
          monthIndexes.push(index);
          return [...acc, moment(day).format("MMM YYYY")];
        }
        return acc;
      }, []);

      const normalizeMonthSet = (dataset) => {
        let monthSet = [];
        console.log("monthIndexes", monthIndexes);
        monthIndexes.forEach((index, i) => {
          // get values from last day of month
          monthSet.push(dataset[index]?.value || 0);
        });
        return monthSet;
      };

      const normalizeDayMonthSet = (dataset) => {
        let daySet = [];
        let sum = 0;
        dataset.forEach((day, i) => {
          // get values from last day of month
          let dayVal = parseFloat(day.value);
          daySet.push(sum + dayVal);
          if (monthIndexes.findIndex((v) => v === i) > -1) sum = 0;
          else sum += dayVal;
        });
        return daySet;
      };

      const onlyFullMonths = (data) => {
        let tempValueData = [...data];
        if (
          dateLabels[dateLabels.length - 1].getDate() < 28 &&
          monthLabels.length > 1
        )
          tempValueData.pop();
        return tempValueData;
      };

      const getValueTop = (data) => {
        let tempValueData = onlyFullMonths(data);
        let top = { index: -1, value: -9999999, label: "" };
        tempValueData.forEach((value, index) => {
          if (value >= top.value)
            top = { index: index, value: value, label: monthLabels[index] };
        });
        if (top.value === 0) top = { index: -1, value: -9999999, label: "" };
        return top;
      };

      const getValueBottom = (data) => {
        let tempValueData = onlyFullMonths(data);
        let bottom = { index: -1, value: 9999999, label: "" };
        tempValueData.forEach((value, index) => {
          if (value <= bottom.value)
            bottom = { index: index, value: value, label: monthLabels[index] };
        });
        if (bottom.value === 0)
          bottom = { index: -1, value: 9999999, label: "" };
        return bottom;
      };

      const getValueBest = (data) => {
        let tempValueData = onlyFullMonths(data);
        let past = tempValueData[0];
        let best = { index: -1, value: -9999999, label: "" };
        tempValueData.forEach((value, index) => {
          let dif = value - past;
          past = value;
          if (dif >= best.value)
            best = { index: index, value: dif, label: monthLabels[index] };
        });
        if (best.value === 0) best = { index: -1, value: -9999999, label: "" };
        return best;
      };

      const getValueWorst = (data) => {
        let tempValueData = onlyFullMonths([...data]);
        let past = tempValueData[0];
        let worst = { index: -1, value: 9999999, label: "" };
        tempValueData.forEach((value, index) => {
          let dif = value - past;
          past = value;
          if (dif <= worst.value && (dif !== 0 || index !== 0))
            worst = { index: index, value: dif, label: monthLabels[index] };
        });
        if (worst.value == 0 && worst.index == 0)
          worst = { index: -1, value: 9999999, label: "" };
        return worst;
      };

      if (invoiceTimeline?.length > 0) {
        // Invoices Daily Monthly Stats
        const invoice_sum_daily_monthly = Object.assign(
          {},
          INVOICE_SUM_DAY_MONTH
        );
        invoice_sum_daily_monthly.labels = [
          ...globalLabels.map((v) => moment(v.date).format("DD MMM YYYY")),
        ];
        invoice_sum_daily_monthly.datasets[0].data = generateDays(
          invoiceTimeline.map((day) => {
            return { value: day.bill_sum_month, date: new Date(day.date) };
          }),
          [...globalLabels]
        ).map((v) => v.value);
        invoice_sum_daily_monthly.datasets[1].data = generateDays(
          invoiceTimeline.map((day) => {
            return { value: day.paid_sum_month, date: new Date(day.date) };
          }),
          [...globalLabels]
        ).map((v) => v.value);
        invoice_sum_daily_monthly.datasets[2].data = generateDays(
          invoiceTimeline.map((day) => {
            return { value: day.debt_sum_month, date: new Date(day.date) };
          }),
          [...globalLabels]
        ).map((v) => v.value);
        invoice_sum_daily_monthly.datasets[3].data = generateDays(
          invoiceTimeline.map((day) => {
            return { value: day.wait_sum_today, date: new Date(day.date) };
          }),
          [...globalLabels]
        ).map((v) => v.value);
        invoice_sum_daily_monthly.datasets[4].data = generateDays(
          invoiceTimeline.map((day) => {
            return { value: day.fail_sum_today, date: new Date(day.date) };
          }),
          [...globalLabels]
        ).map((v) => v.value);
        invoice_sum_daily_monthly.datasets[5].data = generateDays(
          invoiceTimeline.map((day) => {
            return { value: day.debt_sum_total, date: new Date(day.date) };
          }),
          [...globalLabels]
        ).map((v) => v.value);
        setDailyMonthlyInvoiceChartData(invoice_sum_daily_monthly);

        // Invoices Monthly Stats
        const invoice_sum_monthly = Object.assign({}, INVOICE_SUM_MONTH);
        invoice_sum_monthly.labels = monthLabels;
        invoice_sum_monthly.datasets[0].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.bill_sum_month, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly.datasets[1].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.paid_sum_month, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly.datasets[2].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.debt_sum_month, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly.datasets[3].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.wait_sum_today, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly.datasets[4].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.fail_sum_today, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly.datasets[5].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.debt_sum_total, date: new Date(day.date) };
          })
        );
        setMonthlyInvoiceChartData(invoice_sum_monthly);

        // Invoices Monthly Counts
        const invoice_sum_monthly_counts = Object.assign({}, INVOICE_NUM_MONTH);
        invoice_sum_monthly_counts.labels = monthLabels;
        invoice_sum_monthly_counts.datasets[0].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.bill_num_month, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly_counts.datasets[1].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.paid_num_month, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly_counts.datasets[2].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.debt_num_month, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly_counts.datasets[3].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.wait_num_today, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly_counts.datasets[4].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.fail_num_today, date: new Date(day.date) };
          })
        );
        invoice_sum_monthly_counts.datasets[5].data = normalizeMonthSet(
          invoiceTimeline.map((day) => {
            return { value: day.debt_num_total, date: new Date(day.date) };
          })
        );
        setMonthlyCountsChartData(invoice_sum_monthly_counts);

        const invoice_kpi_data = {
          // ALL TIME
          bill_total_sum:
            invoiceTimeline[dateLabels.length - 1]?.bill_sum_total || 0,
          paid_total_sum:
            invoiceTimeline[dateLabels.length - 1]?.paid_sum_total || 0,
          debt_total_sum:
            invoiceTimeline[dateLabels.length - 1]?.debt_sum_total || 0,
          wait_total_sum:
            invoiceTimeline[dateLabels.length - 1]?.wait_sum_total || 0,
          fail_total_sum:
            invoiceTimeline[dateLabels.length - 1]?.fail_sum_today || 0,
          bill_total_sum_stripe:
            invoiceTimeline[dateLabels.length - 1]?.bill_sum_total_stripe || 0,
          bill_total_sum_gocard:
            invoiceTimeline[dateLabels.length - 1]?.bill_sum_total_gocard || 0,
          unpaid_total_sum:
            (invoiceTimeline[dateLabels.length - 1]?.bill_sum_total || 0) -
            (invoiceTimeline[dateLabels.length - 1]?.paid_sum_total || 0),
          bill_total_num:
            invoiceTimeline[dateLabels.length - 1]?.bill_num_total || 0,
          paid_total_num:
            invoiceTimeline[dateLabels.length - 1]?.paid_num_total || 0,
          debt_total_num:
            invoiceTimeline[dateLabels.length - 1]?.debt_num_total || 0,
          wait_total_num:
            invoiceTimeline[dateLabels.length - 1]?.wait_num_total || 0,
          fail_total_num:
            invoiceTimeline[dateLabels.length - 1]?.fail_num_today || 0,
          bill_total_num_stripe:
            invoiceTimeline[dateLabels.length - 1]?.bill_num_total_stripe || 0,
          bill_total_num_gocard:
            invoiceTimeline[dateLabels.length - 1]?.bill_num_total_gocard || 0,
          unpaid_total_num:
            (invoiceTimeline[dateLabels.length - 1]?.bill_num_total || 0) -
            (invoiceTimeline[dateLabels.length - 1]?.paid_num_total || 0),

          // IN RANGE
          bill_sum: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return { value: day.bill_sum_today, date: new Date(day.date) };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),
          paid_sum: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return { value: day.paid_sum_today, date: new Date(day.date) };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),
          debt_sum:
            getSumCount(
              generateDays(
                invoiceTimeline.map((day) => {
                  return {
                    value: day.bill_sum_today,
                    date: new Date(day.date),
                  };
                }),
                [...globalLabels]
              ).map((v) => v.value) || []
            ) -
            getSumCount(
              generateDays(
                invoiceTimeline.map((day) => {
                  return {
                    value: day.paid_sum_today,
                    date: new Date(day.date),
                  };
                }),
                [...globalLabels]
              ).map((v) => v.value) || []
            ) -
            (invoiceTimeline[dateLabels.length - 1]?.wait_sum_total || 0) -
            (invoiceTimeline[dateLabels.length - 1]?.fail_sum_today || 0),
          wait_sum: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return { value: day.wait_sum_today, date: new Date(day.date) };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),
          unpaid_sum:
            getSumCount(
              generateDays(
                invoiceTimeline.map((day) => {
                  return {
                    value: day.bill_sum_today,
                    date: new Date(day.date),
                  };
                }),
                [...globalLabels]
              ).map((v) => v.value) || []
            ) -
            getSumCount(
              generateDays(
                invoiceTimeline.map((day) => {
                  return {
                    value: day.paid_sum_today,
                    date: new Date(day.date),
                  };
                }),
                [...globalLabels]
              ).map((v) => v.value) || []
            ),

          fail_sum: invoiceTimeline[dateLabels.length - 1]?.fail_sum_today || 0,
          bill_sum_stripe: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return {
                  value: day.bill_sum_today_stripe,
                  date: new Date(day.date),
                };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),
          bill_sum_gocard: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return {
                  value: day.bill_sum_today_gocard,
                  date: new Date(day.date),
                };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),

          bill_num: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return { value: day.bill_num_today, date: new Date(day.date) };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),
          paid_num: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return { value: day.paid_num_today, date: new Date(day.date) };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),
          debt_num:
            getSumCount(
              generateDays(
                invoiceTimeline.map((day) => {
                  return {
                    value: day.bill_num_today,
                    date: new Date(day.date),
                  };
                }),
                [...globalLabels]
              ).map((v) => v.value) || []
            ) -
            getSumCount(
              generateDays(
                invoiceTimeline.map((day) => {
                  return {
                    value: day.paid_num_today,
                    date: new Date(day.date),
                  };
                }),
                [...globalLabels]
              ).map((v) => v.value) || []
            ) -
            (invoiceTimeline[dateLabels.length - 1]?.wait_num_total || 0) -
            (invoiceTimeline[dateLabels.length - 1]?.fail_num_today || 0),
          wait_num: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return { value: day.wait_num_today, date: new Date(day.date) };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),
          fail_num: invoiceTimeline[dateLabels.length - 1]?.fail_num_today || 0,
          bill_num_stripe: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return {
                  value: day.bill_num_today_stripe,
                  date: new Date(day.date),
                };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),
          bill_num_gocard: getSumCount(
            generateDays(
              invoiceTimeline.map((day) => {
                return {
                  value: day.bill_num_today_gocard,
                  date: new Date(day.date),
                };
              }),
              [...globalLabels]
            ).map((v) => v.value) || []
          ),
          unpaid_num:
            getSumCount(
              generateDays(
                invoiceTimeline.map((day) => {
                  return {
                    value: day.bill_num_today,
                    date: new Date(day.date),
                  };
                }),
                [...globalLabels]
              ).map((v) => v.value) || []
            ) -
            getSumCount(
              generateDays(
                invoiceTimeline.map((day) => {
                  return {
                    value: day.paid_num_today,
                    date: new Date(day.date),
                  };
                }),
                [...globalLabels]
              ).map((v) => v.value) || []
            ),

          // PER MONTH AVERAGE
          bill_month_sum: getSumCount(
            onlyFullMonths(invoice_sum_monthly.datasets[0].data || [])
          ),
          paid_month_sum: getSumCount(
            onlyFullMonths(invoice_sum_monthly.datasets[1].data || [])
          ),
          debt_month_sum: getSumCount(
            onlyFullMonths(invoice_sum_monthly.datasets[2].data || [])
          ),
          wait_month_sum: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.wait_sum_today };
                })
              ) || []
            )
          ),
          fail_month_sum: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.fail_sum_today };
                })
              ) || []
            )
          ),
          bill_month_sum_stripe: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.bill_sum_month_stripe };
                })
              ) || []
            )
          ),
          bill_month_sum_gocard: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.bill_sum_month_gocard };
                })
              ) || []
            )
          ),
          unpaid_month_sum:
            getSumCount(
              onlyFullMonths(invoice_sum_monthly.datasets[0].data || [])
            ) -
            getSumCount(
              onlyFullMonths(invoice_sum_monthly.datasets[1].data || [])
            ),
          bill_month_num: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.bill_num_month };
                })
              ) || []
            )
          ),
          paid_month_num: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.paid_num_month };
                })
              ) || []
            )
          ),
          debt_month_num: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.debt_num_month };
                })
              ) || []
            )
          ),
          wait_month_num: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.wait_num_today };
                })
              ) || []
            )
          ),
          fail_month_num: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.fail_num_today };
                })
              ) || []
            )
          ),
          bill_month_num_stripe: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.bill_num_month_stripe };
                })
              ) || []
            )
          ),
          bill_month_num_gocard: getSumCount(
            onlyFullMonths(
              normalizeMonthSet(
                invoiceTimeline.map((day) => {
                  return { value: day.bill_num_month_gocard };
                })
              ) || []
            )
          ),
          unpaid_month_num:
            getSumCount(
              onlyFullMonths(
                normalizeMonthSet(
                  invoiceTimeline.map((day) => {
                    return { value: day.bill_num_month };
                  })
                ) || []
              )
            ) -
            getSumCount(
              onlyFullMonths(
                normalizeMonthSet(
                  invoiceTimeline.map((day) => {
                    return { value: day.paid_num_month };
                  })
                ) || []
              )
            ),

          all_debt_month_sum: getSumCount(
            onlyFullMonths(invoice_sum_monthly.datasets[5].data || [])
          ),
          all_debt_month_num: getSumCount(
            onlyFullMonths(invoice_sum_monthly_counts.datasets[5].data || [])
          ),
        };

        // ALL TIME (AVERAGES)
        invoice_kpi_data.bill_total_avg =
          Math.floor(
            (invoice_kpi_data.bill_total_sum /
              invoice_kpi_data.bill_total_num) *
              10
          ) / 10 || 0;
        invoice_kpi_data.paid_total_avg =
          Math.floor(
            (invoice_kpi_data.paid_total_sum /
              invoice_kpi_data.paid_total_num) *
              10
          ) / 10 || 0;
        invoice_kpi_data.debt_total_avg =
          Math.floor(
            (invoice_kpi_data.debt_total_sum /
              invoice_kpi_data.debt_total_num) *
              10
          ) / 10 || 0;
        invoice_kpi_data.wait_total_avg =
          Math.floor(
            (invoice_kpi_data.wait_total_sum /
              invoice_kpi_data.wait_total_num) *
              10
          ) / 10 || 0;
        invoice_kpi_data.fail_total_avg =
          Math.floor(
            (invoice_kpi_data.fail_total_sum /
              invoice_kpi_data.fail_total_num) *
              10
          ) / 10 || 0;
        invoice_kpi_data.unpaid_total_avg =
          Math.floor(
            (invoice_kpi_data.unpaid_total_sum /
              invoice_kpi_data.unpaid_total_num) *
              10
          ) / 10 || 0;

        // IN RANGE (AVERAGES)
        invoice_kpi_data.bill_avg =
          Math.floor(
            (invoice_kpi_data.bill_sum / invoice_kpi_data.bill_num) * 10
          ) / 10 || 0;
        invoice_kpi_data.paid_avg =
          Math.floor(
            (invoice_kpi_data.paid_sum / invoice_kpi_data.paid_num) * 10
          ) / 10 || 0;
        invoice_kpi_data.debt_avg =
          Math.floor(
            (invoice_kpi_data.debt_sum / invoice_kpi_data.debt_num) * 10
          ) / 10 || 0;
        invoice_kpi_data.wait_avg =
          Math.floor(
            (invoice_kpi_data.wait_sum / invoice_kpi_data.wait_num) * 10
          ) / 10 || 0;
        invoice_kpi_data.fail_avg =
          Math.floor(
            (invoice_kpi_data.fail_sum / invoice_kpi_data.fail_num) * 10
          ) / 10 || 0;
        invoice_kpi_data.unpaid_avg =
          Math.floor(
            (invoice_kpi_data.unpaid_sum / invoice_kpi_data.unpaid_num) * 10
          ) / 10 || 0;

        // PER MONTH AVERAGE (AVERAGES)
        invoice_kpi_data.bill_month_avg =
          Math.floor(
            (invoice_kpi_data.bill_month_sum /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.paid_month_avg =
          Math.floor(
            (invoice_kpi_data.paid_month_sum /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.debt_month_avg =
          Math.floor(
            (invoice_kpi_data.debt_month_sum /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.wait_month_avg =
          Math.floor(
            (invoice_kpi_data.wait_month_sum /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.fail_month_avg =
          Math.floor(
            (invoice_kpi_data.fail_month_sum /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.bill_month_stripe_avg =
          Math.floor(
            (invoice_kpi_data.bill_month_sum_stripe /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.bill_month_gocard_avg =
          Math.floor(
            (invoice_kpi_data.bill_month_sum_gocard /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.unpaid_month_avg =
          Math.floor(
            (invoice_kpi_data.unpaid_month_sum /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.bill_month_avg_num =
          Math.floor(
            (invoice_kpi_data.bill_month_num /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.paid_month_avg_num =
          Math.floor(
            (invoice_kpi_data.paid_month_num /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.debt_month_avg_num =
          Math.floor(
            (invoice_kpi_data.debt_month_num /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.wait_month_avg_num =
          Math.floor(
            (invoice_kpi_data.wait_month_num /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.fail_month_avg_num =
          Math.floor(
            (invoice_kpi_data.fail_month_num /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.bill_month_stripe_avg_num =
          Math.floor(
            (invoice_kpi_data.bill_month_num_stripe /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.bill_month_gocard_avg_num =
          Math.floor(
            (invoice_kpi_data.bill_month_num_gocard /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.unpaid_month_avg_num =
          Math.floor(
            (invoice_kpi_data.unpaid_month_num /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;

        // ALL DEBT MONTHLY (AVERAGES)
        invoice_kpi_data.all_debt_month_avg =
          Math.floor(
            (invoice_kpi_data.all_debt_month_sum /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;
        invoice_kpi_data.all_debt_month_avg_num =
          Math.floor(
            (invoice_kpi_data.all_debt_month_num /
              onlyFullMonths(monthLabels).length) *
              10
          ) / 10 || 0;

        // PER DAY AVERAGE (AVERAGES)
        invoice_kpi_data.bill_day_avg =
          Math.floor((invoice_kpi_data.bill_sum / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.paid_day_avg =
          Math.floor((invoice_kpi_data.paid_sum / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.debt_day_avg =
          Math.floor((invoice_kpi_data.debt_sum / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.wait_day_avg =
          Math.floor((invoice_kpi_data.wait_sum / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.fail_day_avg =
          Math.floor((invoice_kpi_data.fail_sum / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.bill_day_stripe_avg =
          Math.floor(
            (invoice_kpi_data.bill_sum_stripe / dateLabels.length) * 10
          ) / 10 || 0;
        invoice_kpi_data.bill_day_gocard_avg =
          Math.floor(
            (invoice_kpi_data.bill_sum_gocard / dateLabels.length) * 10
          ) / 10 || 0;
        invoice_kpi_data.bill_day_avg_num =
          Math.floor((invoice_kpi_data.bill_num / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.paid_day_avg_num =
          Math.floor((invoice_kpi_data.paid_num / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.debt_day_avg_num =
          Math.floor((invoice_kpi_data.debt_num / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.wait_day_avg_num =
          Math.floor((invoice_kpi_data.wait_num / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.fail_day_avg_num =
          Math.floor((invoice_kpi_data.fail_num / dateLabels.length) * 10) /
            10 || 0;
        invoice_kpi_data.bill_day_stripe_avg_num =
          Math.floor(
            (invoice_kpi_data.bill_num_stripe / dateLabels.length) * 10
          ) / 10 || 0;
        invoice_kpi_data.bill_day_gocard_avg_num =
          Math.floor(
            (invoice_kpi_data.bill_num_gocard / dateLabels.length) * 10
          ) / 10 || 0;

        invoice_kpi_data.bill_diffs = [...monthLabels].map((month, index) => {
          if (index < 1)
            return (
              (invoice_sum_monthly?.datasets[0]?.data[0] || 0) -
              (d?.primaryKPIReport?.invoice_timeline?.data[0]?.data
                ?.bill_sum_month || 0)
            );
          else
            return (
              (invoice_sum_monthly?.datasets[0]?.data[index] || 0) -
              (invoice_sum_monthly?.datasets[0]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        });
        invoice_kpi_data.paid_diffs = [...monthLabels].map((month, index) => {
          if (index < 1)
            return (
              (invoice_sum_monthly?.datasets[1]?.data[0] || 0) -
              (d?.primaryKPIReport?.invoice_timeline?.data[0]?.data
                ?.paid_sum_month || 0)
            );
          else
            return (
              (invoice_sum_monthly?.datasets[1]?.data[index] || 0) -
              (invoice_sum_monthly?.datasets[1]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        });
        invoice_kpi_data.debt_diffs = [...monthLabels].map((month, index) => {
          if (index < 1)
            return (
              (invoice_sum_monthly?.datasets[2]?.data[0] || 0) -
              (d?.primaryKPIReport?.invoice_timeline?.data[0]?.data
                ?.debt_sum_month || 0)
            );
          else
            return (
              (invoice_sum_monthly?.datasets[2]?.data[index] || 0) -
              (invoice_sum_monthly?.datasets[2]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        });
        invoice_kpi_data.wait_diffs = [...monthLabels].map((month, index) => {
          if (index < 1)
            return (
              (invoice_sum_monthly?.datasets[3]?.data[0] || 0) -
              (d?.primaryKPIReport?.invoice_timeline?.data[0]?.data
                ?.wait_sum_month || 0)
            );
          else
            return (
              (invoice_sum_monthly?.datasets[3]?.data[index] || 0) -
              (invoice_sum_monthly?.datasets[3]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        });
        invoice_kpi_data.fail_diffs = [...monthLabels].map(
          (month, index) =>
            (invoice_sum_monthly?.datasets[4]?.data[index] || 0) -
            (invoice_sum_monthly?.datasets[4]?.data[Math.max(index - 1, 0)] ||
              0)
        );
        invoice_kpi_data.all_debt_diffs = [...monthLabels].map(
          (month, index) => {
            if (index < 1)
              return (
                (invoice_sum_monthly?.datasets[5]?.data[0] || 0) -
                (d?.primaryKPIReport?.invoice_timeline?.data[0]?.data
                  ?.debt_sum_total || 0)
              );
            else
              return (
                (invoice_sum_monthly?.datasets[5]?.data[index] || 0) -
                (invoice_sum_monthly?.datasets[5]?.data[
                  Math.max(index - 1, 0)
                ] || 0)
              );
          }
        );

        invoice_kpi_data.bill_avg_diffs = [...monthLabels].map(
          (month, index) =>
            (invoice_kpi_data?.bill_diffs[index] || 0) /
            (invoice_sum_monthly_counts?.datasets[0]?.data[index] || 0)
        );
        invoice_kpi_data.paid_avg_diffs = [...monthLabels].map(
          (month, index) =>
            (invoice_kpi_data?.paid_diffs[index] || 0) /
            (invoice_sum_monthly_counts?.datasets[1]?.data[index] || 0)
        );
        invoice_kpi_data.debt_avg_diffs = [...monthLabels].map(
          (month, index) =>
            (invoice_kpi_data?.debt_diffs[index] || 0) /
            (invoice_sum_monthly_counts?.datasets[2]?.data[index] || 0)
        );
        invoice_kpi_data.wait_avg_diffs = [...monthLabels].map(
          (month, index) =>
            (invoice_kpi_data?.wait_diffs[index] || 0) /
            (invoice_sum_monthly_counts?.datasets[3]?.data[index] || 0)
        );
        invoice_kpi_data.fail_avg_diffs = [...monthLabels].map(
          (month, index) =>
            (invoice_kpi_data?.fail_diffs[index] || 0) /
            (invoice_sum_monthly_counts?.datasets[4]?.data[index] || 0)
        );

        invoice_kpi_data.all_debt_avg_diffs = [...monthLabels].map(
          (month, index) =>
            (invoice_kpi_data?.all_debt_diffs[index] || 0) /
            (invoice_sum_monthly_counts?.datasets[5]?.data[index] || 0)
        );
        invoice_kpi_data.bill_month_avg_diff =
          getSumCount(onlyFullMonths(invoice_kpi_data?.bill_avg_diffs || [])) ||
          0;
        invoice_kpi_data.paid_month_avg_diff =
          getSumCount(onlyFullMonths(invoice_kpi_data?.paid_avg_diffs || [])) ||
          0;
        invoice_kpi_data.debt_month_avg_diff =
          getSumCount(onlyFullMonths(invoice_kpi_data?.debt_avg_diffs || [])) ||
          0;
        invoice_kpi_data.wait_month_avg_diff =
          getSumCount(onlyFullMonths(invoice_kpi_data?.wait_avg_diffs || [])) ||
          0;
        invoice_kpi_data.fail_month_avg_diff =
          getSumCount(onlyFullMonths(invoice_kpi_data?.fail_avg_diffs || [])) ||
          0;
        invoice_kpi_data.all_debt_month_avg_diff =
          getSumCount(
            onlyFullMonths(invoice_kpi_data?.all_debt_avg_diffs || [])
          ) || 0;

        invoice_kpi_data.bill_month_dif =
          getSumCount(onlyFullMonths(invoice_kpi_data?.bill_diffs || [])) || 0;
        invoice_kpi_data.paid_month_dif =
          getSumCount(onlyFullMonths(invoice_kpi_data?.paid_diffs || [])) || 0;
        invoice_kpi_data.debt_month_dif =
          getSumCount(onlyFullMonths(invoice_kpi_data?.debt_diffs || [])) || 0;
        invoice_kpi_data.wait_month_dif =
          getSumCount(onlyFullMonths(invoice_kpi_data?.wait_diffs || [])) || 0;
        invoice_kpi_data.fail_month_dif =
          getSumCount(onlyFullMonths(invoice_kpi_data?.fail_diffs || [])) || 0;
        invoice_kpi_data.all_debt_month_dif =
          getSumCount(onlyFullMonths(invoice_kpi_data?.all_debt_diffs || [])) ||
          0;

        invoice_kpi_data.bill_month_var =
          invoice_kpi_data.bill_month_dif /
          Math.max(1, onlyFullMonths(monthLabels).length);
        invoice_kpi_data.paid_month_var =
          invoice_kpi_data.paid_month_dif /
          Math.max(1, onlyFullMonths(monthLabels).length);
        invoice_kpi_data.debt_month_var =
          invoice_kpi_data.debt_month_dif /
          Math.max(1, onlyFullMonths(monthLabels).length);
        invoice_kpi_data.wait_month_var =
          invoice_kpi_data.wait_month_dif /
          Math.max(1, onlyFullMonths(monthLabels).length);
        invoice_kpi_data.fail_month_var =
          invoice_kpi_data.fail_month_dif /
          Math.max(1, onlyFullMonths(monthLabels).length);
        invoice_kpi_data.all_debt_month_var =
          invoice_kpi_data.all_debt_month_dif /
          Math.max(1, onlyFullMonths(monthLabels).length);

        invoice_kpi_data.bill_month_best = getValueBest(
          invoice_sum_monthly.datasets[0].data || []
        );
        invoice_kpi_data.paid_month_best = getValueBest(
          invoice_sum_monthly.datasets[1].data || []
        );
        invoice_kpi_data.debt_month_best = getValueBest(
          invoice_sum_monthly.datasets[2].data || []
        );
        invoice_kpi_data.wait_month_best = getValueBest(
          invoice_sum_monthly.datasets[3].data || []
        );
        invoice_kpi_data.fail_month_best = getValueBest(
          invoice_sum_monthly.datasets[4].data || []
        );
        invoice_kpi_data.all_debt_month_best = getValueBest(
          invoice_sum_monthly.datasets[5].data || []
        );

        invoice_kpi_data.bill_month_worst = getValueWorst(
          invoice_sum_monthly.datasets[0].data || []
        );
        invoice_kpi_data.paid_month_worst = getValueWorst(
          invoice_sum_monthly.datasets[1].data || []
        );
        invoice_kpi_data.debt_month_worst = getValueWorst(
          invoice_sum_monthly.datasets[2].data || []
        );
        invoice_kpi_data.wait_month_worst = getValueWorst(
          invoice_sum_monthly.datasets[3].data || []
        );
        invoice_kpi_data.fail_month_worst = getValueWorst(
          invoice_sum_monthly.datasets[4].data || []
        );
        invoice_kpi_data.all_debt_month_worst = getValueWorst(
          invoice_sum_monthly.datasets[5].data || []
        );

        invoice_kpi_data.bill_month_top = getValueTop(
          invoice_sum_monthly.datasets[0].data || []
        );
        invoice_kpi_data.paid_month_top = getValueTop(
          invoice_sum_monthly.datasets[1].data || []
        );
        invoice_kpi_data.debt_month_top = getValueTop(
          invoice_sum_monthly.datasets[2].data || []
        );
        invoice_kpi_data.wait_month_top = getValueTop(
          invoice_sum_monthly.datasets[3].data || []
        );
        invoice_kpi_data.fail_month_top = getValueTop(
          invoice_sum_monthly.datasets[4].data || []
        );
        invoice_kpi_data.all_debt_month_top = getValueTop(
          invoice_sum_monthly.datasets[5].data || []
        );

        invoice_kpi_data.bill_month_bottom = getValueBottom(
          invoice_sum_monthly.datasets[0].data || []
        );
        invoice_kpi_data.paid_month_bottom = getValueBottom(
          invoice_sum_monthly.datasets[1].data || []
        );
        invoice_kpi_data.debt_month_bottom = getValueBottom(
          invoice_sum_monthly.datasets[2].data || []
        );
        invoice_kpi_data.wait_month_bottom = getValueBottom(
          invoice_sum_monthly.datasets[3].data || []
        );
        invoice_kpi_data.fail_month_bottom = getValueBottom(
          invoice_sum_monthly.datasets[4].data || []
        );
        invoice_kpi_data.all_debt_month_bottom = getValueBottom(
          invoice_sum_monthly.datasets[5].data || []
        );

        setInvoiceKpiData(invoice_kpi_data);
      }

      // Main Stats
      const newMainStatsData = Object.assign({}, MAIN_STATS_REPORT_DRAFT);
      newMainStatsData.labels = [...globalLabels.map((v) => v.date)];
      newMainStatsData.datasets[0].data = generateDays(
        d.primaryKPIReport.clients,
        [...globalLabels]
      ).map((r) => r.value);
      newMainStatsData.datasets[1].data = generateDays(
        d.primaryKPIReport.users,
        [...globalLabels]
      ).map((r) => r.value);
      newMainStatsData.datasets[2].data = generateDays(
        d.primaryKPIReport.active_users,
        [...globalLabels]
      ).map((r) => r.value);
      newMainStatsData.datasets[3].data = generateDays(
        d.primaryKPIReport.new_users,
        [...globalLabels]
      ).map((r) => r.value);
      newMainStatsData.datasets[4].data = generateDays(
        d.primaryKPIReport.deleted_users,
        [...globalLabels]
      ).map((r) => r.value);
      setMainStatsChartData(newMainStatsData);

      let kpisTotals = {
        clients:
          generateDays(d.primaryKPIReport.clients, [...globalLabels]).map(
            (v) => v.value
          ) || [],
        users:
          generateDays(d.primaryKPIReport.users, [...globalLabels]).map(
            (v) => v.value
          ) || [],
        users_active:
          generateDays(d.primaryKPIReport.active_users, [...globalLabels]).map(
            (v) => v.value
          ) || [],
        basic_users:
          generateDays(d.primaryKPIReport.basic_users, [...globalLabels]).map(
            (v) => v.value
          ) || [],
        basic_users_active:
          generateDays(d.primaryKPIReport.basic_users_active, [
            ...globalLabels,
          ]).map((v) => v.value) || [],
        premium_users:
          generateDays(d.primaryKPIReport.premium_users, [...globalLabels]).map(
            (v) => v.value
          ) || [],
        premium_users_active:
          generateDays(d.primaryKPIReport.premium_users_active, [
            ...globalLabels,
          ]).map((v) => v.value) || [],
        label_tenant:
          generateDays(
            d.primaryKPIReport.label_daily_totals.map((d) => {
              return { ...d, value: d.tenant };
            }),
            [...globalLabels]
          ).map((v) => v.value) || [],
        label_landlord:
          generateDays(
            d.primaryKPIReport.label_daily_totals.map((d) => {
              return { ...d, value: d.landlord };
            }),
            [...globalLabels]
          ).map((v) => v.value) || [],
        label_buyer:
          generateDays(
            d.primaryKPIReport.label_daily_totals.map((d) => {
              return { ...d, value: d.buyer };
            }),
            [...globalLabels]
          ).map((v) => v.value) || [],
        label_vendor:
          generateDays(
            d.primaryKPIReport.label_daily_totals.map((d) => {
              return { ...d, value: d.vendor };
            }),
            [...globalLabels]
          ).map((v) => v.value) || [],
        label_staff:
          generateDays(
            d.primaryKPIReport.label_daily_totals.map((d) => {
              return { ...d, value: d.staff };
            }),
            [...globalLabels]
          ).map((v) => v.value) || [],
        label_student:
          generateDays(
            d.primaryKPIReport.label_daily_totals.map((d) => {
              return { ...d, value: d.student };
            }),
            [...globalLabels]
          ).map((v) => v.value) || [],
      };

      kpisTotals.users_active_ratio =
        [...kpisTotals.users_active].map((value, index) => {
          return Math.floor((value / kpisTotals.users[index]) * 10000) / 100;
        }) || [];
      kpisTotals.basic_users_active_ratio =
        [...kpisTotals.basic_users_active].map((value, index) => {
          return (
            Math.floor((value / kpisTotals.basic_users[index]) * 10000) / 100
          );
        }) || [];
      kpisTotals.premium_users_active_ratio =
        [...kpisTotals.premium_users_active].map((value, index) => {
          return (
            Math.floor((value / kpisTotals.premium_users[index]) * 10000) / 100
          );
        }) || [];

      kpisTotals.label_tenant_ratio =
        [...kpisTotals.label_tenant].map((value, index) => {
          return Math.floor((value / kpisTotals.users[index]) * 10000) / 100;
        }) || [];
      kpisTotals.label_buyer_ratio =
        [...kpisTotals.label_buyer].map((value, index) => {
          return Math.floor((value / kpisTotals.users[index]) * 10000) / 100;
        }) || [];
      kpisTotals.label_vendor_ratio =
        [...kpisTotals.label_vendor].map((value, index) => {
          return Math.floor((value / kpisTotals.users[index]) * 10000) / 100;
        }) || [];
      kpisTotals.label_landlord_ratio =
        [...kpisTotals.label_landlord].map((value, index) => {
          return Math.floor((value / kpisTotals.users[index]) * 10000) / 100;
        }) || [];
      kpisTotals.label_staff_ratio =
        [...kpisTotals.label_staff].map((value, index) => {
          return Math.floor((value / kpisTotals.users[index]) * 10000) / 100;
        }) || [];
      kpisTotals.label_student_ratio =
        [...kpisTotals.label_student].map((value, index) => {
          return Math.floor((value / kpisTotals.users[index]) * 10000) / 100;
        }) || [];

      let kpisDaily = {
        users_added:
          generateDays(
            d.primaryKPIReport.new_users.map((day, i) => {
              return { value: day.value, name: day.name };
            }),
            [...globalLabels]
          ) || [],
        users_removed:
          generateDays(
            d.primaryKPIReport.deleted_users.map((day, i) => {
              return { value: day.value, name: day.name };
            }),
            [...globalLabels]
          ) || [],
        added_client_api:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day, i) => {
              return { value: day.client_api, date: new Date(day.date) };
            }),
            [...globalLabels]
          ) || [],
        added_client_dashboard:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day, i) => {
              return { value: day.client_dashboard, date: new Date(day.date) };
            }),
            [...globalLabels]
          ) || [],
        added_control:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day, i) => {
              return { value: day.control, date: new Date(day.date) };
            }),
            [...globalLabels]
          ) || [],
        added_control_csv:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day, i) => {
              return { value: day.control_csv, date: new Date(day.date) };
            }),
            [...globalLabels]
          ) || [],
        added_propco_api:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day, i) => {
              return { value: day.propco_api, date: new Date(day.date) };
            }),
            [...globalLabels]
          ) || [],
        sessions:
          generateDays(
            d.primaryKPIReport.user_sessions_today.map((day, index) => {
              return { value: day.value, name: day.name };
            }),
            [...globalLabels]
          ) || [],
        logins:
          generateDays(
            d.primaryKPIReport.user_logins_today.map((day, index) => {
              return { value: day.value, name: day.name };
            }),
            [...globalLabels]
          ) || [],
      };
      kpisDaily.users_diff = kpisDaily.users_added.map((value, index) => {
        return {
          value: value.value - (kpisDaily?.users_removed[index]?.value || 0),
          date: value.date,
        };
      });
      kpisDaily.returns = kpisDaily.sessions.map((value, index) => {
        return {
          value: value.value - (kpisDaily?.logins[index]?.value || 0),
          date: value.date,
        };
      });
      kpisDaily.returns_ratio = kpisDaily.returns.map((value, index) => {
        return {
          value:
            Math.floor(
              ((value?.value || 0) / (kpisDaily?.sessions[index]?.value || 0)) *
                10000
            ) / 100,
          date: value.date,
        };
      });

      let kpisDailyMonthly = {
        users_added: normalizeDayMonthSet(kpisDaily?.users_added || []) || [],
        users_removed:
          normalizeDayMonthSet(kpisDaily?.users_removed || []) || [],
        added_client_api:
          normalizeDayMonthSet(kpisDaily?.added_client_api || []) || [],
        added_client_dashboard:
          normalizeDayMonthSet(kpisDaily?.added_client_dashboard || []) || [],
        added_control:
          normalizeDayMonthSet(kpisDaily?.added_control || []) || [],
        added_control_csv:
          normalizeDayMonthSet(kpisDaily?.added_control_csv || []) || [],
        added_propco_api:
          normalizeDayMonthSet(kpisDaily?.added_propco_api || []) || [],
        logins: normalizeDayMonthSet(kpisDaily?.logins || []) || [],
        sessions: normalizeDayMonthSet(kpisDaily?.sessions || []) || [],
        returns: normalizeDayMonthSet(kpisDaily?.returns || []) || [],
      };
      kpisDailyMonthly.users_diff = kpisDailyMonthly.users_added.map(
        (value, index) => {
          return value - (kpisDailyMonthly?.users_removed[index] || 0);
        }
      );
      kpisDailyMonthly.returns_ratio = kpisDailyMonthly.returns.map(
        (value, index) => {
          return (
            Math.floor(
              ((value || 0) / (kpisDailyMonthly?.sessions[index] || 0)) * 10000
            ) / 100
          );
        }
      );

      let kpisCumulative = {
        users_added:
          generateDays(
            d.primaryKPIReport.new_users.map((day) => {
              return { name: day.name, value: day.value };
            }),
            [...globalLabels]
          )
            .map((v) => v.value)
            .reduce((acc, day) => {
              return [...acc, (acc[acc?.length - 1] || 0) + day];
            }, []) || [],
        users_removed:
          generateDays(
            d.primaryKPIReport.deleted_users.map((day) => {
              return { name: day.name, value: day.value };
            }),
            [...globalLabels]
          )
            .map((v) => v.value)
            .reduce((acc, day) => {
              return [...acc, (acc[acc?.length - 1] || 0) + day];
            }, []) || [],
        added_client_api:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day) => {
              return { date: day.date, value: day.client_api };
            }),
            [...globalLabels]
          )
            .map((v) => v.value)
            .reduce((acc, day) => {
              return [...acc, (acc[acc?.length - 1] || 0) + day];
            }, []) || [],
        added_client_dashboard:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day) => {
              return { date: day.date, value: day.client_dashboard };
            }),
            [...globalLabels]
          )
            .map((v) => v.value)
            .reduce((acc, day) => {
              return [...acc, (acc[acc?.length - 1] || 0) + day];
            }, []) || [],
        added_control:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day) => {
              return { date: day.date, value: day.control };
            }),
            [...globalLabels]
          )
            .map((v) => v.value)
            .reduce((acc, day) => {
              return [...acc, (acc[acc?.length - 1] || 0) + day];
            }, []) || [],
        added_control_csv:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day) => {
              return { date: day.date, value: day.control_csv };
            }),
            [...globalLabels]
          )
            .map((v) => v.value)
            .reduce((acc, day) => {
              return [...acc, (acc[acc?.length - 1] || 0) + day];
            }, []) || [],
        added_propco_api:
          generateDays(
            d.primaryKPIReport.created_methods_today.map((day) => {
              return { date: day.date, value: day.propco_api };
            }),
            [...globalLabels]
          )
            .map((v) => v.value)
            .reduce((acc, day) => {
              return [...acc, (acc[acc?.length - 1] || 0) + day];
            }, []) || [],
        sessions:
          generateDays(
            d.primaryKPIReport.user_sessions_today.map((day) => {
              return { name: day.name, value: day.value };
            }),
            [...globalLabels]
          )
            .map((v) => v.value)
            .reduce((acc, day) => {
              return [...acc, (acc[acc?.length - 1] || 0) + day];
            }, []) || [],
        logins:
          generateDays(
            d.primaryKPIReport.user_logins_today.map((day) => {
              return { name: day.name, value: day.value };
            }),
            [...globalLabels]
          )
            .map((v) => v.value)
            .reduce((acc, day) => {
              return [...acc, (acc[acc?.length - 1] || 0) + day];
            }, []) || [],
      };
      kpisCumulative.users_diff =
        [...kpisCumulative.users_added].map(
          (value, index) => value - (kpisCumulative?.users_removed[index] || 0)
        ) || [];
      kpisCumulative.returns =
        [...kpisCumulative.sessions].map(
          (value, index) => value - (kpisCumulative?.logins[index] || 0)
        ) || [];
      kpisCumulative.returns_ratio =
        [...kpisCumulative.returns].map(
          (value, index) =>
            Math.floor(
              (value / (kpisCumulative?.sessions[index] || 0)) * 10000
            ) / 100
        ) || [];

      // Monthly Member Growth Stats
      const memberGrowthMonth = Object.assign({}, MEMBER_GROWTH_MONTH);
      memberGrowthMonth.labels = monthLabels;
      memberGrowthMonth.datasets[0].data =
        normalizeMonthSet(
          kpisDailyMonthly.added_propco_api.map((day) => {
            return { value: day };
          })
        ) || [];
      memberGrowthMonth.datasets[1].data =
        normalizeMonthSet(
          kpisDailyMonthly.added_control_csv.map((day) => {
            return { value: day };
          })
        ) || [];
      memberGrowthMonth.datasets[2].data =
        normalizeMonthSet(
          kpisDailyMonthly.added_control.map((day) => {
            return { value: day };
          })
        ) || [];
      memberGrowthMonth.datasets[3].data =
        normalizeMonthSet(
          kpisDailyMonthly.added_client_api.map((day) => {
            return { value: day };
          })
        ) || [];
      memberGrowthMonth.datasets[4].data =
        normalizeMonthSet(
          kpisDailyMonthly.added_client_dashboard.map((day) => {
            return { value: day };
          })
        ) || [];
      memberGrowthMonth.datasets[5].data =
        normalizeMonthSet(
          kpisDailyMonthly.users_added.map((day) => {
            return { value: day };
          })
        ) || [];
      memberGrowthMonth.datasets[6].data =
        normalizeMonthSet(
          kpisDailyMonthly.users_removed.map((day) => {
            return { value: day * -1 };
          })
        ) || [];
      memberGrowthMonth.datasets[7].data =
        normalizeMonthSet(
          kpisCumulative.users_diff.map((day) => {
            return { value: day };
          })
        ) || [];
      setMemberGrowthMonthChartData(memberGrowthMonth); // memberGrowthMonthChartData

      // Monthly Member Breakdown Stats
      const memberBreakdownMonth = Object.assign({}, MEMBER_BREAKDOWN_MONTH);
      memberBreakdownMonth.labels = monthLabels;
      memberBreakdownMonth.datasets[0].data =
        normalizeMonthSet(
          kpisTotals.users.map((day) => {
            return { value: day };
          })
        ) || [];
      memberBreakdownMonth.datasets[1].data =
        normalizeMonthSet(
          kpisTotals.premium_users.map((day) => {
            return { value: day };
          })
        ) || [];
      memberBreakdownMonth.datasets[2].data =
        normalizeMonthSet(
          kpisTotals.basic_users.map((day) => {
            return { value: day };
          })
        ) || [];
      memberBreakdownMonth.datasets[3].data =
        normalizeMonthSet(
          kpisTotals.premium_users_active.map((day) => {
            return { value: day };
          })
        ) || [];
      memberBreakdownMonth.datasets[4].data =
        normalizeMonthSet(
          kpisTotals.basic_users_active.map((day) => {
            return { value: day };
          })
        ) || [];
      memberBreakdownMonth.datasets[5].data =
        normalizeMonthSet(
          kpisTotals.users_active.map((day) => {
            return { value: day };
          })
        ) || [];
      memberBreakdownMonth.datasets[6].data =
        normalizeMonthSet(
          kpisTotals.users_active_ratio.map((day) => {
            return { value: day };
          })
        ) || [];
      memberBreakdownMonth.datasets[7].data =
        normalizeMonthSet(
          kpisTotals.premium_users_active_ratio.map((day) => {
            return { value: day };
          })
        ) || [];
      memberBreakdownMonth.datasets[8].data =
        normalizeMonthSet(
          kpisTotals.basic_users_active_ratio.map((day) => {
            return { value: day };
          })
        ) || [];

      setMemberBreakdownMonthChartData(memberBreakdownMonth); // memberBreakdownMonthChartData

      // Monthly Member Label Stats
      const memberLabelsMonth = Object.assign({}, MEMBER_LABELS_MONTH);
      memberLabelsMonth.labels = monthLabels;
      memberLabelsMonth.datasets[0].data =
        normalizeMonthSet(
          kpisTotals.label_tenant.map((day) => {
            return { value: day };
          })
        ) || [];
      memberLabelsMonth.datasets[1].data =
        normalizeMonthSet(
          kpisTotals.label_buyer.map((day) => {
            return { value: day };
          })
        ) || [];
      memberLabelsMonth.datasets[2].data =
        normalizeMonthSet(
          kpisTotals.label_vendor.map((day) => {
            return { value: day };
          })
        ) || [];
      memberLabelsMonth.datasets[3].data =
        normalizeMonthSet(
          kpisTotals.label_landlord.map((day) => {
            return { value: day };
          })
        ) || [];
      memberLabelsMonth.datasets[4].data =
        normalizeMonthSet(
          kpisTotals.label_staff.map((day) => {
            return { value: day };
          })
        ) || [];
      memberLabelsMonth.datasets[5].data =
        normalizeMonthSet(
          kpisTotals.label_student.map((day) => {
            return { value: day };
          })
        ) || [];
      // memberLabelsMonth.datasets[5].data      = normalizeMonthSet(kpisCumulative.users_diff.map(day => { return { value: day } })) || []
      memberLabelsMonth.datasets[6].data =
        memberLabelsMonth.datasets[0].data.map((day, index) => {
          let set = [
            memberLabelsMonth.datasets[0].data[index] || undefined,
            memberLabelsMonth.datasets[1].data[index] || undefined,
            memberLabelsMonth.datasets[2].data[index] || undefined,
            memberLabelsMonth.datasets[3].data[index] || undefined,
            memberLabelsMonth.datasets[4].data[index] || undefined,
            memberLabelsMonth.datasets[5].data[index] || undefined,
          ].filter((v) => v !== undefined);
          return getDistPercent(set);
        }) || [];
      setMemberLabelsMonthChartData(memberLabelsMonth); // memberLabelsMonthChartData

      // Monthly Member Engagement Stats
      const memberEngagementMonth = Object.assign({}, MEMBER_ENGAGEMENT_MONTH);
      memberEngagementMonth.labels = monthLabels;

      memberEngagementMonth.datasets[0].data =
        normalizeMonthSet(
          kpisDailyMonthly.sessions.map((day) => {
            return { value: day };
          })
        ) || [];
      memberEngagementMonth.datasets[1].data =
        normalizeMonthSet(
          kpisDailyMonthly.logins.map((day) => {
            return { value: day };
          })
        ) || [];
      memberEngagementMonth.datasets[2].data =
        normalizeMonthSet(
          kpisDailyMonthly.returns.map((day) => {
            return { value: day };
          })
        ) || [];
      memberEngagementMonth.datasets[3].data =
        normalizeMonthSet(
          kpisDailyMonthly.returns_ratio.map((day) => {
            return { value: day };
          })
        ) || [];

      setMemberEngagementMonthChartData(memberEngagementMonth); // memberEngagementMonthChartData

      const member_kpi_data = {
        clients_num: kpisTotals?.clients[kpisTotals?.clients?.length - 1] || 0,
        clients_diff:
          (kpisTotals?.clients[kpisTotals?.clients?.length - 1] || 0) -
            (kpisTotals?.clients[0] || 0) || 0,

        users_added:
          kpisCumulative?.users_added[
            kpisCumulative?.users_added?.length - 1
          ] || 0,
        users_added_ratio:
          Math.floor(
            ((kpisCumulative?.users_added[
              kpisCumulative?.users_added?.length - 1
            ] || 0) /
              (kpisTotals?.users[0] || 0)) *
              10000
          ) / 100 || 0,
        users_removed:
          kpisCumulative?.users_removed[
            kpisCumulative?.users_removed?.length - 1
          ] || 0,
        users_removed_ratio:
          Math.floor(
            ((kpisCumulative?.users_removed[
              kpisCumulative?.users_removed?.length - 1
            ] || 0) /
              (kpisTotals?.users[0] || 0)) *
              10000
          ) / 100 || 0,

        users_num: kpisTotals?.users[kpisTotals?.users?.length - 1] || 0,
        users_diff:
          (kpisTotals?.users[kpisTotals?.users?.length - 1] || 0) -
            (kpisTotals?.users[0] || 0) || 0,
        users_diff_ratio:
          Math.floor(
            ((((kpisTotals?.users[kpisTotals?.users?.length - 1] || 0) -
              (kpisTotals?.users[0] || 0)) /
              (kpisTotals?.users[0] || 0)) *
              10000) /
              100
          ) || 0,
        users_active_num:
          kpisTotals?.users_active[kpisTotals?.users_active?.length - 1] || 0,
        users_active_diff:
          (kpisTotals?.users_active[kpisTotals?.users_active?.length - 1] ||
            0) - (kpisTotals?.users_active[0] || 0) || 0,
        users_active_ratio:
          Math.floor(
            ((kpisTotals?.users_active[kpisTotals?.users_active?.length - 1] ||
              0) /
              (kpisTotals?.users[kpisTotals?.users?.length - 1] || 0)) *
              10000
          ) / 100 || 0,
        users_active_ratio_diff:
          Math.floor(
            ((kpisTotals?.users_active[kpisTotals?.users_active?.length - 1] ||
              0) /
              (kpisTotals?.users[kpisTotals?.users?.length - 1] || 0) -
              ((kpisTotals?.users_active[0] || 0) /
                (kpisTotals?.users[0] || 0) || 0)) *
              10000
          ) / 100 || 0,

        basic_users_num:
          kpisTotals?.basic_users[kpisTotals?.basic_users?.length - 1] || 0,
        basic_users_ratio:
          Math.floor(
            ((kpisTotals?.basic_users[kpisTotals?.basic_users?.length - 1] ||
              0) /
              (kpisTotals?.users[kpisTotals?.users?.length - 1] || 0)) *
              10000
          ) / 100 || 0,
        basic_users_diff:
          (kpisTotals?.basic_users[kpisTotals?.basic_users?.length - 1] || 0) -
            (kpisTotals?.basic_users[0] || 0) || 0,
        basic_users_active_num:
          kpisTotals?.basic_users_active[
            kpisTotals?.basic_users_active?.length - 1
          ] || 0,
        basic_users_active_diff:
          (kpisTotals?.basic_users_active[
            kpisTotals?.basic_users_active?.length - 1
          ] || 0) - (kpisTotals?.basic_users_active[0] || 0) || 0,
        basic_users_active_ratio:
          Math.floor(
            ((kpisTotals?.basic_users_active[
              kpisTotals?.basic_users_active?.length - 1
            ] || 0) /
              (kpisTotals?.basic_users[kpisTotals?.basic_users?.length - 1] ||
                0)) *
              10000
          ) / 100 || 0,
        basic_users_active_ratio_diff:
          Math.floor(
            ((kpisTotals?.basic_users_active[
              kpisTotals?.basic_users_active?.length - 1
            ] || 0) /
              (kpisTotals?.basic_users[kpisTotals?.basic_users?.length - 1] ||
                0) -
              ((kpisTotals?.basic_users_active[0] || 0) /
                (kpisTotals?.basic_users[0] || 0) || 0)) *
              10000
          ) / 100 || 0,

        premium_users_num:
          kpisTotals?.premium_users[kpisTotals?.premium_users?.length - 1] || 0,
        premium_users_ratio:
          Math.floor(
            ((kpisTotals?.premium_users[
              kpisTotals?.premium_users?.length - 1
            ] || 0) /
              (kpisTotals?.users[kpisTotals?.users?.length - 1] || 0)) *
              10000
          ) / 100 || 0,
        premium_users_diff:
          (kpisTotals?.premium_users[kpisTotals?.premium_users?.length - 1] ||
            0) - (kpisTotals?.premium_users[0] || 0) || 0,
        premium_users_active_num:
          kpisTotals?.premium_users_active[
            kpisTotals?.premium_users_active?.length - 1
          ] || 0,
        premium_users_active_diff:
          (kpisTotals?.premium_users_active[
            kpisTotals?.premium_users_active?.length - 1
          ] || 0) - (kpisTotals?.premium_users_active[0] || 0) || 0,
        premium_users_active_ratio:
          Math.floor(
            ((kpisTotals?.premium_users_active[
              kpisTotals?.premium_users_active?.length - 1
            ] || 0) /
              (kpisTotals?.premium_users[
                kpisTotals?.premium_users?.length - 1
              ] || 0)) *
              10000
          ) / 100 || 0,
        premium_users_active_ratio_diff:
          Math.floor(
            ((kpisTotals?.premium_users_active[
              kpisTotals?.premium_users_active?.length - 1
            ] || 0) /
              (kpisTotals?.premium_users[
                kpisTotals?.premium_users?.length - 1
              ] || 0) -
              ((kpisTotals?.premium_users_active[0] || 0) /
                (kpisTotals?.premium_users[0] || 0) || 0)) *
              10000
          ) / 100 || 0,

        month_users_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberBreakdownMonth.datasets[0].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_users_active_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberBreakdownMonth.datasets[5].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_premium_users_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberBreakdownMonth.datasets[1].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_basic_users_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberBreakdownMonth.datasets[2].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_premium_users_active_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberBreakdownMonth.datasets[3].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_basic_users_active_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberBreakdownMonth.datasets[4].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_users_added_avg:
          Math.floor(
            (getSumCount(onlyFullMonths(memberGrowthMonth.datasets[5].data)) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_users_removed_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(
                [...memberGrowthMonth.datasets[6].data].map((d) => Math.abs(d))
              )
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_users_diff_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(
                normalizeMonthSet(
                  kpisDailyMonthly.users_diff.map((day) => {
                    return { value: day };
                  }) || []
                )
              )
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,

        month_tenant_avg:
          Math.floor(
            (getSumCount(onlyFullMonths(memberLabelsMonth.datasets[0].data)) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_landlord_avg:
          Math.floor(
            (getSumCount(onlyFullMonths(memberLabelsMonth.datasets[1].data)) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_buyer_avg:
          Math.floor(
            (getSumCount(onlyFullMonths(memberLabelsMonth.datasets[2].data)) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_vendor_avg:
          Math.floor(
            (getSumCount(onlyFullMonths(memberLabelsMonth.datasets[3].data)) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_staff_avg:
          Math.floor(
            (getSumCount(onlyFullMonths(memberLabelsMonth.datasets[4].data)) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_student_avg:
          Math.floor(
            (getSumCount(onlyFullMonths(memberLabelsMonth.datasets[5].data)) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,

        day_tenant_avg:
          Math.floor(
            (getSumCount(kpisTotals?.label_tenant || []) /
              (kpisTotals.label_tenant.length || 0)) *
              10
          ) / 10 || 0,
        day_landlord_avg:
          Math.floor(
            (getSumCount(kpisTotals?.label_landlord || []) /
              (kpisTotals.label_landlord.length || 0)) *
              10
          ) / 10 || 0,
        day_buyer_avg:
          Math.floor(
            (getSumCount(kpisTotals?.label_buyer || []) /
              (kpisTotals.label_buyer.length || 0)) *
              10
          ) / 10 || 0,
        day_vendor_avg:
          Math.floor(
            (getSumCount(kpisTotals?.label_vendor || []) /
              (kpisTotals.label_vendor.length || 0)) *
              10
          ) / 10 || 0,
        day_staff_avg:
          Math.floor(
            (getSumCount(kpisTotals?.label_staff || []) /
              (kpisTotals.label_staff.length || 0)) *
              10
          ) / 10 || 0,
        day_student_avg:
          Math.floor(
            (getSumCount(kpisTotals?.label_student || []) /
              (kpisTotals.label_student.length || 0)) *
              10
          ) / 10 || 0,

        tenant_num:
          kpisTotals?.label_tenant[kpisTotals?.label_tenant?.length - 1] || 0,
        landlord_num:
          kpisTotals?.label_landlord[kpisTotals?.label_landlord?.length - 1] ||
          0,
        buyer_num:
          kpisTotals?.label_buyer[kpisTotals?.label_buyer?.length - 1] || 0,
        vendor_num:
          kpisTotals?.label_vendor[kpisTotals?.label_vendor?.length - 1] || 0,
        staff_num:
          kpisTotals?.label_staff[kpisTotals?.label_staff?.length - 1] || 0,
        student_num:
          kpisTotals?.label_student[kpisTotals?.label_student?.length - 1] || 0,

        day_users_avg:
          Math.floor(
            (getSumCount(kpisTotals?.users || []) /
              (kpisTotals.users.length || 0)) *
              10
          ) / 10 || 0,
        day_users_active_avg:
          Math.floor(
            (getSumCount(kpisTotals?.users_active || []) /
              (kpisTotals.users_active.length || 0)) *
              10
          ) / 10 || 0,
        day_premium_users_avg:
          Math.floor(
            (getSumCount(kpisTotals?.premium_users || []) /
              (kpisTotals.premium_users.length || 0)) *
              10
          ) / 10 || 0,
        day_basic_users_avg:
          Math.floor(
            (getSumCount(kpisTotals?.basic_users || []) /
              (kpisTotals.basic_users.length || 0)) *
              10
          ) / 10 || 0,
        day_premium_users_active_avg:
          Math.floor(
            (getSumCount(kpisTotals?.premium_users_active || []) /
              (kpisTotals.premium_users_active.length || 0)) *
              10
          ) / 10 || 0,
        day_basic_users_active_avg:
          Math.floor(
            (getSumCount(kpisTotals?.basic_users_active || []) /
              (kpisTotals.basic_users_active.length || 0)) *
              10
          ) / 10 || 0,
        day_users_added_avg:
          Math.floor(
            (getSumCount(kpisDaily?.users_added.map((v) => v.value) || []) /
              (kpisDaily.users_added.map((v) => v.value).length || 0)) *
              10
          ) / 10 || 0,
        day_users_removed_avg:
          Math.floor(
            (getSumCount(kpisDaily?.users_removed.map((v) => v.value) || []) /
              (kpisDaily.users_removed.length || 0)) *
              10
          ) / 10 || 0,

        label_tenant_num:
          kpisTotals?.label_tenant[kpisTotals?.label_tenant?.length - 1] || 0,
        label_tenant_diff:
          (kpisTotals?.label_tenant[kpisTotals?.label_tenant?.length - 1] ||
            0) - (kpisTotals?.label_tenant[0] || 0) || 0,

        label_landlord_num:
          kpisTotals?.label_landlord[kpisTotals?.label_landlord?.length - 1] ||
          0,
        label_landlord_diff:
          (kpisTotals?.label_landlord[kpisTotals?.label_landlord?.length - 1] ||
            0) - (kpisTotals?.label_landlord[0] || 0) || 0,

        label_buyer_num:
          kpisTotals?.label_buyer[kpisTotals?.label_buyer?.length - 1] || 0,
        label_buyer_diff:
          (kpisTotals?.label_buyer[kpisTotals?.label_buyer?.length - 1] || 0) -
            (kpisTotals?.label_buyer[0] || 0) || 0,

        label_vendor_num:
          kpisTotals?.label_vendor[kpisTotals?.label_vendor?.length - 1] || 0,
        label_vendor_diff:
          (kpisTotals?.label_vendor[kpisTotals?.label_vendor?.length - 1] ||
            0) - (kpisTotals?.label_vendor[0] || 0) || 0,

        label_staff_num:
          kpisTotals?.label_staff[kpisTotals?.label_staff?.length - 1] || 0,
        label_staff_diff:
          (kpisTotals?.label_staff[kpisTotals?.label_staff?.length - 1] || 0) -
            (kpisTotals?.label_staff[0] || 0) || 0,

        label_student_num:
          kpisTotals?.label_student[kpisTotals?.label_student?.length - 1] || 0,
        label_student_diff:
          (kpisTotals?.label_student[kpisTotals?.label_student?.length - 1] ||
            0) - (kpisTotals?.label_student[0] || 0) || 0,

        sessions_sum:
          kpisCumulative.sessions[
            (kpisCumulative?.sessions?.length || 1) - 1
          ] || 0,
        logins_sum:
          kpisCumulative.logins[(kpisCumulative?.logins?.length || 1) - 1] || 0,
        returns_sum:
          kpisCumulative.returns[(kpisCumulative?.returns?.length || 1) - 1] ||
          0,
        returns_ratio_sum:
          kpisCumulative.returns_ratio[
            (kpisCumulative?.returns_ratio?.length || 1) - 1
          ] || 0,

        day_sessions_avg:
          (kpisCumulative.sessions[
            (kpisCumulative?.sessions?.length || 1) - 1
          ] || 0) / (kpisCumulative?.sessions?.length || 0),
        day_logins_avg:
          (kpisCumulative.logins[(kpisCumulative?.logins?.length || 1) - 1] ||
            0) / (kpisCumulative?.logins?.length || 0),
        day_returns_avg:
          (kpisCumulative.returns[(kpisCumulative?.returns?.length || 1) - 1] ||
            0) / (kpisCumulative?.returns?.length || 0),
        day_returns_ratio_avg:
          kpisCumulative.returns_ratio[
            (kpisCumulative?.returns_ratio?.length || 1) - 1
          ] || 0,

        month_sessions_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberEngagementMonth.datasets[0].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_logins_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberEngagementMonth.datasets[1].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_returns_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberEngagementMonth.datasets[2].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
        month_returns_ratio_avg:
          Math.floor(
            (getSumCount(
              onlyFullMonths(memberEngagementMonth.datasets[3].data)
            ) /
              Math.max(1, onlyFullMonths(monthLabels).length)) *
              10
          ) / 10 || 0,
      };

      member_kpi_data.month_users_active_ratio_avg =
        Math.floor(
          ((member_kpi_data?.month_users_active_avg || 0) /
            (member_kpi_data?.month_users_avg || 0)) *
            10000
        ) / 100 || 0;
      member_kpi_data.month_basic_users_active_ratio_avg =
        Math.floor(
          ((member_kpi_data?.month_basic_users_active_avg || 0) /
            (member_kpi_data?.month_basic_users_avg || 0)) *
            10000
        ) / 100 || 0;
      member_kpi_data.month_premium_users_active_ratio_avg =
        Math.floor(
          ((member_kpi_data?.month_premium_users_active_avg || 0) /
            (member_kpi_data?.month_premium_users_avg || 0)) *
            10000
        ) / 100 || 0;

      member_kpi_data.user_diffs = [...monthLabels].map((month, index) => {
        if (index < 1)
          return (
            (memberBreakdownMonth?.datasets[0]?.data[0] || 0) -
            (kpisTotals?.users[0] || 0) +
            (kpisDaily?.users_diff[0]?.value || 0)
          );
        else
          return (
            (memberBreakdownMonth?.datasets[0]?.data[index] || 0) -
            (memberBreakdownMonth?.datasets[0]?.data[Math.max(index - 1, 0)] ||
              0)
          );
      });
      member_kpi_data.user_active_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberBreakdownMonth?.datasets[5]?.data[0] || 0) -
              (kpisTotals?.users_active[0] || 0)
            );
          else
            return (
              (memberBreakdownMonth?.datasets[5]?.data[index] || 0) -
              (memberBreakdownMonth?.datasets[5]?.data[
                Math.max(index - 1, 0)
              ] || 0)
            );
        }
      );
      member_kpi_data.user_active_ratio_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberBreakdownMonth?.datasets[6]?.data[0] || 0) -
              (kpisTotals?.users_active_ratio[0] || 0)
            );
          else
            return (
              (memberBreakdownMonth?.datasets[6]?.data[index] || 0) -
              (memberBreakdownMonth?.datasets[6]?.data[
                Math.max(index - 1, 0)
              ] || 0)
            );
        }
      );
      member_kpi_data.user_added_diffs = [...monthLabels].map(
        (month, index) =>
          (memberGrowthMonth?.datasets[5]?.data[index] || 0) -
          (memberGrowthMonth?.datasets[5]?.data[Math.max(index - 1, 0)] || 0)
      );
      member_kpi_data.user_removed_diffs = [...monthLabels].map(
        (month, index) =>
          (memberGrowthMonth?.datasets[6]?.data[index] || 0) -
          (memberGrowthMonth?.datasets[6]?.data[Math.max(index - 1, 0)] || 0)
      );
      member_kpi_data.user_diff_diffs = [...monthLabels].map(
        (month, index) =>
          (memberGrowthMonth?.datasets[5]?.data[index] || 0) +
          (memberGrowthMonth?.datasets[6]?.data[index] || 0) -
          ((memberGrowthMonth?.datasets[5]?.data[Math.max(index - 1, 0)] || 0) +
            (memberGrowthMonth?.datasets[6]?.data[Math.max(index - 1, 0)] || 0))
      );
      member_kpi_data.premium_user_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberBreakdownMonth?.datasets[1]?.data[0] || 0) -
              (kpisTotals?.premium_users[0] || 0)
            );
          else
            return (
              (memberBreakdownMonth?.datasets[1]?.data[index] || 0) -
              (memberBreakdownMonth?.datasets[1]?.data[
                Math.max(index - 1, 0)
              ] || 0)
            );
        }
      );
      member_kpi_data.premium_user_active_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberBreakdownMonth?.datasets[3]?.data[0] || 0) -
              (kpisTotals?.premium_users_active[0] || 0)
            );
          else
            return (
              (memberBreakdownMonth?.datasets[3]?.data[index] || 0) -
              (memberBreakdownMonth?.datasets[3]?.data[
                Math.max(index - 1, 0)
              ] || 0)
            );
        }
      );
      member_kpi_data.basic_user_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberBreakdownMonth?.datasets[2]?.data[0] || 0) -
              (kpisTotals?.basic_users[0] || 0)
            );
          else
            return (
              (memberBreakdownMonth?.datasets[2]?.data[index] || 0) -
              (memberBreakdownMonth?.datasets[2]?.data[
                Math.max(index - 1, 0)
              ] || 0)
            );
        }
      );
      member_kpi_data.basic_user_active_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberBreakdownMonth?.datasets[4]?.data[0] || 0) -
              (kpisTotals?.basic_users_active[0] || 0)
            );
          else
            return (
              (memberBreakdownMonth?.datasets[4]?.data[index] || 0) -
              (memberBreakdownMonth?.datasets[4]?.data[
                Math.max(index - 1, 0)
              ] || 0)
            );
        }
      );
      member_kpi_data.tenant_user_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberLabelsMonth?.datasets[0]?.data[0] || 0) -
              (kpisTotals?.label_tenant[0] || 0)
            );
          else
            return (
              (memberLabelsMonth?.datasets[0]?.data[index] || 0) -
              (memberLabelsMonth?.datasets[0]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        }
      );
      member_kpi_data.landlord_user_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberLabelsMonth?.datasets[1]?.data[0] || 0) -
              (kpisTotals?.label_landlord[0] || 0)
            );
          else
            return (
              (memberLabelsMonth?.datasets[1]?.data[index] || 0) -
              (memberLabelsMonth?.datasets[1]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        }
      );
      member_kpi_data.buyer_user_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberLabelsMonth?.datasets[2]?.data[0] || 0) -
              (kpisTotals?.label_buyer[0] || 0)
            );
          else
            return (
              (memberLabelsMonth?.datasets[2]?.data[index] || 0) -
              (memberLabelsMonth?.datasets[2]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        }
      );
      member_kpi_data.vendor_user_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberLabelsMonth?.datasets[3]?.data[0] || 0) -
              (kpisTotals?.label_vendor[0] || 0)
            );
          else
            return (
              (memberLabelsMonth?.datasets[3]?.data[index] || 0) -
              (memberLabelsMonth?.datasets[3]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        }
      );
      member_kpi_data.staff_user_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberLabelsMonth?.datasets[4]?.data[0] || 0) -
              (kpisTotals?.label_staff[0] || 0)
            );
          else
            return (
              (memberLabelsMonth?.datasets[4]?.data[index] || 0) -
              (memberLabelsMonth?.datasets[4]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        }
      );
      member_kpi_data.student_user_diffs = [...monthLabels].map(
        (month, index) => {
          if (index < 1)
            return (
              (memberLabelsMonth?.datasets[5]?.data[0] || 0) -
              (kpisTotals?.label_student[0] || 0)
            );
          else
            return (
              (memberLabelsMonth?.datasets[5]?.data[index] || 0) -
              (memberLabelsMonth?.datasets[5]?.data[Math.max(index - 1, 0)] ||
                0)
            );
        }
      );

      member_kpi_data.user_month_top = getValueTop(
        memberBreakdownMonth.datasets[0].data || []
      );
      member_kpi_data.user_month_bottom = getValueBottom(
        memberBreakdownMonth.datasets[0].data || []
      );
      member_kpi_data.user_month_best = getValueTop(
        member_kpi_data?.user_diffs || []
      );
      member_kpi_data.user_month_worst = getValueBottom(
        member_kpi_data?.user_diffs || []
      );
      member_kpi_data.user_month_var =
        Math.floor(
          ((member_kpi_data?.users_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.user_active_month_top = getValueTop(
        memberBreakdownMonth.datasets[5].data || []
      );
      member_kpi_data.user_active_month_bottom = getValueBottom(
        memberBreakdownMonth.datasets[5].data || []
      );
      member_kpi_data.user_active_month_best = getValueTop(
        member_kpi_data?.user_active_diffs || []
      );
      member_kpi_data.user_active_month_worst = getValueBottom(
        member_kpi_data?.user_active_diffs || []
      );
      member_kpi_data.user_active_month_var =
        Math.floor(
          ((member_kpi_data?.users_active_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.user_active_ratio_month_top = getValueTop(
        memberBreakdownMonth.datasets[6].data || []
      );
      member_kpi_data.user_active_ratio_month_bottom = getValueBottom(
        memberBreakdownMonth.datasets[6].data || []
      );
      member_kpi_data.user_active_ratio_month_best = getValueTop(
        member_kpi_data?.user_active_diffs || []
      );
      member_kpi_data.user_active_ratio_month_worst = getValueBottom(
        member_kpi_data?.user_active_ratio_diffs || []
      );
      member_kpi_data.user_active_ratio_month_var =
        Math.floor(
          ((member_kpi_data?.users_active_ratio_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.user_added_month_top = getValueTop(
        memberGrowthMonth.datasets[5].data || []
      );
      member_kpi_data.user_added_month_bottom = getValueBottom(
        memberGrowthMonth.datasets[5].data || []
      );
      member_kpi_data.user_added_month_best = getValueTop(
        member_kpi_data?.user_added_diffs || []
      );
      member_kpi_data.user_added_month_worst = getValueBottom(
        member_kpi_data?.user_added_diffs || []
      );
      member_kpi_data.users_added_diff = getSumCount(
        onlyFullMonths(member_kpi_data?.user_added_diffs || [])
      );
      member_kpi_data.user_added_month_var =
        Math.floor(
          (member_kpi_data.users_added_diff /
            Math.max(1, onlyFullMonths(monthLabels).length - 1)) *
            10
        ) / 10 || 0;

      member_kpi_data.user_removed_month_top = getValueTop(
        memberGrowthMonth.datasets[6].data || []
      );
      member_kpi_data.user_removed_month_bottom = getValueBottom(
        memberGrowthMonth.datasets[6].data || []
      );
      member_kpi_data.user_removed_month_best = getValueTop(
        member_kpi_data?.user_removed_diffs || []
      );
      member_kpi_data.user_removed_month_worst = getValueBottom(
        member_kpi_data?.user_removed_diffs || []
      );
      member_kpi_data.users_removed_diff = getSumCount(
        onlyFullMonths(member_kpi_data?.user_removed_diffs || [])
      );
      member_kpi_data.user_removed_month_var =
        Math.floor(
          (member_kpi_data.users_removed_diff /
            Math.max(1, onlyFullMonths(monthLabels).length - 1)) *
            10
        ) / 10 || 0;

      member_kpi_data.user_diff_month_top = getValueTop(
        memberGrowthMonth.datasets[7].data || []
      );
      member_kpi_data.user_diff_month_bottom = getValueBottom(
        memberGrowthMonth.datasets[7].data || []
      );
      member_kpi_data.user_diff_month_best = getValueTop(
        member_kpi_data?.user_diff_diffs || []
      );
      member_kpi_data.user_diff_month_worst = getValueBottom(
        member_kpi_data?.user_diff_diffs || []
      );
      member_kpi_data.users_diff_diff = getSumCount(
        onlyFullMonths(member_kpi_data?.user_diff_diffs || [])
      );
      member_kpi_data.user_diff_month_var =
        Math.floor(
          (member_kpi_data.users_diff_diff /
            Math.max(1, onlyFullMonths(monthLabels).length - 1)) *
            10
        ) / 10 || 0;

      member_kpi_data.premium_user_month_top = getValueTop(
        memberBreakdownMonth.datasets[1].data || []
      );
      member_kpi_data.premium_user_month_bottom = getValueBottom(
        memberBreakdownMonth.datasets[1].data || []
      );
      member_kpi_data.premium_user_month_best = getValueTop(
        member_kpi_data?.premium_user_diffs || []
      );
      member_kpi_data.premium_user_month_worst = getValueBottom(
        member_kpi_data?.premium_user_diffs || []
      );
      member_kpi_data.premium_user_month_var =
        Math.floor(
          ((member_kpi_data?.premium_users_diff || 0) /
            Math.max(1, (monthLabels?.length || 0) - 1)) *
            100
        ) / 100;

      member_kpi_data.premium_user_active_month_top = getValueTop(
        memberBreakdownMonth.datasets[3].data || []
      );
      member_kpi_data.premium_user_active_month_bottom = getValueBottom(
        memberBreakdownMonth.datasets[3].data || []
      );
      member_kpi_data.premium_user_active_month_best = getValueTop(
        member_kpi_data?.premium_user_active_diffs || []
      );
      member_kpi_data.premium_user_active_month_worst = getValueBottom(
        member_kpi_data?.premium_user_active_diffs || []
      );
      member_kpi_data.premium_user_active_month_var =
        Math.floor(
          ((member_kpi_data?.premium_users_active_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.premium_user_active_ratio_month_top = getValueTop(
        memberBreakdownMonth.datasets[7].data || []
      );
      member_kpi_data.premium_user_active_ratio_month_bottom = getValueBottom(
        memberBreakdownMonth.datasets[7].data || []
      );
      member_kpi_data.premium_user_active_ratio_month_best = getValueTop(
        member_kpi_data?.premium_user_active_diffs || []
      );
      member_kpi_data.premium_user_active_ratio_month_worst = getValueBottom(
        member_kpi_data?.premium_user_active_ratio_diffs || []
      );
      member_kpi_data.premium_user_active_ratio_month_var =
        Math.floor(
          ((member_kpi_data?.premium_users_active_ratio_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.basic_user_month_top = getValueTop(
        memberBreakdownMonth.datasets[2].data || []
      );
      member_kpi_data.basic_user_month_bottom = getValueBottom(
        memberBreakdownMonth.datasets[2].data || []
      );
      member_kpi_data.basic_user_month_best = getValueTop(
        member_kpi_data?.basic_user_diffs || []
      );
      member_kpi_data.basic_user_month_worst = getValueBottom(
        member_kpi_data?.basic_user_diffs || []
      );
      member_kpi_data.basic_user_month_var =
        Math.floor(
          ((member_kpi_data?.basic_users_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.basic_user_active_month_top = getValueTop(
        memberBreakdownMonth.datasets[4].data || []
      );
      member_kpi_data.basic_user_active_month_bottom = getValueBottom(
        memberBreakdownMonth.datasets[4].data || []
      );
      member_kpi_data.basic_user_active_month_best = getValueTop(
        member_kpi_data?.basic_user_active_diffs || []
      );
      member_kpi_data.basic_user_active_month_worst = getValueBottom(
        member_kpi_data?.basic_user_active_diffs || []
      );
      member_kpi_data.basic_user_active_month_var =
        Math.floor(
          ((member_kpi_data?.basic_users_active_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.basic_user_active_ratio_month_top = getValueTop(
        memberBreakdownMonth.datasets[8].data || []
      );
      member_kpi_data.basic_user_active_ratio_month_bottom = getValueBottom(
        memberBreakdownMonth.datasets[8].data || []
      );
      member_kpi_data.basic_user_active_ratio_month_best = getValueTop(
        member_kpi_data?.basic_user_active_diffs || []
      );
      member_kpi_data.basic_user_active_ratio_month_worst = getValueBottom(
        member_kpi_data?.basic_user_active_ratio_diffs || []
      );
      member_kpi_data.basic_user_active_ratio_month_var =
        Math.floor(
          ((member_kpi_data?.basic_users_active_ratio_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.tenant_user_month_top = getValueTop(
        memberLabelsMonth.datasets[0].data || []
      );
      member_kpi_data.tenant_user_month_bottom = getValueBottom(
        memberLabelsMonth.datasets[0].data || []
      );
      member_kpi_data.tenant_user_month_best = getValueTop(
        member_kpi_data?.tenant_user_diffs || []
      );
      member_kpi_data.tenant_user_month_worst = getValueBottom(
        member_kpi_data?.tenant_user_diffs || []
      );
      member_kpi_data.tenant_user_month_var =
        Math.floor(
          ((member_kpi_data?.label_tenant_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.landlord_user_month_top = getValueTop(
        memberLabelsMonth.datasets[1].data || []
      );
      member_kpi_data.landlord_user_month_bottom = getValueBottom(
        memberLabelsMonth.datasets[1].data || []
      );
      member_kpi_data.landlord_user_month_best = getValueTop(
        member_kpi_data?.landlord_user_diffs || []
      );
      member_kpi_data.landlord_user_month_worst = getValueBottom(
        member_kpi_data?.landlord_user_diffs || []
      );
      member_kpi_data.landlord_user_month_var =
        Math.floor(
          ((member_kpi_data?.label_landlord_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.buyer_user_month_top = getValueTop(
        memberLabelsMonth.datasets[2].data || []
      );
      member_kpi_data.buyer_user_month_bottom = getValueBottom(
        memberLabelsMonth.datasets[2].data || []
      );
      member_kpi_data.buyer_user_month_best = getValueTop(
        member_kpi_data?.buyer_user_diffs || []
      );
      member_kpi_data.buyer_user_month_worst = getValueBottom(
        member_kpi_data?.buyer_user_diffs || []
      );
      member_kpi_data.buyer_user_month_var =
        Math.floor(
          ((member_kpi_data?.label_buyer_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.vendor_user_month_top = getValueTop(
        memberLabelsMonth.datasets[3].data || []
      );
      member_kpi_data.vendor_user_month_bottom = getValueBottom(
        memberLabelsMonth.datasets[3].data || []
      );
      member_kpi_data.vendor_user_month_best = getValueTop(
        member_kpi_data?.vendor_user_diffs || []
      );
      member_kpi_data.vendor_user_month_worst = getValueBottom(
        member_kpi_data?.vendor_user_diffs || []
      );
      member_kpi_data.vendor_user_month_var =
        Math.floor(
          ((member_kpi_data?.label_vendor_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.staff_user_month_top = getValueTop(
        memberLabelsMonth.datasets[4].data || []
      );
      member_kpi_data.staff_user_month_bottom = getValueBottom(
        memberLabelsMonth.datasets[4].data || []
      );
      member_kpi_data.staff_user_month_best = getValueTop(
        member_kpi_data?.staff_user_diffs || []
      );
      member_kpi_data.staff_user_month_worst = getValueBottom(
        member_kpi_data?.staff_user_diffs || []
      );
      member_kpi_data.staff_user_month_var =
        Math.floor(
          ((member_kpi_data?.label_staff_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;
      member_kpi_data.student_user_month_top = getValueTop(
        memberLabelsMonth.datasets[5].data || []
      );
      member_kpi_data.student_user_month_bottom = getValueBottom(
        memberLabelsMonth.datasets[5].data || []
      );
      member_kpi_data.student_user_month_best = getValueTop(
        member_kpi_data?.student_user_diffs || []
      );
      member_kpi_data.student_user_month_worst = getValueBottom(
        member_kpi_data?.student_user_diffs || []
      );
      member_kpi_data.student_user_month_var =
        Math.floor(
          ((member_kpi_data?.label_student_diff || 0) /
            Math.max(1, monthLabels?.length || 0)) *
            100
        ) / 100;

      member_kpi_data.sessions_diffs = [...monthLabels].map(
        (month, index) =>
          (memberEngagementMonth?.datasets[0]?.data[index] || 0) -
          (memberEngagementMonth?.datasets[0]?.data[Math.max(index - 1, 0)] ||
            0)
      );
      member_kpi_data.logins_diffs = [...monthLabels].map(
        (month, index) =>
          (memberEngagementMonth?.datasets[1]?.data[index] || 0) -
          (memberEngagementMonth?.datasets[1]?.data[Math.max(index - 1, 0)] ||
            0)
      );
      member_kpi_data.returns_diffs = [...monthLabels].map(
        (month, index) =>
          (memberEngagementMonth?.datasets[2]?.data[index] || 0) -
          (memberEngagementMonth?.datasets[2]?.data[Math.max(index - 1, 0)] ||
            0)
      );
      member_kpi_data.returns_ratio_diffs = [...monthLabels].map(
        (month, index) =>
          (memberEngagementMonth?.datasets[3]?.data[index] || 0) -
          (memberEngagementMonth?.datasets[3]?.data[Math.max(index - 1, 0)] ||
            0)
      );

      member_kpi_data.sessions_month_top = getValueTop(
        memberEngagementMonth.datasets[0].data || []
      );
      member_kpi_data.sessions_month_bottom = getValueBottom(
        memberEngagementMonth.datasets[0].data || []
      );
      member_kpi_data.sessions_month_best = getValueTop(
        member_kpi_data?.sessions_diffs || []
      );
      member_kpi_data.sessions_month_worst = getValueBottom(
        member_kpi_data?.sessions_diffs || []
      );
      member_kpi_data.sessions_diff = getSumCount(
        onlyFullMonths(member_kpi_data?.sessions_diffs || [])
      );
      member_kpi_data.sessions_month_var =
        Math.floor(
          (member_kpi_data.sessions_diff /
            Math.max(1, onlyFullMonths(monthLabels).length - 1)) *
            10
        ) / 10 || 0;

      member_kpi_data.logins_month_top = getValueTop(
        memberEngagementMonth.datasets[1].data || []
      );
      member_kpi_data.logins_month_bottom = getValueBottom(
        memberEngagementMonth.datasets[1].data || []
      );
      member_kpi_data.logins_month_best = getValueTop(
        member_kpi_data?.logins_diffs || []
      );
      member_kpi_data.logins_month_worst = getValueBottom(
        member_kpi_data?.logins_diffs || []
      );
      member_kpi_data.logins_diff = getSumCount(
        onlyFullMonths(member_kpi_data?.logins_diffs || [])
      );
      member_kpi_data.logins_month_var =
        Math.floor(
          (member_kpi_data.logins_diff /
            Math.max(1, onlyFullMonths(monthLabels).length - 1)) *
            10
        ) / 10 || 0;

      member_kpi_data.returns_month_top = getValueTop(
        memberEngagementMonth.datasets[2].data || []
      );
      member_kpi_data.returns_month_bottom = getValueBottom(
        memberEngagementMonth.datasets[2].data || []
      );
      member_kpi_data.returns_month_best = getValueTop(
        member_kpi_data?.returns_diffs || []
      );
      member_kpi_data.returns_month_worst = getValueBottom(
        member_kpi_data?.returns_diffs || []
      );
      member_kpi_data.returns_diff = getSumCount(
        onlyFullMonths(member_kpi_data?.returns_diffs || [])
      );
      member_kpi_data.returns_month_var =
        Math.floor(
          (member_kpi_data.returns_diff /
            Math.max(1, onlyFullMonths(monthLabels).length - 1)) *
            10
        ) / 10 || 0;

      member_kpi_data.returns_ratio_month_top = getValueTop(
        memberEngagementMonth.datasets[3].data || []
      );
      member_kpi_data.returns_ratio_month_bottom = getValueBottom(
        memberEngagementMonth.datasets[3].data || []
      );
      member_kpi_data.returns_ratio_month_best = getValueTop(
        member_kpi_data?.returns_ratio_diffs || []
      );
      member_kpi_data.returns_ratio_month_worst = getValueBottom(
        member_kpi_data?.returns_ratio_diffs || []
      );
      member_kpi_data.returns_ratio_diff = getSumCount(
        onlyFullMonths(member_kpi_data?.returns_ratio_diffs || [])
      );
      member_kpi_data.returns_ratio_month_var =
        Math.floor(
          (member_kpi_data.returns_ratio_diff /
            Math.max(1, onlyFullMonths(monthLabels).length - 1)) *
            10
        ) / 10 || 0;

      setMemberKpiData(member_kpi_data);

      // Account Type Stats
      const newAccountTypeStatsData = Object.assign(
        {},
        ACCOUNT_TYPE_STATS_REPORT_DRAFT
      );
      newAccountTypeStatsData.labels = globalLabels.map((r) => r.date);
      newAccountTypeStatsData.datasets[0].data = generateDays(
        d.primaryKPIReport.basic_users,
        [...globalLabels]
      ).map((r) => r.value);
      newAccountTypeStatsData.datasets[1].data = generateDays(
        d.primaryKPIReport.basic_users_active,
        [...globalLabels]
      ).map((r) => r.value);
      newAccountTypeStatsData.datasets[2].data = generateDays(
        d.primaryKPIReport.premium_users,
        [...globalLabels]
      ).map((r) => r.value);
      newAccountTypeStatsData.datasets[3].data = generateDays(
        d.primaryKPIReport.premium_users_active,
        [...globalLabels]
      ).map((r) => r.value);
      setAccountTypeStatsChartData(newAccountTypeStatsData);

      // Upgrade Stats
      const newUpgradeStatsData = Object.assign({}, UPGRADE_STATS_REPORT_DRAFT);
      newUpgradeStatsData.labels = globalLabels.map((r) => r.date);
      newUpgradeStatsData.datasets[0].data = generateDays(
        d.primaryKPIReport.total_upgraded_users,
        [...globalLabels]
      ).map((r) => r.value);
      newUpgradeStatsData.datasets[1].data = generateDays(
        d.primaryKPIReport.total_downgraded_users,
        [...globalLabels]
      ).map((r) => r.value);
      newUpgradeStatsData.datasets[2].data = generateDays(
        d.primaryKPIReport.current_upgraded_users,
        [...globalLabels]
      ).map((r) => r.value);
      setUpgradeStatsChartData(newUpgradeStatsData);

      // User Activity Stats
      const newUserActivityStatsData = Object.assign(
        {},
        USER_ACTIVITY_REPORT_DRAFT
      );
      newUserActivityStatsData.labels = globalLabels.map((r) => r.date);
      newUserActivityStatsData.datasets[0].data = generateDays(
        d.primaryKPIReport.user_logins_today,
        [...globalLabels]
      ).map((r) => r.value);
      newUserActivityStatsData.datasets[1].data = generateDays(
        d.primaryKPIReport.user_logins_this_month,
        [...globalLabels]
      ).map((r) => r.value);
      newUserActivityStatsData.datasets[2].data = generateDays(
        d.primaryKPIReport.user_sessions_today,
        [...globalLabels]
      ).map((r) => r.value);
      setUserActivityStatsChartData(newUserActivityStatsData);

      // Created Method Stats
      const newCreatedMethodStatsData = Object.assign(
        {},
        CREATED_METHOD_REPORT_DRAFT
      );
      newCreatedMethodStatsData.labels = globalLabels.map((r) => r.date);
      newCreatedMethodStatsData.datasets[0].data = generateDays(
        d.primaryKPIReport.created_methods_today.map((r) => {
          return { date: r.date, value: r.client_api };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      newCreatedMethodStatsData.datasets[1].data = generateDays(
        d.primaryKPIReport.created_methods_today.map((r) => {
          return { date: r.date, value: r.client_dashboard };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      newCreatedMethodStatsData.datasets[2].data = generateDays(
        d.primaryKPIReport.created_methods_today.map((r) => {
          return { date: r.date, value: r.control };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      newCreatedMethodStatsData.datasets[3].data = generateDays(
        d.primaryKPIReport.created_methods_today.map((r) => {
          return { date: r.date, value: r.control_csv };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      newCreatedMethodStatsData.datasets[4].data = generateDays(
        d.primaryKPIReport.created_methods_today.map((r) => {
          return { date: r.date, value: r.propco_api };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      setCreatedMethodChartData(newCreatedMethodStatsData);

      // Labels Stats
      const newLabelsStatsData = Object.assign({}, LABELS_REPORT_DRAFT);
      newLabelsStatsData.labels = globalLabels.map((r) => r.date);
      newLabelsStatsData.datasets[0].data = generateDays(
        d.primaryKPIReport.label_daily_totals.map((r) => {
          return { date: r.date, value: r.tenant };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      newLabelsStatsData.datasets[1].data = generateDays(
        d.primaryKPIReport.label_daily_totals.map((r) => {
          return { date: r.date, value: r.landlord };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      newLabelsStatsData.datasets[2].data = generateDays(
        d.primaryKPIReport.label_daily_totals.map((r) => {
          return { date: r.date, value: r.buyer };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      newLabelsStatsData.datasets[3].data = generateDays(
        d.primaryKPIReport.label_daily_totals.map((r) => {
          return { date: r.date, value: r.vendor };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      newLabelsStatsData.datasets[4].data = generateDays(
        d.primaryKPIReport.label_daily_totals.map((r) => {
          return { date: r.date, value: r.staff };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      newLabelsStatsData.datasets[5].data = generateDays(
        d.primaryKPIReport.label_daily_totals.map((r) => {
          return { date: r.date, value: r.student };
        }),
        [...globalLabels]
      ).map((v) => v.value);
      setLabelsChartData(newLabelsStatsData);

      setStats(d.primaryKPIReport.stats);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  if (stats.loading || !mainStatsChartData) return <PageSpinner clear />;

  return (
    <React.Fragment>
      <div className="inline-flex ml-auto self-right w-max select-none">
        <SimpleDateRangeInput
          startDate={startDate}
          endDate={endDate}
          onApplyRange={handleDateRangeChange}
          min="2019-01-01"
          max={moment().subtract(1, "days").format("YYYY-MM-DD")}
          loading={loading}
          type="month"
        />
      </div>

      {invoiceKpiData && (
        <Row tweaks="w-full text-left gap-y-[2px] pt-6">
          <Col width={5}>
            <div className="mb-[12px] text-slate-500 text-[16px] inline-flex justify-between w-full">
              <button
                onClick={() => setVatIncluded(!vatIncluded)}
                className={`
                  text-[10px] text-white text-center
                  py-0.5 px-2 
                  focus:outline-none 
                  w-[84px]
                  ${vatIncluded ? "bg-emerald-500" : "bg-rose-500"}
                `}
              >
                {vatIncluded ? "VAT included" : "VAT excluded"}
              </button>
            </div>
            <GrowthStatBlocksContainer blocks={3} gap>
              <div className="pl-2 mb-[6px] text-slate-500 text-[12px]">
                In range totals
              </div>
              <div className="pl-2 mb-[6px] text-slate-500 text-[12px]">
                EOM averages
              </div>
              <div className="pl-2 mb-[6px] text-slate-500 text-[12px]">
                Daily averages
              </div>
            </GrowthStatBlocksContainer>
            <Centrify>
              <GrowthStatBlocksContainer blocks={3} gap>
                <GrowthStatBlock
                  description="Invoices"
                  value={vatSwitch(invoiceKpiData?.bill_sum || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.bill_num || 0}
                  percentAbbr="Number of invoices"
                  growth={invoiceKpiData?.bill_avg || 0}
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-x-slate-400 border-t-slate-400 mb-px"
                />
                <GrowthStatBlock
                  description="Invoices"
                  value={vatSwitch(invoiceKpiData?.bill_month_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.bill_month_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.bill_month_avg /
                        invoiceKpiData?.bill_month_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-x-slate-400 border-t-slate-400 mb-px"
                />
                <GrowthStatBlock
                  description="Invoices"
                  value={vatSwitch(invoiceKpiData?.bill_day_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.bill_day_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={invoiceKpiData?.bill_avg || 0}
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-x-slate-400 border-t-slate-400 mb-px"
                />

                <GrowthStatBlock
                  description="Paid"
                  value={vatSwitch(invoiceKpiData?.paid_sum || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.paid_num || 0}
                  percentAbbr="Number of invoices"
                  growth={invoiceKpiData?.paid_avg || 0}
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-x-slate-400 border-b-slate-400 mb-px"
                />
                <GrowthStatBlock
                  description="Paid"
                  value={vatSwitch(invoiceKpiData?.paid_month_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.paid_month_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.paid_month_avg /
                        invoiceKpiData?.paid_month_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-x-slate-400 border-b-slate-400 mb-px"
                />
                <GrowthStatBlock
                  description="Paid"
                  value={vatSwitch(invoiceKpiData?.paid_day_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.paid_day_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={invoiceKpiData?.paid_avg || 0}
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-x-slate-400 border-b-slate-400 mb-px"
                />

                <GrowthStatBlock
                  description="Unpaid"
                  value={vatSwitch(invoiceKpiData?.unpaid_sum || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.unpaid_num || 0}
                  percentAbbr="Number of invoices"
                  growth={invoiceKpiData?.unpaid_avg || 0}
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />
                <GrowthStatBlock
                  description="Unpaid"
                  value={vatSwitch(invoiceKpiData?.unpaid_month_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.unpaid_month_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.unpaid_month_avg /
                        invoiceKpiData?.unpaid_month_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />
                <GrowthStatBlock
                  description="Unpaid"
                  value={vatSwitch(invoiceKpiData?.unpaid_day_sum || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.unpaid_day_num || 0}
                  percentAbbr="Number of invoices"
                  growth={invoiceKpiData?.unpaid_avg || 0}
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />

                <GrowthStatBlock
                  description="Pending state"
                  value={vatSwitch(invoiceKpiData?.wait_total_sum || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.wait_total_num || 0}
                  percentAbbr="Number of invoices"
                  growth={invoiceKpiData?.wait_total_avg || 0}
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />
                <GrowthStatBlock
                  description="Pending state"
                  value={vatSwitch(invoiceKpiData?.wait_month_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.wait_month_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.wait_month_avg /
                        invoiceKpiData?.wait_month_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />
                <GrowthStatBlock
                  description="Pending state"
                  value={vatSwitch(invoiceKpiData?.wait_day_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.wait_day_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.wait_day_avg /
                        invoiceKpiData?.wait_day_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />

                <GrowthStatBlock
                  description="Failed state"
                  value={vatSwitch(invoiceKpiData?.fail_total_sum || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.fail_total_num || 0}
                  percentAbbr="Number of invoices"
                  growth={invoiceKpiData?.fail_total_avg || 0}
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-b-gray-300 mb-px"
                />
                <GrowthStatBlock
                  description="Failed state"
                  value={vatSwitch(invoiceKpiData?.fail_month_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.fail_month_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.fail_month_avg /
                        invoiceKpiData?.fail_month_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-b-gray-300 mb-px"
                />
                <GrowthStatBlock
                  description="Failed state"
                  value={vatSwitch(invoiceKpiData?.fail_day_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.fail_day_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.fail_day_avg /
                        invoiceKpiData?.fail_day_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-b-gray-300 mb-px"
                />

                <GrowthStatBlock
                  description="Debt records"
                  value={vatSwitch(invoiceKpiData?.debt_sum || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.debt_num || 0}
                  percentAbbr="Number of invoices"
                  growth={invoiceKpiData?.debt_avg || 0}
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-slate-400 mb-px"
                />
                <GrowthStatBlock
                  description="Debt records"
                  value={vatSwitch(invoiceKpiData?.debt_month_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.debt_month_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.debt_month_avg /
                        invoiceKpiData?.debt_month_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-slate-400 mb-px"
                />
                <GrowthStatBlock
                  description="Debt records"
                  value={vatSwitch(invoiceKpiData?.debt_day_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.debt_day_avg_num || 0}
                  percentAbbr="Number of invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.debt_day_avg /
                        invoiceKpiData?.debt_day_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                  tweaks="border-slate-400 mb-px"
                />

                <GrowthStatBlock
                  description="Stripe"
                  value={vatSwitch(invoiceKpiData?.bill_sum_stripe || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.bill_num_stripe || 0}
                  percentAbbr="Number of Stripe invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.bill_sum_stripe /
                        invoiceKpiData?.bill_num_stripe) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />
                <GrowthStatBlock
                  description="Stripe"
                  value={vatSwitch(invoiceKpiData?.bill_month_stripe_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.bill_month_stripe_avg_num || 0}
                  percentAbbr="Number of Stripe invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.bill_month_stripe_avg /
                        invoiceKpiData?.bill_month_stripe_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />
                <GrowthStatBlock
                  description="Stripe"
                  value={vatSwitch(invoiceKpiData?.bill_day_stripe_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.bill_day_stripe_avg_num || 0}
                  percentAbbr="Number of Stripe invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.bill_day_stripe_avg /
                        invoiceKpiData?.bill_day_stripe_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />

                <GrowthStatBlock
                  description="GoCardless"
                  value={vatSwitch(invoiceKpiData?.bill_sum_gocard || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.bill_num_gocard || 0}
                  percentAbbr="Number of GoCardless invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.bill_sum_gocard /
                        invoiceKpiData?.bill_num_gocard) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />
                <GrowthStatBlock
                  description="GoCardless"
                  value={vatSwitch(invoiceKpiData?.bill_month_gocard_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.bill_month_gocard_avg_num || 0}
                  percentAbbr="Number of GoCardless invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.bill_month_gocard_avg /
                        invoiceKpiData?.bill_month_gocard_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />
                <GrowthStatBlock
                  description="GoCardless"
                  value={vatSwitch(invoiceKpiData?.bill_day_gocard_avg || 0)}
                  valueFormatter={currencyNumber}
                  percent={invoiceKpiData?.bill_day_gocard_avg_num || 0}
                  percentAbbr="Number of GoCardless invoices"
                  growth={
                    Math.floor(
                      (invoiceKpiData?.bill_day_gocard_avg /
                        invoiceKpiData?.bill_day_gocard_avg_num) *
                        10
                    ) / 10
                  }
                  growthAbbr="Average invoice amount"
                  growthPrepend="£"
                  indicator={false}
                  color={false}
                />
              </GrowthStatBlocksContainer>
            </Centrify>
          </Col>
          <Col width={7} extraStyles="srelative hover:z-[9999]">
            <Row tweaks="w-full text-left gap-y-[24px] pt-8">
              {/* <Col width={12} extraStyles="relative hover:z-[9999]">
                <Bar id={'invoice_sum_day'} height={100} data={dailyInvoiceChartData} options={invoiceBarOptions} />
              </Col> */}
              <Col width={12} extraStyles="relative hover:z-[9999]">
                <Bar
                  id={"invoice_sum_day_month"}
                  height={100}
                  data={dailyMonthlyInvoiceChartData}
                  options={chartOptionsSwitch(
                    null,
                    { beginAtZero: true, min: undefined, max: undefined },
                    vatSwitch
                  )}
                />
              </Col>
              <Col width={12} extraStyles="relative hover:z-[9999]">
                <Bar
                  id={"invoice_sum_month"}
                  height={100}
                  data={monthlyInvoiceChartData}
                  options={chartOptionsSwitch(
                    null,
                    { beginAtZero: true, min: undefined, max: undefined },
                    vatSwitch
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col width={12} extraStyles="inline-flex max-w-full">
            <InvoicesTable
              monthlyInvoiceChartData={monthlyInvoiceChartData || null}
              monthlyCountsChartData={monthlyCountsChartData || null}
              invoiceKpiData={invoiceKpiData || null}
              vatSwitch={vatSwitch}
            />
          </Col>
        </Row>
      )}
      <Row tweaks="w-full text-left gap-y-[2px] pt-6">
        <Col width={5}>
          <GrowthStatBlocksContainer blocks={3} gap>
            <div className="pl-2 mb-[6px] text-slate-500 text-[12px]">
              In range totals
            </div>
            <div className="pl-2 mb-[6px] text-slate-500 text-[12px]">
              EOM averages
            </div>
            <div className="pl-2 mb-[6px] text-slate-500 text-[12px]">
              Daily averages
            </div>
          </GrowthStatBlocksContainer>
          <Centrify>
            <GrowthStatBlocksContainer blocks={3} gap>
              <GrowthStatBlock
                description="Members"
                value={memberKpiData?.users_num || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.users_diff || 0}
                growthAbbr="Member count difference in range"
                tweaks="border-x-slate-400 border-t-slate-400"
              />
              <GrowthStatBlock
                description="Members"
                value={memberKpiData?.month_users_avg || 0}
                valueFormatter={numberWithCommas}
                tweaks="border-x-slate-400 border-t-slate-400"
              />
              <GrowthStatBlock
                description="Members"
                value={memberKpiData?.day_users_avg || 0}
                valueFormatter={numberWithCommas}
                tweaks="border-x-slate-400 border-t-slate-400"
              />
              <GrowthStatBlock
                description="Active"
                value={memberKpiData?.users_active_num || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.users_active_diff || 0}
                growthAbbr="Member count difference in range"
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Active"
                value={memberKpiData?.month_users_active_avg || 0}
                valueFormatter={numberWithCommas}
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Active"
                value={memberKpiData?.day_users_active_avg || 0}
                valueFormatter={numberWithCommas}
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Activation rate"
                value={memberKpiData?.users_active_ratio || 0}
                valueFormatter={numberWithCommas}
                valueAppend="%"
                growth={memberKpiData?.users_active_ratio_diff || 0}
                growthAbbr="Activation rate difference in range"
                growthAppend="%"
                tweaks="border-x-slate-400 border-b-slate-400 mb-px"
              />
              <GrowthStatBlock
                description="Activation rate"
                value={memberKpiData?.month_users_active_ratio_avg || 0}
                valueFormatter={numberWithCommas}
                valueAppend="%"
                tweaks="border-x-slate-400 border-b-slate-400 mb-px"
              />
              <GrowthStatBlock
                description="Activation rate"
                value={
                  Math.floor(
                    (memberKpiData?.day_users_active_avg /
                      memberKpiData?.day_users_avg) *
                      10000
                  ) / 100 || 0
                }
                valueFormatter={numberWithCommas}
                valueAppend="%"
                tweaks="border-x-slate-400 border-b-slate-400 mb-px"
              />
              <GrowthStatBlock
                description="Added"
                value={memberKpiData?.users_added || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.users_added_ratio || 0}
                growthAbbr="Members added as percentage of starting count"
                growthAppend="%"
              />
              <GrowthStatBlock
                description="Added"
                value={memberKpiData?.month_users_added_avg || 0}
                valueFormatter={numberWithCommas}
                growth={
                  Math.floor(
                    ((memberKpiData?.month_users_added_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) / 100 || 0
                }
                growthAbbr="Members added as percentage of the monthly average"
                growthAppend="%"
              />
              <GrowthStatBlock
                description="Added"
                value={memberKpiData?.day_users_added_avg || 0}
                valueFormatter={numberWithCommas}
                growth={
                  Math.floor(
                    ((memberKpiData?.day_users_added_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) / 100 || 0
                }
                growthAbbr="Members added as percentage of the daily average"
                growthAppend="%"
              />
              <GrowthStatBlock
                description="Removed"
                value={(memberKpiData?.users_removed || 0) * -1}
                valueFormatter={numberWithCommas}
                growth={(memberKpiData?.users_removed_ratio || 0) * -1}
                growthAbbr="Members removed as percentage of starting count"
                growthAppend="%"
              />
              <GrowthStatBlock
                description="Removed"
                value={(memberKpiData?.month_users_removed_avg || 0) * -1}
                valueFormatter={numberWithCommas}
                growth={
                  Math.floor(
                    ((memberKpiData?.month_users_removed_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      -10000
                  ) / 100 || 0
                }
                growthAbbr="Members removed as percentage of the monthly average"
                growthAppend="%"
              />
              <GrowthStatBlock
                description="Removed"
                value={(memberKpiData?.day_users_removed_avg || 0) * -1}
                valueFormatter={numberWithCommas}
                growth={
                  Math.floor(
                    ((memberKpiData?.day_users_removed_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      -10000
                  ) / 100 || 0
                }
                growthAbbr="Members removed as percentage of the daily average"
                growthAppend="%"
              />
              <GrowthStatBlock
                description="Growth"
                value={memberKpiData?.users_diff || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.users_diff_ratio || 0}
                growthAbbr="Members growth as percentage of starting count"
                growthAppend="%"
                tweaks="border-slate-400 mb-px"
              />
              <GrowthStatBlock
                description="Growth"
                value={memberKpiData?.month_users_diff_avg || 0}
                valueFormatter={numberWithCommas}
                growth={
                  Math.floor(
                    ((memberKpiData?.month_users_diff_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) / 100 || 0
                }
                growthAbbr="Member growth as percentage of the monthly average"
                growthAppend="%"
                tweaks="border-slate-400 mb-px"
              />
              <GrowthStatBlock
                description="Growth"
                value={
                  (memberKpiData?.day_users_added_avg || 0) -
                  (memberKpiData?.day_users_removed_avg || 0)
                }
                valueFormatter={numberWithCommas}
                growth={
                  Math.floor(
                    (((memberKpiData?.day_users_added_avg || 0) -
                      (memberKpiData?.day_users_removed_avg || 0)) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) / 100 || 0
                }
                growthAbbr="Member growth as percentage of the monthly average"
                growthAppend="%"
                tweaks="border-slate-400 mb-px"
              />
              <GrowthStatBlock
                description="Basic"
                value={memberKpiData?.basic_users_num || 0}
                valueFormatter={numberWithCommas}
                percent={(memberKpiData?.basic_users_ratio || 0) + "%"}
                percentAbbr="Basic members as a percentage of all"
                growth={memberKpiData?.basic_users_diff || 0}
                growthAbbr="Basic member count difference in range"
              />
              <GrowthStatBlock
                description="Basic"
                value={memberKpiData?.month_basic_users_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (Math.floor(
                    ((memberKpiData?.month_basic_users_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Basic members as percentage of the monthly average"
              />
              <GrowthStatBlock
                description="Basic"
                value={memberKpiData?.day_basic_users_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (Math.floor(
                    ((memberKpiData?.day_basic_users_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Basic members as percentage of the daily average"
              />
              <GrowthStatBlock
                description="Basic Active"
                value={memberKpiData?.basic_users_active_num || 0}
                valueFormatter={numberWithCommas}
                percent={(memberKpiData?.basic_users_active_ratio || 0) + "%"}
                percentAbbr="Active basic members as a percentage of basic"
                growth={memberKpiData?.basic_users_active_ratio_diff || 0}
                growthAbbr="Activation rate difference in range"
                growthAppend="%"
              />
              <GrowthStatBlock
                description="Basic Active"
                value={memberKpiData?.month_basic_users_active_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (memberKpiData?.month_basic_users_active_ratio_avg || 0) + "%"
                }
                percentAbbr="Active basic members as a percentage of basic"
              />
              <GrowthStatBlock
                description="Basic Active"
                value={memberKpiData?.day_basic_users_active_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (Math.floor(
                    (memberKpiData?.day_basic_users_active_avg /
                      memberKpiData?.day_basic_users_avg) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Active basic members as a percentage of basic"
              />
              <GrowthStatBlock
                description="Premium"
                value={memberKpiData?.premium_users_num || 0}
                valueFormatter={numberWithCommas}
                percent={(memberKpiData?.premium_users_ratio || 0) + "%"}
                percentAbbr="Premium members as a percentage of all"
                growth={memberKpiData?.premium_users_diff || 0}
                growthAbbr="Premium member count difference in range"
              />
              <GrowthStatBlock
                description="Premium"
                value={memberKpiData?.month_premium_users_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (Math.floor(
                    ((memberKpiData?.month_premium_users_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Premium members as percentage of the monthly average"
              />
              <GrowthStatBlock
                description="Premium"
                value={memberKpiData?.day_premium_users_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (Math.floor(
                    ((memberKpiData?.day_premium_users_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Premium members as percentage of the daily average"
              />
              <GrowthStatBlock
                description="Premium Active"
                value={memberKpiData?.premium_users_active_num || 0}
                valueFormatter={numberWithCommas}
                percent={(memberKpiData?.premium_users_active_ratio || 0) + "%"}
                percentAbbr="Active premium members as a percentage of premium"
                growth={memberKpiData?.premium_users_active_ratio_diff || 0}
                growthAbbr="Activation rate difference in range"
                growthAppend="%"
              />
              <GrowthStatBlock
                description="Premium Active"
                value={memberKpiData?.month_premium_users_active_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (memberKpiData?.month_premium_users_active_ratio_avg || 0) +
                  "%"
                }
                percentAbbr="Active premium members as a percentage of premium"
              />
              <GrowthStatBlock
                description="Premium Active"
                value={memberKpiData?.day_premium_users_active_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (Math.floor(
                    (memberKpiData?.day_premium_users_active_avg /
                      memberKpiData?.day_premium_users_avg) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Active premium members as a percentage of premium"
              />
              <GrowthStatBlock
                description="Tenants"
                value={memberKpiData?.tenant_num || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.label_tenant_diff || 0}
                growthAbbr="Tenants count difference in range"
                percent={
                  Math.floor(
                    ((memberKpiData?.tenant_num || 0) /
                      (memberKpiData?.users_num || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                percentAbbr="Tenants as percent of all members"
                tweaks="border-x-slate-400 border-t-slate-400"
              />
              <GrowthStatBlock
                description="Tenants"
                value={memberKpiData?.month_tenant_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.month_tenant_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400 border-t-slate-400"
              />
              <GrowthStatBlock
                description="Tenants"
                value={memberKpiData?.day_tenant_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.day_tenant_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400 border-t-slate-400"
              />
              <GrowthStatBlock
                description="Landlords"
                value={memberKpiData?.landlord_num || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.label_landlord_diff || 0}
                growthAbbr="Landlords count difference in range"
                percent={
                  Math.floor(
                    ((memberKpiData?.landlord_num || 0) /
                      (memberKpiData?.users_num || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                percentAbbr="Landlords as percent of all members"
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Landlords"
                value={memberKpiData?.month_landlord_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.month_landlord_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Landlords"
                value={memberKpiData?.day_landlord_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.day_landlord_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Buyers"
                value={memberKpiData?.buyer_num || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.label_buyer_diff || 0}
                growthAbbr="Buyers count difference in range"
                percent={
                  Math.floor(
                    ((memberKpiData?.buyer_num || 0) /
                      (memberKpiData?.users_num || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                percentAbbr="Buyers as percent of all members"
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Buyers"
                value={memberKpiData?.month_buyer_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.month_buyer_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Buyers"
                value={memberKpiData?.day_buyer_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.day_buyer_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Vendors"
                value={memberKpiData?.vendor_num || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.label_vendor_diff || 0}
                growthAbbr="Vendors count difference in range"
                percent={
                  Math.floor(
                    ((memberKpiData?.vendor_num || 0) /
                      (memberKpiData?.users_num || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                percentAbbr="Vendors as percent of all members"
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Vendors"
                value={memberKpiData?.month_vendor_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.month_vendor_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Vendors"
                value={memberKpiData?.day_vendor_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.day_vendor_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400"
              />
              <GrowthStatBlock
                description="Staff"
                value={memberKpiData?.staff_num || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.label_staff_diff || 0}
                growthAbbr="Staff count difference in range"
                percent={
                  Math.floor(
                    ((memberKpiData?.staff_num || 0) /
                      (memberKpiData?.users_num || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                percentAbbr="Staff as percent of all members"
                tweaks="border-x-slate-400 border-b-slate-400"
              />
              <GrowthStatBlock
                description="Staff"
                value={memberKpiData?.month_staff_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.month_staff_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400 border-b-slate-400"
              />
              <GrowthStatBlock
                description="Staff"
                value={memberKpiData?.day_staff_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.day_staff_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400 border-b-slate-400"
              />
              <GrowthStatBlock
                description="Student"
                value={memberKpiData?.student_num || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.label_student_diff || 0}
                growthAbbr="Student count difference in range"
                percent={
                  Math.floor(
                    ((memberKpiData?.student_num || 0) /
                      (memberKpiData?.users_num || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                percentAbbr="Student as percent of all members"
                tweaks="border-x-slate-400 border-b-slate-400"
              />
              <GrowthStatBlock
                description="Student"
                value={memberKpiData?.month_student_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.month_student_avg || 0) /
                      (memberKpiData?.month_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400 border-b-slate-400"
              />
              <GrowthStatBlock
                description="Student"
                value={memberKpiData?.day_student_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  Math.floor(
                    ((memberKpiData?.day_student_avg || 0) /
                      (memberKpiData?.day_users_avg || 0)) *
                      10000
                  ) /
                    100 +
                  "%"
                }
                tweaks="border-x-slate-400 border-b-slate-400"
              />
              <GrowthStatBlock
                description="Sessions"
                value={memberKpiData?.sessions_sum || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.sessions_diff || 0}
                growthAbbr="Basic member count difference in range"
                tweaks="border-t-slate-400"
              />

              <GrowthStatBlock
                description="Sessions"
                value={memberKpiData?.month_sessions_avg || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.sessions_month_var || 0}
                growthAbbr="Basic members as percentage of the monthly average"
                tweaks="border-t-slate-400"
              />
              <GrowthStatBlock
                description="Sessions"
                value={memberKpiData?.day_sessions_avg || 0}
                valueFormatter={numberWithCommas}
                tweaks="border-t-slate-400"
              />
              <GrowthStatBlock
                description="Logins"
                value={memberKpiData?.logins_sum || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.logins_diff || 0}
                growthAbbr="Basic member count difference in range"
                percent={
                  (Math.floor(
                    ((memberKpiData?.logins_sum || 0) /
                      (memberKpiData?.sessions_sum || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Basic members as a percentage of all"
              />
              <GrowthStatBlock
                description="Logins"
                value={memberKpiData?.month_logins_avg || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.logins_month_var || 0}
                growthAbbr="Basic members as percentage of the monthly average"
                percent={
                  (Math.floor(
                    ((memberKpiData?.month_logins_avg || 0) /
                      (memberKpiData?.month_sessions_avg || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Basic members as a percentage of all"
              />
              <GrowthStatBlock
                description="Logins"
                value={memberKpiData?.day_logins_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (Math.floor(
                    ((memberKpiData?.day_logins_avg || 0) /
                      (memberKpiData?.day_sessions_avg || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Basic members as a percentage of all"
              />
              <GrowthStatBlock
                description="Returns"
                value={memberKpiData?.returns_sum || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.returns_diff || 0}
                growthAbbr="Basic member count difference in range"
                percent={
                  (Math.floor(
                    ((memberKpiData?.returns_sum || 0) /
                      (memberKpiData?.sessions_sum || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Basic members as a percentage of all"
              />
              <GrowthStatBlock
                description="Returns"
                value={memberKpiData?.month_returns_avg || 0}
                valueFormatter={numberWithCommas}
                growth={memberKpiData?.returns_month_var || 0}
                growthAbbr="Basic members as percentage of the monthly average"
                percent={
                  (Math.floor(
                    ((memberKpiData?.month_returns_avg || 0) /
                      (memberKpiData?.month_sessions_avg || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Basic members as a percentage of all"
              />
              <GrowthStatBlock
                description="Returns"
                value={memberKpiData?.day_returns_avg || 0}
                valueFormatter={numberWithCommas}
                percent={
                  (Math.floor(
                    ((memberKpiData?.day_returns_avg || 0) /
                      (memberKpiData?.day_sessions_avg || 0)) *
                      10000
                  ) / 100 || 0) + "%"
                }
                percentAbbr="Basic members as a percentage of all"
              />
              <GrowthStatBlock
                description="Returns %"
                value={memberKpiData?.returns_ratio_sum || 0}
                valueFormatter={numberWithCommas}
                valueAppend="%"
                growth={memberKpiData?.returns_ratio_diff || 0}
                growthAbbr="Basic member count difference in range"
                growthAppend="%"
              />
              <GrowthStatBlock
                description="Returns %"
                value={memberKpiData?.month_returns_ratio_avg || 0}
                valueFormatter={numberWithCommas}
                valueAppend="%"
                growth={memberKpiData?.returns_ratio_month_var || 0}
                growthAbbr="Basic members as percentage of the monthly average"
              />
              <GrowthStatBlock
                description="Returns %"
                value={memberKpiData?.day_returns_ratio_avg || 0}
                valueFormatter={numberWithCommas}
                valueAppend="%"
              />
            </GrowthStatBlocksContainer>
          </Centrify>
        </Col>
        <Col width={7} extraStyles="srelative hover:z-[9999]">
          <Row tweaks="w-full text-left gap-y-[24px] pt-8">
            <Col width={12} extraStyles="relative hover:z-[9999]">
              <Bar
                id={"member-growth-month"}
                height={100}
                data={memberGrowthMonthChartData}
                options={chartOptionsSwitch(
                  null,
                  { display: false },
                  numberWithCommas
                )}
              />
            </Col>
            <Col width={12} extraStyles="relative hover:z-[9999]">
              <Bar
                id={"member-breakdown-month"}
                height={100}
                data={memberBreakdownMonthChartData}
                options={chartOptionsSwitch(
                  null,
                  { beginAtZero: false },
                  numberWithCommas
                )}
              />
            </Col>
            <Col width={12} extraStyles="relative hover:z-[9999]">
              <Bar
                id={"member-labels-month"}
                height={100}
                data={memberLabelsMonthChartData}
                options={chartOptionsSwitch(
                  null,
                  { beginAtZero: false },
                  numberWithCommas
                )}
              />
            </Col>
            <Col width={12} extraStyles="relative hover:z-[9999]">
              <Bar
                id={"member-engagement-month"}
                height={100}
                data={memberEngagementMonthChartData}
                options={chartOptionsSwitch(
                  null,
                  { beginAtZero: false },
                  numberWithCommas
                )}
              />
            </Col>
          </Row>
        </Col>
        <Col width={12} extraStyles="inline-flex max-w-full">
          <UserGrowthTable
            memberBreakdownMonthChartData={memberBreakdownMonthChartData}
            memberGrowthMonthChartData={memberGrowthMonthChartData}
            memberLabelsMonthChartData={memberLabelsMonthChartData}
            memberKpiData={memberKpiData}
            memberEngagementMonthChartData={memberEngagementMonthChartData}
          />
        </Col>
      </Row>
      <Row tweaks="w-full text-left gap-y-[2px] pt-8">
        <Col width={12}>
          <div className="mb-[6px] text-slate-500 text-[16px]">Old charts</div>
        </Col>
        <Col width={6} extraStyles="relative hover:z-[9999]">
          <Line
            id={"key-stats"}
            height={100}
            data={mainStatsChartData}
            options={chartOptions}
          />
        </Col>
        <Col width={6} extraStyles="relative hover:z-[9999]">
          <Line
            id={"access-types"}
            height={100}
            data={accountTypeStatsChartData}
            options={chartOptions}
          />
        </Col>
        <Col width={6} extraStyles="relative hover:z-[9999]">
          <Line
            id={"label-type"}
            height={100}
            data={labelsCharData}
            options={chartOptions}
          />
        </Col>
        <Col width={6} extraStyles="relative hover:z-[9999]">
          <Line
            id={"created-method"}
            height={100}
            data={createdMethodCharData}
            options={chartOptions}
          />
        </Col>
        <Col width={6} extraStyles="relative hover:z-[9999]">
          <Line
            id={"upgrades"}
            height={100}
            data={upgradeStatsChartData}
            options={chartOptions}
          />
        </Col>
        <Col width={6} extraStyles="relative hover:z-[9999]">
          <Line
            id={"engagement"}
            height={100}
            data={userActivityChartData}
            options={chartOptions}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PrimaryKPIReport;
