import React from "react";
import { useParams } from "react-router-dom";
import Permissions, {
  tenantNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import VabooUserInvoiceList from "./VabooUserInvoiceList";

const TenantInvoiceList = () => {
  const { id } = useParams();
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.UserInvoices, {
    noAccessFallbackList: tenantNoAccessFallbackList,
    variables: { id },
  });
  return (
    <VabooUserInvoiceList
      viewItemPermission={hasAccess(Permissions.Query.UserInvoice)}
      createItemPermission={hasAccess(Permissions.Mutation.CreateUserInvoice)}
      deleteItemPermission={hasAccess(Permissions.Mutation.DeleteUserInvoice)}
    />
  );
};

export default TenantInvoiceList;
