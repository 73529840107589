import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router-dom";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import { REPORTS_USER_CINEMA_ORDERS_MODEL_NAME } from "./../../configs/constants";
import { cinemaOrder } from "./../../graphql/queries/reportQueries";
import useBaseNavigation from "./../../hooks/baseNavigation";
import {
  CancelButton,
  InlineControls,
  TEditButton,
} from "./../../moose-ui/Controls";
import {
  BlockContainer,
  Col,
  PageContainer,
  Row,
} from "./../../moose-ui/Layout";
import PageSpinner from "./../../moose-ui/PageSpinner";
import { Heading } from "./../../moose-ui/Typography";
import { useState } from "react";
import useModal from "../../hooks/modal";
import useNotification from "../../hooks/notifications";
import { ConfirmActionModal } from "../../moose-ui/Modals";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../moose-ui/Notifications";
import {
  FieldBlock,
  renderOrderPaymentStatus,
  renderOrderState,
  renderOrderStatus,
  renderSaving,
  renderVoucher,
  SpaceBlock,
} from "../TilloBrandListApprovalPage";
import {
  TableContainer,
  TableView,
  TCol,
  TContent,
  THCol,
  THeading,
  TRow,
} from "./../../moose-ui/DataTable";

const CINEMA_ORDER_DETAILS = gql(cinemaOrder);

const InfoSection = ({ children, title = "", description = "" }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-7">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1  text-sm text-gray-500">{description}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
          {children}
        </dl>
      </div>
    </div>
  );
};

const TableSection = ({ children, title = "", description = "" }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-7">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1  text-sm text-gray-500">{description}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
          {children}
        </dl>
      </div>
    </div>
  );
};

const CinemaSocietyOrder = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.CinemaOrdersReport);
  const { goBack, goTo } = useBaseNavigation(
    REPORTS_USER_CINEMA_ORDERS_MODEL_NAME
  );
  const { id } = useParams();
  const [order, setOrder] = useState(false);

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    loading,
    error,
    refetch: refetchData,
  } = useQuery(CINEMA_ORDER_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      setOrder({ ...res.cinemaOrder });
    },
  });

  if (error) return <div>Error...</div>;
  if (!order) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="User Cinema Society Order Details" />
        <InfoSection
          title="User & Client"
          description="Brief informantion about user who made this order and user's client"
        >
          <FieldBlock label="User Id" value={order.user.id} />
          <FieldBlock label="User Email" value={order.user.email} />
          <FieldBlock
            label="Full Name"
            value={`${order.user.firstname} ${order.user.lastname}`}
          />
          <FieldBlock label="Telephone" value={`${order.user.telephone}`} />

          <FieldBlock label="Client Id" value={order.client.id} />
          <FieldBlock label="Client Email" value={order.client.email} />
          <FieldBlock label="Account Name" value={order.client.account_name} />
        </InfoSection>
        <InfoSection
          title="Order, Payment and Discounts"
          description="Full informantion about this order, related payment, amounts and discounts"
        >
          <FieldBlock label="Order Id" value={order.id} />
          <FieldBlock label="Provider" value={order.provider} />
          <FieldBlock label="Payment Provider" value={order.payment_provider} />
          <SpaceBlock width={1} />

          <FieldBlock
            label="Order Status"
            value={order.order_status}
            valueRenderFunc={renderOrderStatus}
          />
          <FieldBlock label="Order Date" value={order.created_at} />
          {/* <FieldBlock
            label="Final Response At"
            value={order.order_final_response_at}
          /> */}
          <SpaceBlock width={2} />

          <FieldBlock
            label="Payment Status"
            value={order.bank_payment_status}
            valueRenderFunc={renderOrderPaymentStatus}
          />
          <FieldBlock label="Payment Url" value={order.bank_payment_url} />
          <FieldBlock
            label="Payment Started At"
            value={order.bank_payment_started_at}
          />
          <FieldBlock
            label="Payment Completed At"
            value={order.bank_payment_completed_at}
          />

          <FieldBlock label="Amount, £" value={order.amount} />
          <FieldBlock label="Saving Amount, £" value={order.saving_amount} />
          <FieldBlock
            label="Saving, %"
            value={order.saving_percentage}
            valueRenderFunc={renderSaving}
          />

          <FieldBlock
            label="Final Amount (User Payment), £"
            value={order.final_amount}
          />
        </InfoSection>

        {order.products &&
          order.products.map((product, idx) => (
            <InfoSection
              key={idx}
              title={`Product #${idx + 1}. ${product.title}`}
              description={`${product.description}`}
            >
              <FieldBlock label="SKU" value={product.sku} />
              <FieldBlock label="Quantity" value={product.qty} />
              <FieldBlock />
              <FieldBlock
                label="Final Price per Item, £"
                value={product.final_price}
              />

              <FieldBlock label="RRP Price, £" value={product.rrp_price} />
              <FieldBlock
                label="Saving Amount, £"
                value={product.saving_amount}
              />
              <FieldBlock label="Saving, %" value={product.saving_percentage} />
              <FieldBlock label="Price, £" value={product.price} />

              {product.vouchers &&
                product.vouchers.map((voucher, vdx) => (
                  <React.Fragment key={vdx}>
                    <FieldBlock
                      label={`Voucher #${vdx + 1}`}
                      value={voucher}
                      width={1}
                      valueRenderFunc={renderVoucher}
                    />
                  </React.Fragment>
                ))}
            </InfoSection>
          ))}

        <TableSection
          title="Other Cinema Orders by this User"
          description="List of other orders made by this user"
        >
          {(!order.other_orders || order.other_orders.length === 0) && (
            <span>This user has no other orders</span>
          )}
          {order.other_orders && order.other_orders.length > 0 && (
            <TableContainer>
              <TableView>
                <THeading>
                  <TRow>
                    <THCol name="order_id" value="Order Id" />
                    <THCol name="chain" value="Chain" />
                    <THCol name="venue" value="Venue" />
                    <THCol name="total_amount" value="Total Amount, £" />
                    <THCol name="final_amount" value="Final Amount, £" />
                    <THCol name="payment_status" value="Payment Status" />
                    <THCol name="order_status" value="Order Status" />
                    <THCol name="order_state" value="State" />
                    <THCol name="order_date" value="Order Date" />
                    <THCol />
                  </TRow>
                </THeading>
                <TContent>
                  {order.other_orders.map((order, idx) => (
                    <TRow key={idx}>
                      <TCol value={order.id} />
                      <TCol value={order.chain} />
                      <TCol value={order.venue} />
                      <TCol>{order.total_amount}</TCol>
                      <TCol>{order.final_amount}</TCol>
                      <TCol>
                        {renderOrderPaymentStatus(order.bank_payment_status)}
                      </TCol>
                      <TCol>{renderOrderStatus(order.order_status)}</TCol>
                      <TCol>{renderOrderState(order.state)}</TCol>
                      <TCol>{order.created_at}</TCol>
                      <TCol>
                        <TEditButton
                          onClick={() => {
                            goTo(
                              `/${REPORTS_USER_CINEMA_ORDERS_MODEL_NAME}/${order.id}`
                            );
                          }}
                          label="View"
                        />
                      </TCol>
                    </TRow>
                  ))}
                </TContent>
              </TableView>
            </TableContainer>
          )}
        </TableSection>

        <TableSection
          title="Fire Webhooks"
          description="List of Fire webhooks connected with this order"
        >
          {(!order.fire_webhooks || order.fire_webhooks.length === 0) && (
            <span>No webhooks</span>
          )}
          {order.fire_webhooks && order.fire_webhooks.length > 0 && (
            <TableContainer>
              <TableView>
                <THeading>
                  <TRow>
                    <THCol name="id" value="Id" />
                    <THCol name="transaction_type" value="Transaction Type" />
                    <THCol name="status" value="Status" />
                    <THCol name="received_at" value="Received At" />
                  </TRow>
                </THeading>
                <TContent>
                  {order.fire_webhooks.map((wh, idx) => (
                    <TRow key={idx}>
                      <TCol value={wh.id} />
                      <TCol value={wh.transaction_type} />
                      <TCol value={wh.status} />
                      <TCol value={wh.created_at} />
                    </TRow>
                  ))}
                </TContent>
              </TableView>
            </TableContainer>
          )}
        </TableSection>

        <TableSection
          title="Fire Payment Check Logs"
          description="List of API call response for orders that have only one webhook (in PENDING state)"
        >
          {(!order.fire_payment_check_logs ||
            order.fire_payment_check_logs.length === 0) && (
            <span>No payment check logs</span>
          )}
          {order.fire_payment_check_logs &&
            order.fire_payment_check_logs.length > 0 && (
              <TableContainer>
                <TableView>
                  <THeading>
                    <TRow>
                      <THCol name="id" value="Id" />
                      <THCol name="transaction_type" value="Transaction Type" />
                      <THCol name="status" value="Status" />
                      <THCol name="checked_at" value="Checked At" />
                    </TRow>
                  </THeading>
                  <TContent>
                    {order.fire_payment_check_logs.map((fpcl, idx) => (
                      <TRow key={idx}>
                        <TCol value={fpcl.id} />
                        <TCol value={fpcl.transaction_type} />
                        <TCol value={fpcl.status} />
                        <TCol value={fpcl.created_at} />
                      </TRow>
                    ))}
                  </TContent>
                </TableView>
              </TableContainer>
            )}
        </TableSection>

        <Row>
          <Col width={9}>
            <InlineControls align="start">
              {/* <BasicControl onClick={() => {}} label="Check Order Status" />
              &nbsp; &nbsp;
              {hasAccess(Permissions.Mutation.PlaceOrderRaw) &&
                order.order_status !== "Resolved" && (
                  <DeleteButton
                    onClick={confirmPlaceOrder}
                    title="Place Order (Force Issue)"
                  />
                )}
              {hasAccess(Permissions.Mutation.DeleteOrder) &&
                order.order_status !== "Resolved" && (
                  <React.Fragment>
                    &nbsp; &nbsp;
                    <DeleteButton
                      onClick={confirmDeletion}
                      title="Delete Order"
                    />
                  </React.Fragment>
                )} */}
            </InlineControls>
          </Col>
          <Col width={3}>
            <InlineControls align="end">
              <CancelButton onClick={goBack} label="Go back" />
            </InlineControls>
          </Col>
        </Row>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default CinemaSocietyOrder;
