import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { clientApi } from "./../../graphql/queries/landlordQueries";
import { updateClientApiMutation } from "./../../graphql/mutations/landlordMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";
import { FormControls, SaveButton } from "../../mood-ui/Controls";
import {
  NumberField,
  SelectField,
  TextField,
  ToggleCheckboxField,
} from "../../mood-ui/Fields";
import { Heading } from "../../mood-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../mood-ui/Notifications";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useHyperState from "./../../hooks/hyperState";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "./../../hooks/notifications";

const CLIENT_API = gql(clientApi);
const UPDATE_CLIENT_API = gql(updateClientApiMutation);

const LandlordClientApi = () => {
  const { id: account_id } = useParams();
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.ClientAPI, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: account_id },
  });
  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    account_id: 0,
    access_key: "",
    is_enabled: false,
    show_activation_status: false,
    enable_activation_push: false,
  });

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { loading, error } = useQuery(CLIENT_API, {
    variables: {
      account_id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.clientApi) return false;
      setFormData({ ...result.clientApi });
    },
  });

  const [
    update,
    {
      // data: updateResult,
      loading: isUpdating,
      // error: updateError
    },
  ] = useMutation(UPDATE_CLIENT_API, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated client api",
        secondsToDisplay: 1,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update client api",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    update({ variables: data });
  };

  const [isDraft, setDraft] = useState(false);

  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="API settings"
              description="Clients can use our API to add/update/remove members. Set a complex
                          access_key such as 'W3T8-F6T0-T7Y3-T9843'."
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Configure API access"
                      description="If you change the access_key or disable the master switch,
                                  the API will stop working for the client immediately."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="is_enabled"
                      name="is_enabled"
                      label="Master Switch"
                      description="Allow API access"
                      isChecked={formData?.is_enabled}
                      error={fieldError("is_enabled")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleToggleCheckbox(e);
                      }}
                    />
                  </Col>
                  <Col width={8} extraStyles={"px-0"}>
                    <TextField
                      loading={loading}
                      name="access_key"
                      label="Access Key"
                      value={formData?.access_key}
                      onChange={handleFieldOnChange}
                      error={fieldError("access_key")}
                      description="A unique and complex access key for accessing the API"
                      isRequired={formData?.is_enabled}
                      minlength={12}
                      maxlength={64}
                      pattern="^[aA-zZ0-9]+(?:-[aA-zZ0-9]+)*"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col width={4} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="show_activation_status"
                      name="show_activation_status"
                      label="Return Activation Status"
                      description="Return Activation Status value in v4/user-api response"
                      isChecked={formData?.show_activation_status}
                      error={fieldError("show_activation_status")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleToggleCheckbox(e);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col width={4} extraStyles={"pt-1.5"}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="enable_activation_push"
                      name="enable_activation_push"
                      label="Enable Activation Push"
                      description="Enable Activation Push to AC for the client using API endpoint"
                      isChecked={formData?.enable_activation_push}
                      error={fieldError("enable_activation_push")}
                      onChange={(e) => {
                        setDraft((d) => true);
                        handleToggleCheckbox(e);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            {hasAccess(Permissions.Mutation.UpdateClientAPI) && (
              <SaveButton
                onClick={handleSave}
                loading={isUpdating}
                disabled={!isDraft || formHasErrors()}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default LandlordClientApi;
