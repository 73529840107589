import React, { useState } from "react";
import FilterItem from "./FilterItem";
import { clientTableDefaultCols } from "../../configs/clientConfig";

const visibleColsDefaultString = clientTableDefaultCols().sort().join('_')

const FiltersList = ({ 
  columns = [], 
  selectedColumns = [], 
  selectedFilters, 
  hasActiveFilters, 
  activeList, 
  typeFiltersReady, 
  toggleVisibleColumns, 
  resetDefaultVisibleCols, 
  getColumnFilter = () => {}, 
  onUpdateFilter = () => {}, 
  onUpdateDatesFilter = () => {}, 
  onRemoveDatesFilter = () => {}, 
  onUpdateRangeFilter = () => {}, 
  onRemoveRangeFilter = () => {}, 
  onClearFilter = () => {}, 
  onClearManyFilter = () => {}, 
  onSelectManyFilter = () => {}, 
  fieldGroups = [], 
}) => { 

  const [ selectedGroup, setSelectedGroup ] = useState(null)
  
  const [ allLoading, setAllLoading ] = useState(false)
  
  const [ groupLoading, setGroupLoading ] = useState(false)

  const visibleColsString = selectedColumns?.sort().join('_')

  if (!selectedGroup && fieldGroups.length > 0) { setSelectedGroup(fieldGroups[0]) }

  const getGroupDefaultStatus = (group) => {
    const defaultColsForGroup  = columns?.filter(col => col.group === group && col.default_col).map(col => col.name).sort().join('_')
    const visibleColsForGroup  = columns?.filter(col => col.group === group && selectedColumns?.includes(col.name)).map(col => col.name).sort().join('_')
    return visibleColsForGroup === defaultColsForGroup
  }
  
  const getAllZero = () => {
    return columns?.filter(col => col.hide === false).length
  }
  
  const getGroupZero = (group) => {
    return columns?.filter(col => col.group === group && !col.hide).length
  }
  
  const getGroupCount = (group) => {
    return columns?.filter(col => col.group === group).length
  }
  
  const getGroupSelectedCount = (group) => {
    return columns?.filter(col => col.group === group && selectedColumns?.includes(col.name)).length
  }

  const getGroupActiveFiltersCount = (group) => {
    return columns?.filter(col => col.group === group && selectedFilters[col.name]).length
  }

  const clearGroupFilters = (group) => {
    setGroupLoading(true)
    requestAnimationFrame(() => {
      const allActiveFiltersForGroup = columns?.filter(col => col.group === group && selectedFilters[col.name]).map(col => col.name)
      onClearManyFilter(allActiveFiltersForGroup)
      setGroupLoading(false)
    })
  }

  const clearAllFilters = () => {
    setAllLoading(true)
    requestAnimationFrame(() => {
      const allActiveFilters = columns?.filter(col => selectedFilters[col.name]).map(col => col.name)
      onClearManyFilter(allActiveFilters)
      setAllLoading(false)
    })
  }

  const showAll = () => {
    let toToggle = []
    setAllLoading(true)
    requestAnimationFrame(() => {
      columns?.filter(col => col.hide)
      .forEach(col => { 
        if (!selectedColumns.includes(col.name)) { toToggle.push(col.name) }
      })
      toggleVisibleColumns(toToggle)
      setAllLoading(false)
    })
  }

  const hideAll = () => {
    let toToggle = []
    setAllLoading(true)
    requestAnimationFrame(() => {
      columns?.filter(col => col.hide)
      .forEach(col => { 
        if (selectedColumns.includes(col.name)) { toToggle.push(col.name) }
      })
      toggleVisibleColumns(toToggle)
      setAllLoading(false)
    })
  }
  
  const resetAllDefaultVisibleCols = () => {
    setAllLoading(true)
    requestAnimationFrame(() => {
      resetDefaultVisibleCols()
      setAllLoading(false)
    })
  }

  const resetGroupDefaultVisibleCols = (group) => {
    let toToggle = []
    setGroupLoading(true)
    requestAnimationFrame(() => {
      columns?.filter(col => col.group === group && col.hide)
      .forEach(col => { 
        if (col.default_col) { if (!selectedColumns.includes(col.name)) { toToggle.push(col.name) } }
        else if (selectedColumns.includes(col.name)) { toToggle.push(col.name) }
      })
      toggleVisibleColumns(toToggle)
      setGroupLoading(false)
    })
  }

  const showAllGroup = (group) => {
    let toToggle = []
    setGroupLoading(true)
    requestAnimationFrame(() => {
      columns?.filter(col => col.group === group && col.hide)
        .forEach(col => { 
          if (!selectedColumns.includes(col.name)) { toToggle.push(col.name) }
        })
      toggleVisibleColumns(toToggle)
      setGroupLoading(false)
    })
  }

  const hideAllGroup = (group) => {
    let toToggle = []
    setGroupLoading(true)
    requestAnimationFrame(() => {
      columns?.filter(col => col.group === group && col.hide)
        .forEach(col => { 
          if (selectedColumns.includes(col.name)) { toToggle.push(col.name) }
        })
      toggleVisibleColumns(toToggle)
      setGroupLoading(false)
    })
  }

  return (
    <React.Fragment>
      <button
        type="button" 
        className={`
          select-none 
          flex justify-center items-center 
          w-full h-full
          border border-gray-200 focus:outline-none
          text-xs 
          transition-colors duration-75 ease-in-out 
          ${ 
            hasActiveFilters && (Object.keys(selectedFilters).length + (activeList ? -1 : 0) > 0)
            ? 'border-[#895f9e] bg-[#A377B8] text-white' 
            : 'hover:border-gray-200 hover:text-opacity-[80%] hover:bg-[#ffffff] bg-[#FCFCFE] border-gray-150 text-opacity-[70%] text-gray-500 '
          }
        `}
      >
        <div className="p-1 pl-[3px] spt-[4px] relative font-mono slashed-zero tabular-nums text-[11px]">
          <span className="mdi mdi-filter text-[14px]"></span>
          { hasActiveFilters && (Object.keys(selectedFilters).length + (activeList ? -1 : 0) > 0) &&
            <div className="absolute w-fit min-w-[15px] h-[18px] p-0.5 pb-0 rounded-[3px] leading-none flex items-center justify-center top-[-8px] right-[-10px] bg-[#592373] text-white">
              { Object.keys(selectedFilters).length + (activeList ? -1 : 0) }
            </div>
          }
        </div>
      </button>
      <div 
        className={`
          z-[999] origin-top-right absolute 
          top-[calc(100%+5px)] right-0
          spt-1.5 spb-0.5 spl-1.5 spr-1.5 
          overscroll-contain select-none 
          bg-white border border-gray-300 bg-white text-gray-500 
          hidden group-focus-within/cols:block hover:block
          pointer-events-clicks 
          w-fit min-w-[420px]
          before:h-[calc(75vh)] before:absolute before:top-[-10px] before:left-[-80px] before:right-[-20px] before:bg-[#ff000] before:z-[-15]
          
        `}
      >
        {columns.length > 0 && (
          <div 
            className="
              pointer-events-click 
              flex justify-end 
              w-full 
              bg-white 
              py-1 pl-1 pr-1
              text-xs 
              z-[999] 
            "
          >
            <div className="w-full flex-wrap justify-between ">
              {
                fieldGroups.length > 0 && 
                <div className="flex-wrap inline-flex gap-[2px] w-full pb-1 border-b">
                  { 
                    fieldGroups.map((group, index) => {
                      return (
                        <div 
                          key={index}
                          className={`
                            py-1 pr-1.5 pl-1
                            text-center
                            flex-grow
                            inline-flex
                            justify-between
                            min-w-[calc(33.33%-1.5px)]
                            max-w-[calc(50%-1px)]
                            text-gray-400
                            ${ 
                              selectedGroup?.name === group.name
                                ? ' bg-slate-200 text-gray-500 pointer-events-none cursor-default'
                                : ' bg-slate-50 hover:bg-slate-100 cursor-pointer'
                            }
                          `}
                          onClick={e => { setSelectedGroup(group) }}
                        >
                          <div className="inline-flex items-center">
                            <div 
                              className={`
                                w-[16px] h-[16px]
                                font-mono slashed-zero tabular-nums text-[11px]
                                ${ getGroupActiveFiltersCount(group.name) > 0 ? 'bg-[#A377B8] text-white' : 'bg-white' }
                              `}
                            >
                              { getGroupActiveFiltersCount(group.name) }
                            </div>
                            <div className="pl-1.5">
                              <span className={`mdi mdi-${ group.icon } mr-2 scale-[1] translate-y-[-0px] inline-block text-[#592373] opacity-50`}></span>
                              { group.title } 
                            </div>
                          </div>
                          <div 
                            className={`
                              text-[11px] pl-2
                              ${
                                getGroupSelectedCount(group.name) > 0 ? 'opacity-100' : 'opacity-50'
                              }
                            `}
                          >
                            <span className="text-gray-500">
                              { getGroupSelectedCount(group?.name) - getGroupZero(group?.name) }
                            </span>
                            <span className="pl-0.5 text-gray-300">
                              / <span className="text-gray-400">{ getGroupCount(group?.name) - getGroupZero(group?.name) }</span>
                            </span>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              }
              <div 
                className="bg-white z-40 w-fit overscroll-contain overflow-x-visible focus:outline-none py-1 smr-[-2px] scrollbar-hide"
                style={{ maxHeight: "50vh", overflowY: "auto", overflowX: "visible" }}
              >
                <div className="flex flex-wrap">
                  {typeFiltersReady && selectedColumns && columns && columns.length > 0 &&
                    columns
                    .filter(col => col.in_table && (selectedGroup ? col.group === selectedGroup.name : true))
                    .map((value, i) => (
                      <FilterItem 
                        key={value.name + i}
                        columnKey={value.name}
                        columnIcon={value.icon || null}
                        columnName={value.title}
                        isSelected={ selectedColumns.includes(value.name) }
                        canHide={value.hide}
                        toggleColumnVisible={toggleVisibleColumns}
                        filter={value.filter ? getColumnFilter(value.name) : null}
                        selectedValues={
                          value.filter && selectedFilters[getColumnFilter(value.name)?.queryKey] 
                          ? selectedFilters[getColumnFilter(value.name)?.queryKey] 
                          : []
                        }
                        onUpdateFilter={onUpdateFilter}
                        onUpdateDatesFilter={onUpdateDatesFilter}
                        onRemoveDatesFilter={onRemoveDatesFilter}
                        onUpdateRangeFilter={onUpdateRangeFilter}
                        onRemoveRangeFilter={onRemoveRangeFilter}
                        onClearFilter={onClearFilter}
                        onSelectManyFilter={onSelectManyFilter}
                      />
                    ))
                  }
                </div>
              </div>
              <div 
                className={`
                  w-full inline-flex justify-between pl-1 pr-1.5 pt-2 pb-1.5 border-t gap-[5px]
                  transition-all ease-out duration-100
                  ${ groupLoading && 'opacity-50 bg-gray-100 pointer-events-none' }
                `}
              >
                <div className="text-[11px] pl-0.5 text-left w-[100px] whitespace-nowrap">
                  <button 
                    onClick={e => { clearGroupFilters(selectedGroup?.name) }}
                    className={`
                      focus:outline-none
                      ${
                        getGroupActiveFiltersCount(selectedGroup?.name) == 0 
                        ? ' pointer-events-none opacity-30'
                        : ' opacity-80 hover:opacity-90 active:opacity-100'
                      }
                    `}
                  >
                    Clear group filters
                  </button>
                </div>
                <div className="text-[12px] px-0.5 text-center w-[140px] mx-auto whitespace-nowrap">
                  <span className={`mdi mdi-${ selectedGroup?.icon } mr-1.5 scale-[1.2] translate-y-[-0px] inline-block text-[#592373] opacity-50`}></span>
                  { selectedGroup?.title }
                </div>
                <div className="text-[11px] text-right w-[44px] whitespace-nowrap">
                  <span className="text-gray-500">
                    { getGroupSelectedCount(selectedGroup?.name) - getGroupZero(selectedGroup?.name) }
                  </span>
                  <span className="pl-0.5 text-gray-300">
                    / <span className="text-gray-400">{ getGroupCount(selectedGroup?.name) - getGroupZero(selectedGroup?.name) }</span>
                  </span>
                </div>
                <div className="text-[11px] text-right ml-1">
                  <button 
                    onClick={e => { showAllGroup(selectedGroup?.name) }}
                    className={`
                      focus:outline-none
                      ${
                        getGroupSelectedCount(selectedGroup?.name) >= getGroupCount(selectedGroup?.name) 
                        ? ' pointer-events-none opacity-100 underline underline-offset-2'
                        : ' opacity-40 hover:opacity-60 active:opacity-80'
                      }
                    `}

                  >
                    All
                  </button>
                </div>
                <div className="text-[11px] ml-0.5 text-right">
                  <button 
                    onClick={e => { resetGroupDefaultVisibleCols(selectedGroup?.name) }}
                    className={`
                      focus:outline-none
                      ${
                        getGroupDefaultStatus(selectedGroup?.name)
                        ? ' pointer-events-none opacity-100 underline underline-offset-2'
                        : ' opacity-40 hover:opacity-60 active:opacity-80'
                      }
                    `}
                  >
                    Default
                  </button>
                </div>
                <div className="text-[11px] ml-0.5 pr-0.5 text-right">
                  <button 
                    onClick={e => { hideAllGroup(selectedGroup?.name) }}
                    className={`
                      focus:outline-none
                      ${
                        getGroupSelectedCount(selectedGroup?.name) <= getGroupZero(selectedGroup?.name)
                        ? ' pointer-events-none opacity-100 underline underline-offset-2'
                        : ' opacity-40 hover:opacity-60 active:opacity-80'
                      }
                    `}
                  >
                    None
                  </button>
                </div>
              </div>
              <div 
                className={`
                  w-full inline-flex justify-between pl-1 pr-1.5 pt-2 pb-1.5 border-t gap-[5px]
                  transition-all ease-out duration-100
                  ${ allLoading && 'opacity-50 bg-gray-100 pointer-events-none' }
                `}
              >

                <div className="text-[11px] pl-0.5 text-left w-[100px] whitespace-nowrap">
                  <button 
                    onClick={e => { clearAllFilters() }}
                    className={`
                      focus:outline-none
                      ${
                        !hasActiveFilters
                        ? ' pointer-events-none opacity-30'
                        : ' opacity-80 hover:opacity-90 active:opacity-100'
                      }
                    `}
                  >
                    Clear global filters
                  </button>
                </div>
                <div className="text-[12px] px-0.5 text-center w-[140px] mx-auto whitespace-nowrap">
                  All groups
                </div>
                <div className="text-[11px] text-right w-[44px] whitespace-nowrap">
                  <span className="text-gray-500">
                    {  selectedColumns?.length - getAllZero() || 0 }
                  </span>
                  <span className="pl-0.5 text-gray-300 ">
                    / <span className="text-gray-400">{ columns?.length - getAllZero() }</span>
                  </span>
                </div>
                <div className="text-[11px] text-right ml-1">
                  <button 
                    onClick={e => { showAll() }}
                    className={`
                      focus:outline-none
                      ${
                        selectedColumns?.length >= columns?.length
                        ? ' pointer-events-none opacity-100 underline underline-offset-2'
                        : ' opacity-40 hover:opacity-60 active:opacity-80'
                      }
                    `}

                  >
                    All
                  </button>
                </div>
                <div className="text-[11px] ml-px text-right whitespace-nowrap">
                  <button 
                    onClick={e => { resetAllDefaultVisibleCols() }}
                    className={`
                      focus:outline-none
                      ${
                        (visibleColsDefaultString == visibleColsString)
                        ? ' pointer-events-none opacity-100 underline underline-offset-2'
                        : ' opacity-40 hover:opacity-60 active:opacity-80'
                      }
                    `}
                  >
                    Default
                  </button>
                </div>
                <div className="text-[11px] ml-px pr-0.5 text-right whitespace-nowrap">
                  <button 
                    onClick={e => { hideAll() }}
                    className={`
                      focus:outline-none
                      ${
                        selectedColumns?.length <= getAllZero()
                        ? ' pointer-events-none opacity-100 underline underline-offset-2'
                        : ' opacity-40 hover:opacity-60 active:opacity-80'
                      }
                    `}
                  >
                    None
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default FiltersList