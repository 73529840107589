import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { importFullDetails } from "./../../graphql/queries/landlordQueries";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useTypeFilters from "./../../hooks/typeFilters";
import { IMPORT_TYPE_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../mood-ui/Layout";
import { Heading } from "./../../mood-ui/Typography";
import TypeFilters from "../../mood-ui/filters/zzzTypeFilters"; 
import { ItemsNotFound, ColorLabel } from "./../../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
} from "./../../mood-ui/DataTable"; 

const IMPORT_FULL_DETAILS = gql(importFullDetails);

const LandlordImportDetails = () => {
  const { id: clientId, import_id: id } = useParams();
  const { search: queryString } = useLocation();
  const { data, loading, error } = useQuery(IMPORT_FULL_DETAILS, {
    variables: {
      id,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });
  const { applyRawFilters: applyFilters, resetRawFilters: resetFilters } =
    useBaseNavigation(IMPORT_TYPE_MODEL_NAME, {
      urlContext: `clients/${clientId}/imports/${id}/view`,
    });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    resetFilters: onResetFilters,
    removeFilterValue: onRemoveFilterValue,
    applyFilters: onApplyFilters,
    clearFilter: onClearFilter, 
    selectManyFilter: onSelectManyFilter, 
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "tenantImport",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: queryString,
  });

  if (error) return <div>Error</div>;
  
  return (
    <PageContainer>
      <BlockContainer>
        <Row tweaks="border-b border-[#dfe2e8] pb-5 mx-5">
          <Col width={4}>
            <Heading
              inset={true}
              text={
                loading
                  ? `Import details- Loading`
                  : `Import #${data.importFullDetails.id || ''}`
              }
              description="A log of records for this import."
            />
          </Col>
          <Col width={8}>
            {typeFiltersReady && (
              <TypeFilters
                data={typeFilters}
                onApply={onApplyFilters}
                onReset={onResetFilters}
                onRemoveValue={onRemoveFilterValue}
                hasActiveFilters={hasActiveFilters}
                onUpdate={onUpdateFilter}
                selectedFilters={selectedFilters}
                onClearFilter={onClearFilter}
                onSelectManyFilter={onSelectManyFilter}              
              />
            )}
          </Col>
        </Row>
        <TableContainer tweaks="px-5 mt-8">
          <TableView tweaks={(loading || !data || (data && data.importFullDetails && data.importFullDetails.tenantImports?.length === 0)) ? 'border-[#ebedf2]' : ''}>
            <THeading tweaks={(loading || !data || (data && data.importFullDetails && data.importFullDetails.tenantImports?.length === 0)) ? 'opacity-30 border-b' : ''}> 
              <TRow>
                <THCol tweaks="text-left" name="first_name" value="First name" />
                <THCol tweaks="text-left" name="last_name" value="Last name" />
                <THCol tweaks="text-left" name="email" value="Email" />
                <THCol tweaks="text-left" name="telephone" value="Telephone" />
                <THCol tweaks="text-left" name="duplicate_info" value="Details" />
                <THCol tweaks="text-center" name="status" value="Status" />
              </TRow>
            </THeading>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <PageSpinner inline extraStyles="h-[300px]" />
                  </td>
                </tr>
                </tbody>
              ) 
              : ((data && data.importFullDetails 
                  && data.importFullDetails.tenantImports 
                  && data.importFullDetails.tenantImports.length == 0
                ) ? (
                  <tbody>
                    <tr>
                      <td colSpan={"100%"}>
                        <ItemsNotFound text="No previous operations found." tweaks="mx-5" />
                      </td>
                    </tr>
                  </tbody>
                )
                :
                ((data && data.importFullDetails 
                  && data.importFullDetails.tenantImports 
                  && data.importFullDetails.tenantImports.length > 0) 
                  && (
                    <TContent>
                      {data.importFullDetails.tenantImports.map((i) => (
                        <TRow key={i.id}>
                          <TCol tweaks="max-w-[110px] font-mono slashed-zero tabular-nums text-left text-xs">
                            <abbr title={i.firstname}>
                              {i.firstname}
                            </abbr>
                          </TCol>
                          <TCol tweaks="max-w-[110px] font-mono slashed-zero tabular-nums text-left text-xs">
                            <abbr title={i.lastname}>
                              {i.lastname}
                            </abbr>
                          </TCol>
                          <TCol tweaks="max-w-[180px] font-mono slashed-zero tabular-nums text-left text-xs">
                            <abbr title={i.email}>
                              {i.email}
                            </abbr>
                          </TCol>
                          <TCol 
                            tweaks="max-w-[90px] font-mono slashed-zero tabular-nums text-left uppercase text-xs" 
                            value={i.telephone} 
                          />
                          <TCol tweaks="max-w-[220px] font-mono slashed-zero tabular-nums text-left text-xs">
                            {i.duplicate_of_tenant &&
                              i.duplicate_of_tenant.id &&
                              i.duplicate_of_tenant.landlord 
                            && (
                              <abbr title={`Belongs to ${i.duplicate_of_tenant.landlord.email} (${i.duplicate_of_tenant.landlord.id})`}>
                                <span className="font-light">
                                  Dbl: 
                                </span>
                                <span className="font-lights mx-3">
                                  {i.duplicate_of_tenant.landlord.email} 
                                </span>
                                <span className="font-light">
                                  {i.duplicate_of_tenant.landlord.id}
                                </span>
                              </abbr>
                            )}
                          </TCol>
                          <TCol tweaks={`w-[50px] font-mono slashed-zero tabular-nums text-center uppercase text-xs
                            ${
                              (   i.status == 'Imported' )
                              ? ' text-emerald-600 bg-emerald-200'
                              : ( i.status == 'Duplicate' )
                              ? ' text-yellow-600 bg-yellow-200'
                              : ( i.status == 'Init' )
                              ? ' text-gray-600 bg-gray-200'
                              : ' text-rose-500 bg-rose-100'
                            }`
                          }>
                            { i.status } 
                          </TCol>
                        </TRow>
                      ))}
                    </TContent>
                  )
                )
              )
            }
          </TableView>
        </TableContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default LandlordImportDetails;
