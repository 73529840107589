import { useState, useEffect, useCallback } from "react";

export const useBaseField = props => {
  const { value, name, onChange, error } = props;
  const [fieldState, setFieldState] = useState({ value, error });
  useEffect(() => {
    setFieldState({ value, error });
  }, [value, error, name]);
  const handleOnChange = useCallback(
    e => {
      setFieldState({ value: e.target.value, error: false });
      onChange(e);
    },
    [onChange]
  );

  const newProps = { ...props };
  newProps.value =
    typeof fieldState.value != "undefined" ? fieldState.value : "";
  newProps.error = fieldState.error;
  newProps.onChange = handleOnChange;

  return newProps;
};
