import React from "react";
import DatesFilter from "./DatesFilter";
import StandardFilter from "./StandardFilters";

const TypeFilter = (props) => {
  const { type } = props.options;

  if (type === "dates") return <DatesFilter {...props} />;

  return <StandardFilter {...props} />;
};

export default TypeFilter;
