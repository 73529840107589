import React, { useState } from "react";

import useDataFieldSetters from "./../hooks/dataFieldSetters";
import useAuthService from "./../hooks/authService";

const Login = () => {
  const [state, setState] = useState({ email: "", password: "" });
  const { setFieldValue } = useDataFieldSetters(state, setState);
  const { login, error } = useAuthService();

  const handleLogin = (e) => {
    login(state.email, state.password);
    e.target.blur()
  };

  return (
    <div className="min-h-screen bg-[#39114d] flex flex-col items-center justify-center pa-8">
      <div className="fixed top-8 left-8 opacity-20">
        <img
          className="h-10"
          src="https://control.vaboo.co.uk/images/vaboo.png"
          alt="Vaboo Control"
        />
      </div>
      <div className="mx-auto w-[400px]">
        <div className="bg-[#39114d] py-8 rounded-none sm:pl-10">
          <form action="#" method="POST">
            <div className="">
              <input
                placeholder="Email address"
                id="email"
                type="email"
                required
                className="appearance-none block 
                            w-full px-5 py-3 
                            border border-[#5a2873]
                            placeholder-gray-400 
                            bg-transparent
                            focus:bg-white
                            valid:bg-white
                            focus:placeholder-gray-500 
                            focus:outline-none 
                            transition duration-150 ease-in-out 
                            sm:text-sm sm:leading-7 text-center"
                name="email"
                value={state.email}
                onChange={(e) => setFieldValue(e)}
                onKeyPress={(e) => {
                  if (e.key == "Enter") {
                    handleLogin();
                  }
                }}
              />
          </div>
          <div className="mt-3 ml-auto">
            <input
              placeholder="Password"
              id="password"
              type="password"
              required
              className="appearance-none block 
                          w-full px-5 py-3 
                          border border-[#5a2873]
                          placeholder-gray-400 
                          bg-transparent
                          focus:bg-white
                          valid:bg-white
                          focus:placeholder-gray-500 
                          focus:outline-none 
                          sm:text-sm sm:leading-7 text-center"
              name="password"
              value={state.password}
              onChange={(e) => setFieldValue(e)}
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  handleLogin();
                }
              }}
            />
          </div>
            {/* <div className="mt-6 flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                />
                <label
                  for="remember_me"
                  className="ml-2 block text-sm leading-5 text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm leading-5">
                <a
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Forgot your password?
                </a>
              </div>
            </div> */}
            <div className="mt-3">
              <span className="block w-full">
                <button
                  type="button"
                  className={`
                              appearance-none block 
                              w-full flex justify-center py-2 px-4 
                              border-4 border-transparent
                              bg-[#5a2873] 
                              text-sm text-white leading-7
                              hover:bg-[#844aa1] 
                              focus:bg-[#844aa1] 
                              focus:outline-none focusfocus:shadow-outline 
                              active:bg-[#2d0c3d] 
                              ${ error && ' bg-red-600' }
                            `}
                  onClick={handleLogin}
                >
                  Sign in
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
