export const profileSettings = `query profileSettings($account_id: Int!, $label_id: Int!) {
    profileSettings(account_id: $account_id, label_id: $label_id) {
        is_enabled
        profile_fields {
            field
            is_required
            page_num
            order_num
            question {
                id
                admin_title
                title
                type    
            }            
        }
      }
      }
      `;
