import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { invoiceDetails } from "../../graphql/queries/billingQueries";
import { chargeNowMutation } from "../../graphql/mutations/billingMutations";
import {
  BILLING_MANUAL_INVOICE_STATUS,
  INVOICE_TYPE_MODEL_NAME,
  FAILED_INVOICE_STATUSES,
} from "./../../configs/constants";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useNotification from "../../hooks/notifications";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../../mood-ui/Layout";
import {
  Heading,
  InfoMessage,
  WarningMessage,
  ItemsNotFound, 
} from "./../../mood-ui/Typography";
import { ReadOnlyField } from "./../../mood-ui/Fields";
import {
  TableContainer,
  TableView,
  THeading,
  TContent,
  THCol,
  TRow,
  TCol,
} from "./../../mood-ui/DataTable";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../mood-ui/Notifications";
import {
  BasicControl
} from "./../../mood-ui/Controls";

const INVOICE_DETAILS = gql(invoiceDetails);
const CHARGE_NOW = gql(chargeNowMutation);

const OPTIONS = {
  USER_MODE: "",
};

const VabooUserInvoiceView = ({ chargeNowPermission }) => {
  useEffect(() => {
    OPTIONS.USER_MODE = window.location.pathname.includes("clients")
      ? "clients"
      : "users";
    return () => {
      OPTIONS.USER_MODE = "";
    };
  }, []);

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();
  const [invoice, setInvoice] = useState(false);
  const { id: user_id, invoice_id } = useParams();
  const { goBack } = useBaseNavigation(INVOICE_TYPE_MODEL_NAME, {
    urlContext: `${OPTIONS.USER_MODE}/${user_id}`,
  });
  const { loading, error } = useQuery(INVOICE_DETAILS, {
    variables: {
      id: invoice_id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.invoice) return false;
      if (result.invoice.status === BILLING_MANUAL_INVOICE_STATUS) goBack();
      setInvoice({ ...result.invoice });
    },
  });

  const [
    chargeNow,
    { data: chargeResult, loading: isCharging, error: chargingError },
  ] = useMutation(CHARGE_NOW, {
    variables: { id: invoice_id },
    onCompleted: (result) => {
      setInvoice({ ...result.chargeNow });
      if (FAILED_INVOICE_STATUSES.includes(result.chargeNow.status)) {
        displayErrorNotification({
          heading: "Error",
          message: `Failed to charge client with message: ${result.chargeNow.post_billing_message}`,
          secondsToDisplay: 4,
        });
      } else {
        displayNotification({
          heading: "Charge Now OK",
          message: "Successfully charged client",
          secondsToDisplay: 2,
        });
      }
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to charge client",
        secondsToDisplay: 3,
      });
    },
  });

  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer>
        <FormContainer>
          <Row tweaks="mx-5 pb-4">
            <Col width={12} extraStyles="h-[58px]">
              { ["Paid", "paid_out", "confirmed"].includes(invoice.status) 
              ? (
                  <InfoMessage text="Invoice is paid" />
                )
              : ["Billing disabled"].includes(invoice.status)  
              ? (
                  <InfoMessage text="Billing was disabled on the billing day" />
                )
              : ["Manual"].includes(invoice.status)  
              ? (
                  <InfoMessage text="This is a manual bill that needs further action" />
                )
              : ["cancelled", "failed", "Overdue", "Overdue_Old", "Debt Record"].includes(invoice.status) 
              ? (
                  <WarningMessage text="Invoice has problems and is not paid" />
                )
              : (
                  <PageSpinner clean fill />
                )
              }
            </Col>
          </Row>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] pb-7 pt-3 mx-5">
              <Col width={3}> 
                <Row tweaks="">
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Invoice details"
                      description="Details about this invoice. You can attempt to charge for unpaid bills but cannot make changes."
                    />
                  </Col>
                  {chargeNowPermission && FAILED_INVOICE_STATUSES.includes(invoice.status) && invoice.amount > 0 && invoice.status !== "Billing disabled" && (
                    <Col width={12}>
                      <BasicControl
                        onClick={chargeNow}
                        label="Charge now"
                        loading={isCharging}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row tweaks="gap-y-2">
                  <Col width={3}>
                    <ReadOnlyField loading={loading} value={invoice.amount} label="Amount" />
                  </Col>
                  <Col width={3}>
                    <ReadOnlyField loading={loading} value={invoice.last_billing_attempt_date} label="Last attempt"
                    />
                  </Col>
                  <Col width={3}>
                    <ReadOnlyField loading={loading} value={invoice.status} label="Status" />
                  </Col>
                  <Col width={3}>
                    <ReadOnlyField loading={loading} value={invoice.payment_system} label="Payment method" />
                  </Col>
                  <Col width={12}> 
                    <ReadOnlyField loading={loading} value={invoice.description} label="Description" />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-[#dfe2e8] pb-8 pt-7 mt-0 mx-5">
              <Col width={9}>
                <Heading
                  size="md"
                  inset={false}
                  text="Charge logs"
                  description="A list of charge attempts for this invoice."
                  />
              </Col>
            </Row>
            <TableContainer tweaks="px-5">
              <TableView tweaks={(loading || !invoice || (invoice.checks && invoice.checks && invoice.checks.length === 0)) ? 'border-[#ebedf2]' : ''}>
                <THeading tweaks={(loading || !invoice || (invoice.checks && invoice.checks && invoice.checks.length === 0)) ? 'opacity-30 border-b' : ''}>
                  <TRow>
                    <THCol tweaks="text-left" name="id" value="ID" />
                    <THCol tweaks="text-left" name="post_billing_message" value="Details" />
                    <THCol tweaks="text-center" name="created_at" value="Date time" />
                    <THCol tweaks="text-center" name="status" value="Status" />
                  </TRow>
                </THeading>
                {(loading) && (
                  <tbody>
                    <tr>
                      <td colSpan={"100%"}>
                        <PageSpinner inline extraStyles="h-[300px]" />
                      </td>
                    </tr>
                  </tbody>
                )}
                {!loading && invoice && invoice.checks && invoice.checks.length == 0 && (
                  <tbody>
                    <tr>
                      <td colSpan={"100%"}>
                        <ItemsNotFound text="No previous charge attempts found" tweaks="mx-5" />
                      </td>
                    </tr>
                  </tbody>
                )}
                {!loading && invoice && invoice.checks && invoice.checks.length > 0 && (
                  <TContent>
                    {invoice.checks.map((check, index) => (
                      <TRow key={index}>
                        <TCol 
                          tweaks="font-mono slashed-zero tabular-nums text-left text-xs"
                          value={check.id} 
                        />
                        <TCol tweaks="max-w-[380px] text-ellipsis overflow-hidden ... font-mono slashed-zero tabular-nums text-left text-xs">
                          <abbr title={check.post_billing_message}>
                            {check.post_billing_message}
                          </abbr>
                        </TCol>
                        <TCol tweaks="max-w-[40px] font-mono slashed-zero tabular-nums text-center uppercase text-xs "> 
                          <Moment format="DD&middot;MM&middot;YY | mm:hh" date={invoice.created_at} /> 
                        </TCol>
                        <TCol tweaks={`max-w-[140px] font-mono slashed-zero tabular-nums text-center uppercase text-xs
                          ${
                            (   invoice.status == 'Paid' || invoice.status == 'paid_out' || invoice.status == 'confirmed' )
                            ? ' text-emerald-600 bg-emerald-200'
                            : ( invoice.status == 'submitted' )
                            ? ' text-cyan-600 bg-cyan-200'
                            : ( invoice.status == 'pending_submission' )
                            ? ' text-yellow-600 bg-yellow-200'
                            : ( invoice.status == 'Overdue' || invoice.status == 'Overdue_Old' )
                            ? ' text-orange-600 bg-orange-200'
                            : ( invoice.status == 'failed' || invoice.status == 'cancelled' || invoice.status == 'Debt Record' )
                            ? ' text-rose-500 bg-rose-100'
                            : ( invoice.status == 'Manual' )
                            ? ' text-indigo-500 bg-indigo-100'
                            : ' text-gray-500 bg-gray-100'
                          }`
                        }>
                          { invoice.status } 
                        </TCol>
                      </TRow>
                    ))}
                  </TContent>
                )}
              </TableView>
            </TableContainer>
          </FormData>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default VabooUserInvoiceView;
