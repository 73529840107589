import React from "react";

const File = ({
  file,
  // showCopyLink,
  onSelect,
  onSelectConfirmed,
  isSelected = false,
}) => {
  
  let transitionClass = 'transition ease'

  let mainDivStyles = `text-xs text-gray-400 group cursor-pointer ${ transitionClass }`
  
  mainDivStyles += isSelected 
    ? " outline-[#b07fc7] outline outline-2 "
    : " ";
  return (

    <div
      key={file.id}
      className={mainDivStyles}
      onClick={() => {
        onSelect(file);
      }}
      onDoubleClick={() => {
        onSelectConfirmed(file);
      }}
    >

      <div className={`checkerboard overflow-hidden relative w-full flex justify-center align-center h-[70px] 
                       ${ isSelected 
                          ? 'bg-[rgba(255,255,255,0.05)] opacity-90 ' 
                          : 'bg-[rgba(0,0,0,0.05)] group-hover:bg-[rgba(115,115,115,0.05)] group-hover:opacity-90'
                        }
                     `}
      >
        <img src={file.public_url} alt="thumbnail" className="absolute top-1/2 -translate-y-[50%] backdrop-blur-sm" />
      </div>

      <div 
        className={`
          ${ isSelected ? 'bg-gray-50 text-whkite border-[#b07fc7]' : 'bg-gray-100'}
          flex justify-end 
          py-1.5 px-2 
          border-t-2
          border-gray-200
          group-hover:bg-gray-50 
        `}
      >
        {/* <span>
          {file.mimetype} 
        </span> */}
        <span>
          {parseInt(file.size / 1014)} KB
        </span>
      </div>

      {/*<center>
         {showCopyLink && (
          <button
            type="button"
            className="mt-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 text-gray-500 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            onClick={(e) => {
              navigator.clipboard.writeText(file.public_url);
              e.stopPropagation();
            }}
          >
            Copy link
          </button>
        )} 
      </center>*/}

    </div>

  );
};
 
export default File;
