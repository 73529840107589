import React from "react";
import { useBaseField } from "./../hooks/coreHooks";
import ColorPickerCustom from "./ColorPickerCustom"

const loadingCss = 'text-transparent bg-gradient-to-r from-purple-100 via-purple-50 to-purple-100 animate-pulse opacity-50 bg-white border border-purple-200'

const BaseField = ({ name, children, label, error, description, isRequired, loading = false }) => (
  <div>
    {label && (
      <label
        htmlFor={name}
        className={`text-sm leading-5 text-gray-500 pl-1 select-none ${ loading && loadingCss }`}
      >
        {label}
        {isRequired && " *"}
      </label>
    )}
    <div className="mt-0.5 relative">
      {children}
      {error && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-red-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </div>
    {!error && description && (
      <p className={`mt-1.5 text-xs text-gray-400 pl-0.5 select-none ${ loading && loadingCss }`}>{description}</p>
    )}
    {error && <p className={`mt-2 text-xs text-red-600 select-none ${ loading && loadingCss }`}>{error}</p>}
  </div>
);

const BaseColorField = React.memo(props => {
  const {
    value = "#FFFFFF",
    name,
    onChange,
    placeholder,
    error,
    loading = false, 
    onEnter = () => {
      return false;
    },
  } = props;
  return (
    <BaseField {...props}>
      { loading ? (
        <div className={loadingCss + " h-[38px] w-full "}></div>
      )
      :
      (
        <ColorPickerCustom color={value} onChange={(e) => { onChange({ target: { name, value: e.hex } }) }} />
      )
      }
    </BaseField>
  );
});
export const ColorPicker = props => {
  const newProps = useBaseField(props);
  return <BaseColorField {...newProps} />;
};
