import { useLocation } from "react-router-dom";

const useRootPageContext = (deps = []) => {
  const context = {};
  const location = useLocation();
  const parts = location.pathname.split("/");
  const rootContext = typeof parts[1] != "undefined" ? parts[1] : "";
  return { rootContext };
};

export default useRootPageContext;
