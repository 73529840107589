import queryString from "query-string";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { myDynamicListsForModel } from "../graphql/queries/listQueries";
import {
  createDynamicList,
  updateDynamicList,
} from "../graphql/mutations/listMutations";
import { useEffect, useState } from "react";

const MY_DYNAMIC_LISTS_FOR_MODEL = gql(myDynamicListsForModel);
const SAVE_NEW_LIST = gql(createDynamicList);
const SAVE_LIST = gql(updateDynamicList);

const useDynamicList = (
  model,
  {
    onNavigate = (url) => {},
    onReset = () => {},
    currentUrl,
    onSaveNewSucces = () => {},
    onSaveNewError = () => {},
    onSaveSucces = () => {},
    onSaveError = () => {},
  }
) => {

  const { queryString: search } = currentUrl();
  
  const [activeListId, setActiveListId] = useState(false);

  useEffect(() => {
    const searchObject = queryString.parse(search);
    setActiveListId(
      typeof searchObject.dl !== "undefined" && parseInt(searchObject.dl) > 0
        ? searchObject.dl
        : false
    );
  }, [search]);

  const [lists, setLists] = useState([]);
  
  const {
    loading: dynamicListsAreLoading,
    error: errorFetchingDynamicLists,
    refetch: refetchDynamicLists,
  } = useQuery(MY_DYNAMIC_LISTS_FOR_MODEL, {
    variables: { model },
    fetchPolicy: "network-only",
    onCompleted: (data) => setLists(data.myDynamicListsForModel),
  });

  const [create, { loading: isCreating }] = useMutation(SAVE_NEW_LIST, {
    onCompleted: async (result) => {
      await refetchDynamicLists();
      onNavigate(result.createDynamicList.url);
      onSaveNewSucces(result);
    },
    onError: (e) => {
      onSaveNewError(e);
    },
  });

  const [saveList, { loading: isUpdating }] = useMutation(SAVE_LIST, {
    onCompleted: async (result) => {
      await refetchDynamicLists();
      onNavigate(result.updateDynamicList.url);
      onSaveSucces(result);
    },
    onError: (e) => {
      onSaveError(e);
    },
  });

  const navigateToList = (id) => {
    const dl = lists.find((l) => l.id === id);
    dl ? onNavigate(dl.url) : onReset();
  };

  const findListById = (id) => {
    if (!lists) return false;
    return lists.find((l) => l.id === id);
  };

  const canSaveList = () => {
    if (!activeListId) return false;
    var { search, queryString: qs } = currentUrl();

    qs = qs.replace(/\%3C/g, '<')
    qs = qs.replace(/\%3E/g, '>')
    // qs = qs.replaceAll(\%3C = <  %3E = >)
    
    const list = findListById(activeListId);
    // console.log(list.queryString, '\n', qs)
    if (!list) return false;
    if (list.search !== search) return true;
    if (list.queryString !== qs) return true;
    return false;
  };

  const canSaveNewList = () => {
    if (activeListId) return canSaveList();
    const { search, queryString: qs } = currentUrl();
    return search !== "0" || qs !== "" ? true : false;
  };

  const saveNew = (data) => {
    const {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
      url,
      urlContext,
    } = currentUrl();
    create({
      variables: {
        ...data,
        model,
        page,
        limit,
        orderBy,
        orderHow,
        search,
        queryString,
        url,
        urlContext,
      },
    });
    onSaveNewSucces();
  };

  const save = ({ name, permissions }) => {
    const {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
      url,
      urlContext,
    } = currentUrl();
    saveList({
      variables: {
        id: activeListId,
        name, 
        permissions, 
        page,
        limit,
        orderBy,
        orderHow,
        search,
        queryString,
        url,
        urlContext,
      },
    });
    onSaveSucces();
  };

  return {
    activeListId,
    setActiveListId,
    lists,
    dynamicListsAreLoading,
    errorFetchingDynamicLists,
    refetchDynamicLists,
    navigateToList,
    canSaveNewList,
    saveNew,
    savingNew: isCreating,
    canSaveList,
    save,
    saving: isUpdating,
  };
};

export default useDynamicList;
