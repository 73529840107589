import { useContext } from "react";
import { PermissionContext } from "../contexts/PermissionContext";
import { useHistory } from "react-router-dom";
import useAuthService from "./authService";

const usePermissions = () => {
  const { getCurrentUser } = useAuthService();

  const v = useContext(PermissionContext);
  const h = useHistory();

  const hasPermission = (permissionCode) =>
    v.userPermissions.indexOf(permissionCode) > -1 ? true : false;

  const hasAccess = (permissionCode) => hasPermission(permissionCode);

  const hasAtLeastOnePermission = (permissions) => {
    let result = false;
    permissions.forEach((permission) => {
      if (hasPermission(permission)) result = true;
    });
    return result;
  };

  const protectPage = (
    permissionCode,
    options = { noAccessFallbackList: false, variables: false }
  ) => {
    getCurrentUser();
    if (hasPermission(permissionCode)) {
      return true;
    }
    let redirectTo = "/";
    if (
      typeof options.noAccessFallbackList === "object" &&
      typeof options.variables === "object"
    ) {
      const varNames = Object.keys(options.variables);
      options.noAccessFallbackList.map((item) => {
        varNames.forEach((varName) => {
          item.url = item.url.replace(
            `:${varName}`,
            options.variables[varName]
          );
        });
        return item;
      });
    }
    if (typeof options.noAccessFallbackList === "object") {
      options.noAccessFallbackList.forEach((item) => {
        if (redirectTo === "/" && hasAccess(item.permission))
          redirectTo = item.url;
      });
    }
    window.location.replace(redirectTo);
    // The commented way of redirecting is preffered, but React retunrs warning (but works).
    // Need to come back this later.
    // h.push(redirectTo);
  };

  return { hasPermission, hasAccess, hasAtLeastOnePermission, protectPage };
};

export default usePermissions;
