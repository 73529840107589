const clientSchema = [
  {
    name: "platform_logo_url",
    title: "Logo",
    gqlSummary: false,
    type: "image",
    default_col: true,
    sort: false,
    table_summary: false,
    click: false,
    filter: false,
    summaryToggle: true,
    cellWidth: 84,
    tdClass: "bg-[::#primary_colour::] py-[4px] border-l-2 ",
    isMainImage: true,
    imageAlt: "::#id::",
    imageClass: "mx-auto h-[21px] w-auto object-contain ",
    group: "company",
  },
  {
    name: "primary_colour",
    title: "Primary Colour",
    type: "text",
    gqlSummary: false,
    in_table: false,
    group: "company",
  },
  {
    name: "id",
    title: "ID",
    type: "text",
    table_summary: false,
    gqlSummary: null,
    click: false,
    filter: false,
    tdClass: "text-left ",
    overview_default: "--",
    group: "company",
  },
  {
    name: "email",
    title: "Email",
    type: "text",
    table_summary: false,
    gqlSummary: null,
    click: false,
    filter: false,
    cellWidth: 120,
    tdClass: " text-left",
    overview_default: "--",
    group: "company",
  },
  {
    name: "account_name",
    title: "Account",
    type: "text",
    table_summary: false,
    click: false,
    filter: false,
    cellWidth: 120,
    tdClass: "text-left ",
    overview_default: "--",
    group: "company",
  },
  {
    name: "platform_name",
    title: "Platform",
    type: "text",
    table_summary: false,
    click: false,
    filter: false,
    cellWidth: 120,
    tdClass: "text-left ",
    overview_default: "--",
    group: "company",
  },
  {
    name: "subdomain",
    title: "Subdomain",
    type: "text",
    default_col: true,
    table_summary: false,
    click: false,
    hide: false,
    summaryHeader: true,
    cellWidth: 130,
    thClass: "sticky left-[-14px] z-[100] ",
    tdClass:
      "sticky left-[-14px] z-[75] border-r-[#ecdaf5] border-r-2 text-left ",
    overview_default: "--",
    group: "company",
  },

  // ACTIVITY DATES
  {
    name: "created_at",
    title: "Created",
    type: "date",
    default_col: true,
    cellWidth: 90,
    group: "activity",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "updated_at",
    title: "Updated",
    type: "date",
    cellWidth: 90,
    group: "activity",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "last_login_date",
    title: "Last login",
    type: "date",
    default_col: true,
    cellWidth: 90,
    group: "activity",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "last_add_user_date",
    title: "Added users",
    type: "date",
    default_col: true,
    cellWidth: 90,
    group: "activity",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "last_delete_user_date",
    title: "Removed users",
    type: "date",
    default_col: true,
    cellWidth: 90,
    group: "activity",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "first_invoice_date",
    title: "First bill",
    type: "date",
    cellWidth: 90,
    group: "activity",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "first_charge_date",
    title: "First charge",
    type: "date",
    cellWidth: 90,
    group: "activity",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "last_invoice_date",
    title: "Last bill",
    type: "date",
    cellWidth: 90,
    group: "activity",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "last_charge_date",
    title: "Last charge",
    type: "date",
    cellWidth: 90,
    group: "activity",
    overview_default: "2020-01-01 00:00:00",
  },

  // SERVICE
  {
    name: "status",
    title: "Status",
    gqlSummary: "OptionsCount",
    type: "text",
    likeNull: [],
    hover: "status",
    hoverText: " - client status",
    default_col: true,
    cellWidth: 100,
    tdClass: " text-center text-[10.5px] ",
    group: "service",
  },
  {
    name: "client_type",
    title: "Client type",
    gqlSummary: "OptionsCount",
    type: "text",
    likeNull: [],
    hover: "client_type",
    hoverText: " - client type",
    default_col: true,
    cellWidth: 120,
    tdClass: " text-center text-[10.5px] ",
    group: "service",
  },
  {
    name: "service_level",
    title: "Service level",
    gqlSummary: "OptionsCount",
    type: "text",
    likeNull: [],
    hover: "service_level",
    hoverText: " service level",
    default_col: true,
    cellWidth: 100,
    tdClass: " text-center text-[10.5px] ",
    group: "service",
  },
  {
    name: "verbally_agreed_date",
    title: "Verbally agreed",
    type: "date",
    default_col: false,
    cellWidth: 90,
    group: "service",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "contract_signed_date",
    title: "Contract signed",
    type: "date",
    default_col: false,
    cellWidth: 90,
    group: "service",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "campaign_type",
    title: "Campaign",
    gqlSummary: "OptionsCount",
    type: "text",
    likeNull: [],
    hover: "campaign_type",
    hoverText: " - origin campaign",
    cellWidth: 100,
    tdClass: " text-center text-[10.5px] ",
    group: "service",
  },
  {
    name: "data_received_date",
    title: "Data received",
    type: "date",
    default_col: false,
    cellWidth: 90,
    group: "service",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "launch_date",
    title: "Launched",
    type: "date",
    default_col: false,
    cellWidth: 90,
    group: "service",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "number_of_days_to_launch",
    title: "Launch time",
    type: "number",
    default_col: false,
    hoverText: " days",
    group: "service",
  },
  {
    name: "number_of_days_to_upload",
    title: "Upload time",
    type: "number",
    default_col: false,
    hoverText: " days",
    group: "service",
  },
  {
    name: "expected_users",
    title: "Expected users",
    type: "number",
    default_col: false,
    hoverText: " users promised",
    group: "service",
  },
  {
    name: "actual_users",
    title: "Actual users",
    type: "number",
    default_col: false,
    hoverText: " users at launch",
    group: "service",
  },
  {
    name: "launch_number_user_diff",
    title: "Users diff",
    type: "number",
    default_col: false,
    hoverText: " users diff from expected",
    group: "service",
    indicator: true,
  },
  {
    name: "contract_term_length",
    title: "Contract term",
    type: "number",
    default_col: false,
    hoverText: " months",
    group: "service",
  },
  {
    name: "contract_end_date",
    title: "Contract end",
    type: "date",
    default_col: false,
    cellWidth: 90,
    group: "service",
    overview_default: "2020-01-01 00:00:00",
  },
  {
    name: "final_bill_date",
    title: "Final bill",
    type: "date",
    default_col: false,
    cellWidth: 90,
    group: "service",
    overview_default: "2020-01-01 00:00:00",
  },

  // USERS
  {
    name: "users_total",
    title: "Users",
    type: "number",
    default_col: true,
    hoverText: " users",
    group: "users",
  },
  {
    name: "active_users",
    title: "Active",
    type: "number",
    hover: "activation_rate",
    hoverText: " % active",
    group: "users",
    child: "activation_rate",
  },
  {
    name: "activation_rate",
    title: "Active %",
    type: "percent",
    default_col: true,
    hover: "active_users",
    hoverText: " active",
    group: "users",
    parent: "active_users",
  },

  // USER ACCESS TYPES
  {
    name: "basic_users_count",
    title: "Basic",
    type: "number",
    hover: "basic_users_ratio",
    hoverText: " % basic users",
    group: "users",
    child: "basic_users_ratio",
  },
  {
    name: "basic_users_ratio",
    title: "Basic %",
    type: "percent",
    hover: "basic_users_count",
    hoverText: " basic users",
    group: "users",
    parent: "basic_users_count",
  },
  {
    name: "basic_users_active",
    title: "Basic Active",
    type: "number",
    hover: "basic_users_activation_rate",
    hoverText: " % active",
    group: "users",
    child: "basic_users_activation_rate",
  },
  {
    name: "basic_users_activation_rate",
    title: "Basic Active %",
    type: "percent",
    hover: "basic_users_active",
    hoverText: " active",
    group: "users",
    parent: "basic_users_active",
  },
  {
    name: "premium_users_count",
    title: "Premium",
    type: "number",
    hover: "premium_users_ratio",
    hoverText: " % premium users",
    group: "users",
    child: "premium_users_ratio",
  },
  {
    name: "premium_users_ratio",
    title: "Premium %",
    type: "percent",
    default_col: true,
    hover: "premium_users_count",
    hoverText: " premium users",
    group: "users",
    parent: "premium_users_count",
  },
  {
    name: "premium_users_active",
    title: "Premium Active",
    type: "number",
    hover: "premium_users_activation_rate",
    hoverText: " % active",
    group: "users",
    child: "premium_users_activation_rate",
  },
  {
    name: "premium_users_activation_rate",
    title: "Premium Active %",
    type: "percent",
    hover: "premium_users_active",
    hoverText: " active",
    group: "users",
    parent: "premium_users_active",
  },

  // USER LABEL TYPES
  {
    name: "tenant_users_count",
    title: "Tenants",
    type: "number",
    hover: "tenant_users_ratio",
    hoverText: " % tenants",
    group: "users",
    child: "tenant_users_ratio",
  },
  {
    name: "tenant_users_ratio",
    title: "Tenants %",
    type: "percent",
    default_col: true,
    hover: "tenant_users_count",
    hoverText: " tenants",
    group: "users",
    parent: "tenant_users_count",
  },
  {
    name: "tenant_users_active",
    title: "Tenants Active",
    type: "number",
    hover: "tenant_users_activation_rate",
    hoverText: " % active",
    group: "users",
    child: "tenant_users_activation_rate",
  },
  {
    name: "tenant_users_activation_rate",
    title: "Tenants Active %",
    type: "percent",
    hover: "tenant_users_active",
    hoverText: " active",
    group: "users",
    parent: "tenant_users_active",
  },
  {
    name: "landlord_users_count",
    title: "Landlords",
    type: "number",
    hover: "landlord_users_ratio",
    hoverText: " % landlords",
    group: "users",
    child: "landlord_users_ratio",
  },
  {
    name: "landlord_users_ratio",
    title: "Landlords %",
    type: "percent",
    default_col: true,
    hover: "landlord_users_count",
    hoverText: " landlords",
    group: "users",
    parent: "landlord_users_count",
  },
  {
    name: "landlord_users_active",
    title: "Landlords Active",
    type: "number",
    hover: "landlord_users_activation_rate",
    hoverText: " % active",
    group: "users",
    child: "landlord_users_activation_rate",
  },
  {
    name: "landlord_users_activation_rate",
    title: "Landlords Active %",
    type: "percent",
    hover: "landlord_users_active",
    hoverText: " active",
    group: "users",
    parent: "landlord_users_active",
  },
  {
    name: "buyer_users_count",
    title: "Buyers",
    type: "number",
    hover: "buyer_users_ratio",
    hoverText: " % buyers",
    group: "users",
    child: "buyer_users_ratio",
  },
  {
    name: "buyer_users_ratio",
    title: "Buyers %",
    type: "percent",
    default_col: true,
    hover: "buyer_users_count",
    hoverText: " buyers",
    group: "users",
    parent: "buyer_users_count",
  },
  {
    name: "buyer_users_active",
    title: "Buyers Active",
    type: "number",
    hover: "buyer_users_activation_rate",
    hoverText: " % active",
    group: "users",
    child: "buyer_users_activation_rate",
  },
  {
    name: "buyer_users_activation_rate",
    title: "Buyers Active %",
    type: "percent",
    hover: "buyer_users_active",
    hoverText: " active",
    group: "users",
    parent: "buyer_users_active",
  },
  {
    name: "vendor_users_count",
    title: "Vendors",
    type: "number",
    hover: "vendor_users_ratio",
    hoverText: " % vendors",
    group: "users",
    child: "vendor_users_ratio",
  },
  {
    name: "vendor_users_ratio",
    title: "Vendors %",
    type: "percent",
    default_col: true,
    hover: "vendor_users_count",
    hoverText: " vendors",
    group: "users",
    parent: "vendor_users_count",
  },
  {
    name: "vendor_users_active",
    title: "Vendors Active",
    type: "number",
    hover: "vendor_users_activation_rate",
    hoverText: " % active",
    group: "users",
    child: "vendor_users_activation_rate",
  },
  {
    name: "vendor_users_activation_rate",
    title: "Vendors Active %",
    type: "percent",
    hover: "vendor_users_active",
    hoverText: " active",
    group: "users",
    parent: "vendor_users_active",
  },
  {
    name: "staff_users_count",
    title: "Staff",
    type: "number",
    hover: "staff_users_ratio",
    hoverText: " % staff",
    group: "users",
    child: "staff_users_ratio",
  },
  {
    name: "staff_users_ratio",
    title: "Staff %",
    type: "percent",
    default_col: true,
    hover: "staff_users_count",
    hoverText: " staff",
    group: "users",
    parent: "staff_users_count",
  },
  {
    name: "staff_users_active",
    title: "Staff Active",
    type: "number",
    hover: "staff_users_activation_rate",
    hoverText: " % active",
    group: "users",
    child: "staff_users_activation_rate",
  },
  {
    name: "staff_users_activation_rate",
    title: "Staff Active %",
    type: "percent",
    hover: "staff_users_active",
    hoverText: " active",
    group: "users",
    parent: "staff_users_active",
  },
  {
    name: "student_users_count",
    title: "Student",
    type: "number",
    hover: "student_users_ratio",
    hoverText: " % student",
    group: "users",
    child: "student_users_ratio",
  },
  {
    name: "student_users_ratio",
    title: "Student %",
    type: "percent",
    default_col: true,
    hover: "student_users_count",
    hoverText: " student",
    group: "users",
    parent: "student_users_count",
  },
  {
    name: "student_users_active",
    title: "Student Active",
    type: "number",
    hover: "student_users_activation_rate",
    hoverText: " % active",
    group: "users",
    child: "student_users_activation_rate",
  },
  {
    name: "student_users_activation_rate",
    title: "Student Active %",
    type: "percent",
    hover: "student_users_active",
    hoverText: " active",
    group: "users",
    parent: "student_users_active",
  },

  // BILLING INFORMATION
  {
    name: "should_invoice",
    title: "Should invoice",
    gqlSummary: "BooleanSummary",
    type: "images",
    default_col: true,
    imageAlt: "::#should_invoice::",
    likeNull: [false],
    cellWidth: 70,
    images: {
      false: "/images/false-mark.png",
      true: "/images/check-mark.png",
      default: "/images/false-mark.png",
    },
    group: "billing",
    overview_default: false,
  },
  {
    name: "pricing_model",
    title: "Model",
    gqlSummary: "OptionsCount",
    type: "text",
    likeNull: [],
    hover: "pricing_model",
    hoverText: " billing model",
    cellWidth: 150,
    tdClass: " text-center text-[10.5px] ",
    group: "billing",
  },
  {
    name: "maintenance_fee_monthly_amount",
    title: "Maintenance",
    type: "currency",
    hover: "maintenance_fee_monthly_billing_day",
    hoverText: " billing day",
    cellWidth: 90,
    group: "billing",
    child: "maintenance_fee_monthly_billing_day",
  },
  {
    name: "maintenance_fee_monthly_billing_day",
    title: "Bill day",
    type: "number",
    hover: "maintenance_fee_monthly_amount",
    hoverText: " maintenance fee",
    group: "billing",
    parent: "maintenance_fee_monthly_amount",
  },
  {
    name: "free_premium_users_count",
    title: "Free premium",
    type: "number",
    hover: "free_premium_users_ratio",
    hoverText: " % free premium users",
    group: "billing",
    child: "free_premium_users_ratio",
  },
  {
    name: "free_premium_users_ratio",
    title: "Free premium %",
    type: "percent",
    hover: "free_premium_users_count",
    hoverText: " free premium users",
    group: "billing",
    parent: "free_premium_users_count",
  },
  {
    name: "promo_premium_users_count",
    title: "Promo Prm",
    type: "number",
    hover: "promo_premium_users_ratio",
    hoverText: " % promo premium users",
    group: "billing",
    child: "promo_premium_users_ratio",
  },
  {
    name: "promo_premium_users_ratio",
    title: "Promo Prm %",
    type: "percent",
    hover: "promo_premium_users_count",
    hoverText: " promo premium users",
    group: "billing",
    parent: "promo_premium_users_count",
  },
  {
    name: "has_connected_payment_method",
    title: "£ Connected",
    gqlSummary: "BooleanSummary",
    type: "images",
    imageAlt: "::#has_connected_payment_method::",
    likeNull: [false],
    images: {
      default: "/images/false-mark.png",
      true: "/images/check-mark.png",
    },
    group: "billing",
    // overview_default: '--',
    in_overview: false,
  },
  {
    name: "payment_system",
    title: "£ Method",
    gqlSummary: "OptionsCount",
    type: "images",
    default_col: true,
    imageAlt: "::#payment_system::",
    likeNull: [],
    hover: "payment_system",
    cellWidth: 105,
    images: {
      default: "/images/false-mark.png",
      GoCardless: "/images/gocardless.png",
      Stripe: "/images/stripe.png",
    },
    group: "billing",
    overview_default: "",
  },

  // PAYMENT ISSUES INFORMATION
  {
    name: "has_overdue_unpaid_invoice",
    title: "£ Issue",
    gqlSummary: "BooleanSummary",
    type: "images",
    default_col: true,
    imageAlt: "::#has_overdue_unpaid_invoice::",
    likeNull: [false],
    cellWidth: 70,
    images: {
      default: "",
      true: "/images/warn-mark.png",
    },
    group: "billing",
    overview_default: "--",
  },
  {
    name: "total_overdue_amount",
    title: "£ Overdue",
    type: "currency",
    default_col: true,
    cellWidth: 90,
    group: "billing",
  },
  {
    name: "total_debt_amount",
    title: "£ Debt",
    type: "currency",
    default_col: true,
    cellWidth: 90,
    group: "billing",
  },

  // INVOICES INFORMATION
  {
    name: "last_invoice_amount",
    title: "Last bill",
    type: "currency",
    default_col: true,
    cellWidth: 90,
    hover: "last_invoice_date",
    hoverText: "",
    group: "billing",
  },
  {
    name: "invoices_all_count",
    title: "Bills count",
    type: "number",
    hover: "invoices_all_ratio",
    hoverText: " % billable invoices",
    group: "billing",
    child: "invoices_all_ratio",
  },
  {
    name: "invoices_all_ratio",
    title: "Bills %",
    type: "percent",
    hover: "invoices_all_count",
    hoverText: " billable invoices",
    group: "billing",
    parent: "invoices_all_count",
  },
  {
    name: "invoices_all_sum",
    title: "Bills sum",
    type: "currency",
    hover: "invoices_all_avg",
    hoverText: " average invoiced amount",
    cellWidth: 90,
    group: "billing",
    child: "invoices_all_avg",
  },
  {
    name: "invoices_all_avg",
    title: "Bills Avg",
    type: "currency",
    hover: "invoices_all_sum",
    hoverText: " total invoiced amount",
    cellWidth: 90,
    group: "billing",
    parent: "invoices_all_sum",
  },
  {
    name: "invoices_paid_count",
    title: "Paid count",
    type: "number",
    hover: "invoices_paid_ratio",
    hoverText: " % of invoices paid",
    group: "billing",
    child: "invoices_paid_ratio",
  },
  {
    name: "invoices_paid_ratio",
    title: "Paid %",
    type: "percent",
    hover: "invoices_paid_count",
    hoverText: " invoices paid",
    group: "billing",
    parent: "invoices_paid_count",
  },
  {
    name: "invoices_paid_sum",
    title: "Paid sum",
    type: "currency",
    hover: "invoices_paid_avg",
    hoverText: " average paid amount",
    cellWidth: 90,
    group: "billing",
    child: "invoices_paid_avg",
  },
  {
    name: "invoices_paid_avg",
    title: "Paid avg",
    type: "currency",
    hover: "invoices_paid_sum",
    hoverText: " total paid amount",
    cellWidth: 90,
    group: "billing",
    parent: "invoices_paid_sum",
  },
  {
    name: "invoices_unpaid_count",
    title: "Unpaid count",
    type: "number",
    hover: "invoices_unpaid_ratio",
    hoverText: " % of invoices unpaid",
    group: "billing",
    child: "invoices_unpaid_ratio",
  },
  {
    name: "invoices_unpaid_ratio",
    title: "Unpaid %",
    type: "percent",
    hover: "invoices_unpaid_count",
    hoverText: " invoices unpaid",
    group: "billing",
    parent: "invoices_unpaid_count",
  },
  {
    name: "invoices_unpaid_sum",
    title: "Unpaid sum",
    type: "currency",
    hover: "invoices_unpaid_avg",
    hoverText: " average unpaid amount",
    cellWidth: 90,
    group: "billing",
    child: "invoices_unpaid_avg",
  },
  {
    name: "invoices_unpaid_avg",
    title: "Unpaid avg",
    type: "currency",
    hover: "invoices_unpaid_sum",
    hoverText: " total unpaid amount",
    cellWidth: 90,
    group: "billing",
    parent: "invoices_unpaid_sum",
  },

  // USER COUNT CHANGE OVER TIME
  {
    name: "all_users_variance_1_month",
    title: "Usr < 1m",
    icon: "swap-vertical",
    type: "percent",
    range: true,
    group: "growth",
    indicator: true,
  },
  {
    name: "all_users_variance_3_months",
    title: "Usr < 3m",
    icon: "swap-vertical",
    type: "percent",
    range: true,
    group: "growth",
    indicator: true,
  },
  {
    name: "all_users_variance_6_months",
    title: "Usr < 6m",
    icon: "swap-vertical",
    type: "percent",
    default_col: true,
    range: true,
    group: "growth",
    indicator: true,
  },
  {
    name: "all_users_variance_12_months",
    title: "Usr < 12m",
    icon: "swap-vertical",
    type: "percent",
    range: true,
    group: "growth",
    indicator: true,
  },

  // PAYMENT AMOUNT CHANGE OVER TIME
  {
    name: "payment_variance_1_month",
    title: "£ < 1m",
    icon: "swap-vertical",
    type: "percent",
    range: true,
    group: "growth",
    indicator: true,
  },
  {
    name: "payment_variance_3_months",
    title: "£ < 3m",
    icon: "swap-vertical",
    type: "percent",
    range: true,
    group: "growth",
    indicator: true,
  },
  {
    name: "payment_variance_6_months",
    title: "£ < 6m",
    icon: "swap-vertical",
    type: "percent",
    default_col: true,
    range: true,
    group: "growth",
    indicator: true,
  },
  {
    name: "payment_variance_12_months",
    title: "£ < 12m",
    icon: "swap-vertical",
    type: "percent",
    range: true,
    group: "growth",
    indicator: true,
  },

  // NPS INFORMATION
  {
    name: "net_promoted_score",
    title: "NPS",
    type: "nps",
    range: true,
    default_col: true,
    likeNull: [],
    hover: "nps_count",
    hoverText: " answers",
    group: "nps",
  },
  {
    name: "nps_count",
    title: "NPS Ans",
    type: "number",
    hover: "nps_ratio",
    hoverText: " % of users",
    group: "nps",
    child: "nps_ratio",
  },
  {
    name: "nps_ratio",
    title: "NPS Ans %",
    type: "percent",
    default_col: true,
    hover: "nps_count",
    hoverText: " answers",
    group: "nps",
    parent: "nps_count",
  },
  {
    name: "nps_promoters_count",
    title: "Promoters",
    type: "number",
    hover: "nps_promoters_ratio",
    hoverText: " % promoters",
    group: "nps",
    child: "nps_promoters_ratio",
  },
  {
    name: "nps_promoters_ratio",
    title: "Promoters %",
    type: "percent",
    hover: "nps_promoters_count",
    hoverText: " promoters",
    group: "nps",
    parent: "nps_promoters_count",
  },
  {
    name: "nps_passives_count",
    title: "Passives",
    type: "number",
    hover: "nps_passives_ratio",
    hoverText: " % passives",
    group: "nps",
    child: "nps_passives_ratio",
  },
  {
    name: "nps_passives_ratio",
    title: "Passives %",
    type: "percent",
    hover: "nps_promoters_count",
    hoverText: " passives",
    group: "nps",
    parent: "nps_passives_count",
  },
  {
    name: "nps_detractors_count",
    title: "Detractors",
    type: "number",
    hover: "nps_detractors_ratio",
    hoverText: " % detractors",
    group: "nps",
    child: "nps_detractors_ratio",
  },
  {
    name: "nps_detractors_ratio",
    title: "Detractors %",
    type: "percent",
    hover: "nps_promoters_count",
    hoverText: " detractors",
    group: "nps",
    parent: "nps_detractors_count",
  },

  // BUY TIME INFORMATION
  {
    name: "buyer_date_count",
    title: "Buy Ans",
    type: "number",
    hover: "buyer_date_ratio",
    hoverText: " % of users",
    group: "buy_sell_date",
    child: "buyer_date_ratio",
  },
  {
    name: "buyer_date_ratio",
    title: "Buy Ans %",
    type: "percent",
    default_col: true,
    hover: "buyer_date_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_count",
  },
  {
    name: "buyer_date_past_count",
    title: "Buy Past",
    type: "number",
    hover: "buyer_date_past_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "buyer_date_past_ratio",
  },
  {
    name: "buyer_date_past_ratio",
    title: "Buy Past %",
    type: "percent",
    default_col: true,
    hover: "buyer_date_past_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_past_count",
  },
  {
    name: "buyer_date_6_months_count",
    title: "Buy < 6m",
    type: "number",
    hover: "buyer_date_6_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "buyer_date_6_months_ratio",
  },
  {
    name: "buyer_date_6_months_ratio",
    title: "Buy < 6m %",
    type: "percent",
    default_col: true,
    hover: "buyer_date_6_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_6_months_count",
  },
  {
    name: "buyer_date_12_months_count",
    title: "Buy < 12m",
    type: "number",
    hover: "buyer_date_12_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "buyer_date_12_months_ratio",
  },
  {
    name: "buyer_date_12_months_ratio",
    title: "Buy < 12m %",
    type: "percent",
    hover: "buyer_date_12_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_12_months_count",
  },
  {
    name: "buyer_date_18_months_count",
    title: "Buy < 18m",
    type: "number",
    hover: "buyer_date_18_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "buyer_date_18_months_ratio",
  },
  {
    name: "buyer_date_18_months_ratio",
    title: "Buy < 18m %",
    type: "percent",
    hover: "buyer_date_18_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_18_months_count",
  },
  {
    name: "buyer_date_24_months_count",
    title: "Buy < 2y",
    type: "number",
    hover: "buyer_date_24_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "buyer_date_24_months_ratio",
  },
  {
    name: "buyer_date_24_months_ratio",
    title: "Buy < 2y %",
    type: "percent",
    hover: "buyer_date_24_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_24_months_count",
  },
  {
    name: "buyer_date_36_months_count",
    title: "Buy < 3y",
    type: "number",
    hover: "buyer_date_36_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "buyer_date_36_months_ratio",
  },
  {
    name: "buyer_date_36_months_ratio",
    title: "Buy < 3y %",
    type: "percent",
    hover: "buyer_date_36_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_36_months_count",
  },
  {
    name: "buyer_date_48_months_count",
    title: "Buy < 4y",
    type: "number",
    hover: "buyer_date_48_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "buyer_date_48_months_ratio",
  },
  {
    name: "buyer_date_48_months_ratio",
    title: "Buy < 4y %",
    type: "percent",
    hover: "buyer_date_48_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_48_months_count",
  },
  {
    name: "buyer_date_60_months_count",
    title: "Buy < 5y",
    type: "number",
    hover: "buyer_date_60_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "buyer_date_60_months_ratio",
  },
  {
    name: "buyer_date_60_months_ratio",
    title: "Buy < 5y %",
    type: "percent",
    hover: "buyer_date_60_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_60_months_count",
  },
  {
    name: "buyer_date_never_count",
    title: "Buy Never",
    type: "number",
    hover: "buyer_date_never_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "buyer_date_never_ratio",
  },
  {
    name: "buyer_date_never_ratio",
    title: "Buy Never %",
    type: "percent",
    hover: "buyer_date_never_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "buyer_date_never_count",
  },

  // SELL TIME INFORMATION
  {
    name: "seller_date_count",
    title: "Sell Ans",
    type: "number",
    hover: "seller_date_ratio",
    hoverText: " % of users",
    group: "buy_sell_date",
    child: "seller_date_ratio",
  },
  {
    name: "seller_date_ratio",
    title: "Sell Ans %",
    type: "percent",
    default_col: true,
    hover: "seller_date_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_count",
  },
  {
    name: "seller_date_past_count",
    title: "Sell Past",
    type: "number",
    hover: "seller_date_past_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "seller_date_past_ratio",
  },
  {
    name: "seller_date_past_ratio",
    title: "Sell Past %",
    type: "percent",
    default_col: true,
    hover: "seller_date_past_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_past_count",
  },
  {
    name: "seller_date_6_months_count",
    title: "Sell < 6m",
    type: "number",
    hover: "seller_date_6_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "seller_date_6_months_ratio",
  },
  {
    name: "seller_date_6_months_ratio",
    title: "Sell < 6m %",
    type: "percent",
    default_col: true,
    hover: "seller_date_6_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_6_months_count",
  },
  {
    name: "seller_date_12_months_count",
    title: "Sell < 12m",
    type: "number",
    hover: "seller_date_12_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "seller_date_12_months_ratio",
  },
  {
    name: "seller_date_12_months_ratio",
    title: "Sell < 12m %",
    type: "percent",
    hover: "seller_date_12_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_12_months_count",
  },
  {
    name: "seller_date_18_months_count",
    title: "Sell < 18m",
    type: "number",
    hover: "seller_date_18_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "seller_date_18_months_ratio",
  },
  {
    name: "seller_date_18_months_ratio",
    title: "Sell < 18m %",
    type: "percent",
    hover: "seller_date_18_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_18_months_count",
  },
  {
    name: "seller_date_24_months_count",
    title: "Sell < 2y",
    type: "number",
    hover: "seller_date_24_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "seller_date_24_months_ratio",
  },
  {
    name: "seller_date_24_months_ratio",
    title: "Sell < 2y %",
    type: "percent",
    hover: "seller_date_24_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_24_months_count",
  },
  {
    name: "seller_date_36_months_count",
    title: "Sell < 3y",
    type: "number",
    hover: "seller_date_36_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "seller_date_36_months_ratio",
  },
  {
    name: "seller_date_36_months_ratio",
    title: "Sell < 3y %",
    type: "percent",
    hover: "seller_date_36_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_36_months_count",
  },
  {
    name: "seller_date_48_months_count",
    title: "Sell < 4y",
    type: "number",
    hover: "seller_date_48_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "seller_date_48_months_ratio",
  },
  {
    name: "seller_date_48_months_ratio",
    title: "Sell < 4y %",
    type: "percent",
    hover: "seller_date_48_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_48_months_count",
  },
  {
    name: "seller_date_60_months_count",
    title: "Sell < 5y",
    type: "number",
    hover: "seller_date_60_months_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "seller_date_60_months_ratio",
  },
  {
    name: "seller_date_60_months_ratio",
    title: "Sell < 5y %",
    type: "percent",
    hover: "seller_date_60_months_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_60_months_count",
  },
  {
    name: "seller_date_never_count",
    title: "Sell Never",
    type: "number",
    hover: "seller_date_never_ratio",
    hoverText: " % of answers",
    group: "buy_sell_date",
    child: "seller_date_never_ratio",
  },
  {
    name: "seller_date_never_ratio",
    title: "Sell Never %",
    type: "percent",
    hover: "seller_date_never_count",
    hoverText: " users",
    group: "buy_sell_date",
    parent: "seller_date_never_count",
  },

  // GENDER INFORMATION
  {
    name: "gender_count",
    title: "Gend Ans",
    type: "number",
    hover: "gender_ratio",
    hoverText: " % of users",
    group: "demographics",
    child: "gender_ratio",
  },
  {
    name: "gender_ratio",
    title: "Gend Ans %",
    type: "percent",
    hover: "gender_count",
    hoverText: " users",
    group: "demographics",
    parent: "gender_count",
  },
  {
    name: "gender_male_count",
    title: "Male",
    type: "number",
    hover: "gender_male_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "gender_male_ratio",
  },
  {
    name: "gender_male_ratio",
    title: "Male %",
    type: "percent",
    hover: "gender_male_count",
    hoverText: " users",
    group: "demographics",
    parent: "gender_male_count",
  },
  {
    name: "gender_female_count",
    title: "Female",
    type: "number",
    hover: "gender_female_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "gender_female_ratio",
  },
  {
    name: "gender_female_ratio",
    title: "Female %",
    type: "percent",
    hover: "gender_female_count",
    hoverText: " users",
    group: "demographics",
    parent: "gender_female_count",
  },
  {
    name: "gender_prefer_not_to_say_count",
    title: "Gend No",
    type: "number",
    hover: "gender_prefer_not_to_say_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "gender_prefer_not_to_say_ratio",
  },
  {
    name: "gender_prefer_not_to_say_ratio",
    title: "Gend No %",
    type: "percent",
    hover: "gender_prefer_not_to_say_count",
    hoverText: " users",
    group: "demographics",
    parent: "gender_prefer_not_to_say_count",
  },

  // INCOME
  {
    name: "household_income_count",
    title: "Income Ans",
    type: "number",
    hover: "household_income_ratio",
    hoverText: " % of users",
    group: "demographics",
    child: "household_income_ratio",
  },
  {
    name: "household_income_ratio",
    title: "Income Ans %",
    type: "percent",
    hover: "household_income_count",
    hoverText: " users",
    group: "demographics",
    parent: "household_income_count",
  },
  {
    name: "household_income_less_than_20k_count",
    title: "Inc < £20k",
    type: "number",
    hover: "household_income_less_than_20k_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "household_income_less_than_20k_ratio",
  },
  {
    name: "household_income_less_than_20k_ratio",
    title: "Inc < £20k %",
    type: "percent",
    hover: "household_income_less_than_20k_count",
    hoverText: " users",
    group: "demographics",
    parent: "household_income_less_than_20k_count",
  },
  {
    name: "household_income_20k_40k_count",
    title: "Inc < £40k",
    type: "number",
    hover: "household_income_20k_40k_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "household_income_20k_40k_ratio",
  },
  {
    name: "household_income_20k_40k_ratio",
    title: "Inc < £40k %",
    type: "percent",
    hover: "household_income_20k_40k_count",
    hoverText: " users",
    group: "demographics",
    parent: "household_income_20k_40k_count",
  },
  {
    name: "household_income_40k_60k_count",
    title: "Inc < £60k",
    type: "number",
    hover: "household_income_40k_60k_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "household_income_40k_60k_ratio",
  },
  {
    name: "household_income_40k_60k_ratio",
    title: "Inc < £60k %",
    type: "percent",
    hover: "household_income_40k_60k_count",
    hoverText: " users",
    group: "demographics",
    parent: "household_income_40k_60k_count",
  },
  {
    name: "household_income_60k_80k_count",
    title: "Inc < £80k",
    type: "number",
    hover: "household_income_60k_80k_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "household_income_60k_80k_ratio",
  },
  {
    name: "household_income_60k_80k_ratio",
    title: "Inc < £80k %",
    type: "percent",
    hover: "household_income_60k_80k_count",
    hoverText: " users",
    group: "demographics",
    parent: "household_income_60k_80k_count",
  },
  {
    name: "household_income_80k_100k_count",
    title: "Inc < £100k",
    type: "number",
    hover: "household_income_80k_100k_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "household_income_80k_100k_ratio",
  },
  {
    name: "household_income_80k_100k_ratio",
    title: "Inc < £100k %",
    type: "percent",
    hover: "household_income_80k_100k_count",
    hoverText: " users",
    group: "demographics",
    parent: "household_income_80k_100k_count",
  },
  {
    name: "household_income_more_than_100k_count",
    title: "Inc > £100k",
    type: "number",
    hover: "household_income_more_than_100k_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "household_income_more_than_100k_ratio",
  },
  {
    name: "household_income_more_than_100k_ratio",
    title: "Inc > £100k %",
    type: "percent",
    hover: "household_income_more_than_100k_count",
    hoverText: " users",
    group: "demographics",
    parent: "household_income_more_than_100k_count",
  },
  {
    name: "household_income_prefer_not_to_say_count",
    title: "Income No",
    type: "number",
    hover: "household_income_prefer_not_to_say_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "household_income_prefer_not_to_say_ratio",
  },
  {
    name: "household_income_prefer_not_to_say_ratio",
    title: "Income No %",
    type: "percent",
    hover: "household_income_prefer_not_to_say_count",
    hoverText: " users",
    group: "demographics",
    parent: "household_income_prefer_not_to_say_count",
  },
  {
    name: "user_age_count",
    title: "Age Ans",
    type: "number",
    hover: "user_age_ratio",
    hoverText: " % of users",
    group: "demographics",
    child: "user_age_ratio",
  },
  {
    name: "user_age_ratio",
    title: "Age Ans %",
    type: "percent",
    hover: "user_age_count",
    hoverText: " users",
    group: "demographics",
    parent: "user_age_count",
  },
  {
    name: "user_age_24_count",
    title: "Age < 24",
    type: "number",
    hover: "user_age_24_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "user_age_24_ratio",
  },
  {
    name: "user_age_24_ratio",
    title: "Age < 24 %",
    type: "percent",
    hover: "user_age_24_count",
    hoverText: " users",
    group: "demographics",
    parent: "user_age_24_count",
  },
  {
    name: "user_age_25_34_count",
    title: "Age 25-34",
    type: "number",
    hover: "user_age_25_34_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "user_age_25_34_ratio",
  },
  {
    name: "user_age_25_34_ratio",
    title: "Age 25-34 %",
    type: "percent",
    hover: "user_age_25_34_count",
    hoverText: " users",
    group: "demographics",
    parent: "user_age_25_34_count",
  },
  {
    name: "user_age_35_44_count",
    title: "Age 35-44",
    type: "number",
    hover: "user_age_35_44_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "user_age_35_44_ratio",
  },
  {
    name: "user_age_35_44_ratio",
    title: "Age 35-44 %",
    type: "percent",
    hover: "user_age_35_44_count",
    hoverText: " users",
    group: "demographics",
    parent: "user_age_35_44_count",
  },
  {
    name: "user_age_45_54_count",
    title: "Age 45-54",
    type: "number",
    hover: "user_age_45_54_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "user_age_45_54_ratio",
  },
  {
    name: "user_age_45_54_ratio",
    title: "Age 45-54 %",
    type: "percent",
    hover: "user_age_45_54_count",
    hoverText: " users",
    group: "demographics",
    parent: "user_age_45_54_count",
  },
  {
    name: "user_age_55_64_count",
    title: "Age 55-64",
    type: "number",
    hover: "user_age_55_64_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "user_age_55_64_ratio",
  },
  {
    name: "user_age_55_64_ratio",
    title: "Age 55-64 %",
    type: "percent",
    hover: "user_age_55_64_count",
    hoverText: " users",
    group: "demographics",
    parent: "user_age_55_64_count",
  },
  {
    name: "user_age_65_and_over_count",
    title: "Age 65 +",
    type: "number",
    hover: "user_age_65_and_over_ratio",
    hoverText: " % of answers",
    group: "demographics",
    child: "user_age_65_and_over_ratio",
  },
  {
    name: "user_age_65_and_over_ratio",
    title: "Age 65 + %",
    type: "percent",
    hover: "user_age_65_and_over_count",
    hoverText: " users",
    group: "demographics",
    parent: "user_age_65_and_over_count",
  },

  // VIEW CLIENT BUTTON
  {
    name: "view",
    title: "View",
    default_col: true,
    text: "View",
    sort: false,
    click: false,
    filter: false,
    hide: false,
    inQuery: false,
    type: "view",
    scellWidth: 70,
    thClass: " pr-2 text-center ",
    tdClass:
      "pr-2 border-l-[#ecdaf5] border-x-2 text-[12px] w-fulls text-center ",
    in_overview: false,
  },
];

const getGqlSummary = (type = null) => {
  return type === "text"
    ? "TextCount"
    : type === "date"
    ? "DateSummary"
    : type === "number"
    ? "NumberSummary"
    : type === "percent"
    ? "PercentageSummary"
    : type === "score"
    ? "ScoreSummary"
    : type === "nps"
    ? "ScoreSummary"
    : type === "currency"
    ? "CurrencySummary"
    : type === "boolean"
    ? "BooleanSummary"
    : type === "options"
    ? "OptionsCount"
    : null;
};

export const clientFieldGroups = [
  { name: "company", icon: "home", title: "Company" },
  { name: "activity", icon: "account-clock", title: "Activity" },
  { name: "service", icon: "draw-pen", title: "Service" },
  { name: "users", icon: "account-group", title: "Users" },
  { name: "billing", icon: "credit-card-outline", title: "Billing" },
  { name: "growth", icon: "chart-timeline-variant", title: "Growth" },
  { name: "nps", icon: "star", title: "NPS" },
  { name: "buy_sell_date", icon: "sign-real-estate", title: "Buy & sell" },
  { name: "demographics", icon: "target-account", title: "Profiles" },
];

export const clientQueryFields = () => {
  return clientSchema.filter((item) => item.inQuery !== false && item.name);
};

export const clientSummaryFields = () => {
  return clientSchema
    .filter(
      (item) =>
        item.inQuery !== false &&
        item.gqlSummary !== false &&
        item.gqlSummary !== null &&
        item.name
    )
    .map((item) => {
      return {
        gqlSummary: getGqlSummary(item.type),
        ...item,
      };
    });
};

export const clientTableFields = () => {
  return clientSchema
    .filter((item) => item.in_table !== false)
    .map((item) => {
      return {
        title: null,
        icon: null,
        in_table: true,
        default_col: false,
        sort: true,
        click: true,
        filter: true,
        hide: true,
        table_summary: true,
        range: false,
        cellWidth: 80,
        thClass: "",
        tdClass: "",
        hover: null,
        hoverText: null,
        group: null,
        gqlSummary: getGqlSummary(item.type),
        inQuery: true,
        summaryToggle: false,
        likeNull: [0, ""],
        in_overview: true,
        ...item,
      };
    });
};

export const clientTableDefaultCols = () => {
  return clientSchema
    .filter((item) => item.default_col)
    .map((item) => item.name);
};

export const clientOverviewFields = () => {
  return clientSchema
    .filter((item) => item.gqlSummary !== false && item.in_overview !== false)
    .map((item) => {
      return {
        in_overview: true,
        title: null,
        icon: null,
        overview_default: 0,
        parent: null,
        child: null,
        group: null,
        gqlSummary: getGqlSummary(item.type),
        inQuery: true,
        likeNull: [0, ""],
        indicator: false,
        ...item,
      };
    });
};

export const clientOverviewDefaults = () => {
  let defaults = {};
  clientSchema
    .filter((item) => item.in_overview)
    .forEach((item) => {
      defaults[item.name] = item.overview_default;
    });
  return defaults;
};
