import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { userOrder } from "./../../graphql/queries/reportQueries";
import {
  deleteOrder,
  placeOrder,
} from "./../../graphql/mutations/orderMutations";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import { REPORTS_USER_ORDER_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import {
  FormControls,
  CancelButton,
  TEditButton,
  InlineControls,
  DeleteButton,
  StdButton,
  BasicControl,
} from "./../../moose-ui/Controls";
import { Heading, ColorLabel } from "./../../moose-ui/Typography";

import {
  TableContainer,
  TableView,
  THeading,
  TContent,
  THCol,
  TRow,
  TCol,
} from "./../../moose-ui/DataTable";
import { useState } from "react";
import {
  FieldBlock,
  renderObject,
  renderLink,
  renderList,
  renderBoolean,
  renderOrderStatus,
  SpaceBlock,
  renderOrderPaymentStatus,
  renderOrderState,
} from "../TilloBrandListApprovalPage";
import { render } from "@testing-library/react";
import { ConfirmActionModal } from "../../moose-ui/Modals";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../moose-ui/Notifications";
import useModal from "../../hooks/modal";
import useNotification from "../../hooks/notifications";

const USER_ORDER_DETAILS = gql(userOrder);
const DELETE_ORDER = gql(deleteOrder);
const PLACE_ORDER = gql(placeOrder);

const InfoSection = ({ children, title = "", description = "" }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-7">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1  text-sm text-gray-500">{description}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
          {children}
        </dl>
      </div>
    </div>
  );
};

const TableSection = ({ children, title = "", description = "" }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-7">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1  text-sm text-gray-500">{description}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
          {children}
        </dl>
      </div>
    </div>
  );
};

const UserOrder = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.UserOrdersReport);
  const { goBack, goTo } = useBaseNavigation(REPORTS_USER_ORDER_MODEL_NAME);
  const { id } = useParams();
  const [order, setOrder] = useState(false);

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    loading,
    error,
    refetch: refetchData,
  } = useQuery(USER_ORDER_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      setOrder({ ...res.order });
    },
  });

  const [deleteOrder] = useMutation(DELETE_ORDER, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Order deleted",
        message: "Successfully deleted current order",
        secondsToDisplay: 1,
      });
      setTimeout(() => goBack(), 2000);
    },
    onError: (e) => {
      if (
        typeof e.graphQLErrors != "undefined" &&
        typeof e.graphQLErrors[0] != "undefined"
      ) {
        displayErrorNotification({
          heading: e.graphQLErrors[0].message,
          message: e.graphQLErrors[0].debugMessage,
          secondsToDisplay: 4,
        });
      } else {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to issue current order",
          secondsToDisplay: 2,
        });
      }
    },
  });

  const [placeOrderRaw] = useMutation(PLACE_ORDER, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Order placed",
        message: "Successfully issued current order to the Tillo",
        secondsToDisplay: 2,
      });
      refetchData();
    },
    onError: (e) => {
      if (
        typeof e.graphQLErrors != "undefined" &&
        typeof e.graphQLErrors[0] != "undefined"
      ) {
        displayErrorNotification({
          heading: e.graphQLErrors[0].message,
          message: e.graphQLErrors[0].debugMessage,
          secondsToDisplay: 4,
        });
      } else {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to issue current order",
          secondsToDisplay: 2,
        });
      }
    },
  });

  const confirmDeletion = () => {
    showModal({
      heading: "Confirm Order Deletion",
      message:
        "Are you sure you want to delete this order from the Vaboo Application?",
      mood: "Danger",
      confirmButtonLabel: "Yes, I confirm. Delete",
      onConfirm: () => {
        closeModal();
        handleDeletion();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeletion = () => {
    deleteOrder({ variables: { id } });
  };

  const confirmPlaceOrder = () => {
    showModal({
      heading: "Confirm Place Order (Force Issue)",
      message: "Are you sure you want to make a raw place of this order?",
      mood: "Danger",
      confirmButtonLabel: "Yes, I confirm. Place Order",
      onConfirm: () => {
        closeModal();
        handlePlaceOrderRaw();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handlePlaceOrderRaw = () => {
    placeOrderRaw({ variables: { id } });
  };

  if (error) return <div>Error...</div>;
  if (!order) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="User Order Details" />
        <InfoSection
          title="User & Client"
          description="Brief informantion about user who made this order and user's client"
        >
          <FieldBlock label="User Id" value={order.user.id} />
          <FieldBlock label="User Email" value={order.user.email} />
          <FieldBlock
            label="Full Name"
            value={`${order.user.firstname} ${order.user.lastname}`}
          />
          <FieldBlock label="Telephone" value={`${order.user.telephone}`} />

          <FieldBlock label="Client Id" value={order.client.id} />
          <FieldBlock label="Client Email" value={order.client.email} />
          <FieldBlock label="Account Name" value={order.client.account_name} />
        </InfoSection>
        <InfoSection
          title="Order, Payment and Discounts"
          description="Full informantion about this order, related payment, amounts and discounts"
        >
          <FieldBlock label="Order Id" value={order.id} />
          <FieldBlock label="Provider" value={order.reward_card_provider} />
          <FieldBlock label="Payment Provider" value={order.payment_provider} />
          <SpaceBlock width={1} />

          <FieldBlock
            label="Order Status"
            value={order.order_status}
            valueRenderFunc={renderOrderStatus}
          />
          <FieldBlock label="Order Date" value={order.created_at} />
          <FieldBlock
            label="Final Response At"
            value={order.order_final_response_at}
          />
          <SpaceBlock width={1} />

          <FieldBlock
            label="Payment Status"
            value={order.bank_payment_status}
            valueRenderFunc={renderOrderPaymentStatus}
          />
          <FieldBlock label="Payment Url" value={order.bank_payment_url} />
          <FieldBlock
            label="Payment Started At"
            value={order.bank_payment_started_at}
          />
          <FieldBlock
            label="Payment Completed At"
            value={order.bank_payment_completed_at}
          />

          <FieldBlock
            label="Requested Voucher Amount, £"
            value={order.requested_voucher_amount}
          />
          <FieldBlock label="Discount, %" value={order.discount} />
          <FieldBlock
            label="Discount Adjustment, %"
            value={order.discount_adjustment}
          />
          <FieldBlock label="User Saving, %" value={order.discount_for_user} />

          <FieldBlock
            label="User Payment Amount, £"
            value={order.user_payment_amount}
          />
          <FieldBlock
            label="Vaboo Payment Amount, £"
            value={order.vaboo_payment_amount}
          />
        </InfoSection>
        <InfoSection title="Offer" description="Details about offer order">
          <FieldBlock label="Offer Id" value={order.initial_offer.id} />
          <FieldBlock label="Offer Slug" value={order.initial_offer.slug} />
          <FieldBlock
            label="Merchant Slug"
            value={order.initial_offer.merchant_slug}
          />
          <FieldBlock
            label="Last Updated"
            value={order.initial_offer.extendedData.last_updated}
            valueRenderFunc={(v) =>
              moment(order.initial_offer.extendedData.last_updated).format(
                "YYYY-MM-DD"
              )
            }
          />

          <FieldBlock
            label="Transaction Types"
            value={order.initial_offer.extendedData.transaction_types}
            valueRenderFunc={renderList}
          />
          <FieldBlock
            label="Delivery Methods"
            value={order.initial_offer.extendedData.delivery_methods}
            valueRenderFunc={renderList}
          />
          <FieldBlock
            label="GC Pool"
            value={order.initial_offer.extendedData.gc_pool}
            valueRenderFunc={renderBoolean}
          />
          <FieldBlock
            label="Async Only"
            value={order.initial_offer.extendedData.async_only}
            valueRenderFunc={renderBoolean}
          />

          <FieldBlock
            label="Digital Face Value Limits (Provider)"
            value={order.initial_offer.extendedData.digital_face_value_limits}
            valueRenderFunc={renderObject}
          />
          <FieldBlock
            label="Digital Face Value Limits (Vaboo)"
            value={order.initial_offer.digital_face_value_limits}
            valueRenderFunc={renderObject}
          />
          <SpaceBlock width={2} />

          <FieldBlock
            label="Digital General Restrictions"
            value={
              order.initial_offer.extendedData.detail
                .digital_general_restrictions
            }
          />
          <FieldBlock
            label="Online Order Restrictions"
            value={
              order.initial_offer.extendedData.detail.online_order_restrictions
            }
          />
          <FieldBlock
            label="In-store Order Restrictions"
            value={
              order.initial_offer.extendedData.detail.instore_order_restrictions
            }
          />

          <FieldBlock
            label="Description"
            width={4}
            value={order.initial_offer.extendedData.detail.description}
          />

          <FieldBlock
            label="Balance Enquiry Url"
            value={order.initial_offer.extendedData.detail.balance_enquiry_url}
            width={4}
            valueRenderFunc={renderLink}
          />

          <FieldBlock
            label="Barcode"
            value={order.initial_offer.extendedData.detail.barcode}
          />
          <FieldBlock
            label="Expiry"
            value={order.initial_offer.extendedData.detail.expiry}
          />
          <FieldBlock
            label="Website Url"
            value={order.initial_offer.extendedData.detail.website_url}
            width={2}
            valueRenderFunc={renderLink}
          />

          <FieldBlock
            label="Redemption Instructions Url"
            value={
              order.initial_offer.extendedData.detail
                .redemption_instructions_url
            }
            valueRenderFunc={renderLink}
          />
          <FieldBlock
            label="Redemption Methods"
            value={order.initial_offer.extendedData.detail.redemption_methods}
            valueRenderFunc={renderList}
          />
          <FieldBlock
            label="Terms And Conditions Copy"
            value={
              order.initial_offer.extendedData.detail.terms_and_conditions_copy
            }
          />
          <FieldBlock
            label="Terms And Conditions Url"
            value={
              order.initial_offer.extendedData.detail.terms_and_conditions_url
            }
            valueRenderFunc={renderLink}
          />

          <FieldBlock
            label="Denominations"
            value={order.initial_offer.extendedData.denominations}
            valueRenderFunc={renderList}
          />
          <FieldBlock
            label="Digital Denominations"
            value={order.initial_offer.extendedData.digital_denominations}
            valueRenderFunc={renderList}
          />
        </InfoSection>

        <TableSection
          title="Other Orders by this User"
          description="List of other orders made by this user"
        >
          {(!order.other_orders || order.other_orders.length === 0) && (
            <span>This user has no other orders</span>
          )}
          {order.other_orders && order.other_orders.length > 0 && (
            <TableContainer>
              <TableView>
                <THeading>
                  <TRow>
                    <THCol name="order_id" value="Order Id" />
                    <THCol name="brand_slug" value="Brand" />
                    <THCol name="offer_title" value="Offer" />
                    <THCol
                      name="requested_voucher_amount"
                      value="Voucher Amount, £"
                    />
                    <THCol name="payment_status" value="Payment Status" />
                    <THCol name="order_status" value="Order Status" />
                    <THCol name="order_state" value="State" />
                    <THCol name="order_date" value="Order Date" />
                    <THCol />
                  </TRow>
                </THeading>
                <TContent>
                  {order.other_orders.map((order, idx) => (
                    <TRow key={idx}>
                      <TCol value={order.id} />
                      <TCol value={order.brand_slug} />
                      <TCol value={order.initial_offer.slug} />
                      <TCol>{order.requested_voucher_amount}</TCol>
                      <TCol>
                        {renderOrderPaymentStatus(order.bank_payment_status)}
                      </TCol>
                      <TCol>{renderOrderStatus(order.order_status)}</TCol>
                      <TCol>{renderOrderState(order.state)}</TCol>
                      <TCol>{order.created_at}</TCol>
                      <TCol>
                        <TEditButton
                          onClick={() => {
                            goTo(
                              `/${REPORTS_USER_ORDER_MODEL_NAME}/${order.id}`
                            );
                          }}
                          label="View"
                        />
                      </TCol>
                    </TRow>
                  ))}
                </TContent>
              </TableView>
            </TableContainer>
          )}
        </TableSection>

        <TableSection
          title="Fire Webhooks"
          description="List of Fire webhooks connected with this order"
        >
          {(!order.fire_webhooks || order.fire_webhooks.length === 0) && (
            <span>No webhooks</span>
          )}
          {order.fire_webhooks && order.fire_webhooks.length > 0 && (
            <TableContainer>
              <TableView>
                <THeading>
                  <TRow>
                    <THCol name="id" value="Id" />
                    <THCol name="transaction_type" value="Transaction Type" />
                    <THCol name="status" value="Status" />
                    <THCol name="received_at" value="Received At" />
                  </TRow>
                </THeading>
                <TContent>
                  {order.fire_webhooks.map((wh, idx) => (
                    <TRow key={idx}>
                      <TCol value={wh.id} />
                      <TCol value={wh.transaction_type} />
                      <TCol value={wh.status} />
                      <TCol value={wh.created_at} />
                    </TRow>
                  ))}
                </TContent>
              </TableView>
            </TableContainer>
          )}
        </TableSection>

        <TableSection
          title="Fire Payment Check Logs"
          description="List of API call response for orders that have only one webhook (in PENDING state)"
        >
          {(!order.fire_payment_check_logs ||
            order.fire_payment_check_logs.length === 0) && (
            <span>No payment check logs</span>
          )}
          {order.fire_payment_check_logs &&
            order.fire_payment_check_logs.length > 0 && (
              <TableContainer>
                <TableView>
                  <THeading>
                    <TRow>
                      <THCol name="id" value="Id" />
                      <THCol name="transaction_type" value="Transaction Type" />
                      <THCol name="status" value="Status" />
                      <THCol name="checked_at" value="Checked At" />
                    </TRow>
                  </THeading>
                  <TContent>
                    {order.fire_payment_check_logs.map((fpcl, idx) => (
                      <TRow key={idx}>
                        <TCol value={fpcl.id} />
                        <TCol value={fpcl.transaction_type} />
                        <TCol value={fpcl.status} />
                        <TCol value={fpcl.created_at} />
                      </TRow>
                    ))}
                  </TContent>
                </TableView>
              </TableContainer>
            )}
        </TableSection>
        <Row>
          <Col width={9}>
            <InlineControls align="start">
              <BasicControl onClick={() => {}} label="Check Order Status" />
              &nbsp; &nbsp;
              {hasAccess(Permissions.Mutation.PlaceOrderRaw) &&
                order.order_status !== "Resolved" && (
                  <DeleteButton
                    onClick={confirmPlaceOrder}
                    title="Place Order (Force Issue)"
                  />
                )}
              {hasAccess(Permissions.Mutation.DeleteOrder) &&
                order.order_status !== "Resolved" && (
                  <React.Fragment>
                    &nbsp; &nbsp;
                    <DeleteButton
                      onClick={confirmDeletion}
                      title="Delete Order"
                    />
                  </React.Fragment>
                )}
            </InlineControls>
          </Col>
          <Col width={3}>
            <InlineControls align="end">
              <CancelButton onClick={goBack} label="Go back" />
            </InlineControls>
          </Col>
        </Row>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default UserOrder;
