import React, { useState } from "react";

const numberWithCommas = (value) => {
  return value.toLocaleString("en-US", { maximumFractionDigits: 2 });
};
const ColorText = ({ value = 0, text = "", lum = 700, inverted = false }) => {
  const normalVal = parseFloat(value) * (inverted ? -1 : 1);
  return (
    <span
      className={`text-${
        normalVal > 0 ? "emerald" : normalVal < 0 ? "rose" : "slate-400 "
      }-${lum} whitespace-nowrap`}
    >
      {text}
      <span
        className={`
              pl-0.5 mdi mr-[-1px]
              mdi-${
                parseFloat(value) > 0
                  ? "arrow-up-thick "
                  : parseFloat(value) < 0
                  ? "arrow-down-thick "
                  : "approximately-equal "
              }
            `}
      ></span>
    </span>
  );
};

const UserGrowthTable = ({
  memberBreakdownMonthChartData = null,
  memberGrowthMonthChartData = null,
  memberLabelsMonthChartData = null,
  memberEngagementMonthChartData = null,
  memberKpiData = null,
}) => {
  return (
    <React.Fragment>
      <div className="mt-6 pb-2">
        <table className="select-alls border-collapse">
          <thead className="text-[11px] text-slate-500 text-left font-medium whitespace-nowrap">
            <tr>
              <th className="bg-slate-600 text-white py-1 px-2 border-[1px] border-gray-400 border-b-[1px] border-b-gray-400">
                EOM Members
              </th>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Members
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Active
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400 border-b-gray-400">
                Active %
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Added
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Removed
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400 border-b-gray-400">
                Growth
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Premium
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Premium active
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Basic
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400 border-b-gray-400">
                Basic active
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Tenants
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Landlords
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Buyers
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Vendors
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400 border-b-gray-400">
                Staff
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400 border-b-gray-400">
                Student
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Sessions
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Logins
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400">
                Returns
              </td>
            </tr>
            <tr>
              <td className="py-1 px-2 border-[1px] border-x-gray-400 border-b-gray-400">
                Returns %
              </td>
            </tr>
          </thead>
        </table>
      </div>
      <div className="mt-6 w-full overflow-x-scroll scrollbar-hides pb-2 px-2">
        <table className="select-alls border-collapse min-w-full whitespace-nowrap">
          <thead className="bg-slate-500 text-white">
            <tr>
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <th
                      colSpan={2}
                      key={index + "-month"}
                      className="py-1 px-2 border-[1px] border-gray-400 text-[11px] text-center font-medium"
                    >
                      {label}
                    </th>
                  ))}
              </React.Fragment>
            </tr>
          </thead>
          <tbody>
            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-users"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_month_bottom.index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberBreakdownMonthChartData?.datasets[0]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_month_worst.index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.user_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.user_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-users-active"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_active_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_active_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberBreakdownMonthChartData?.datasets[5]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_active_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_active_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.user_active_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.user_active_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-users-active"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_active_ratio_month_top.index ===
                          index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_active_ratio_month_bottom
                                .index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberBreakdownMonthChartData?.datasets[6]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_active_ratio_month_best.index ===
                          index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_active_ratio_month_worst
                                .index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={
                            memberKpiData?.user_active_ratio_diffs[index] || 0
                          }
                          text={numberWithCommas(
                            memberKpiData?.user_active_ratio_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberGrowthMonthChartData?.labels?.length > 0 &&
                  memberGrowthMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-users-added"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_added_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_added_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberGrowthMonthChartData?.datasets[5]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_added_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_added_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.user_added_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.user_added_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberGrowthMonthChartData?.labels?.length > 0 &&
                  memberGrowthMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-users-removed"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_removed_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_removed_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberGrowthMonthChartData?.datasets[6]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_removed_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_removed_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.user_removed_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.user_removed_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberGrowthMonthChartData?.labels?.length > 0 &&
                  memberGrowthMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-users-growth"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_diff_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_diff_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          (memberGrowthMonthChartData?.datasets[5]?.data[
                            index
                          ] || 0) +
                            (memberGrowthMonthChartData?.datasets[6]?.data[
                              index
                            ] || 0)
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 border-b-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.user_diff_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.user_diff_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.user_diff_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.user_diff_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-premium"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.premium_user_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.premium_user_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberBreakdownMonthChartData?.datasets[1]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.premium_user_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.premium_user_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.premium_user_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.premium_user_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-premium-active"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.premium_user_active_month_top.index ===
                          index
                            ? " bg-emerald-200"
                            : memberKpiData?.premium_user_active_month_bottom
                                .index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberBreakdownMonthChartData?.datasets[3]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.premium_user_active_month_best
                            .index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.premium_user_active_month_worst
                                .index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={
                            memberKpiData?.premium_user_active_diffs[index] || 0
                          }
                          text={numberWithCommas(
                            memberKpiData?.premium_user_active_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-basic"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.basic_user_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.basic_user_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberBreakdownMonthChartData?.datasets[2]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.basic_user_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.basic_user_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.basic_user_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.basic_user_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-basic-active"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.basic_user_active_month_top.index ===
                          index
                            ? " bg-emerald-200"
                            : memberKpiData?.basic_user_active_month_bottom
                                .index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberBreakdownMonthChartData?.datasets[4]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 border-b-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.basic_user_active_month_best.index ===
                          index
                            ? " bg-emerald-200"
                            : memberKpiData?.basic_user_active_month_worst
                                .index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={
                            memberKpiData?.basic_user_active_diffs[index] || 0
                          }
                          text={numberWithCommas(
                            memberKpiData?.basic_user_active_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-tenants"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.tenant_user_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.tenant_user_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberLabelsMonthChartData?.datasets[0]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.tenant_user_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.tenant_user_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.tenant_user_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.tenant_user_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-landlords"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.landlord_user_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.landlord_user_month_bottom
                                .index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberLabelsMonthChartData?.datasets[1]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.landlord_user_month_best.index ===
                          index
                            ? " bg-emerald-200"
                            : memberKpiData?.landlord_user_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.landlord_user_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.landlord_user_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-buyers"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.buyer_user_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.buyer_user_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberLabelsMonthChartData?.datasets[2]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.buyer_user_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.buyer_user_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.buyer_user_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.buyer_user_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-vendors"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.vendor_user_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.vendor_user_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberLabelsMonthChartData?.datasets[3]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.vendor_user_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.vendor_user_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.vendor_user_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.vendor_user_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-staff"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-b-gray-400  border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.staff_user_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.staff_user_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberLabelsMonthChartData?.datasets[4]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.staff_user_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.staff_user_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.staff_user_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.staff_user_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberBreakdownMonthChartData?.labels?.length > 0 &&
                  memberBreakdownMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-student"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-b-gray-400  border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.student_user_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.student_user_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberLabelsMonthChartData?.datasets[5]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.student_user_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.student_user_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.student_user_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.student_user_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberEngagementMonthChartData?.labels?.length > 0 &&
                  memberEngagementMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-sessions"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.sessions_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.sessions_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberEngagementMonthChartData?.datasets[0]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.sessions_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.sessions_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.sessions_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.sessions_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberEngagementMonthChartData?.labels?.length > 0 &&
                  memberEngagementMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-logins"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.logins_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.logins_month_bottom.index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberEngagementMonthChartData?.datasets[1]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.logins_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.logins_month_worst.index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.logins_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.logins_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberEngagementMonthChartData?.labels?.length > 0 &&
                  memberEngagementMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-returns"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.returns_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.returns_month_bottom.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberEngagementMonthChartData?.datasets[2]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.returns_month_best.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.returns_month_worst.index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.returns_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.returns_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <React.Fragment>
                {memberEngagementMonthChartData?.labels?.length > 0 &&
                  memberEngagementMonthChartData?.labels.map((label, index) => (
                    <React.Fragment key={index + "-returns_ratio"}>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-l-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.returns_ratio_month_top.index === index
                            ? " bg-emerald-200"
                            : memberKpiData?.returns_ratio_month_bottom
                                .index === index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        {numberWithCommas(
                          memberEngagementMonthChartData?.datasets[3]?.data[
                            index
                          ] || 0
                        )}
                      </td>
                      <td
                        className={`
                        min-w-[74px] py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-r-gray-400 font-light text-[11px] text-slate-900 text-right opacity-70
                        ${
                          memberKpiData?.returns_ratio_month_best.index ===
                          index
                            ? " bg-emerald-200"
                            : memberKpiData?.returns_ratio_month_worst.index ===
                              index
                            ? " bg-rose-200"
                            : " bg-white"
                        } 
                      `}
                      >
                        <ColorText
                          value={memberKpiData?.returns_ratio_diffs[index] || 0}
                          text={numberWithCommas(
                            memberKpiData?.returns_ratio_diffs[index] || 0
                          )}
                          inverted={false}
                        />
                      </td>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-6 pb-2">
        <table className="select-alls border-collapse">
          <thead className="bg-slate-600 text-white whitespace-nowrap">
            <tr>
              <th className="min-w-[80px] py-1 px-2 border-[1px] border-gray-400 border-l-indigo-400 border-t-indigo-400 text-[11px] text-right font-medium">
                Average
              </th>
              <th className="min-w-[80px] py-1 px-2 border-[1px] border-gray-400 border-t-indigo-400 text-[11px] text-right font-medium">
                Growth
              </th>
              <th className="min-w-[80px] py-1 px-2 border-[1px] border-gray-400 border-t-indigo-400 text-[11px] text-right font-medium">
                Steps
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_users_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.users_diff || 0}
                  text={numberWithCommas(memberKpiData?.users_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.user_month_var || 0}
                  text={numberWithCommas(memberKpiData?.user_month_var || 0)}
                  inverted={false}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_users_active_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.users_active_diff || 0}
                  text={numberWithCommas(memberKpiData?.users_active_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.user_active_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.user_active_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(
                  memberKpiData?.month_users_active_ratio_avg || 0
                )}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.users_active_ratio_diff || 0}
                  text={numberWithCommas(
                    memberKpiData?.users_active_ratio_diff || 0
                  )}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.user_active_ratio_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.user_active_ratio_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_users_added_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.users_added_diff || 0}
                  text={numberWithCommas(memberKpiData?.users_added_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.user_added_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.user_added_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_users_removed_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.users_removed_diff || 0}
                  text={numberWithCommas(
                    memberKpiData?.users_removed_diff || 0
                  )}
                  inverted={true}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.user_removed_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.user_removed_month_var || 0
                  )}
                  inverted={true}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_users_diff_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.users_diff_diff || 0}
                  text={numberWithCommas(memberKpiData?.users_diff_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.user_diff_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.user_diff_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_premium_users_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.premium_users_diff || 0}
                  text={numberWithCommas(
                    memberKpiData?.premium_users_diff || 0
                  )}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.premium_user_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.premium_user_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(
                  memberKpiData?.month_premium_users_active_avg || 0
                )}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.premium_users_active_diff || 0}
                  text={numberWithCommas(
                    memberKpiData?.premium_users_active_diff || 0
                  )}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.premium_user_active_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.premium_user_active_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_basic_users_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.basic_users_diff || 0}
                  text={numberWithCommas(memberKpiData?.basic_users_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.basic_user_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.basic_user_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(
                  memberKpiData?.month_basic_users_active_avg || 0
                )}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.basic_users_active_diff || 0}
                  text={numberWithCommas(
                    memberKpiData?.basic_users_active_diff || 0
                  )}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.basic_user_active_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.basic_user_active_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_tenant_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.label_tenant_diff || 0}
                  text={numberWithCommas(memberKpiData?.label_tenant_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.tenant_user_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.tenant_user_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_landlord_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.label_landlord_diff || 0}
                  text={numberWithCommas(
                    memberKpiData?.label_landlord_diff || 0
                  )}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.landlord_user_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.landlord_user_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_buyer_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.label_buyer_diff || 0}
                  text={numberWithCommas(memberKpiData?.label_buyer_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.buyer_user_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.buyer_user_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_vendor_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.label_vendor_diff || 0}
                  text={numberWithCommas(memberKpiData?.label_vendor_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.vendor_user_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.vendor_user_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_staff_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.label_staff_diff || 0}
                  text={numberWithCommas(memberKpiData?.label_staff_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.staff_user_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.staff_user_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_student_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.label_student_diff || 0}
                  text={numberWithCommas(
                    memberKpiData?.label_student_diff || 0
                  )}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.student_user_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.student_user_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>

            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_sessions_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.sessions_diff || 0}
                  text={numberWithCommas(memberKpiData?.sessions_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.sessions_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.sessions_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_logins_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.logins_diff || 0}
                  text={numberWithCommas(memberKpiData?.logins_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.logins_month_var || 0}
                  text={numberWithCommas(memberKpiData?.logins_month_var || 0)}
                  inverted={false}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_returns_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.returns_diff || 0}
                  text={numberWithCommas(memberKpiData?.returns_diff || 0)}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.returns_month_var || 0}
                  text={numberWithCommas(memberKpiData?.returns_month_var || 0)}
                  inverted={false}
                />
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-slate-100">
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-l-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                {numberWithCommas(memberKpiData?.month_returns_ratio_avg || 0)}
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.returns_ratio_diff || 0}
                  text={numberWithCommas(
                    memberKpiData?.returns_ratio_diff || 0
                  )}
                  inverted={false}
                />
              </td>
              <td
                className={`py-1 px-2 border-[1px] border-gray-200 border-b-gray-400 border-r-indigo-400 font-regular text-[11px] text-slate-900 text-right opacity-70 bg-white`}
              >
                <ColorText
                  value={memberKpiData?.returns_ratio_month_var || 0}
                  text={numberWithCommas(
                    memberKpiData?.returns_ratio_month_var || 0
                  )}
                  inverted={false}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default UserGrowthTable;
