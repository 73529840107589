import React, { useState } from "react";

const initialState = {
  isVisible: false,
  heading: "",
  message: "",
  mood: "Info",
  confirmButtonLabel: "Ok",
  onConfirm: () => {},
  onCancel: () => {},
};

const useModal = () => {
  
  const [state, setState] = useState(initialState);

  const showModal = ({
    heading,
    message,
    mood,
    confirmButtonLabel,
    onConfirm,
    onCancel,
  }) => {
    setState({
      isVisible: true,
      heading,
      message,
      mood,
      confirmButtonLabel,
      onConfirm,
      onCancel,
    });
  };

  const openModal = () => {
    setState({ ...state, isVisible: true });
  };

  const closeModal = () => {
    setState(initialState);
  };

  return { showModal, openModal, closeModal, modalState: state };
};

export default useModal;
