import React, { useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Radar } from "react-chartjs-2";
import { profileFieldReport } from "./../../graphql/queries/reportQueries";
import { generateSystemAccessCodeMutation } from "./../../graphql/mutations/systemMutations";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useTypeFilters from "./../../hooks/typeFilters";
import { REPORTS_PROFILE_FIELD_ANSWERS_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import { Heading, ItemsNotFound } from "./../../moose-ui/Typography";
import { SelectField } from "../../moose-ui/Fields";

const PROFILE_FIELD_ANSWERS_REPORT = gql(profileFieldReport);
const GENERATE_SYSTEM_ACCESS_CODE = gql(generateSystemAccessCodeMutation);

const chartOptions = {
  type: "radar",
  options: {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Answers",
      },
    },
  },
};

const REPORT_DRAFT = {
  labels: [],
  datasets: [
    {
      label: "Share",
      data: [],
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderColor: "rgba(54, 162, 235, 1)",
    },
  ],
};

const ProfileFieldAnswers = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.ProfileFieldAnswerShareReport);
  const chartRef = useRef();
  const history = useHistory();
  const { client_id: clientId, field_name: fieldName } = useParams();
  const [reportData, setReportData] = useState(REPORT_DRAFT);

  const handleClientChange = (e) =>
    history.push(
      `${REPORTS_PROFILE_FIELD_ANSWERS_MODEL_NAME}/${e.target.value}/${fieldName}`
    );

  const handleFieldChange = (e) =>
    history.push(
      `${REPORTS_PROFILE_FIELD_ANSWERS_MODEL_NAME}/${clientId}/${e.target.value}`
    );

  const { data, loading, error } = useQuery(PROFILE_FIELD_ANSWERS_REPORT, {
    variables: { client_id: clientId, field_name: fieldName },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data && data.profileFieldReport) {
        const { answers } = data.profileFieldReport;
        const newReportData = { ...REPORT_DRAFT };
        newReportData.labels = answers.map((r) => r.answer);
        newReportData.datasets[0].data = answers.map((r) => r.percentage);
        setReportData(newReportData);
      }
    },
  });

  const [generateSystemAccessCode] = useMutation(GENERATE_SYSTEM_ACCESS_CODE, {
    onCompleted: (result) => {},
    onError: (e) => {
      alert("ailed to generate Vaboo REST System Access Code");
      console.log(e);
    },
  });

  const { data: typeFilters, isReady: typeFiltersReady } = useTypeFilters({
    filtersFor: "profileCompletions",
    queryString: "",
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        {loading && (
          <Heading text="Report. Profile Field Answers Share, %. Loading..." />
        )}
        {!loading && <Heading text="Report. Profile Field Answers Share, %" />}
        <Row>
          <Col width={2}>
            <SelectField
              value={clientId}
              values={typeFilters[0].values}
              label="Client"
              onChange={handleClientChange}
              valueKey="uid"
              labelKey="name"
              prependOption="All Clients"
            />
          </Col>
          <Col width={2}>
            <SelectField
              value={fieldName}
              values={typeFilters[1].values}
              label="Field"
              onChange={handleFieldChange}
              valueKey="uid"
              labelKey="name"
            />
          </Col>
        </Row>
        {data.profileFieldReport.answers.length > 0 && (
          <Row>
            <Col width={12}>
              <Radar ref={chartRef} data={reportData} options={chartOptions} />
            </Col>
          </Row>
        )}
        {data.profileFieldReport.answers.length === 0 && (
          <ItemsNotFound
            text="Nothing found"
            description="No answers found for current criteria"
          />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default ProfileFieldAnswers;
