import React from "react";
import { 
  // useParams, 
  // useHistory, 
  useLocation, 
} from "react-router-dom";
// import { CLIENT_MODEL_NAME } from "../configs/constants";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import PageContainer from "../vaboo-ui/PageContainer";

// const isActiveUrl = (location, urlPart) =>
//   location.indexOf(urlPart) > 0 ? true : false;

const Settings = (props) => {
  const { hasAccess } = usePermissions();
  // const { id } = useParams();
  const location = useLocation();
  const rootUrl = ``;

  document.title = 'Users'

  const page = {
    title: 'Users', 
    icon: 'userGroup', 
    nav: [],
  };

  if (hasAccess(Permissions.Query.Users))
    page.nav.push({
      name: "Overview",
      url: `${rootUrl}/`,
      isActive: location.pathname.split('/')[2] === '' ? true : false,
      icon: "chart",
    });

  if (hasAccess(Permissions.Query.Users))
    page.nav.push({
      name: "All users",
      url: `${rootUrl}/users/1/50/0/0/0`,
      isActive: location.pathname.split('/')[1] === 'users' ? true : false,
      icon: "list",
    });

  if (hasAccess(Permissions.Mutation.CreateUser))
    page.nav.push({
      name: "New user",
      url: `${rootUrl}/user/create`,
      isActive: location.pathname.split('/')[1] === 'user' ? true : false,
      icon: "add",
    });

  return <PageContainer page={page} {...props } hasDynamicLists={true} />;
};

export default Settings;
