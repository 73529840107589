export const allTenants = `query tenants($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  tenants(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    email
    access_type
    firstname
    lastname
    email
    landlord {
      id
      email
    }
    account_state
    activated
  }
}
`;

export const tenantDetails = `query tenant($id: ID!) {  
  tenant(id: $id) {
    id
    email
    access_type
    firstname
    lastname
    email
    landlord {
      id
      email
    }
    promo_premium_access
    tenancy_start
    tenancy_end
    gender
    locale
    age_range
    ref
    address
    towncity
    postcode
    telephone
    notes
    account_state
    created_method
    label_type {
      id
      name
    }
    meta_state {
      activation_push_enabled
    }
  }
}
`;

export const tenantPaymentSettings = `query tenantPaymentSettings($account_id: Int!) {  
  tenantPaymentSettings(account_id: $account_id) {
      account_id
      vaboo_premium_upgrade_price
      description
    }
  }
  `;
