import React, { useState, useContext, useEffect } from "react";
// import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { platformDetails } from "../../graphql/queries/systemQueries";
import { updatePlatformMutation } from "../../graphql/mutations/systemMutations";
import Permissions, {
  settingsNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../mood-ui/Notifications";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useHyperState from "./../../hooks/hyperState";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "../../hooks/notifications";
import useBaseNavigation from "../../hooks/baseNavigation";
import useDataProvider from "./../../hooks/dataProvider";

import { 
  // CLIENT_MODEL_NAME, 
  OFFER_MODEL_NAME, 
} from "../../configs/constants";

import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";

import { 
  FormControls, 
  SaveButton 
} from "../../mood-ui/Controls";

import { 
  TextField, 
  SelectField, 
  // ReadOnlyField, 
} from "../../mood-ui/Fields";

import { Heading } from "../../mood-ui/Typography";

import LabelContextSwitch from "../../mood-ui/LabelContextSwitcher";

import { LabelContext } from "../../contexts/LabelContext";

const PLATFORM_DETAILS = gql(platformDetails);

const UPDATE_PLATFORM = gql(updatePlatformMutation);

const SettingsMain = () => {
  
  const { protectPage, hasAccess } = usePermissions(); 
  
  protectPage(Permissions.Query.MainAppSettings, {
    noAccessFallbackList: settingsNoAccessFallbackList,
  });
  
  const labelContext = useContext(LabelContext);
  
  const { composeQueryString } = useBaseNavigation(OFFER_MODEL_NAME);
  
  // const [offers] = useDataProvider("offers", {
  //   filters: { activity: ["onlyTrulyActive"], label_id: [labelContext.activeLabel] },
  // });
  
  const [offers, _, onRefetchOffers] = useDataProvider("offers", {
    filters: { activity: ["onlyTrulyActive"], label_id: [labelContext.activeLabel] }
  });

  useEffect(() => {
    onRefetchOffers({ queryString: composeQueryString({ activity: ["onlyTrulyActive"], label_id: [labelContext.activeLabel] }) });
  }, [labelContext.activeLabel]);

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    ownerId: 0,
    labelId: labelContext.activeLabel,
    platform_name: "VABOO_APPLICATION",
    featured_offer: 0,
    section_behavior: "GLOBAL",
    section_ids: "",
  });

  const { setFieldError, formHasErrors, fieldError, resolveMutationErrors, clearError } = useFormErrors();

  const {
    setFieldValue: handleFieldOnChangeProxied,
    // toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);
  
  const handleFieldOnChange = (e) => {
    clearError(e.target.name)
    if (e.target?.checkValidity && !e.target?.checkValidity()) { 
      setFieldError(e.target.name, e.target.title || e.target.validationMessage || 'Input has an error')
      setDraft(false)
    }
    else if (formHasErrors()){ setDraft(false) } else { setDraft(true) }
    handleFieldOnChangeProxied(e)
  }

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { loading, error } = useQuery(PLATFORM_DETAILS, {
    variables: {
      ownerId: 0,
      labelId: labelContext.activeLabel
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.platform) return false;
      setFormData({
        ...result.platform,
        ownerId: 0,
        labelId: labelContext.activeLabel,
        platform_name: "VABOO_APPLICATION",
      });
    },
  });

  const [
    updatePlatform,
    { 
      // data: updateResult, 
      loading: isUpdating, 
      // error: updateError 
    },
  ] = useMutation(UPDATE_PLATFORM, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated app platform settings",
        secondsToDisplay: 1,
      });
      setDraft(d => false)
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update app platform settings",
        secondsToDisplay: 2,
      });
      setDraft(d => false)
      resolveMutationErrors(e);
    },
  });

  const handleUpdatePlatform = () => {
    const data = { ...formData };
    updatePlatform({ variables: data });
  };
  
  const [ isDraft, setDraft ] = useState(false)

  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <FormContainer>
          <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
            <Col width={12}>
              {/* <ReadOnlyField
                value={formData.platform_name}
                label="Platform Name"
              /> */}
              <Heading
                inset={true}
                text="Global platform settings"
                description="Control platform content for each member type.
                            These settings can be overwritten for each Client in their settings."
                />
            </Col>
          </Row>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Featured offer"
                      description="Choose an offer to be featured at the top of the member platform."
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles={""}>
                    <SelectField
                      loading={loading || !offers}
                      value={formData?.featured_offer}
                      values={offers}
                      label="Selected Offer"
                      name="featured_offer"
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e); }}
                      valueKey="id"
                      labelKey="admin_title"
                      error={fieldError("featured_offer")}
                      description="Only use an 'active' offer"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-b-0 border-[#dfe2e8] py-7 mx-5">
              <Col width={3}> 
                <Row>
                  <Col width={12}>
                    <Heading 
                      size="md"
                      text="Offer carousels"
                      description="Control the scrollable sections on the member platform. 
                                  This can be changed or extended in a client's settings"
                    />
                  </Col>
                </Row>
              </Col>
              <Col width={1} extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"> 
              </Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles={"px-0"}>
                    <TextField
                      loading={loading}
                      name="section_ids"
                      label="Ordered section list"
                      value={formData?.section_ids}
                      onChange={(e) => { setDraft(d => true); handleFieldOnChange(e) }}
                      error={fieldError("section_ids")}
                      description="Comma-separated list of section ids"
                      pattern="^([0-9]+)(,\s?[0-9]+)*\s?$"
                      maxlength={1080}
                      tweaks="pr-8"
                      isRequired={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls spaced={false}>
            <LabelContextSwitch />
            {hasAccess(Permissions.Mutation.UpdateMainAppSettings) && (
              <SaveButton onClick={handleUpdatePlatform} disabled={!isDraft} loading={isUpdating} />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default SettingsMain;
