import React from "react";

const FilterValue = ({
  filterId: id,
  filterValue: name,
  onClick: toggleFilterValue,
  isSelected,
}) => {
  const aCssClass = isSelected
    ? "block px-3 py-1 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 bg-gray-100"
    : "block px-3 py-1 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900";

  return (
    <a
      className={aCssClass}
      style={{ cursor: "pointer" }}
      onClick={() => {
        toggleFilterValue(id);
      }}
    >
      {name}
    </a>
  );
};

export default FilterValue;
