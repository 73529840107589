import { truncateNumber } from "./../../hooks/utils";

const killTooltips = () => {
  const test = document.getElementsByClassName('chartjs-tooltip-2') || []
  for (let i = 0; i < test.length; i++) { test[i].style.opacity = 0 }
}

const customTooltips = (tooltipModel) => {                             // https://www.chartjs.org/docs/2.9.4/general/options.html
  const slug = tooltipModel?.beforeBody ? tooltipModel?.beforeBody[0] : null
  if (!slug) {
    killTooltips()
  }
  else {
    var tooltipEl  = document.getElementById("chartjs-tooltip-" + slug); 
    var yAlign     = tooltipModel.yAlign
    var xAlign     = tooltipModel.xAlign    
    const chart    = document.getElementById(slug)
    if (!tooltipEl) { 
      tooltipEl    = document.createElement("div");                       // Create element on first render
      tooltipEl.id = "chartjs-tooltip-" + slug
      tooltipEl.classList.add('chartjs-tooltip-2')
      tooltipEl.innerHTML = `<div style="position: relative; background-color: rgba(20, 20, 20, 0.8); padding: ${ tooltipModel.yPadding }px ${ tooltipModel.xPadding }px"><table class="z-[99999] relative" style=""></table>`;
      chart.parentNode.appendChild(tooltipEl);
    }
    var tableRoot = tooltipEl.querySelector("table");
    if (tooltipModel.opacity === 0) { tooltipEl.style.opacity = 0; return; } // Hide if no tooltip
    if (tooltipModel.body) {
      var bodyLines     = tooltipModel.body.map((bodyItem) => { return bodyItem.lines; });
      var innerHtml     = "<thead></thead><tbody>";
      let rows          = []
      let lastCountType = -1
      bodyLines.forEach((body, i) => { 
        var style = `background-color: ${ tooltipModel.labelColors[i].backgroundColor }; border-width: 1px; border-color: ${ tooltipModel.labelTextColors[i] };
          display: inline-block; width: 12px; height: 12px; margin-right: 6px; margin-bottom: -3px;`;
        if (tooltipModel.labelColors[i].borderColor !== 'rgba(0,0,0,0)') {
          style += 'border-radius: 0; ' 
          lastCountType ++
        }
        else { style += 'border-radius: 50%; ' }
        
        var labelColor = `<td class="whitespace-nowrap w-min"><span class="chartjs-tooltip-key inline-block" style="${ style }"></span></td>`;
        var labelName = `<td class="whitespace-nowrap" style="color: ${ tooltipModel.labelTextColors[i] };"> ${body[0].split(': ')[0]} </td>`
        var labelValue = `<td class="whitespace-nowrap pl-3 text-right" style="color: ${ tooltipModel.labelTextColors[i] };"> ${body[0].split(': ')[1]} </td>`
        rows.push(`<tr> ${ labelColor } ${ labelName } ${ labelValue } </tr>`)
      });
      if (lastCountType > -1 && rows.length > lastCountType + 1) {
        rows.splice(lastCountType + 1, 0, `<tr style="padding: 0; margin: 0; width: 12px; height: 6px;"><td></td></tr>`)
      }

      innerHtml += rows.join('')
      innerHtml += `<tr><th colspan="3"><div class="text-right" style="color: white; margin-top: 6px;"> ${ tooltipModel.footer[0] }</div></th></tr></tbody>`;
      tableRoot.innerHTML = innerHtml;
    }
    const scale             = chart.height / 245
    const { height, width } = tableRoot.getBoundingClientRect();       // Tooltip height and width
    var caretY              = tooltipModel.caretY;                     // Carets
    var caretX              = tooltipModel.caretX;                     // Carets
    let space               = 8                                        // Caret + 1px (can increase)
    let top                 = window.pageYOffset + caretY - height;    // Final coordinates
    let left                = window.pageXOffset + caretX - width / 2; // Final coordinates
    if (height > chart.height - (40 + (68 * scale))) {
      if (tooltipModel.y < 80 && yAlign === 'top') {}
      else {
        yAlign = 'center'
        if (xAlign === 'center') {
          if (caretX > chart.width / 2) { xAlign = 'right' }
          else { xAlign = 'left' }
        }
      }
    }
    if (yAlign === 'bottom') { 
      top = Math.max(44, top - 13); left -= 7
    }
    if (yAlign === 'center') { 
      top = Math.max(32 - (height / 2), top) 
      caretY = Math.min(0 - (chart.height), caretY) 
    }
    if      (yAlign === "top")    { top += height + space; left -= space - 1 }
    else if (yAlign === "center") { top += height / 2; }
    else if (yAlign === "bottom") { top -= space; }
    if      (xAlign === "left")   { left -= 3;  left += width / 2 - tooltipModel.xPadding + space; if (yAlign === "center") { left += space; top -= 6 } } 
    else if (xAlign === "right")  { left -= 11; left -= width / 2 - tooltipModel.xPadding - space; if (yAlign === "center") { left -= 9; left -= space * 2; top -= 6; } }
    let offsetHeight = height - chart.height
    if (yAlign === 'center') {
      if (height > chart.height - (40 + (68 * scale))) {
        top = 40
        if (tooltipModel.yAlign === 'bottom')      { offsetHeight = ((tooltipModel.y * 2) + (height / 2) + (14 * scale)) }
        else if (tooltipModel.yAlign === 'top')    { offsetHeight = ((tooltipModel.y * 2) + (12 * scale)) / 2 }
        else if (tooltipModel.yAlign === 'center') { offsetHeight = ((tooltipModel.y * 2)) + (18 * scale) }
      }
    }
    tooltipEl.classList.remove("top", "bottom", "center", "left", "right");
    tooltipEl.classList.add(yAlign);
    tooltipEl.classList.add(xAlign);
    tooltipEl.style = `opacity: 1; position: absolute; transition: all 0.17s ease-out;pointer-events: none; height: ${ offsetHeight }px;`
    tooltipEl.style.opacity    = 1
    tooltipEl.style.top        = `${ top }px`;
    tooltipEl.style.left       = `${ left }px`;
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize   = `${ tooltipModel.bodyFontSize }px`
    tooltipEl.style.fontStyle  = `${ tooltipModel._bodyFontStyle }px`;
  }
}



export const BarOptions = (
    tooltipLabelFunc = v => { return v }
  ) => { 
  return {
    type: 'bar',
    keepApectRatio: false, 
    responsive: true, 
    maintainAspectRatio: true, 
    animation: { duration: 300, easing: 'easeOutQuad', }, 
    hover: { animationDuration: 125, },
    legend: {
      onHover: () => killTooltips(),
      labels: {
        boxWidth: 12, 
        fontSize: 10, 
        fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
      }, 
    },
    tooltips: {
      mode: "index", 
      intersect: false, 
      backgroundColor: 'rgba(66,66,66,0.9)', 
      titleMarginBottom: 0, titleFontSize: 0, 
      bodySpacing: 4, bodyFontSize: 11, 
      footerMarginTop: 6, footerAlign: 'right', 
      bodyFontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
      cornerRadius: 0, caretSize: 4, borderWidth: 0, 
      xPadding: 7, yPadding: 7, position: 'nearest',
      callbacks: {
        footer: function(tooltipItem, data) {
          return tooltipItem[0].label
        }, 
        beforeBody: function(tooltipItem, data) {
          return data.datasets[0].id
        }, 
        labelColor: function(tooltipItem, chart) {
          return { 
            backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor, 
            borderColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor, 
          }
        },
        labelTextColor: function(tooltipItem, chart) {
          return `rgba(255,255,255, 1)`
        },
        label: function(tooltipItem, data) {
          return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipLabelFunc(parseFloat(tooltipItem.value))
        }, 
      }, 
      enabled: false, 
      custom: customTooltips, 
    },
    scaleLabel: { fontSize: 10, },
    elements: {
      point: { radius: 0, hoverRadius: 0 },
      line: { tension: 0.3, clip: { left: 5, top: 0, right: -2, bottom: 10 }, },
    }, 
    scales: {  
      yAxes: [
        {
          gridLines: { 
            color: 'rgba(200, 200, 200, 0.1)', 
            zeroLineWidth: 0, 
          }, 
          ticks: {
            fontSize: 10, 
            beginAtZero: false, fontColor: 'rgba(100, 100, 100, 0.7)', 
            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
            callback: function(value, index, values) { 
              if (value >= 0) {
                return truncateNumber(value)[0] + truncateNumber(value)[2] 
              }
              else {
                return '-' + truncateNumber(Math.abs(value))[0] + truncateNumber(Math.abs(value))[2] 
              }
            }
          },
          id: 'first-y-axis',
        },
        {
          gridLines: { 
            color: 'rgba(200, 200, 200, 0.1)', 
            zeroLineWidth: 0, 
          }, 
          ticks: {
            fontSize: 10, 
            beginAtZero: true, 
            fontColor: 'rgba(100, 100, 100, 0.7)', fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
            callback: function(value, index, values) { 
              if (value >= 0) {
                return truncateNumber(value)[0] + truncateNumber(value)[2] 
              }
              else {
                return '-' + truncateNumber(Math.abs(value))[0] + truncateNumber(Math.abs(value))[2] 
              }
            }
          },
          position: 'right', 
          id: 'second-y-axis',
        },
      ],
      xAxes: [
        {
          offset: true, 
          stacked: true, 
          gridLines: { 
            color: 'rgba(200, 200, 200, 0.1)', 
            zeroLineWidth: 0, 
          }, 
          ticks: {
            source: 'auto', 
            fontSize: 10, fontColor: 'rgba(100, 100, 100, 0.7)', 
            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', 
          },
          type: 'time',
          time: {
            displayFormats: {
              day: 'DD/MM/YY', 
              week: 'DD MMM \'YY', 
              month: 'MMM \'YY', 
              quarter: 'MMM \'YY', 
            }
          },
          distribution: 'series'
        },
      ],
    },
  }
}