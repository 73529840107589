import React from "react";
import Moment from "react-moment";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { userOrders } from "./../../graphql/queries/reportQueries";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useTypeFilters from "./../../hooks/typeFilters";
import { REPORTS_USER_ORDER_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import { TEditButton } from "./../../moose-ui/Controls";
import {
  Heading,
  ItemsNotFound,
  ColorLabel,
} from "./../../moose-ui/Typography";
import Search from "./../../moose-ui/Search";
import TypeFilters from "./../../moose-ui/filters/TypeFilters";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  TableActions,
  Pagination,
} from "./../../moose-ui/DataTable";
import useItemCounter from "../../hooks/itemCounter";
import {
  renderOrderPaymentStatus,
  renderOrderState,
  renderOrderStatus,
} from "../TilloBrandListApprovalPage";

const USER_ORDERS = gql(userOrders);

const UserOrders = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.UserOrdersReport);
  const { page, limit, orderBy, orderHow, search } = useParams();
  const { search: queryString } = useLocation();
  const { data, loading, error } = useQuery(USER_ORDERS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "orders" });

  const { nextPage, prevPage, applySearch, applyFilters, resetFilters, goTo } =
    useBaseNavigation(REPORTS_USER_ORDER_MODEL_NAME, {
      showingItemsCount: itemsTotal,
    });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    updateDatesFilter: onUpdateDatesFilter,
    resetFilters: onResetFilters,
    removeFilterValue: onRemoveFilterValue,
    applyFilters: onApplyFilters,
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "userOrders",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: queryString,
    fetchPolicy: "no-cache",
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        {loading && <Heading text="Report. User Orders. Loading..." />}
        {!loading && <Heading text="Report. User Orders" />}
        <Row>
          <Col width={12}>
            <TypeFilters
              data={typeFilters}
              onApply={onApplyFilters}
              onReset={onResetFilters}
              onRemoveValue={onRemoveFilterValue}
              hasActiveFilters={hasActiveFilters}
              onUpdate={onUpdateFilter}
              onUpdateDates={onUpdateDatesFilter}
              selectedFilters={selectedFilters}
              filterBaseTitle="Dates & Filters"
            />
          </Col>
        </Row>
        <Row>
          <Col width={9}></Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>

        {data && data.orders && data.orders.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol name="order_id" value="Order Id" />
                  <THCol name="transaction_id" value="Transaction Id" />
                  <THCol name="brand_slug" value="Brand" />
                  <THCol name="offer_title" value="Offer" />
                  <THCol name="email" value="User Email" />
                  <THCol name="client" value="Account Name" />
                  <THCol
                    name="requested_voucher_amount"
                    value="Voucher Amount, £"
                  />
                  <THCol
                    name="user_payment_amount"
                    value="User Payment Amount, £"
                  />
                  <THCol name="payment_status" value="Payment Status" />
                  <THCol name="order_status" value="Order Status" />
                  <THCol name="state" value="State" />
                  <THCol name="created_at" value="Order Date" />
                  <THCol />
                </TRow>
              </THeading>
              <TContent>
                {data.orders.map((order, idx) => (
                  <TRow key={idx}>
                    <TCol value={order.id} />
                    <TCol value={order.transaction_id} />
                    <TCol value={order.brand_slug} />
                    <TCol value={order.initial_offer.slug} />
                    <TCol value={order.user.email} />
                    <TCol value={order.client.account_name} />
                    <TCol>{order.requested_voucher_amount}</TCol>
                    <TCol>{order.user_payment_amount}</TCol>
                    <TCol>
                      {renderOrderPaymentStatus(order.bank_payment_status)}
                    </TCol>
                    <TCol>{renderOrderStatus(order.order_status)}</TCol>
                    <TCol>{renderOrderState(order.state)}</TCol>
                    <TCol>
                      <Moment format="LL" date={order.created_at} />
                    </TCol>
                    <TCol>
                      <TEditButton
                        onClick={() => {
                          goTo(`/${REPORTS_USER_ORDER_MODEL_NAME}/${order.id}`);
                        }}
                        label="View"
                      />
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            </TableView>
            <TableActions>
              <TableSelectedActions></TableSelectedActions>
              <Pagination onPrev={prevPage} onNext={nextPage} />
            </TableActions>
          </TableContainer>
        )}
        {data && data.orders && data.orders.length === 0 && (
          <ItemsNotFound text="No user orders found by current criteria" />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default UserOrders;
