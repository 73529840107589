import React, { useState } from "react";

export const TableContainer = ({ children }) => (
  <div className="flex flex-col">{children}</div>
);

export const TableView = ({ children }) => (
  <div className="my-4 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
      <table className="min-w-full">{children}</table>
    </div>
  </div>
);

export const THeading = ({ children }) => <thead>{children}</thead>;

export const TRow = ({ isSelected = false, children }) => (
  <tr className={isSelected ? "bg-gray-300" : "bg-white"}>{children}</tr>
);

export const TCol = ({
  isBold = false,
  value = false,
  width = "min-w-full",
  centerContent = false,
  children,
}) => {
  const marginX = centerContent ? 6 : 4;
  const cssClass = isBold
    ? `px-${marginX} py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900 ${width}`
    : `px-${marginX} py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 ${width}`;
  return <td className={cssClass}>{value !== false ? value : children}</td>;
};

export const TColBorderless = React.memo(
  ({ children, isBold = false, width = "min-w-full" }) => {
    const cssClass = isBold
      ? `const px-4 py-4 whitespace-no-wrap  text-sm leading-5 font-medium text-gray-900`
      : `px-4 py-4 whitespace-no-wrap  text-sm leading-5 text-gray-500 ${width}`;
    return <td className={cssClass}>{children}</td>;
  },
  (prevProps, nextProps) => {
    if (prevProps.children != nextProps.children) return false;
    return true;
  }
);

export const THCol = ({
  value = false,
  children,
  name,
  sortBy,
  sortHow,
  onSort,
  width = "min-w-full",
}) => {
  if (!onSort || !name)
    return (
      <th
        className={`px-4 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider ${width}`}
      >
        {value ? value : children}
      </th>
    );

  return (
    <th
      className={`px-4 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider ${width}`}
      style={{ cursor: "pointer" }}
      onClick={() => onSort(name)}
    >
      <div className="flex">
        {value ? value : children}
        {name == sortBy && sortHow == "asc" && (
          <svg
            width="16"
            height="16"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.29289 7.70711C4.90237 7.31658 4.90237 6.68342 5.29289 6.29289L9.29289 2.29289C9.68342 1.90237 10.3166 1.90237 10.7071 2.29289L14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711C14.3166 8.09763 13.6834 8.09763 13.2929 7.70711L11 5.41421L11 17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17L9 5.41421L6.70711 7.70711C6.31658 8.09763 5.68342 8.09763 5.29289 7.70711Z"
              fill="#4A5568"
            />
          </svg>
        )}
        {name == sortBy && sortHow == "desc" && (
          <svg
            width="16"
            height="16"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31658 11.9024 6.70711 12.2929L9 14.5858L9 3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44772 11 3L11 14.5858L13.2929 12.2929C13.6834 11.9024 14.3166 11.9024 14.7071 12.2929Z"
              fill="#4A5568"
            />
          </svg>
        )}
      </div>
    </th>
  );
};

export const TContent = ({ children }) => (
  <tbody className="bg-white">{children}</tbody>
);

export const TableActions = ({ children }) => (
  <div className="mt-0 grid grid-cols-1 row-gap-12 col-gap-4 sm:grid-cols-12">
    {children}
  </div>
);

export const TableSelectedActions = ({ children }) => (
  <div className={`sm:col-span-8 flex justify-between`}>{children}</div>
);

export const Pagination = ({ children, onPrev, onNext }) => {
  return (
    <div className={`sm:col-span-4 text-right`}>
      {!onPrev && (
        <span className="ml-0 inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-300 focus:outline-none cursor-not-allowed"
          >
            Prev
          </button>
        </span>
      )}
      {onPrev && (
        <span className="ml-0 inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
            onClick={onPrev}
          >
            Prev
          </button>
        </span>
      )}
      <span className="ml-2 inline-flex rounded-md shadow-sm">
        {!onNext && (
          <button
            type="button"
            className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-300 focus:outline-none cursor-not-allowed"
          >
            Next
          </button>
        )}
        {onNext && (
          <button
            type="button"
            className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
            onClick={onNext}
          >
            Next
          </button>
        )}
      </span>
    </div>
  );
};

export const FetchMoreControl = ({ onFetchMore, label = "Fetch more" }) => (
  <div className="flex-1 flex justify-between sm:justify-end">
    <span className="ml-0 inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
        onClick={onFetchMore}
      >
        {label}
      </button>
    </span>
  </div>
);

export const ActionList = ({ onClearSelection, children }) => {
  const [visible, setVisibility] = useState(false);
  const show = () => {
    setVisibility(true);
  };
  const hide = () => {
    setVisibility(false);
  };
  const mainDivCss = visible
    ? "origin-top-right absolute left-0 bottom-0 mt-2 w-56 rounded-md shadow-lg"
    : "origin-top-right absolute left-0 bottom-0 mt-2 w-56 rounded-md shadow-lg hidden";

  return (
    <div
      className="flex-11 flex justify-left sm:justify-end"
      onMouseEnter={show}
      onMouseLeave={hide}
    >
      <div className="relative inline-block text-left">
        <div>
          <span className="rounded-md shadow-sm">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            >
              With selected
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </span>
        </div>

        <div className={mainDivCss}>
          <div className="rounded-md bg-white shadow-xs">
            <div className="py-1">
              <a
                onClick={onClearSelection}
                className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                style={{ cursor: "pointer" }}
              >
                Clear selection
              </a>
            </div>
            <div className="border-t border-gray-100"></div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ActionGroup = ({ children }) => (
  <div className="py-1">{children}</div>
);

export const Action = ({ onClick = () => {}, label }) => (
  <a
    style={{ cursor: "pointer" }}
    className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
    onClick={onClick}
  >
    {label}
  </a>
);

export const Divider = () => <div className="border-t border-gray-100"></div>;
