import React from "react";
import { useBaseField } from "./../hooks/coreHooks";

const BaseField = ({ children, label, error, description, isRequired }) => (
  <div>
    <label
      htmlFor="email"
      className="block text-sm font-medium leading-5 text-gray-700"
    >
      {label}
      {isRequired && " *"}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      {children}
      {error && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-red-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </div>
    {!error && description && (
      <p className="mt-2 text-sm text-gray-500">{description}</p>
    )}
    {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
  </div>
);

export const ReadOnlyField = ({
  value,
  label,
  isRequired = false,
  extraStyles = "",
}) => (
  <div>
    <label
      htmlFor="email"
      className={`block text-sm font-medium leading-5 text-gray-700 ${extraStyles}`}
    >
      {label}
      {isRequired && " *"}
    </label>
    <div className={`mt-1 relative ${extraStyles}`}>{value}</div>
  </div>
);

export const TextField = (props) => {
  const newProps = useBaseField(props);
  return <BaseTextField {...newProps} />;
};

export const BaseTextField = React.memo((props) => {
  const {
    value = "",
    name,
    onChange,
    placeholder,
    error,
    onEnter = () => {
      return false;
    },
    mode = "text",
    readOnly = false,
    disabled = false
  } = props;
  const cssClass = error
    ? "form-input block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red sm:text-sm sm:leading-5"
    : "form-input block w-full sm:text-sm sm:leading-5";
  return (
    <BaseField {...props}>
      <input
        id={name}
        name={name}
        type={mode}
        value={value}
        onChange={onChange}
        onKeyPress={(e) => {
          if (e.key == "Enter") {
            onEnter(e);
          }
        }}
        className={cssClass}
        placeholder={placeholder ? placeholder : ""}
        readOnly={readOnly}
      />
    </BaseField>
  );
});

export const NumberField = (props) => {
  const newProps = useBaseField(props);
  return <BaseTextField {...newProps} mode="number" />;
};

export const NumberFieldReadOnly = (props) => {
  const newProps = useBaseField(props);
  return <BaseTextField {...newProps} mode="number" readOnly={true} />;
};

export const DateField = (props) => {
  const newProps = useBaseField(props);
  return <BaseTextField {...newProps} mode="date" />;
};

export const SelectField = React.memo((props) => {
  const newProps = useBaseField(props);
  const {
    value,
    values,
    name,
    onChange,
    label = false,
    valueKey,
    labelKey,
    prependOption,
    prependOptionValue = 0,
    description,
    error,
    isDisabled = false,
    isRequired = false,
  } = newProps;

  const disabledCssModifier = isDisabled ? "bg-gray-100" : "";

  const selectCssClass = error
    ? "form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
    : `form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledCssModifier}`;

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          {label}
          {isRequired && " *"}
        </label>
      )}
      <div className="mt-1 rounded-md shadow-sm">
        <select
          className={selectCssClass}
          name={name}
          value={value}
          onChange={onChange}
          disabled={isDisabled ? "disabled" : ""}
          style={{ cursor: isDisabled ? "not-allowed" : "auto" }}
        >
          {prependOption && (
            <option key={-1} value={prependOptionValue}>
              {prependOption}
            </option>
          )}
          {values.map((option) => (
            <option key={option[valueKey]} value={option[valueKey]}>
              {option[labelKey]}
            </option>
          ))}
        </select>
      </div>
      {!error && description && (
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      )}
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
});

export const CodeEditorField = (props) => {
  const { value, name, onChange, label, error, description, rows } = props;

  const textAreaCssClass = error
    ? "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red "
    : "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";

  return (
    <div>
      <label
        htmlFor="about"
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 rounded-md shadow-sm">
        <textarea
          id={name}
          name={name}
          rows={rows}
          className={textAreaCssClass}
          onChange={onChange}
          value={value}
        ></textarea>
      </div>
      {!error && description && (
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      )}
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export const RichTextEditorField = (props) => {
  const newProps = useBaseField(props);
  const {
    value,
    name,
    onChange,
    label,
    error,
    description,
    rows,
    isRequired = false,
  } = newProps;

  const textAreaCssClass = error
    ? "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red "
    : "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";

  return (
    <div>
      <label
        htmlFor="about"
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {label}
        {isRequired && " *"}
      </label>
      <div className="mt-1 rounded-md shadow-sm">
        <textarea
          id={name}
          name={name}
          rows={rows}
          className={textAreaCssClass}
          onChange={onChange}
          value={value}
        ></textarea>
      </div>
      {!error && description && (
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      )}
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export const ToggleCheckboxField = React.memo(
  ({ name, id, label, description = "", isChecked = false, onChange }) => {
    const eventObject = { target: { name, value: id } };
    const mainStyle = isChecked
      ? "relative inline-block flex-no-shrink h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline bg-indigo-600"
      : "relative inline-block flex-no-shrink h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline bg-gray-200";
    const togglingStyle = isChecked
      ? "relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200 translate-x-5"
      : "relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200 translate-x-0";
    return (
      <div className={`sm:col-span-2`}>
        <label
          htmlFor="about"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          {label}
        </label>
        <div className="mt-1">
          <span
            className={mainStyle}
            role="checkbox"
            tabIndex="0"
            onClick={() => {
              onChange(eventObject);
            }}
          >
            <span aria-hidden="true" className={togglingStyle}>
              {!isChecked && (
                <span className="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
                  <svg
                    className="h-3 w-3 text-gray-400"
                    fill="none"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              )}
              {isChecked && (
                <span className="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
                  <svg
                    className="h-3 w-3 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              )}
            </span>
          </span>
        </div>
        {description && (
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (
      prevProps.id != nextProps.id ||
      prevProps.isChecked != nextProps.isChecked ||
      prevProps.onChange != nextProps.onChange
    )
      return false;
    return true;
  }
);
