import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { tenantQAFlow } from "./../../graphql/queries/reportQueries";
import { generateSystemAccessCodeMutation } from "./../../graphql/mutations/systemMutations";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useTypeFilters from "./../../hooks/typeFilters";
import {
  REPORTS_TENANT_QA_FLOW_MODEL_NAME,
  VABOO_REST_API_URL,
} from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import { StdButton } from "./../../moose-ui/Controls";
import { Heading, ItemsNotFound } from "./../../moose-ui/Typography";
import Search from "./../../moose-ui/Search";
import TypeFilters from "./../../moose-ui/filters/TypeFilters";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  TableActions,
  Pagination,
} from "./../../moose-ui/DataTable";
import useItemCounter from "../../hooks/itemCounter";

const TENANT_QA_FLOW = gql(tenantQAFlow);
const GENERATE_SYSTEM_ACCESS_CODE = gql(generateSystemAccessCodeMutation);

const TenantQAFlow = () => {
  const { protectPage } = usePermissions();

  protectPage(Permissions.Query.TenantQAFlowReport);
  const { page, limit, orderBy, orderHow, search } = useParams();
  const { search: queryString } = useLocation();
  const { data, loading, error } = useQuery(TENANT_QA_FLOW, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "tenantQAFlow" });

  const { nextPage, prevPage, applySearch, applyFilters, resetFilters } =
    useBaseNavigation(REPORTS_TENANT_QA_FLOW_MODEL_NAME, {
      showingItemsCount: itemsTotal,
    });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    resetFilters: onResetFilters,
    removeFilterValue: onRemoveFilterValue,
    applyFilters: onApplyFilters,
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "tenantQA",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString: queryString,
  });

  const [generateSystemAccessCode] = useMutation(GENERATE_SYSTEM_ACCESS_CODE, {
    onCompleted: (result) => {
      const restApiExportUrl = `${VABOO_REST_API_URL}reporting/survey-report${queryString}&system_access_code=${result.generateSystemAccessCode.code}`;
      window.open(restApiExportUrl);
    },
    onError: (e) => {
      alert("Failed to generate Vaboo REST System Access Code");
      console.log(e);
    },
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        {loading && <Heading text="Report. Tenant QA Flow. Loading..." />}
        {!loading && <Heading text="Report. Tenant QA Flow" />}
        <Row>
          <Col width={12}>
            <TypeFilters
              data={typeFilters}
              onApply={onApplyFilters}
              onReset={onResetFilters}
              onRemoveValue={onRemoveFilterValue}
              hasActiveFilters={hasActiveFilters}
              onUpdate={onUpdateFilter}
              selectedFilters={selectedFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col width={9}>
            {hasActiveFilters && queryString.length > 0 && (
              <StdButton
                label="Export to CSV"
                onClick={generateSystemAccessCode}
              />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>

        {data && data.tenantQAFlow && data.tenantQAFlow.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol name="tenant" value="Tenant (Email)" />
                  <THCol name="survey" value="Survey" />
                  <THCol name="question" value="Question" />
                  <THCol name="answers" value="Answers" />
                </TRow>
              </THeading>
              <TContent>
                {data.tenantQAFlow.map((tenantQA, idx) => (
                  <TRow key={idx}>
                    <TCol value={tenantQA.tenant.email} />
                    <TCol>
                      ({tenantQA.survey.id}) {tenantQA.survey.title}
                    </TCol>
                    <TCol>
                      ({tenantQA.question.id}) {tenantQA.question.title}
                    </TCol>
                    <TCol>
                      {tenantQA.answers.map((answer, answerIdx) => (
                        <div key={answerIdx}>
                          ({answer.uid}) {answer.title}
                          {answer.text.length > 0 && <div>{answer.text}</div>}
                        </div>
                      ))}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            </TableView>
            <TableActions>
              <TableSelectedActions></TableSelectedActions>
              <Pagination onPrev={prevPage} onNext={nextPage} />
            </TableActions>
          </TableContainer>
        )}
        {data && data.tenantQAFlow && data.tenantQAFlow.length == 0 && (
          <ItemsNotFound text="Tenant Survey QA not found" />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default TenantQAFlow;
