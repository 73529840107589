export const createRoleMutation = `mutation createRole(
    $name: String, 
    $permissionIds: [Int!]!
  ) {  
    createRole(
     name: $name,
     permissionIds: $permissionIds     
    ) {
      id
    }
  }
  `;

export const updateRoleMutation = `mutation updateRole(
    $id: ID!, 
    $name: String, 
    $permissionIds: [Int!]!
  ) {  
    updateRole(
     id: $id,
     name: $name,
     permissionIds: $permissionIds     
    ) {
      id
    }
  }
  `;

export const createSystemUserMutation = `mutation createSystemUser(
    $name: String, 
    $email: String, 
    $password: String, 
    $roleIds: [Int!]!
  ) {  
    createSystemUser(
     name: $name,
     email: $email,
     password: $password,
     roleIds: $roleIds     
    ) {
      id
    }
  }
  `;

export const updateSystemUserMutation = `mutation updateSystemUser(
    $id: ID!, 
    $name: String, 
    $email: String,
    $password: String,
    $roleIds: [Int!]!
  ) {  
    updateSystemUser(
     id: $id,
     name: $name,
     email: $email,
     password: $password,
     roleIds: $roleIds     
    ) {
      id
    }
  }
  `;
