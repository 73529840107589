import React from "react";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import VabooUserInvoiceView from "./VabooUserInvoiceView";

const LandlordInvoiceView = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.ClientInvoice);
  return <VabooUserInvoiceView chargeNowPermission={Permissions.Mutation.ChargeNowClient} />;
};

export default LandlordInvoiceView;
