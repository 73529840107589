import React from "react";
import { useParams } from "react-router-dom";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import VabooUserInvoiceList from "./VabooUserInvoiceList";
import ClientOverview from "./../../components/reports/ClientOverview";
import PageSpinner from "../../mood-ui/PageSpinner";

const LandlordInvoiceList = ({ entityDetails = null }) => {
  
  const { id } = useParams();
  
  const { protectPage, hasAccess } = usePermissions();
  
  protectPage(Permissions.Query.ClientInvoices, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id },
  });

  if (!id || !entityDetails) return <PageSpinner clear />;

  return (
    <React.Fragment>
      <VabooUserInvoiceList
        viewItemPermission={hasAccess(Permissions.Query.ClientInvoice)}
        createItemPermission={hasAccess(Permissions.Mutation.CreateClientInvoice)}
        deleteItemPermission={hasAccess(Permissions.Mutation.DeleteClientInvoice)}
      >
        <ClientOverview entityDetails={ entityDetails } groupFilter={['billing', 'growth']} dense />
      </VabooUserInvoiceList>
    </React.Fragment>
  );
};

export default LandlordInvoiceList;
