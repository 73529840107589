import React from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import { TENANT_MODEL_NAME } from "../configs/constants";
import PageContainer from "../vaboo-ui/PageContainer";

const isActiveUrl = (location, urlPart) =>
  location.indexOf(urlPart) > 0 ? true : false;

const Tenant = (props) => {
  const { hasAccess } = usePermissions();
  const { id } = useParams();
  const location = useLocation();
  const rootUrl = `/${TENANT_MODEL_NAME}/${id}`;

  const page = {
    title: 'User Settings', 
    icon: 'userSettings', 
    nav: [], 
  };

  page.nav = [
    {
      name: "Primary Data",
      url: `${rootUrl}`,
      isActive: `${rootUrl}` == location.pathname ? true : false,
      icon: "home",
    },
  ];

  if (hasAccess(Permissions.Mutation.UpdateUserPassword))
    page.nav.push({
      name: "Password",
      url: `${rootUrl}/password`,
      isActive: `${rootUrl}/password` == location.pathname ? true : false,
      icon: "locker",
    });

  if (hasAccess(Permissions.Query.UserPaymentSettings))
    page.nav.push({
      name: "Payment Settings",
      url: `${rootUrl}/payment-settings`,
      isActive:
        `${rootUrl}/payment-settings` == location.pathname ? true : false,
      icon: "creditCard",
    });

  if (hasAccess(Permissions.Query.UserInvoices))
    page.nav.push({
      name: "Invoices",
      url: `${rootUrl}/invoices/1/50/0/0/0`,
      isActive: location.pathname.indexOf("invoices") > -1 ? true : false,
      icon: "ticket",
    });

  if (hasAccess(Permissions.Query.Logins))
    page.nav.push({
      name: "Logins",
      url: `${rootUrl}/logins/1/300/0/0/0`,
      // isActive: `${rootUrl}/logins` == location.pathname ? true : false,
      isActive: location.pathname.indexOf("logins") > -1 ? true : false,
      icon: "clipboardIn",
    });

  if (hasAccess(Permissions.Query.UserBenefitsLogs))
    page.nav.push({
      name: "Benefits Logs",
      url: `${rootUrl}/benefit-logs/1/50/0/0/0`,
      // isActive: `${rootUrl}/benefits-logs` == location.pathname ? true : false,
      isActive: location.pathname.indexOf("benefit-logs") > -1 ? true : false,
      icon: "pencilAlt",
    });

  if (hasAccess(Permissions.Query.UserActivityEvents))
    page.nav.push({
      name: "Activity Events",
      url: `${rootUrl}/activity-events/1/50/0/0/0`,
      // isActive: `${rootUrl}/activity-events` == location.pathname ? true : false,
      isActive: location.pathname.indexOf("activity-events") > -1 ? true : false,
      icon: "database",
    });

  return <PageContainer page={page} {...props} />;
};

export default Tenant;
