import React, { useState, createContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { authUserPermisions } from "./../graphql/queries/rolePermissionQueries";
import { Divider } from "../moose-ui/DataTable";

const AUTH_USER_PERMISSIONS = gql(authUserPermisions);

const DEFAULT_PERMISSION_DATA = { userPermissions: [] };

export const PermissionContext = createContext();

const PermissionProvider = ({ children }) => {
  const [permissionData, setPermissionData] = useState(DEFAULT_PERMISSION_DATA);
  const [permissionsReady, setPermissionsState] = useState(false);
  useQuery(AUTH_USER_PERMISSIONS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (!result || !result.authUserPermissions) return false;
      const permissionCodes = result.authUserPermissions.map(
        (permission) => permission.code
      );
      setPermissionData({
        ...permissionData,
        userPermissions: permissionCodes,
      });
      setPermissionsState(true);
    },
  });
  if (!permissionsReady)
    return <Divider>Loading App. Initializing Permissions...</Divider>;
  return (
    <React.Fragment>
      <PermissionContext.Provider value={permissionData}>
        {children}
      </PermissionContext.Provider>
    </React.Fragment>
  );
};

export default PermissionProvider;
