export const typeFilters = `query typeFilters($for: String!, $context: String, $excludeFilters: [String!]) {
    typeFilters(for:$for, context: $context, excludeFilters: $excludeFilters) {
      __typename 
      ... on Filter {
        type
        title
        queryKey
        group      
        values {
          uid
          name
          count
        }  
      }
      __typename 
      ... on DatesFilter {
        type
        title
        queryKey
        group      
        values {
          uid
          name
        }  
        from
        to
      }      
  }
}
`;
 