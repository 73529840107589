import React, { useState } from "react";
import TypeFilters from "./../../moose-ui/filters/TypeFilters";
// import Search from "./../../moose-ui/Search";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../../moose-ui/Layout";
import { ItemsNotFound } from "./../../moose-ui/Typography";
import { Pagination, FetchMoreControl } from "./../../moose-ui/DataTable";
// import { BaseTableBottomCTA } from "./../../moose-ui/tables/BaseTable";
// import {
//   Pagination,
//   FetchMoreControl,
// } from "./../../moose-ui/tables/Pagination";
import Dropzone from "./Dropzone";
import Files from "./Files";

const FileGallery = ({
  showDropzone,
  files,
  nextPage,
  prevPage,
  fetchMore = false,
  onSelectFile = false,
  onSelectFileConfirmed = false,
  showCopyLink = true,
  hasMore = false,
  onUploadComplete = false,
}) => {
  const [selectedFile, setSelectedFile] = useState(false);
  const handleSelectFile = (file) => {
    setSelectedFile(file);
    onSelectFile(file);
  };
  const handleFileDoubleClick = (file) => {
    setSelectedFile(file);
    onSelectFileConfirmed(file);
  };
  return (
    <div>
      {showDropzone && (
        <Row>
          <Col width={12}>
            <Dropzone onUploadComplete={onUploadComplete} />
          </Col>
        </Row>
      )}

      {/* <div className="flex justify-between">
        <div className="flex flex-col"></div>
        <div className="flex-11 flex-col right-0"></div>
      </div> */}
      <Row>
        <Col width={12}>
          {files.length > 0 && (
            <Files
              files={files}
              onSelectFile={onSelectFile ? handleSelectFile : () => {}}
              onSelectConfirmed={
                onSelectFileConfirmed ? handleFileDoubleClick : () => {}
              }
              selectedFile={selectedFile}
              showCopyLink={showCopyLink}
            />
          )}
          {!files ||
            (files.length == 0 && <ItemsNotFound text="Files not found" />)}
        </Col>
      </Row>
      <Row>
        <Col width={12}>
          {files.length > 0 && prevPage && nextPage && !fetchMore && (
            <Pagination onPrev={prevPage} onNext={nextPage} />
          )}
          {files.length > 0 && fetchMore && (
            <div>{hasMore && <FetchMoreControl onFetchMore={fetchMore} />}</div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FileGallery;
