import React from "react";

const FilterTitles = ({ values, options, onRemoveFilterValue }) => {
  const option = options[0];

  const titleByValue = (id) => {
    const result = option.values.filter((o) => o.uid == id);
    return result.length && result[0].name ? result[0].name : "";
  };
  return (
    <span style={{ marginRight: 15 + "px" }}>
      <strong>{option.title}:</strong>&nbsp;
      {values.map((value, index) => (
        <span
          key={index}
          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-indigo-100 text-indigo-800 mr-2"
        >
          {titleByValue(value)}
          <button
            type="button"
            className="flex-shrink-0 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700"
            onClick={() => onRemoveFilterValue(option.queryKey, value)}
          >
            <svg
              className="h-2 w-2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M1 1l6 6m0-6L1 7"
              />
            </svg>
          </button>
        </span>
      ))}
    </span>
  );
};

export default FilterTitles;
