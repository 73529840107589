import { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { typeFilters } from "./../graphql/queries/typeFilters";
import gql from "graphql-tag";
// import Controls from "../mood-ui/filters/Controls";

const TYPE_FILTERS = gql(typeFilters);

const useTypeFilters = ({
  filtersFor,
  context = "",
  onApply,
  onReset,
  queryString: initialQueryString,
  fetchPolicy = "no-cache",
  excludeFilters = [], 
}) => {
  const [ selectedFilters, setSelectedFilters ] = useState({});
  const [ resolved, setResolved ] = useState(false);
  const { 
    data, 
    loading, 
    // error, 
  } = useQuery(TYPE_FILTERS, {
    variables: {
      for: filtersFor,
      context,
      excludeFilters, 
    },
    fetchPolicy,
    onCompleted: (res) => {
      setResolved(true);
    },
    onError: (e) => {
      setResolved(true);
    },
  });

  useEffect(() => {
    if (
      initialQueryString.length > 0 &&
      initialQueryString.indexOf("?") === 0
    ) {
      let qs = initialQueryString.replace(/\?/, "");
      const queryParts = qs.split("&");
      const newSelectedFilters = {};
      const missingValues = {};
      const presentValues = {};
      for (let qp of queryParts) {
        const [queryKey, value] = qp.split("=");
        if (value?.includes('%3C_OR_%3E')) {
          newSelectedFilters[queryKey] = value.split('%3C_OR_%3E')
        }
        else if (value?.includes('<_OR_>')) {
          newSelectedFilters[queryKey] = value.split('<_OR_>')
        }
        else if (queryKey === 'missing_values') {
          const missing = value.split(',')
          missing.forEach(key => missingValues[key] = ['<_MISSING_>'])
        } 
        else if (queryKey === 'present_values') {
          const present = value.split(',')
          present.forEach(key => presentValues[key] = ['<_PRESENT_>'])
        }
        else if (value?.length > 0) {
          newSelectedFilters[queryKey] = [ value ]
        }
      }
      setSelectedFilters({...newSelectedFilters, ...presentValues, ...missingValues});
    }
  }, [initialQueryString]);

  const hasActiveFilters =
    Object.keys(selectedFilters).length > 0 ? true : false;

  const updateFilter = (queryKey, id) => {
    const newSelectedFilters = { ...selectedFilters };
    if (newSelectedFilters[queryKey]) {
      if (newSelectedFilters[queryKey].indexOf(id) > -1) {
        const newSelectedFilterValues = newSelectedFilters[queryKey].filter(
          (item) => item !== id
        );
        newSelectedFilters[queryKey] = newSelectedFilterValues;
      } else {
        newSelectedFilters[queryKey].push(id);
      }
    } else {
      newSelectedFilters[queryKey] = [id];
    }
    if (newSelectedFilters[queryKey].length == 0) {
      delete newSelectedFilters[queryKey]
    }
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  const updateDatesFilter = (queryKey, value) => {
    const newSelectedFilters = { ...selectedFilters };
    newSelectedFilters[queryKey] = [value];
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  const removeDatesFilter = (queryKey) => {
    const newSelectedFilters = { ...selectedFilters };
    delete newSelectedFilters[queryKey];
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  const updateRangeFilter = (queryKey, value) => {
    const newSelectedFilters = { ...selectedFilters };
    newSelectedFilters[queryKey] = [value];
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  const removeRangeFilter = (queryKey) => {
    const newSelectedFilters = { ...selectedFilters };
    delete newSelectedFilters[queryKey];
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  const clearFilter = (queryKey) => {
    const newSelectedFilters = { ...selectedFilters };
    delete newSelectedFilters.missing_values
    delete newSelectedFilters.present_values
    if (newSelectedFilters[queryKey]) {
      delete newSelectedFilters[queryKey]
    }
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  const clearManyFilter = (queryKeyArray) => {
    const newSelectedFilters = { ...selectedFilters };
    queryKeyArray.forEach(queryKey => {
      if (newSelectedFilters[queryKey]) {
        delete newSelectedFilters[queryKey]
      }
    })
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  const selectManyFilter = (queryKey, ids) => {
    const newSelectedFilters = { ...selectedFilters };
    newSelectedFilters[queryKey] = ids
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  const resetFilters = () => {
    setSelectedFilters({});
    // onApply({}, false);
    onReset();
  };

  const removeFilterValue = (queryKey, id) => {
    const newSelectedFilters = { ...selectedFilters };
    const updatedFilterValues = newSelectedFilters[queryKey].filter(
      (value) => value !== id
    );
    if (updatedFilterValues.length > 0) {
      newSelectedFilters[queryKey] = updatedFilterValues;
    } else {
      delete newSelectedFilters[queryKey];
    }
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  const applyFilters = (filters = false) => {
    const newSelectedFilters = { ...selectedFilters };
    Object.keys(newSelectedFilters).forEach(key => {
      if (newSelectedFilters[key].length === 0) {
        delete newSelectedFilters[key]
      }
    })
    setSelectedFilters(newSelectedFilters);
    onApply(newSelectedFilters, false);
  };

  return {
    data: data && data.typeFilters ? data.typeFilters : { typeFilters: [] }, 
    loading, 
    selectedFilters, 
    hasActiveFilters, 
    updateFilter, 
    removeFilterValue, 
    updateDatesFilter, 
    removeDatesFilter, 
    updateRangeFilter, 
    removeRangeFilter, 
    applyFilters, 
    resetFilters, 
    clearFilter, 
    clearManyFilter, 
    selectManyFilter, 
    isReady: resolved, 
  };
};

export default useTypeFilters;
