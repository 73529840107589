import React from "react";

export const FormControls = ({ children }) => (
  <div className="mt-6 pt-5">
    <div className="flex justify-end">{children}</div>
  </div>
);

export const InlineControls = ({ children, align = "end" }) => (
  <div className={`flex justify-${align}`}>{children}</div>
);

export const StdButton = ({ children, label, onClick }) => (
  <span className="inline-flex rounded-md shadow-sm">
    <button
      type="button"
      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
      onClick={onClick}
    >
      {label}
    </button>
  </span>
);

export const BasicControl = ({ onClick, label = "Button" }) => (
  <span className="inline-flex rounded-md shadow-sm">
    <button
      type="button"
      className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
      onClick={onClick}
    >
      {label}
    </button>
  </span>
);

export const SaveButton = ({ onClick, label = "Save" }) => (
  <span className="ml-3 inline-flex rounded-md shadow-sm">
    <button
      type="button"
      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
      onClick={onClick}
    >
      {label}
    </button>
  </span>
);

export const AddButton = ({ onClick, label = "Create Item" }) => (
  <span className="ml-0 inline-flex rounded-md shadow-sm">
    <button
      type="button"
      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
      onClick={onClick}
    >
      {label}
    </button>
  </span>
);

export const DeleteButton = ({ onClick, title = "Delete" }) => (
  <span className="inline-flex rounded-md shadow-sm content-right">
    <button
      onClick={onClick}
      type="button"
      className={`relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150`}
    >
      {title}
    </button>
  </span>
);

export const CancelButton = ({ onClick, label = "Cancel" }) => (
  <span className="inline-flex rounded-md shadow-sm">
    <button
      type="button"
      className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
      onClick={onClick}
    >
      {label}
    </button>
  </span>
);

export const TEditButton = ({ onClick, label = "Edit" }) => (
  <button className="text-indigo-600 hover:text-indigo-900" onClick={onClick}>
    {label}
  </button>
);

export const ListItemCheckbox = ({ onChange, isChecked }) => (
  <input
    type="checkbox"
    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
    style={{ cursor: "pointer" }}
    onChange={onChange}
    checked={isChecked}
  />
);

export const ItemOrderControl = ({ currentOrder, onOrderUp, onOrderDown }) => (
  <div className="flex align-start items-center text-gray-500">
    <div className="mr-3 text-sm leading-5 font-medium text-gray-500">
      {currentOrder}
    </div>
    <svg
      style={{ cursor: "pointer" }}
      onClick={onOrderDown}
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31658 11.9024 6.70711 12.2929L9 14.5858L9 3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44772 11 3L11 14.5858L13.2929 12.2929C13.6834 11.9024 14.3166 11.9024 14.7071 12.2929Z"
        fill="currentColor"
      />
    </svg>
    <svg
      className="ml-1"
      style={{ cursor: "pointer" }}
      onClick={onOrderUp}
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 7.70711C4.90237 7.31658 4.90237 6.68342 5.29289 6.29289L9.29289 2.29289C9.68342 1.90237 10.3166 1.90237 10.7071 2.29289L14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711C14.3166 8.09763 13.6834 8.09763 13.2929 7.70711L11 5.41421L11 17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17L9 5.41421L6.70711 7.70711C6.31658 8.09763 5.68342 8.09763 5.29289 7.70711Z"
        fill="currentColor"
      />
    </svg>
  </div>
);

export const FullWHButton = ({ onClick, label }) => (
  <button
    type="button"
    className="py-2 px-10 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
    onClick={onClick}
  >
    {label}
  </button>
);
