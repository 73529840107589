import React, { useState } from "react";
import gql from "graphql-tag";
import FileGallery from "./../mood-ui/files/FileGallery";
import { fetchMoreFiles } from "./../graphql/queries/fileQueries";
import useFetchMoreNavigation from "./../hooks/fetchMoreNavigation";
import { Row, Col } from "./Layout";
import Search from "./../mood-ui/Search";
const FETCH_MORE_FILES = gql(fetchMoreFiles);

const ImageGalleryModal = ({ onCancel, onConfirm, isVisible = false }) => {
  
  const [selectedFile, setSelectedFile] = useState(false);

  const {
    result: files,
    setResult: setFiles,
    error,
    fetchMore,
    hasMore,
    queryContext,
    applySearch,
  } = useFetchMoreNavigation({
    query: FETCH_MORE_FILES,
    entityType: "files",
    queryString: `?storageId=public_images`,
  });

  const handleUploadComplete = (cache, data) => {
    const prevData = cache.readQuery(queryContext);
    const newData = { ...prevData };
    newData.fetchMoreFiles.files = [
      data.uploadFile,
      ...newData.fetchMoreFiles.files,
    ];
    cache.writeQuery({
      ...queryContext,
      data: newData,
    });
    setFiles([...newData.fetchMoreFiles.files]);
  };

  const handleSelectConfirmation = () => {
    if (!selectedFile) return false;
    onConfirm(selectedFile);
    
    // console.log(selectedFile)
    // const asd = { public_url: selectedFile.public_url }
    // console.log(asd)
    // onConfirm(asd);
  };

  if (error) return <div>Error</div>;
  if (!isVisible) return <div></div>;

  // const visibilityStyle = "transition-all ease duration-300 " + (isVisible ? " opacity-1 mt-0" : " mt-[-101vh] opacity-0")
  const visibilityStyle = ""
  // const overlayIndex = isVisible ?  : -10000;
  // const overlayIndex = isVisible ? 100 : -10000;
  const overlayIndex = 100;

  return (
    <div
      className={`${visibilityStyle} duration-300 transition-all ease fixed inset-6 flex items-center justify-center select-none`}
      style={{ zIndex: overlayIndex }}
    >
      <div className={`fixed inset-0 duration-500 transition-all`} onClick={onCancel}>
        <div className="transition-all ease absolute inset-0 bg-gray-900 opacity-90"></div>
      </div>

      <div className={`relative grid bg-white transform transition-all ease w-full h-full pt-12 pl-10 pr-10 pb-2`}>

        <Row tweaks="pb-5 px-1 -mt-2 w-full border-b">
          <Col width={9}>
            <div className="text-xl  text-gray-700">
              Select or upload an image
            </div>
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        
        <div className="overscroll-contain overflow-y-auto h-full row-span-6">
          
          <div className="pr-4 h-min">
            
            <div className="">
              {files && (
                <FileGallery
                  showDropzone={true}
                  files={files}
                  queryString={""}
                  applyFilters={() => { }}
                  applySearch={applySearch}
                  resetFilters={() => { }}
                  nextPage={() => { }}
                  prevPage={() => { }}
                  showCopyLink={false}
                  onSelectFile={(file) => setSelectedFile(file)}
                  onSelectFileConfirmed={(file) => {
                    setSelectedFile(file);
                    handleSelectConfirmation();
                  }}
                  fetchMore={fetchMore}
                  hasMore={hasMore}
                  onUploadComplete={handleUploadComplete}
                />
              )}
            </div>
          </div>
        </div>
        
        <div className={`mt-auto flex justify-end bg-white py-4 pr-2 max-h-[70.17px] border-t`} >
          <span className="flex w-full w-auto">
            <button
              type="button"
              className="inline-flex justify-center w-full border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={handleSelectConfirmation}
            >
              Select
            </button>
          </span>
          <span className="flex w-full w-auto -mr-1 ml-2">
            <button
              type="button"
              className="inline-flex justify-center w-full border border-gray-300 px-4 py-2 bg-white text-base leading-6 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={onCancel}
            >
              Cancel
            </button>
          </span>
        </div>


      </div>
    </div>
  );
};

export default ImageGalleryModal;
