export const allFiles = `query files($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    files(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      original_name
      filename
      filepath
      public_url
      title 
      description
      mimetype
      size
      width
      height
    }
  }
  `;

export const fetchMoreFiles = `query fetchMoreFiles($cursor: Int!, $search: String!, $queryString: String) {  
    fetchMoreFiles(cursor: $cursor, search: $search, queryString: $queryString) {
      hasMore
      cursor
      files {
        id
        original_name
        filename
        filepath
        public_url
        title 
        description
        mimetype
        size
        width
        height
      }
    }
  }
  `;
