import React from "react";
import Moment from "react-moment";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allOffers } from "./../graphql/queries/benefitQueries";
import { deleteOffersMutation } from "./../graphql/mutations/benefitMutations"; 
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useListSelection from "./../hooks/rowSelection";
import useNotification from "./../hooks/notifications";
import useModal from "./../hooks/modal";
import { OFFER_MODEL_NAME } from "./../configs/constants";
import { ConfirmActionModal } from "./../mood-ui/Modals";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../mood-ui/Notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import { PageControls, TEditButton, AddButton } from "./../mood-ui/Controls";
import Search from "./../mood-ui/Search";
import { ListItemCheckbox } from "./../mood-ui/Controls";
import { ItemsNotFound, ColorLabel } from "./../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow, 
  TCol,
  TContent,
  THCol,
  // TableActions,
  TableSelectedActions,
  Pagination,
} from "./../mood-ui/DataTable";
import OfferListActions from "./../components/list-secondary-actions/OfferListActions";
import useItemCounter from "../hooks/itemCounter";
import OfferTile from "../mood-ui/content/OfferTile";

const ALL_OFFERS = gql(allOffers);

const DELETE_OFFERS = gql(deleteOffersMutation);

const OfferList = ({ entityDetails: { offers, cards, merchants } }) => {

  const history = useHistory();
  
  const { protectPage, hasAccess } = usePermissions();
  
  protectPage(Permissions.Query.Offers);

  const getRelatedOffers = (offerIds = '') => {
    if (!offers || !cards || !merchants) return null
    let ids = offerIds?.replace(/\s/g, '').split(',')
    return ids.map(id => [ ...offers, ...cards ].find(el => el.id == id) )
  }
  
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
    isAllRowsSelected, 
    toggleAllRows, 
  } = useListSelection();
  
  const { page, limit, orderBy, orderHow, search } = useParams();
  
  const { search: queryString } = useLocation();
  
  const { modalState, showModal, closeModal } = useModal();
  
  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();
  
  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();
  
  const { data, loading, error, refetch } = useQuery(ALL_OFFERS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "offers" });

  const {
    nextPage,
    prevPage,
    editForm,
    createForm,
    applySearch,
    // applySorting,
  } = useBaseNavigation(OFFER_MODEL_NAME, { showingItemsCount: itemsTotal });

  const [deleteOffers] = useMutation(DELETE_OFFERS, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Offers Deleted",
        message: "Successfully deleted selected offers",
        secondsToDisplay: 1,
      });
      clearSelection();
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to delete selected offers",
        secondsToDisplay: 2,
      });
    },
    update: (cache, { data }) => {
      if (!data.deleteOffers) return false;
      const deletedIds = data.deleteOffers.map((cl) => cl.id);
      const existingOffers = cache.readQuery({
        query: ALL_OFFERS,
        variables: {
          page,
          limit,
          orderBy,
          orderHow,
          search,
          queryString,
        },
      });
      const newOffers = existingOffers.offers.filter(
        (cl) => deletedIds.indexOf(cl.id) === -1
      );
      cache.writeQuery({
        query: ALL_OFFERS,
        variables: {
          page,
          limit,
          orderBy,
          orderHow,
          search,
          queryString,
        },
        data: { offers: newOffers },
      });
      refetch();
    },
  });

  const confirmDeletion = () => {
    if (!hasAccess(Permissions.Mutation.DeleteOffer)) {
      alert("You do not have a permission to delete offers");
      return false;
    }
    showModal({
      heading: "Confirm Deletion",
      message:
        "Are you sure you want to DELETE selected offers? Do not proceed if you are not sure.",
      mood: "Danger",
      confirmButtonLabel: "Delete",
      onConfirm: () => {
        closeModal();
        handleDeleteSections();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteSections = () => {
    deleteOffers({ variables: { ids: selectedRows } });
  };

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Mutation.CreateOffer) && (
              <AddButton label="Add new offer" onClick={() => history.push('/offer/create')} loading={loading}  />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row> 
        <TableContainer tweaks="px-5 pb-5">
          <TableView>
            <THeading>
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() => toggleAllRows(data?.offers?.map(offer => offer.id))}
                    isChecked={isAllRowsSelected(data?.offers?.map(offer => offer.id)) ? true : false}
                  />
                </THCol>
                <THCol tweaks="w-8 text-left" name="image" value="" />
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="admin_title" value="Admin title" />
                <THCol tweaks="text-left" name="code" value="Slug" />
                <THCol tweaks="text-left" name="start" value="Starts" />
                <THCol tweaks="text-left" name="end" value="Ends" />
                <THCol tweaks="text-left" name="status" value="Status" />
                <THCol tweaks="text-left" name="related" value="Related offers" />
                <THCol tweaks="w-16 text-center" />
                <THCol tweaks="w-8 text-center" />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.offers && data.offers.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <ItemsNotFound text="No offers found" tweaks="" />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.offers && data.offers.length > 0 && (
              <TContent>
                {data.offers.map((offer) => (
                  <TRow key={offer.id} isSelected={rowSelected(offer.id)}>
                    <TCol tweaks="w-4 text-center">
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(offer.id)}
                        isChecked={rowSelected(offer.id) ? true : false}
                      />
                    </TCol>
                    <TCol tweaks="inline-flex items-center pt-0 pr-0 pl-0 pb-0"  truncate={false}>
                      <OfferTile offer={offer} />
                    </TCol>
                    <TCol value={offer.id} />
                    <TCol tweaks="max-w-[120px]">
                      <abbr title={offer.admin_title}>
                        {offer.admin_title}
                      </abbr>
                    </TCol>
                    <TCol tweaks="max-w-[120px]" value={offer.code} />
                    <TCol tweaks={`max-w-[70px] ${ (new Date(offer.start_date).setHours(0,0,0,0)) <= (new Date().setHours(0, 0, 0, 0)) ? '' : ' text-rose-600 bg-rose-100' }`}>
                      <Moment format="DD-MM-YYYY" date={offer.start_date} />
                    </TCol>
                    <TCol tweaks={`max-w-[70px] ${ (new Date(offer.end_date).setHours(0,0,0,0)) > (new Date().setHours(0, 0, 0, 0)) ? '' : ' text-rose-600 bg-rose-100' }`}>
                      <Moment format="DD-MM-YYYY" date={offer.end_date} />
                    </TCol>
                    <TCol tweaks="max-w-[70px]">
                      {
                        offer.status === "Active" 
                        ? <ColorLabel text={offer.status} color="emerald" /> 
                        : <ColorLabel text={offer.status} color="rose" />
                      }
                    </TCol>
                    <TCol tweaks="max-w-[600px] px-[2px] pt-0 pb-0 bg-slate-100 border-white overflow-visible inline-flex flex-wrap items-center gap-[2px]" truncate={false}>
                      {
                        getRelatedOffers(offer?.related_offers)?.map((o, index) => {
                          return <OfferTile key={index} offer={o} />
                        })
                      }
                    </TCol>
                    <TCol tweaks="w-16 text-center"> 
                      {hasAccess(Permissions.Query.Offer) && (
                        <TEditButton onClick={() => editForm(offer.id)} />
                      )}
                    </TCol>
                    <TCol tweaks="w-8 text-center"> 
                      <a 
                        target="_BLANK" 
                        href={`https://fakeagent.vaboo.co.uk/view/${ offer.merchant.slug }/${ offer.code }`} 
                        className="text-indigo-600 hover:text-indigo-900 select-none focus:outline-none"
                      >
                        <span className="text-[15px] mdi mdi-launch"></span>
                      </a>
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={2}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <OfferListActions
                      onClearSelection={clearSelection}
                      onDelete={confirmDeletion}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={7} extraStyles="">
              </Col>
              <Col width={3}>
                <Pagination onPrev={page > 1 ? prevPage : false} onNext={data?.offers?.length >= limit - 1 ? nextPage : false} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default OfferList;
