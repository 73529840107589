import React from "react";
import { 
  // useParams, 
  // useHistory, 
  useLocation, 
} from "react-router-dom";
// import { CLIENT_MODEL_NAME } from "../configs/constants";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import PageContainer from "../vaboo-ui/PageContainer";

const Settings = (props) => {
  
  const { hasAccess } = usePermissions();
  // const { id }       = useParams();
  const location      = useLocation();
  const rootUrl       = ``;

  const page = {
    title: 'Clients', 
    icon: 'client', 
    nav: [],
  }; 

  document.title = location.pathname.split('/')[2] === ''        ? 'Clients overview' 
                 : location.pathname.split('/')[1] === 'clients' ? 'Explore clients' 
                 : location.pathname.split('/')[1] === 'client'  ? 'Add client' 
                 : 'Clients'

  if (hasAccess(Permissions.Query.Clients))
    page.nav.push({
      name: "Overview",
      url: `${rootUrl}/`,
      isActive: location.pathname.split('/')[2] === '' ? true : false,
      icon: "chart",
    });

  if (hasAccess(Permissions.Query.Clients))
    page.nav.push({
      name: "All clients",
      url: `${rootUrl}/clients/1/50/0/0/0`,
      isActive: location.pathname.split('/')[1] === 'clients' ? true : false,
      icon: "list",
    });

  if (hasAccess(Permissions.Mutation.CreateClient))
    page.nav.push({
      name: "New client",
      url: `${rootUrl}/client/create`,
      isActive: location.pathname.split('/')[1] === 'client' ? true : false,
      icon: "add",
    });

  return <PageContainer page={page} {...props } hasDynamicLists={true} />;
};
 
export default Settings;
