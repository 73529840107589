import React from "react";
import Moment from "react-moment";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allBenefitLogs } from "./../../graphql/queries/reportQueries";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useRootPageContext from "./../../hooks/rootPageContext";
import Permissions, { tenantNoAccessFallbackList } from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import { BENEFIT_LOG_TYPE_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import { Heading } from "./../../moose-ui/Typography";
import Search from "./../../moose-ui/Search";
import { ItemsNotFound } from "./../../moose-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableActions,
  TableSelectedActions,
  Pagination,
} from "./../../moose-ui/DataTable";

const ALL_USER_BENEFIT_LOGS = gql(allBenefitLogs);

const VabooUserBenefitLogs = () => {
  const { id: userId, page, limit, orderBy, orderHow, search } = useParams();
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.UserBenefitsLogs, {
    noAccessFallbackList: tenantNoAccessFallbackList,
    variables: { id: userId }
  });
  const { search: queryString } = useLocation();
  const { rootContext } = useRootPageContext();
  const { data, loading, error } = useQuery(ALL_USER_BENEFIT_LOGS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString: `?user=${userId}`,
    },
    fetchPolicy: "cache-and-network",
  });
  const { nextPage, prevPage, applySearch } = useBaseNavigation(
    BENEFIT_LOG_TYPE_MODEL_NAME,
    {
      urlContext: `${rootContext}/${userId}`,
    }
  );

  if (error) return <div>Error</div>;
  if (!data) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text={loading ? "Benefit Logs. Loading..." : "Benefit Logs"} />
        <Row>
          <Col width={9}></Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>

        {data && data.benefitLogs && data.benefitLogs.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol name="id" value="ID" />
                  <THCol name="datetime" value="Datetime" />
                  <THCol name="ip" value="IP" />
                  <THCol name="useragent" value="Useragent" />
                </TRow>
              </THeading>
              <TContent>
                {data.benefitLogs.map((bl) => (
                  <TRow key={bl.id}>
                    <TCol value={bl.id} />
                    <TCol value={bl.datetime} />
                    <TCol value={bl.ip} />
                    <TCol value={bl.useragent} />
                  </TRow>
                ))}
              </TContent>
            </TableView>
            <TableActions>
              <TableSelectedActions></TableSelectedActions>
              <Pagination onPrev={prevPage} onNext={nextPage} />
            </TableActions>
          </TableContainer>
        )}
        {data && data.benefitLogs && data.benefitLogs.length == 0 && (
          <ItemsNotFound text="Benefit Logs not found" />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default VabooUserBenefitLogs;
