import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { membershipVoucherDetails } from "./../graphql/queries/billingQueries";
import { updateMembershipVoucherMutation } from "./../graphql/mutations/billingMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import { MEMBERSHIP_VOUCHER_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../mood-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "./../mood-ui/Controls";
import { Heading } from "./../mood-ui/Typography";
import {
  TextField,
  RichTextEditorField,
  NumberField,
  SelectField,
  DateField,
} from "./../mood-ui/Fields";
import { ErrorNotification } from "../mood-ui/Notifications";
import useNotification from "../hooks/notifications";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const VOUCHER_DETAILS = gql(membershipVoucherDetails);
const UPDATE_VOUCHER = gql(updateMembershipVoucherMutation);

const MembershipVoucher = () => {
  const { protectPage } = usePermissions();

  const { id } = useParams();

  protectPage(Permissions.Mutation.ManageMembershipVouchers);

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const { goBack } = useBaseNavigation(MEMBERSHIP_VOUCHER_MODEL_NAME);

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
    syncView,
  } = useHyperState("formData", {
    title: "",
    code: "",
    description: "",
    type: "Fixed_Discount",
    max_uses_per_user: 10,
    released: 10,
    monthly_value: "",
    annually_value: "",
    lifetime_value: "",
    expires_at: "2024-12-31",
  });

  const { setFieldValue: handleFieldOnChangeProxied } = useBaseFieldSetters(
    formData,
    stateMethods,
    clearError
  );

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  useQuery(VOUCHER_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.membershipVoucher) return false;
      setFormData({
        ...result.membershipVoucher,
      });
    },
    onError: (e) => {
      console.log("e", e);
    },
  });

  const [updateVoucher, { loading: isUpdating }] = useMutation(UPDATE_VOUCHER, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
      displayErrorNotification({
        heading: "Validation error",
        message:
          "Looks like this code is alread in use, please try another one.",
        secondsToDisplay: 5,
      });
    },
  });

  const handleSave = () => {
    const data = { ...formData };

    if (data.type === "Fixed_Discount") {
      let hasErrors = false;
      if (
        parseInt(data.monthly_value) > 100 ||
        parseInt(data.monthly_value) < 0
      ) {
        hasErrors = true;
        setFieldError("monthly_value", "Value must be between 0 and 100");
      }
      if (
        parseInt(data.annually_value) > 100 ||
        parseInt(data.annually_value) < 0
      ) {
        hasErrors = true;
        setFieldError("annually_value", "Value must be between 0 and 100");
      }
      if (
        parseInt(data.lifetime_value) > 100 ||
        parseInt(data.lifetime_value) < 0
      ) {
        hasErrors = true;
        setFieldError("lifetime_value", "Value must be between 0 and 100");
      }
      if (hasErrors) return;
    }

    updateVoucher({ variables: data });
  };

  const [isDraft, setDraft] = useState(false);

  if (!formData || !formData.id) return <PageSpinner />;

  const isPublished = formData.status === "Published";

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text={
                isPublished
                  ? "View published Membership Voucher"
                  : "Update a Membership Voucher"
              }
              description="Membership Vouchers (Promocodes) are used for giving members discounts when they upgrade to premium access or change membership plan."
            />
            {isPublished && (
              <p className={"mt-1 text-sm leading-5 text-gray-400 select-none"}>
                <strong>
                  This voucher was Published. Changes are not allowed.
                </strong>
              </p>
            )}
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading size="md" text="Voucher details" description="" />
                    <p
                      className={
                        "mt-1 text-sm leading-5 text-gray-400 select-none"
                      }
                    >
                      Primary details about voucher like title, code and type.
                      <br />
                      <br />
                      How the type field will work when a voucher is appplied
                      <br />- <strong>Fixed_Discount</strong> - will adjust
                      Monthly | Annually | Lifetime price with the respetive
                      percentage applied for each plan
                      <br />- <strong>Fixed_Price</strong> - will overwrite the
                      price with the fixed value - Monthly | Annually | Lifetime
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={5}>
                    <TextField
                      name="title"
                      label="Title"
                      value={formData.title}
                      onChange={handleFieldOnChange}
                      error={fieldError("title")}
                      isRequired={true}
                      minlength={3}
                      maxlength={48}
                      description=""
                      disabled={isPublished}
                    />
                  </Col>
                  <Col width={4}>
                    <TextField
                      name="code"
                      label="Code"
                      value={formData.code}
                      onChange={handleFieldOnChange}
                      error={fieldError("code")}
                      isRequired={true}
                      maxlength={64}
                      description="Voucher code that user will apply"
                      disabled={isPublished}
                    />
                  </Col>
                  <Col width={3}>
                    <SelectField
                      value={formData.type}
                      values={[
                        { id: "Fixed_Discount", name: "Fixed_Discount" },
                        { id: "Fixed_Price", name: "Fixed_Price" },
                      ]}
                      label="Type"
                      name="type"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                      valueKey="id"
                      labelKey="name"
                      isRequired={true}
                      error={fieldError("type")}
                      description=""
                      isDisabled={isPublished}
                    />
                  </Col>
                  <Col width={12}>
                    <RichTextEditorField
                      name="description"
                      label="Description"
                      value={formData.description}
                      onChange={handleFieldOnChange}
                      error={fieldError("description")}
                      rows={3}
                      disabled={isPublished}
                      description="Supporting text that describes how exatctly a voucher works"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Voucher usage and release"
                      description=""
                    />
                    <p
                      className={
                        "mt-1 text-sm leading-5 text-gray-400 select-none"
                      }
                    >
                      Setup how many times this voucher can be used by the user
                      and how many vouchers should be released - how many times
                      this voucher can be applied by different users.
                      <br />
                      <br />
                      Field details:
                      <br />- <strong>released</strong> - how many vouchers
                      should be released and be available for users
                      <br />- <strong>max_uses_per_user</strong> - how many
                      times a voucher will be applied by a single user. 1 time -
                      equals to 1 payment by user. For example, if user pays
                      Monthly and max_uses_per_user is 3, then user will have 3
                      payments with discount.
                      <br />
                      <br />- <strong>expires_at</strong>- date when this
                      voucher will expire. Note: this does not affect the usage
                      of the voucher that were already applied by members, but
                      the availability of the voucher for the next members.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4}>
                    <NumberField
                      name="released"
                      label="Released"
                      value={formData.released}
                      onChange={handleFieldOnChange}
                      error={fieldError("released")}
                      isRequired={true}
                      minlength={3}
                      maxlength={48}
                      description="How many vouchers should be released"
                      disabled={isPublished}
                    />
                  </Col>
                  <Col width={4}>
                    <NumberField
                      name="max_uses_per_user"
                      label="Max uses per user"
                      value={formData.max_uses_per_user}
                      onChange={handleFieldOnChange}
                      error={fieldError("max_uses_per_user")}
                      isRequired={true}
                      minlength={3}
                      maxlength={48}
                      description="One use - equals to one payment by user"
                      disabled={isPublished}
                    />
                  </Col>
                  <Col width={4}>
                    <DateField
                      name="expires_at"
                      label="Expires At"
                      value={formData.expires_at}
                      onChange={handleFieldOnChange}
                      error={fieldError("expires_at")}
                      isRequired={true}
                      description=""
                      disabled={isPublished}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading size="md" text="Voucher values" description="" />
                    {formData.type === "Fixed_Discount" && (
                      <p
                        className={
                          "mt-1 text-sm leading-5 text-gray-400 select-none"
                        }
                      >
                        Values for Fixed_Discount
                        <br />
                        <br />
                        Set the percentage value for each plan - Monthly,
                        Annually and Lifetime. If left empty - voucher for this
                        plan will use default price, no discount. All values
                        must be between 0 and 100.
                      </p>
                    )}
                    {formData.type === "Fixed_Price" && (
                      <p
                        className={
                          "mt-1 text-sm leading-5 text-gray-400 select-none"
                        }
                      >
                        Values for Fixed_Price
                        <br />
                        <br />
                        Set specific prices for each plan - Monthly, Annually
                        and Lifetime. If left empty - voucher for this plan will
                        use default price, no discount.
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4}>
                    <NumberField
                      name="monthly_value"
                      label="Monthly value"
                      value={formData.monthly_value}
                      onChange={handleFieldOnChange}
                      error={fieldError("monthly_value")}
                      isRequired={true}
                      minlength={3}
                      maxlength={48}
                      description=""
                      disabled={isPublished}
                      step=".01"
                    />
                  </Col>
                  <Col width={4}>
                    <NumberField
                      name="annually_value"
                      label="Annually value"
                      value={formData.annually_value}
                      onChange={handleFieldOnChange}
                      error={fieldError("annually_value")}
                      isRequired={true}
                      minlength={3}
                      maxlength={48}
                      description=""
                      disabled={isPublished}
                      step=".01"
                    />
                  </Col>
                  <Col width={4}>
                    <NumberField
                      name="lifetime_value"
                      label="Lifetime value"
                      value={formData.lifetime_value}
                      onChange={handleFieldOnChange}
                      error={fieldError("lifetime_value")}
                      isRequired={true}
                      minlength={3}
                      maxlength={48}
                      description=""
                      disabled={isPublished}
                      step=".01"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          {isPublished && (
            <FormControls>
              <CancelButton onClick={goBack} label="Go back" />
            </FormControls>
          )}
          {!isPublished && (
            <FormControls>
              <CancelButton onClick={goBack} />
              <SaveButton onClick={handleSave} loading={isUpdating} />
            </FormControls>
          )}
        </FormContainer>
      </BlockContainer>
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default MembershipVoucher;
