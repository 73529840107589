import React from "react";

const GenericDataModal = ({ isVisible = false, children }) => {
  const visibilityStyle = isVisible ? "" : "hidden";
  return (
    <div
      className="z-[99]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ visibility: visibilityStyle }}
    >
      <div className="fixed inset-0 z-[99] bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-[99] overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          {children}
        </div>
      </div>
    </div> 
  );
};

export const GenericSaveControls = ({ onSave, onCancel, disabled = true }) => (
  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 select-none">
    <button
      type="button"
      className={`
        inline-flex w-full justify-center border border-transparent bg-indigo-600 px-4 py-1.5 text-sm text-white hover:bg-indigo-700 
        focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2
        disabled:bg-indigo-300
      `}
      onClick={onSave}
      disabled={disabled}
    >
      Save
    </button>
    <button
      type="button"
      className="mt-3 inline-flex w-full justify-center border border-gray-300 bg-white px-4 py-1.5 text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0"
      onClick={onCancel}
    >
      Cancel
    </button>
  </div>
);

export default GenericDataModal;
