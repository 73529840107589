import { useState } from "react";

const useSimpleModal = () => {
  const [state, setState] = useState(false);

  const showModal = () => setState(true);

  const closeModal = () => setState(false);

  return { isVisible: state, showModal, closeModal };
};

export default useSimpleModal;
