import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  allPermissionGroups,
  roleDetails,
} from "./../graphql/queries/rolePermissionQueries";
import { updateRoleMutation } from "./../graphql/mutations/rolePermissionMutation";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import { ROLE_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../mood-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "./../mood-ui/Controls";
import { Heading } from "./../mood-ui/Typography";
import { TextField, ToggleCheckboxField } from "./../mood-ui/Fields";

const ALL_PERMISSION_GROUPS = gql(allPermissionGroups);
const ROLE_DETAILS = gql(roleDetails);
const UPDATE_ROLE = gql(updateRoleMutation);

const Role = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.Role);
  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const { id } = useParams();
  const { goBack } = useBaseNavigation(ROLE_MODEL_NAME, {
    urlContext: "settings",
  });

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    id,
    name: "",
    permissionIds: [],
  });

  const {
    setFieldValue: handleFieldOnChange,
    toggleArrayFieldValue,
    arrayFieldHasValue,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const { data: pgData, loading: loadingPG } = useQuery(ALL_PERMISSION_GROUPS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log("e", e);
    },
  });

  const { loading: loadingRole } = useQuery(ROLE_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (!result || !result.role) return false;
      const pIds = result.role.permissions.map((p) => p.id);
      setFormData({
        id: result.role.id,
        name: result.role.name,
        permissionIds: pIds,
      });
    },
    onError: (e) => {
      console.log("e", e);
    },
  });

  const [updateRole] = useMutation(UPDATE_ROLE, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    updateRole({ variables: data });
  };

  const processPermissionLabel = (label) => {
    if (label.split('.').length == 2) {
      return {
        label: label.split('.')[1], 
        action: label.split('.')[0], 
      }
    }
    return {
      label: label.split('.')[0], 
      action: '', 
    }
  }

  const [ isDraft, setDraft ] = useState(false)

  // if (!formData || !pgData || loadingRole) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update an existing role"
              description="Roles allow the Vaboo team to be hands-on while only accessing what they need.
                           This approach helps to safeguard the company and our customers in the event of password leaks or device theft.
                           Each time this page is used to provide access to the team, those making changes should look for existing 
                           permissions that are no longer needed and can be removed."
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-8 mx-5">
              <Col width={4}>
                <TextField
                  loading={!formData || !pgData?.permissionGroups || loadingRole}
                  name="name"
                  label="Role name"
                  value={formData?.name}
                  onChange={(e) => { setDraft(d => true); handleFieldOnChange(e) }}
                  error={fieldError("name")}
                  isRequired={true}
                />
              </Col>
            </Row>
            {(loadingRole || !pgData?.permissionGroups || !formData) && (
              <Col width={12} extraStyles="h-[330px] mt-20 px-5">
                <PageSpinner fill />
              </Col>
            )}

            {!loadingRole && formData && pgData?.permissionGroups?.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
            .map((pg, pgIdx) => (
              <React.Fragment key={pgIdx}>
                <Row tweaks="pt-8 pb-5 mx-5">
                  <Col width={12}>
                    <Heading
                      inset={true}
                      text={pg.name}
                      size="md"
                    />
                  </Col>
                </Row>
                <Row tweaks="border-b border-[#dfe2e8] pb-8 mx-5 gap-0">
                  {pg.permissions.map((permission, pIdx) => (
                    <Col key={permission.id} width={2}>
                      <ToggleCheckboxField
                        id={permission.id}
                        label={processPermissionLabel(permission.code).label}
                        action={processPermissionLabel(permission.code).action}
                        description={permission.description}
                        flush
                        isChecked={arrayFieldHasValue(
                          "permissionIds",
                          permission.id
                        )}
                        onChange={(e) => {
                          toggleArrayFieldValue(
                            "permissionIds",
                            e.target.value
                          );
                          setDraft(d => true); 
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </React.Fragment>
            ))}
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            {hasAccess(Permissions.Mutation.UpdateRole) && (
              <SaveButton onClick={handleSave} disabled={!isDraft} />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default Role;