import React from "react";
import File from "./File";

const Files = ({
  files,
  showCopyLink,
  onSelectFile,
  onSelectConfirmed,
  selectedFile
}) => {
  return (
    <div>
      <div className="mt-4 grid grid-cols-1 row-gap-4 col-gap-4 sm:grid-cols-4">
        {files.map(file => (
          <File
            key={file.id}
            file={file}
            showCopyLink={showCopyLink}
            onSelect={onSelectFile}
            onSelectConfirmed={onSelectConfirmed}
            isSelected={
              selectedFile && selectedFile.id && selectedFile.id == file.id
                ? true
                : false
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Files;
