import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allDirectRegisterLinks } from "./../../graphql/queries/landlordQueries";
import { deleteDirectRegisterLinksMutation } from "./../../graphql/mutations/landlordMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useListSelection from "./../../hooks/rowSelection";
import useNotification from "./../../hooks/notifications";
import useModal from "./../../hooks/modal";
import { DIRECT_REGISTER_LINK_TYPE_MODEL_NAME } from "./../../configs/constants";
import { ConfirmActionModal } from "./../../mood-ui/Modals";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../mood-ui/Notifications";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../mood-ui/Layout";
import { PageControls, TEditButton, AddButton } from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import Search from "./../../mood-ui/Search";
import { ListItemCheckbox } from "./../../mood-ui/Controls";
import { ItemsNotFound } from "./../../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  Pagination,
} from "./../../mood-ui/DataTable";

import DirectRegisterLinkListActions from "./../../components/list-secondary-actions/DirectRegisterLinkListActions";

const ALL_DIRECT_REGISTER_LINKS = gql(allDirectRegisterLinks);
const DELETE_DIRECT_REGISTER_LINKS = gql(deleteDirectRegisterLinksMutation);

const LandlordDirectRegisterLinkList = () => {
  const { id: ownerId, page, limit, orderBy, orderHow, search } = useParams();
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Mutation.ManageDirectRegisterLinks, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: ownerId },
  });
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
    isAllRowsSelected,
    toggleAllRows,
  } = useListSelection();
  // const { search: queryString } = useLocation();
  const { modalState, showModal, closeModal } = useModal();
  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();
  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();
  const { data, loading, error } = useQuery(ALL_DIRECT_REGISTER_LINKS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
      queryString: `?owner=${ownerId}`,
    },
    fetchPolicy: "cache-and-network",
  });
  const { nextPage, prevPage, editForm, createForm, applySearch } =
    useBaseNavigation(DIRECT_REGISTER_LINK_TYPE_MODEL_NAME, {
      urlContext: `clients/${ownerId}`,
    });

  const [deleteDirectRegisterLinks] = useMutation(
    DELETE_DIRECT_REGISTER_LINKS,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Direct Register Links Deleted",
          message: "Successfully deleted selected items",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to delete selected items",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.deleteDirectRegisterLinks) return false;

        const deletedIds = data.deleteDirectRegisterLinks.map((cl) => cl.id);
        const existingLinks = cache.readQuery({
          query: ALL_DIRECT_REGISTER_LINKS,
          variables: {
            page,
            limit,
            orderBy,
            orderHow,
            search,
            queryString: `?owner=${ownerId}`,
          },
        });
        const newLinks = existingLinks.directRegisterLinks.filter(
          (cl) => deletedIds.indexOf(cl.id) === -1
        );
        cache.writeQuery({
          query: ALL_DIRECT_REGISTER_LINKS,
          variables: {
            page,
            limit,
            orderBy,
            orderHow,
            search,
            queryString: `?owner=${ownerId}`,
          },
          data: { directRegisterLinks: newLinks },
        });
      },
    }
  );

  const confirmDeletion = () => {
    showModal({
      heading: "Confirm Deletion",
      message:
        "Are you sure you want to DELETE selected direct register links? Do not proceed if you are not sure.",
      mood: "Danger",
      confirmButtonLabel: "Delete",
      onConfirm: () => {
        closeModal();
        handleDeleteItems();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteItems = () =>
    deleteDirectRegisterLinks({ variables: { ids: selectedRows } });

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Direct Register Links"
              description="Direct Register Links can by used by client to provide their customers with a link to register directly with the client."
            />
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Mutation.ManageCustomLinks) && (
              <AddButton
                label="Add direct register link"
                onClick={createForm}
                loading={loading}
              />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        <TableContainer tweaks="px-5 pb-5">
          <TableView
            tweaks={
              loading ||
              !data ||
              (data &&
                data.directRegisterLinks &&
                data.directRegisterLinks.length === 0)
                ? "border-[#ebedf2]"
                : ""
            }
          >
            <THeading
              tweaks={
                loading ||
                !data ||
                (data &&
                  data.directRegisterLinks &&
                  data.directRegisterLinks.length === 0)
                  ? "opacity-30 border-b"
                  : ""
              }
            >
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() =>
                      toggleAllRows(
                        data?.directRegisterLinks?.map((cl) => cl.id)
                      )
                    }
                    isChecked={
                      isAllRowsSelected(
                        data?.directRegisterLinks?.map((cl) => cl.id)
                      )
                        ? true
                        : false
                    }
                  />
                </THCol>
                <THCol
                  tweaks="text-left"
                  name="register_code"
                  value="Register Code"
                />
                <THCol tweaks="text-left" name="max_users" value="Max Users" />
                <THCol
                  tweaks="text-left"
                  name="regs_used"
                  value="Used Registrations"
                />
                <THCol
                  tweaks="text-left"
                  name="access_type"
                  value="Access Type"
                />
                <THCol tweaks="text-left" name="label" value="Label" />
                <THCol tweaks="text-left" name="enabled" value="Enabled" />
                <THCol tweaks="w-16 text-center" />
              </TRow>
            </THeading>
            {loading && (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <PageSpinner inline extraStyles="h-[300px]" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data &&
              data.directRegisterLinks &&
              data.directRegisterLinks.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan={"100%"}>
                      <ItemsNotFound
                        text="No direct register links found."
                        tweaks="mx-5"
                      />
                    </td>
                  </tr>
                </tbody>
              )}
            {!loading &&
              data &&
              data.directRegisterLinks &&
              data.directRegisterLinks.length > 0 && (
                <TContent>
                  {[...data?.directRegisterLinks].map((drl) => (
                    <TRow key={drl.id} isSelected={rowSelected(drl.id)}>
                      <TCol tweaks="w-4 text-center">
                        <ListItemCheckbox
                          onChange={() => toggleRowSelection(drl.id)}
                          isChecked={rowSelected(drl.id) ? true : false}
                        />
                      </TCol>
                      <TCol>
                        <p className="text-xs text-[#592373] opacity-60 underline underline-offset-4 mt-2 select-none">
                          <a
                            href={drl.register_link}
                            rel="noreferrer"
                            target="_BLANK"
                          >
                            {drl.register_code}
                          </a>
                        </p>
                      </TCol>
                      <TCol>{drl.max_users_per_register_link}</TCol>
                      <TCol>{drl.used_registrations_count}</TCol>
                      <TCol>{drl.register_access_type}</TCol>
                      <TCol>{drl.label?.name}</TCol>
                      <TCol>{drl.is_enabled ? "Yes" : "No"}</TCol>
                      <TCol tweaks="w-16 text-center">
                        {hasAccess(Permissions.Query.ClientCustomLink) && (
                          <TEditButton onClick={() => editForm(drl.id)} />
                        )}
                      </TCol>
                    </TRow>
                  ))}
                </TContent>
              )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={4}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <DirectRegisterLinkListActions
                      onClearSelection={clearSelection}
                      onDelete={confirmDeletion}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={5} extraStyles="">
                <div className="flex">
                  {/* dynamic list filter functionality here  */}
                </div>
              </Col>
              <Col width={3}>
                <Pagination onPrev={prevPage} onNext={nextPage} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default LandlordDirectRegisterLinkList;
