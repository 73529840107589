import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const Search = ({ onApplySearch: applySearch, disabled = false }) => {
  
  const [query, setQuery] = useState('');

  const [isDebouncing, setDebouncing] = useState(false);

  const { search } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => { 
      setDebouncing(false)
      if (query?.length > 2) {
        applySearch(query)
      }
    }, 900)
    return () => clearTimeout(timer)
  }, [query]);

  useEffect(() => {
    if (search !== "0" && search !== undefined) {
      setQuery(search);
    }
  }, []);
  
  const readableQuery = () => query == 0 || query == "0" || query == "all" ? "" : query;

  return (
    <div className="relative select-none">
      <div className="absolute inset-y-0 left-0 pl-[7px] flex items-center pointer-events-none">
        <svg
          className={`h-4 w-4 ${ disabled ? 'text-gray-300' : 'text-gray-400' }`}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <input
        id="search"
        className={`-z-1 block w-full pl-7 pr-10 py-1 border border-[#dfe2e8] leading-5 bg-white placeholder-gray-400 focus:outline-none 
                    focus:placeholder-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm transition duration-150 ease-in-out
                    text-gray-500
                    disabled:placeholder-gray-300 disabled:bg-gray-50 
                    `}
        placeholder="Search"
        value={readableQuery()}
        onChange={e => {
          setQuery(e.target.value);
          setDebouncing(true)
        }}
        onKeyUp={e => {
          if (query?.length > 3 && e.key == "Enter") {
            applySearch(query);
          }
          if (query?.length > 0 && e.key == "Escape") {
            setQuery('');
            applySearch('');
          }
          if (e.key === "Backspace" || e.key === "Delete") { 
            if(e.target.value.length == 0) {
              applySearch('');
            }
          }
        }}
        disabled={disabled && query == '' && isDebouncing == false}
      />
      {query?.length > 0 && (
        <div 
          onClick={e => {
            setQuery('');
            applySearch('');
          }}
          onKeyDown={e => {
            if (query?.length > 0 && e.key == "Enter") {
              setQuery('');
              applySearch('');
            }
          }}
          tabIndex="0" 
          className="bg-[#6f328c] border-[#6f328c] absolute inset-y-0 right-0 pr-[9px] pl-1 border-l flex items-center cursor-pointer 
                    focus:bg-[#834a9e] focus:border-blue-300 focus:shadow-outline-blue focus:outline-none hover:bg-[#834a9e]"
        >
          <svg
          className={`h-4 w-4 -mt-1 ${ disabled ? 'text-gray-300' : 'text-gray-400' }`}
          stroke="currentColor"
          viewBox="0 0 20 20"
          >
            <path
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
              />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Search;
