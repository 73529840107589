import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

const mergeString = (offer, string) => {
  string = string.replace(/{_RC_NAME_}/gi, offer.merchant.title)
  string = string.replace(/{_RC_SAVING_}/gi, offer.saving)
  return string
}

const today = moment(new Date()).format('YYYY-MM-DD')

const validOffer = (offer) => {
  return offer?.status == 'Active' && (offer?.start_date <= today && offer?.end_date > today)
}

const offerDatesCalc = (offer) => {
  let obj = {
    status: offer.status == 'Active' ? 'text-emerald-500' : 'text-rose-400', 
    text:   'Issue',
    css:    'text-rose-400',
    icon:   'close-thick text-rose-400',
    tile:   offer.status == 'Active' ? 'bg-slate-200' : 'bg-[#ebcbc3]', 
    start:  offer?.start_date > today ? 'text-indigo-400' : '',
    end:    offer?.end_date   < today ? 'text-rose-400'   : '',
  }
  if (offer?.start_date > today && offer?.end_date > offer?.start_date) {
    obj.text = 'Scheduled'
    obj.css  = 'text-indigo-400'
    obj.icon = offer.status == 'Active' ? 'clock-check-outline text-indigo-400' : 'clock-alert-outline text-rose-400'
    obj.tile = offer.status == 'Active' ? 'bg-[#c3ebc7]' : 'bg-[#ebcbc3]'
  }
  else if (offer?.end_date < today && offer?.start_date < offer?.end_date) {
    obj.text   = 'Expired'
    obj.status = offer.status !== 'Active' ? 'text-gray-300 opacity-50' : 'text-emerald-500'
    obj.css    = offer.status !== 'Active' ? 'text-gray-300 opacity-50' : 'text-rose-400'
    obj.icon   = offer.status !== 'Active' ? 'history text-gray-300 opacity-50' : 'close-thick text-rose-400'
    obj.tile   = offer.status !== 'Active' ? 'bg-slate-200' : 'bg-[#ebcbc3]'
  }
  else if(offer?.end_date > today && offer?.start_date < today) {
    obj.text = 'In range'
    obj.css  = 'text-emerald-500'
    obj.icon = offer.status == 'Active' ? 'check-bold text-emerald-500' : 'close-thick text-rose-400'
    obj.tile = offer.status == 'Active' ? 'bg-slate-200' : 'bg-[#ebcbc3]'
  }
  return obj
}

const OfferTile = ({ offer }) => {

  const history = useHistory();

  const [ posY, setPosY ] = useState(null)
  
  const [ posX, setPosX ] = useState(null)

  const [ details, setDetails ] = useState(null)

  const tile = useRef()

  const getPosY = (el) => {
    if (el.current) { 
      const rects = el.current.getBoundingClientRect()
      if (rects.top > window.innerHeight - 400) setPosY('bottom')
      else setPosY('top')
      if (rects.left < 500) setPosX('left')
      else setPosX('right')
    }
    else {
      setPosY('top')
      setPosX('right')
    }
  }

  if (!offer) return ''

  if (!details) setDetails(offerDatesCalc(offer))

  return (
    <div 
      ref={tile}
      className={`
        relative flex items-center justify-center
        w-fit h-fit
        group 
        border-x-[1px] border-[#F1F5F9] mx-[-1px]
        hover:outline-indigo-500 hover:outline hover:z-[999] outline-offset-[-2px] outline-[2px]
        ${ details?.tile }
      `}
      onMouseEnter={() => { getPosY(tile) }}
      onMouseLeave={() => { setPosY(null) }}
    > 
      <img 
        src={offer?.image} 
        alt="" 
        className={`
          flex mt-[-3px]s h-[44px] min-w-[44px] w-[44px]
          ${ !validOffer(offer) && 'opacity-70 mix-blend-soft-light ' }
        `} 
      />
      {
        !validOffer(offer) && <span className={`mdi mdi-${ details?.icon } text-[20px] absolute `}></span>
      }
      { posY && (
        <div
          className={`
            absolute 
            z-[999]
            ${ posX }-[8px] 
            ${ posY }-[56px] 
            bg-gray-800
            text-[#ffffff] 
            pt-3 pb-2 px-3
            hidden
            group-hover:block
            text-[12px] 
            w-[270px] whitespace-normal
          `}
        >
          <div className={`absolute w-[46px] h-[44px] ${ posY }-[-20px] ${ posX }-[2px]`}></div>
          <div className={`absolute w-[110%] h-[100%] ${ posY }-[20px] ${ posX }-[-5%]`}></div>
          <div className="w-full flex mb-1 relative">
            <img 
              src={offer?.image} 
              alt="" 
              className={`
                w-[28%]
                aspect-ratio-1/1 
                absolute
                top-[10%] left-[4%]
              `} 
            />
            <img src={offer?.background} alt="" />
          </div>
          <div className="w-full flex mb-1 leading-5">
            <table className="w-full">
              <tbody>
                <tr className="align-top">
                  <td className="pr-3 py-1 font-mono slashed-zero tabular-nums opacity-80">
                    Title
                  </td>
                  <td className="pl-0.5 py-1 text-[14px] border-b border-slate-700">
                    { mergeString(offer, offer?.title) }
                  </td>
                </tr>
                <tr className="align-top">
                  <td className="pr-3 py-1 font-mono slashed-zero tabular-nums opacity-80">
                    Saving
                  </td>
                  <td className="py-1 text-[14px] inline-flex w-full border-b border-slate-700">
                    <span className={`flex-grow basis-1 text-center whitespace-nowrap inline-flex`}>
                      { offer?.saving }{ offer?.provider === 'Tillo' && '%' }
                    </span>
                    <span className={`flex-grow basis-1 justify-end whitespace-nowrap inline-flex`}>
                      <span className={`mdi ${ offer?.methodIcon } scale-[1.2] inline-flex mr-2`}></span>
                      { offer?.method } 
                    </span>
                  </td>
                </tr>
                <tr className="align-top">
                  <td className="pr-3 py-1 font-mono slashed-zero tabular-nums opacity-80">
                    Dates
                  </td>
                  <td className="py-1 text-[14px] inline-flex w-full border-b border-slate-700">
                    <span className={`flex-grow basis-1 text-center whitespace-nowrap inline-flex ${ details?.start }`}>
                      { moment(offer?.start_date).format('DD-MMM\'YY') } 
                    </span>
                    <span className="mdi mdi-arrow-right inline-flex"></span>
                    <span className={`flex-grow basis-1 justify-end whitespace-nowrap inline-flex ${ details?.end }`}>
                      { moment(offer?.end_date).format('DD-MMM\'YY') } 
                    </span>
                  </td>
                </tr>
                <tr className="align-top">
                  <td className="pr-3 py-1 font-mono slashed-zero tabular-nums opacity-80">
                    Status
                  </td>
                  <td className="py-1 text-[14px] inline-flex w-full">
                    <span className={`flex-grow basis-1 text-center whitespace-nowrap inline-flex ${ details?.status }`}>
                      { offer?.status } 
                    </span>
                    <span className={`mdi mdi-${ details?.icon } scale-[1.2] inline-flex`}></span>
                    <span className={`flex-grow basis-1 justify-end whitespace-nowrap inline-flex ${ details?.css }`}>
                      { details?.text } 
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-full flex mb-1 relative gap-x-[4px]">
            <button
              onClick={() => history.push(`/merchants/${ offer?.merchant?.id }`)} 
              className={`
                inline-flex flex-grow justify-center 
                p-2 
                border-[1px] border-slate-600 
                hover:bg-slate-700 focus:outline-none 
              `}
            >
              Merchant
            </button>
            <button
              onClick={() => history.push(`/${ offer?.provider === 'Standard' ? 'offers' : 'cards' }/${ offer?.id }`)} 
              className={`
                inline-flex flex-grow justify-center 
                p-2 
                border-[1px] border-slate-600 
                hover:bg-slate-700 focus:outline-none 
              `}
            >
              { offer?.provider === 'Tillo' ? 'Card' : 'Offer' }
            </button>
            <button
              className={`
                inline-flex flex-grow justify-center 
                p-2 
                border-[1px] border-slate-600 
                hover:bg-slate-700 focus:outline-none 
                ${
                  validOffer(offer) 
                  ? '' 
                  : 'pointer-events-none cursor-default opacity-50'
                }
              `}
            >
              <a 
                href={`https://fakeagent.vaboo.co.uk/view/${ offer?.merchant?.slug }/${ offer?.code }`} 
                target="_BLANK"
                className="w-full h-full"
              >
                <span className="mdi mdi-launch"></span>
              </a>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}


export default OfferTile