import React from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createSurveyMutation } from "./../graphql/mutations/vabooSurveyMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import useDataProvider from "./../hooks/dataProvider";
import { SURVEY_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../moose-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "./../moose-ui/Controls";
import { Heading, BoxedMessage, InfoMessage } from "./../moose-ui/Typography";
import {
  TextField,
  ToggleCheckboxField,
  RichTextEditorField,
  SelectField,
  ReadOnlyField,
} from "./../moose-ui/Fields";
const CREATE_SURVEY = gql(createSurveyMutation);

const SurveyCreate = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.CreateSurvey);
  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();

  const { goBack } = useBaseNavigation(SURVEY_MODEL_NAME);

  const [surveyTypes] = useDataProvider("surveyTypes");

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    title: "",
    reusable: false,
    description: "",
    survey_type_id: 0,
    question_ids: "",
  });

  const {
    setFieldValue: handleFieldOnChange,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const [
    createSurvey,
    { data: createResult, loading: isCreating, error: creationError },
  ] = useMutation(CREATE_SURVEY, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    createSurvey({ variables: data });
  };

  if (!formData) return <div></div>;

  return (
    <PageContainer>
      <BlockContainer>
        <Heading text="New Survey" />
        <FormContainer>
          <FormData>
            <Row>
              <Col width={6}>
                <TextField
                  name="title"
                  label="Survey"
                  value={formData.title}
                  onChange={handleFieldOnChange}
                  error={fieldError("title")}
                  isRequired={true}
                />
              </Col>
              <Col width={6}>
                <TextField
                  name="question_ids"
                  label="Question IDs"
                  description="Comma-separated list of question identifiers; wildcard to use next not answered question from the infinite list. Example: 1, 2, *, 50"
                  value={formData.question_ids}
                  onChange={handleFieldOnChange}
                  error={fieldError("question_ids")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={3}>
                <SelectField
                  value={formData.survey_type_id}
                  values={surveyTypes}
                  label="Survey Type"
                  name="survey_type_id"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="name"
                  error={fieldError("survey_type_id")}
                  prependOption="Select Type"
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                <RichTextEditorField
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={handleFieldOnChange}
                  error={fieldError("description")}
                  rows={5}
                  isRequired={true}
                />
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default SurveyCreate;
