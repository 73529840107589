import React, { useState } from "react";
import MultipleFilterValue from "./MultipleFilterValue";
import { PresenceControls } from "./PresenceControls"

const RangeFilter = ({ 
  filterValues,
  selectedValues,
  onUpdateFilter: onUpdateFilter,
  selectAllFilter: selectAllFilter, 
  clearFilter: clearFilter,
  updatePresence, 
}) => {

  const [isBoolean, setBoolean] = useState(false)
  
  if (!isBoolean) {
    if (filterValues.length == 2) {
      if ((filterValues[0].uid == '0' && filterValues[0].name == '0') || (filterValues[0].uid == '1' && filterValues[0].name == '1')) {
        if ((filterValues[1].uid == '1' && filterValues[1].name == '1') || (filterValues[1].uid == '0' && filterValues[1].name == '0')) {
          setBoolean(true)
        }
      }
    }
  }

  const handlePresenceFilter = (presence) => {
    updatePresence(presence)
  }

  const toggleFilterValue = (id) => {
    onUpdateFilter(id);
  };

  return (
    <div className="max-w-fit relative flex-wrap justify-between">

      <div className="flex justify-between pb-2 text-[11px] w-[100%]">
        <PresenceControls 
          selectedValues={selectedValues}
          setPresenceFilter={handlePresenceFilter}
          unsetPresenceFilter={clearFilter}
        />
        <button
          type="button"
          onClick={(e) => { selectAllFilter(); e.target.blur()}} 
          aria-disabled={
            (selectedValues.length === filterValues.length)
            || selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>'
          }
          className="
            aria-disabled:opacity-40 aria-disabled:pointer-events-none 
            inline-flex justify-center items-center
            min-w-[26px] h-[26px] basis-1/10
            rounded-[3px]
            -mt-px ml-1
            text-[11px] font-medium text-white 
            opacity-80 bg-indigo-600 border 
            hover:bg-indigo-500 active:bg-indigo-700 
            focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo 
            transition duration-150 ease-in-out
          "
        >
          <span className="mdi mdi-arrow-up-down smt-px mx-px"></span>
        </button>
        <button
          type="button"
          onClick={(e) => { clearFilter(); e.target.blur()}} 
          disabled={selectedValues.length === 0}
          className="
            disabled:opacity-40 disabled:pointer-events-none 
            inline-flex justify-center items-center
            min-w-[26px] h-[26px] basis-1/10
            rounded-[3px]
            ml-[3px] -mt-px
            text-[11px] font-medium text-white 
            opacity-80 bg-rose-600 border 
            hover:bg-rose-500 
            active:bg-rose-700 
            focus:outline-none focus:border-rose-700 focus:shadow-outline-rose 
            transition duration-150 ease-in-out
          "
        >
          <span className="mdi mdi-close smt-px mx-px"></span>
        </button>
      </div>
      <div 
        className={`
          relative pointer-events-click 
          flex justify-end w-full 
          bg-white text-xs z-50 
          ${ 
            selectedValues[0] == '<_MISSING_>' || selectedValues[0] == '<_PRESENT_>' 
            ? 'opacity-30 pointer-events-none' : ''
          }
        `}
      >
        <div 
          className="bg-white z-40 max-h-[calc(50vh-56px)] flex-grow w-full overscroll-contain overflow-x-hidden focus:outline-none"
          style={{ overflowY: "auto" }}
        >
          <div className="">
            {filterValues &&
              filterValues.length > 0 &&
              filterValues
              .sort((a, b) => b.name > a.name ? -1 : 0)
              .sort((a, b) => b.count - a.count)
              .map((value, i) => (
                <MultipleFilterValue
                  isBoolean={isBoolean}
                  key={value.uid + i}
                  filterId={value.uid}
                  filterValue={value.name}
                  isSelected={ selectedValues.indexOf(value.uid) > -1 ? true : false }
                  count={value.count}
                  onClick={toggleFilterValue}
                />
              ))
            }
          </div>
        </div>
      </div>
      <div className="opacity-70 text-[11px] w-full text-right px-2 pt-2 pb-1 -mt-1 bg-white">
        {filterValues.length && (
          <span>
            { filterValues.length } Options
          </span>
        )}
      </div>
    </div>
  );
};

export default RangeFilter;
