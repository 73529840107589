import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Bar } from "react-chartjs-2";
import { profileCompletionReport } from "./../../graphql/queries/reportQueries";
import { generateSystemAccessCodeMutation } from "./../../graphql/mutations/systemMutations";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useTypeFilters from "./../../hooks/typeFilters";
import {
  REPORTS_PROFILE_COMPLETIONS_MODEL_NAME,
  REPORTS_PROFILE_FIELD_ANSWERS_MODEL_NAME,
} from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import { Heading, ItemsNotFound } from "./../../moose-ui/Typography";
import { SelectField } from "../../moose-ui/Fields";

const PROFILE_COMPLETIONS_REPORT = gql(profileCompletionReport);
const GENERATE_SYSTEM_ACCESS_CODE = gql(generateSystemAccessCodeMutation);
const chartOptions = {
  type: "bar",
  options: {
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  },
};
const REPORT_DRAFT = {
  labels: [],
  datasets: [
    {
      label: "Coverage",
      data: [],
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
    },
  ],
};

const ProfileFieldCompletions = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.ProfileFieldCompletionsReport);
  const chartRef = useRef();
  const { client_id: clientId } = useParams();
  const history = useHistory();
  const [reportData, setReportData] = useState(REPORT_DRAFT);

  const handleClientChange = (e) =>
    history.push(`${REPORTS_PROFILE_COMPLETIONS_MODEL_NAME}/${e.target.value}`);

  const handleFieldOnClick = (e) => {
    const { _index } = e[0];
    history.push(
      `${REPORTS_PROFILE_FIELD_ANSWERS_MODEL_NAME}/${clientId}/${reportData.labels[_index]}`
    );
  };

  const { data, loading, error } = useQuery(PROFILE_COMPLETIONS_REPORT, {
    variables: { client_id: clientId },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data && data.profileCompletionReport) {
        const { field_completions } = data.profileCompletionReport;
        const newReportData = { ...REPORT_DRAFT };
        newReportData.labels = field_completions.map((r) => r.field_name);
        newReportData.datasets[0].data = field_completions.map(
          (r) => r.completion_percentage
        );
        setReportData(newReportData);
      }
    },
  });

  const [generateSystemAccessCode] = useMutation(GENERATE_SYSTEM_ACCESS_CODE, {
    onCompleted: (result) => {},
    onError: (e) => {
      alert("ailed to generate Vaboo REST System Access Code");
      console.log(e);
    },
  });

  const { data: typeFilters, isReady: typeFiltersReady } = useTypeFilters({
    filtersFor: "profileCompletions",
    queryString: "",
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        {loading && (
          <Heading text="Report. Profile Field Completions (Coverage), %. Loading..." />
        )}
        {!loading && (
          <Heading text="Report. Profile Field Completions (Coverage), %" />
        )}
        <Row>
          <Col width={2}>
            <SelectField
              value={clientId}
              values={typeFilters[0].values}
              label="Client"
              onChange={handleClientChange}
              valueKey="uid"
              labelKey="name"
              prependOption="All Clients"
            />
          </Col>
        </Row>
        {data.profileCompletionReport.field_completions.length > 0 && (
          <Row>
            <Col width={12}>
              <Bar
                ref={chartRef}
                height={95}
                data={reportData}
                options={chartOptions}
                getElementAtEvent={handleFieldOnClick}
              />
            </Col>
          </Row>
        )}
        {data.profileCompletionReport.field_completions.length === 0 && (
          <ItemsNotFound
            text="Nothing found"
            description="No field coverage found for current criteria"
          />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default ProfileFieldCompletions;
