export const deleteInvoicesMutation = `mutation deleteInvoices(
    $ids: [Int!]!
  ) {  
    deleteInvoices(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const createInvoiceMutation = `mutation createInvoice(
    $cart_id: Int!,
    $amount: Float,
    $description: String,
    $payment_system: String,
    $date_to_charge: Date
  ) {  
  createInvoice(
      cart_id: $cart_id,
      amount: $amount,
      description: $description,
      payment_system: $payment_system,
      date_to_charge: $date_to_charge      
  ) {
    id
  }
  }
  `;

export const updateInvoiceMutation = `mutation updateInvoice(
    $id: ID!,
    $cart_id: Int!,
    $amount: Float,
    $description: String,
    $payment_system: String,
    $date_to_charge: Date
  ) {  
  updateInvoice(
      id: $id,
      cart_id: $cart_id,
      amount: $amount,
      description: $description,
      payment_system: $payment_system,
      date_to_charge: $date_to_charge      
  ) {
    id
  }
  }
  `;

export const createMembershipVoucherMutation = `mutation createMembershipVoucher(
    $title: String
    $code: String
    $description: String
    $type: String
    $max_uses_per_user: Int
    $released: Int
    $monthly_value: Float
    $annually_value: Float
    $lifetime_value: Float
    $status: String,
    $expires_at: Date
  ) {  
    createMembershipVoucher(
      title: $title
      code: $code
      description: $description
      type: $type
      max_uses_per_user: $max_uses_per_user
      released: $released
      monthly_value: $monthly_value
      annually_value: $annually_value
      lifetime_value: $lifetime_value
      status: $status
      expires_at: $expires_at  
  ) {
    id
  }
  }
  `;

export const updateMembershipVoucherMutation = `mutation updateMembershipVoucher(
    $id: ID!
    $title: String
    $code: String
    $description: String
    $type: String
    $max_uses_per_user: Int
    $released: Int
    $monthly_value: Float
    $annually_value: Float
    $lifetime_value: Float
    $status: String,
    $expires_at: Date
  ) {  
    updateMembershipVoucher(
      id: $id
      title: $title
      code: $code
      description: $description
      type: $type
      max_uses_per_user: $max_uses_per_user
      released: $released
      monthly_value: $monthly_value
      annually_value: $annually_value
      lifetime_value: $lifetime_value
      status: $status
      expires_at: $expires_at  
  ) {
    id
  }
  }
  `;

export const updateClientStandardPricingMutation = `mutation updateClientStandardPricing(
    $client_id: Int!,
    $type: String,
    $maintance_fee_monthly_amount: Float,
    $maintance_fee_monthly_billing_day: Int,
    $gifted_premium_access_fee_monthly: Float,
    $acquired_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $can_give_basic: Boolean,
    $can_give_premium: Boolean,
    $can_upgrade: Boolean,
    $must_have_payment_method: Boolean,
    $payment_takeover_by_user_id: Int,
    $invoice_to_text: String,
    $only_charge_for_active_premium: Boolean,
    $should_invoice: Boolean,
    $payment_method_code: String
  ) {  
    updateClientStandardPricing(      
      client_id: $client_id,
      type: $type,
      maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
      maintance_fee_monthly_billing_day: $maintance_fee_monthly_billing_day,
      gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
      acquired_premium_access_fee_monthly: $acquired_premium_access_fee_monthly,
      minimum_payment_amount: $minimum_payment_amount,
      number_of_free_premium_users: $number_of_free_premium_users,
      can_give_basic: $can_give_basic,
      can_give_premium: $can_give_premium,
      can_upgrade: $can_upgrade,
      must_have_payment_method: $must_have_payment_method,
      payment_takeover_by_user_id: $payment_takeover_by_user_id,
      invoice_to_text: $invoice_to_text,
      only_charge_for_active_premium: $only_charge_for_active_premium,
      should_invoice: $should_invoice,
      payment_method_code: $payment_method_code
    ) {
      payment_settings {
        id
      }
    }
  }
  `;

export const updateClientPackagePricingMutation = `mutation updateClientPackagePricing(
    $client_id: Int!,
    $type: String,
    $maintance_fee_monthly_amount: Float,
    $maintance_fee_monthly_billing_day: Int,
    $gifted_premium_access_fee_monthly: Float,
    $acquired_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $can_give_basic: Boolean,
    $can_give_premium: Boolean,
    $can_upgrade: Boolean,
    $must_have_payment_method: Boolean,
    $units: Int,
    $price: Float,
    $payment_takeover_by_user_id: Int,
    $invoice_to_text: String,
    $only_charge_for_active_premium: Boolean,
    $should_invoice: Boolean,
    $payment_method_code: String
  ) {  
    updateClientPackagePricing(      
      client_id: $client_id,
      type: $type,
      maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
      maintance_fee_monthly_billing_day: $maintance_fee_monthly_billing_day,
      gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
      acquired_premium_access_fee_monthly: $acquired_premium_access_fee_monthly,
      minimum_payment_amount: $minimum_payment_amount,
      number_of_free_premium_users: $number_of_free_premium_users,
      can_give_basic: $can_give_basic,
      can_give_premium: $can_give_premium,
      can_upgrade: $can_upgrade,
      must_have_payment_method: $must_have_payment_method,
      units: $units,
      price: $price,
      payment_takeover_by_user_id: $payment_takeover_by_user_id,
      invoice_to_text: $invoice_to_text,
      only_charge_for_active_premium: $only_charge_for_active_premium,
      should_invoice: $should_invoice,
      payment_method_code: $payment_method_code
    ) {
      payment_settings {
        id
      }      
    }
  }
  `;

export const updateClientGraduatedPricingMutation = `mutation updateClientGraduatedPricing(
    $client_id: Int!,
    $type: String,
    $maintance_fee_monthly_amount: Float,
    $maintance_fee_monthly_billing_day: Int,
    $gifted_premium_access_fee_monthly: Float,
    $acquired_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $can_give_basic: Boolean,
    $can_give_premium: Boolean,
    $can_upgrade: Boolean,
    $must_have_payment_method: Boolean,
    $tiers: [PricingTierInput],
    $payment_takeover_by_user_id: Int,   
    $invoice_to_text: String,
    $only_charge_for_active_premium: Boolean,
    $should_invoice: Boolean,
    $payment_method_code: String 
  ) {  
    updateClientGraduatedPricing(      
      client_id: $client_id,
      type: $type,
      maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
      maintance_fee_monthly_billing_day: $maintance_fee_monthly_billing_day,
      gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
      acquired_premium_access_fee_monthly: $acquired_premium_access_fee_monthly,
      minimum_payment_amount: $minimum_payment_amount,
      number_of_free_premium_users: $number_of_free_premium_users,
      can_give_basic: $can_give_basic,
      can_give_premium: $can_give_premium,
      can_upgrade: $can_upgrade,
      must_have_payment_method: $must_have_payment_method,
      tiers: $tiers,
      payment_takeover_by_user_id: $payment_takeover_by_user_id,
      invoice_to_text: $invoice_to_text,
      only_charge_for_active_premium: $only_charge_for_active_premium,
      should_invoice: $should_invoice,
      payment_method_code: $payment_method_code
    ) {
      payment_settings {
        id
      }      
    }
  }
  `;

export const updateClientVolumePricingMutation = `mutation updateClientVolumePricing(
    $client_id: Int!,
    $type: String,
    $maintance_fee_monthly_amount: Float,
    $maintance_fee_monthly_billing_day: Int,
    $gifted_premium_access_fee_monthly: Float,
    $acquired_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $can_give_basic: Boolean,
    $can_give_premium: Boolean,
    $can_upgrade: Boolean,
    $must_have_payment_method: Boolean,
    $tiers: [PricingTierInput],
    $payment_takeover_by_user_id: Int,
    $invoice_to_text: String,
    $only_charge_for_active_premium: Boolean,
    $should_invoice: Boolean,
    $payment_method_code: String 
  ) {  
    updateClientVolumePricing(      
      client_id: $client_id,
      type: $type,
      maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
      maintance_fee_monthly_billing_day: $maintance_fee_monthly_billing_day,
      gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
      acquired_premium_access_fee_monthly: $acquired_premium_access_fee_monthly,
      minimum_payment_amount: $minimum_payment_amount,
      number_of_free_premium_users: $number_of_free_premium_users,
      can_give_basic: $can_give_basic,
      can_give_premium: $can_give_premium,
      can_upgrade: $can_upgrade,
      must_have_payment_method: $must_have_payment_method,
      tiers: $tiers,
      payment_takeover_by_user_id: $payment_takeover_by_user_id,
      invoice_to_text: $invoice_to_text,
      only_charge_for_active_premium: $only_charge_for_active_premium,
      should_invoice: $should_invoice,
      payment_method_code: $payment_method_code
    ) {
      payment_settings {
        id
      }      
    }
  }
  `;

export const updateClientVolumePackagePricingMutation = `mutation updateClientVolumePackagePricing(
    $client_id: Int!,
    $type: String,
    $maintance_fee_monthly_amount: Float,
    $maintance_fee_monthly_billing_day: Int,
    $gifted_premium_access_fee_monthly: Float,
    $acquired_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $can_give_basic: Boolean,
    $can_give_premium: Boolean,
    $can_upgrade: Boolean,
    $must_have_payment_method: Boolean,
    $tiers: [PricingTierInput],
    $payment_takeover_by_user_id: Int,
    $invoice_to_text: String,
    $only_charge_for_active_premium: Boolean,
    $should_invoice: Boolean,
    $payment_method_code: String 
  ) {  
    updateClientVolumePackagePricing(      
      client_id: $client_id,
      type: $type,
      maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
      maintance_fee_monthly_billing_day: $maintance_fee_monthly_billing_day,
      gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
      acquired_premium_access_fee_monthly: $acquired_premium_access_fee_monthly,
      minimum_payment_amount: $minimum_payment_amount,
      number_of_free_premium_users: $number_of_free_premium_users,
      can_give_basic: $can_give_basic,
      can_give_premium: $can_give_premium,
      can_upgrade: $can_upgrade,
      must_have_payment_method: $must_have_payment_method,
      tiers: $tiers,
      payment_takeover_by_user_id: $payment_takeover_by_user_id,
      invoice_to_text: $invoice_to_text,
      only_charge_for_active_premium: $only_charge_for_active_premium,
      should_invoice: $should_invoice,
      payment_method_code: $payment_method_code
    ) {
      payment_settings {
        id
      }      
    }
  }
  `;

export const chargeNowMutation = `mutation chargeNow($id: ID!) {  
    chargeNow(id: $id) {
      id
      cart_id
      amount
      description
      status
      last_billing_attempt_date
      post_billing_message
      payment_system
      pricing_model 
      date_to_charge
      checks {
        id
        bill_id
        status
        post_billing_message
        created_at
        updated_at
      }     
    }
  }
  `;

export const updateMembershipPricingMutation = `mutation updateMembershipPricing(
    $client_id: Int!,
    $monthly_price: Float!,
    $annual_price: Float!,
    $lifetime_price: Float!,
    $use_global_settings: Boolean!
  ) {  
    updateMembershipPricing(
      client_id: $client_id,
      monthly_price: $monthly_price,
      annual_price: $annual_price,
      lifetime_price: $lifetime_price,
      use_global_settings: $use_global_settings
    ) {
      id
      client_id
      monthly_price
      annual_price
      lifetime_price
      use_global_settings
    }
  }
  `;

export const publishMembershipVouchersMutation = `mutation publishMembershipVouchers(
    $ids: [Int!]!
  ) {  
    publishMembershipVouchers(      
      ids: $ids
    ) {
      id
    }
  }
  `;

export const deleteMembershipVouchersMutation = `mutation deleteMembershipVouchers(
    $ids: [Int!]!
  ) {  
    deleteMembershipVouchers(      
      ids: $ids
    ) {
      id
    }
  }
  `;
