import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { IconSearch } from "./../icons/Icons";

const Search = ({ onApplySearch: applySearch }) => {
  const [query, setQuery] = useState("");
  const { search } = useParams();
  useEffect(() => {
    if (search != "0") {
      setQuery(search);
    }
  }, []);
  const readableQuery = () =>
    query == 0 || query == "0" || query == "all" ? "" : query;
  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <svg
          className="h-5 w-5 text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <input
        id="search"
        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm transition duration-150 ease-in-out"
        placeholder="Search"
        value={readableQuery()}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key == "Enter") {
            applySearch(query);
          }
        }}
      />
    </div>
  );
};

export default Search;
