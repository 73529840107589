import { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";

const rootQueryPrefix = "fetchMore";

const state = {
  search: "0",
  cursor: 0,
  queryString: "",
};

const useFetchMoreNavigation = ({ query, entityType, queryString }) => {
  const [hasMore, setHasMore] = useState(false);
  const [result, setResult] = useState([]);

  useEffect(() => {
    state.search = "0";
    state.cursor = 0;
    state.queryString = queryString;
  }, []);

  const queryContext = {
    query,
    variables: { cursor: 0, search: "0", queryString },
  };

  const rootQueryId =
    rootQueryPrefix + entityType[0].toUpperCase() + entityType.slice(1);

  const { loading, error, fetchMore, refetch } = useQuery(query, {
    variables: { cursor: 0, search: "0", queryString },
    onCompleted: (data) => {
      state.cursor = data[rootQueryId].cursor;
      setHasMore(data[rootQueryId].hasMore);
      setResult(data[rootQueryId][entityType]);
    },
  });

  const applyFetchMore = () => {
    fetchMore({
      query: query,
      variables: { ...state },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const fetchResult = fetchMoreResult[rootQueryId];

        state.cursor = fetchResult.cursor;
        setHasMore(fetchResult.hasMore);

        const newDataCache = JSON.parse(JSON.stringify(({ ...prev })));

        newDataCache[rootQueryId].cursor = fetchResult.cursor;
        newDataCache[rootQueryId].hasMore = fetchResult.hasMore;
        newDataCache[rootQueryId][entityType] = [
          ...newDataCache[rootQueryId][entityType],
          ...fetchResult[entityType],
        ];

        setResult([...newDataCache[rootQueryId][entityType]]);

        return newDataCache;
      },
    });
  };

  const applySearch = (searchQuery) => {
    // Proper way is to use refetch instead of fetchMore in this method.
    // But refetch is "buggy" in the current version of ApolloClient, so using fetchMore instead.
    // updateQuery will not merge new data with cached, instead just overwrite it.

    state.search = searchQuery.length > 1 ? searchQuery : "0";
    state.cursor = 0;

    fetchMore({
      query: query,
      variables: { ...state },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        state.cursor = fetchMoreResult.cursor;
        const fetchResult = fetchMoreResult[rootQueryId];
        setHasMore(fetchResult.hasMore);
        const newDataCache = { [rootQueryId]: { ...fetchResult } };
        setResult([...newDataCache[rootQueryId][entityType]]);

        return newDataCache;
      },
    });
  };

  return {
    applySearch,
    hasMore,
    result,
    setResult,
    error,
    loading,
    fetchMore: applyFetchMore,
    refetch,
    queryContext,
  };
};

export default useFetchMoreNavigation;
