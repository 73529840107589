import React, { useEffect } from "react";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableActions,
  TableSelectedActions,
  Pagination,
} from "./../../moose-ui/DataTable";
import { ItemsNotFound } from "./../../moose-ui/Typography";
import Search from "./../../moose-ui/Search";
import { Heading } from "./../../moose-ui/Typography";
import TypeFilters from "./../../moose-ui/filters/TypeFilters";
import DateRangeFilter from "./../../moose-ui/filters/DateRangeFilter";
import EventValuesNiceView from "./ui-parts/EventValuesNiceView";

/*
 * Field Drawer Components
 */

const SessionField = ({ data }) => <TCol value={data.session.session_id} />;

const UserField = ({ data }) => (
  <TCol>{data.session.tenant && data.session.tenant.email}</TCol>
);

const ClientField = ({ data }) => (
  <TCol>
    {data.session.tenant &&
      data.session.tenant.landlord &&
      data.session.tenant.landlord.email}
  </TCol>
);

const DateTimeField = ({ data }) => <TCol value={data.created_at} />;

const SessionSimpleTextField = ({ field, data }) => (
  <TCol value={data.session[field]} />
);

/*
 * Field settings
 */
const REPORT_FIELDS = {
  session_id: {
    name: "session_id",
    title: "Session ID",
    draw: (key, data) => <SessionField key={key} data={data} />,
  },
  user: {
    name: "user",
    title: "User",
    draw: (key, data) => <UserField key={key} data={data} />,
  },
  client: {
    name: "client",
    title: "Client",
    draw: (key, data) => <ClientField key={key} data={data} />,
  },
  datetime: {
    name: "datetime",
    title: "Datetime",
    draw: (key, data) => <DateTimeField key={key} data={data} />,
  },
  continent_name: {
    name: "continent_name",
    title: "Continent Name",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="continent_name" data={data} />
    ),
  },
  continent_code: {
    name: "continent_code",
    title: "Continent Code",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="continent_code" data={data} />
    ),
  },
  browser_name: {
    name: "browser_name",
    title: "Browser Name",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="browser_name" data={data} />
    ),
  },
  browser_type: {
    name: "browser_type",
    title: "Browser Type",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="browser_type" data={data} />
    ),
  },
  device: {
    name: "device",
    title: "Device",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="device" data={data} />
    ),
  },
  os: {
    name: "os",
    title: "OS",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="os" data={data} />
    ),
  },
  ip: {
    name: "ip",
    title: "IP",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="ip" data={data} />
    ),
  },
  provider_asn: {
    name: "provider_asn",
    title: "Provider ASN",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="provider_asn" data={data} />
    ),
  },
  provider_domain: {
    name: "provider_domain",
    title: "Provider Domain",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="provider_domain" data={data} />
    ),
  },
  provider_type: {
    name: "provider_type",
    title: "Provider Type",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="provider_type" data={data} />
    ),
  },
  city: {
    name: "city",
    title: "City",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="city" data={data} />
    ),
  },
  country_name: {
    name: "country_name",
    title: "Country Name",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="country_name" data={data} />
    ),
  },
  flag: {
    name: "flag",
    title: "Flag",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="flag" data={data} />
    ),
  },
  is_eu: {
    name: "is_eu",
    title: "Is EU",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="is_eu" data={data} />
    ),
  },
  region: {
    name: "region",
    title: "Region",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="region" data={data} />
    ),
  },
  region_code: {
    name: "region_code",
    title: "Region Code",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="region_code" data={data} />
    ),
  },
  postal: {
    name: "postal",
    title: "Postal",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="postal" data={data} />
    ),
  },
  latitude: {
    name: "latitude",
    title: "Latitude",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="latitude" data={data} />
    ),
  },
  longitude: {
    name: "longitude",
    title: "Longitude",
    draw: (key, data) => (
      <SessionSimpleTextField key={key} field="longitude" data={data} />
    ),
  },
};

/*
 * List of available fields from field settings object
 */
const REPORT_FIELD_NAMES = Object.keys(REPORT_FIELDS);

const ActivityEventsReport = ({
  loading,
  data,
  typeFilters,
  onApplySearch: applySearch,
  onNextPage: nextPage,
  onPrevPage: prevPage,
  onApplyFilters: applyFilters,
  onResetFilters: resetFilters,
  onRemoveFiltervalue: removeFilterValue,
  onUpdateFilter: updateFilter,
  selectedFilters,
  hasActiveFilters,
  displayFields = ["session_id", "user", "client", "datetime"],
  startDate,
  endDate,
}) => {
  return (
    <PageContainer>
      <BlockContainer>
        {loading && <Heading text="Report. Activity Events. Loading..." />}
        {!loading && <Heading text="Report. Activity Events" />}
        <div className="flex items-center pt-5"></div>
        <Row>
          <Col width={12}>
            <DateRangeFilter
              startDate={startDate}
              endDate={endDate}
              onApplyRange={applyFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col width={12}>
            <TypeFilters
              data={typeFilters}
              onApply={applyFilters}
              onReset={resetFilters}
              onRemoveValue={removeFilterValue}
              hasActiveFilters={hasActiveFilters}
              onUpdate={updateFilter}
              selectedFilters={selectedFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col width={9}></Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>

        {data && data.activityEvents && data.activityEvents.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol name="id" value="ID" />
                  <THCol name="event" value="Event" />
                  <THCol name="data" value="Data" />
                  {displayFields.map(
                    (field) =>
                      REPORT_FIELD_NAMES.indexOf(field) > -1 && (
                        <THCol
                          key={field}
                          name={REPORT_FIELDS[field].name}
                          value={REPORT_FIELDS[field].title}
                        />
                      )
                  )}
                </TRow>
              </THeading>
              <TContent>
                {data.activityEvents.map((ae, idx) => (
                  <TRow key={idx}>
                    <TCol value={ae.id} />
                    <TCol value={ae.event} />
                    <TCol>
                      <EventValuesNiceView data={ae.values} />
                    </TCol>
                    {displayFields.map(
                      (field) =>
                        REPORT_FIELD_NAMES.indexOf(field) > -1 &&
                        REPORT_FIELDS[field].draw(field, ae)
                    )}
                  </TRow>
                ))}
              </TContent>
            </TableView>
            <TableActions>
              <TableSelectedActions></TableSelectedActions>
              <Pagination onPrev={prevPage} onNext={nextPage} />
            </TableActions>
          </TableContainer>
        )}
        {data && data.activityEvents && data.activityEvents.length == 0 && (
          <ItemsNotFound text="Activity Events not found" />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default ActivityEventsReport;
