export const allSurveyTypes = `query surveyTypes($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  surveyTypes(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
    id
    name
  }
}
`;

export const allQuestions = `query questions($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    questions(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      is_survey_type
      is_profile_type
      admin_title
      title
      type
      dropdown_title
      status 
      answers {
        id
        title
        metadata {
          minimum
          maximum
          weight
        }
      }
      params
    }
  }
  `;

export const questionDetails = `query question($id: ID!) {
  question(id: $id) {
    id
    is_survey_type
    is_profile_type
    admin_title
    title
    status
    type
    answers {
      id
      title
      metadata {
        minimum
        maximum
        weight
      }
    }
    params
  }
  }
  `;

export const allSurveys = `query surveys($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    surveys(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      title
      description
      status     
      question_ids 
      survey_type {
        id
        name
      }
    }
  }
  `;

export const publishedSurveys = `query publishedSurveys($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
  publishedSurveys(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      title
      description
      status     
      question_ids 
    }
  }
  `;

export const surveyDetails = `query survey($id: ID!) {
  survey(id: $id) {
    id
    title
    reusable
    survey_type_id
    description
    status    
    question_ids
  }
  }
  `;

export const qaSettings = `query qaSettings($owner_id: Int!, $label_id: Int!) {
    qaSettings(owner_id: $owner_id, label_id: $label_id) {
      activation_survey_id
      monthly_competition_survey_id
      barrier_survey_id
      barrier_survey_is_active
      frequency_survey_id
      frequency_days
      infinityQuestionList 
      barrier_survey_enabled
      frequency_survey_enabled
    }
    }
    `;
